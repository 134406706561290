import { ContentWrapper } from '@/components/organisms/Reports';
import { ReportTable, Tbody, Td, Th, Tr } from '@/components/organisms/Reports/ReportContent/ReportTable';
import { useAppSelector } from '@/hooks';
import { getWonVsLossReportAction } from '@/redux/actions';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { convertToNumber } from '@/utils';
import { useEffect, useState } from 'react';
interface TData {
  tabelOne: {
    total: any;
    data: any[];
  };
  tableTwo: {
    total: any;
    data: any[];
  };
  tableThree: {
    total: any;
    data: any[];
  };
}

const LeadToClientLostVsWon = () => {
  const [tableData, setTableData] = useState<TData | undefined>(undefined);

  const [isShowPercentage, setIsShowPercentage] = useState(false);
  const [isShowPercentage1, setIsShowPercentage1] = useState(false);
  const [isShowPercentage2, setIsShowPercentage2] = useState(false);

  const result = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_LOST_VS_WON_REPORT];

  useEffect(() => {
    getWonVsLossReportAction({
      report_type: 'lead',
    });
  }, []);

  useEffect(() => {
    if (result && result?.data) {
      restructureData(result);
    }
  }, [result]);

  const restructureData = (result: any) => {
    const newDataSet: TData = {
      tabelOne: {
        total: {},
        data: [],
      },
      tableTwo: {
        total: {},
        data: [],
      },
      tableThree: {
        total: {},
        data: [],
      },
    };

    result?.data &&
      Object.keys(result?.data).forEach((key: string) => {
        const item = result?.data[key];

        // table one
        const defaultValue = {
          percentage: 0,
          count: 0,
        };

        newDataSet.tabelOne.data.push({
          source: key,
          total_leads: item.won_vs_lost.total_leads || 0,
          leads_not_converted_yet: item.won_vs_lost.leads_not_converted_yet || 0,
          converted_to_clients: item.won_vs_lost.converted_to_clients || 0,
          total_lost: item.won_vs_lost.total_lost || 0,
        });

        const table1Sums: any = {};
        newDataSet.tabelOne.data.forEach((_: any) => {
          table1Sums.total_leads = (table1Sums.total_leads || 0) + _?.total_leads;
          table1Sums.leads_not_converted_yet =
            (table1Sums.leads_not_converted_yet || 0) + _.leads_not_converted_yet?.count;
          table1Sums.converted_to_clients = (table1Sums.converted_to_clients || 0) + _.converted_to_clients?.count;
          table1Sums.total_lost = (table1Sums.total_lost || 0) + _.total_lost?.count;
        });
        newDataSet.tabelOne.total = table1Sums;

        // table two
        const table2Cols = ['no_los', 'lost', 'duplicate']; // + others

        const table2others = item?.lost_type_breakdown.filter((a: any) => !table2Cols.includes(a.reason));

        const table2OthersCount = table2others.reduce((acc: number, item: any) => acc + item?.count, 0) || 0;

        newDataSet.tableTwo.data.push({
          source: key,
          total_leads: item.won_vs_lost.total_leads || 0,
          no_los: item.lost_type_breakdown.find((_: any) => _.reason === 'no_los') || defaultValue,
          lost: item.lost_type_breakdown.find((_: any) => _.reason === 'lost') || defaultValue,
          duplicate: item.lost_type_breakdown.find((_: any) => _.reason === 'duplicate') || defaultValue,
          other: {
            count: table2OthersCount,
            percentage: ((table2OthersCount * 100) / item.won_vs_lost.total_leads).toFixed(2),
          },
        });

        const table2Sums: any = {};
        newDataSet.tableTwo.data.forEach((_: any) => {
          table2Sums.total_leads = (table2Sums.total_leads || 0) + _.total_leads;
          table2Sums.no_los = (table2Sums.no_los || 0) + _.no_los.count;
          table2Sums.lost = (table2Sums.lost || 0) + _.lost.count;
          table2Sums.duplicate = (table2Sums.duplicate || 0) + _.duplicate.count;
          table2Sums.other = (table2Sums.other || 0) + _.other.count;
        });
        newDataSet.tableTwo.total = table2Sums;

        // table three

        const table3Cols = [
          'Installation Cost to High',
          'Monthly Rate to High',
          'Not Fast Enough',
          "Doesn't See Value",
          'Happy with Current Provider',
          'Unreachable',
          'Chose Different Provider',
        ]; // + others

        const table3others = item?.lost_type_breakdown.filter((a: any) => !table3Cols.includes(a.reason));

        const table3OthersCount = table3others.reduce((acc: number, item: any) => acc + item?.count, 0) || 0;
        console.log({ table3others, table3OthersCount });
        newDataSet.tableThree.data.push({
          source: key,
          total_lost: item.won_vs_lost.total_lost,
          installation_cost_to_high:
            item.lost_sub_type_breakdown.find((_: any) => _.reason_details === 'Installation Cost to High') ||
            defaultValue,
          monthly_rate_to_high:
            item.lost_sub_type_breakdown.find((_: any) => _.reason_details === 'Monthly Rate to High') || defaultValue,
          not_fast_enough:
            item.lost_sub_type_breakdown.find((_: any) => _.reason_details === 'Not Fast Enough') || defaultValue,
          does_not_see_value:
            item.lost_sub_type_breakdown.find((_: any) => _.reason_details === "Doesn't See Value") || defaultValue,
          happy_with_current_provider:
            item.lost_sub_type_breakdown.find((_: any) => _.reason_details === 'Happy with Current Provider') ||
            defaultValue,
          unreachable:
            item.lost_sub_type_breakdown.find((_: any) => _.reason_details === 'Unreachable') || defaultValue,
          chose_different_provider:
            item.lost_sub_type_breakdown.find((_: any) => _.reason_details === 'Chose Different Provider') ||
            defaultValue,
          other: {
            count: table3OthersCount,
            percentage: (table3OthersCount * 100) / item.won_vs_lost.total_lost.count || 0,
          },
        });
      });

    const table3Sums: any = {};

    newDataSet.tableThree.data.forEach((_: any) => {
      table3Sums.total_lost = (table3Sums.total_lost || 0) + _.total_lost.count;
      table3Sums.installation_cost_to_high =
        (table3Sums.installation_cost_to_high || 0) + _.installation_cost_to_high.count;
      table3Sums.monthly_rate_to_high = (table3Sums.monthly_rate_to_high || 0) + _.monthly_rate_to_high.count;
      table3Sums.not_fast_enough = (table3Sums.not_fast_enough || 0) + _.not_fast_enough.count;
      table3Sums.does_not_see_value = (table3Sums.does_not_see_value || 0) + _.does_not_see_value.count;
      table3Sums.happy_with_current_provider =
        (table3Sums.happy_with_current_provider || 0) + _.happy_with_current_provider.count;
      table3Sums.unreachable = (table3Sums.unreachable || 0) + _.unreachable.count;
      table3Sums.chose_different_provider =
        (table3Sums.chose_different_provider || 0) + _.chose_different_provider.count;
      table3Sums.other = (table3Sums.other || 0) + _.other.count;
    });

    newDataSet.tableThree.total = table3Sums;
    setTableData(newDataSet);
  };

  return (
    <>
      <ContentWrapper headings={['Lead to Client | Won vs. Lost']}>
        <div className={'data-toggle-view'}>
          <span onClick={() => setIsShowPercentage(!isShowPercentage)}>Toggle %</span>
        </div>
        <ReportTable tableContainerClass='no-border-bottom' marginBottom={'18px'}>
          <Tbody>
            <Tr>
              <Th align={'left'}>Source</Th>
              <Th align={'center'}>Total Leads</Th>
              <Th align={'center'}>Converted to Clients</Th>
              <Th align={'center'}>Total Lost</Th>
            </Tr>
            {tableData &&
              tableData.tabelOne.data.map((item: any, index: number) => (
                <Tr key={index}>
                  <Td align={'left'}>{item.source}</Td>
                  <Td align={'center'}>{item.total_leads}</Td>
                  <Td align={'center'}>
                    {isShowPercentage
                      ? `${Math.round(convertToNumber(item.converted_to_clients?.percentage))}%`
                      : item.converted_to_clients?.count}
                  </Td>
                  <Td align={'center'}>
                    {isShowPercentage
                      ? `${Math.round(convertToNumber(item.total_lost.percentage))}%`
                      : item.total_lost.count}
                  </Td>
                </Tr>
              ))}
            <Tr>
              <Th align={'left'}>ALL Sources</Th>
              <Th>{tableData?.tabelOne.total.total_leads}</Th>
              <Th>
                {isShowPercentage
                  ? `${Math.round(
                      (convertToNumber(tableData?.tabelOne.total.converted_to_clients) * 100) /
                        convertToNumber(tableData?.tabelOne.total.total_leads),
                    )}%`
                  : tableData?.tabelOne.total.converted_to_clients}
              </Th>
              <Th>
                {isShowPercentage
                  ? `${Math.round(
                      (convertToNumber(tableData?.tabelOne.total.total_lost) * 100) /
                        convertToNumber(tableData?.tabelOne.total.total_leads),
                    )}%`
                  : tableData?.tabelOne.total.total_lost}
              </Th>
            </Tr>
          </Tbody>
        </ReportTable>

        <div className={'data-toggle-view'}>
          <span onClick={() => setIsShowPercentage1(!isShowPercentage1)}>Toggle %</span>
        </div>
        <ReportTable tableContainerClass='no-border-bottom' marginBottom={'18px'}>
          <Tbody>
            <Tr>
              <Th align={'left'}>Source</Th>
              <Th align={'center'}>Total Leads</Th>
              <Th align={'center'}>No LOS</Th>
              <Th align={'center'}>Lost</Th>
              <Th align={'center'}>Duplicate</Th>
              <Th align={'center'}>Other</Th>
            </Tr>
            {tableData &&
              tableData.tableTwo.data.map((item: any, index: number) => (
                <Tr key={index}>
                  <Td align={'left'}>{item.source}</Td>
                  <Td align={'center'}>{item.total_leads}</Td>
                  <Td align={'center'}>
                    {isShowPercentage1 ? `${Math.round(convertToNumber(item.no_los.percentage))}%` : item.no_los.count}
                  </Td>
                  <Td align={'center'}>
                    {isShowPercentage1 ? `${Math.round(convertToNumber(item.lost.percentage))}%` : item.lost.count}
                  </Td>
                  <Td align={'center'}>
                    {isShowPercentage1
                      ? `${Math.round(convertToNumber(item.duplicate.percentage))}%`
                      : item.duplicate.count}
                  </Td>
                  <Td align={'center'}>
                    {isShowPercentage1 ? `${Math.round(convertToNumber(item.other.percentage))}%` : item.other.count}
                  </Td>
                </Tr>
              ))}
            <Tr>
              <Th align={'left'}>ALL Sources</Th>
              <Th>{tableData?.tableTwo.total.total_leads}</Th>
              <Th>
                {isShowPercentage1
                  ? `${Math.round(
                      (convertToNumber(tableData?.tableTwo.total.no_los) * 100) /
                        convertToNumber(tableData?.tableTwo.total.total_leads),
                    )}%`
                  : tableData?.tableTwo.total.no_los}
              </Th>
              <Th>
                {isShowPercentage1
                  ? `${Math.round(
                      (convertToNumber(tableData?.tableTwo.total.lost) * 100) /
                        convertToNumber(tableData?.tableTwo.total.total_leads),
                    )}%`
                  : tableData?.tableTwo.total.lost}
              </Th>
              <Th>
                {isShowPercentage1
                  ? `${Math.round(
                      (convertToNumber(tableData?.tableTwo.total.duplicate) * 100) /
                        convertToNumber(tableData?.tableTwo.total.total_leads),
                    )}%`
                  : tableData?.tableTwo.total.duplicate}
              </Th>
              <Th>
                {isShowPercentage1
                  ? `${Math.round(
                      (convertToNumber(tableData?.tableTwo.total.other) * 100) /
                        convertToNumber(tableData?.tableTwo.total.total_leads),
                    )}%`
                  : tableData?.tableTwo.total.other}
              </Th>
            </Tr>
          </Tbody>
        </ReportTable>

        <div className={'data-toggle-view'}>
          <span onClick={() => setIsShowPercentage2(!isShowPercentage2)}>Toggle %</span>
        </div>
        <ReportTable>
          <Tbody>
            <Tr>
              <Th align={'left'}>Source</Th>
              <Th align={'center'}>Total Lost</Th>
              <Th align={'center'}>Installation Cost To High</Th>
              <Th align={'center'}>Monthly Rate to High</Th>
              <Th align={'center'}>Not Fast Enough</Th>
              <Th align={'center'}>Doesn't See Value</Th>
              <Th align={'center'}>Happy with Current Provider</Th>
              <Th align={'center'}>Unreachable</Th>
              <Th align={'center'}>Chose Different Provider</Th>
              <Th align={'center'}>Other</Th>
            </Tr>
            {tableData &&
              tableData.tableThree.data.map((item: any, index: number) => (
                <Tr key={index}>
                  <Td align={'left'}>{item.source}</Td>
                  <Td align={'center'}>{item.total_lost.count}</Td>
                  <Td align={'center'}>
                    {isShowPercentage2
                      ? `${Math.round(convertToNumber(item.installation_cost_to_high.percentage))}%`
                      : item.installation_cost_to_high.count}
                  </Td>
                  <Td align={'center'}>
                    {isShowPercentage2
                      ? `${Math.round(convertToNumber(item.monthly_rate_to_high.percentage))}%`
                      : item.monthly_rate_to_high.count}
                  </Td>
                  <Td align={'center'}>
                    {isShowPercentage2
                      ? `${Math.round(convertToNumber(item.not_fast_enough.percentage))}%`
                      : item.not_fast_enough.count}
                  </Td>
                  <Td align={'center'}>
                    {isShowPercentage2
                      ? `${Math.round(convertToNumber(item.does_not_see_value.percentage))}%`
                      : item.does_not_see_value.count}
                  </Td>
                  <Td align={'center'}>
                    {isShowPercentage2
                      ? `${Math.round(convertToNumber(item.happy_with_current_provider.percentage))}%`
                      : item.happy_with_current_provider.count}
                  </Td>
                  <Td align={'center'}>
                    {isShowPercentage2
                      ? `${Math.round(convertToNumber(item.unreachable.percentage))}%`
                      : item.unreachable.count}
                  </Td>
                  <Td align={'center'}>
                    {isShowPercentage2
                      ? `${Math.round(convertToNumber(item.chose_different_provider.percentage))}%`
                      : item.chose_different_provider.count}
                  </Td>
                  <Td align={'center'}>
                    {isShowPercentage2 ? `${Math.round(convertToNumber(item.other.percentage))}%` : item.other.count}
                  </Td>
                </Tr>
              ))}
            <Tr>
              <Th align={'left'}>ALL Sources</Th>
              <Th>{tableData?.tableThree.total.total_lost}</Th>
              <Th>
                {isShowPercentage2
                  ? `${Math.round(
                      (convertToNumber(tableData?.tableThree.total.installation_cost_to_high) * 100) /
                        convertToNumber(tableData?.tableThree.total.total_lost),
                    )}%`
                  : tableData?.tableThree.total.installation_cost_to_high}
              </Th>
              <Th>
                {isShowPercentage2
                  ? `${Math.round(
                      (convertToNumber(tableData?.tableThree.total.monthly_rate_to_high) * 100) /
                        convertToNumber(tableData?.tableThree.total.total_lost),
                    )}%`
                  : tableData?.tableThree.total.monthly_rate_to_high}
              </Th>
              <Th>
                {isShowPercentage2
                  ? `${Math.round(
                      (convertToNumber(tableData?.tableThree.total.not_fast_enough) * 100) /
                        convertToNumber(tableData?.tableThree.total.total_lost),
                    )}%`
                  : tableData?.tableThree.total.not_fast_enough}
              </Th>
              <Th>
                {isShowPercentage2
                  ? `${Math.round(
                      (convertToNumber(tableData?.tableThree.total.does_not_see_value) * 100) /
                        convertToNumber(tableData?.tableThree.total.total_lost),
                    )}%`
                  : tableData?.tableThree.total.does_not_see_value}
              </Th>
              <Th>
                {isShowPercentage2
                  ? `${Math.round(
                      (convertToNumber(tableData?.tableThree.total.happy_with_current_provider) * 100) /
                        convertToNumber(tableData?.tableThree.total.total_lost),
                    )}%`
                  : tableData?.tableThree.total.happy_with_current_provider}
              </Th>
              <Th>
                {isShowPercentage2
                  ? `${Math.round(
                      (convertToNumber(tableData?.tableThree.total.unreachable) * 100) /
                        convertToNumber(tableData?.tableThree.total.total_lost),
                    )}%`
                  : tableData?.tableThree.total.unreachable}
              </Th>
              <Th>
                {isShowPercentage2
                  ? `${Math.round(
                      (convertToNumber(tableData?.tableThree.total.chose_different_provider) * 100) /
                        convertToNumber(tableData?.tableThree.total.total_lost),
                    )}%`
                  : tableData?.tableThree.total.chose_different_provider}
              </Th>
              <Th>
                {isShowPercentage2
                  ? `${Math.round(
                      (convertToNumber(tableData?.tableThree.total.other) * 100) /
                        convertToNumber(tableData?.tableThree.total.total_lost),
                    )}%`
                  : tableData?.tableThree.total.other}
              </Th>
            </Tr>
          </Tbody>
        </ReportTable>
      </ContentWrapper>
    </>
  );
};

export { LeadToClientLostVsWon };
