import { css } from '@emotion/css';

export const useContainerStyles = () => {
  return {
    root: css`
      display: flex;
      flex-grow: 1;
      overflow: auto;
      //margin-bottom: 60px;
      ::-webkit-scrollbar {
        width: 5px;
      }
      ::-webkit-scrollbar-track {
        background-color: #000000;
        -webkit-border-radius: 0px;
        border-radius: 0px;
      }
      ::-webkit-scrollbar-thumb {
        border-radius: 0px;
        background: #ddd;
      }
      .block-item {
        width: 100%;
      }
    `,

    container: css`
      .container-default {
        max-width: 100%;
        min-height: 200px;
        margin: auto;
        padding-bottom: 5px;
      }
      .sm {
        width: 650px;
      }
      .md {
        width: 960px;
      }
      .lg {
        width: 1300px;
      }
      .xxl {
        padding: 0px 20px;
        max-width: 1920px;
      }
    `,
  };
};
