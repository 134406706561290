import React from 'react';
import classnames from 'classnames';
import { useGeneralTabStyles } from '@/static/stylesheets/organisms/generalTabStyles';

interface TabItem<TabItemValue> {
  label: string;
  value: TabItemValue;
}

interface TabsProps<TabItemValue> {
  items: TabItem<TabItemValue>[];
  activeTab: TabItemValue;
  handleActiveTab: (tabValue: TabItemValue) => void;
}

function Tabs<TabItemValue>({ items, activeTab, handleActiveTab }: TabsProps<TabItemValue>): JSX.Element {
  const classes = useGeneralTabStyles(items?.length ?? 0);

  return (
    <ul className={classes.tabItems}>
      {items &&
        items.length &&
        items?.map((menu, index) => (
          <li key={index} className={classes.tabItem} onClick={() => handleActiveTab(menu.value)}>
            <span
              className={classnames({
                [classes.tabText]: true,
                [classes.active]: activeTab === menu.value,
              })}
            >
              {menu?.label}
            </span>
          </li>
        ))}
    </ul>
  );
}

export default Tabs;
