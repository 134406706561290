import React from 'react';
import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useButtonStyles } from '@/static/stylesheets/atoms';

interface CloseButtonProps {}
export const CloseButton: React.FC<CloseButtonProps> = (props) => {
  const classes = useButtonStyles();

  return (
    <Box className={classes.CloseButton}>
      <CloseIcon />
    </Box>
  );
};
