import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useHeaderStyles } from '@/static/stylesheets/molecules/headerStyles';
import { Container, Grid } from '@mui/material';
import { Button } from '@/components/atoms/Button';
import RefreshIcon from '@mui/icons-material/Refresh';
import { MultiSelect } from '@/components/atoms/MultiSelect';
import {
  sendLeadFilter,
  clearSendLeadFilter,
  getSourceItemList,
  getLeads,
  getClients,
  sendClientFilter,
  clearSendClientFilter,
  getJobTypesAction,
  clearSendJobFilter,
  getJobs,
  sendJobFilter,
} from '@/redux/actions';
import { UserParams, JobProgressItemParams, FeatureType, JobTypeParams } from '@/types';
import toast from 'react-hot-toast';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { useAppSelector } from '@/hooks';
import { clientFilterSortOptions, jobFilterSortOptions, leadFilterSortOptions } from '@/config/constants';
import { toggleLostLeads } from '@/redux/actions';
import { selectUiState } from '@/redux/reducers/uiSlice';
import { getFilterStaffMembers } from '@/redux/actions/httpActions/commonActions';
import { useJobListType } from '@/hooks/custom';
import { timezone } from '@/utils/timeZone';

interface FilterProps {
  isFilterOpen?: boolean;
  featureType?: FeatureType;

  onClose?(): void;
}

interface SortProps {
  value?: number;
  column_name?: string;
  sort_direction?: string;
  label?: string;
}

const Filter: React.FC<FilterProps> = (props) => {
  const classes = useHeaderStyles();

  const staffMemberList = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_FILTER_STAFF_MEMBER_LIST];
  const staffMembers: UserParams[] = staffMemberList?.data?.data ?? [];
  const jobProgressBarList = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_PROGRESS_LIST];
  const progressBarList: JobProgressItemParams[] = jobProgressBarList?.data?.data ?? [];
  const sourceItemList = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_SOURCE_LIST];
  const jobTypeList = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_JOB_TYPES];
  const jobTypeItems: JobTypeParams[] = jobTypeList?.data?.data ?? [];

  const sourceList = sourceItemList?.data?.data ?? [];

  const filterSortOptions =
    props?.featureType === 'leads'
      ? leadFilterSortOptions
      : props?.featureType === 'clients'
        ? clientFilterSortOptions
        : props?.featureType === 'jobs'
          ? jobFilterSortOptions
          : [];

  const [status, setStatus] = useState<string[]>([]);
  const [members, setMembers] = useState<string[]>([]);
  const [progressBar, setProgressBar] = useState<string[]>([]);
  const [jobTypes, setJobTypes] = useState<string[]>([]);
  const [source, setSourceList] = useState<string[]>([]);
  const [sortList, setSortList] = useState<SortProps[]>([]);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const { showLostLeads, showDeactivatedClients } = useAppSelector(selectUiState);
  const { currentSelected: jobListType } = useJobListType();

  const getSourceList = () => getSourceItemList();

  const getJobTypes = () => getJobTypesAction();

  const getStaffMemberList = () =>
    getFilterStaffMembers({
      limit: 'all',
      page: 1,
    });

  const getLeadList = () =>
    getLeads({
      limit: 10,
      page: 1,
      type: showLostLeads ? 'lostLead' : 'lead',
    });

  const getClientList = () =>
    getClients({
      limit: 10,
      page: 1,
      type: showDeactivatedClients ? 'deactivatedClient' : 'clients',
    });

  const getJobList = () =>
    getJobs({
      limit: 10,
      page: 1,
      type: jobListType,
    });

  const resetFields = () => {
    setStatus(() => []);
    setMembers(() => []);
    setSortList(() => []);
  };

  const resetFilter = () => {
    setStatus(() => []);
    setMembers(() => []);
    setSortList(() => []);
    setJobTypes(() => []);
    setProgressBar(() => []);
    setSourceList(() => []);
    toggleLostLeads(false);

    if (props?.featureType === 'leads') {
      // clearSendLeadFilter();
      getLeadList();
    } else if (props?.featureType === 'clients') {
      // clearSendClientFilter();
      getClientList();
    } else if (props?.featureType === 'jobs') {
      // clearSendJobFilter();
      getJobList();
    }
  };

  const onSubmit = () => {
    if (
      status?.length === 0 &&
      members?.length === 0 &&
      sortList?.length === 0 &&
      progressBar?.length === 0 &&
      jobTypes?.length === 0 &&
      source?.length === 0
    ) {
      toast.error('Please at least one field selected!');
      return;
    }

    const statusData = status.map((i) => parseInt(i) - 1);
    const sorted = [...sortList];

    sorted.forEach((item) => {
      delete item.value;
    });

    const data: any = {
      current_status: progressBar[0] ?? undefined,
      source: source[0] ?? undefined,
      job_type: jobTypes[0] ?? undefined,
      customer_type:
        props?.featureType === 'leads'
          ? showLostLeads
            ? 'Lost Lead'
            : 'Lead'
          : props?.featureType === 'clients'
            ? showDeactivatedClients
              ? 'Deactivated Client'
              : 'Clients'
            : undefined,
      timezone,
    };

    if (sorted?.length > 0) {
      if (props?.featureType === 'jobs') {
        data.sortBy = sorted[0] ?? undefined;
      } else {
        data.sort = sorted;
      }
    }

    if (statusData.length > 0) {
      if (props?.featureType === 'jobs') {
        data.status = statusData;
      } else {
        data.lead_status = statusData;
      }
    }

    if (members.length > 0) {
      data.assigned_to = members;
    }

    if (props?.featureType === 'leads') {
      sendLeadFilter(data);
    } else if (props?.featureType === 'clients') {
      sendClientFilter(data);
    } else if (props?.featureType === 'jobs') {
      sendJobFilter(data);
    }
    setIsSubmit(true);
    if (props?.onClose) {
      props?.onClose();
    }
  };

  useEffect(() => {
    resetFields();
    getSourceList();
    getStaffMemberList();
    getJobTypes();
  }, []);

  return (
    <>
      <Box className={classes.filterBody}>
        <Container>
          <Grid container spacing={2}>
            {(props?.featureType === 'leads' || props?.featureType === 'jobs') && (
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <div className={'multiSelect'}>
                  <MultiSelect
                    options={[
                      { label: 'On Track', value: 1, sign: 'green' },
                      { label: 'Needs Attention', value: 2, sign: 'yellow' },
                      { label: 'WTF?! Help', value: 3, sign: 'red' },
                    ]}
                    type='indicator'
                    value={status}
                    onChange={(status) => setStatus(() => status)}
                    fullWidth={true}
                    label={props?.featureType === 'leads' ? 'Lead Status' : 'Status'}
                    multiple={true}
                  />
                </div>
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <div className={'multiSelect'}>
                <MultiSelect
                  options={
                    staffMembers && staffMembers?.length > 0
                      ? staffMembers?.map((item: any) => ({
                          value: item?.id,
                          label: `${item?.first_name ?? ''} ${item?.last_name ?? ''}`,
                        }))
                      : []
                  }
                  value={members}
                  onChange={(item) => setMembers(() => item)}
                  fullWidth={true}
                  label={'Assigned To'}
                  multiple={true}
                />
              </div>
            </Grid>
            {(props?.featureType === 'leads' || props?.featureType === 'jobs') && (
              <Grid item xs={12} sm={12} md={12} lg={4}>
                <div className={'multiSelect'}>
                  <MultiSelect
                    options={
                      progressBarList && progressBarList?.length > 0
                        ? progressBarList?.map((item: any) => ({
                            value: item?.id,
                            label: item?.progress_name,
                          }))
                        : []
                    }
                    value={progressBar}
                    onChange={(item) => setProgressBar(() => item)}
                    fullWidth={true}
                    label={'Current Status'}
                    type={'radio'}
                    multiple={false}
                  />
                </div>
              </Grid>
            )}
            {props?.featureType === 'jobs' && (
              <Grid item xs={12} sm={12} md={12} lg={4}>
                <div className={'multiSelect'}>
                  <MultiSelect
                    options={
                      jobTypeItems && jobTypeItems?.length > 0
                        ? jobTypeItems?.map((item) => ({
                            value: item?.id,
                            label: item?.name ?? '',
                          }))
                        : []
                    }
                    value={jobTypes}
                    onChange={(item) => setJobTypes(() => item)}
                    fullWidth={true}
                    label={'Type of Job'}
                    type={'radio'}
                    multiple={false}
                  />
                </div>
              </Grid>
            )}
            {props?.featureType !== 'jobs' && (
              <Grid item xs={12} sm={12} md={12} lg={props?.featureType === 'clients' ? 6 : 4}>
                <div className={'multiSelect'}>
                  <MultiSelect
                    options={
                      sourceList && sourceList?.length > 0
                        ? sourceList?.map((item: any) => ({
                            value: item?.id,
                            label: item?.source_name,
                          }))
                        : []
                    }
                    value={source}
                    onChange={(item) => setSourceList(() => item)}
                    fullWidth={true}
                    label={'Source'}
                    type={'radio'}
                    multiple={false}
                  />
                </div>
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={12} lg={props?.featureType === 'clients' ? 12 : 4}>
              <div className={'multiSelect'}>
                <MultiSelect
                  options={
                    filterSortOptions && filterSortOptions?.length > 0
                      ? filterSortOptions?.map((item: any) => ({
                          value: item,
                          label: item?.label,
                        }))
                      : []
                  }
                  value={sortList}
                  onChange={(item) => setSortList(item)}
                  fullWidth={true}
                  label={'Sort'}
                  type={'radio'}
                  multiple={false}
                />
              </div>
            </Grid>
          </Grid>
          <div className={'button'}>
            <Button title={'Apply Filters'} fullWidth={true} onClick={onSubmit} />
            <button onClick={resetFilter}>
              <RefreshIcon />
            </button>
          </div>
        </Container>
      </Box>
    </>
  );
};

export { Filter };
