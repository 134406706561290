import React, { useEffect } from 'react';
import { Box, Dialog, DialogContent, Grid } from '@mui/material';
import { useTaskStyles } from '@/static/stylesheets/molecules/Tasks';
import { DialogHeader } from './DialogHeader';
import { TaskParameters } from '@/components/molecules/Tasks/TaskViewDialog/TaskParameters';
import { ActiveTasks } from '@/components/molecules/Tasks/TaskViewDialog/ActiveTasks';
import { CompletedTasks } from '@/components/molecules/Tasks/TaskViewDialog/CompletedTasks';
import { ActivityLog } from '@/components/molecules/Tasks/TaskViewDialog/ActivityLog';
import { SubTasksForm } from '@/components/molecules/Tasks/TaskViewDialog/SubTasksForm';
import { ViewFiles } from '@/components/molecules/Tasks/TaskViewDialog/ViewFiles';
import { FileUploadResponse, TaskItem } from '@/types';
import {
  clearDeletedTask,
  clearUpdatedTaskResponse,
  deleteTask,
  getSingleTask,
  getTaskStaffMembers,
  setTaskEditFormData,
} from '@/redux/actions';
import { useAppSelector, useComponentState } from '@/hooks';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { getTaskPriorityIndex } from '@/utils';
import toast from 'react-hot-toast';
import { alertMessages } from '@/config';
import { NoSubTaskSelected } from './NoSubTaskSelected';

interface TaskViewDialogProps {
  open: boolean;

  onClose?(options?: { reloadList?: boolean }): void;

  taskId?: TaskItem['id'] | null;
}

export interface ChildTaskUpdateActionProps {
  index: number;
  payload: {
    [key in keyof Partial<TaskItem>]: any;
  };
}

const TaskViewDialog: React.FC<TaskViewDialogProps> = (props) => {
  const classes = useTaskStyles();
  const taskId = props?.taskId;

  const taskDeleteResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.DELETE_SUB_TASK_FROM_POPUP];
  const singleTaskResponse = (useAppSelector(selectHttpState) as any)[
    `${CORRELATION_IDS.GET_SINGLE_TASK}_${taskId}_view`
  ];
  const taskUpdateResponse = (useAppSelector(selectHttpState) as any)[CORRELATION_IDS.UPDATE_TASK];

  const {
    state: {
      filesView,
      reloadListOnClose,
      singleTaskData,
      activeTasks,
      completedTasks,
      selectedActiveTaskIndexForEdit,
      selectedCompletedTaskIndexForEdit,
      selectedActiveTaskData,
      selectedCompletedTaskData,
      selectedTaskType,
      deleteTaskId,
    },
    setState,
  } = useComponentState<{
    filesView: boolean;
    reloadListOnClose: boolean;
    singleTaskData: TaskItem | null;
    activeTasks: TaskItem[];
    completedTasks: TaskItem[];
    selectedActiveTaskIndexForEdit: number | null;
    selectedCompletedTaskIndexForEdit: number | null;
    selectedActiveTaskData: TaskItem | null;
    selectedCompletedTaskData: TaskItem | null;
    selectedTaskType: 'active' | 'completed' | null;
    deleteTaskId: string | null;
  }>({
    filesView: false,
    reloadListOnClose: false,
    singleTaskData: null,
    activeTasks: [],
    completedTasks: [],
    selectedActiveTaskIndexForEdit: null,
    selectedCompletedTaskIndexForEdit: null,
    selectedActiveTaskData: null,
    selectedCompletedTaskData: null,
    selectedTaskType: null,
    deleteTaskId: null,
  });

  const handleClose = () => {
    if (props?.onClose) {
      props.onClose({
        reloadList: reloadListOnClose,
      });
      setReloadListOnClose(false);
    }
  };

  const setReloadListOnClose = (reload: boolean = true) => {
    setState({
      reloadListOnClose: reload,
    });
  };

  const getStaffMemberList = () =>
    getTaskStaffMembers({
      limit: 'all',
      page: 1,
    });

  const getTaskDetail = () => {
    if (taskId) {
      getSingleTask(taskId, '_view');
    }
  };

  const updateParentTaskData = (data: {
    [key in keyof TaskItem]: any;
  }) => {
    setState({
      singleTaskData: {
        ...singleTaskData,
        ...data,
      },
    });
  };

  const selectActiveTask = (index: number | null) =>
    setState({
      selectedActiveTaskIndexForEdit: index,
      selectedTaskType: 'active',
    });

  const selectCompletedTask = (index: number | null) =>
    setState({
      selectedCompletedTaskIndexForEdit: index,
      selectedTaskType: 'completed',
    });

  const addActiveTask = (taskData: TaskItem) => {
    let newActiveTasks = [...activeTasks];
    newActiveTasks.push(taskData);
    setState({ activeTasks: newActiveTasks });
  };

  const updateActiveTask = (action: ChildTaskUpdateActionProps) => {
    let newActiveTasks = [...activeTasks];

    newActiveTasks[action.index] = {
      ...newActiveTasks[action.index],
      ...action.payload,
    };

    setState({ activeTasks: newActiveTasks });
  };

  const deleteActiveTask = (index: number) => {
    let newActiveTasks = [...activeTasks];
    console.log('newActiveTasks', newActiveTasks);
    const taskItem = newActiveTasks[index];
    if (taskItem?.id && taskItem?.id !== '') {
      setState({ deleteTaskId: taskItem?.id });
      deleteTask(taskItem?.id, true);
    }

    newActiveTasks.splice(index, 1);
    setState({ activeTasks: newActiveTasks });
  };

  const updateCompletedTask = (action: ChildTaskUpdateActionProps) => {
    const newCompletedTasks = [...completedTasks];

    newCompletedTasks[action.index] = {
      ...newCompletedTasks[action.index],
      ...action.payload,
    };

    setState({ completedTasks: newCompletedTasks });
  };

  const getAllFiles = () => {
    const files: FileUploadResponse = [];
    const tasks = [...activeTasks, ...completedTasks];

    tasks?.forEach((task) => {
      task?.files?.forEach((file) => {
        files?.push(file);
      });
    });

    return files;
  };

  const fileList = getAllFiles();

  useEffect(() => {
    const taskTypes: string[] = [];
    singleTaskData?.task_types?.forEach((taskType) => {
      if (taskType?.id) {
        taskTypes.push(taskType?.id);
      }
    });

    setTaskEditFormData({
      priority: getTaskPriorityIndex(singleTaskData?.priority),
      dueDate: singleTaskData?.due_datetime?.date,
      dueTime: singleTaskData?.due_datetime?.timestamp,
      assignedTo: singleTaskData?.assigned_to?.id,
      taskTypes: taskTypes,
    });
  }, [singleTaskData]);

  useEffect(() => {
    setTaskEditFormData({
      childTasks: [...activeTasks, ...completedTasks],
    });
  }, [activeTasks, completedTasks]);

  useEffect(() => {
    if (selectedActiveTaskIndexForEdit !== null) {
      setState({
        selectedActiveTaskData: activeTasks[selectedActiveTaskIndexForEdit],
      });
    }
  }, [selectedActiveTaskIndexForEdit]);

  useEffect(() => {
    if (selectedCompletedTaskIndexForEdit !== null) {
      setState({
        selectedCompletedTaskData: completedTasks[selectedCompletedTaskIndexForEdit],
      });
    }
  }, [selectedCompletedTaskIndexForEdit]);

  useEffect(() => {
    if (taskUpdateResponse?.success && taskUpdateResponse.payload?.id === singleTaskData?.id) {
      if (taskUpdateResponse?.data) {
        setState({
          singleTaskData: taskUpdateResponse?.data,
        });
      }
      clearUpdatedTaskResponse();
      toast.success(alertMessages.tasks.taskUpdateSuccess);
    }

    if (taskUpdateResponse?.error && taskUpdateResponse.payload?.id === singleTaskData?.id) {
      clearUpdatedTaskResponse();
      toast.error(alertMessages.tasks.taskUpdateError);
    }
  }, [taskUpdateResponse]);

  useEffect(() => {
    if (deleteTaskId && taskDeleteResponse?.success) {
      setState({ deleteTaskId: null });
      toast.success(alertMessages.tasks.taskDeleteSuccess);
      clearDeletedTask();
    }

    if (deleteTaskId && taskDeleteResponse?.error) {
      setState({ deleteTaskId: null });
      toast.error(alertMessages.tasks.taskDeleteError);
      clearDeletedTask();
    }
  }, [taskDeleteResponse, deleteTaskId]);

  useEffect(() => {
    const activeTasks = [...(singleTaskData?.child_tasks ?? []).filter((task) => task?.is_complete === false)];
    const completedTasks = [...(singleTaskData?.child_tasks ?? []).filter((task) => task?.is_complete === true)];

    setState({ activeTasks, completedTasks: completedTasks });
  }, [singleTaskData]);

  useEffect(() => {
    setState({
      singleTaskData: singleTaskResponse?.data,
    });
  }, [singleTaskResponse?.data]);

  useEffect(() => {
    if (props?.open) {
      getStaffMemberList();
      getTaskDetail();
    } else {
      setState({
        filesView: false,
        selectedActiveTaskIndexForEdit: -1,
        selectedCompletedTaskIndexForEdit: -1,
      });
    }
  }, [taskId, props?.open]);

  return (
    <>
      <Dialog
        open={props?.open}
        maxWidth={'xl'}
        onClose={handleClose}
        sx={{
          '& .MuiPaper-root': {
            width: '100vw',
            maxWidth: '100vw',
            margin: '5px 4px',
            maxHeight: '99%',
          },
        }}
        className={classes.viewTaskDialog}
      >
        {props?.open && (
          <>
            <DialogHeader
              loading={singleTaskResponse?.loading}
              filesView={filesView}
              onClickToggleFilesView={() => setState({ filesView: !filesView })}
              taskData={singleTaskData}
              updateParentTaskData={updateParentTaskData}
              onActionsDone={setReloadListOnClose}
              handleClose={handleClose}
            />

            <DialogContent className={'dialogContent'}>
              <TaskParameters
                taskData={singleTaskData}
                onChangePriority={setReloadListOnClose}
                reloadData={getTaskDetail}
              />
              <Box sx={{ marginTop: '15px' }}>
                {!filesView ? (
                  <Grid container spacing={4}>
                    <Grid item lg={6}>
                      <ActiveTasks
                        loading={singleTaskResponse?.loading}
                        tasks={activeTasks}
                        selectedActiveTaskIndexForEdit={selectedActiveTaskIndexForEdit}
                        addData={addActiveTask}
                        updateData={updateActiveTask}
                        deleteData={deleteActiveTask}
                        onSelectActiveTask={selectActiveTask}
                      />
                      <CompletedTasks
                        loading={singleTaskResponse?.loading}
                        tasks={completedTasks}
                        selectedCompletedTaskIndexForEdit={selectedCompletedTaskIndexForEdit}
                        updateData={updateCompletedTask}
                        onSelectCompletedTask={selectCompletedTask}
                      />
                      <ActivityLog
                        loading={singleTaskResponse?.loading}
                        activityLogs={singleTaskData?.task_activity_logs}
                      />
                    </Grid>
                    <Grid item lg={6} style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                      {selectedTaskType === 'active' && (
                        <>
                          {selectedActiveTaskIndexForEdit !== null && selectedActiveTaskData ? (
                            <SubTasksForm
                              selectedTaskIndexForEdit={selectedActiveTaskIndexForEdit}
                              taskData={selectedActiveTaskData}
                              updateData={updateActiveTask}
                            />
                          ) : (
                            <NoSubTaskSelected />
                          )}
                        </>
                      )}

                      {selectedTaskType === 'completed' && (
                        <>
                          {selectedCompletedTaskIndexForEdit !== null && selectedCompletedTaskData ? (
                            <SubTasksForm
                              selectedTaskIndexForEdit={selectedCompletedTaskIndexForEdit}
                              taskData={selectedCompletedTaskData}
                              updateData={updateCompletedTask}
                            />
                          ) : (
                            <NoSubTaskSelected />
                          )}
                        </>
                      )}
                    </Grid>
                  </Grid>
                ) : (
                  <ViewFiles files={fileList} />
                )}
              </Box>
            </DialogContent>
          </>
        )}
      </Dialog>
    </>
  );
};

export { TaskViewDialog };
