import React from 'react';
import { useLeadExpandBodyStyle } from '@/static/stylesheets/organisms';
import { Grid } from '@mui/material';
import { TextInput } from '@/components/atoms/TextInput';
import { Button } from '@/components/atoms/Button';

interface IpInfoProps {
  lostLead?: boolean;
  formState: {
    bindConnect?: any;
    connectError?: any;

    bindPrivateIP?: any;
    privateIPError?: any;

    bindPrivateCIDR?: any;
    privateCIDRerror?: any;

    bindPrivateGateway?: any;
    privateGatewayError?: any;

    bindPublicIP?: any;
    publicIPError?: any;

    bindPublicCIDR?: any;
    publicCIDRerror?: any;
  };
}

const IpInfo: React.FC<IpInfoProps> = (props) => {
  const classes = useLeadExpandBodyStyle();
  const {
    lostLead,
    formState: {
      bindConnect,
      connectError,

      bindPrivateIP,
      privateIPError,

      bindPrivateCIDR,
      privateCIDRerror,

      bindPrivateGateway,
      privateGatewayError,

      bindPublicIP,
      publicIPError,

      bindPublicCIDR,
      publicCIDRerror,
    },
  } = props;
  const openInNewTabPrivateIP = () => {
    if (bindPrivateIP?.value) {
      window.open(`https://` + bindPrivateIP?.value + `:9999`, '_blank', 'noopener,noreferrer');
    }
  };

  const openInNewTabPublicIP = () => {
    if (bindPublicIP) {
      window.open(`https://` + bindPublicIP?.value + `:9999`, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <>
      <div className={classes.root}>
        <div className={'list_body'}>
          <Grid container spacing={2}>
            <Grid item sm={12} md={12} lg={12}>
              <TextInput
                variant={'standard'}
                placeholder={'AP Connecting To'}
                centerMode={true}
                fullWidth={true}
                {...bindConnect}
                error={connectError?.error}
                disabled={lostLead}
              />
            </Grid>
            <Grid item sm={8} md={8} lg={8}>
              <TextInput
                variant={'standard'}
                placeholder={'Private IP'}
                centerMode={true}
                fullWidth={true}
                {...bindPrivateIP}
                error={privateIPError?.error}
                errorText={privateIPError?.message}
                disabled={lostLead}
              />
            </Grid>
            <Grid item sm={4} md={4} lg={4}>
              <TextInput
                variant={'standard'}
                placeholder={'CIDR'}
                centerMode={true}
                fullWidth={true}
                {...bindPrivateCIDR}
                error={privateCIDRerror?.error}
                errorText={privateCIDRerror?.message}
                startAdornment={<>/</>}
                disabled={lostLead}
              />
            </Grid>
            <Grid item sm={12} md={12} lg={12} sx={{ pb: 1.5 }}>
              <TextInput
                variant={'standard'}
                placeholder={'Private Gateway'}
                centerMode={true}
                fullWidth={true}
                {...bindPrivateGateway}
                error={privateGatewayError?.error}
                errorText={privateGatewayError?.message}
                disabled={lostLead}
              />
            </Grid>
            <Grid item className={'button'} sm={12} md={12} lg={12} style={{ marginBottom: '10px' }}>
              <Button
                variant={'contained'}
                title={'Go To Private IP'}
                fullWidth={true}
                onClick={openInNewTabPrivateIP}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item sm={8} md={8} lg={8}>
              <TextInput
                variant={'standard'}
                placeholder={'Public IP'}
                centerMode={true}
                fullWidth={true}
                {...bindPublicIP}
                error={publicIPError?.error}
                errorText={publicIPError?.message}
                disabled={lostLead}
              />
            </Grid>
            <Grid item sm={4} md={4} lg={4}>
              <TextInput
                variant={'standard'}
                placeholder={'CIDR'}
                centerMode={true}
                fullWidth={true}
                {...bindPublicCIDR}
                error={publicCIDRerror?.error}
                errorText={publicCIDRerror?.message}
                startAdornment={<>/</>}
                disabled={lostLead}
              />
            </Grid>
            <Grid item className={'button'} sm={12} md={12} lg={12} sx={{ mt: 1.5 }}>
              <Button variant={'contained'} title={'Go To Public IP'} fullWidth={true} onClick={openInNewTabPublicIP} />
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export { IpInfo };
