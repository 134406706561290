import { css } from '@emotion/css';
import { colors, themeParser, useAppTheme } from '@/theme';

export const useEquipmentsStyles = () => {
  const theme = useAppTheme();
  return {
    root: css`
      table {
        thead tr th {
          color: ${colors.primary} !important;
          font-weight: 500 !important;
          white-space: nowrap;
        }
      }
      ,
      .searchable_input {
        overflow: hidden;
      }
      .mx-width {
        @media (min-width: 1200px) {
          max-width: 1300px;
        }
      }
    `,

    tableRowCell: css`
      //font-size: 15px !important;
      font-weight: 500 !important;
    `,
  };
};
