import React, { useEffect, useState } from 'react';
import { useFloatingActionButtonStyles } from '@/static/stylesheets/atoms';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import BuildIcon from '@mui/icons-material/Build';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { useNavigate } from 'react-router-dom';
import { routePaths } from '@/routes';
import AppsIcon from '@mui/icons-material/Apps';
import { ThemeState, withAppTheme } from '@/theme';
import { AuthService } from '@/services/auth';
import { clearLoggedOut, logout, storeLoginData } from '@/redux/actions';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { useAppSelector } from '@/hooks';
import toast from 'react-hot-toast';

interface FloatingActionButtonProps {
  value?: any;
  setAppTheme: ThemeState['setAppTheme'];
  appTheme: ThemeState['appTheme'];
}

interface ItemAction {
  icon: string | React.ReactNode;
  title: string;

  onClick(): void;
}

const FloatingActionButton: React.FC<FloatingActionButtonProps> = (props) => {
  const classes = useFloatingActionButtonStyles();
  const navigate = useNavigate();
  const { setAppTheme, appTheme } = props;
  const [open, setOpen] = useState(false);

  const loggedOut = useAppSelector(selectHttpState)[CORRELATION_IDS.LOGOUT];

  const HandleSpeedDail = () => {
    setOpen(!open);
  };
  const handleClickToggleTheme = () => {
    if (setAppTheme) {
      setAppTheme(appTheme === 'light' ? 'dark' : 'light');
    }
  };

  const tryLogout = () => {
    logout();
  };

  const actions: ItemAction[] = [
    // {
    //   icon: <BuildIcon className={classes.buildIcon} />,
    //   title: 'Equipment',
    //   onClick() {
    //   },
    // },
    {
      icon: <PersonIcon className={classes.buildIcon} />,
      title: 'Profile',
      onClick() {
        navigate(routePaths.profile);
      },
    },
    {
      icon: <SettingsIcon className={classes.personIcon} />,
      title: 'Settings',
      onClick() {
        navigate(routePaths?.settings?.generalSettings);
      },
    },
    // {
    //   icon: <AccountBalanceIcon className={classes.accountBalanceIcon} />,
    //   title: 'Account',
    //   onClick() {
    //   },
    // },
    // {
    //   icon: <DarkModeIcon className={classes.themeIcon} />,
    //   title: 'Theme',
    //   onClick() {
    //     handleClickToggleTheme();
    //   },
    // },
    {
      icon: <ExitToAppIcon className={classes.settingsIcon} />,
      title: 'Logout',
      onClick() {
        tryLogout();
      },
    },
  ];

  useEffect(() => {
    if (loggedOut?.success) {
      clearLoggedOut();
      new AuthService().removeToken();
      storeLoginData({
        token: null,
        logged_in: false,
        userData: null,
        shouldReloadUserData: true,
      });
      navigate(routePaths.login);
    }

    if (loggedOut?.error) {
      toast.error('Failed to logout!');
      clearLoggedOut();
    }
  }, [loggedOut]);

  return (
    <>
      <Box className={classes.root}>
        <SpeedDial
          ariaLabel='SpeedDial basic example'
          className={classes.fleatingAction}
          sx={{ position: 'absolute', bottom: 16, right: 16 }}
          icon={<AppsIcon />}
          direction={'up'}
          onClick={HandleSpeedDail}
          open={open}
        >
          {actions.map((item) => (
            <SpeedDialAction key={item?.title} icon={item?.icon} tooltipTitle={item?.title} onClick={item?.onClick} />
          ))}
        </SpeedDial>
      </Box>
    </>
  );
};

const CustomFloatingActionButton = withAppTheme(FloatingActionButton);
export { CustomFloatingActionButton as FloatingActionButton };
