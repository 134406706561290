import { format } from 'date-fns';

export function formatDateTime(date: Date | number, formatString: string) {
  let formatted;
  try {
    formatted = format(date, formatString);
  } catch (e) {
    formatted = '';
  }
  return formatted;
}

export function dateWithTime(date?: string | null, time?: string | null) {
  let dateString = '',
    timeString = '';

  if (date && date !== '') {
    dateString = formatDateTime(new Date(date), 'yyyy-MM-dd');
  }

  if (time) {
    timeString = formatDateTime(new Date(time), 'h:mm a');
  }

  return `${dateString} ${timeString}`;
}
