import { css } from '@emotion/css';
import { colors, themeParser, useAppTheme } from '@/theme';

export const useAPIInformationStyle = () => {
  const theme = useAppTheme();

  return {
    root: css`
      margin: 20px 0px 80px 0px;
      padding: 0px 20px;

      .MuiListItem-root {
        height: 32px;
        font-size: 13px;
        font-weight: 500;
        line-height: 32px;
        padding: 0 12px;
        border-radius: 16px;
        background-color: #3e3e3e;
        margin-bottom: 5px;
        margin-right: 5px;
        border-radius: 3px;
        color: #9e9e9e;
        display: flex;
        justify-content: space-between;
        transition: all 0.1s ease-in-out;

        :hover {
          background: #505050;
          cursor: grab;
        }

        :focus {
          outline: none;
          background-color: #2196f3;
          color: #2a2a2a;
        }

        svg {
          opacity: 0.2;
          font-size: 1.2rem;
        }

        :hover svg {
          opacity: 1;
        }
      }

      h2 {
        text-align: right;
        color: ${themeParser(colors.tabText.light, colors.tabText.dark, theme)} !important;
        @media (max-width: 900px) {
          text-align: center;
        }
      }

      button {
        color: ${themeParser(colors.body.light, colors.body.dark, theme)};
        margin-top: 20px;
      }

      .MuiInput-root::after {
        border-bottom: 1px solid !important;
      }

      .MuiGrid-item {
        position: relative;

        textarea {
          padding: 10px 0px 10px 5px;
          color: ${colors.primary};
          font-size: 16px;
        }

        @media (max-width: 900px) {
          text-align: center;
          display: block;
        }
      }
    `,
    cardHeader: css`
      color: #2196f3;
      border-bottom: 1px solid #2196f3;
      padding-bottom: 10px;
      margin: 80px 0px;
      display: flex;
      justify-content: space-between;
    `,
    textAria: css`
      width: 100%;
      background: transparent;
    `,
    container: css`
      margin-left: -80px !important;
      align-items: end;
      @media (max-width: 900px) {
        margin-left: -16px !important;
        display: block !important;
      }
    `,
    success: css`
      color: green;
    `,
    marginTop: css`
      margin-top: 25px !important;
    `,
    error: css`
      color: red;
    `,

    errorText: css`
      color: red;
      font-size: 13px;
    `,
  };
};
