import React, { useEffect } from 'react';
import { FeatureItemList } from '@/components/organisms/FeatureItemList';
import {
  clearDeletedApiKeyItem,
  clearSavedAPiKey,
  deleteAPiKeyItem,
  deleteSourceItem,
  getApiKeyList,
  openConfirmationDialog,
  saveApiKeyItem,
  saveSourceItem,
} from '@/redux/actions';
import { useAppSelector } from '@/hooks';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import toast from 'react-hot-toast';
import { alertMessages } from '@/config';
import { Button } from '@/components/atoms/Button';
import { Box } from '@mui/material';

const ApiKeyList = () => {
  const apiKeyItemList = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_API_KEY_LIST];
  const savedApiKeyItem = useAppSelector(selectHttpState)[CORRELATION_IDS.SAVE_API_KEY_ITEM];
  const deletedApiKeyItem = useAppSelector(selectHttpState)[CORRELATION_IDS.DELETE_API_KEY_ITEM];
  const itemList = apiKeyItemList?.data?.data ?? [];

  const fetchItems = () => getApiKeyList();

  const createItem = (name: string) => saveApiKeyItem();

  const deleteItem = (id: string) => {
    openConfirmationDialog({
      title: 'Delete',
      message: alertMessages.generalSettings.apiKeyList.deleteConfirmation,
      textCenter: 'center',
      type: 'error',
      onClickConfirm() {
        deleteAPiKeyItem(id);
      },
    });
  };

  useEffect(() => {
    if (savedApiKeyItem?.success) {
      clearSavedAPiKey();
      fetchItems();
      toast.success(alertMessages.generalSettings.apiKeyList.saveSuccess);
    }

    if (savedApiKeyItem?.error) {
      clearSavedAPiKey();
      toast.error(alertMessages.generalSettings.apiKeyList.saveError);
    }
  }, [savedApiKeyItem]);

  useEffect(() => {
    if (deletedApiKeyItem?.success) {
      clearDeletedApiKeyItem();
      fetchItems();
      toast.success(alertMessages.generalSettings.apiKeyList.deleteSuccess);
    }

    if (deletedApiKeyItem?.error) {
      clearDeletedApiKeyItem();
      toast.error(alertMessages.generalSettings.apiKeyList.deleteError);
    }
  }, [deletedApiKeyItem]);

  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <>
      <FeatureItemList
        title='API Key List'
        items={itemList?.map((item: any) => ({
          title: item?.api_key,
          onClickRemove() {
            deleteItem(item?.id);
          },
        }))}
        loading={apiKeyItemList?.loading || savedApiKeyItem?.loading || deletedApiKeyItem?.loading}
        placeholder={'Enter source name'}
        hasCopyButton={true}
        noDelete={false}
        noInput={true}
      />

      <Box
        sx={{
          padding: '10px',
          marginBottom: '10px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button title={'Create'} onClick={createItem} />
      </Box>
    </>
  );
};

export { ApiKeyList };
