import React from 'react';
import { useLeadStyles } from '@/static/stylesheets/organisms/Leads/leadStyles';
import classnames from 'classnames';
import { TextInput } from '@/components/atoms/TextInput';
import EditIcon from '@mui/icons-material/Edit';
import { CustomerProfileParams, UserParams } from '@/types';
import { useAppSelector } from '@/hooks';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { Loader } from '@/components/atoms/Loader';
import { ToggleSaveButtonEnabled } from '@/components/molecules/Leads/SingleLead';
import { formatPhoneNumber } from '@/utils';
import { Controller, useFormContext } from 'react-hook-form';
import { SingleJobFormData } from '@/types/formData';
import { useJobListType } from '@/hooks/custom';

interface LeftHeaderProps {
  customerData?: CustomerProfileParams;
  toggleSaveEnabled: ToggleSaveButtonEnabled;
  jobData?: any;

  isRemovedJob?: boolean;
  onClickEdit?(): void;
}

const LeftHeader: React.FC<LeftHeaderProps> = (props) => {
  const classes = useLeadStyles();
  const { jobData, customerData, onClickEdit } = props;
  const { fieldsDisabled } = useJobListType();
  const staffMemberList = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_JOB_STAFF_MEMBERS];
  const staffMembers: UserParams[] = staffMemberList?.data?.data ?? [];

  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<SingleJobFormData>();

  const prepareAddress = () => {
    let address = '';

    if (customerData?.street && customerData?.street !== '') {
      address += customerData?.street;
    }

    if (customerData?.apartment && customerData?.apartment !== '') {
      if (address !== '') {
        address += ', ';
      }
      address += `${customerData?.apartment}`;
    }

    if (customerData?.city && customerData?.city !== '') {
      if (address !== '') {
        address += ', ';
      }
      address += `${customerData?.city}`;
    }

    return address;
  };

  return (
    <>
      <div>
        <span
          className={classnames({
            [classes.indicatorCircleFilled]: true,
            [jobData?.status === 0 ? classes.green : jobData?.status === 1 ? classes.yellow : classes.red]: true,
          })}
        />
        <span
          onClick={() => {
            if (!fieldsDisabled && onClickEdit) {
              onClickEdit();
            }
          }}
          className={classes.Names}
        >
          {`${customerData?.first_name ?? ''} ${customerData?.last_name ?? ''}`}
          {!props?.isRemovedJob && !fieldsDisabled ? <EditIcon /> : ''}
        </span>
        <span className={classes.Phones}>
          <a href={`tel:${customerData?.phone}`} target='_blank' rel='noreferrer'>
            {customerData?.phone ? formatPhoneNumber(customerData?.phone?.toString()) : ''}
          </a>
        </span>
        <span className={classes.gmail}>
          <a href={`mailto: ${customerData?.email}`} target='_blank' rel='noreferrer'>
            {customerData?.email}
          </a>
        </span>
        <span className={classes.address}>
          <a href={` https://maps.google.com/?q=${customerData?.address}`} target='_blank'>
            {prepareAddress()}
          </a>
        </span>

        <span className={classes.selector}>
          {staffMemberList?.loading ? (
            <Loader
              containerStyle={{
                display: 'inline-block',
              }}
              progressStyle={{
                height: 20,
                width: 20,
              }}
            />
          ) : (
            <Controller
              control={control}
              name={'assigned_to'}
              rules={{
                validate(value) {
                  if (!value || value === '--empty--') {
                    return 'Please select assigned to!';
                  }

                  return true;
                },
              }}
              render={({ field }) => (
                <TextInput
                  select={true}
                  variant={'standard'}
                  disabled={fieldsDisabled}
                  className={'select'}
                  options={[
                    {
                      title: 'Assigned To',
                      value: '--empty--',
                      disabled: true,
                    },
                    ...(staffMembers && staffMembers?.length > 0
                      ? staffMembers?.map((item) => ({
                          title: `${item?.first_name ?? ''} ${item?.last_name ?? ''}`,
                          value: item?.id,
                        }))
                      : []),
                  ]}
                  {...field}
                  onChange={(e) => {
                    field?.onChange(e);
                    props?.toggleSaveEnabled(true);
                  }}
                  fullWidth={false}
                  error={!!errors?.assigned_to}
                  // errorText={errors?.assigned_to?.message}
                />
              )}
            />
          )}
        </span>
      </div>
    </>
  );
};

export { LeftHeader };
