import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Button, ButtonProps } from '@/components/atoms/Button';
import { useMenuDialogStyles } from '@/static/stylesheets/atoms';
import { TextInput } from '@/components/atoms/TextInput';
import { useInput } from '@/hooks';

interface MenuDialogProps {
  title?: string | React.ReactNode;
  menuHeader?: string;
  menuList?: {
    title?: string;
    icon?: React.ReactNode;
    action?: string;
    options?: any;
    prepareOptions?(data: any): any;
  }[];
  onClick?: (e: any) => any;
  disabled?: boolean;
  buttonProps?: ButtonProps;
  subHeader?: string;
  tooltipText?: string;
  isInput?: boolean;
  leftAlign?: string;
}

const MenuDialog: React.FC<MenuDialogProps> = (props) => {
  const classes = useMenuDialogStyles();
  const { title, leftAlign, buttonProps, menuHeader, menuList, onClick, subHeader, tooltipText, isInput } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!props.disabled) {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { value: isData, setValue: setData, bind: bindData, reset: resetData } = useInput('', {});
  return (
    <React.Fragment>
      <div className={classes.root}>
        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
          <span onClick={handleClick}>
            <Button title={title} disabled={props.disabled} {...buttonProps} tooltipText={tooltipText} />
          </span>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id='account-menu'
          open={open}
          className={`${leftAlign ? leftAlign : ''} icon__menu`}
          onClose={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          {menuHeader && (
            <MenuItem disabled={true} className={'paperTitle'}>
              {menuHeader}
            </MenuItem>
          )}
          {subHeader && <p className={'paperSubTitle'}>{subHeader}</p>}
          {isInput && (
            <div style={{ padding: '0px 30px' }}>
              <TextInput
                variant={'standard'}
                placeholder={'Actual Total Cost'}
                style={{ marginTop: -10 }}
                type='number'
                centerMode={true}
                fullWidth={true}
                {...bindData}
              />
            </div>
          )}
          {(menuList ?? [])?.map((menu: any, index: number) => (
            <MenuItem
              key={index}
              onClick={(e) => {
                handleClose();
                if (onClick) {
                  onClick(e);
                }
              }}
              id={menu.action}
              {...menu?.options}
              {...(menu?.prepareOptions ? menu?.prepareOptions({ isData }) : {})}
            >
              {menu.icon} {menu.title}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </React.Fragment>
  );
};

export { MenuDialog };
