import React from 'react';
import { Checkbox } from '@mui/material';
import classNames from 'classnames';
import FlagIcon from '@mui/icons-material/Flag';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { TaskItem } from '@/types';
import { useComponentState } from '@/hooks';
import { TaskGridLoader } from '@/components/organisms/Tasks';
import { ChildTaskUpdateActionProps } from '@/components/molecules/Tasks';
import { format } from 'date-fns';

interface CompletedTasksProps {
  loading?: boolean;
  tasks?: TaskItem[];
  selectedCompletedTaskIndexForEdit: number | null;
  updateData(options: ChildTaskUpdateActionProps): void;
  onSelectCompletedTask(index: number): void;
}

const CompletedTasks: React.FC<CompletedTasksProps> = (props) => {
  const { updateData, tasks: completedTasks, selectedCompletedTaskIndexForEdit, onSelectCompletedTask } = props;

  const {
    state: { open },
    setState,
  } = useComponentState({
    open: true,
  });

  const toggleOpen = () => setState({ open: !open });

  return (
    <>
      <div className={'taskListTableContainer'}>
        <div className={'title-bar'}>
          <span>
            {`Completed Tasks`} {!props?.loading && ` | ${completedTasks?.length} Subtasks`}
          </span>
          <span className={'add-button'} onClick={toggleOpen}>
            {open ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </span>
        </div>
        {open && (
          <table>
            <thead>
              <tr>
                <th className={'left'}></th>
                <th className={'left'} style={{ width: '150px' }}>
                  Task
                </th>
                <th className={'left'} style={{ width: '150px' }}>
                  Assigned
                </th>
                <th className={'center'}>Due Date</th>
                <th className={'center'}>Est. Time</th>
                <th className={'center'}>Priority</th>
              </tr>
            </thead>
            <tbody>
              {props?.loading ? (
                <TaskGridLoader containerStyle={{ padding: 10 }} colSpan={7} />
              ) : (
                <>
                  {completedTasks &&
                    completedTasks?.length > 0 &&
                    completedTasks?.map((task, index) => (
                      <tr
                        key={index}
                        className={classNames({
                          active:
                            selectedCompletedTaskIndexForEdit !== null && index === selectedCompletedTaskIndexForEdit,
                        })}
                        onClick={() => onSelectCompletedTask(index)}
                      >
                        <td className={'center'}>
                          <Checkbox
                            checked={task?.is_complete}
                            onChange={() =>
                              updateData({
                                index,
                                payload: {
                                  is_complete: !task?.is_complete,
                                },
                              })
                            }
                          />
                        </td>
                        <td className={'left'}>{task?.title}</td>
                        <td className={'left'}>{task?.assigned_to?.name}</td>
                        <td className={'center'}>
                          {task?.due_datetime?.date && typeof task?.due_datetime?.date === 'object'
                            ? format(new Date(task?.due_datetime?.date), 'MM/d/yyyy')
                            : task?.due_datetime?.date}
                        </td>
                        <td className={'center'}>{task?.estimated_hours ?? 0} Hours</td>
                        <td className={'center'}>
                          <FlagIcon
                            className={classNames({
                              'no-priority': task?.priority === 'No Priority',
                              'low-priority': task?.priority === 'Low Priority',
                              'medium-priority': task?.priority === 'Medium Priority',
                              'high-priority': task?.priority === 'High Priority',
                            })}
                            style={{ cursor: 'default' }}
                          />
                        </td>
                      </tr>
                    ))}
                </>
              )}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

export { CompletedTasks };
