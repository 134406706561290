import React from 'react';
import { useAppSelector } from '@/hooks/useAppSelector';
import { selectUiState } from '@/redux/reducers/uiSlice';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Button } from '@/components/atoms/Button';
import { closeConfirmationDialog } from '@/redux/actions';
import { useButtonStyles } from '@/static/stylesheets/atoms';

const ConfirmationDialog = () => {
  const classes = useButtonStyles();
  const {
    open,
    title,
    message,
    type,
    cancelType,
    textCenter,
    cancelLabel,
    confirmLabel,
    hasCancel,
    onClickCancel,
    onClickConfirm,
  } = useAppSelector(selectUiState).confirmationDialog;

  const handleClose = () => {
    closeConfirmationDialog();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        className={classes.dialogBody}
        sx={{
          '& .MuiPaper-root': {
            width: 450,
            textAlign: 'center',
          },
          '& .MuiButton-textError': {
            color: '#f44336 !important',
          },
          '& .MuiButton-textPrimary': {
            color: '#2196f3 !important',
          },
          '& .MuiDialogActions-root': {
            justifyContent: 'center !important',
          },
        }}
        maxWidth={'lg'}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ textAlign: textCenter === 'center' ? 'center' : 'left' }}>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          className={classes.dialogButton}
          style={{ justifyContent: textCenter === 'center' ? 'center' : 'left' }}
        >
          {hasCancel && (
            <Button
              title={cancelLabel ? cancelLabel : 'Cancel'}
              color={cancelType === 'error' ? 'error' : 'primary'}
              variant={'text'}
              onClick={() => {
                handleClose();
                if (typeof onClickCancel === 'function') {
                  onClickCancel();
                }
              }}
            />
          )}
          <Button
            title={confirmLabel ? confirmLabel : 'Ok'}
            variant={'text'}
            color={type === 'error' ? 'error' : type === 'success' ? 'success' : 'primary'}
            onClick={() => {
              handleClose();
              if (typeof onClickConfirm === 'function') {
                onClickConfirm();
              }
            }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export { ConfirmationDialog };
