import { useReportStyles } from '@/static/stylesheets/molecules';
import { themeParser, useAppTheme } from '@/theme';
import { ReportsFormData } from '@/types';
import { Checkbox } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import classnames from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';

const StatusPicker = () => {
  const classes = useReportStyles();
  const theme = useAppTheme();

  const { watch, setValue, control } = useFormContext<ReportsFormData>();

  const formValues = watch();

  const isChecked = (id: string) => {
    const items = [...(formValues?.status ?? [])];
    return !!(items?.indexOf(id) > -1);
  };

  const toggle = (id: string) => {
    const items = [...(formValues?.status ?? [])];
    const index = items?.indexOf(id);
    if (index === -1) {
      items?.push(id);
    } else {
      items?.splice(index, 1);
    }
    setValue('status', items);
  };

  return (
    <div className={'report-filter-option-container status-picker-container'}>
      <div className={'report-filter-option-title'}>Status</div>
      <div className={'report-filter-option-body'}>
        <FormGroup
          sx={{
            '& .MuiTypography-root': {
              fontSize: '13px',
              fontWeight: 400,
              marginLeft: '5px',
              color: themeParser('#313131', '#b7b7b7', theme),
            },
            '& .MuiFormControlLabel-root': {
              height: '25px',
              marginLeft: '3px',
            },
          }}
        >
          <Controller
            control={control}
            name={'assigned_to'}
            render={() => (
              <>
                {['On Track', 'Needs Attention', 'WTF?! Help'].map((item, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        color={'primary'}
                        checked={isChecked(index + '')}
                        onChange={() => {
                          toggle(index + '');
                        }}
                      />
                    }
                    label={
                      <>
                        {item}-
                        <span className={classes.indicatorCircleFilled}>
                          <span
                            className={classnames({
                              wrapper: true,
                              green: index === 0,
                              yellow: index === 1,
                              red: index === 2,
                            })}
                          />
                        </span>
                      </>
                    }
                  />
                ))}
              </>
            )}
          />
        </FormGroup>
      </div>
    </div>
  );
};

export { StatusPicker };
