import React, { useEffect, useState } from 'react';
import { DataTable } from '@/components/organisms/DataTable';
import { ActionButton, Button } from '@/components/atoms/Button';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { TextInput } from '@/components/atoms/TextInput';
import { useSettingsEquipmentStyle } from '@/static/stylesheets/molecules';
import { EquipmentForm } from '@/components/molecules/Settings/EquipmentForm';
import { clearDeletedEquipment, deleteEquipment, getEquipments, openConfirmationDialog } from '@/redux/actions';
import { useAppSelector, useThrottle } from '@/hooks';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { EquipmentParams } from '@/types';
import toast from 'react-hot-toast';
import { alertMessages } from '@/config';

const SettingsEquipment = () => {
  const classes = useSettingsEquipmentStyle();
  const equipmentList = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_EQUIPMENTS];
  const equipments: any = equipmentList?.data?.data ?? [];
  const totalPages = equipmentList?.data?.last_page ?? 0;

  const deletedEquipment = useAppSelector(selectHttpState)[CORRELATION_IDS.DELETE_EQUIPMENT];
  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const throttleSearch = useThrottle(search, 1000);

  const [formOpen, setFormOpen] = useState(false);
  const [editFormData, setEditFormData] = useState<EquipmentParams | null>(null);

  const openFormDialog = (mode: 'add' | 'edit', formData: EquipmentParams | null) => {
    setFormOpen(true);
    setEditFormData(formData);
  };

  const handleCloseFormDialog = () => {
    setFormOpen(false);
    setTimeout(() => {
      setEditFormData(null);
    }, 100);
  };

  const fetchEquipments = () => {
    getEquipments({
      limit,
      page: currentPage,
      search,
    });
  };

  const deleteEquipmentItem = (id: EquipmentParams['id']) => {
    deleteEquipment(id);
  };

  useEffect(() => {
    if (deletedEquipment?.success) {
      toast.success(alertMessages.generalSettings.equipments.deleteSuccess);
      fetchEquipments();
      clearDeletedEquipment();
    }

    if (deletedEquipment?.error) {
      toast.error(alertMessages.generalSettings.equipments.deleteError);
      clearDeletedEquipment();
    }
  }, [deletedEquipment]);

  useEffect(() => {
    fetchEquipments();
  }, [throttleSearch, limit, currentPage]);

  return (
    <>
      <div className={classes.TopHeader}>
        <div className={classes.selector}>
          <TextInput
            select={true}
            variant={'standard'}
            options={[
              { title: '15', value: '15' },
              { title: '30', value: '30' },
              { title: '50', value: '50' },
            ]}
            value={limit}
            onChange={(e) => setLimit(Number(e?.target?.value))}
          />
        </div>
        <div className={classes.inputField}>
          <TextInput
            variant={'standard'}
            placeholder={'Search for item...'}
            centerMode={true}
            value={search}
            onChange={(e) => setSearch(e?.target?.value)}
          />
        </div>
        <Button
          onClick={() => openFormDialog('add', null)}
          color={'primary'}
          variant={'contained'}
          title={'Add item'}
          size={'small'}
        />
      </div>
      <div style={{ textAlign: 'center', marginTop: 40, padding: 10 }}>
        <div style={{ width: '100%', display: 'inline-block' }}>
          <DataTable
            heads={[
              { label: 'Item', align: 'left' },
              { label: 'SKU', align: 'left' },
              { label: 'Link', align: 'left' },
              { label: 'Our Cost', align: 'left' },
              { label: 'Client Cost', align: 'left' },
              { label: 'Actions', align: 'center' },
            ]}
            rows={[
              ...equipments?.map((equipment: EquipmentParams) => [
                { label: equipment?.equipment_name ?? '', align: 'left' },
                { label: equipment?.sku ?? '', align: 'center' },
                {
                  label: (
                    <a target='_blank' href={equipment?.url} rel='noreferrer'>
                      <InsertLinkIcon style={{ color: '#2196f3' }} />
                    </a>
                  ),
                  align: 'center',
                },
                { label: equipment?.our_cost, align: 'center' },
                { label: equipment?.client_cost, align: 'center' },
                {
                  label: (
                    <div className={'action-icon'}>
                      <ActionButton onClick={() => openFormDialog('edit', equipment)} icon={'edit'} />
                      <ActionButton
                        icon={'delete'}
                        onClick={() =>
                          openConfirmationDialog({
                            title: 'Are you sure?',
                            message: 'Are you sure to delete the equipment?',
                            type: 'error',
                            textCenter: 'center',
                            onClickConfirm() {
                              deleteEquipmentItem(equipment?.id);
                            },
                          })
                        }
                      />
                    </div>
                  ),
                  align: 'center',
                },
              ]),
            ]}
            hasPagination={true}
            paginationProps={{
              count: totalPages,
              currentPage,
              onChange(value) {
                setCurrentPage(value);
              },
            }}
          />
        </div>
      </div>

      <EquipmentForm
        open={formOpen}
        formData={editFormData}
        onClose={handleCloseFormDialog}
        onReload={fetchEquipments}
      />
    </>
  );
};

export { SettingsEquipment };
