import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useModalStyles } from '@/static/stylesheets/organisms';
import Modal from '@mui/material/Modal';
import { TextareaAutosize } from '@mui/material';
import { Button } from '@/components/atoms/Button';
import {
  updateCustomerLead,
  clearUpdatedCustomerLead,
  getLeads,
  getClients,
  updateCustomerClient,
  clearUpdatedCustomerClient,
} from '@/redux/actions';
import { useInput } from '@/hooks';
import toast from 'react-hot-toast';
import { useAppSelector } from '@/hooks';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { alertMessages } from '@/config';

interface RemoveActionProps {
  openModal: boolean;

  onClose?(): void;

  modalValue?: any;
  leadsData?: any;
}

const RemoveAction: React.FC<RemoveActionProps> = (props) => {
  const classes = useModalStyles();
  const { openModal, onClose, modalValue, leadsData } = props;
  const updatedCustomerClientResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.UPDATE_CUSTOMER_CLIENT];
  const [isReason, setIsReason] = useState(null);
  const [targetId, setTargetId] = useState(null);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const resetFields = () => {
    resetReasonDetails();
  };

  const {
    value: isReasonDetails,
    reset: resetReasonDetails,
    error: reasonDetailsError,
    bind: bindReasonDetails,
  } = useInput('', {
    required: 'Please enter Reason Details!',
    validate(value) {
      if ((isReason as any)?.toString()?.toLowerCase() === 'other' && value === '') {
        return 'Please enter the reason details!';
      }

      if ((modalValue as any)?.toString()?.toLowerCase() === 'other' && value === '') {
        return 'Please enter the reason details!';
      }

      return true;
    },
  });

  const onSubmit = () => {
    if (isReasonDetails === '' && modalValue === 'Other') {
      toast.error('Please fill all the required fields!');
      return;
    }
    reasonDetailsError?.check();
    if (reasonDetailsError?.check() && (isReason as any)?.toString()?.toLowerCase() === 'Other') {
      toast.error('Please fill all the required fields!');
      return;
    }
    const data: any = {
      removed_reason: modalValue,
      removed_reason_details: isReasonDetails ? isReasonDetails : isReason,
      time_zone: timezone,
      name: 'Deactivated Client',
    };
    setTargetId(leadsData?.id);
    data.id = leadsData?.id;
    updateCustomerClient(data);
  };

  const getClientList = () =>
    getClients({
      limit: 10,
      page: 1,
      type: 'clients',
    });

  useEffect(() => {
    if (!openModal) {
      resetFields();
    }
  }, [openModal]);

  useEffect(() => {
    if (updatedCustomerClientResponse?.success && targetId === leadsData?.id) {
      toast.success(alertMessages.clients.customerClientUpdateSuccess);
      setTargetId(null);
      clearUpdatedCustomerClient();
      getClientList();

      if (onClose) {
        onClose();
      }
    }

    if (updatedCustomerClientResponse?.error && targetId === leadsData?.id) {
      toast.error(alertMessages.clients.customerClientUpdateError);
      clearUpdatedCustomerClient();
    }
  }, [updatedCustomerClientResponse]);

  return (
    <>
      <Modal
        open={openModal}
        onClose={onClose}
        className={classes.root}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Please briefly describe why you chose other
          </Typography>
          {modalValue === 'Other' && (
            <>
              <TextareaAutosize
                aria-label='minimum height'
                minRows={4}
                placeholder='Textarea'
                {...bindReasonDetails}
                className={
                  reasonDetailsError?.error
                    ? 'textaria_error'
                    : isReasonDetails === '' && modalValue === 'Other'
                      ? 'textaria_error'
                      : 'textaria'
                }
              />
              {reasonDetailsError && <span className='error'>{reasonDetailsError?.message}</span>}
            </>
          )}
          <div className={'actionButton'}>
            <Button color={'error'} variant={'contained'} title={'Cancel'} onClick={onClose} />
            {modalValue === 'Other' && (
              <Button color={'success'} variant={'contained'} title={'Submit'} onClick={onSubmit} />
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
};

export { RemoveAction };
