import InputMask from 'react-input-mask';
import { TextInput, TextInputProps } from './TextInput';
interface PhoneMaskProps {
  mask?: string;
}
const PhoneMask: React.FC<TextInputProps & PhoneMaskProps> = (props) => {
  const { mask, value, onChange, onBlur, ...rest } = props;
  return (
    <InputMask mask={mask || '999.999.9999'} value={(value || '') as string} onChange={onChange} type={props.type}>
      <TextInput value={value} onChange={onChange} {...rest} />
    </InputMask>
  );
};
export default PhoneMask;
