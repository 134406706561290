import React from 'react';
import { SettingsLayout } from '@/components/layouts';
import { SettingsCard } from '@/components/organisms/SettingsCard';
import { PrivateLayout } from '@/components/Middleware';
import { PricingSetting } from '@/components/molecules/Settings/PricingSetting';

const Pricing = () => {
  return (
    <>
      <PrivateLayout>
        <SettingsLayout hideSettingsTabPanel={true} hideHeaderRightSide={false} containerSize={'md'}>
          <SettingsCard title={'Proposal Pricing'}>
            <PricingSetting />
          </SettingsCard>
        </SettingsLayout>
      </PrivateLayout>
    </>
  );
};

export { Pricing };
