type ShouldReturn = any;

export function convertToPayloadParam(value: any, shouldReturn: ShouldReturn = undefined) {
  if (!value) {
    return shouldReturn;
  }

  if (typeof value === 'string' && value?.trim() === '') {
    return shouldReturn;
  }

  if (typeof value === 'object' && Array.isArray(value) && value?.length === 0) {
    return shouldReturn;
  }

  return value;
}
