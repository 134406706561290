import { store } from '@/redux/store';
import {
  createClientHttpCall,
  getClientsHttpCall,
  getClientStaffMembersDropdownHttpCall,
  updateClientHttpCall,
  getClientQuickNotesHttpCall,
  createClientQuickNotesHttpCall,
  updateClientQuickNotesHttpCall,
  deleteClientQuickNotesHttpCall,
  getClientActivityLogsHttpCall,
  deleteAllClientEquipmentsHttpCall,
  moveClientHttpCall,
  sendClientFilterHttpCall,
  uploadClientFileHttpCall,
  getClientFileDetailsHttpCall,
  deleteClientFileHttpCall,
  getClientContractPDFHttpCall,
} from '@/redux/reducers/http/httpCallSets';
import {
  CustomerProfileParams,
  ClientListFilterParams,
  UserListFilterParams,
  QuickNotesParams,
  ActivityLogsParams,
  EquipmentParams,
  EquipmentBodyParams,
  MoveClientParams,
  ClientFilterParams,
  ClientItem,
} from '@/types';
import { clearHttpStateObject } from '@/redux/actions';
import {
  CORRELATION_IDS,
  updateClientVMCountHttpCall,
  sendClientContractHttpCall,
  restoreDeactivatedClientHttpCall,
  createClientEquipmentsHttpCall,
  getClientEquipmentsHttpCall,
  deleteClientEquipmentHttpCall,
  updateCustomerClientHttpCall,
  updateClientCustomerDetailsHttpCall,
} from '@/redux/reducers/http';

export function getClients(params: ClientListFilterParams) {
  clearSendClientFilter();
  store.dispatch(getClientsHttpCall(params));
}

export function getClientStaffMembers(params: UserListFilterParams) {
  store.dispatch(getClientStaffMembersDropdownHttpCall(params));
}

export function createClient(data: CustomerProfileParams) {
  store.dispatch(createClientHttpCall(data));
}

export function updateClient(data: CustomerProfileParams) {
  store.dispatch(updateClientHttpCall(data));
}

export function clearCreatedClient() {
  clearHttpStateObject(CORRELATION_IDS.CREATE_CLIENT);
}

export function clearUpdatedClient() {
  clearHttpStateObject(CORRELATION_IDS.UPDATE_CLIENT);
}

export function updateClientVMCount(id: string) {
  store.dispatch(updateClientVMCountHttpCall(id));
}

export function sendClientContract(id: string) {
  store.dispatch(sendClientContractHttpCall(id));
}

export function clearClientSendContractResponse() {
  clearHttpStateObject(CORRELATION_IDS.SEND_CLIENT_CONTRACT);
}

export function clearClientUpdatedVMCount() {
  clearHttpStateObject(CORRELATION_IDS.UPDATE_CLIENT_VM_COUNT);
}

export function restoreDeactivatedClient(id: string) {
  store.dispatch(restoreDeactivatedClientHttpCall(id));
}

export function clearRestoreDeactivatedClient() {
  clearHttpStateObject(CORRELATION_IDS.RESTORE_DEACTIVATED_CLIENT);
}

export function createClientEquipments(data: EquipmentBodyParams) {
  store.dispatch(createClientEquipmentsHttpCall(data));
}

export function clearCreateClientEquipments() {
  clearHttpStateObject(CORRELATION_IDS.CREATE_CLIENT_EQUIPMENTS);
}

export function getClientEquipments(id: any) {
  store.dispatch(getClientEquipmentsHttpCall(id));
}

export function clearGetClientEquipments() {
  clearHttpStateObject(CORRELATION_IDS.GET_CLIENT_EQUIPMENTS);
}

export function deleteClientEquipment(id: EquipmentParams['id']) {
  store.dispatch(deleteClientEquipmentHttpCall(id));
}

export function deleteAllClientEquipments(id: EquipmentParams['id']) {
  store.dispatch(deleteAllClientEquipmentsHttpCall(id));
}

export function clearDeletedClientEquipment() {
  clearHttpStateObject(CORRELATION_IDS.DELETE_CLIENT_EQUIPMENT);
}

export function updateCustomerClient(id: CustomerProfileParams) {
  store.dispatch(updateCustomerClientHttpCall(id));
}

export function updateClientCustomerDetails(id: CustomerProfileParams) {
  store.dispatch(updateClientCustomerDetailsHttpCall(id));
}

export function clearUpdatedCustomerClient() {
  clearHttpStateObject(CORRELATION_IDS.UPDATE_CUSTOMER_CLIENT);
}

export function clearClientUpdateCustomerDetails() {
  clearHttpStateObject(CORRELATION_IDS.UPDATE_CLIENT_CUSTOMER_DETAILS);
}

export function getClientQuickNotes(id: QuickNotesParams['id']) {
  store.dispatch(getClientQuickNotesHttpCall(id));
}

export function createClientQuickNotes(data: QuickNotesParams) {
  store.dispatch(createClientQuickNotesHttpCall(data));
}

export function clearClientCreateQuickNotes() {
  clearHttpStateObject(CORRELATION_IDS.CREATE_CLIENT_QUICK_NOTES);
}

export function updateClientQuickNotes(data: QuickNotesParams) {
  store.dispatch(updateClientQuickNotesHttpCall(data));
}

export function clearClientUpdatedQuickNotes() {
  clearHttpStateObject(CORRELATION_IDS.UPDATE_CLIENT_QUICK_NOTES);
}

export function deleteClientQuickNotes(id: QuickNotesParams['id']) {
  store.dispatch(deleteClientQuickNotesHttpCall(id));
}

export function clearDeleteClientQuickNoteResponse() {
  clearHttpStateObject(CORRELATION_IDS.DELETE_CLIENT_QUICK_NOTES);
}

export function getClientActivityLogs(params: ActivityLogsParams) {
  store.dispatch(getClientActivityLogsHttpCall(params));
}

export function moveClient(params: MoveClientParams) {
  store.dispatch(moveClientHttpCall(params));
}

export function clearMovedClientResponse() {
  clearHttpStateObject(CORRELATION_IDS.MOVE_CLIENT);
}

export function sendClientFilter(data: ClientFilterParams) {
  store.dispatch(sendClientFilterHttpCall(data));
}

export function clearSendClientFilter() {
  clearHttpStateObject(CORRELATION_IDS.SEND_CLIENT_FILTER);
}

export function uploadClientFile(data: any, onUploadProgress: (progressEvent: any) => void) {
  store.dispatch(uploadClientFileHttpCall(data?.id, data.formData, onUploadProgress));
}

export function getClientFileDetails(id: any) {
  store.dispatch(getClientFileDetailsHttpCall(id));
}

export function deleteClientFile(id: any) {
  store.dispatch(deleteClientFileHttpCall(id));
}

export function clearDeleteClientFileResponse() {
  clearHttpStateObject(CORRELATION_IDS.DELETE_CLIENT_FILE);
}

export function getClientContractPDF(leadId: ClientItem['id']) {
  store.dispatch(getClientContractPDFHttpCall(leadId));
}

export function clearClientContractPDF() {
  clearHttpStateObject(CORRELATION_IDS.GET_LEAD_CONTRACT_PDF);
}
