import { BackToTopButton } from '@/components/atoms/BackToTopButton';
import { ExpensesBreakDown, MonthBreakDown, YearSummary } from '@/components/molecules/Reports/ReportPages/General';
import {
  LeadToClientLostVsWon,
  LeadToClientPendingProfitLoss,
  LeadToClientProfitLoss,
  LeadToClientProgress,
} from '@/components/molecules/Reports/ReportPages/LeadToClient';
import {
  TechWorkLostVsWon,
  TechWorkPendingProfitLoss,
  TechWorkProfitLoss,
} from '@/components/molecules/Reports/ReportPages/TechWork';
import { TechWorkProgress } from '@/components/molecules/Reports/ReportPages/TechWork/TechWorkProgress';
import { ReportFilter } from '@/components/organisms/Reports';
import { useAppSelector } from '@/hooks';
import { setReportGlobalReload } from '@/redux/actions';
import { selectUiState } from '@/redux/reducers/uiSlice';
import { useReportStyles } from '@/static/stylesheets/molecules';
import { ReportMenuKey, ReportsFormData } from '@/types';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

interface ReportContentProps {
  selectedMenuKey: ReportMenuKey;
}

const ReportContent: React.FC<ReportContentProps> = (props) => {
  const classes = useReportStyles();
  const [isResetForm, setIsResetForm] = useState(false);
  const { selectedMenuKey } = props;
  const { isReportGlobalReload } = useAppSelector(selectUiState);
  const initialFormData: ReportsFormData = {
    assigned_to: [],
    install_type: '',
    report_type: '',
    period: 'all_time',
    source: '',
    period_start: '',
    period_end: '',
    job_type: '',
  };

  const formMethods = useForm<ReportsFormData>({
    mode: 'onChange',
    defaultValues: initialFormData,
  });

  const { watch, reset, setValue } = formMethods;
  const formValues = watch();
  console.log('formValues', formValues);

  useEffect(() => {
    reset({
      ...initialFormData,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMenuKey]);

  const resetFormStatus = () => {
    setIsResetForm(false);
    setReportGlobalReload(false);
  };

  const clearFilter = () => {
    reset({
      ...initialFormData,
    });

    setIsResetForm(true);
  };

  const scrollTop = () => {
    const body = document.querySelector('.block-item');
    if (body != null) {
      body.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (isReportGlobalReload) {
      console.log('=====isGlobalReload ==== ');
      clearFilter();
    }
  }, [isReportGlobalReload]);

  return (
    <>
      <FormProvider {...formMethods}>
        <BackToTopButton scrollTop={scrollTop} />
        <div className={classes.contentRoot}>
          <div className={`${classes.contentSubRoot} block-item`}>
            <ReportFilter
              selectedMenuKey={selectedMenuKey}
              onClickClearFilter={clearFilter}
              resetFormStatus={resetFormStatus}
              isResetForm={isResetForm}
              showSelectPeriod={
                selectedMenuKey === 'GENERAL_EXPENSES_BREAKDOWN' ||
                selectedMenuKey === 'LEAD_TO_CLIENT_PROFIT_AND_LOSS' ||
                selectedMenuKey === 'LEAD_TO_CLIENT_PENDING_PROFIT_AND_LOSS' ||
                selectedMenuKey === 'LEAD_TO_CLIENT_LOST_VS_WON' ||
                selectedMenuKey === 'LEAD_TO_CLIENT_PROGRESS' ||
                selectedMenuKey === 'TECH_WORK_PROFIT_AND_LOSS' ||
                selectedMenuKey === 'TECH_WORK_PENDING_PROFIT_AND_LOSS' ||
                selectedMenuKey === 'TECH_WORK_LOST_VS_WON' ||
                selectedMenuKey === 'TECH_WORK_PROGRESS'
              }
              showTypeOfWorkPicker={
                selectedMenuKey === 'GENERAL_TOTAL_P&L_OR_YEAR_SUMMARY' ||
                selectedMenuKey === 'GENERAL_TOTAL_P&L_OR_MONTH_BREAKDOWN' ||
                selectedMenuKey === 'GENERAL_EXPENSES_BREAKDOWN' ||
                selectedMenuKey === 'TECH_WORK_PROFIT_AND_LOSS' ||
                selectedMenuKey === 'TECH_WORK_PENDING_PROFIT_AND_LOSS' ||
                selectedMenuKey === 'TECH_WORK_LOST_VS_WON' ||
                selectedMenuKey === 'TECH_WORK_PROGRESS'
              }
              showAssignedToPicker={
                selectedMenuKey === 'GENERAL_TOTAL_P&L_OR_YEAR_SUMMARY' ||
                selectedMenuKey === 'GENERAL_TOTAL_P&L_OR_MONTH_BREAKDOWN' ||
                // selectedMenuKey === "GENERAL_EXPENSES_BREAKDOWN" ||
                selectedMenuKey === 'LEAD_TO_CLIENT_PROFIT_AND_LOSS' ||
                selectedMenuKey === 'LEAD_TO_CLIENT_PENDING_PROFIT_AND_LOSS' ||
                selectedMenuKey === 'LEAD_TO_CLIENT_LOST_VS_WON' ||
                selectedMenuKey === 'LEAD_TO_CLIENT_PROGRESS' ||
                selectedMenuKey === 'TECH_WORK_PROFIT_AND_LOSS' ||
                selectedMenuKey === 'TECH_WORK_PENDING_PROFIT_AND_LOSS' ||
                selectedMenuKey === 'TECH_WORK_LOST_VS_WON' ||
                selectedMenuKey === 'TECH_WORK_PROGRESS'
              }
              showSourcePicker={
                selectedMenuKey === 'GENERAL_TOTAL_P&L_OR_YEAR_SUMMARY' ||
                selectedMenuKey === 'GENERAL_TOTAL_P&L_OR_MONTH_BREAKDOWN' ||
                // selectedMenuKey === "GENERAL_EXPENSES_BREAKDOWN" ||
                selectedMenuKey === 'LEAD_TO_CLIENT_PROFIT_AND_LOSS' ||
                selectedMenuKey === 'LEAD_TO_CLIENT_PENDING_PROFIT_AND_LOSS' ||
                selectedMenuKey === 'LEAD_TO_CLIENT_PROGRESS' ||
                selectedMenuKey === 'TECH_WORK_PROFIT_AND_LOSS' ||
                selectedMenuKey === 'TECH_WORK_PENDING_PROFIT_AND_LOSS' ||
                selectedMenuKey === 'TECH_WORK_LOST_VS_WON' ||
                selectedMenuKey === 'TECH_WORK_PROGRESS'
              }
              showStatusPicker={
                selectedMenuKey === 'LEAD_TO_CLIENT_PENDING_PROFIT_AND_LOSS' ||
                selectedMenuKey === 'LEAD_TO_CLIENT_PROGRESS' ||
                selectedMenuKey === 'TECH_WORK_PENDING_PROFIT_AND_LOSS' ||
                selectedMenuKey === 'TECH_WORK_PROGRESS'
              }
              showCurrentStatusPicker={
                selectedMenuKey === 'LEAD_TO_CLIENT_PENDING_PROFIT_AND_LOSS' ||
                selectedMenuKey === 'TECH_WORK_PENDING_PROFIT_AND_LOSS'
              }
            />

            {selectedMenuKey === 'GENERAL_TOTAL_P&L_OR_YEAR_SUMMARY' && <YearSummary />}

            {selectedMenuKey === 'GENERAL_TOTAL_P&L_OR_MONTH_BREAKDOWN' && <MonthBreakDown />}

            {selectedMenuKey === 'GENERAL_EXPENSES_BREAKDOWN' && <ExpensesBreakDown />}

            {selectedMenuKey === 'LEAD_TO_CLIENT_PROFIT_AND_LOSS' && <LeadToClientProfitLoss />}

            {selectedMenuKey === 'LEAD_TO_CLIENT_PENDING_PROFIT_AND_LOSS' && <LeadToClientPendingProfitLoss />}

            {selectedMenuKey === 'LEAD_TO_CLIENT_LOST_VS_WON' && <LeadToClientLostVsWon />}

            {selectedMenuKey === 'LEAD_TO_CLIENT_PROGRESS' && <LeadToClientProgress />}

            {selectedMenuKey === 'TECH_WORK_PROFIT_AND_LOSS' && <TechWorkProfitLoss />}

            {selectedMenuKey === 'TECH_WORK_PENDING_PROFIT_AND_LOSS' && <TechWorkPendingProfitLoss />}

            {selectedMenuKey === 'TECH_WORK_LOST_VS_WON' && <TechWorkLostVsWon />}

            {selectedMenuKey === 'TECH_WORK_PROGRESS' && <TechWorkProgress />}
          </div>
        </div>
      </FormProvider>
    </>
  );
};

export { ReportContent };
