import React, { useEffect } from 'react';
import { Button } from '@/components/atoms/Button';
import { useGeneralSettingStyle } from '@/static/stylesheets/molecules';
import { TextInput } from '@/components/atoms/TextInput';
import { Typography, Grid, Tooltip } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { clearSavedLeadClientJobSettings, getLeadClientJobSettings, saveLeadClientJobSettings } from '@/redux/actions';
import { useAppSelector, useInput } from '@/hooks';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import toast from 'react-hot-toast';
import { Loader } from '@/components/atoms/Loader';
import { isInt } from '@/utils';
import { alertMessages } from '@/config';

interface GeneralSettingProps {}

const LeadClientJobSettings: React.FC<GeneralSettingProps> = () => {
  const classes = useGeneralSettingStyle();
  const leadClientJobSettings = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_LEAD_CLIENT_JOB_SETTINGS];
  const savedLeadClientJobSettings = useAppSelector(selectHttpState)[CORRELATION_IDS.SAVE_LEAD_CLIENT_JOB_SETTINGS];

  const validateNumberField = (value: any) => {
    if (isNaN(Number(value)) || Number(value) === 0 || Number(value) < 0) {
      return 'Input should be greater than 0';
    }

    return true;
  };

  const {
    value: defaultHourlyRate,
    bind: bindDefaultHourlyRate,
    setValue: setDefaultHourlyRate,
    error: defaultHourlyRateError,
  } = useInput('', {
    required: true,
    validate: validateNumberField,
  });
  const {
    value: defaultEquipMarkup,
    bind: bindDefaultEquipMarkup,
    setValue: setDefaultEquipMarkup,
    error: defaultEquipMarkupError,
  } = useInput('', {
    required: true,
    validate: validateNumberField,
  });
  const {
    value: contractReminder,
    bind: bindContractReminder,
    setValue: setContractReminder,
    error: contractReminderError,
  } = useInput('', {
    required: true,
    validate(value) {
      if (isNaN(Number(value)) || Number(value) === 0 || Number(value) < 0) {
        return 'Input should be greater than 0';
      }

      if (!isInt(value)) {
        return 'Please input integer value!';
      }

      return true;
    },
  });
  const {
    value: vmRemoveLeadAfter,
    bind: bindVmRemoveLeadAfter,
    setValue: setVmRemoveLeadAfter,
    error: vmRemoveLeadAfterError,
  } = useInput('', {
    required: true,
    validate(value) {
      if (isNaN(Number(value)) || Number(value) === 0 || Number(value) < 0) {
        return 'Input should be greater than 0';
      }

      if (!isInt(value)) {
        return 'Please input integer value!';
      }

      return true;
    },
  });
  const {
    value: hoursToTurnLeadYellow,
    bind: bindHoursToTurnLeadYellow,
    setValue: setHoursToTurnLeadYellow,
    error: hoursToTurnLeadYellowError,
  } = useInput('', {
    required: true,
    validate: validateNumberField,
  });
  const {
    value: hoursToTurnLeadRed,
    bind: bindHoursToTurnLeadRed,
    setValue: setHoursToTurnLeadRed,
    error: hoursToTurnLeadRedError,
  } = useInput('', {
    required: true,
    validate: validateNumberField,
  });
  const {
    value: hoursToTurnJobYellow,
    bind: bindHoursToTurnJobYellow,
    setValue: setHoursToTurnJobYellow,
    error: hoursToTurnJobYellowError,
  } = useInput('', {
    required: true,
    validate: validateNumberField,
  });
  const {
    value: hoursToTurnJobRed,
    bind: bindHoursToTurnJobRed,
    setValue: setHoursToTurnJobRed,
    error: hoursToTurnJobRedError,
  } = useInput('', {
    required: true,
    validate: validateNumberField,
  });
  const {
    value: hoursToTurnTaskRed,
    bind: bindHoursToTurnTaskRed,
    setValue: setHoursToTurnTaskRed,
    error: hoursToTurnTaskRedError,
  } = useInput('', {
    required: true,
    validate: validateNumberField,
  });
  const {
    value: hoursToTurnTaskYellow,
    bind: bindHoursToTurnTaskYellow,
    setValue: setHoursToTurnTaskYellow,
    error: hoursToTurnTaskYellowError,
  } = useInput('', {
    required: true,
    validate: validateNumberField,
  });

  const handleSubmit = () => {
    if (
      defaultHourlyRateError.check() ||
      defaultEquipMarkupError.check() ||
      contractReminderError.check() ||
      vmRemoveLeadAfterError.check() ||
      hoursToTurnLeadYellowError.check() ||
      hoursToTurnLeadRedError.check() ||
      hoursToTurnJobYellowError.check() ||
      hoursToTurnJobRedError.check() ||
      hoursToTurnTaskRedError.check() ||
      hoursToTurnTaskYellowError.check()
    ) {
      toast.error(alertMessages.generalSettings.leadClientJobSettings.submitValidationError);
      return;
    }

    saveLeadClientJobSettings({
      default_hourly_rate: defaultHourlyRate,
      hours_to_turn_job_red: hoursToTurnJobRed,
      hours_to_turn_job_yellow: hoursToTurnJobYellow,
      hours_to_turn_lead_red: hoursToTurnLeadRed,
      hours_to_turn_lead_yellow: hoursToTurnLeadYellow,
      hours_to_turn_task_red: hoursToTurnTaskRed,
      hours_to_turn_task_yellow: hoursToTurnTaskYellow,
      remind_contract_after_days: contractReminder,
      vm_remove_lead_after: vmRemoveLeadAfter,
      default_equip_markup: defaultEquipMarkup,
    });
  };

  useEffect(() => {
    if (leadClientJobSettings && leadClientJobSettings?.success) {
      const data = leadClientJobSettings?.data;

      setDefaultHourlyRate(data?.default_hourly_rate);
      setDefaultEquipMarkup(data?.default_equip_markup);
      setContractReminder(data?.remind_contract_after_days);
      setVmRemoveLeadAfter(data?.vm_remove_lead_after);
      setHoursToTurnLeadYellow(data?.hours_to_turn_lead_yellow);
      setHoursToTurnLeadRed(data?.hours_to_turn_lead_red);
      setHoursToTurnJobYellow(data?.hours_to_turn_job_yellow);
      setHoursToTurnJobRed(data?.hours_to_turn_job_red);
      setHoursToTurnTaskRed(data?.hours_to_turn_task_red);
      setHoursToTurnTaskYellow(data?.hours_to_turn_task_yellow);
    }
  }, [leadClientJobSettings]);

  useEffect(() => {
    if (savedLeadClientJobSettings?.success) {
      clearSavedLeadClientJobSettings();
      toast.success(alertMessages.generalSettings.leadClientJobSettings.saveSuccess);
    }

    if (savedLeadClientJobSettings?.error) {
      clearSavedLeadClientJobSettings();
      toast.error(alertMessages.generalSettings.leadClientJobSettings.saveError);
    }
  }, [savedLeadClientJobSettings]);

  useEffect(() => {
    getLeadClientJobSettings();
  }, []);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.cardHeader}>
          <Typography variant='subtitle1' component='div'>
            Lead, Client & Job Settings
          </Typography>
          <div>
            <Button
              title={'Save Settings'}
              color={'success'}
              size={'small'}
              onClick={handleSubmit}
              loading={savedLeadClientJobSettings?.loading}
              disabled={leadClientJobSettings?.loading}
            />
          </div>
        </div>

        {leadClientJobSettings?.loading ? (
          <Loader />
        ) : (
          <Grid className={classes.container} container spacing={2}>
            <Grid item sm={6} md={6} lg={6}>
              <Grid container spacing={2} className={classes.container}>
                <Grid item sm={12} md={7} lg={7}>
                  <Typography className={classes.textLabel} variant='subtitle1' component='h2'>
                    Default Hourly Rate:
                  </Typography>
                </Grid>
                <Grid className={classes.itemForm} item sm={12} md={5} lg={5}>
                  <TextInput
                    type={'number'}
                    variant={'standard'}
                    fullWidth={true}
                    centerMode={true}
                    error={defaultHourlyRateError.error}
                    {...bindDefaultHourlyRate}
                  />
                  <Tooltip title='Default rate for jobs and tech work.' arrow>
                    <HelpOutlineIcon className={classes.Icon} />
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6} md={6} lg={6}>
              <Grid container spacing={2} className={classes.container}>
                <Grid item sm={12} md={7} lg={7}>
                  <Typography className={classes.textLabel} variant='subtitle1' component='h2'>
                    Default Equip. Markup:
                  </Typography>
                </Grid>
                <Grid item className={classes.itemForm} sm={12} md={5} lg={5}>
                  <TextInput
                    type={'number'}
                    variant={'standard'}
                    fullWidth={true}
                    centerMode={true}
                    error={defaultEquipMarkupError.error}
                    {...bindDefaultEquipMarkup}
                  />
                  <Tooltip title='Percentage markup on equipment the we sell clients. Use 0.1 - not %' arrow>
                    <HelpOutlineIcon className={classes.Icon} />
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6} md={6} lg={6}>
              <Grid container spacing={2} className={classes.container}>
                <Grid item sm={12} md={7} lg={7}>
                  <Typography className={classes.textLabel} variant='subtitle1' component='h2'>
                    Contract Reminder::
                  </Typography>
                </Grid>
                <Grid item className={classes.itemForm} sm={12} md={5} lg={5}>
                  <TextInput
                    type={'number'}
                    variant={'standard'}
                    fullWidth={true}
                    centerMode={true}
                    error={contractReminderError.error}
                    {...bindContractReminder}
                  />
                  <Tooltip
                    title='Days before contract expires that auto email is sent out to staff about out of contract.'
                    arrow
                  >
                    <HelpOutlineIcon className={classes.Icon} />
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6} md={6} lg={6}>
              <Grid container spacing={2} className={classes.container}>
                <Grid item sm={12} md={7} lg={7}>
                  <Typography className={classes.textLabel} variant='subtitle1' component='h2'>
                    VM - Remove Lead After:
                  </Typography>
                </Grid>
                <Grid item className={classes.itemForm} sm={12} md={5} lg={5}>
                  <TextInput
                    type={'number'}
                    variant={'standard'}
                    fullWidth={true}
                    centerMode={true}
                    error={vmRemoveLeadAfterError.error}
                    {...bindVmRemoveLeadAfter}
                  />
                  <Tooltip
                    title='If the VM button has been pressed X amount of times, the lead will automatically be marked as LOST and removed from the active leads page.'
                    arrow
                  >
                    <HelpOutlineIcon className={classes.Icon} />
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6} md={6} lg={6}>
              <Grid container spacing={2} className={classes.container}>
                <Grid item sm={12} md={7} lg={7}>
                  <Typography className={classes.textLabel} variant='subtitle1' component='h2'>
                    Hours to Turn Lead Yellow:
                  </Typography>
                </Grid>
                <Grid item className={classes.itemForm} sm={12} md={5} lg={5}>
                  <TextInput
                    type={'number'}
                    variant={'standard'}
                    fullWidth={true}
                    centerMode={true}
                    error={hoursToTurnLeadYellowError.error}
                    {...bindHoursToTurnLeadYellow}
                  />
                  <Tooltip
                    title='If no action is taken on a lead and there is not a follow up date set, the lead will turn to YELLOW status and an email will be dispacted to the staff member assigned.'
                    arrow
                  >
                    <HelpOutlineIcon className={classes.Icon} />
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6} md={6} lg={6}>
              <Grid container spacing={2} className={classes.container}>
                <Grid item sm={12} md={7} lg={7}>
                  <Typography className={classes.textLabel} variant='subtitle1' component='h2'>
                    Hours to Turn Lead Red:
                  </Typography>
                </Grid>
                <Grid item className={classes.itemForm} sm={12} md={5} lg={5}>
                  <TextInput
                    type={'number'}
                    variant={'standard'}
                    fullWidth={true}
                    centerMode={true}
                    error={hoursToTurnLeadRedError.error}
                    {...bindHoursToTurnLeadRed}
                  />
                  <Tooltip
                    title='If no action is taken on a lead and there is not a follow up date set, the lead will turn to RED status and an email will be dispacted to the entire team.'
                    arrow
                  >
                    <HelpOutlineIcon className={classes.Icon} />
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6} md={6} lg={6}>
              <Grid container spacing={2} className={classes.container}>
                <Grid item sm={12} md={7} lg={7}>
                  <Typography className={classes.textLabel} variant='subtitle1' component='h2'>
                    Hours to Turn Job Yellow:
                  </Typography>
                </Grid>
                <Grid item className={classes.itemForm} sm={12} md={5} lg={5}>
                  <TextInput
                    type={'number'}
                    variant={'standard'}
                    fullWidth={true}
                    centerMode={true}
                    error={hoursToTurnJobYellowError.error}
                    {...bindHoursToTurnJobYellow}
                  />
                  <Tooltip
                    title='If no action is taken on a job and there is not a follow up date set, the job will turn to YELLOW status and an email will be dispacted to the staff member assigned.'
                    arrow
                  >
                    <HelpOutlineIcon className={classes.Icon} />
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6} md={6} lg={6}>
              <Grid container spacing={2} className={classes.container}>
                <Grid item sm={12} md={7} lg={7}>
                  <Typography className={classes.textLabel} variant='subtitle1' component='h2'>
                    Hours to Turn Job Red:
                  </Typography>
                </Grid>
                <Grid item className={classes.itemForm} sm={12} md={5} lg={5}>
                  <TextInput
                    type={'number'}
                    variant={'standard'}
                    fullWidth={true}
                    centerMode={true}
                    error={hoursToTurnJobRedError.error}
                    {...bindHoursToTurnJobRed}
                  />
                  <Tooltip
                    title='If no action is taken on a job and there is not a follow up date set, the job will turn to RED status and an email will be dispacted to the entire team.'
                    arrow
                  >
                    <HelpOutlineIcon className={classes.Icon} />
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6} md={6} lg={6}>
              <Grid container spacing={2} className={classes.container}>
                <Grid item sm={12} md={7} lg={7}>
                  <Typography className={classes.textLabel} variant='subtitle1' component='h2'>
                    Hours to Turn Task Yellow:
                  </Typography>
                </Grid>
                <Grid item className={classes.itemForm} sm={12} md={5} lg={5}>
                  <TextInput
                    type={'number'}
                    variant={'standard'}
                    fullWidth={true}
                    centerMode={true}
                    error={hoursToTurnTaskYellowError.error}
                    {...bindHoursToTurnTaskYellow}
                  />
                  <Tooltip
                    title='If no action is taken on a job and there is not a follow up date set, the job will turn to RED status and an email will be dispacted to the entire team.'
                    arrow
                  >
                    <HelpOutlineIcon className={classes.Icon} />
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6} md={6} lg={6}>
              <Grid container spacing={2} className={classes.container}>
                <Grid item sm={12} md={7} lg={7}>
                  <Typography className={classes.textLabel} variant='subtitle1' component='h2'>
                    Hours to Turn Task Red:
                  </Typography>
                </Grid>
                <Grid item className={classes.itemForm} sm={12} md={5} lg={5}>
                  <TextInput
                    type={'number'}
                    variant={'standard'}
                    fullWidth={true}
                    centerMode={true}
                    error={hoursToTurnTaskRedError.error}
                    {...bindHoursToTurnTaskRed}
                  />
                  <Tooltip
                    title='If no action is taken on a job and there is not a follow up date set, the job will turn to RED status and an email will be dispacted to the entire team.'
                    arrow
                  >
                    <HelpOutlineIcon className={classes.Icon} />
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
    </>
  );
};

export { LeadClientJobSettings };
