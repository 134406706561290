export const convertCCArrayToText = (cc: string[]) => {
  let ccEmailsString = '';

  if (cc && cc?.length > 0) {
    cc?.forEach((email, index) => {
      ccEmailsString += `${index > 0 ? ', ' : ''}${email}`;
    });
  }

  return ccEmailsString;
};
