import { Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import React, { ReactNode } from 'react';
import { Button } from '../Button';
import { useDialogStyles } from './dialogStyle';
import { DialogLayout } from './DialogLayout';

interface DeleteConfirmationDialogProps {
  title: string | ReactNode;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  leftButtonText?: string;
  rightButtonText?: string;
}

const DeleteConfirmationDialog: React.FC<DeleteConfirmationDialogProps> = ({
  title,
  open,
  onClose,
  onConfirm,
  leftButtonText,
  rightButtonText,
}) => {
  const classes = useDialogStyles();
  return (
    <DialogLayout open={open} onClose={onClose}>
      <DialogContentText className='textContent'>{title}</DialogContentText>
      <DialogActions className='deleteModalActions'>
        <Button color='success' onClick={onClose} title={leftButtonText || 'No, Cancel'} />
        <Button color='error' onClick={onConfirm} title={rightButtonText || 'Yes, Delete'} />
      </DialogActions>
    </DialogLayout>
  );
};

export { DeleteConfirmationDialog };
