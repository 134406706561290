import React, { useEffect, useRef, useState } from 'react';
import { useTextInputStyles } from '@/static/stylesheets/atoms';
import { TextField, TextFieldProps } from '@mui/material';
import classNames from 'classnames';
import MenuItem from '@mui/material/MenuItem';

interface TextInputAdditionalProps {
  centerMode?: boolean;
  fullWidth?: boolean;
  options?: {
    title?: string;
    value?: any;
    disabled?: boolean;
  }[];
  errorText?: string | null;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  inputClass?: string;
}

export type TextInputProps = TextInputAdditionalProps & TextFieldProps;

const TextInput: React.FC<TextInputProps> = (props) => {
  const classes = useTextInputStyles();
  const startAdornmentRef = useRef<HTMLInputElement | null>(null);
  const endAdornmentRef = useRef<HTMLInputElement | null>(null);
  const handleFocus = () => {
    if (startAdornmentRef.current) {
      startAdornmentRef.current.style.color = '#2196f3'; // Change color on focus'#4caf50'
    }
    if (endAdornmentRef.current) {
      endAdornmentRef.current.style.color = '#2196f3'; // Change color on focus
    }
  };
  const handleBlur = () => {
    if (startAdornmentRef.current) {
      startAdornmentRef.current.style.color = '#4caf50';
    }
    if (endAdornmentRef.current) {
      endAdornmentRef.current.style.color = '#4caf50';
    }
  };
  const removeInjectedProps = () => {
    const componentProps = { ...props };
    delete componentProps.centerMode;
    delete componentProps.errorText;

    return componentProps;
  };

  const filteredProps = removeInjectedProps();

  useEffect(() => {
    if (startAdornmentRef.current) startAdornmentRef.current.style.color = props?.value ? '#4caf50' : '#4caf50';
    if (endAdornmentRef.current) endAdornmentRef.current.style.color = props?.value ? '#4caf50' : '#4caf50';
  }, []);

  return (
    <>
      <TextField
        {...filteredProps}
        className={classes.root}
        fullWidth={props.fullWidth}
        InputProps={{
          classes: {
            root: classes.textInput,
            input: classNames({
              [classes.centerMode]: props.centerMode,
              [classes.errorMode]: props.error,
              [props?.inputClass ?? '']: props?.inputClass,
            }),
          },
          startAdornment: props?.startAdornment && <div ref={startAdornmentRef}>{props?.startAdornment}</div>,
          endAdornment: props?.endAdornment && <div ref={endAdornmentRef}>{props?.endAdornment}</div>,
        }}
        {...((props?.startAdornment || props?.endAdornment) && { onBlur: handleBlur, onFocus: handleFocus })}
        select={props.select}
        SelectProps={{
          classes: {
            select: classes.selectRoot,
          },
        }}
        helperText={props?.errorText ?? props?.helperText}
      >
        {props.select && props.options
          ? props.options.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                disabled={option?.disabled}
                onClick={(e) => {
                  if (props?.onSelect) {
                    props?.onSelect(e as any);
                  }
                }}
                style={{
                  width: '100%',
                }}
              >
                {option.title}
              </MenuItem>
            ))
          : null}
      </TextField>
    </>
  );
};

export { TextInput };
