import React, { useState, useEffect } from 'react';
import { TextInput } from '@/components/atoms/TextInput';

const SelectPreview = () => {
  const [currency, setCurrency] = useState('EUR');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrency(event.target.value);
  };

  useEffect(() => {
    console.log('value', currency);
  }, [currency]);

  return (
    <>
      <TextInput
        select={true}
        variant={'standard'}
        options={[
          {
            title: 'USD',
            value: 'usd',
          },
          {
            title: 'EUR',
            value: 'eur',
          },
        ]}
        value={currency}
        onChange={handleChange}
        fullWidth={true}
        label={'Age'}
      />
    </>
  );
};

export default SelectPreview;
