import { css } from '@emotion/css';
import { colors, themeParser, useAppTheme } from '@/theme';

export const usePaginationStyles = () => {
  const theme = useAppTheme();

  return {
    root: css`
      display: inline-block;

      button {
        background: ${themeParser(colors.pagination.bg.light, colors.pagination.bg.dark, theme)} !important;
        color: ${themeParser(colors.pagination.text.light, colors.pagination.text.dark, theme)} !important;
      }

      button.Mui-selected {
        background: ${colors.primary} !important;
        color: ${themeParser(colors.pagination.text.dark, colors.pagination.text.light, theme)} !important;
      }

      .MuiPaginationItem-ellipsis {
        color: ${themeParser(colors.pagination.text.light, colors.pagination.text.dark, theme)} !important;
      }
    `,
  };
};
