import React, { useEffect, useState } from 'react';
import { Button } from '@/components/atoms/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { useProfileStyles } from '@/static/stylesheets/molecules';
import { EditorInstance, RichTextEditor } from '@/components/organisms/RichTextEditor';
import { Grid } from '@mui/material';
import { clearSavedUserProfileEmailSignature, saveUserProfileEmailSignature } from '@/redux/actions';
import { useAppSelector } from '@/hooks';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import toast from 'react-hot-toast';

interface SignatureFormProps {
  open?: any;
  emailSignature?: string | null;
  onClose?(): void;
}

const SignatureForm: React.FC<SignatureFormProps> = (props) => {
  const classes = useProfileStyles();
  const { open, onClose } = props;
  const [editor, setEditor] = useState<EditorInstance | null>(null);
  const [emailSignature, setEmailSignature] = useState<string | null>('');

  const savedEmailSignature = useAppSelector(selectHttpState)[CORRELATION_IDS.SAVE_PROFILE_EMAIL_SIGNATURE];

  const handleClickSaveSignature = () => {
    saveUserProfileEmailSignature({
      email_signature: emailSignature,
    });
  };

  useEffect(() => {
    if (savedEmailSignature?.success) {
      toast.success('Saved successfully!');
      clearSavedUserProfileEmailSignature();

      if (typeof onClose === 'function') {
        onClose();
      }
    }

    if (savedEmailSignature?.error) {
      toast.error('Failed to save the email signature!');
      clearSavedUserProfileEmailSignature();
    }
  }, [savedEmailSignature]);

  useEffect(() => {
    if (props?.emailSignature) {
      const signatureText = props?.emailSignature;
      setEmailSignature(signatureText);

      if (editor) {
        editor?.setEditorValue(signatureText);
      }
    }
  }, [props?.emailSignature, editor]);

  return (
    <>
      <Dialog
        open={open}
        maxWidth={'md'}
        className={classes.textEditBody}
        onClose={onClose}
        aria-labelledby='responsive-dialog-title'
      >
        <Grid item sm={12} md={12} lg={12}>
          <h6>Your Personal Email Signature</h6>
          <RichTextEditor
            placeholder={'Enter Text Here'}
            onChange={(value) => setEmailSignature(value)}
            onMount={(editor) => setEditor(editor)}
            height={500}
            disableDragAndDrop={true}
            disableLocalImageSelection={true}
          />
        </Grid>
        <DialogActions>
          <Button
            onClick={handleClickSaveSignature}
            color={'primary'}
            variant={'contained'}
            title={'Set Email Signature'}
            fullWidth={true}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export { SignatureForm };
