import { Button } from '@/components/atoms/Button';
import {
  getExpensesBreakdownReportAction,
  getGeneralTotalPlYearSummaryReports,
  getJobTypesAction,
  getProfitAndLossReportAction,
  getProgressReportAction,
  getWonVsLossReportAction,
} from '@/redux/actions';
import { useReportStyles } from '@/static/stylesheets/molecules';
import { ReportMenuKey } from '@/types';
import { dateWithTime } from '@/utils';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  AssignedToPicker,
  CurrentStatusPicker,
  SelectPeriod,
  SourcePicker,
  StatusPicker,
  TypeOfWorkPicker,
} from './FIlterOptions';

interface ReportFilterProps {
  selectedMenuKey: ReportMenuKey;
  showSelectPeriod?: boolean;
  showSourcePicker?: boolean;
  showTypeOfWorkPicker?: boolean;
  showCurrentStatusPicker?: boolean;
  showStatusPicker?: boolean;
  showAssignedToPicker?: boolean;
  isResetForm: boolean;
  onClickClearFilter(): void;
  resetFormStatus(): void;
}

const ReportFilter: React.FC<ReportFilterProps> = (props) => {
  const classes = useReportStyles();
  const {
    selectedMenuKey,
    showSelectPeriod,
    showCurrentStatusPicker,
    showAssignedToPicker,
    showSourcePicker,
    showTypeOfWorkPicker,
    showStatusPicker,
    onClickClearFilter,
    resetFormStatus,
    isResetForm,
  } = props;

  const { watch } = useFormContext();
  const formValues = watch();

  const applyFilter = async () => {
    if (
      selectedMenuKey === 'GENERAL_TOTAL_P&L_OR_YEAR_SUMMARY' ||
      selectedMenuKey === 'GENERAL_TOTAL_P&L_OR_MONTH_BREAKDOWN'
    ) {
      const { install_type, assigned_to, source } = formValues;
      getGeneralTotalPlYearSummaryReports({
        report_type: selectedMenuKey === 'GENERAL_TOTAL_P&L_OR_YEAR_SUMMARY' ? 'yearly' : 'monthly',
        install_type: install_type || undefined,
        assigned_to: assigned_to && assigned_to.length ? assigned_to : undefined,
        source: source || undefined,
      });
    }

    if (selectedMenuKey === 'GENERAL_EXPENSES_BREAKDOWN') {
      const { install_type, period_start, period_end, assigned_to, source } = formValues;
      getExpensesBreakdownReportAction({
        install_type: install_type || undefined,
        period_start: dateWithTime(period_start)?.trim() || undefined,
        period_end: dateWithTime(period_end)?.trim() || undefined,
        assigned_to: assigned_to && assigned_to.length ? assigned_to : undefined,
        source: source || undefined,
      });
    }

    if (
      selectedMenuKey === 'LEAD_TO_CLIENT_PROFIT_AND_LOSS' ||
      selectedMenuKey === 'LEAD_TO_CLIENT_PENDING_PROFIT_AND_LOSS' ||
      selectedMenuKey === 'TECH_WORK_PROFIT_AND_LOSS' ||
      selectedMenuKey === 'TECH_WORK_PENDING_PROFIT_AND_LOSS'
    ) {
      const { assigned_to, source, period_start, period_end, current_status, install_type, status, job_type } =
        formValues;
      getProfitAndLossReportAction({
        profit_loss_report_type:
          selectedMenuKey === 'LEAD_TO_CLIENT_PROFIT_AND_LOSS' || selectedMenuKey === 'TECH_WORK_PROFIT_AND_LOSS'
            ? 'install_only'
            : 'pending',
        install_type:
          selectedMenuKey === 'TECH_WORK_PROFIT_AND_LOSS' || selectedMenuKey === 'TECH_WORK_PENDING_PROFIT_AND_LOSS'
            ? 'job_install'
            : 'internet_install',
        job_type: job_type || undefined,
        source: source || undefined,
        period_start: dateWithTime(period_start)?.trim() || undefined,
        period_end: dateWithTime(period_end)?.trim() || undefined,
        status: status && status.length ? status : undefined,
        assigned_to: assigned_to && assigned_to.length ? assigned_to : undefined,
        current_status: current_status || undefined,
      });
    }

    if (selectedMenuKey === 'LEAD_TO_CLIENT_LOST_VS_WON' || selectedMenuKey === 'TECH_WORK_LOST_VS_WON') {
      const { assigned_to, job_type, source, install_type, period_start, period_end } = formValues;
      getWonVsLossReportAction({
        report_type: selectedMenuKey === 'LEAD_TO_CLIENT_LOST_VS_WON' ? 'lead' : 'job',
        assigned_to: assigned_to && assigned_to.length ? assigned_to : undefined,
        job_type: job_type || undefined,
        source: source || undefined,
        install_type: install_type || undefined,
        period_start: dateWithTime(period_start)?.trim() || undefined,
        period_end: dateWithTime(period_end)?.trim() || undefined,
      });
    }

    if (selectedMenuKey === 'LEAD_TO_CLIENT_PROGRESS' || selectedMenuKey === 'TECH_WORK_PROGRESS') {
      const { assigned_to, job_type, source, status, period_start, period_end, install_type } = formValues;
      getProgressReportAction({
        report_type: selectedMenuKey === 'LEAD_TO_CLIENT_PROGRESS' ? 'lead' : 'job',
        assigned_to: assigned_to && assigned_to.length ? assigned_to : undefined,
        status: status && status.length ? status : undefined,
        job_type: job_type || undefined,
        source: source || undefined,
        install_type: install_type || undefined,
        period_start: dateWithTime(period_start)?.trim() || undefined,
        period_end: dateWithTime(period_end)?.trim() || undefined,
      });
    }

    resetFormStatus();
  };

  useEffect(() => {
    if (isResetForm) {
      applyFilter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResetForm]);

  useEffect(() => {
    getJobTypesAction();
  }, []);

  return (
    <div className={classes.filterRoot}>
      <div className={'report-filter-header'}>
        <div className={'title'}>Report Filters</div>
        <div className={'action-buttons'}>
          <Button
            variant={'contained'}
            color={'error'}
            title={'Clear Filters'}
            size={'small'}
            onClick={onClickClearFilter}
          />
          <Button
            variant={'contained'}
            color={'primary'}
            title={'Apply Filters'}
            size={'small'}
            onClick={applyFilter}
          />
        </div>
      </div>

      <div className={'report-filter-fields'}>
        {showSelectPeriod && <SelectPeriod />}
        {showTypeOfWorkPicker && <TypeOfWorkPicker selectedMenuKey={selectedMenuKey} />}
        {showAssignedToPicker && <AssignedToPicker />}
        {showStatusPicker && <StatusPicker />}
        {showCurrentStatusPicker && <CurrentStatusPicker selectedMenuKey={selectedMenuKey} />}
        {showSourcePicker && <SourcePicker />}
      </div>
    </div>
  );
};

export { ReportFilter };
