import React, { useState } from 'react';
import { AppTheme, ThemeContext, themeState } from './ThemeContext';
import { getCurrentTheme, setTheme } from '@/theme/utils';

export function ThemeProvider(props: { children: React.ReactNode }): React.ReactElement {
  const [state, setState] = useState(themeState);
  const setAppTheme = (theme: AppTheme) => {
    setState({
      ...state,
      appTheme: theme,
    });
    setTheme(theme);
  };

  const __checkAndUpdateTheme = () => {
    if (getCurrentTheme() !== state.appTheme) {
      setAppTheme(getCurrentTheme());
    }
  };

  React.useEffect(() => {
    const __themeCheckerInterval = setInterval(__checkAndUpdateTheme, 500);
    return () => clearInterval(__themeCheckerInterval);
  });

  return (
    <ThemeContext.Provider
      value={{
        ...state,
        setAppTheme,
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
}
