import React from 'react';
import { useSettingsCardStyle } from '@/static/stylesheets/organisms/settingsCardStyles';

interface SettingsCardProps {
  children?: React.ReactNode;
  title?: String;
}

const SettingsCard: React.FC<SettingsCardProps> = (props) => {
  const classes = useSettingsCardStyle();
  return (
    <>
      <div className={classes.root}>
        {props.title && <div className={classes.cardHeader}>{props.title}</div>}
        {props.children}
      </div>
    </>
  );
};

export { SettingsCard };
