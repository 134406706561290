import { Button } from '@/components/atoms/Button';
import { DeleteConfirmationDialog } from '@/components/atoms/Dialog';
import { useAppSelector } from '@/hooks';
import { deleteLocationAction, updateLocationAction } from '@/redux/actions';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ILocation } from './PricingSetting';
import { ProposalAndTerms } from './ProposalAndTerms';
import { usePricingStyle } from './pricingStyle';

interface IProps {
  data: ILocation;
}

const FormSchema = yup
  .object({
    packages: yup
      .array(
        yup.object({
          id: yup.string().optional(),
          location_id: yup.string().required(),
          download_bandwidth: yup.string().required('Download brandwidth is required!'),
          upload_bandwidth: yup.string().required('Upload brandwidth is required!'),
          price: yup.string().required('Price is required!'),
        }),
      )
      .required(),
    terms: yup
      .array(
        yup.object({
          id: yup.string().optional(),
          location_id: yup.string().required(),
          install_price: yup.string().required('Price is required!'),
          term_years: yup.string().required('Years is required!'),
        }),
      )
      .required(),
  })
  .required();

const SingleResidential: React.FC<IProps> = ({ data }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isOpenDeleteAlert, setIsOpenDeleteAlert] = useState<boolean>(false);

  const classes = usePricingStyle();
  const onToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const updateLocation = useAppSelector(selectHttpState)[CORRELATION_IDS.UPDATE_LOCATION];

  // form
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      packages: data.packages || [],
      terms: data.terms || [],
    },
    resolver: yupResolver(FormSchema),
  });

  const onSubmit = methods.handleSubmit((formData) => {
    updateLocationAction({
      id: data.id,
      packages: formData.packages,
      terms: formData.terms,
    });
  });

  const _handleConfirmDelete = () => {
    deleteLocationAction(data.id);
    setIsOpenDeleteAlert(false);
  };

  useEffect(() => {
    methods.setValue('packages', data.packages);
    methods.setValue('terms', data.terms);
  }, [data]);

  return (
    <>
      <li>
        <div className={classes.accordionHeader} onClick={onToggleExpand}>
          <div className={classes.letterIcon}>{data.location_type === 'RESIDENTIAL' ? 'R' : 'C'}</div>
          <div>{data.location_name}</div>
        </div>
        <div
          className={classnames(classes.accordionBody, {
            [classes.show]: isExpanded,
            [classes.hide]: !isExpanded,
          })}
        >
          <FormProvider {...methods}>
            <ProposalAndTerms data={data} />
          </FormProvider>
          <Stack m={1} direction='row' justifyContent={'space-between'}>
            <Button
              sx={{
                opacity: 0.5,
                height: '30px',
                lineHeight: '30px',
                transition: 'all .1s ease-in-out',
                '&:hover': {
                  opacity: 1,
                },
              }}
              variant='contained'
              title='DELETE LOCATION'
              color='error'
              onClick={() => setIsOpenDeleteAlert(true)}
            />
            <Button
              sx={{
                opacity: 0.5,
                height: '30px',
                lineHeight: '30px',
                transition: 'all .1s ease-in-out',
                '&:hover': {
                  opacity: 1,
                },
              }}
              onClick={onSubmit}
              variant='contained'
              title='SAVE'
              color='success'
              loading={updateLocation?.loading || updateLocation?.loading}
            />
          </Stack>
        </div>
        <DeleteConfirmationDialog
          title={'Are you sure you want to delete this location?'}
          open={isOpenDeleteAlert}
          onClose={() => setIsOpenDeleteAlert(false)}
          onConfirm={_handleConfirmDelete}
        />
      </li>
    </>
  );
};

export { SingleResidential };
