import React, { useEffect, useState } from 'react';
import { Button } from '@/components/atoms/Button';
import { useEmailTemplateStyles } from '@/static/stylesheets/molecules';
import { TextInput } from '@/components/atoms/TextInput';
import { Typography, Grid, Stack, FormLabel, FormControl } from '@mui/material';
import Switch from '@mui/material/Switch';
import { useAppSelector, useInput } from '@/hooks';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import {
  clearSentSmtpTestMail,
  clearUpdatedSmtpSettings,
  getSmtpSettings,
  smtpSendTestMail,
  updateSmtpSettings,
} from '@/redux/actions';
import toast from 'react-hot-toast';
import { validateEmail } from '@/utils';
import { SmtpSettingsParams } from '@/types';
import { Loader } from '@/components/atoms/Loader';
import { alertMessages } from '@/config';

interface SMTPSettingsProps {}

const SMTPSettings: React.FC<SMTPSettingsProps> = () => {
  const classes = useEmailTemplateStyles();

  const {
    value: mode,
    setValue: setMode,
    error: modeError,
    bind: bindMode,
  } = useInput('', {
    required: 'Please select a mode!',
  });

  const {
    value: hostname,
    setValue: setHostname,
    error: hostnameError,
    bind: bindHostname,
  } = useInput('', {
    required: 'Please enter hostname!',
  });

  const {
    value: port,
    setValue: setPort,
    error: portError,
    bind: bindPort,
  } = useInput('', {
    required: 'Please enter port!',
  });

  const {
    value: username,
    setValue: setUsername,
    error: usernameError,
    bind: bindUsername,
  } = useInput('', {
    validate(value) {
      if (!validateEmail(value)) {
        return 'Please enter a valid email address!';
      }
      return true;
    },
  });

  const {
    value: password,
    setValue: setPassword,
    error: passwordError,
    bind: bindPassword,
  } = useInput('', {
    required: 'Please enter password',
  });

  const {
    value: senderAddress,
    setValue: setSenderAddress,
    error: senderAddressError,
    bind: bindSenderAddress,
  } = useInput('', {
    validate(value) {
      if (!validateEmail(value)) {
        return 'Please enter a valid email address!';
      }
      return true;
    },
  });

  const {
    value: testAddress,
    setValue: setTestAddress,
    error: testAddressError,
    bind: bindTestAddress,
  } = useInput('', {
    validate(value) {
      if (!validateEmail(value)) {
        return 'Please enter a valid email address!';
      }
      return true;
    },
  });

  const [isSelfSignCertificateEnabled, setIsSelfSignCertificateEnabled] = useState<boolean>(true);
  const [isAuthEnabled, setIsAuthEnabled] = useState<boolean>(true);

  const smtpSettingsData = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_SMTP_SETTINGS];
  const updatedSmtpSettings = useAppSelector(selectHttpState)[CORRELATION_IDS.UPDATE_SMTP_SETTINGS];
  const sentSmtpTestMail = useAppSelector(selectHttpState)[CORRELATION_IDS.SMTP_SEND_TEST_MAIL];

  const fetchSmtpSettings = () => getSmtpSettings();

  const saveSmtpSettings = () => {
    if (
      modeError?.check() ||
      hostnameError?.check() ||
      portError?.check() ||
      usernameError?.check() ||
      passwordError?.check() ||
      senderAddressError?.check() ||
      testAddressError?.check()
    ) {
      toast.error(alertMessages.generalSettings.smtpSettings.submitValidationError);
      return;
    }

    updateSmtpSettings({
      hostname,
      port,
      is_auth_enabled: isAuthEnabled,
      is_self_sign_certificate_enabled: isSelfSignCertificateEnabled,
      mode,
      password,
      username,
      sender_address: senderAddress,
      test_address: testAddress,
    });
  };

  const sendTestMail = () => {
    if (
      modeError?.check() ||
      hostnameError?.check() ||
      portError?.check() ||
      usernameError?.check() ||
      passwordError?.check() ||
      senderAddressError?.check() ||
      testAddressError?.check()
    ) {
      toast.error(alertMessages.generalSettings.smtpSettings.sendTestMailValidationError);
      return;
    }

    smtpSendTestMail({
      hostname,
      port,
      is_auth_enabled: isAuthEnabled,
      is_self_sign_certificate_enabled: isSelfSignCertificateEnabled,
      mode,
      password,
      username,
      sender_address: senderAddress,
      test_address: testAddress,
    });
  };

  useEffect(() => {
    if (updatedSmtpSettings?.success) {
      toast.success(alertMessages.generalSettings.smtpSettings.saveSuccess);
      clearUpdatedSmtpSettings();
    }

    if (updatedSmtpSettings?.error) {
      toast.error(alertMessages.generalSettings.smtpSettings.saveError);
      clearUpdatedSmtpSettings();
    }
  }, [updatedSmtpSettings]);

  useEffect(() => {
    if (sentSmtpTestMail?.success) {
      toast.success(alertMessages.generalSettings.smtpSettings.testMailSendSuccess);
      clearSentSmtpTestMail();
    }

    if (sentSmtpTestMail?.error) {
      toast.error(alertMessages.generalSettings.smtpSettings.testMailSendError);
      clearSentSmtpTestMail();
    }
  }, [sentSmtpTestMail]);

  useEffect(() => {
    if (smtpSettingsData?.success && smtpSettingsData?.data) {
      const data: SmtpSettingsParams = smtpSettingsData?.data;
      setMode(data?.mode);
      setHostname(data?.hostname);
      setPort(data?.port);
      setUsername(data?.username);
      setPassword(data?.password);
      setSenderAddress(data?.sender_address);
      setTestAddress(data?.test_address);
      setIsSelfSignCertificateEnabled(Number(data?.is_self_sign_certificate_enabled) === 1);
      setIsAuthEnabled(Number(data?.is_auth_enabled) === 1);
    }
  }, [smtpSettingsData]);

  useEffect(() => {
    fetchSmtpSettings();
  }, []);

  return (
    <>
      <div className={classes.cardHeader}>
        <Typography variant='subtitle1' component='div'>
          SMTP Settings
        </Typography>
        <div>
          <Button
            title={'Test SMTP'}
            size={'small'}
            onClick={sendTestMail}
            loading={sentSmtpTestMail?.loading}
            style={{
              marginRight: 10,
            }}
          />
          <Button
            title={'Save Settings'}
            color={'success'}
            size={'small'}
            onClick={saveSmtpSettings}
            loading={updatedSmtpSettings?.loading}
          />
        </div>
      </div>

      {smtpSettingsData?.loading ? (
        <Loader />
      ) : (
        <Grid container spacing={2} className={classes.container}>
          <Grid item sm={6} md={2} lg={2}>
            <TextInput
              select={true}
              variant={'standard'}
              options={[
                { title: 'Plain Text', value: 'plain_text' },
                { title: 'TLS', value: 'tls' },
                { title: 'SSL', value: 'ssl' },
              ]}
              fullWidth={true}
              {...bindMode}
              error={modeError.error}
              errorText={modeError.message}
              label={'Security Mode'}
            />
          </Grid>
          <Grid item sm={12} md={4} lg={4}>
            <TextInput
              className={classes.textAria}
              variant={'standard'}
              fullWidth={true}
              centerMode={false}
              label={'Hostname'}
              {...bindHostname}
              error={hostnameError.error}
              errorText={hostnameError.message}
            />
          </Grid>
          <Grid item sm={12} md={2} lg={2}>
            <TextInput
              className={classes.textAria}
              variant={'standard'}
              fullWidth={true}
              centerMode={false}
              label={'SMTP Port'}
              {...bindPort}
              error={portError.error}
              errorText={portError.message}
            />
          </Grid>
          <Grid item sm={6} md={2} lg={2}>
            <FormControl component='fieldset' variant='standard'>
              <FormLabel className={classes.textLabel} component='legend'>
                Allow Self-sign Cert
              </FormLabel>
              <Stack direction='row' spacing={1} alignItems='center' justifyContent={'center'}>
                <Typography className={classes.textLabel}>Off</Typography>
                <Switch
                  checked={isSelfSignCertificateEnabled}
                  onChange={() => setIsSelfSignCertificateEnabled(!isSelfSignCertificateEnabled)}
                />
                <Typography className={classes.textLabel}>On</Typography>
              </Stack>
            </FormControl>
          </Grid>
          <Grid item sm={6} md={2} lg={2}>
            <FormControl component='fieldset' variant='standard'>
              <FormLabel className={classes.textLabel} component='legend'>
                Enable Auth
              </FormLabel>
              <Stack direction='row' spacing={1} alignItems='center'>
                <Typography className={classes.textLabel}>Off</Typography>
                <Switch checked={isAuthEnabled} onChange={() => setIsAuthEnabled(!isAuthEnabled)} />
                <Typography className={classes.textLabel}>On</Typography>
              </Stack>
            </FormControl>
          </Grid>
          <Grid item sm={6} md={3} lg={3}>
            <TextInput
              className={classes.textAria}
              variant={'standard'}
              fullWidth={true}
              centerMode={false}
              label={'Username'}
              {...bindUsername}
              error={usernameError.error}
              errorText={usernameError.message}
            />
          </Grid>
          <Grid item sm={6} md={3} lg={3}>
            <TextInput
              type={'password'}
              className={classes.textAria}
              variant={'standard'}
              fullWidth={true}
              centerMode={false}
              label={'Password'}
              {...bindPassword}
              error={passwordError.error}
              errorText={passwordError.message}
            />
          </Grid>
          <Grid item sm={6} md={3} lg={3}>
            <TextInput
              className={classes.textAria}
              variant={'standard'}
              fullWidth={true}
              centerMode={false}
              label={'Sender Address'}
              {...bindSenderAddress}
              error={senderAddressError.error}
              errorText={senderAddressError.message}
            />
          </Grid>
          <Grid item sm={6} md={3} lg={3}>
            <TextInput
              className={classes.textAria}
              variant={'standard'}
              fullWidth={true}
              centerMode={false}
              label={'Test Email'}
              {...bindTestAddress}
              error={testAddressError.error}
              errorText={testAddressError.message}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export { SMTPSettings };
