import { Dialog, DialogContent, PaperProps, SxProps, Theme } from '@mui/material';
import React from 'react';
import { useDialogStyles } from './dialogStyle';

interface DialogProps {
  open: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  sx?: SxProps<Theme>;
  PaperProps?: PaperProps;
}

const DialogLayout: React.FC<DialogProps> = ({ open, onClose, children, sx, PaperProps }) => {
  const classes = useDialogStyles();
  return (
    <Dialog PaperProps={PaperProps} sx={sx} open={open} onClose={onClose} className={classes.root}>
      <DialogContent className='modalContent'>{children}</DialogContent>
    </Dialog>
  );
};

export { DialogLayout };
