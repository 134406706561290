import React, { useEffect } from 'react';
import { Checkbox, Dialog } from '@mui/material';
import classNames from 'classnames';
import { Button } from '@/components/atoms/Button';
import FlagIcon from '@mui/icons-material/Flag';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { TextInput } from '@/components/atoms/TextInput';
import { DatePicker } from '@/components/atoms/DatePicker';
import { TaskItem, UserParams } from '@/types';
import { useAppSelector, useComponentState, useInput } from '@/hooks';
import { TaskGridLoader } from '@/components/organisms/Tasks';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { ChildTaskUpdateActionProps } from '@/components/molecules/Tasks';
import { openConfirmationDialog } from '@/redux/actions';
import toast from 'react-hot-toast';
import { alertMessages } from '@/config';
import { TimePicker } from '@/components/atoms/TimePicker';
import { formatDateTime, getTaskPriorityByIndex, getTaskPriorityIndex } from '@/utils';
import { format } from 'date-fns';
import { useTaskStyles } from '@/static/stylesheets/molecules/Tasks';

interface ActiveTasksProps {
  loading?: boolean;
  tasks?: TaskItem[];
  selectedActiveTaskIndexForEdit: number | null;

  addData(data: TaskItem): void;

  updateData(options: ChildTaskUpdateActionProps): void;

  deleteData(index: number): void;

  onSelectActiveTask(index: number): void;
}

const ActiveTasks: React.FC<ActiveTasksProps> = (props) => {
  const classes = useTaskStyles();
  const {
    addData,
    updateData,
    deleteData,
    onSelectActiveTask,
    tasks: activeTasks,
    selectedActiveTaskIndexForEdit,
  } = props;

  const staffMemberList = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_TASK_STAFF_MEMBERS];
  const staffMembers: UserParams[] = staffMemberList?.data?.data ?? [];

  const {
    state: { formDialogOpen, selectedTaskIndexForEdit },
    setState,
  } = useComponentState<{
    formDialogOpen: boolean;
    selectedTaskIndexForEdit: number | null;
  }>({
    formDialogOpen: false,
    selectedTaskIndexForEdit: null,
  });

  const editTaskData: TaskItem | null =
    activeTasks && selectedTaskIndexForEdit !== null ? activeTasks[selectedTaskIndexForEdit] : null;

  const {
    value: selectedTitle,
    setValue: setSelectedTitle,
    bind: bindSelectedTitle,
    reset: resetSelectedTitle,
    error: selectedTitleError,
  } = useInput('', {
    required: 'Please enter title!',
  });

  const {
    value: selectedAssignedTo,
    setValue: setSelectedAssignedTo,
    bind: bindSelectedAssignedTo,
    reset: resetSelectedAssignedTo,
    error: selectedAssignedToError,
  } = useInput('', {
    required: 'Select team member!',
  });

  const {
    value: selectedPriority,
    setValue: setSelectedPriority,
    bind: bindSelectedPriority,
    reset: resetSelectedPriority,
    error: selectedPriorityError,
  } = useInput('', {
    // required: "Please select priority!",
  });

  const {
    value: selectedDueDate,
    setValue: setSelectedDueDate,
    bind: bindSelectedDueDate,
    reset: resetSelectedDueDate,
    error: selectedDueDateError,
  } = useInput('', {
    // required: "Please input due date!",
  });

  const {
    value: selectedDueTime,
    setValue: setSelectedDueTime,
    bind: bindSelectedDueTime,
    reset: resetSelectedDueTime,
    error: selectedDueTimeError,
  } = useInput('', {
    // required: "Please input due time!",
  });

  const resetFields = () => {
    resetSelectedTitle();
    resetSelectedAssignedTo();
    resetSelectedPriority();
    resetSelectedDueDate();
    resetSelectedDueTime();
  };

  const submitTask = () => {
    selectedTitleError.check();
    selectedAssignedToError.check();
    selectedPriorityError.check();
    selectedDueDateError.check();
    selectedDueTimeError.check();

    if (
      selectedTitleError.check() ||
      selectedAssignedToError.check() ||
      selectedPriorityError.check() ||
      selectedDueDateError.check() ||
      selectedDueTimeError.check()
    ) {
      toast.error(alertMessages.tasks.childTaskSubmitValidationError);
      return;
    }

    const data: TaskItem = {
      title: selectedTitle,
      priority: getTaskPriorityByIndex(selectedPriority),
      due_datetime: {
        date: formatDateTime(new Date(selectedDueDate), 'MM/d/yyyy'),
        time: selectedDueTime,
      },
      assigned_to: {
        id: selectedAssignedTo,
      },
    };

    if (selectedTaskIndexForEdit !== null) {
      updateData({
        index: selectedTaskIndexForEdit,
        payload: {
          ...data,
        },
      });
    } else {
      addData({
        ...data,
        is_parent: false,
        sub_task_body: '',
      });
    }

    handleCloseFormDialog();
  };

  const handleOpenFormDialog = (taskIndex: number | null = null) => {
    if (taskIndex === null) {
      resetFields();
    }

    setState({
      formDialogOpen: true,
      selectedTaskIndexForEdit: taskIndex,
    });
  };
  const handleCloseFormDialog = () => {
    setState({
      formDialogOpen: false,
      selectedTaskIndexForEdit: null,
    });
  };

  useEffect(() => {
    if (editTaskData) {
      setSelectedTitle(editTaskData?.title);
      setSelectedPriority(getTaskPriorityIndex(editTaskData?.priority));
      setSelectedAssignedTo(editTaskData?.assigned_to?.id);
      setSelectedDueDate(editTaskData?.due_datetime?.date);
      setSelectedDueTime(editTaskData?.due_datetime?.timestamp);
    }
  }, [editTaskData]);

  return (
    <>
      <div className={'taskListTableContainer'}>
        <div className={'title-bar'}>
          <span>
            {`Active Tasks`} {!props?.loading && ` | ${activeTasks?.length} Subtasks`}
          </span>
          <span className={'add-button'} onClick={() => handleOpenFormDialog()}>
            <AddCircleOutlineIcon />
          </span>
        </div>
        <table>
          <thead>
            <tr>
              <th className={'center'}>Done</th>
              <th className={'left'} style={{ width: '150px' }}>
                Task
              </th>
              <th className={'left'} style={{ width: '150px' }}>
                Assigned
              </th>
              <th className={'center'}>Due Date</th>
              <th className={'center'}>Est. Time</th>
              <th className={'center'}>Priority</th>
              <th className={'center'}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {props?.loading ? (
              <TaskGridLoader containerStyle={{ padding: 10 }} colSpan={7} />
            ) : (
              <>
                {activeTasks &&
                  activeTasks?.length > 0 &&
                  activeTasks?.map((task, index) => (
                    <tr
                      key={index}
                      className={classNames({
                        active: selectedActiveTaskIndexForEdit !== null && index === selectedActiveTaskIndexForEdit,
                      })}
                      onClick={() => onSelectActiveTask(index)}
                    >
                      <td className={'center'}>
                        <Checkbox
                          checked={task?.is_complete}
                          onClick={() =>
                            updateData({
                              index,
                              payload: {
                                is_complete: !task?.is_complete,
                              },
                            })
                          }
                          disabled={task?.is_running}
                        />
                      </td>
                      <td className={'left'}>{task?.title}</td>
                      <td className={'left'}>
                        <TextInput
                          select={true}
                          variant={'standard'}
                          options={[
                            {
                              title: 'Assigned To',
                              value: '--empty--',
                              disabled: true,
                            },
                            ...(staffMembers && staffMembers?.length > 0
                              ? staffMembers?.map((item) => ({
                                  title: `${item?.first_name ?? ''} ${item?.last_name ?? ''}`,
                                  value: item?.id,
                                }))
                              : []),
                          ]}
                          fullWidth={true}
                          value={task?.assigned_to?.id}
                          onChange={(e) => {
                            const id = e?.target?.value;
                            updateData({
                              index,
                              payload: {
                                assigned_to: {
                                  ...task?.assigned_to,
                                  id,
                                },
                              },
                            });
                          }}
                        />
                      </td>
                      <td className={'center'}>
                        {task?.due_datetime?.date && typeof task?.due_datetime?.date === 'object'
                          ? format(new Date(task?.due_datetime?.date), 'MM/d/yyyy')
                          : task?.due_datetime?.date}
                      </td>
                      <td className={'center'}>{task?.estimated_hours ?? 0} Hours</td>
                      <td className={'center'}>
                        <FlagIcon
                          className={classNames({
                            'no-priority': task?.priority === 'No Priority',
                            'low-priority': task?.priority === 'Low Priority',
                            'medium-priority': task?.priority === 'Medium Priority',
                            'high-priority': task?.priority === 'High Priority',
                          })}
                          onClick={() => {
                            const currentPriorityIndex = task?.priority;
                            let nextPriority: TaskItem['priority'];

                            switch (currentPriorityIndex) {
                              case 'No Priority':
                                nextPriority = 'Low Priority';
                                break;
                              case 'Low Priority':
                                nextPriority = 'Medium Priority';
                                break;
                              case 'Medium Priority':
                                nextPriority = 'High Priority';
                                break;
                              case 'High Priority':
                                nextPriority = 'No Priority';
                                break;
                            }

                            updateData({
                              index,
                              payload: {
                                priority: nextPriority,
                              },
                            });
                          }}
                        />
                      </td>
                      <td className={'center'}>
                        <div className={'actions'}>
                          <Button
                            title={<EditIcon />}
                            color={'primary'}
                            size={'small'}
                            onClick={() => handleOpenFormDialog(index)}
                          />
                          <Button
                            title={<DeleteIcon />}
                            color={'error'}
                            size={'small'}
                            onClick={() => {
                              openConfirmationDialog({
                                title: 'Delete',
                                message: 'Are you sure to delete this task',
                                type: 'error',
                                textCenter: 'center',
                                onClickCancel() {},
                                onClickConfirm() {
                                  deleteData(index);
                                },
                              });
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
              </>
            )}
          </tbody>
        </table>
      </div>

      <Dialog
        open={formDialogOpen}
        onClose={handleCloseFormDialog}
        sx={{
          '& .content': {
            padding: '10px 0',

            '& .form-field': {
              padding: '8px 0',
            },
          },
        }}
        classes={{
          paper: classes.activeTaskDialogPaper,
        }}
        hideBackdrop={true}
      >
        <div className={classes.activeTaskDialogTitleBar}>{editTaskData ? 'EDIT TASK' : 'ADD NEW TASK'}</div>
        <div className={'content'}>
          <div className={'form-field'} style={{ marginTop: selectedTitle ? '12px' : 'auto' }}>
            <TextInput
              variant={'standard'}
              fullWidth={true}
              label={selectedTitle ? undefined : 'Title'}
              error={selectedTitleError.error}
              errorText={selectedTitleError.message}
              {...bindSelectedTitle}
            />
          </div>
          <div className={'form-field'} style={{ marginTop: selectedPriority ? '12px' : 'auto' }}>
            <TextInput
              select={true}
              variant={'standard'}
              options={[
                {
                  title: 'No Priority',
                  value: '0',
                },
                {
                  title: 'Low Priority',
                  value: '1',
                },
                {
                  title: 'Medium Priority',
                  value: '2',
                },
                {
                  title: 'High Priority',
                  value: '3',
                },
              ]}
              fullWidth={true}
              label={selectedPriority ? undefined : 'Priority'}
              error={selectedPriorityError.error}
              errorText={selectedPriorityError.message}
              {...bindSelectedPriority}
            />
          </div>
          <div className={'form-field'} style={{ marginTop: selectedAssignedTo ? '12px' : 'auto' }}>
            <TextInput
              select={true}
              variant={'standard'}
              options={[
                {
                  title: 'Assigned To',
                  value: '--empty--',
                  disabled: true,
                },
                ...(staffMembers && staffMembers?.length > 0
                  ? staffMembers?.map((item) => ({
                      title: `${item?.first_name ?? ''} ${item?.last_name ?? ''}`,
                      value: item?.id,
                    }))
                  : []),
              ]}
              fullWidth={true}
              label={selectedAssignedTo ? undefined : 'Assigned To'}
              error={selectedAssignedToError.error}
              errorText={selectedAssignedToError.message}
              {...bindSelectedAssignedTo}
            />
          </div>

          <div className={'form-field date-picker-input'}>
            <DatePicker
              label={selectedDueDate ? undefined : 'Due Date'}
              fullWidth={true}
              value={bindSelectedDueDate.value}
              onChange={(value) => {
                bindSelectedDueDate.onChange({
                  target: {
                    value,
                  },
                } as any);
              }}
              error={selectedDueDateError.error}
              errorText={selectedDueDateError.message}
            />
          </div>
          <div className={'form-field date-picker-input'}>
            <TimePicker
              label={selectedDueTime ? undefined : 'Due Time'}
              value={bindSelectedDueTime.value}
              fullWidth={true}
              onChange={(value) => {
                bindSelectedDueTime.onChange({
                  target: {
                    value,
                  },
                } as any);
              }}
              error={selectedDueTimeError.error}
              errorText={selectedDueTimeError.message}
            />
          </div>

          <div className={'form-field'}>
            <Button title={'Save'} color={'success'} fullWidth={true} onClick={submitTask} />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export { ActiveTasks };
