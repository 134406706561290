import React, { useEffect, useState } from 'react';

import { Button } from '@/components/atoms/Button';
import { DialogLayout } from '@/components/atoms/Dialog';
import Tabs from '@/components/atoms/Tabs/Tabs';
import { TextInput } from '@/components/atoms/TextInput';
import { alertMessages } from '@/config';
import { useAppSelector } from '@/hooks';
import {
  clearCreateLocation,
  clearDeleteLocation,
  clearUpdateLocation,
  createLocationAction,
  getLocationsAction,
} from '@/redux/actions';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { Box, Grid, Stack, Typography } from '@mui/material';
import toast from 'react-hot-toast';
import { SingleResidential } from './SingleResidential';
import { usePricingStyle } from './pricingStyle';

type TabItemValue = 'r' | 'c';
interface TabItem {
  label: string;
  value: TabItemValue;
}

export interface ILocationPackage {
  id: string;
  location_id: string;
  download_bandwidth: string;
  upload_bandwidth: string;
  price: string;
}
export interface ILocationTerm {
  id: string;
  location_id: string;
  install_price: string;
  term_years: string;
}

export interface ILocation {
  id: string;
  location_name: string;
  location_type: string;
  packages: ILocationPackage[];
  terms: ILocationTerm[];
}

const PricingSetting: React.FC = () => {
  const [activeTab, setActiveTab] = useState<TabItemValue>('r');
  const [isOpenAddModal, setIsOpenAddModal] = useState<boolean>(false);
  const [location, setLocation] = useState<string>();

  const createLocation = useAppSelector(selectHttpState)[CORRELATION_IDS.CREATE_LOCATION];
  const getAlllocations = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_LOCATIONS];
  const updateLocation = useAppSelector(selectHttpState)[CORRELATION_IDS.UPDATE_LOCATION];
  const deleteLocation = useAppSelector(selectHttpState)[CORRELATION_IDS.DELETE_LOCATION];

  const classes = usePricingStyle();

  const items: TabItem[] = [
    {
      label: 'Residential',
      value: 'r',
    },
    {
      label: 'Company',
      value: 'c',
    },
  ];

  const handleActiveTab = (tab: TabItemValue) => {
    setActiveTab(tab);
  };
  const _handleAddNewResidentialLocation = () => {
    setIsOpenAddModal(true);
  };
  const _handleAddNewCompany = () => {
    setIsOpenAddModal(true);
  };
  const _handleAddModal = () => {
    setIsOpenAddModal(false);
  };

  const onSubmitLocation = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && location) {
      createLocationAction({
        location_name: location,
        location_type: activeTab === 'r' ? 'RESIDENTIAL' : 'COMPANY',
      });

      setLocation('');
      setTimeout(() => {
        setIsOpenAddModal(false);
      }, 300);
    }
  };

  useEffect(() => {
    if (createLocation && createLocation?.success) {
      toast.success(alertMessages.generalSettings.pricing.createSuccess);
      clearCreateLocation();
    }

    if (createLocation && createLocation?.error) {
      toast.error(alertMessages.generalSettings.pricing.createError);
      clearCreateLocation();
    }
  }, [createLocation]);

  useEffect(() => {
    if (deleteLocation && deleteLocation?.success) {
      toast.success(alertMessages.generalSettings.pricing.deleteSuccess);
      clearDeleteLocation();
    }

    if (deleteLocation && deleteLocation?.error) {
      toast.error(alertMessages.generalSettings.pricing.deleteError);
      clearDeleteLocation();
    }
  }, [deleteLocation]);

  useEffect(() => {
    if (updateLocation && updateLocation?.success) {
      toast.success(alertMessages.generalSettings.pricing.saveSuccess);
      clearUpdateLocation();
    }

    if (updateLocation && updateLocation?.error) {
      toast.error(alertMessages.generalSettings.pricing.saveError);
      clearUpdateLocation();
    }
  }, [updateLocation]);

  useEffect(() => {
    getLocationsAction({
      page: 1,
      limit: 'all',
      location_type: activeTab === 'r' ? 'residential' : 'company',
    });
  }, [activeTab, deleteLocation, updateLocation, createLocation]);

  return (
    <>
      <div className={classes.root}>
        <Tabs<TabItemValue> items={items} activeTab={activeTab} handleActiveTab={handleActiveTab} />
        {activeTab === 'r' && (
          <Box>
            <Grid className={'button'} sm={12} style={{ margin: '15px' }}>
              <Button
                type='button'
                onClick={_handleAddNewResidentialLocation}
                variant={'contained'}
                color={'secondary'}
                title={'ADD NEW RESIDENTIAL LOCATION'}
                fullWidth={true}
              />
            </Grid>
            <ul className={classes.according}>
              {(getAlllocations?.data?.data ?? []).map((item: any) => (
                <SingleResidential data={item} key={item.id} />
              ))}
            </ul>
          </Box>
        )}
        {activeTab === 'c' && (
          <>
            <Grid className={'button'} sm={12} style={{ margin: '15px' }}>
              <Button
                type='button'
                onClick={_handleAddNewCompany}
                variant={'contained'}
                title={'ADD NEW COMPANY LOCATION'}
                fullWidth={true}
              />
            </Grid>
            <ul className={classes.according}>
              {(getAlllocations?.data?.data ?? []).map((item: any) => (
                <SingleResidential data={item} key={item.id} />
              ))}
            </ul>
          </>
        )}
        <DialogLayout
          open={isOpenAddModal}
          onClose={_handleAddModal}
          PaperProps={{
            style: {
              top: '17%',
              position: 'absolute',
            },
          }}
          sx={{
            '& .MuiDialog-paper': {
              width: { base: '90%', md: '600px' },
              maxWidth: 'none',
            },
          }}
        >
          <Stack spacing={3} sx={{ marginBottom: '30px' }}>
            <Typography fontSize={18} textAlign={'center'} className={classes.textHeader}>
              Name of {activeTab === 'r' ? 'Residential' : 'Company'} Location
            </Typography>
            <TextInput
              type={'text'}
              variant={'standard'}
              placeholder={'Location name...'}
              centerMode={true}
              fullWidth={true}
              value={location}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLocation(e.target.value)}
              onKeyDown={onSubmitLocation}
              // error={}
              // disabled={}
            />
          </Stack>
        </DialogLayout>
      </div>
    </>
  );
};

export { PricingSetting };
