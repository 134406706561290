import { css } from '@emotion/css';
import { colors, themeParser, useAppTheme } from '@/theme';

export const useCardItemListStyle = () => {
  const theme = useAppTheme();

  return {
    root: css`
      margin-top: 20px;
      padding: 0px 20px;

      .MuiListItem-root {
        height: 32px;
        font-size: 13px;
        font-weight: 500;
        color: ${themeParser('#000000', '#9e9e9e', theme)} !important;
        line-height: 32px;
        padding: 0 12px;
        border-radius: 16px;
        background-color: ${themeParser(colors.white.light, '#3e3e3e', theme)} !important;
        margin-bottom: 5px;
        margin-right: 5px;
        border-radius: 3px;
        display: flex;
        justify-content: space-between;
        transition: all 0.1s ease-in-out;
        :hover {
          background: ${themeParser(colors.hoverBG.light, colors.hoverBG.dark, theme)}!important;
          color: ${colors.white.light} !important;
          cursor: grab;
        }
        :focus {
          outline: none;
          background-color: #2196f3;
          color: #2a2a2a;
        }
        svg {
          opacity: 0.6;
          font-size: 1.2rem;
        }
        :hover svg {
          opacity: 1;
        }
        .MuiTypography-root {
          font-size: 13px !important;
        }
      }
      .MuiInput-root::before {
        border-bottom: 0px solid !important;
      }
      .MuiInput-root::after {
        border-bottom: 1px solid !important;
      }
    `,
    cardHeader: css`
      color: #2196f3;
      border-bottom: 1px solid #2196f3;
      padding-bottom: 10px;
      margin: 0 0 10px;
    `,
  };
};
