import React, { useEffect, useState } from 'react';
import { Button } from '@/components/atoms/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useSettingDialogStyle } from '@/static/stylesheets/molecules';
import DialogTitle from '@mui/material/DialogTitle';
import { SearchableTextInput, TextInput } from '@/components/atoms/TextInput';
import { Grid } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAppSelector } from '@/hooks';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { EquipmentBodyParams, EquipmentParams, LeadItem } from '@/types';
import toast from 'react-hot-toast';
import {
  clearDeletedLeadEquipment,
  deleteLeadEquipment,
  createLeadEquipments,
  clearCreateLeadEquipments,
  openConfirmationDialog,
  deleteAllLeadEquipments,
} from '@/redux/actions';

import { Loader } from '@/components/atoms/Loader';
import { apiClient, endpoints } from '@/api';
import { convertToNumber } from '@/utils';

interface InstallPopUPProps {
  open?: any;

  onClose?(): void;

  leadId?: LeadItem['id'];
  leadData?: any;

  onChangeEquipments?(leadEquipments: EquipmentParams[]): void;
}

type EquipmentItem = EquipmentParams & {
  idError: boolean;
  quantityError: boolean;
  ourCost: number | string;
  clientCost: number | string;
  ourCostError: boolean;
  clientCostError: boolean;
};

const InstallPopUP: React.FC<InstallPopUPProps> = (props: any) => {
  const classes = useSettingDialogStyle();
  const { open, onClose, leadId, onChangeEquipments } = props;

  const deletedEquipment = useAppSelector(selectHttpState)[CORRELATION_IDS.DELETE_LEAD_EQUIPMENT];
  const equipmentDropdownResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_EQUIPMENTS];
  const createEquipmentResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.CREATE_LEAD_EQUIPMENTS];

  const equipmentsDropdownData: EquipmentParams[] = equipmentDropdownResponse?.data?.data ?? [];

  const [leadEquipmentList, setLeadEquipmentList] = useState<{
    loading: boolean;
    data: EquipmentItem[];
  }>({
    loading: false,
    data: [],
  });

  const [targetId, setTargetId] = useState(null);

  const initialEquipment: EquipmentItem = {
    id: '',
    quantity: '',
    ourCost: '',
    clientCost: '',
    idError: false,
    quantityError: false,
    ourCostError: false,
    clientCostError: false,
  };

  const [equipments, setEquipments] = useState<EquipmentItem[]>([]);

  const getEquipments = (callback: (() => void) | undefined = undefined) => {
    setLeadEquipmentList((prevState) => ({
      ...prevState,
      loading: true,
    }));

    apiClient
      .get({
        url: endpoints.private.getLeadEquipments(leadId),
      })
      .then(({ data }) => {
        if (data) {
          setLeadEquipmentList(() => ({
            loading: false,
            data: data?.data ?? [],
          }));
        }

        if (callback) {
          setTimeout(() => callback(), 200);
        }
      })
      .catch(() => {
        setLeadEquipmentList(() => ({
          loading: false,
          data: [],
        }));

        if (callback) {
          setTimeout(() => callback(), 200);
        }
      });
  };

  const addEmptyField = () => {
    setEquipments(() => [initialEquipment]);
  };

  const addEquipment = () => {
    if (!validateGears()) {
      return false;
    }
    const __equipments = [...equipments];
    __equipments?.push(initialEquipment);
    setEquipments(() => __equipments);
  };

  const deleteEquipmentItem = (index: number) => {
    const __equipments = [...equipments];
    const id = __equipments[index]?.id;
    __equipments?.splice(index, 1);
    setEquipments(() => __equipments);

    if (leadEquipmentList?.data?.length) {
      setTargetId(leadId);
      deleteLeadEquipment(id);
    }
  };

  const onChangeEquipmentItem = (index: number, key: keyof EquipmentItem, value: any) => {
    const __equipments = [...equipments];
    __equipments[index][key] = value;

    setEquipments(() => __equipments);

    if (key === 'equipment_id' || key === 'quantity' || key === 'ourCost' || key === 'clientCost') {
      validateGears();
    }
  };

  const validateGears = (): boolean => {
    let noError = true;
    equipments?.forEach((item, index) => {
      if (!item?.equipment_id || item?.equipment_id === '') {
        onChangeEquipmentItem(index, 'idError', true);
        noError = false;
      } else {
        onChangeEquipmentItem(index, 'idError', false);
      }

      if (!item?.quantity || isNaN(Number(item?.quantity)) || Number(item?.quantity) <= 0) {
        onChangeEquipmentItem(index, 'quantityError', true);
        noError = false;
      } else {
        onChangeEquipmentItem(index, 'quantityError', false);
      }

      if (!item?.ourCost || isNaN(Number(item?.ourCost)) || Number(item?.ourCost) <= 0) {
        onChangeEquipmentItem(index, 'ourCostError', true);
        noError = false;
      } else {
        onChangeEquipmentItem(index, 'ourCostError', false);
      }

      if (!item?.clientCost || isNaN(Number(item?.clientCost)) || Number(item?.clientCost) <= 0) {
        onChangeEquipmentItem(index, 'clientCostError', true);
        noError = false;
      } else {
        onChangeEquipmentItem(index, 'clientCostError', false);
      }
    });

    return noError;
  };

  const onSubmit = () => {
    if (equipments?.length === 0) {
      toast.error('Please add at least one gear item!');
      return;
    }

    if (!validateGears()) {
      toast.error('Please fill all the required fields!');
      return;
    }

    const data: EquipmentBodyParams = {
      leadId,
      equipments: [...equipments]?.map((item) => ({
        equipment_id: item?.equipment_id,
        quantity: convertToNumber(item?.quantity),
        our_cost: convertToNumber(item?.ourCost),
        client_cost: convertToNumber(item?.clientCost),
      })),
    };

    createLeadEquipments(data);
    setTargetId(leadId);
  };

  const deleteAllEquipments = () => {
    openConfirmationDialog({
      title: 'Delete',
      message: 'Are you sure to delete all the items?',
      type: 'error',
      textCenter: 'center',
      onClickConfirm() {
        setEquipments(() => []);

        if (leadEquipmentList?.data?.length > 0) {
          setTargetId(leadId);
          deleteAllLeadEquipments(leadId);
        }
      },
    });
  };

  useEffect(() => {
    if (open && !leadEquipmentList?.loading) {
      if (onChangeEquipments) {
        onChangeEquipments(leadEquipmentList.data);
      }
    }
  }, [leadEquipmentList.data, open]);

  useEffect(() => {
    if (deletedEquipment?.success && targetId === leadId) {
      toast.success('Equipment(s) deleted successfully!');

      clearDeletedLeadEquipment();
      setTargetId(null);
      getEquipments();
    }

    if (deletedEquipment?.error && targetId === leadId) {
      toast.error('Failed to delete the equipment(s)!');
      clearDeletedLeadEquipment();
    }
  }, [deletedEquipment, targetId]);

  useEffect(() => {
    if (createEquipmentResponse?.success && targetId === leadId) {
      toast.success('Equipment successfully updated!');
      clearCreateLeadEquipments();
      setTargetId(null);

      getEquipments(() => {
        if (onClose) {
          onClose();
        }
      });
    }

    if (createEquipmentResponse?.error && targetId === leadId) {
      toast.error('Failed to update the equipment!');
      clearCreateLeadEquipments();
    }
  }, [createEquipmentResponse]);

  useEffect(() => {
    if (open) {
      let __equipmentList: any = [...leadEquipmentList?.data];

      if (__equipmentList?.length > 0) {
        __equipmentList = __equipmentList?.map((item: any) => ({
          ...item,
          idError: false,
          quantityError: false,
          ourCost: item?.our_cost,
          clientCost: item?.client_cost,
          ourCostError: false,
          clientCostError: false,
        }));
        setEquipments(() => [...__equipmentList]);
      } else {
        addEmptyField();
      }
    }
  }, [leadEquipmentList?.data, open]);

  useEffect(() => {
    if (open) {
      addEmptyField();
      getEquipments();
    }
  }, [open]);

  return (
    <>
      <Dialog className={classes.root} open={open} onClose={onClose} maxWidth={'lg'}>
        <DialogTitle className={classes.titleCenter}>{'What equipment was used?'}</DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.DialogContent}>
            {equipments &&
              equipments?.length > 0 &&
              equipments?.map((item, index) => (
                <Grid key={index} container spacing={2} style={{ alignItems: 'flex-end' }}>
                  <Grid item sm={12} md={5} lg={5} className={'install_search'}>
                    <SearchableTextInput
                      placeholder={'Type Product...'}
                      options={[
                        ...equipmentsDropdownData.map((equipment: EquipmentParams) => ({
                          label: equipment?.equipment_name,
                          value: equipment?.id,
                        })),
                      ]}
                      value={item?.equipment_id}
                      onChange={(value) => {
                        const equipmentData = equipmentsDropdownData?.find((eq) => eq.id === value);

                        onChangeEquipmentItem(index, 'equipment_id', value);
                        onChangeEquipmentItem(index, 'quantity', 1);
                        onChangeEquipmentItem(index, 'ourCost', equipmentData?.our_cost ?? '');
                        onChangeEquipmentItem(index, 'clientCost', equipmentData?.client_cost ?? '');
                      }}
                      loading={equipmentDropdownResponse?.data?.loading}
                      error={item?.idError}
                      errorText={item?.idError ? 'Required' : undefined}
                      hideArrow={true}
                    />
                  </Grid>
                  <Grid item sm={12} md={2} lg={2}>
                    <TextInput
                      type={'number'}
                      variant={'standard'}
                      placeholder={'Quantity'}
                      centerMode={true}
                      fullWidth={true}
                      value={item?.quantity}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const equipmentData = equipmentsDropdownData?.find((eq) => eq.id === item?.equipment_id);
                        const quantity = convertToNumber(e?.target?.value);

                        onChangeEquipmentItem(index, 'quantity', e?.target?.value);
                        onChangeEquipmentItem(
                          index,
                          'ourCost',
                          convertToNumber(equipmentData?.our_cost ?? '') * quantity,
                        );
                        onChangeEquipmentItem(
                          index,
                          'clientCost',
                          convertToNumber(equipmentData?.client_cost ?? '') * quantity,
                        );
                      }}
                      error={item?.quantityError}
                      errorText={item?.quantityError ? 'Required' : undefined}
                    />
                  </Grid>
                  <Grid item sm={12} md={2} lg={2}>
                    <TextInput
                      type={'number'}
                      variant={'standard'}
                      placeholder={'Our Cost'}
                      centerMode={true}
                      fullWidth={true}
                      value={item?.ourCost}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onChangeEquipmentItem(index, 'ourCost', e?.target?.value)
                      }
                      error={item?.ourCostError}
                      errorText={item?.ourCostError ? 'Required' : undefined}
                    />
                  </Grid>
                  <Grid item sm={12} md={2} lg={2}>
                    <TextInput
                      type={'number'}
                      variant={'standard'}
                      placeholder={'Client Cost'}
                      centerMode={true}
                      fullWidth={true}
                      value={item?.clientCost}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onChangeEquipmentItem(index, 'clientCost', e?.target?.value)
                      }
                      error={item?.clientCostError}
                      errorText={item?.clientCostError ? 'Required' : undefined}
                    />
                  </Grid>
                  <Grid item sm={1} md={1} lg={1} className={classes.deleteIcon}>
                    <DeleteIcon
                      sx={{ fontSize: '20px' }}
                      onClick={() => {
                        openConfirmationDialog({
                          title: 'Delete',
                          message: 'Are you sure to delete this item?',
                          type: 'error',
                          textCenter: 'center',
                          onClickConfirm() {
                            deleteEquipmentItem(index);
                          },
                        });
                      }}
                    />
                  </Grid>
                </Grid>
              ))}
            {leadEquipmentList?.loading && <Loader />}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.installActions}>
          <Button
            onClick={addEquipment}
            color={'primary'}
            variant={'contained'}
            title={'Add Gear'}
            disabled={leadEquipmentList?.loading}
          />
        </DialogActions>
        <DialogActions className={classes.bottomIcon}>
          <Button fullWidth={false} onClick={onClose} color={'error'} variant={'contained'} title={'Cancel'} />

          {equipments?.length > 0 && (
            <Button
              fullWidth={false}
              onClick={deleteAllEquipments}
              color={'error'}
              variant={'contained'}
              title={'Delete All'}
            />
          )}

          <Button
            fullWidth={false}
            onClick={onSubmit}
            color={'success'}
            variant={'contained'}
            title={'Submit'}
            loading={createEquipmentResponse?.loading}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export { InstallPopUP };
