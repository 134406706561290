import { css } from '@emotion/css';
import { colors, themeParser, useAppTheme } from '@/theme';

export const useButtonStyles = () => {
  const theme = useAppTheme();
  return {
    root: css`
      text-transform: uppercase !important;
      color: ${themeParser(colors.body.light, colors.black.default, theme)} !important;
      font-family: 'Poppins', sans-serif !important;
    `,
    CloseButton: css`
      position: absolute;
      right: 10px;
      top: 10px;
      color: #000;
      cursor: pointer;
    `,
    dialogBody: css`
      .MuiPaper-root {
        background: ${themeParser(colors.body.light, colors.body.dark, theme)};
        .MuiTypography-root {
          color: ${themeParser('#2a2a2a', '#cbcbcb', theme)} !important;
        }
      }
    `,
    disabled: css`
      background: ${themeParser(colors.black.default, colors.black.default, theme)} !important;
      color: ${themeParser(colors.white.default, colors.white.default, theme)} !important;
    `,
    dialogButton: css`
      .MuiButton-textSuccess {
        color: ${themeParser('#4CAF50', '#4CAF50', theme)} !important;
        background-color: #4caf5057 !important;
      }
      .MuiButton-textError {
        background-color: #f443364a !important;
      }
    `,
  };
};
