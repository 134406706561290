import React, { useEffect, useState } from 'react';
import { Button } from '@/components/atoms/Button';
import { useEmailTemplateStyles } from '@/static/stylesheets/molecules';
import { Typography } from '@mui/material';
import { DataTable } from '@/components/organisms/DataTable';
import { ActionButton } from '@/components/atoms/Button';
import { EmailTemplatesForm } from '@/components/molecules/Settings/Email/EmailTemplates';
import {
  clearDeletedEmailTemplate,
  deleteEmailTemplate,
  getEmailTemplates,
  openConfirmationDialog,
} from '@/redux/actions';
import { EmailTemplateParams, UserParams } from '@/types';
import { useAppSelector } from '@/hooks';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import toast from 'react-hot-toast';
import { alertMessages } from '@/config';
import { convertCCArrayToText } from '@/utils/convertCCArrayToText';

interface EmailTemplatesProps {}

const EmailTemplates: React.FC<EmailTemplatesProps> = () => {
  const classes = useEmailTemplateStyles();
  const emailTemplateList = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_EMAIL_TEMPLATE_LIST];
  const deletedEmailTemplate = useAppSelector(selectHttpState)[CORRELATION_IDS.DELETE_EMAIL_TEMPLATE];

  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [formOpen, setFormOpen] = useState(false);
  const [editFormData, setEditFormData] = useState<UserParams | null>(null);

  const emailTemplates = emailTemplateList?.data?.data ?? [];
  const totalPages = emailTemplateList?.data?.last_page ?? 0;

  const openFormDialog = (mode: 'add' | 'edit', formData: EmailTemplateParams | null) => {
    setFormOpen(true);
    setEditFormData(formData);
  };

  const handleCloseFormDialog = () => {
    setFormOpen(false);
    setTimeout(() => {
      setEditFormData(null);
    }, 100);
  };

  const fetchEmailTemplates = () =>
    getEmailTemplates({
      limit,
      page: currentPage,
    });

  const deleteTemplate = (id: EmailTemplateParams['id']) => deleteEmailTemplate(id);

  useEffect(() => {
    if (deletedEmailTemplate?.success) {
      toast.success(alertMessages.generalSettings.emailTemplates.deleteSuccess);
      clearDeletedEmailTemplate();

      fetchEmailTemplates();
    }

    if (deletedEmailTemplate?.error) {
      toast.error(alertMessages.generalSettings.emailTemplates.deleteError);
      clearDeletedEmailTemplate();
    }
  }, [deletedEmailTemplate]);

  useEffect(() => {
    fetchEmailTemplates();
  }, [currentPage, limit]);

  return (
    <>
      <div className={classes.cardHeader} style={{ margin: '10px 20px 0px 20px' }}>
        <Typography variant='subtitle1' component='div'>
          Email Templates
        </Typography>
        <div>
          <Button onClick={() => openFormDialog('add', null)} title={'Add New Template'} size={'small'} />
        </div>
      </div>
      <div style={{ textAlign: 'center', padding: 20 }}>
        <div style={{ width: '100%', display: 'inline-block' }}>
          <DataTable
            heads={[
              { label: 'Template Name', align: 'left' },
              { label: 'Subject Line', align: 'left' },
              { label: 'CC', align: 'left' },
              { label: 'Actions', align: 'center' },
            ]}
            rows={[
              ...emailTemplates?.map((emailTemplate: EmailTemplateParams) => [
                { label: emailTemplate?.name ?? '', align: 'left' },
                { label: emailTemplate?.subject ?? '', align: 'left' },
                {
                  label: emailTemplate?.cc_address ? convertCCArrayToText(emailTemplate?.cc_address as string[]) : '',
                  align: 'left',
                },
                {
                  label: (
                    <div className={'action-icon'}>
                      <ActionButton onClick={() => openFormDialog('edit', emailTemplate)} icon={'edit'} />
                      <ActionButton
                        icon={'delete'}
                        onClick={() => {
                          openConfirmationDialog({
                            title: 'Are you sure?',
                            message: alertMessages.generalSettings.emailTemplates.deleteConfirmation,
                            type: 'error',
                            textCenter: 'center',
                            onClickConfirm() {
                              deleteTemplate(emailTemplate?.id);
                            },
                          });
                        }}
                      />
                    </div>
                  ),
                  align: 'right',
                },
              ]),
            ]}
            hasPagination={true}
            paginationProps={{
              count: totalPages,
              currentPage,
              onChange(value) {
                setCurrentPage(value);
              },
            }}
          />
        </div>
        <EmailTemplatesForm
          featureType={'settings'}
          open={formOpen}
          onClose={handleCloseFormDialog}
          onReload={fetchEmailTemplates}
          formData={editFormData}
          paperStyles={{
            width: '90%',
            maxWidth: '100%',
          }}
        />
      </div>
    </>
  );
};

export { EmailTemplates };
