import { css } from '@emotion/css';
import { colors, themeParser, useAppTheme } from '@/theme';

export const useEmailTemplateStyles = () => {
  const theme = useAppTheme();

  return {
    root: css`
      margin: 20px 0px 80px 0px;
      padding: 0px 20px;

      .MuiListItem-root {
        height: 32px;
        font-size: 13px;
        font-weight: 500;
        line-height: 32px;
        padding: 0 12px;
        border-radius: 16px;
        background-color: #3e3e3e;
        margin-bottom: 5px;
        margin-right: 5px;
        border-radius: 3px;
        color: #9e9e9e;
        display: flex;
        justify-content: space-between;
        transition: all 0.1s ease-in-out;

        :hover {
          background: #505050;
          cursor: grab;
        }

        :focus {
          outline: none;
          background-color: #2196f3;
          color: #2a2a2a;
        }

        svg {
          opacity: 0.2;
          font-size: 1.2rem;
        }

        :hover svg {
          opacity: 1;
        }
      }

      h2 {
        text-align: right;
        color: ${themeParser(colors.tabText.light, colors.tabText.dark, theme)} !important;
        @media (max-width: 900px) {
          text-align: center;
        }
      }

      button {
        color: ${themeParser(colors.body.light, colors.body.dark, theme)};
        margin-top: 20px;
      }

      .MuiInput-root::after {
        border-bottom: 1px solid !important;
      }

      .MuiGrid-item {
        @media (max-width: 900px) {
          text-align: center;
          display: block;
        }
      }
    `,
    cardHeader: css`
      color: #2196f3;
      border-bottom: 1px solid #2196f3;
      padding-bottom: 10px;
      margin: 30px 20px 20px 20px;
      display: flex;
      justify-content: space-between;

      button {
        color: ${themeParser(colors.white.default, colors.black.default, theme)};
        padding: 4px 15px !important;
        @media (max-width: 480px) {
          padding: 4px 5px !important;
        }
      }
    `,
    textAria: css``,
    textLabel: css`
      color: ${themeParser(colors.textInput.labelColor.light, colors.textInput.labelColor.dark, theme)} !important;
      text-align: center;
      font-size: 14px !important;
    `,
    container: css`
      padding: 20px;
      align-items: end;

      .MuiGrid-item {
        margin-bottom: 30px;
      }

      @media (max-width: 900px) {
        margin-left: -16px !important;
        display: block !important;
      }
    `,
    success: css`
      color: green;
    `,
    error: css`
      color: red;
    `,

    templateInformationSection: css`
      height: 100%;
      display: flex;
      flex-direction: column;
    `,

    templateBasicInformationSection: css``,

    customVariableListSection: css`
      flex-grow: 1;
    `,
  };
};
