import { colors, themeParser, useAppTheme } from '@/theme';
import { css } from '@emotion/css';

export const useLeadStyles = () => {
  const theme = useAppTheme();

  return {
    root: css`
      align-items: baseline;
      padding-right: 10px;
      width: 100% !important;
      padding-bottom: 12px;
      border-radius: 3px;
      transition: all 0.3s ease-in-out;
      margin: 20px 0px 20px 0px !important;
      border-bottom: 4px solid transparent;
      :hover {
        border-bottom: 4px solid ${themeParser(colors.primary, '#3e3e3e', theme)};
        box-shadow:
          0 2px 2px 0 rgb(0 0 0 / 14%),
          0 3px 1px -2px rgb(0 0 0 / 12%),
          0 1px 5px 0 rgb(0 0 0 / 20%);
      }
    `,

    leftColumn: css`
      padding-top: 10px !important;
      @media (min-width: 1600px) {
        flex-basis: 63.33333% !important;
        max-width: 63.33333% !important;
      }
    `,

    rightColumn: css`
      padding-top: 10px !important;
      @media (min-width: 1600px) {
        flex-basis: 36.666667% !important;
        max-width: 36.666667% !important;
      }
    `,

    leadListBody: css`
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      height: 100vh;
      overflow: hidden !important;
    `,

    activeBody: css`
      border-bottom: 4px solid ${themeParser(colors.primary, '#3e3e3e', theme)};
      box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
    }`,
    trackingContainer: css`
      padding-top: 6px !important;
    `,
    expandContainer: css`
      padding-top: 20px !important;
    `,
    hide: css`
      height: 0;
      transition: all 0.5s ease;
    `,
    show: css`
      overflow-y: hidden;
      height: 500px;
      transition: height 0.5s ease;

      @media (max-width: 768px) {
        overflow-y: auto;
      }
    `,
    indicatorCircleFilled: css`
      height: 20px;
      width: 20px;
      border: none;
      display: inline-flex;
      border-radius: 150px;
      margin: 0 5px -3px 3px;
    `,
    green: css`
      background: #4caf50;
    `,
    yellow: css`
      background: #ffc107 !important;
    `,
    red: css`
      background: #f44336 !important;
      box-shadow: 0 0 0 0 #ff00001a;
      transform: scale(1);
      animation: pulse 1s infinite;
      @keyframes pulse {
        0% {
          transform: scale(0.85);
          box-shadow: 0 0 0 0 #ff00001a;
        }

        70% {
          transform: scale(1);
          box-shadow: 0 0 0 10px #ff00001a;
        }

        100% {
          transform: scale(0.85);
          box-shadow: 0 0 0 0 #ff00001a;
        }
      }
    `,
    Names: css`
      font-weight: bold;
      //text-transform: capitalize;
      letter-spacing: 1px;
      font-size: 1.3em;
      margin-right: 10px;
      border-right: 1px solid #3e3e3e;
      padding-right: 10px;
      color: ${themeParser(colors.black.default, colors.primary, theme)};
      cursor: pointer;

      svg {
        font-size: 14px;
        margin-left: 5px;
      }
    `,
    Phones: css`
      margin-right: 10px;
      border-right: 1px solid #3e3e3e;
      padding-right: 10px;
      line-height: 2;

      a {
        color: #1494d3;
        text-decoration: none;
        font-weight: normal;
      }
    `,
    gmail: css`
      margin-right: 10px;
      border-right: 1px solid #3e3e3e;
      padding-right: 10px;
      line-height: 2;

      a {
        color: #1494d3;
        text-decoration: none;
        font-weight: normal;
      }
    `,
    address: css`
      margin-right: 10px;
      border-right: 1px solid #3e3e3e;
      padding-right: 10px;
      line-height: 2;
      @media (max-width: 400px) {
        display: inline-block;
      }

      a {
        color: #1494d3;
        text-decoration: none;
        font-weight: normal;
      }
    `,
    selector: css`
      .MuiInput-root {
        color: #1494d3 !important;
      !important;
        font-weight: normal;
      }

      .MuiInput-root:before,
      .MuiInput-root:after {
        border: 0px solid !important;
      }
    `,
    rightDetails: css`
      text-align: end;
      display: flex;
      align-item: center;
      justify-content: flex-end;

      @media (max-width: 900px) {
        text-align: left;
      }

      @media (max-width: 768px) {
        flex-wrap: wrap;
        justify-content: flex-start;
      }

      .Mui-disabled {
        color: ${themeParser('#9F9F9F', '#9F9F9F', theme)} !important;
        background-color: ${themeParser('#DFDFDF', '#3e3e3e', theme)} !important;
      }
      button {
        padding: 2px 8px;
        font-weight: 500;
        margin: 2px;
        font-size: 13px;
        border-radius: 2px;
        height: 32px;
        line-height: 32px;
        padding: 0 16px;
        margin-bottom: 4px;
        color: ${themeParser(colors.white.default, colors.black.default, theme)};
      }

      .last__button {
        display: contents;
        button {
          margin-right: 0px;
        }
      }
    `,
    vertLine: css`
      font-size: 1em;
      color: ${themeParser('#bfbfbf', '#3e3e3e', theme)};
      margin: 2px;
      padding-top: 10px !important;
    `,
    progressBar: css`
      margin-top: 7px;

      table {
        width: 100%;
        display: table;
        border-collapse: collapse;
        border-spacing: 0;
      }

      .disable td {
        cursor: no-drop;
        background: ${themeParser('#9e9e9e', '#9e9e9e', theme)};
        color: ${themeParser('#505050', '#505050', theme)};
      }

      tr {
        display: block;
        @media (min-width: 1366px) {
          display: inline-table;
          width: 100%;
        }
      }

      td {
        background: ${themeParser(colors.progress.light, colors.progress.dark, theme)};
        border: 0.5px solid ${themeParser('#bfbfbf', '#2a2a2a', theme)};
        text-align: center;
        margin: 2px 0px;
        padding: 5px 4px;
        font-weight: normal;
        cursor: pointer;
        display: inline-block;
        font-size: 15px;
        color: ${themeParser('#000000de', '#000000de', theme)};
        @media (min-width: 1520px) {
          display: table-cell;
          padding: 5px 5px;
        }
        @media (min-width: 1366px) and (max-width: 1519px) {
          display: table-cell;
          padding: 5px 5px;
          font-size: 13px;
        }

        button {
          background: transparent;
          color: inherit;
          font-size: 15px !important;
          border: none;
          outline: none;
          cursor: pointer;
          display: contents;
          font-family: 'Poppins', serif !important;
        }
      }

      td.active {
        background: #4caf50;
        color: ${themeParser(colors.white.default, colors.black.default, theme)};
      }
      td.lostLeads {
        pointer-events: none;
        background: #9e9e9e !important;
        color: #505050 !important;
        cursor: not-allowed;
      }
    `,
    fileBody: css`
      padding: 30px 0px 10px 0px;
      @media (max-width: 600px) {
        .itemFlex {
          display: block;
        }
      }

      .img_grid {
        overflow-y: auto;
        max-height: 450px;
        position: relative;
        margin-top: 0px;
        @media (max-width: 600px) {
          max-height: 750px;
        }
      }

      .img_grid .MuiGrid-item {
        padding-top: 0;
        padding-bottom: 16px;
      }

      img,
      .other {
        width: 100%;
        height: 100%;
        cursor: zoom-in;
        border-radius: 4px;
        max-height: 200px;
        min-height: 200px;
        object-fit: cover;
        border: 1px solid #a6a6a630;
      }
      .other {
        background: #a8a8a875;
        justify-content: center;
        align-items: center;
        display: flex;
        margin-bottom: 8px;
        svg {
          font-size: 80px;
        }
      }

      .actionButton {
        display: flex;
        justify-content: center;

        button {
          margin: 2px;
          height: 24px;
          line-height: 24px;
          border-radius: 2px;
          font-family: 'Poppins', sans-serif;
        }
      }
      .container {
        height: 90%;

        .dropzone {
          border: 3px dashed ${themeParser('#ddd', '#dddddd29', theme)};
          border-radius: 9px;
          margin: 0;
          min-height: 450px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          cursor: pointer;
          transition: all 0.3s ease-in-out;

          p {
            margin: 0px;
            text-align: center;
            color: ${themeParser(colors.body.dark, colors.primary, theme)};
            font-family: 'Poppins', sans-serif;
          }

          :hover {
            border: 3px dashed ${colors.primary};
          }

          @media (max-width: 600px) {
            min-height: 200px;
          }
        }
      }

      .light-box_layout {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        inset: 0px;
        background-color: rgb(0 0 0 / 66%);
        z-index: 9999;
      }

      .light-box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 70%;
        height: 90%;
        background-color: ${themeParser(colors.white.default, colors.black.default, theme)};
        border: 2px solid rgb(0, 0, 0);
        box-shadow:
          rgb(0 0 0 / 20%) 0px 11px 15px -7px,
          rgb(0 0 0 / 14%) 0px 24px 38px 3px,
          rgb(0 0 0 / 12%) 0px 9px 46px 8px;

        svg {
          background: #2196f3;
          padding: 4px;
          color: #fff;
        }

        img {
          cursor: auto;
          object-fit: contain;
        }
      }
    `,

    fileLoader: css`
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
      border: 1px solid ${themeParser('#eeeeee', colors.primary, theme)};
      border-radius: 5px;
      height: 202px;
      color: ${colors.primary};
      font-weight: 500;
    `,

    clientLoader: css`
      .left-header {
        .container-skeleton {
          height: 50px;
          width: 80%;
        }
      }

      .right-header {
        text-align: right;

        .button-skeleton {
          height: 50px;
          width: 80px;
          display: inline-block;
          margin-left: 5px;

          :first-child {
            margin-left: 0;
          }
        }
      }

      .tracker-container {
        .tracker-list-skeleton {
          height: 55px;
        }
      }
    `,

    pagination: css`
      text-align: center;
    `,

    deleteModalRoot: css`
      .MuiDialog-paper {
        position: absolute;
        top: 100px;
        background-color: transparent;
      }

      .deleteModalContent {
        background: ${themeParser(colors.body.light, colors.body.dark, theme)};
        font-size: 20px;
        color: '#fff';
      }

      .textContent {
        color: #f44336 !important;
        text-align: center;
        font-size: 20px;
        padding-top: 25px;
        font-weight: 500;
      }

      .deleteModalActions {
        padding: 30px 0;
        justify-content: center;
      }
    `,
  };
};
