import React from 'react';
import { ReportsContainer } from '@/components/molecules/Reports';
import { PrivateLayout } from '@/components/Middleware';
import { ReportsLayout } from '@/components/layouts';

const Reports = () => {
  return (
    <>
      <PrivateLayout>
        <ReportsLayout hideSettingsTabPanel={false} hideHeaderRightSide={true} featureType={'reports'}>
          <ReportsContainer />
        </ReportsLayout>
      </PrivateLayout>
    </>
  );
};

export default Reports;
