import { css } from '@emotion/css';
import { colors, themeParser, useAppTheme } from '@/theme';

export const useMenuDialogStyles = () => {
  const theme = useAppTheme();

  return {
    root: css`
      display: inline-block;
    `,
  };
};
