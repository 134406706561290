import React from 'react';
import { useAppTheme } from './useAppTheme';
import { AppTheme, ThemeState, ThemeContext } from './ThemeContext';
import { themeParser } from './utils';

export type ThemeParser = (light: string, dark: string) => string;
export type { AppTheme };

interface WithThemeInjectedProps {
  parseTheme: ThemeParser;
  appTheme: AppTheme;
  setAppTheme: ThemeState['setAppTheme'];
}

export const withAppTheme =
  <T extends WithThemeInjectedProps>(
    Component: React.ComponentType<T>,
  ): React.FC<Pick<T, Exclude<keyof T, keyof WithThemeInjectedProps>>> =>
  (props: Omit<T, keyof WithThemeInjectedProps>) => {
    const appTheme: AppTheme = useAppTheme();
    const parseTheme: ThemeParser = (light: string, dark: string) => {
      return themeParser(light, dark, appTheme);
    };

    return (
      <ThemeContext.Consumer>
        {(themeProps) => (
          <Component
            {...(props as T)}
            parseTheme={parseTheme}
            appTheme={appTheme}
            setAppTheme={themeProps.setAppTheme}
          />
        )}
      </ThemeContext.Consumer>
    );
  };
