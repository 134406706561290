import React from 'react';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import Lightbox, { LightboxProps } from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

export interface LightBoxProps {
  open?: boolean;

  onClose?(): void;

  slides?: LightboxProps['slides'];
  index?: number;
}

const LightBox: React.FC<LightBoxProps> = (props) => {
  const { open, onClose, slides, index } = props;

  return (
    <>
      <Lightbox
        open={open}
        close={onClose}
        slides={slides}
        carousel={{
          finite: true,
        }}
        index={index}
        plugins={[Thumbnails, Zoom]}
      />
    </>
  );
};

export { LightBox };
