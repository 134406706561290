import React from 'react';
import { useReportStyles } from '@/static/stylesheets/molecules';
import { Sidebar } from '@/components/organisms/Reports';
import { ReportContent } from './ReportContent';
import { useComponentState } from '@/hooks';
import { ReportMenuKey } from '@/types';

interface ComponentState {
  selectedMenuKey: ReportMenuKey;
}

const ReportsContainer = () => {
  const classes = useReportStyles();

  const {
    state: { selectedMenuKey },
    setState,
  } = useComponentState<ComponentState>({
    selectedMenuKey: 'GENERAL_TOTAL_P&L_OR_YEAR_SUMMARY',
  });

  return (
    <div className={classes.containerRoot}>
      <Sidebar
        selectedMenuKey={selectedMenuKey}
        onChangeMenuItem={(key) =>
          setState({
            selectedMenuKey: key,
          })
        }
      />
      <ReportContent selectedMenuKey={selectedMenuKey} />
    </div>
  );
};

export { ReportsContainer };
