import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { useDatePickerStyles } from '@/static/stylesheets/atoms';
import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

interface DatePickerProps {
  label?: string;
  placeholder?: string;
  value?: string;
  error?: boolean;
  errorText?: string | null;
  centerMode?: boolean;
  fullWidth?: boolean;

  onChange?(value: any): void;
  rootSx?: SxProps<Theme>;

  disabled?: boolean;
}

const DatePicker: React.FC<DatePickerProps> = (props) => {
  const classes = useDatePickerStyles();
  const { value, onChange, error, errorText, rootSx, centerMode, fullWidth, label, placeholder, disabled } = props;
  const [values, setValue] = useState<Date | string | number | null>(null);

  useEffect(() => {
    setValue(value ?? null);
  }, [value]);
  return (
    <>
      <Box className={classes.root} sx={rootSx}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MobileDatePicker
            orientation='landscape'
            inputFormat='MM/dd/yyyy'
            // disablePast={true}
            value={value ? value : values}
            DialogProps={{ className: 'datePicker' }}
            onChange={(event: any) => {
              setValue(event);
              if (onChange) {
                onChange(event);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={placeholder ?? 'Date'}
                error={error}
                helperText={errorText}
                sx={{
                  '& input': {
                    textAlign: `${centerMode ? 'center' : 'left'} !important`,
                  },
                }}
                label={label}
                fullWidth={fullWidth}
              />
            )}
            disabled={disabled}
          />
        </LocalizationProvider>
      </Box>
    </>
  );
};

export { DatePicker };
