/**
 * Convert a number
 * @param number {number}
 */
export function convertToNumber(number?: any): number {
  if (typeof number === 'undefined' || number === null || isNaN(Number(number)) || isNaN(number)) {
    return 0;
  }

  return Number(number);
}
