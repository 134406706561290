import React from 'react';
import { Pagination } from '@/components/atoms/Pagination';

const PaginationPreview = () => {
  return (
    <>
      <div>
        <Pagination count={20} />
      </div>
    </>
  );
};

export { PaginationPreview };
