import { css } from '@emotion/css';
import { colors, themeParser, useAppTheme } from '@/theme';

export const useSettingsEquipmentStyle = () => {
  const theme = useAppTheme();

  return {
    TopHeader: css`
      display: flex;
      margin-top: 40px;
      justify-content: center;
      position: relative;
      @media (max-width: 480px) {
        display: grid;
      }
    `,
    selector: css`
      display: contents;
      .MuiTextField-root {
        width: 10%;
        @media (max-width: 480px) {
          width: 100%;
          margin-bottom: 20px;
        }
      }
    `,
    inputField: css`
      display: contents;
      .MuiTextField-root {
        width: 60%;
        margin: 0px 65px;
        @media (max-width: 800px) {
          width: 40%;
          margin: 0px 35px;
        }
        @media (max-width: 480px) {
          width: 100%;
          margin: 0px;
          margin-bottom: 20px;
        }
      }
    `,
  };
};
