import React, { useEffect, useState } from 'react';
import { JobColumn, TrackerTable } from '@/components/organisms/Jobs';
import { JobProgressItem } from '@/types';
import { Box, Skeleton } from '@mui/material';
import { convertToNumber } from '@/utils';
import { useJobListType } from '@/hooks/custom';

interface JobTrackerColumnsProps {
  jobProgressItems?: JobProgressItem[];
  jobId?: string;
  jobData?: any;

  reloadList?(): void;
  reloadActivityLogList?(): void;
}

const JobTrackerColumns: React.FC<JobTrackerColumnsProps> = (props) => {
  const { fieldsDisabled } = useJobListType();
  const [columnUpdateObject, setColumnUpdateObject] = useState<{
    updating?: boolean;
    targetId: any;
  }>({
    updating: false,
    targetId: null,
  });

  const jobProgressItems = props?.jobProgressItems ?? [];
  const [oldProgressBarList, setOldProgressBarList] = useState<string[]>([]);

  // const isColumnActive = (id: ProgressItem['id']) => {
  //   const activeItems: ProgressItem[] = props.jobProgressItems ?? [];
  //   const index = activeItems?.findIndex((ai) => ai?.id === id);
  //
  //   return index > -1;
  // };

  useEffect(() => {
    if (props?.jobProgressItems) {
      const progressItems: string[] = [];
      props?.jobProgressItems?.forEach((item: JobProgressItem) => {
        if (item?.id) {
          progressItems?.push(item?.id);
        }
      });

      setOldProgressBarList(() => progressItems);
    }
  }, [props?.jobProgressItems]);

  return (
    <>
      <Box sx={{ position: 'relative' }}>
        {columnUpdateObject?.updating && (
          <Box
            sx={{
              position: 'absolute',
              top: -12,
              left: 0,
              right: 0,
              zIndex: 9999,
            }}
          >
            <Skeleton
              animation={'wave'}
              sx={{
                height: 57,
                background: 'rgb(172 172 172 / 44%)',
              }}
            />
          </Box>
        )}
        <TrackerTable>
          {jobProgressItems &&
            jobProgressItems?.length > 0 &&
            jobProgressItems?.map((progressItem: any, index: number) => (
              <JobColumn
                key={index}
                disabled={fieldsDisabled}
                isLastItem={jobProgressItems?.length - 1 === index}
                jobId={props?.jobId}
                jobProgressBars={jobProgressItems}
                progressItem={progressItem}
                statusChangeable={index !== 0}
                active={index === 0 ? true : convertToNumber(progressItem?.status) === 1}
                oldProgressBars={oldProgressBarList}
                onSuccess={(progressBars) => {
                  setOldProgressBarList(() => progressBars);
                  setTimeout(() => {
                    if (props?.reloadActivityLogList) {
                      props?.reloadActivityLogList();
                    }
                  }, 1000);
                }}
                jobData={props.jobData}
                onUpdateStart={(targetId) =>
                  setColumnUpdateObject(() => ({
                    updating: true,
                    targetId,
                  }))
                }
                onUpdateFinished={() =>
                  setTimeout(() => {
                    setColumnUpdateObject(() => ({
                      updating: false,
                      targetId: null,
                    }));
                  }, 200)
                }
                reloadList={props?.reloadList}
              />
            ))}
        </TrackerTable>
      </Box>
    </>
  );
};

export { JobTrackerColumns };
