import React from 'react';
import { SettingsTabPanel } from '@/components/molecules/SettingsTabPanel';
import { Container } from '@/components/organisms/Container';
import { Header } from '@/components/molecules/Header';
import { FloatingActionButton } from '@/components/atoms/FloatingActionButton';
import { BackToTopButton } from '@/components/atoms/BackToTopButton';
import { useDefaultLayoutStyles } from '@/static/stylesheets/layouts';
import { FeatureType } from '@/types';

interface SettingsLayoutProps {
  children?: React.ReactNode;
  hideSettingsTabPanel?: boolean;
  hideHeaderRightSide?: boolean;
  hideHeaderProfile?: boolean;
  containerSize?: any;
  featureType?: FeatureType;

  onChangeType?(): void;

  turnOnReloadActive?(): void;

  containerStyle?: React.CSSProperties;
  containerChildStyle?: React.CSSProperties;
  wrapperStyle?: React.CSSProperties;
}

const SettingsLayout: React.FC<SettingsLayoutProps> = (props) => {
  const classes = useDefaultLayoutStyles();
  const { onChangeType, turnOnReloadActive } = props;

  const scrollTop = () => {
    const body = document.querySelector('.block-item');
    if (body != null) {
      body.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  return (
    <>
      <div className={classes.root} style={props?.wrapperStyle}>
        <Header
          hideHeaderRightSide={props.hideHeaderRightSide}
          hideHeaderProfile={props.hideHeaderProfile}
          onChangeLeadType={onChangeType}
          featureType={props?.featureType}
          turnOnReloadActive={turnOnReloadActive}
        />
        {props.hideSettingsTabPanel && <SettingsTabPanel />}
        <Container
          containerStyle={props?.containerStyle}
          size={props.containerSize}
          containerChildStyle={props?.containerChildStyle}
        >
          {props.children}
        </Container>
      </div>

      <FloatingActionButton />
      <BackToTopButton scrollTop={scrollTop} />
    </>
  );
};

export { SettingsLayout };
