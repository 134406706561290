import React, { useEffect } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { themeParser, useAppTheme } from '@/theme';
import { Controller, useFormContext } from 'react-hook-form';
import { ReportsFormData, SourceItemParams } from '@/types';
import { getSourceItemList } from '@/redux/actions';
import { useAppSelector } from '@/hooks';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';

const SourcePicker = () => {
  const theme = useAppTheme();

  const sourceItemList = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_SOURCE_LIST];
  const sourceList: SourceItemParams[] = sourceItemList?.data?.data ?? [];

  const { watch, setValue, control } = useFormContext<ReportsFormData>();

  const formValues = watch();

  useEffect(() => {
    getSourceItemList();
  }, []);

  return (
    <div className={'report-filter-option-container source-picker-container'}>
      <div className={'report-filter-option-title'}>Source</div>
      <div className={'report-filter-option-body'}>
        <FormControl
          sx={{
            '& .MuiTypography-root': {
              fontSize: '13px',
              fontWeight: 400,
              color: themeParser('#313131', '#b7b7b7', theme),
              textWrap: 'wrap',
              lineHeight: 1,
              wordWrap: 'break-word',
              width: '120px',
            },
            '& .MuiFormControlLabel-root': {
              height: '26px',
            },
          }}
        >
          <Controller
            control={control}
            name={'source'}
            render={({ field }) => (
              <RadioGroup>
                {sourceList?.map((item, index) => (
                  <FormControlLabel
                    key={index}
                    value={item?.id}
                    checked={formValues?.source === item?.id}
                    onChange={() => setValue('source', item?.id)}
                    control={<Radio size={'small'} />}
                    label={item?.source_name}
                  />
                ))}
              </RadioGroup>
            )}
          />
        </FormControl>
      </div>
    </div>
  );
};

export { SourcePicker };
