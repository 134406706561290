import React from 'react';
import { useTaskStyles } from '@/static/stylesheets/molecules/Tasks';
import { Grid, Tooltip } from '@mui/material';
import { FileUploadResponse } from '@/types';
import { detectImageTypeFromExtension, getFileExtension } from '@/utils';
import NoPlaceholderImage from '@/static/images/no-image-placeholder.jpg';
import classNames from 'classnames';
import { css } from '@emotion/css';
import { LightBox, LightBoxProps } from '@/components/atoms/Lightbox';
import { useComponentState } from '@/hooks';

type FileSrc = string | null | undefined;

interface ViewFilesProps {
  files?: FileUploadResponse;
}

interface FileItemProps {
  fileName?: string | null;
  fileSrc?: FileSrc;
  thumbnailSrc?: string;

  onClick?(): void;
}

const FileItem: React.FC<FileItemProps> = (props) => (
  <Tooltip title={props?.fileName ?? ''} placement={'right-start'} arrow>
    <div
      className={classNames({
        fileItem: true,
        [css`
          background: url('${props?.thumbnailSrc}') !important;
          background-repeat: no-repeat !important;
          background-size: cover !important;
          background-position: center !important;
        `]: true,
      })}
      onClick={props?.onClick}
    />
  </Tooltip>
);

const ViewFiles: React.FC<ViewFilesProps> = (props) => {
  const classes = useTaskStyles();
  const { files } = props;

  const {
    state: { selectedFileIndex },
    setState,
  } = useComponentState<{
    selectedFileIndex: number;
  }>({
    selectedFileIndex: -1,
  });

  const getThumbnailSrcPath = (name?: string | null, path?: string | null) => {
    const extension = name ? getFileExtension(name) : null;
    if (extension && detectImageTypeFromExtension(extension)) {
      return path ?? NoPlaceholderImage;
    }

    return NoPlaceholderImage;
  };

  const getLightBoxSlides = () => {
    const slides: LightBoxProps['slides'] = [];

    (files ?? [])?.forEach((file) => {
      slides.push({
        src: file?.file_path ?? NoPlaceholderImage,
        alt: file?.file_name ?? 'File',
        imageFit: 'cover',
      });
    });

    return slides;
  };

  const lightBoxSlides = getLightBoxSlides();

  return (
    <div className={classes.viewFilesContainer}>
      <div className={'wrapper'}>
        <Grid container spacing={3}>
          {files &&
            files?.length > 0 &&
            files?.map((file, index) => (
              <Grid key={index} item xs={12} sm={12} md={4} lg={4} xl={4}>
                <FileItem
                  fileName={file?.file_name}
                  fileSrc={file?.file_path}
                  thumbnailSrc={getThumbnailSrcPath(file?.file_name, file?.file_path)}
                  onClick={() =>
                    setState({
                      selectedFileIndex: index,
                    })
                  }
                />
              </Grid>
            ))}

          <LightBox
            open={selectedFileIndex >= 0}
            index={selectedFileIndex}
            onClose={() => setState({ selectedFileIndex: -1 })}
            slides={lightBoxSlides}
          />
        </Grid>

        {files?.length === 0 && <div className={'no-files-placeholder'}>No Files Available</div>}
      </div>
    </div>
  );
};

export { ViewFiles };
