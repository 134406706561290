import React from 'react';
import { CircularProgress } from '@mui/material';

interface LoaderProps {
  containerStyle?: React.CSSProperties;
  progressStyle?: React.CSSProperties;
}

const Loader: React.FC<LoaderProps> = (props) => {
  const { containerStyle, progressStyle } = props;

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '10px',
          ...containerStyle,
        }}
      >
        <CircularProgress
          style={{
            height: 30,
            width: 30,
            ...progressStyle,
          }}
        />
      </div>
    </>
  );
};

export { Loader };
