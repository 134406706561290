import React from 'react';
import { useContainerStyles } from '@/static/stylesheets/organisms/containerStyles';
import classnames from 'classnames';

interface ContainerProps {
  children?: React.ReactNode;
  size: 'xxl' | 'lg' | 'md' | 'sm';
  containerStyle?: React.CSSProperties;
  containerChildStyle?: React.CSSProperties;
}

const Container: React.FC<ContainerProps> = (props) => {
  const classes = useContainerStyles();

  return (
    <>
      <div className={classes.root} style={props?.containerStyle}>
        <div className={'block-item'}>
          <div className={classes.container}>
            <div
              className={classnames({
                [props.size]: true,
                'container-default': true,
              })}
              style={props?.containerChildStyle}
            >
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { Container };
