import React, { useEffect, useState } from 'react';
import { SingleLead } from '@/components/molecules/Leads/SingleLead';
import { useAppSelector, useThrottle } from '@/hooks';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import {
  getLeads,
  getLeadStaffMembers,
  getJobProgressItemList,
  clearSendLeadFilter,
  toggleLostLeads,
  getEquipments,
} from '@/redux/actions';
import { LeadEmptyPlaceholder, LeadLoader } from '@/components/organisms/Leads';
import { Pagination } from '@/components/atoms/Pagination';
import { useLeadStyles } from '@/static/stylesheets/organisms';
import { LeadItem } from '@/types';
import { selectUiState } from '@/redux/reducers/uiSlice';
import { convertToNumber } from '@/utils';
import { SingleClient } from '@/components/molecules/Clients/SingleClient';

interface LeadListProps {}

const LeadList: React.FC<LeadListProps> = () => {
  const classes = useLeadStyles();

  const leadListResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_LEAD_LIST];
  const leadListFilterResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.SEND_LEAD_FILTER];
  const leads: LeadItem[] = leadListFilterResponse?.data?.data
    ? leadListFilterResponse?.data?.data ?? []
    : leadListResponse?.data?.data ?? [];
  const totalPages = convertToNumber(
    leadListFilterResponse?.data ? leadListFilterResponse?.data?.last_page : leadListResponse?.data?.last_page,
  );
  const [limit, setLimit] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);

  const createdLead = useAppSelector(selectHttpState)[CORRELATION_IDS.CREATE_LEAD];
  const updatedLead = useAppSelector(selectHttpState)[CORRELATION_IDS.UPDATE_LEAD];
  const { showLostLeads, globalSearch } = useAppSelector(selectUiState);

  const [initialBoot, setInitialBoot] = useState(true);

  const leadSearchQuery = useThrottle(globalSearch, 1000);

  const getStaffMemberList = () =>
    getLeadStaffMembers({
      limit: 'all',
      page: 1,
    });

  const getLeadList = () =>
    getLeads({
      limit,
      page: currentPage,
      type: !leadSearchQuery || leadSearchQuery?.trim() === '' ? (showLostLeads ? 'lostLead' : 'lead') : undefined,
      s: leadSearchQuery ?? undefined,
    });

  const Loader = () => {
    return (
      <>
        {new Array(limit).fill('').map((item, index) => (
          <LeadLoader key={index} />
        ))}
      </>
    );
  };

  const fetchEquipments = () => {
    getEquipments({
      limit: 'all',
      page: 1,
    });
  };

  useEffect(() => {
    if (updatedLead && updatedLead?.success) {
      getLeadList();
    }
  }, [updatedLead]);

  useEffect(() => {
    if (createdLead && createdLead?.success) {
      getLeadList();
    }
  }, [createdLead]);

  useEffect(() => {
    getJobProgressItemList({
      progressType: 'lead',
    });
    getStaffMemberList();
    fetchEquipments();
  }, []);

  useEffect(() => {
    getLeadList();
    setInitialBoot(false);
  }, [currentPage]);

  useEffect(() => {
    if (!initialBoot) {
      getLeadList();
    }
  }, [leadSearchQuery]);

  useEffect(() => {
    return () => {
      clearSendLeadFilter();
      toggleLostLeads(false);
    };
  }, []);

  return (
    <>
      {leadListResponse?.loading ? (
        <Loader />
      ) : (
        <>
          {leads &&
            leads?.length > 0 &&
            leads?.map((item, index) => (
              <React.Fragment key={index}>
                {(item?.customer?.customer_type_name === 'Lead' ||
                  item?.customer?.customer_type_name === 'Lost Lead') && (
                  <SingleLead
                    data={item}
                    reloadList={getLeadList}
                    isLostLead={item?.customer?.customer_type_name === 'Lost Lead'}
                  />
                )}

                {(item?.customer?.customer_type_name === 'Clients' ||
                  item?.customer?.customer_type_name === 'Deactivated Client') && (
                  <SingleClient
                    data={item}
                    reloadList={getLeadList}
                    isDeactivatedClient={item?.customer?.customer_type_name === 'Deactivated Client'}
                  />
                )}
              </React.Fragment>
            ))}
          {leadListResponse?.success && leads.length === 0 && <LeadEmptyPlaceholder />}
          {totalPages > 1 && (
            <div className={classes.pagination}>
              <Pagination count={totalPages} currentPage={currentPage} onChange={(value) => setCurrentPage(value)} />
            </div>
          )}
        </>
      )}
    </>
  );
};

export { LeadList };
