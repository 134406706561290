import { prepareApiBaseFromEnv } from "@/utils";

const clients = {
    local: {
        apiBaseURL: "https://devapi.webperception.net/api/v1",
    },
    live: {
        apiBaseURL: "https://devapi.webperception.net/api/v1",
    },
    devServer1: {
        apiBaseURL: "http://103.87.215.131/webperception/public/api/v1",
    },
    devServer2: {
        apiBaseURL: "https://devapi.webperception.net/api/v1",
    },
};

const defaultClient = () => clients.live;
const localClient = () => clients.devServer2;

export const apiConfig = {
  root: prepareApiBaseFromEnv(
    localClient().apiBaseURL,
    defaultClient().apiBaseURL
  ),
};
