import { ContentWrapper } from '@/components/organisms/Reports';
import { ReportTable, Tbody, Td, Th, Tr } from '@/components/organisms/Reports/ReportContent/ReportTable';
import { useAppSelector } from '@/hooks';
import { getJobProgressItemList, getProgressReportAction } from '@/redux/actions';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { useEffect } from 'react';

const TechWorkProgress = () => {
  const result = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_PROGRESS_REPORT];
  const jobProgressBarList = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_PROGRESS_LIST];

  const fetchProgressItems = () =>
    getJobProgressItemList({
      progressType: 'job',
    });

  useEffect(() => {
    getProgressReportAction({
      report_type: 'job',
    });
    fetchProgressItems();
  }, []);

  return (
    <>
      <ContentWrapper headings={['Tech Work | Progress']}>
        <ReportTable tableContainerClass='no-border-bottom' marginBottom={'5px'}>
          <Tbody>
            <Tr>
              {((jobProgressBarList && jobProgressBarList?.data?.data) ?? []).map((item: any, index: number) => {
                return (
                  <Th align={'center'} key={index}>
                    {item?.progress_name}
                  </Th>
                );
              })}
            </Tr>
            <Tr>
              {((jobProgressBarList && jobProgressBarList?.data?.data) ?? []).map((item: any, index: number) => {
                const find = (result?.data?.break_down ?? []).find((_: any) => _.id === item.id);
                return (
                  <Td align={'center'} key={index}>
                    {find?.job_details_count || 0}
                  </Td>
                );
              })}
            </Tr>
            <Tr>
              {((jobProgressBarList && jobProgressBarList?.data?.data) ?? []).map((item: any, index: number) => {
                const find = (result?.data?.break_down ?? []).find((_: any) => _.id === item.id);
                return (
                  <Td align={'center'} key={index}>
                    {+find?.percent.toFixed(2) || 0}%
                  </Td>
                );
              })}
            </Tr>
          </Tbody>
        </ReportTable>

        <div className={'title-bar footer-title'}>Total Jobs: {result?.data?.total_count}</div>
      </ContentWrapper>
    </>
  );
};

export { TechWorkProgress };
