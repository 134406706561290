import React, { useEffect } from 'react';
import { SearchableTextInput, TextInput } from '@/components/atoms/TextInput';
import { Button } from '@/components/atoms/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useJobStyles } from '@/static/stylesheets/molecules';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { useAppSelector, useComponentState } from '@/hooks';
import { convertToNumber, isInt } from '@/utils';
import {
  clearCreatedJob,
  clearUpdatedJob,
  createJob,
  getEquipments,
  getJobCustomers,
  getJobProgressItemList,
  getJobStaffMembers,
  getJobTypesAction,
  updateJob,
} from '@/redux/actions';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { EquipmentParams, JobItem, JobProgressItemParams, JobTypeParams, UserParams } from '@/types';
import toast from 'react-hot-toast';
import { alertMessages } from '@/config';
import { Box, Checkbox, FormControlLabel, FormGroup, Switch } from '@mui/material';
import { colors, themeParser, useAppTheme } from '@/theme';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { CreateClient } from '@/components/molecules/Clients';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { CreateLead } from '@/components/molecules/Leads';
import { convertToPayloadParam } from '@/utils/convertToPayloadParam';
import TextWithShowMore from '@/components/atoms/Utils/TextWithShowMore';

interface CreateJobsProps {
  open: boolean;
  mode: 'create' | 'edit';
  editData?: JobItem;

  onClose(): void;

  onSuccess?(): void;
}

interface ProgressBarItemProps {
  title?: string;
  checked?: boolean;
  disabled?: boolean;
  labelClass?: string;

  onClick?(): void;
}
type Task = { id?: string | null | undefined; task_name?: string | undefined; hours?: string | number | undefined };
type Equipment = {
  equipment_id?: string;
  quantity?: string | number;
};
interface CreateJobFormData {
  id?: string;
  customer_id?: string;
  job_status_type_id: string;
  job_types: string[];
  assigned_to?: string;
  tasks?: Task[] | null;
  equipments?: Equipment[] | null;
  deleted_task_ids?: string[];
  additional_hours: number | string;
  note: string;
  progress_bar_items: string[];
}

const ProgressBarItem: React.FC<ProgressBarItemProps> = ({ title, checked, disabled, onClick, labelClass }) => {
  const theme = useAppTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '40px',
        borderBottom: `1px solid ${themeParser('#f1f1f1', '#343434', theme)}`,
        fontSize: '15px',
        fontFamily: "'Poppins', sans-serif",
      }}
    >
      <div className={labelClass}>{title}</div>
      <div>
        <Switch checked={checked} disabled={disabled} onClick={onClick} />
      </div>
    </Box>
  );
};

const CreateJob: React.FC<CreateJobsProps> = (props) => {
  const classes = useJobStyles();
  const { open, onClose } = props;
  const editMode: boolean = props?.mode === 'edit' && props?.editData !== undefined && props?.editData?.id !== '';
  const editFormData = props?.editData;

  const staffMemberList = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_JOB_STAFF_MEMBERS];
  const staffMembers: UserParams[] = staffMemberList?.data?.data ?? [];

  const customerList = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_JOB_LEAD_CLIENTS];
  const customers: UserParams[] = customerList?.data?.data ?? [];

  const createdJob = useAppSelector(selectHttpState)[CORRELATION_IDS.CREATE_JOB];
  const updatedJob = useAppSelector(selectHttpState)[CORRELATION_IDS.UPDATE_JOB];

  const jobTypeList = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_JOB_TYPES];
  const jobTypeItems: JobTypeParams[] = jobTypeList?.data?.data ?? [];

  const jobProgressBarList = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_PROGRESS_LIST];
  const jobProgressBarItems: JobProgressItemParams[] = jobProgressBarList?.data?.data ?? [];

  const equipmentListResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_EQUIPMENTS];
  const equipmentItems: EquipmentParams[] = equipmentListResponse?.data?.data ?? [];

  const emptyTaskItemRow = {
    task_name: '',
    hours: '',
  };

  const emptyEquipmentItemRow = {
    equipment_id: '',
    quantity: '',
  };

  const {
    setValue,
    watch,
    reset,
    control,
    trigger,
    setError,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm<CreateJobFormData>({
    mode: 'onChange',
    defaultValues: {
      tasks: [emptyTaskItemRow],
      equipments: [emptyEquipmentItemRow],
      job_types: [],
      progress_bar_items: [],
    },
  });

  const {
    fields: taskFields,
    append: appendTaskItem,
    remove: removeTaskItem,
  } = useFieldArray({
    control,
    name: 'tasks',
  });

  const {
    fields: equipmentFields,
    append: appendEquipmentItem,
    remove: removeEquipmentItem,
  } = useFieldArray({
    control,
    name: 'equipments',
  });

  const {
    state: { clientsFormPopupOpen, showFormContents },
    setState,
  } = useComponentState({
    clientsFormPopupOpen: false,
    showFormContents: false,
  });

  const formValues = watch();
  const selectedCustomer = customers?.find((c) => c?.id === formValues?.customer_id);

  const handleClickSelectCustomerButton = async () => {
    const formIsValid = await trigger('customer_id');
    if (formIsValid) {
      setState({ showFormContents: true });
    }
  };

  const addTaskItemRow = () => {
    appendTaskItem(emptyTaskItemRow);
  };

  const removeTaskItemRow = (index: number) => {
    const item = (formValues?.tasks ?? [])[index];
    const deletedTasks = [...(formValues?.deleted_task_ids ?? [])];
    if (item?.id) {
      deletedTasks.push(item?.id);
    }

    setValue('deleted_task_ids', deletedTasks);

    removeTaskItem(index);
  };

  const addEquipmentItemRow = () => {
    appendEquipmentItem(emptyEquipmentItemRow);
  };

  const removeEquipmentItemRow = (index: number) => {
    removeEquipmentItem(index);
  };

  const resetFields = () => {
    reset({
      id: undefined,
      customer_id: undefined,
      job_status_type_id: undefined,
      job_types: [],
      assigned_to: undefined,
      tasks: [emptyTaskItemRow],
      equipments: [emptyEquipmentItemRow],
      additional_hours: undefined,
      note: undefined,
      progress_bar_items: [],
    });

    setState({ showFormContents: false });
  };

  const getJobProgressItems = () =>
    getJobProgressItemList({
      progressType: 'job',
    });

  const getJobTypes = () => getJobTypesAction();

  const getStaffMemberList = () =>
    getJobStaffMembers({
      limit: 'all',
      page: 1,
    });

  const getCustomers = () =>
    getJobCustomers({
      customer_type: '[Clients,Lead]',
      limit: 'all',
      page: 1,
    });

  const getEquipmentItems = () =>
    getEquipments({
      limit: 'all',
      page: 1,
    });

  const handleClickJobTypeCheckbox = (value?: string) => {
    if (value) {
      const jobTypes = [...(formValues?.job_types ?? [])];
      const existingIndex = jobTypes?.indexOf(value);

      if (existingIndex > -1) {
        jobTypes?.splice(existingIndex, 1);
      } else {
        jobTypes?.push(value);
      }

      setValue('job_types', jobTypes);
    }
  };

  const isJobTypeSelected = (value?: string) => {
    if (value) {
      return (formValues?.job_types ?? [])?.indexOf(value) > -1;
    }
    return false;
  };

  const handleClickJobProgressBarSwitch = (value?: string) => {
    if (value) {
      const progressBarItems = [...(formValues?.progress_bar_items ?? [])];
      const existingIndex = progressBarItems?.indexOf(value);

      if (existingIndex > -1) {
        progressBarItems?.splice(existingIndex, 1);
      } else {
        progressBarItems?.push(value);
      }

      setValue('progress_bar_items', progressBarItems);
    }
  };

  const isJobProgressBarItemSelected = (value?: string) => {
    if (value) {
      return (formValues?.progress_bar_items ?? [])?.indexOf(value) > -1;
    }
    return false;
  };

  const submitJob = async () => {
    const formIsValid = await trigger();

    if (formIsValid) {
      const validTasks = formValues?.tasks?.filter((task) => task?.task_name && task?.hours);
      const validEquipments = formValues?.equipments?.filter((task) => task?.equipment_id && task?.quantity);

      const data = { ...formValues };

      if (editMode) {
        data.id = props?.editData?.id;
      }

      if (editMode && props?.editData?.id) {
        const payload = {
          ...data,
          tasks: convertToPayloadParam(validTasks, null),
          equipments: convertToPayloadParam(validEquipments, null),
        };
        updateJob(props?.editData?.id, payload);
      } else {
        const payload = {
          ...data,
          tasks: convertToPayloadParam(validTasks),
          equipments: convertToPayloadParam(validEquipments),
        };
        createJob(payload);
      }
    }
  };

  useEffect(() => {
    if (open) {
      const progressBarItems = [...(formValues?.progress_bar_items ?? [])];

      jobProgressBarItems?.forEach((item) => {
        if (item?.id && convertToNumber(item?.status) === 0) {
          const existingIndex = progressBarItems?.indexOf(item?.id);

          if (existingIndex === -1) {
            progressBarItems?.push(item?.id);
          }
        }
      });

      setValue('progress_bar_items', progressBarItems);
    }
  }, [jobProgressBarItems, open]);

  useEffect(() => {
    if (!editMode) {
      if (createdJob && createdJob?.success) {
        toast.success(alertMessages.jobs.jobCreateSuccess);
        clearCreatedJob();

        if (props?.onClose) {
          props?.onClose();
        }

        if (props?.onSuccess) {
          props?.onSuccess();
        }
      }

      if (createdJob && createdJob?.error) {
        const error = createdJob?.error;
        if (error?.status === 422) {
          toast.error(<TextWithShowMore text={error?.data?.message} />);
        } else {
          toast.error(alertMessages.jobs.jobCreateError);
        }
        clearCreatedJob();
      }
    }
  }, [createdJob, editMode]);

  useEffect(() => {
    if (open && updatedJob && updatedJob?.success) {
      toast.success(alertMessages.jobs.jobUpdateSuccess);
      clearUpdatedJob();

      if (props?.onSuccess) {
        props?.onSuccess();
      }

      if (props?.onClose) {
        props?.onClose();
      }
    }

    if (open && updatedJob && updatedJob?.error) {
      const error = updatedJob?.error;
      if (error?.status === 422) {
        toast.error(error?.data?.message);
      } else {
        toast.error(alertMessages.jobs.jobUpdateError);
      }
      clearUpdatedJob();
    }
  }, [updatedJob, open]);

  useEffect(() => {
    if (open && editMode && editFormData) {
      const jobTypes: string[] = [];
      const progressBarItems: string[] = [];

      (editFormData?.job_types ?? [])?.forEach((item) => {
        if (item?.id) {
          jobTypes.push(item?.id);
        }
      });

      (editFormData?.progress_steps ?? [])?.forEach((item) => {
        if (item?.id) {
          progressBarItems.push(item?.id);
        }
      });

      console.log('editFormData', editFormData);
      setValue('customer_id', editFormData?.customer?.id);
      setValue('job_types', jobTypes);
      setValue('tasks', editFormData?.labour?.tasks ?? []);
      setValue('equipments', editFormData?.equipments?.items ?? []);
      setValue('progress_bar_items', progressBarItems);
      setValue('additional_hours', convertToNumber(editFormData?.additional_hours));
      setValue('assigned_to', editFormData?.assigned_to?.id);

      setState({ showFormContents: true });
    }
  }, [editMode, editFormData, open]);

  useEffect(() => {
    if (!open) {
      resetFields();
    } else {
      getCustomers();
      getStaffMemberList();
      getJobProgressItems();
      getJobTypes();
      getEquipmentItems();
    }
  }, [open]);

  return (
    <>
      <Dialog
        className={classes.root}
        open={open}
        maxWidth={'xl'}
        onClose={onClose}
        sx={{
          '& .MuiDialog-paper': {
            width: showFormContents && formValues?.customer_id ? '1600px' : '850px',
          },
        }}
      >
        <DialogTitle className={classes.createJobsTitle}>
          {`${editMode ? 'Edit Job for' : 'Add New Job'}`}
          {showFormContents && (
            <>
              {selectedCustomer ? ` - ${selectedCustomer?.first_name ?? ''} ${selectedCustomer?.last_name ?? ''}` : ''}
            </>
          )}
        </DialogTitle>
        <DialogContent className={classes.DialogContentRoot}>
          <DialogContentText className={classes.DialogContent}>
            <Grid container spacing={2}>
              {!editMode && !showFormContents && (
                <Grid item sm={12} md={12} lg={12}>
                  <Box className={'header_search_part'}>
                    <Box sx={{ display: 'inline-block', width: 550 }}>
                      <Controller
                        control={control}
                        name={'customer_id'}
                        rules={{
                          required: 'Please select a customer!',
                        }}
                        render={({ field }) => (
                          <SearchableTextInput
                            label={'Choose from existing Lead/Client'}
                            options={[
                              ...(customers ?? []).map((item) => ({
                                label: `${item?.first_name ?? ''} ${item?.last_name ?? ''}`,
                                value: item?.id,
                              })),
                            ]}
                            onChange={(value) => {
                              setValue('customer_id', value);
                            }}
                            onClear={() => setState({ showFormContents: false })}
                            value={field?.value}
                            error={!!errors?.customer_id}
                            errorText={errors?.customer_id?.message}
                            loading={false}
                          />
                        )}
                      />
                    </Box>
                    <Box sx={{ display: 'inline-block' }}>
                      <Button
                        title={'Select'}
                        style={{ margin: 0, marginLeft: 10 }}
                        onClick={handleClickSelectCustomerButton}
                      />
                    </Box>
                  </Box>
                  <Box sx={{ textAlign: 'center', marginBottom: 2 }}>
                    {`Or `}
                    <span
                      style={{ color: colors.primary, cursor: 'pointer' }}
                      onClick={() =>
                        setState({
                          clientsFormPopupOpen: true,
                        })
                      }
                    >
                      click here to create a new client
                    </span>
                  </Box>
                </Grid>
              )}

              {showFormContents && formValues?.customer_id && (
                <>
                  <Grid item sm={12} md={6} lg={3.9} className='section_part'>
                    <Box sx={{ paddingRight: 2 }}>
                      <Box>
                        <div className={'field-title'}>Type of job (Choose all that apply)</div>
                        <Controller
                          control={control}
                          name={'job_types'}
                          rules={{
                            validate(value) {
                              if (value?.length === 0) {
                                return 'Please select job type!';
                              }

                              return true;
                            },
                          }}
                          render={() => (
                            <Grid container>
                              {jobTypeItems?.map((item, index) => (
                                <Grid key={index} item sm={12} md={12} lg={6}>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={isJobTypeSelected(item?.id)}
                                          onClick={() => handleClickJobTypeCheckbox(item?.id)}
                                        />
                                      }
                                      label={item?.name}
                                      classes={{ label: 'content-text' }}
                                    />
                                  </FormGroup>
                                </Grid>
                              ))}
                            </Grid>
                          )}
                        />
                        {errors?.job_types && (
                          <div style={{ fontSize: '11.25px', color: 'red' }}>{errors?.job_types?.message}</div>
                        )}
                      </Box>

                      <Box sx={{ marginTop: '25px' }}>
                        <div className={'field-title'}>Tasks</div>
                        {taskFields?.map((field, index) => (
                          <Grid key={index} container>
                            <Grid item xs={7} sm={7} md={7} lg={7}>
                              <Box sx={{ paddingRight: 1 }}>
                                <Controller
                                  control={control}
                                  name={`tasks.${index}.task_name`}
                                  rules={{
                                    required: getValues(`tasks.${index}.hours`) ? 'Please enter task name!' : false,
                                    validate(value) {
                                      !value && clearErrors(`tasks.${index}.hours`);
                                      return true;
                                    },
                                  }}
                                  render={({ field }) => (
                                    <TextInput
                                      variant={'standard'}
                                      placeholder={'Task'}
                                      fullWidth={true}
                                      {...field}
                                      error={errors?.tasks && !!errors?.tasks[index]?.task_name}
                                      errorText={errors?.tasks && (errors?.tasks[index]?.task_name?.message as any)}
                                    />
                                  )}
                                />
                              </Box>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4}>
                              <Box sx={{ paddingLeft: 1 }}>
                                <Controller
                                  control={control}
                                  name={`tasks.${index}.hours`}
                                  rules={{
                                    required: getValues(`tasks.${index}.task_name`) ? 'Required' : false,
                                    validate(value) {
                                      if (value && (isNaN(Number(value)) || Number(value) <= 0 || !isInt(value))) {
                                        return 'Integer only!';
                                      }
                                      !value && clearErrors(`tasks.${index}.task_name`);
                                      return true;
                                    },
                                  }}
                                  render={({ field }) => (
                                    <TextInput
                                      variant={'standard'}
                                      placeholder={'Hours'}
                                      fullWidth={true}
                                      centerMode={true}
                                      {...field}
                                      error={errors?.tasks && !!errors?.tasks[index]?.hours}
                                      errorText={errors?.tasks && (errors?.tasks[index]?.hours?.message as any)}
                                    />
                                  )}
                                />
                              </Box>
                            </Grid>
                            <Grid item xs={1} sm={1} md={1} lg={1}>
                              {index === 0 ? (
                                <Button
                                  title={
                                    <>
                                      <AddCircleOutlineIcon />
                                    </>
                                  }
                                  style={{ margin: 0, marginTop: 20, marginLeft: 10, width: '35px', minWidth: 'auto' }}
                                  size={'small'}
                                  onClick={addTaskItemRow}
                                />
                              ) : (
                                <Button
                                  color={'error'}
                                  title={
                                    <>
                                      <DeleteIcon />
                                    </>
                                  }
                                  style={{ margin: 0, marginTop: 20, marginLeft: 10, width: '35px', minWidth: 'auto' }}
                                  size={'small'}
                                  onClick={() => removeTaskItemRow(index)}
                                />
                              )}
                            </Grid>
                          </Grid>
                        ))}
                      </Box>

                      <Box sx={{ marginTop: '60px' }}>
                        <div className={'field-title'}>Equipment Needed</div>
                        {equipmentFields?.map((field, index) => (
                          <Grid key={index} container>
                            <Grid item xs={7} sm={7} md={7} lg={7}>
                              <Box sx={{ paddingRight: 1 }}>
                                <Controller
                                  control={control}
                                  name={`equipments.${index}.equipment_id`}
                                  rules={{
                                    required: getValues(`equipments.${index}.quantity`)
                                      ? 'Please select equipment!'
                                      : false,
                                  }}
                                  render={({ field }) => (
                                    <SearchableTextInput
                                      placeholder={'Item'}
                                      options={[
                                        ...(equipmentItems ?? []).map((item) => ({
                                          label: item?.equipment_name,
                                          value: item?.id,
                                        })),
                                      ]}
                                      onChange={(value) => {
                                        setValue(`equipments.${index}.equipment_id`, value);
                                        value && setValue(`equipments.${index}.quantity`, 1);
                                        !value && clearErrors(`equipments.${index}.quantity`);
                                      }}
                                      value={field?.value}
                                      error={errors?.equipments && !!errors?.equipments[index]?.equipment_id}
                                      errorText={
                                        errors?.equipments && (errors?.equipments[index]?.equipment_id?.message as any)
                                      }
                                    />
                                  )}
                                />
                              </Box>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4}>
                              <Box sx={{ paddingLeft: 1 }}>
                                <Controller
                                  control={control}
                                  name={`equipments.${index}.quantity`}
                                  rules={{
                                    required: getValues(`equipments.${index}.equipment_id`) ? 'Required' : false,
                                    validate(value) {
                                      if (value && (isNaN(Number(value)) || Number(value) <= 0 || !isInt(value))) {
                                        return 'Integer only!';
                                      }
                                      !value && clearErrors(`equipments.${index}.equipment_id`);
                                      return true;
                                    },
                                  }}
                                  render={({ field }) => (
                                    <TextInput
                                      variant={'standard'}
                                      placeholder={'Quantity'}
                                      fullWidth={true}
                                      centerMode={true}
                                      {...field}
                                      error={errors?.equipments && !!errors?.equipments[index]?.quantity}
                                      errorText={
                                        errors?.equipments && (errors?.equipments[index]?.quantity?.message as any)
                                      }
                                    />
                                  )}
                                />
                              </Box>
                            </Grid>
                            <Grid item xs={1} sm={1} md={1} lg={1}>
                              {index === 0 ? (
                                <Button
                                  title={
                                    <>
                                      <AddCircleOutlineIcon />
                                    </>
                                  }
                                  style={{ margin: 0, marginTop: 20, marginLeft: 10, width: '35px', minWidth: 'auto' }}
                                  size={'small'}
                                  onClick={addEquipmentItemRow}
                                />
                              ) : (
                                <Button
                                  color={'error'}
                                  title={
                                    <>
                                      <DeleteIcon />
                                    </>
                                  }
                                  style={{ margin: 0, marginTop: 20, marginLeft: 10, width: '35px', minWidth: 'auto' }}
                                  size={'small'}
                                  onClick={() => removeEquipmentItemRow(index)}
                                />
                              )}
                            </Grid>
                          </Grid>
                        ))}
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item sm={12} md={6} lg={3.9} className='section_part'>
                    <Box sx={{ paddingLeft: 2, paddingRight: 5 }}>
                      <div className={'field-title'}>Quick Notes</div>
                      <Controller
                        control={control}
                        name={'note'}
                        render={({ field }) => (
                          <TextInput
                            multiline={true}
                            rows={8}
                            placeholder={'Add Quick Notes Here'}
                            variant={'standard'}
                            fullWidth={true}
                            {...field}
                            error={!!errors?.note}
                            errorText={errors?.note?.message}
                          />
                        )}
                      />

                      <div className={'field-title'} style={{ marginTop: 30 }}>
                        Additional Info
                      </div>
                      <Controller
                        control={control}
                        name={'additional_hours'}
                        rules={{
                          required: false,
                        }}
                        render={({ field }) => (
                          <TextInput
                            placeholder={'Initial Guesstimate of Job Duration (hours)'}
                            variant={'standard'}
                            fullWidth={true}
                            {...field}
                            error={!!errors?.additional_hours}
                            errorText={errors?.additional_hours?.message}
                          />
                        )}
                      />

                      <Controller
                        control={control}
                        name={'assigned_to'}
                        rules={{
                          required: 'Please select assigned to!',
                        }}
                        render={({ field }) => (
                          <TextInput
                            select={true}
                            variant={'standard'}
                            label={field?.value ? undefined : 'Assigned To'}
                            options={[
                              {
                                title: 'Assigned To',
                                value: undefined,
                                disabled: true,
                              },
                              ...(staffMembers && staffMembers?.length > 0
                                ? staffMembers?.map((item) => ({
                                    title: `${item?.first_name ?? ''} ${item?.last_name ?? ''}`,
                                    value: item?.id,
                                  }))
                                : []),
                            ]}
                            fullWidth={true}
                            {...field}
                            error={!!errors?.assigned_to}
                            errorText={errors?.assigned_to?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>

                  <Grid item sm={12} md={12} lg={4} className='section_part'>
                    <Box sx={{ paddingLeft: 2 }}>
                      <div className={'field-title'} style={{ marginBottom: '10px' }}>
                        {`Progress Bar Items `}
                        {errors?.progress_bar_items && (
                          <span style={{ fontSize: '11.25px', color: 'red' }}>
                            ({errors?.progress_bar_items?.message})
                          </span>
                        )}
                      </div>

                      <Controller
                        control={control}
                        name={'progress_bar_items'}
                        rules={{
                          validate(value) {
                            if (value?.length === 0) {
                              return 'Please select progress bar items!';
                            }

                            return true;
                          },
                        }}
                        render={() => (
                          <>
                            {jobProgressBarItems?.map((item, index) => (
                              <ProgressBarItem
                                key={index}
                                title={item?.progress_name}
                                checked={isJobProgressBarItemSelected(item?.id) || convertToNumber(item?.status) === 0}
                                disabled={convertToNumber(item?.status) === 0}
                                onClick={() => handleClickJobProgressBarSwitch(item?.id)}
                                labelClass={'content-text'}
                              />
                            ))}
                          </>
                        )}
                      />
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.createJobsBottom}>
          <Button onClick={onClose} color={'error'} variant={'contained'} title={'CANCEL'} />
          {formValues?.customer_id && showFormContents && (
            <Button
              onClick={submitJob}
              color={'success'}
              variant={'contained'}
              title={`${editMode ? 'SAVE' : 'CREATE'} JOB`}
              loading={createdJob?.loading || updatedJob?.loading}
            />
          )}
        </DialogActions>
      </Dialog>

      <CreateLead
        open={clientsFormPopupOpen}
        title={'Client'}
        onClose={() => setState({ clientsFormPopupOpen: false })}
        onSuccess={(customerData) => {
          setValue('customer_id', customerData?.id);
          setState({ showFormContents: true });
          getCustomers();
        }}
        mode={'create'}
      />
    </>
  );
};

export { CreateJob };
