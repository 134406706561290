import React from 'react';
import { useBackToTopButtonStyles } from '@/static/stylesheets/atoms';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
interface BackToTopButtonProps {
  value?: any;
  scrollTop?(): void;
}

const BackToTopButton: React.FC<BackToTopButtonProps> = (props) => {
  const classes = useBackToTopButtonStyles();

  return (
    <>
      <Fab className={classes.arrowUpButton} onClick={props.scrollTop}>
        <KeyboardArrowUpIcon />
      </Fab>
    </>
  );
};

export { BackToTopButton };
