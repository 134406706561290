import React from 'react';
import { useLeadExpandBodyStyle } from '@/static/stylesheets/organisms';
import { Grid } from '@mui/material';
import { TextInput } from '@/components/atoms/TextInput';

interface ContractProps {
  isDeactivatedClient?: boolean;
  formState: {
    bindDownload: any;
    downloadError?: any;

    bindUpload?: any;
    uploadError?: any;

    bindPrice?: any;
    priceError?: any;

    bindInstallPrice?: any;
    installPriceError?: any;

    bindValidity?: any;
    validityError?: any;

    bindAdditionalRecurring?: any;
    additionalRecurringError?: any;

    bindRecurringPrice?: any;
    recurringPriceError?: any;

    bindTerm?: any;
    termError?: any;
  };
}

const Contract: React.FC<ContractProps> = (props) => {
  const classes = useLeadExpandBodyStyle();
  const {
    isDeactivatedClient,
    formState: {
      bindDownload,
      downloadError,

      bindUpload,
      uploadError,

      bindPrice,
      priceError,

      bindInstallPrice,
      installPriceError,

      bindValidity,
      validityError,

      bindAdditionalRecurring,
      additionalRecurringError,

      bindRecurringPrice,
      recurringPriceError,

      bindTerm,
      termError,
    },
  } = props;

  return (
    <>
      <div className={classes.root}>
        <div className={'list_body'}>
          <Grid container spacing={2}>
            <Grid item sm={4} md={4} lg={4}>
              <TextInput
                variant={'standard'}
                placeholder={'Download'}
                centerMode={true}
                fullWidth={true}
                {...bindDownload}
                error={downloadError?.error}
                disabled={isDeactivatedClient}
              />
            </Grid>
            <Grid item sm={4} md={4} lg={4}>
              <TextInput
                variant={'standard'}
                placeholder={'Upload'}
                centerMode={true}
                fullWidth={true}
                {...bindUpload}
                error={uploadError?.error}
                disabled={isDeactivatedClient}
              />
            </Grid>
            <Grid item sm={4} md={4} lg={4}>
              <TextInput
                variant={'standard'}
                placeholder={'Price'}
                centerMode={true}
                fullWidth={true}
                {...bindPrice}
                error={priceError?.error}
                disabled={isDeactivatedClient}
              />
            </Grid>
            <Grid item sm={6} md={6} lg={6}>
              <TextInput
                variant={'standard'}
                placeholder={'Install Price'}
                centerMode={true}
                fullWidth={true}
                {...bindInstallPrice}
                error={installPriceError?.error}
                disabled={isDeactivatedClient}
              />
            </Grid>
            <Grid item sm={6} md={6} lg={6}>
              <TextInput
                variant={'standard'}
                placeholder={'Term (Years)'}
                centerMode={true}
                fullWidth={true}
                {...bindValidity}
                error={validityError?.error}
                disabled={isDeactivatedClient}
              />
            </Grid>
            <Grid item sm={6} md={6} lg={6}>
              <TextInput
                variant={'standard'}
                placeholder={'Additional Recurring'}
                centerMode={true}
                fullWidth={true}
                {...bindAdditionalRecurring}
                error={additionalRecurringError?.error}
                disabled={isDeactivatedClient}
              />
            </Grid>
            <Grid item sm={6} md={6} lg={6}>
              <TextInput
                variant={'standard'}
                placeholder={'Price'}
                centerMode={true}
                fullWidth={true}
                {...bindRecurringPrice}
                error={recurringPriceError?.error}
                disabled={isDeactivatedClient}
              />
            </Grid>
            <Grid item sm={12} md={12} lg={12}>
              <TextInput
                variant={'standard'}
                placeholder={'Additional Terms'}
                centerMode={true}
                fullWidth={true}
                {...bindTerm}
                error={termError?.error}
                disabled={isDeactivatedClient}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export { Contract };
