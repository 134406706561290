import { endpoints } from '@/api/endpoints';
import { FileUploaderParams, UserListFilterParams } from '@/types';
import { CORRELATION_IDS } from '../correlationIds';
import { performHttpCall } from '../httpSlice';

export const getFilterStaffMembersDropdownHttpCall = (params: UserListFilterParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_FILTER_STAFF_MEMBER_LIST,
    payload: {
      url: endpoints.private.getUsers(params),
      method: 'get',
      useBearerToken: true,
    },
  });

export const fileUploadHttpCall = (params: FileUploaderParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.UPLOAD_FILE,
    payload: {
      url: endpoints.private.uploadFile(params),
      method: 'post',
      useBearerToken: true,
      body: params?.data,
    },
  });

export const getSingleActivityEmailHttpCall = (activityEmailId: string) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_SINGLE_ACTIVITY_EMAIL,
    payload: {
      url: endpoints.private.getSingleActivityEmail(activityEmailId),
      method: 'get',
      useBearerToken: true,
    },
  });

export const getMediafiles = () =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_MEDIA_FILES,
    payload: {
      url: endpoints.private.getMediafiles,
      method: 'get',
      useBearerToken: true,
    },
  });
