import React, { useEffect } from 'react';
import { useLeadExpandBodyStyle } from '@/static/stylesheets/organisms';
import { Box, Grid } from '@mui/material';
import { TextInput } from '@/components/atoms/TextInput';
import { Button } from '@/components/atoms/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { SingleJobFormData } from '@/types/formData';
import { convertToNumber } from '@/utils';
import { ToggleSaveButtonEnabled } from '@/components/molecules/Jobs/SingleJob';
import { useJobListType } from '@/hooks/custom';

interface LaborFormProps {
  toggleSaveEnabled: ToggleSaveButtonEnabled;
}

const LaborForm: React.FC<LaborFormProps> = (props) => {
  const classes = useLeadExpandBodyStyle();
  const { fieldsDisabled } = useJobListType();

  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<SingleJobFormData>();

  const formValues = watch();

  const { remove, append } = useFieldArray({
    name: 'tasks',
    control,
  });

  const addRow = () => {
    append({
      task_name: '',
      hours: '',
    });
    props?.toggleSaveEnabled(true);
  };

  const removeRow = (index: number) => {
    const item = (formValues?.tasks ?? [])[index];
    const deletedTasks = [...(formValues?.deleted_task_ids ?? [])];
    if (item?.id) {
      deletedTasks.push(item?.id);
    }

    setValue('deleted_task_ids', deletedTasks);

    remove(index);
    props?.toggleSaveEnabled(true);
  };

  const getTotalHours = () => {
    let total = 0;
    formValues?.tasks?.forEach((item) => {
      total += convertToNumber(item?.hours);
    });
    return total;
  };

  const totalHours = getTotalHours();
  const totalLaborCost = totalHours * convertToNumber(formValues?.labour_price_markup);

  useEffect(() => {
    setValue('total_hours', totalHours);
    setValue('total_labor_cost', totalLaborCost);
  }, [totalHours, totalLaborCost]);

  return (
    <>
      <div className={classes.root}>
        <div className={'list_body'}>
          <Grid container spacing={2}>
            <Grid item sm={12} md={12} lg={12}>
              <Controller
                control={control}
                name={'labour_price_markup'}
                rules={{
                  required: false,
                  validate(value) {
                    if (isNaN(Number(value)) || Number(value) <= 0) {
                      return 'Integer only!';
                    }

                    return true;
                  },
                }}
                render={({ field }) => (
                  <TextInput
                    type={'number'}
                    variant={'standard'}
                    placeholder={'Labor Rate'}
                    centerMode={true}
                    fullWidth={true}
                    {...field}
                    onKeyUp={() => props?.toggleSaveEnabled(true)}
                    error={!!errors?.labour_price_markup}
                    disabled={fieldsDisabled}
                  />
                )}
              />
            </Grid>

            {formValues?.tasks?.map((item, index) => (
              <React.Fragment key={index}>
                <Grid item sm={6} md={6} lg={6}>
                  <Controller
                    control={control}
                    name={`tasks.${index}.task_name`}
                    rules={{
                      required: 'Please input task_name',
                    }}
                    render={({ field }) => (
                      <TextInput
                        type={'text'}
                        variant={'standard'}
                        placeholder={'Task'}
                        centerMode={true}
                        fullWidth={true}
                        {...field}
                        onKeyUp={() => props?.toggleSaveEnabled(true)}
                        error={errors?.tasks && !!errors?.tasks[index]?.task_name}
                        disabled={fieldsDisabled}
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={5} md={5} lg={5}>
                  <Controller
                    control={control}
                    name={`tasks.${index}.hours`}
                    rules={{
                      required: 'Please input hours',
                      validate(value) {
                        if (isNaN(Number(value)) || Number(value) <= 0) {
                          return 'Integer only!';
                        }

                        return true;
                      },
                    }}
                    render={({ field }) => (
                      <TextInput
                        type={'number'}
                        variant={'standard'}
                        placeholder={'Hours'}
                        centerMode={true}
                        fullWidth={true}
                        {...field}
                        onKeyUp={() => props?.toggleSaveEnabled(true)}
                        error={errors?.tasks && !!errors?.tasks[index]?.hours}
                        disabled={fieldsDisabled}
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={1} md={1} lg={1} className='delete'>
                  {!fieldsDisabled && index !== 0 && <DeleteIcon onClick={() => removeRow(index)} />}
                </Grid>
              </React.Fragment>
            ))}

            {!fieldsDisabled && (
              <Grid item className={'button'} sm={12} md={12} lg={12} style={{ marginBottom: '10px' }}>
                <Button onClick={addRow} variant={'contained'} title={'Add Task'} fullWidth={true} />
              </Grid>
            )}
          </Grid>

          <Box
            sx={{
              fontSize: '12px',
              marginTop: '10px',
              color: '#9d9d9d',
            }}
          >
            <Grid container spacing={2}>
              <Grid item sm={6} md={6} lg={6}>
                TOTAL HOURS: {convertToNumber(totalHours?.toFixed(2))}
              </Grid>
              <Grid item sm={6} md={6} lg={6} sx={{ textAlign: 'right' }}>
                TOTAL LABOR COST: ${convertToNumber(totalLaborCost?.toFixed(2))}
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
    </>
  );
};

export { LaborForm };
