import { endpoints } from '@/api/endpoints';
import { performHttpCall } from '../httpSlice';
import { CORRELATION_IDS } from '../correlationIds';
import {
  CustomerProfileParams,
  JobListFilterParams,
  UserListFilterParams,
  QuickNotesParams,
  ActivityLogsParams,
  EquipmentParams,
  EquipmentBodyParams,
  MoveJobParams,
  JobFilterParams,
  FilesParams,
  JobItem,
  SendJobDripParams,
  CustomerListFilterParams,
  ProgressItem,
  JobUpdateParams,
} from '@/types';
import { SingleJobFormData } from '@/types/formData';

export const getJobsHttpCall = (params: JobListFilterParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_JOB_LIST,
    payload: {
      url: endpoints.private.getJobs(params),
      method: 'get',
      useBearerToken: true,
    },
  });

export const getJobStaffMembersDropdownHttpCall = (params: UserListFilterParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_JOB_STAFF_MEMBERS,
    payload: {
      url: endpoints.private.getUsers(params),
      method: 'get',
      useBearerToken: true,
    },
  });

export const createJobHttpCall = (params: CustomerProfileParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.CREATE_JOB,
    payload: {
      url: endpoints.private.createJob,
      method: 'post',
      useBearerToken: true,
      body: params,
    },
  });

export const updateJobHttpCall = (id: string, data: SingleJobFormData) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.UPDATE_JOB,
    payload: {
      url: endpoints.private.updateJob(id),
      method: 'patch',
      useBearerToken: true,
      body: data,
    },
  });

export const updateJobDetailsHttpCall = (id: string, data: SingleJobFormData) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.UPDATE_JOB_DETAILS,
    payload: {
      url: endpoints.private.updateJobDetails(id),
      method: 'patch',
      useBearerToken: true,
      body: data,
    },
  });

export const updateJobVMCountHttpCall = (id: string) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.UPDATE_JOB_VM_COUNT,
    payload: {
      url: endpoints.private.updateJobVMCount(id ?? ''),
      method: 'patch',
      useBearerToken: true,
    },
  });

export const sendJobContractHttpCall = (id: string) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.SEND_JOB_CONTRACT,
    payload: {
      url: endpoints.private.sendsJobContract(id ?? ''),
      method: 'get',
      useBearerToken: true,
    },
  });

export const restoreJobHttpCall = (id: string) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.RESTORE_JOB,
    payload: {
      url: endpoints.private.restoresJob(id ?? ''),
      method: 'patch',
      useBearerToken: true,
    },
  });

export const createJobEquipmentsHttpCall = (params: EquipmentBodyParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.CREATE_JOB_EQUIPMENTS,
    payload: {
      url: endpoints.private.createJobEquipments(params?.leadId),
      method: 'put',
      useBearerToken: true,
      body: {
        equipments: params.equipments,
      },
    },
  });

export const getJobEquipmentsHttpCall = (id: string) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_JOB_EQUIPMENTS,
    payload: {
      url: endpoints.private.getJobEquipments(id),
      method: 'get',
      useBearerToken: true,
    },
  });

export const deleteJobEquipmentHttpCall = (id: EquipmentParams['id']) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.DELETE_JOB_EQUIPMENT,
    payload: {
      url: endpoints.private.deleteJobEquipment(id ?? ''),
      method: 'delete',
      useBearerToken: true,
    },
  });

export const deleteAllJobEquipmentsHttpCall = (id: EquipmentParams['id']) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.DELETE_JOB_EQUIPMENT,
    payload: {
      url: endpoints.private.deleteAllJobEquipments(id ?? ''),
      method: 'delete',
      useBearerToken: true,
    },
  });

export const updateJobStatusHttpCall = (jobId: JobItem['id'], params: JobUpdateParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.UPDATE_JOB_STATUS,
    payload: {
      url: endpoints.private.updateJobStatus(jobId),
      method: 'put',
      useBearerToken: true,
      body: params,
    },
  });

export const updateJobCustomerDetailsHttpCall = (params: CustomerProfileParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.UPDATE_JOB_CUSTOMER_DETAILS,
    payload: {
      url: endpoints.private.updateJobCustomerDetails(params?.id),
      method: 'put',
      useBearerToken: true,
      body: params,
    },
  });

export const getJobQuickNotesHttpCall = (id: QuickNotesParams['id']) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_JOB_QUICK_NOTES,
    payload: {
      url: endpoints.private.getJobQuickNotes(id),
      method: 'get',
      useBearerToken: true,
    },
  });

export const createJobQuickNotesHttpCall = (data: QuickNotesParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.CREATE_JOB_QUICK_NOTES,
    payload: {
      url: endpoints.private.createJobQuickNotes(data?.id ?? ''),
      method: 'post',
      useBearerToken: true,
      body: data,
    },
  });

export const updateJobQuickNotesHttpCall = (data: QuickNotesParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.UPDATE_JOB_QUICK_NOTES,
    payload: {
      url: endpoints.private.updateJobQuickNotes(data?.id ?? ''),
      method: 'patch',
      useBearerToken: true,
      body: data,
    },
  });

export const toggleJobQuickNotePinHttpCall = (data: QuickNotesParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.UPDATE_JOB_QUICK_NOTES,
    payload: {
      url: endpoints.private.toggleJobQuickNotePin(data?.id ?? ''),
      method: 'put',
      useBearerToken: true,
      body: data,
    },
  });

export const deleteJobQuickNotesHttpCall = (id: QuickNotesParams['id']) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.DELETE_JOB_QUICK_NOTES,
    payload: {
      url: endpoints.private.deleteJobQuickNotes(id ?? ''),
      method: 'delete',
      useBearerToken: true,
    },
  });

export const getJobActivityLogsHttpCall = (params: ActivityLogsParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_JOB_ACTIVITY_LOGS,
    payload: {
      url: endpoints.private.getJobActivityLogs(params),
      method: 'get',
      useBearerToken: true,
    },
  });

export const moveJobHttpCall = (params: MoveJobParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.MOVE_JOB,
    payload: {
      url: endpoints.private.moveJob(params?.jobId),
      method: 'patch',
      useBearerToken: true,
      body: params,
    },
  });

export const sendJobDripHttpCall = (params: SendJobDripParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.SEND_JOB_DRIP,
    payload: {
      url: endpoints.private.sendJobDrip(params?.jobId),
      method: 'get',
      useBearerToken: true,
      body: params,
    },
  });

export const sendJobFilterHttpCall = (data: JobFilterParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.SEND_JOB_FILTER,
    payload: {
      url: endpoints.private.sendJobFilter,
      method: 'post',
      useBearerToken: true,
      body: data,
    },
  });

export const uploadJobFileHttpCall = (
  id: FilesParams['id'],
  formData: any,
  onUploadProgress: (progressEvent: any) => void,
) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.UPLOAD_JOB_FILE,
    payload: {
      url: endpoints.private.uploadJobFile(id ?? ''),
      method: 'post',
      useBearerToken: true,
      body: formData,
      onUploadProgress,
    },
  });

export const getJobFileDetailsHttpCall = (id: any) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_JOB_FILE_DETAILS,
    payload: {
      url: endpoints.private.getJobFileDetails(id),
      method: 'get',
      useBearerToken: true,
    },
  });

export const deleteJobFileHttpCall = (id: any) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.DELETE_JOB_FILE,
    payload: {
      url: endpoints.private.deleteJobFile(id ?? ''),
      method: 'delete',
      useBearerToken: true,
    },
  });

export const getJobContractPDFHttpCall = (jobId: JobItem['id']) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_JOB_CONTRACT_PDF,
    payload: {
      url: endpoints.private.getJobContractPDF(jobId),
      method: 'get',
      useBearerToken: true,
    },
  });

export const getJobCustomersDropdownHttpCall = (params: CustomerListFilterParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_JOB_LEAD_CLIENTS,
    payload: {
      url: endpoints.private.getCustomers(params),
      method: 'get',
      useBearerToken: true,
    },
  });

export const getJobTypesHttpCall = () =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_JOB_TYPES,
    payload: {
      url: endpoints.private.getJobTypes(),
      method: 'get',
      useBearerToken: true,
    },
  });

export const updateJobProgressListHttpCall = (id: ProgressItem['id'], data: ProgressItem) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.UPDATE_JOB_PROGRESS_LIST,
    payload: {
      url: endpoints.private.updateJobProgressList(id ?? ''),
      method: 'patch',
      useBearerToken: true,
      body: data,
    },
  });
