import React, { useEffect, useState } from 'react';
import { useLeadExpandBodyStyle } from '@/static/stylesheets/organisms';
import { EquipmentForm, LaborForm, ProposalAndTermsForm } from '@/components/organisms/Jobs';
import { Grid } from '@mui/material';
import { ToggleSaveButtonEnabled } from '@/components/molecules/Jobs/SingleJob';
import { JobItem } from '@/types';

interface ProposalInfoProps {
  toggleSaveEnabled: ToggleSaveButtonEnabled;
  jobData?: JobItem;
  activeTab?: TabElements | null;
  onClearActiveTab?(): void;
}

export type TabElements = 'proposal' | 'equipment' | 'labor';

const ProposalInfo: React.FC<ProposalInfoProps> = (props) => {
  const classes = useLeadExpandBodyStyle();
  const [activateTab, setActivateTab] = useState<TabElements>('proposal');

  const handleChangeTab = (tabElement: TabElements) => {
    setActivateTab(tabElement);

    if (props?.onClearActiveTab) {
      props?.onClearActiveTab();
    }
  };

  useEffect(() => {
    if (props?.activeTab) {
      setActivateTab(props?.activeTab);
    }
  }, [props?.activeTab]);

  return (
    <>
      <div className={classes.navHeader}>
        <Grid container>
          <Grid
            onClick={() => handleChangeTab('proposal')}
            item
            sm={4}
            md={4}
            lg={4}
            className={activateTab === 'proposal' ? 'active' : ''}
          >
            <span>Proposal</span>
          </Grid>
          <Grid
            onClick={() => handleChangeTab('equipment')}
            item
            sm={4}
            md={4}
            lg={4}
            className={activateTab === 'equipment' ? 'active' : ''}
          >
            <span>Equipment</span>
          </Grid>
          <Grid
            onClick={() => handleChangeTab('labor')}
            item
            sm={4}
            md={4}
            lg={4}
            className={activateTab === 'labor' ? 'active' : ''}
          >
            <span>Labor</span>
          </Grid>
        </Grid>
      </div>

      <div>
        {activateTab === 'proposal' && <ProposalAndTermsForm toggleSaveEnabled={props?.toggleSaveEnabled} />}

        {activateTab === 'equipment' && <EquipmentForm toggleSaveEnabled={props?.toggleSaveEnabled} />}

        {activateTab === 'labor' && <LaborForm toggleSaveEnabled={props?.toggleSaveEnabled} />}
      </div>
    </>
  );
};

export { ProposalInfo };
