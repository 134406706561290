import React from 'react';
import { DataTable } from '@/components/organisms/DataTable';
import { ActionButton } from '@/components/atoms/Button/ActionButton';

const DataTablePreview = () => {
  return (
    <>
      <div style={{ textAlign: 'center', marginTop: 100 }}>
        <div style={{ width: 960, display: 'inline-block' }}>
          <DataTable
            heads={[
              { label: 'Name', align: 'center' },
              { label: 'Age', align: 'center' },
              { label: 'P.O', align: 'center' },
              { label: 'Dis', align: 'center' },
              { label: 'Address', align: 'center' },
              { label: 'Action', align: 'center' },
            ]}
            rows={[
              [
                { label: 'Nayeem', align: 'left' },
                { label: '24', align: 'center' },
                { label: 'Karikandi', align: 'center' },
                { label: 'Cumilla', align: 'center' },
                { label: 'Daudkandi', align: 'center' },
                {
                  label: (
                    <>
                      <ActionButton icon={'edit'} />
                      <ActionButton icon={'lock'} />
                      <ActionButton icon={'delete'} />
                    </>
                  ),
                  align: 'center',
                },
              ],
            ]}
            hasPagination={true}
            paginationProps={{
              count: 200,
              onChange(value) {
                alert(value);
              },
            }}
          />
        </div>
      </div>
    </>
  );
};

export default DataTablePreview;
