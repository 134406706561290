import React, { useEffect } from 'react';
import { useLeadExpandBodyStyle } from '@/static/stylesheets/organisms';
import { TextInput } from '@/components/atoms/TextInput';
import { DatePicker } from '@/components/atoms/DatePicker';
import { TimePicker } from '@/components/atoms/TimePicker';
import { Grid } from '@mui/material';
import { ToggleSaveButtonEnabled } from '@/components/molecules/Leads/SingleLead';
import { getSourceItemList } from '@/redux/actions';
import { dateWithTime } from '@/utils';
import { Controller, useFormContext } from 'react-hook-form';
import { SingleJobFormData } from '@/types/formData';
import { useJobListType } from '@/hooks/custom';
import { useAppSelector } from '@/hooks';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';

interface SourceProps {
  toggleSaveEnabled: ToggleSaveButtonEnabled;
}

const Source: React.FC<SourceProps> = (props) => {
  const classes = useLeadExpandBodyStyle();
  const { fieldsDisabled } = useJobListType();
  const sourceItemList = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_SOURCE_LIST];
  const sourceList = sourceItemList?.data?.data ?? [];

  const {
    control,
    setValue,
    formState: { errors },
    watch,
  } = useFormContext<SingleJobFormData>();

  const formValues = watch();

  const surveyDate = formValues?.survey_date;
  const surveyTimeFrom = formValues?.survey_time_from;
  const surveyTimeTo = formValues?.survey_time_to;

  const towerWorkDate = formValues?.tower_work_date;
  const towerWorkTimeFrom = formValues?.tower_work_time_from;
  const towerWorkTimeTo = formValues?.tower_work_time_to;

  const installationDate = formValues?.installation_date;
  const installationTimeFrom = formValues?.installation_time_from;
  const installationTimeTo = formValues?.installation_time_to;

  const followUpDate = formValues?.next_follow_up_date;
  const followUpTime = formValues?.next_follow_up_time;

  useEffect(() => {
    setValue('survey_datetime_from', dateWithTime(surveyDate, surveyTimeFrom));
    setValue('survey_datetime_to', dateWithTime(surveyDate, surveyTimeTo));
    setValue('tower_work_datetime_from', dateWithTime(towerWorkDate, towerWorkTimeFrom));
    setValue('tower_work_datetime_to', dateWithTime(towerWorkDate, towerWorkTimeTo));
    setValue('installation_datetime_from', dateWithTime(installationDate, installationTimeFrom));
    setValue('installation_datetime_to', dateWithTime(installationDate, installationTimeTo));
    setValue('next_follow_up_datetime', dateWithTime(followUpDate, followUpTime));
  }, [
    surveyDate,
    surveyTimeFrom,
    surveyTimeTo,
    towerWorkDate,
    towerWorkTimeFrom,
    towerWorkTimeTo,
    installationDate,
    installationTimeFrom,
    installationTimeTo,
    followUpDate,
    followUpTime,
  ]);

  useEffect(() => {
    getSourceItemList();
  }, []);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.selector}>
          <Controller
            control={control}
            name={'source_id'}
            render={({ field }) => (
              <TextInput
                select={true}
                variant={'standard'}
                fullWidth={true}
                label={field?.value ? undefined : 'Source'}
                options={[
                  {
                    title: 'Source',
                    value: '',
                    disabled: true,
                  },
                  ...(sourceList && sourceList?.length > 0
                    ? sourceList?.map((item: any) => ({
                        title: item?.source_name,
                        value: item?.id,
                      }))
                    : []),
                ]}
                {...field}
                onSelect={() => props?.toggleSaveEnabled(true)}
                error={!!errors?.source_id}
                errorText={errors?.source_id?.message}
                disabled={fieldsDisabled}
              />
            )}
          />
        </div>
        <div className={classes.dateBody}>
          <label>Survey Date & Arrival Time</label>
          <Grid container spacing={2}>
            <Grid item sm={4} md={4} lg={4}>
              <Controller
                control={control}
                name={'survey_date'}
                rules={{
                  required: surveyTimeFrom || surveyTimeTo ? 'Please input survey date!' : false,
                }}
                render={({ field }) => (
                  <DatePicker
                    value={field.value}
                    onChange={(value) => {
                      setValue('survey_date', value);
                      props?.toggleSaveEnabled(true);
                    }}
                    error={!!errors?.survey_date}
                    disabled={fieldsDisabled}
                  />
                )}
              />
            </Grid>
            <Grid item sm={4} md={4} lg={4}>
              <Controller
                control={control}
                name={'survey_time_from'}
                rules={{
                  required: surveyDate ? 'Please input survey from time!' : false,
                }}
                render={({ field }) => (
                  <TimePicker
                    placeholder={'Arrival From'}
                    value={field.value}
                    onChange={(value) => {
                      setValue('survey_time_from', value);
                      props?.toggleSaveEnabled(true);
                    }}
                    error={!!errors?.survey_time_from}
                    disabled={fieldsDisabled}
                  />
                )}
              />
            </Grid>
            <Grid item sm={4} md={4} lg={4}>
              <Controller
                control={control}
                name={'survey_time_to'}
                rules={{
                  required: surveyDate ? 'Please input survey to time!' : false,
                }}
                render={({ field }) => (
                  <TimePicker
                    placeholder={'Arrival To'}
                    value={field.value}
                    onChange={(value) => {
                      setValue('survey_time_to', value);
                      props?.toggleSaveEnabled(true);
                    }}
                    error={!!errors?.survey_time_to}
                    disabled={fieldsDisabled}
                  />
                )}
              />
            </Grid>
          </Grid>
        </div>
        <div className={classes.dateBody}>
          <label>Tower Work Install Date</label>
          <Grid container spacing={2}>
            <Grid item sm={4} md={4} lg={4}>
              <Controller
                control={control}
                name={'tower_work_date'}
                rules={{
                  required: towerWorkTimeFrom || towerWorkTimeTo ? 'Please input tower work date!' : false,
                }}
                render={({ field }) => (
                  <DatePicker
                    value={field.value}
                    onChange={(value) => {
                      setValue('tower_work_date', value);
                      props?.toggleSaveEnabled(true);
                    }}
                    error={!!errors?.tower_work_date}
                    disabled={fieldsDisabled}
                  />
                )}
              />
            </Grid>
            <Grid item sm={4} md={4} lg={4}>
              <Controller
                control={control}
                name={'tower_work_time_from'}
                rules={{
                  required: towerWorkDate ? 'Please input tower work from time!' : false,
                }}
                render={({ field }) => (
                  <TimePicker
                    placeholder={'Arrival From'}
                    value={field.value}
                    onChange={(value) => {
                      setValue('tower_work_time_from', value);
                      props?.toggleSaveEnabled(true);
                    }}
                    error={!!errors?.tower_work_time_from}
                    disabled={fieldsDisabled}
                  />
                )}
              />
            </Grid>
            <Grid item sm={4} md={4} lg={4}>
              <Controller
                control={control}
                name={'tower_work_time_to'}
                rules={{
                  required: towerWorkDate ? 'Please input tower work to time!' : false,
                }}
                render={({ field }) => (
                  <TimePicker
                    placeholder={'Arrival To'}
                    value={field.value}
                    onChange={(value) => {
                      setValue('tower_work_time_to', value);
                      props?.toggleSaveEnabled(true);
                    }}
                    error={!!errors?.tower_work_time_to}
                    disabled={fieldsDisabled}
                  />
                )}
              />
            </Grid>
          </Grid>
        </div>
        <div className={classes.dateBody}>
          <label>Install Date & Arrival Time</label>
          <Grid container spacing={2}>
            <Grid item sm={4} md={4} lg={4}>
              <Controller
                control={control}
                name={'installation_date'}
                rules={{
                  required: installationTimeFrom || installationTimeTo ? 'Please input installation date!' : false,
                }}
                render={({ field }) => (
                  <DatePicker
                    value={field.value}
                    onChange={(value) => {
                      setValue('installation_date', value);
                      props?.toggleSaveEnabled(true);
                    }}
                    error={!!errors?.installation_date}
                    disabled={fieldsDisabled}
                  />
                )}
              />
            </Grid>
            <Grid item sm={4} md={4} lg={4}>
              <Controller
                control={control}
                name={'installation_time_from'}
                rules={{
                  required: installationDate ? 'Please input installation from time!' : false,
                }}
                render={({ field }) => (
                  <TimePicker
                    placeholder={'Arrival From'}
                    value={field.value}
                    onChange={(value) => {
                      setValue('installation_time_from', value);
                      props?.toggleSaveEnabled(true);
                    }}
                    error={!!errors?.installation_time_from}
                    disabled={fieldsDisabled}
                  />
                )}
              />
            </Grid>
            <Grid item sm={4} md={4} lg={4}>
              <Controller
                control={control}
                name={'installation_time_to'}
                rules={{
                  required: installationDate ? 'Please input installation to time!' : false,
                }}
                render={({ field }) => (
                  <TimePicker
                    placeholder={'Arrival To'}
                    value={field.value}
                    onChange={(value) => {
                      setValue('installation_time_to', value);
                      props?.toggleSaveEnabled(true);
                    }}
                    error={!!errors?.installation_time_to}
                    disabled={fieldsDisabled}
                  />
                )}
              />
            </Grid>
          </Grid>
        </div>
        <div className={classes.dateBody}>
          <label>Next Follow Up</label>
          <Grid container spacing={2}>
            <Grid item sm={6} md={6} lg={6}>
              <Controller
                control={control}
                name={'next_follow_up_date'}
                rules={{
                  required: followUpTime ? 'Please input follow up date!' : false,
                }}
                render={({ field }) => (
                  <DatePicker
                    value={field.value}
                    onChange={(value) => {
                      setValue('next_follow_up_date', value);
                      props?.toggleSaveEnabled(true);
                    }}
                    error={!!errors?.next_follow_up_date}
                    disabled={fieldsDisabled}
                  />
                )}
              />
            </Grid>
            <Grid item sm={6} md={6} lg={6}>
              <Controller
                control={control}
                name={'next_follow_up_time'}
                rules={{
                  required: followUpDate ? 'Please input follow up time!' : false,
                }}
                render={({ field }) => (
                  <TimePicker
                    placeholder={'Time'}
                    value={field.value}
                    onChange={(value) => {
                      setValue('next_follow_up_time', value);
                      props?.toggleSaveEnabled(true);
                    }}
                    error={!!errors?.next_follow_up_time}
                    disabled={fieldsDisabled}
                  />
                )}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export { Source };
