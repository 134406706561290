import { colors, themeParser, useAppTheme } from '@/theme';
import { css } from '@emotion/css';

export const useReportStyles = () => {
  const theme = useAppTheme();

  return {
    containerRoot: css`
      height: 100vh;
      display: flex;
    `,

    sidebarRoot: css`
      .sidebar-container {
        position: relative;
        top: 0;
        left: 0;
        bottom: 0;
        display: flex;
        width: 300px;
        height: 100%;
        flex-direction: column;
        background: ${themeParser(colors.body.light, colors.body.dark, theme)};
        box-shadow:
          0 2px 2px 0 rgba(0, 0, 0, 0.14),
          0 3px 1px -2px rgba(0, 0, 0, 0.12),
          0 1px 5px 0 rgba(0, 0, 0, 0.2);

        @media only screen and (max-width: 1000px) {
          position: fixed;
          top: 65px;
          bottom: 0;
          left: -265px;
          width: 265px;
          z-index: 4444;
          transition: 0.3s;
          border-right: 0;
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        }

        .drawer-navigator {
          position: absolute;
          z-index: 4445;
          right: -40px;
          top: calc(50% - 20px);
          display: none;

          @media only screen and (max-width: 1000px) {
            display: block;
          }

          .toggle-button {
            width: 45px;
            height: 35px;
            background: #2196f3;
            cursor: pointer;
            color: #fff;
            border-top-left-radius: 10%;
            border-top-right-radius: 10%;
            display: none;
            justify-content: center;
            align-items: center;
            transform: rotate(90deg);
            box-shadow: rgb(0 0 0 / 15%) 0px -3px 4px 0px;
            @media (max-width: 1280px) {
              display: flex;
            }
          }
        }

        .searchBar {
          position: relative;
          display: flex;

          .input {
            width: 100%;
            height: 3rem;
            border: 0;
            border-bottom: 1px solid ${themeParser('#e0e0e0', '#3e3e3e', theme)};
            outline: none;
            padding: 0 40px 0 20px;
            font-size: 16px;
            line-height: 1.15;
            background: transparent;
            color: ${themeParser('#000000de', '#2196f3', theme)} !important;
            transition:
              box-shadow 0.3s,
              border 0.3s,
              -webkit-box-shadow 0.3s;
            box-shadow: none;

            ::placeholder {
              color: ${themeParser('#d1d1d1', '#7a7a7a', theme)} !important;
              font-weight: 500 !important;
            }

            :focus {
              border-bottom: 1px solid ${colors.primary};
            }
          }

          .searchIcon {
            position: absolute;
            top: 26%;
            right: 8px;
            display: flex;

            svg {
              font-size: 24px;
              color: #7e7e7e !important;
            }
          }
        }

        .menu-container {
          flex-grow: 1;
          overflow: auto;

          ::-webkit-scrollbar {
            width: 4px;
          }

          /* Track */

          ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px grey;
            border-radius: 6px;
          }

          /* Handle */

          ::-webkit-scrollbar-thumb {
            background: #9e9898;
          }

          ::-webkit-scrollbar-thumb:hover {
            background: #2196f3;
          }

          .menu-accordion {
            border: 0 !important;
            background: transparent;

            :not(:first-child) {
              margin: 21px 0 !important;
            }

            .menu-accordion-header {
              border-bottom: 1px solid ${themeParser('#e0e0e0', '#3e3e3e', theme)};
              font-size: 14px;
              color: ${themeParser('#000000de', '#ababab', theme)} !important;
              height: 48px;
              min-height: inherit;
              padding: 0 20px;

              svg {
                color: ${themeParser('#5a5a5a', '#ababab', theme)} !important;
              }
            }

            .menu-accordion-details {
              padding: 0 !important;

              .menu-item {
                padding: 0 23px 0 31px;
                height: 48px;
                cursor: pointer;
                font-size: 14px;
                display: flex;
                align-items: center;
                color: ${themeParser('#000000de', '#ababab', theme)} !important;

                :hover {
                  background: ${themeParser('#eeeeee', '#4a4a4a', theme)};
                }
              }

              .menu-item.active {
                font-weight: bold;
                color: ${colors.primary} !important;
              }
            }
          }
        }
      }

      .sidebar-open {
        left: 0;
      }
    `,

    contentRoot: css`
      flex-grow: 1;
      max-height: calc(100vh - 67px);
      overflow: auto;

      ::-webkit-scrollbar {
        width: 5px;
      }
      ::-webkit-scrollbar-track {
        background-color: #000000;
        -webkit-border-radius: 0px;
        border-radius: 0px;
      }
      ::-webkit-scrollbar-thumb {
        border-radius: 0px;
        background: #ddd;
      }
    `,

    contentSubRoot: css`
      padding-x: 20px;
      padding-bottom: 80px;
    `,

    filterRoot: css`
      background: ${themeParser('#f9f9f9', 'transparent', theme)} !important;
      padding: 20px;

      .report-filter-header {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        justify-content: space-between;
        align-items: center;
        padding: 0 0 10px 0;
        color: ${themeParser('#000000de', '#9f9f9f', theme)} !important;
        border-bottom: 1px solid ${themeParser('#e0e0e0', colors.primary, theme)};

        .title {
          font-size: 16px;
          font-weight: 500;
        }

        .action-buttons {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
        }
      }

      .report-filter-fields {
        display: flex;
        padding: 10px 0;
        overflow-x: auto;
        white-space: nowrap;

        ::-webkit-scrollbar {
          width: 4px;
          height: 4px;
        }

        /* Track */

        ::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px grey;
          border-radius: 6px;
        }

        /* Handle */

        ::-webkit-scrollbar-thumb:horizontal {
          background: #9e9898;
          border-radius: 10px;
        }

        ::-webkit-scrollbar-thumb {
          background: #9e9898;
        }

        ::-webkit-scrollbar-thumb:hover {
          background: #2196f3;
        }

        .report-filter-option-container {
          padding: 0 15px;
          border-right: 1px solid ${themeParser('#dddddd', '#3e3e3e', theme)} !important;

          :first-child {
            padding-left: 0;
          }

          :last-child {
            padding-right: 0;
            border-right: 0 !important;
          }

          .report-filter-option-title {
            padding: 5px;
            color: ${themeParser('#000000de', '#9f9f9f', theme)} !important;
            border-bottom: 1px solid ${themeParser('#dddddd', '#505050', theme)};
            font-weight: 500;
            font-size: 14px;
          }

          .report-filter-option-body {
            padding: 8px 0;

            .MuiTypography-root {
              font-size: 12.875px !important;
            }

            .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
              -webkit-text-fill-color: #2196f3 !important;
            }

            [type='radio']:not(:checked) + span {
              svg {
                color: #5a5a5a !important;
              }
            }

            .report-filter-option-period-to {
              color: ${themeParser('#000000de', '#ababab', theme)} !important;
            }
          }
        }

        .select-period-container {
          width: 400px;
        }

        .source-picker-container {
          width: 140px;
        }

        .type-of-work-picker-container {
          width: 150px;
        }

        .current-status-picker-container {
          width: 170px;
        }

        .assigned-to-picker-container {
          width: 200px;
        }

        .status-picker-container {
          width: 190px;
        }
      }
    `,

    contentWrapperRoot: css`
      display: block;
      border-radius: 3px;
      box-shadow:
        0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 3px 1px -2px rgba(0, 0, 0, 0.12),
        0 1px 5px 0 rgba(0, 0, 0, 0.2);
      padding: 20px;
      margin-top: 20px;

      .title-bar {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2px;
        padding-bottom: 10px;
        border-bottom: 1px solid ${themeParser('#e0e0e0', colors.primary, theme)};
        color: ${themeParser('#000000de', colors.primary, theme)} !important;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;

        &.footer-title {
          border-bottom: none;
          padding-bottom: 0;
          margin-top: 20px;
        }
      }

      .children-wrapper {
        padding-top: 10px;

        .data-toggle-view {
          text-align: right;

          span {
            color: ${colors.primary};
            text-transform: uppercase;
            font-size: 11px;
            cursor: pointer;
          }
        }
      }
    `,

    reportsTable: css`
      overflow-x: auto;

      ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }

      /* Track */

      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 6px;
      }

      /* Handle */

      ::-webkit-scrollbar-thumb:horizontal {
        background: #9e9898;
        border-radius: 10px;
      }

      ::-webkit-scrollbar-thumb {
        background: #9e9898;
      }

      ::-webkit-scrollbar-thumb:hover {
        background: #2196f3;
      }

      :not(:last-child) {
        border-bottom: 4px solid ${themeParser('#dddddd', '#3e3e3e', theme)};
      }

      &.no-border-bottom {
        border-bottom: none !important;
      }

      table {
        width: 100%;
        border-collapse: collapse;
        border-color: ${themeParser('#dddddd', '#3e3e3e', theme)};
        color: ${themeParser('#000000de', '#9f9f9f', theme)} !important;
        font-family: 'Poppins', sans-serif;
        white-space: nowrap;

        * {
          transition: 0.2s;
        }

        td,
        th {
          border-color: ${themeParser('#dddddd', '#3e3e3e', theme)};
        }

        thead {
          th {
            padding: 6px 7px;
            font-weight: 500;
          }

          tr:nth-child(even) th {
            background-color: ${themeParser('#f2f2f280', '#2a2a2a', theme)};
          }
        }

        tbody {
          tr:nth-child(odd) {
            background-color: ${themeParser('#f2f2f280', '#2a2a2a', theme)};
          }

          tr:nth-child(even) {
            background-color: ${themeParser('#ffffff', '#282828', theme)};
          }

          th {
            padding: 6px 7px;
          }
        }

        th {
          font-weight: 500;
        }

        td {
          padding: 2px 7px;
        }

        tfoot {
          td,
          th {
            padding: 6px 7px;
            font-weight: 500;
            background: ${themeParser('#f1f1f1', '#2f2f2f', theme)};
          }
        }

        tr:hover {
          td,
          th {
            background: ${themeParser('rgb(237 247 255)', 'rgb(62 62 62)', theme)};
          }
        }

        .left {
          text-align: left;
        }

        .center {
          text-align: center;
        }

        .right {
          text-align: right;
        }
      }
    `,

    indicatorCircleFilled: css`
      .wrapper {
        height: 15px;
        width: 15px;
        border: none;
        display: inline-flex;
        border-radius: 150px;
        margin: 0 5px -3px 3px;
      }

      .green {
        background: #4caf50;
      }

      .yellow {
        background: #ffc107 !important;
      }

      .red {
        background: #f44336 !important;
        box-shadow: 0 0 0 0 #ff00001a;
        transform: scale(1);
        //animation: pulse 1s infinite;
        @keyframes pulse {
          0% {
            transform: scale(0.85);
            box-shadow: 0 0 0 0 #ff00001a;
          }

          70% {
            transform: scale(1);
            box-shadow: 0 0 0 10px #ff00001a;
          }

          100% {
            transform: scale(0.85);
            box-shadow: 0 0 0 0 #ff00001a;
          }
        }
      }
    `,
  };
};
