import { css } from '@emotion/css';
import { colors, themeParser, useAppTheme } from '@/theme';

export const useSettingDialogStyle = () => {
  const theme = useAppTheme();

  return {
    root: css`
      .MuiDialog-paper {
        background: ${themeParser(colors.body.light, colors.body.dark, theme)};
        width: 900px;
        min-height: 50%;
      }

      button {
        width: 100%;
        font-weight: 500;
        color: ${themeParser(colors.body.light, colors.black.default, theme)};
        margin: 0px 14px 25px 14px;
      }

      @media (max-width: 600px) {
        .MuiGrid-grid-sm-12 {
          width: 100%;
        }

        .MuiGrid-grid-sm-11 {
          width: 90%;
        }

        .MuiGrid-grid-sm-1 {
          width: 10%;
        }
      }

      .calculator {
        label {
          color: ${themeParser('rgba(0,0,0,0.87)', colors.primary, theme)};
          font-size: 15px;
          font-family: 'Poppins';
          font-weight: 500;
        }

        input {
          color: ${themeParser('rgba(0,0,0,0.87)', colors.primary, theme)};
        }

        button {
          margin: 15px 0px;

          svg {
            color: ${themeParser('rgba(0,0,0,0.87)', colors.primary, theme)};
          }
        }

        .MuiAutocomplete-root button {
          margin: 0px 14px 25px 14px;
          width: auto;
        }

        .delete {
          font-size: 20px;
          color: #F44336;
          cursor: pointer;
        }

        .add button {
          background-color: transparent !important;
          box-shadow: none;
          color: #b5b5b5 !important;
          border: 1px solid #e0e0e0;
          font-weight: 500;
          height: 32px;
          line-height: 32px;
        }

        .MuiInput-underline:hover:before,
        .MuiInput-underline:focus:before {
          border-bottom: 1px solid !important;
          color: ${colors.primary}
        }
      }
    }
    `,
    DialogTitle: css`
      background: #2196f3;
      padding: 8px !important;
      text-transform: uppercase !important;
      font-weight: 500 !important;
      letter-spacing: 1px;
      color: ${themeParser(colors.body.light, colors.body.dark, theme)};
      font-size: 1.15rem !important;
      text-align: center;
      font-family: 'Poppins', sans-serif !important;
    `,
    DialogContent: css`
      padding: 0px 0px 0px 0px;
      .MuiFormControl-root {
        margin-top: 25px;
      }
      .searchable_input {
        overflow: hidden;
        label {
          color: ${themeParser(colors.body.dark, '#a0a0a0', theme)};
        }
        .MuiAutocomplete-paper {
          padding: 4px 10px;
          box-shadow:
            0px 5px 5px -3px rgb(0 0 0 / 20%),
            0px 8px 10px 1px rgb(0 0 0 / 14%),
            0px 3px 14px 2px rgb(0 0 0 / 12%);
          background: ${themeParser('#ffffff', '#2a2a2a', theme)};
          color: #2196f3;

          ul li {
            padding: 4px;
            cursor: pointer;
          }
        }
        .MuiFormHelperText-root.Mui-error {
          position: relative !important;
          bottom: 0px !important;
        }
      }
      .error {
        font-size: 12px;
        color: #d32f2f;
      }
      .install_search .MuiInput-root {
        color: ${themeParser(colors.body.dark, colors.primary, theme)};
      }
      .install_search .MuiInput-root:before {
        border-bottom: 1px solid ${themeParser(colors.primary, colors.primary, theme)};
      }
      .install_search .MuiInput-root button .MuiSvgIcon-fontSizeSmall {
        background: ${themeParser(colors.body.dark, colors.body.light, theme)};
      }
      .header_search_part {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 40px;
      }
      .section_part {
        border: 1px solid ${themeParser('#cfcfcf', '#433e3e', theme)};
        gap: 10px;
      }
      span,
      div {
        color: ${themeParser('#000000de', '#cbcbcb', theme)};
      }
    `,
    titleCenter: css`
      text-align: center;
      color: ${themeParser(colors.body.dark, colors.primary, theme)};
    `,
    deleteIcon: css`
      line-height: 1;
      text-align: end;
      svg {
        color: ${colors.red.default};
        cursor: pointer;
      }
    `,
    bottomIcon: css`
      justify-content: space-between !important;
      border-top: 1px solid;
      margin: 10px 25px 0px 25px !important;
      padding: 25px 0px 0px 0px !important;
      button {
        width: 120px !important;
        margin: 0px 0px 25px 0px !important;
      }
    `,
    installActions: css`
      padding: 10px 24px !important;
      button {
        margin-bottom: 0px;
        margin: 0;
      }
    `,
    pdfView: css`
      .MuiDialog-paper {
        background: transparent;
        box-shadow: none;
      }
      object,
      iframe {
        align-items: center;
        width: 1200px;
        max-width: 100%;
        height: calc(78vh - 64px);
        margin-top: 2vh;
      }
      .MuiDialogActions-root {
        justify-content: center;
      }
    `,
    createLeadsTitle: css`
      background: #2196f3;
      padding: 8px !important;
      text-transform: uppercase !important;
      font-weight: 500 !important;
      letter-spacing: 1px;
      color: ${themeParser(colors.body.light, colors.body.dark, theme)};
      font-size: 1.15rem !important;
      text-align: center;
      font-family: 'Poppins', sans-serif !important;
    `,
    createLeadsBottom: css`
      justify-content: space-between !important;
      button {
        width: auto !important;
      }
    `,

    createClientTitle: css`
      padding: 20px !important;
      text-transform: uppercase !important;
      font-weight: 500 !important;
      letter-spacing: 3px !important;
      color: ${themeParser(colors.body.dark, colors.primary, theme)};
      font-size: 1.2em !important;
      text-align: center;
      font-family: 'Poppins', sans-serif !important;
      border-bottom: 1px solid ${themeParser('#ddd', colors.primary, theme)};
    `,

    calculatorEquipmentAddButton: css`
      border: 1px solid rgb(65 65 65) !important;
      :hover {
        border: 1px solid rgb(99 99 99) !important;
      }
    `,
  };
};
