import React from 'react';
import { OpenOrdersList, CompletedOrdersList, CancelOrdersList } from '@/components/organisms/EquipmentOrders';
import { useEquipmentsStyles } from '@/static/stylesheets/molecules/EquipmentOrders';

interface EquipmentOrdersListProps {}

const EquipmentOrdersList: React.FC<EquipmentOrdersListProps> = () => {
  const classes = useEquipmentsStyles();
  return (
    <>
      <div className={classes.root}>
        <OpenOrdersList />
        <CompletedOrdersList />
        <CancelOrdersList />
      </div>
    </>
  );
};

export { EquipmentOrdersList };
