import React from 'react';
import { SettingsLayout } from '@/components/layouts';
import { SettingsCard } from '@/components/organisms/SettingsCard';
import { SettingsEquipment } from '@/components/molecules/Settings/SettingsEquipment';
import { PrivateLayout } from '@/components/Middleware';

const Equipment = () => {
  return (
    <>
      <PrivateLayout>
        <SettingsLayout hideSettingsTabPanel={true} hideHeaderRightSide={false} containerSize={'md'}>
          <SettingsCard title={'Equipment'}>
            <SettingsEquipment />
          </SettingsCard>
        </SettingsLayout>
      </PrivateLayout>
    </>
  );
};

export { Equipment };
