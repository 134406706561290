export const formatCurrency = (num: any): string => {
  if (!num) return '$-';
  const number = +num;
  if (isNaN(number)) return '$0';
  let formattedNumber = number.toFixed(2).toString().replace(/,/g, '');
  const parts = formattedNumber.split('.');
  let integerPart = parts[0];
  const decimalPart = parts[1] || '';
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  formattedNumber = `$${integerPart}`;
  if (decimalPart.length > 0) {
    formattedNumber += `.${decimalPart}`;
  }
  return formattedNumber;
};
