import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from '@/redux/store';
import { AuthService } from '@/services/auth';

const authService = new AuthService();

export interface AuthState {
  logged_in: boolean;
  token: string | null;
  userData: any | null;
  shouldReloadUserData: boolean;
}

const initialState: AuthState = {
  logged_in: authService.isLoggedIn(),
  token: authService.getToken(),
  userData: null,
  shouldReloadUserData: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    storeAuth: (state, action: PayloadAction<Partial<AuthState>>) => {
      return {
        ...state,
        ...action?.payload,
      };
    },
    clearAuth: () => {
      return {
        ...initialState,
      };
    },

    shouldReloadUserData: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        shouldReloadUserData: action?.payload,
      };
    },
  },
});

export const selectAuthState = (state: AppState) => state.auth;
export const { storeAuth, clearAuth, shouldReloadUserData } = authSlice.actions;

export default authSlice.reducer;
