import { TaskPriority } from '@/types';

export const constants = {
  resources: {},
};

export const options = [
  {
    id: 1,
    value: 'Installation Cost to High',
    label: 'Installation Cost to High',
  },
  {
    id: 2,
    value: 'Monthly Rate to High',
    label: 'Monthly Rate to High',
  },
  {
    id: 3,
    value: 'Not Fast Enough',
    label: 'Not Fast Enough',
  },
  {
    id: 4,
    value: "Doesn't See Value",
    label: "Doesn't See Value",
  },
  {
    id: 5,
    value: 'Happy with Current Provider',
    label: 'Happy with Current Provider',
  },
  {
    id: 6,
    value: 'Unreachable',
    label: 'Unreachable',
  },
  {
    id: 7,
    value: 'Chose Different Provider',
    label: 'Chose Different Provider',
  },
  {
    id: 8,
    value: 'Other',
    label: 'Other',
  },
];

export const leadFilterSortOptions = [
  {
    label: 'Farthest Along to Least Along',
    value: 11,
    column_name: 'progress_bar',
    sort_direction: 'desc',
  },
  {
    label: 'Least Along to Farthest Along',
    value: 12,
    column_name: 'progress_bar',
    sort_direction: 'asc',
  },
  {
    label: 'Name | A to Z',
    value: 13,
    column_name: 'customer',
    sort_direction: 'asc',
  },
  {
    label: 'Name | Z to A',
    value: 14,
    column_name: 'customer',
    sort_direction: 'desc',
  },
];

export const clientFilterSortOptions = [
  {
    label: 'Name | A to Z',
    value: 13,
    column_name: 'customer',
    sort_direction: 'asc',
  },
  {
    label: 'Name | Z to A',
    value: 14,
    column_name: 'customer',
    sort_direction: 'desc',
  },
];

export const jobFilterSortOptions = [
  {
    label: 'Farthest Along to Least Along',
    value: 11,
    column_name: 'progress_bar',
    sort_direction: 'desc',
  },
  {
    label: 'Least Along to Farthest Along',
    value: 12,
    column_name: 'progress_bar',
    sort_direction: 'asc',
  },
  {
    label: 'Name | A to Z',
    value: 13,
    column_name: 'customer',
    sort_direction: 'asc',
  },
  {
    label: 'Name | Z to A',
    value: 14,
    column_name: 'customer',
    sort_direction: 'desc',
  },
];

export const priorityItems: TaskPriority[] = ['No Priority', 'Low Priority', 'Medium Priority', 'High Priority'];
