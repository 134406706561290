import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useAppSelector } from '@/hooks';
import { selectUiState } from '@/redux/reducers/uiSlice';
import { timezone } from '@/utils/timeZone';
import {
  LeftHeader,
  RightHeader,
  ProposalInfo,
  Source,
  Files,
  Activity,
  QuickNotes,
  TabElements,
} from '@/components/organisms/Jobs';
import { useLeadStyles } from '@/static/stylesheets/organisms';
import { apiClient, endpoints } from '@/api';
import toast from 'react-hot-toast';
import { JobTrackerColumns } from '@/components/molecules/Jobs/JobTrackerColumns';
import { FormProvider, useForm } from 'react-hook-form';
import { SingleJobFormData } from '@/types/formData';
import { EquipmentParams, JobItem } from '@/types';
import { convertToNumber } from '@/utils';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';

interface SingleJobProps {
  optionsExpanded?: boolean;
  data?: JobItem;

  reloadList?(): void;

  onClickEdit?(data?: JobItem): void;
  isRemovedJob?: boolean;
  isCompletedJob?: boolean;
}

export type ToggleSaveButtonEnabled = (enable?: boolean) => void;

const SingleJob: React.FC<SingleJobProps> = (props) => {
  const classes = useLeadStyles();
  const { data, reloadList, onClickEdit } = props;
  const leadsExpanded = useAppSelector(selectUiState).leadsExpanded;
  const [optionsExpanded, setOptionsExpanded] = useState<boolean>(false);
  const [filesMenuOpen, setFilesMenuOpen] = useState<boolean>(false);
  const [isSaveEnabled, setIsSaveEnabled] = useState<boolean>(false);
  const [files, setFiles] = useState<any[]>([]);
  const [notes, setNotes] = useState<any[]>([]);
  const [activity, setActivity] = useState<any[]>([]);
  const [proposalInfoActivateTab, setProposalInfoActivateTab] = useState<TabElements | null>(null);

  const equipmentListResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_EQUIPMENTS];
  const equipmentItems: EquipmentParams[] = equipmentListResponse?.data?.data ?? [];

  const formMethods = useForm<SingleJobFormData>({
    mode: 'onChange',
    defaultValues: {
      assigned_to: '--empty--',
      source_id: '',
      equipments: [
        {
          equipment_id: undefined,
          quantity: undefined,
          unit_cost: undefined,
        },
      ],
      tasks: [
        {
          hours: undefined,
          task_name: undefined,
        },
      ],

      deleted_proposal_ids: [],
      deleted_task_ids: [],

      survey_date: undefined,
      survey_time_from: undefined,
      survey_time_to: undefined,
      survey_datetime_from: undefined,
      survey_datetime_to: undefined,

      tower_work_time_from: undefined,
      tower_work_time_to: undefined,
      tower_work_datetime_from: undefined,
      tower_work_datetime_to: undefined,

      installation_date: undefined,
      installation_time_from: undefined,
      installation_time_to: undefined,
      installation_datetime_from: undefined,
      installation_datetime_to: undefined,

      next_follow_up_date: undefined,
      next_follow_up_time: undefined,
      next_follow_up_datetime: undefined,

      total_equipment_cost: 0,
      total_labor_cost: 0,
    },
  });

  const { watch, setValue } = formMethods;
  const formValues = watch();

  if (optionsExpanded) {
    console.log('formValues', formValues);
  }

  const toggleSaveEnabled: ToggleSaveButtonEnabled = (toggle: boolean | undefined = undefined) => {
    setIsSaveEnabled(toggle ?? !isSaveEnabled);
  };

  const onToggleOptions = () => {
    setOptionsExpanded(!optionsExpanded);
  };
  const onToggleFilesMenu = () => {
    setFilesMenuOpen(!filesMenuOpen);
  };

  const getFiles = () => {
    if (data?.id) {
      apiClient
        .get({
          url: endpoints.private.getJobFileDetails(data?.id),
          method: 'get',
          useBearerToken: true,
        })
        .then(({ data }) => {
          if (data) {
            setFiles(() => [...data]);
          }
        })
        .catch(() => {
          alert();
        });
    }
  };
  const getNotes = () => {
    if (data?.id) {
      apiClient
        .get({
          url: endpoints.private.getJobQuickNotes(data?.id),
          method: 'get',
          useBearerToken: true,
        })
        .then(({ data }) => {
          if (data?.data) {
            setNotes(() => [...data?.data]);
          }
        })
        .catch((e) => {
          alert(e);
        });
    }
  };

  const getActivity = () => {
    if (data?.id) {
      apiClient
        .get({
          url: endpoints.private.getJobActivityLogs({
            id: data?.id,
            timezone: timezone,
            page: 1,
            limit: 30,
          }),
          method: 'get',
          useBearerToken: true,
        })
        .then(({ data }) => {
          if (data?.data) {
            setActivity(() => [...data?.data]);
          }
        })
        .catch((e) => {
          toast.error(e?.message);
        });
    }
  };

  useEffect(() => {
    if (data) {
      setValue('assigned_to', data?.assigned_to?.id ?? '');
      setValue('source_id', data?.source_id ?? '');

      // Source Form Populate
      setValue('survey_date', data?.survey_datetime_from || data?.survey_datetime_to);
      setValue('survey_time_from', data?.survey_datetime_from);
      setValue('survey_time_to', data?.survey_datetime_to);

      setValue('installation_date', data?.installation_datetime_from || data?.installation_datetime_to);
      setValue('installation_time_from', data?.installation_datetime_from);
      setValue('installation_time_to', data?.installation_datetime_to);

      setValue('tower_work_date', data?.tower_work_datetime_from || data?.tower_work_datetime_to);
      setValue('tower_work_time_from', data?.tower_work_datetime_from);
      setValue('tower_work_time_to', data?.tower_work_datetime_to);

      setValue('installation_date', data?.installation_datetime_from || data?.installation_datetime_to);
      setValue('installation_time_from', data?.installation_datetime_from);
      setValue('installation_time_to', data?.installation_datetime_to);

      setValue('next_follow_up_date', data?.next_follow_up_datetime);
      setValue('next_follow_up_time', data?.next_follow_up_datetime);

      setValue('proposals', data?.proposals?.items ?? []);
      setValue('additional_terms', data?.proposals?.additional_terms ?? '');

      setValue('equipments', data?.equipments?.items ?? []);
      setValue('equipment_price_markup', data?.equipments?.equipment_price_markup ?? '');

      setValue('tasks', data?.labour?.tasks ?? []);
      setValue('labour_price_markup', data?.labour?.labour_price_markup ?? '');

      setValue('total_equipment_cost', convertToNumber(data?.equipments?.client_cost));
      setValue('total_labor_cost', convertToNumber(data?.labour?.total_cost));
    }
  }, [data]);

  useEffect(() => {
    setOptionsExpanded(leadsExpanded);
  }, [leadsExpanded]);

  useEffect(() => {
    if (optionsExpanded && filesMenuOpen) {
      getFiles();
    }
  }, [filesMenuOpen, optionsExpanded]);

  return (
    <>
      <FormProvider {...formMethods}>
        <Grid
          container
          spacing={2}
          className={optionsExpanded ? `${classes.root} ${classes.activeBody}` : `${classes.root}`}
        >
          <Grid item sm={12} md={6} lg={7} className={classes.leftColumn}>
            <LeftHeader
              customerData={data?.customer}
              jobData={data}
              toggleSaveEnabled={toggleSaveEnabled}
              onClickEdit={() => {
                if (onClickEdit) {
                  onClickEdit(data);
                }
              }}
            />
          </Grid>
          <Grid item sm={12} md={6} lg={5} className={classes.rightColumn}>
            <RightHeader
              jobData={data}
              expandActivity={optionsExpanded}
              filesMenuOpen={filesMenuOpen}
              onToggleOptions={onToggleOptions}
              onToggleFilesMenu={onToggleFilesMenu}
              isSaveEnabled={isSaveEnabled}
              expandForm={(expand) => setOptionsExpanded(expand)}
              setProposalTab={(tabName) => setProposalInfoActivateTab(tabName)}
              reloadActivityLogList={getActivity}
            />
          </Grid>

          <Grid className={classes.trackingContainer} item sm={12} md={12} lg={12}>
            <Grid container spacing={2}>
              <Grid item sm={12} md={12} lg={12}>
                <JobTrackerColumns
                  jobProgressItems={data?.progress_steps}
                  jobId={data?.id}
                  jobData={data}
                  reloadList={reloadList}
                  reloadActivityLogList={getActivity}
                />
              </Grid>
            </Grid>
            <div className={optionsExpanded ? `${classes.show}` : `${classes.hide}`}>
              {optionsExpanded && !filesMenuOpen && (
                <Grid container spacing={2} className={classes.expandContainer}>
                  <Grid item sm={6} md={3} lg={3}>
                    <Source toggleSaveEnabled={toggleSaveEnabled} />
                  </Grid>
                  <Grid item sm={6} md={3} lg={3}>
                    <ProposalInfo
                      toggleSaveEnabled={toggleSaveEnabled}
                      jobData={data}
                      activeTab={proposalInfoActivateTab}
                      onClearActiveTab={() => setProposalInfoActivateTab(null)}
                    />
                  </Grid>
                  <Grid item sm={6} md={3} lg={3}>
                    {optionsExpanded && (
                      <QuickNotes
                        toggleSaveEnabled={toggleSaveEnabled}
                        jobNotes={notes}
                        jobId={data?.id}
                        onReload={getNotes}
                      />
                    )}
                  </Grid>
                  <Grid item sm={6} md={3} lg={3}>
                    {optionsExpanded && (
                      <Activity leadsActivity={activity} leadData={data?.id} onReload={getActivity} />
                    )}
                  </Grid>
                </Grid>
              )}

              {optionsExpanded && filesMenuOpen && <Files jobFiles={files} jobId={data?.id} onReload={getFiles} />}
            </div>
          </Grid>
        </Grid>
      </FormProvider>
    </>
  );
};

export { SingleJob };
