import { AppTheme, colors, themeParser, useAppTheme } from '@/theme';
import { Global, css } from '@emotion/react';
const styles = (appTheme: AppTheme) => css`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    line-height: 1.5;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: ${themeParser(colors.body.light, colors.body.dark, appTheme)};
    .datePicker .MuiDialogActions-root {
      position: absolute;
      right: 0px;
      bottom: 0px;
      background: #fff;
    }
    .datePicker .MuiPickersToolbar-penIconButton,
    .timePicker .MuiPickersToolbar-penIconButton {
      display: none;
    }
    .datePicker .MuiDialog-paper {
      outline: 0;
      height: 400px !important;
    }

    .MuiTimePickerToolbar-hourMinuteLabel .MuiTypography-h3 {
      color: #ffffff !important;
    }

    .MuiTimePickerToolbar-hourMinuteLabel .Mui-selected {
      text-shadow: 3px 2px 0px #333333 !important;
    }

    .datePicker .MuiPickersToolbar-root {
      height: 380px;
      padding: 10px;
    }
    .datePicker .MuiPickersToolbar-root .MuiPickersToolbar-content {
      display: block;
    }
    .datePicker .MuiPickersToolbar-root .MuiPickersToolbar-content h4 {
      text-align: left;
    }

    .MuiPickersToolbar-root.MuiDatePickerToolbar-root {
      max-width: 200px !important;
    }

    .MuiTimePickerToolbar-root {
      height: 320px;
      width: 160px;
    }

    .MuiTimePickerToolbar-root .MuiGrid-container.MuiPickersToolbar-content {
      justify-content: center;
    }

    .MuiTimePickerToolbar-root .MuiTimePickerToolbar-ampmLabel {
      color: #ffffff !important;
    }

    .MuiTimePickerToolbar-root .MuiTimePickerToolbar-ampmLabel.Mui-selected {
      text-shadow: 2px 2px 0px #333333 !important;
    }

    .PrivatePickersToolbarText-root.Mui-selected {
      color: #fff !important;
    }

    .MuiPickersToolbar-root button span,
    .MuiTypography-h3.MuiTimePickerToolbar-separator {
      color: rgba(255, 255, 255, 0.6) !important;
    }

    .timePicker .MuiDialogActions-root {
      position: absolute;
      right: 0px;
      bottom: 0px;
      background: #fff;
    }

    .timePicker .MuiPickersToolbar-root .MuiTypography-overline,
    .datePicker .MuiPickersToolbar-root .MuiTypography-overline {
      color: #fff;
      font-size: 13px;
    }

    @media (max-width: 480px) {
      .datePicker .MuiPickersToolbar-root,
      .timePicker .MuiPickersToolbar-root {
        display: none;
      }
      .datePicker .MuiDialogActions-root,
      .timePicker .MuiDialogActions-root {
        position: relative;
      }
    }
    .icon__menu .MuiList-root {
      display: block;
      min-width: 250px;
      max-width: 300px;
      padding: 0px;
    }
    .left_align .MuiMenuItem-root {
      justify-content: left !important;
    }
    .icon__menu .MuiMenuItem-root {
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 40px;
      font-family: 'Poppins', sans-serif;
      justify-content: center;
    }
    .icon__menu .paperTitle {
      border-bottom: 1px solid;
      display: block;
      font-size: 16px;
      font-weight: 600;
      color: ${themeParser(colors.body.dark, colors.primary, appTheme)};
    }
    .icon__menu .paperSubTitle {
      text-align: center;
      padding: 0px 5px;
    }
    .icon__menu .MuiMenuItem-root svg {
      height: inherit;
      line-height: inherit;
      float: left;
      margin: 0 24px 0 0;
      width: 24px;
    }
    .icon__menu .MuiPaper-root:before {
      display: none;
    }
  }
  .MuiTooltip-tooltip {
    font-size: 14px !important;
  }
  code {
    font-family: 'Poppins', sans-serif;
  }
  .MuiPickersToolbar-root {
    background: ${colors.primary};
    color: #fff;
    align-items: baseline !important;
    button {
      span {
        color: #fff;
      }
    }
  }
  .MuiPopover-root .MuiPaper-root {
    background: ${themeParser(colors.body.light, colors.body.dark, appTheme)};
    color: ${colors.primary};
  }
  @media only screen and (min-width: 1200px) {
    body,
    html {
      font-size: 15px;
    }
  }
  @media only screen and (max-width: 1199px) {
    body,
    html {
      font-size: 14px;
    }
  }
  .MuiButton-sizeSmall {
    height: 32.4px !important;
    line-height: 32.4px !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    letter-spacing: 0.5px !important;
    border-radius: 2px !important;
  }
  .MuiButton-containedSuccess,
  .MuiButton-containedSuccess:hover {
    background-color: #4caf50 !important;
  }
  .MuiButton-containedPrimary,
  .MuiButton-containedPrimary:hover {
    background-color: #2196f3 !important;
  }
  .MuiButton-containedWarning,
  MuiButton-containedWarning:hover {
    background-color: #fbc02d !important;
  }
  .MuiInput-root:mui-focused::after {
    border-bottom: 1px solid !important;
  }
  .MuiInput-root:after {
    border-bottom: 1px solid !important;
  }
  .MuiInput-root.Mui-error:after {
    border-bottom-color: #d32f2f !important;
  }
  .MuiButton-containedError,
  .MuiButton-containedError:hover {
    background-color: #f44336 !important;
  }
  .MuiFormHelperText-root.Mui-error {
    position: absolute;
    bottom: -20px;
    font-family: 'Poppins', sans-serif !important;
  }
  .MuiTypography-root,
  .MuiInputLabel-root,
  .MuiInput-input,
  .MuiFormLabel-root {
    font-family: 'Poppins', sans-serif !important;
  }
  .indicatorBox {
    display: flex;
    align-items: center;
  }
  .indicatorBox::after {
    height: 15px;
    content: '';
    width: 15px;
    border: none;
    display: inline-flex;
    border-radius: 150px;
    margin: 0 5px -3px 10px;
    padding-right: 0;
  }
  .green::after {
    background: #4caf50 !important;
  }
  .yellow::after {
    background: #ffc107 !important;
  }
  .red::after {
    background: #f44336 !important;
    animation: pulse 1s infinite;
    @keyframes pulse {
      0% {
        transform: scale(0.85);
        box-shadow: 0 0 0 0 #ff00001a;
      }

      70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px #ff00001a;
      }

      100% {
        transform: scale(0.85);
        box-shadow: 0 0 0 0 #ff00001a;
      }
    }
  }
  .MuiRadio-root {
    color: #9e9e9e !important;
  }
  .MuiRadio-root.Mui-checked {
    color: #1976d2 !important;
  }
  .MuiCheckbox-root.MuiButtonBase-root {
    color: ${themeParser(colors.textInput.labelColor.light, colors.textInput.labelColor.dark, appTheme)};
  }

  .MuiAutocomplete-listbox {
    ::-webkit-scrollbar {
      width: 4px;
    }

    /* Track */

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 6px;
    }

    /* Handle */

    ::-webkit-scrollbar-thumb {
      background: #9e9898;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #2196f3;
    }
  }
`;

export const GlobalStyles = () => {
  const appTheme = useAppTheme();

  return <Global styles={styles(appTheme)} />;
};
