import { convertToString } from '@/utils/convertToString';

export function convertEmptyStringToNull(string: any) {
  const parsedString = convertToString(string).trim();
  if (parsedString === '') {
    return null;
  }

  return parsedString;
}
