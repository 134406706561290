import React from 'react';
import { useTaskStyles } from '@/static/stylesheets/molecules/Tasks';

interface TaskGridNoDataProps {
  title?: string;
}

const TaskGridNoData: React.FC<TaskGridNoDataProps> = (props) => {
  const classes = useTaskStyles();

  return (
    <>
      <tr>
        <td colSpan={9}>
          <div className={classes.tasksNoData}>{props?.title ?? 'NO DATA FOUND'}</div>
        </td>
      </tr>
    </>
  );
};

export { TaskGridNoData };
