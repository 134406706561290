import React, { useEffect, useState } from 'react';
import { useLeadStyles } from '@/static/stylesheets/organisms/Leads/leadStyles';
import { Button } from '@/components/atoms/Button';
// import { ContractModal } from '@/components/organisms/Leads/ContractModal';
import { EmailTemplatesForm } from '@/components/molecules/Settings/Email/EmailTemplates';
import { RemoveAction } from '@/components/organisms/Jobs/Expand/modal/RemoveAction';
import { MenuDialog } from '@/components/atoms/Popover/MenuDialog';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import BlockIcon from '@mui/icons-material/Block';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import VoicemailIcon from '@mui/icons-material/Voicemail';
import MouseIcon from '@mui/icons-material/Mouse';
import { timezone } from '@/utils/timeZone';
import {
  restoreLostLead,
  clearRestoreLostLead,
  clearLeadSendContractResponse,
  clearUpdatedCustomerLead,
  clearLeadUpdateCustomerDetails,
  updateJobVMCount,
  clearJobUpdatedVMCount,
  getJobs,
  updateJobStatus,
  updateJobDetails,
  restoreJob,
} from '@/redux/actions';
import { useAppSelector } from '@/hooks';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import toast from 'react-hot-toast';
import { alertMessages } from '@/config';
import { useFormContext } from 'react-hook-form';
import { SingleJobFormData } from '@/types/formData';
import { convertEmptyStringToNull, prepareArrayPropertyForFormData } from '@/utils';
import { TabElements } from '@/components/organisms/Jobs/Expand/ProposalInfo';
import { useJobListType } from '@/hooks/custom';

interface HeaderActionProps {
  jobData?: any;

  onToggleOptions?(): void;

  onToggleFilesMenu?(): void;

  expandActivity?: boolean;
  filesMenuOpen?: boolean;
  isSaveEnabled?: boolean;
  expandForm?(expand: boolean): void;
  setProposalTab?(tabName: TabElements): void;
  reloadActivityLogList?(): void;
}

const RightHeader: React.FC<HeaderActionProps> = (props) => {
  const classes = useLeadStyles();
  const { currentSelected, fieldsDisabled } = useJobListType();
  const updateVMCountResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.UPDATE_JOB_VM_COUNT];
  const updateJobStatusResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.UPDATE_JOB_STATUS];
  const updateCustomerDetailsResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.UPDATE_LEAD_CUSTOMER_DETAILS];
  const sendsContractResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.SEND_LEAD_CONTRACT];
  const [targetId, setTargetId] = useState(null);
  const { onToggleOptions, onToggleFilesMenu, expandActivity, filesMenuOpen } = props;

  const actionsDisabled = fieldsDisabled;
  const [openDialog, setOpenDialog] = useState(false);
  const [openModal, setOpen] = useState(false);
  const [openEmailDialog, setOpenEmailDialog] = useState(false);
  const [isPDFData, setIsPDFData] = useState(null);
  const [modalValue, setModalValue] = useState('');

  const { trigger, watch } = useFormContext<SingleJobFormData>();

  const formValues = watch();

  const handleOpenModal = (e: any) => {
    const value = e.target.id;
    if (value !== 'VM' && value !== 'duplicate' && value) {
      setOpen(true);
    }
    if (value === 'VM') {
      setTargetId(props.jobData?.id);
      updateJobVMCount(props.jobData?.id);
    }
    setModalValue(value);

    if (value === 'duplicate') {
      const data: any = {
        status: 'removed',
        removed_reason: 'duplicate',
        removed_reason_details: 'duplicate',
        time_zone: timezone,
      };
      setTargetId(props.jobData?.id);
      updateJobStatus(props.jobData?.id, data);
    }
  };

  const handleCloseModal = () => setOpen(false);

  const handleDialog = (data: any) => {
    setOpenDialog(true);
    setIsPDFData(data);
    setTargetId(data?.id);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
    setIsPDFData(null);
  };
  const handleEmailDialog = () => {
    setOpenEmailDialog(true);
  };
  const handleEmailDialogClose = () => {
    setOpenEmailDialog(false);
  };

  const handleRestoreJob = () => {
    setTargetId(props.jobData?.id);
    restoreJob(props.jobData?.id);
  };

  const expandAndOpenTabForm = (tabName: TabElements) => {
    if (props?.expandForm) {
      props?.expandForm(true);

      setTimeout(() => {
        if (props?.setProposalTab) {
          props?.setProposalTab(tabName);

          setTimeout(async () => {
            if (tabName === 'proposal') {
              await trigger(['proposals', 'additional_terms']);
            }

            if (tabName === 'equipment') {
              await trigger(['equipment_price_markup', 'equipments']);
            }

            if (tabName === 'labor') {
              await trigger(['labour_price_markup', 'tasks']);
            }
          }, 100);
        }
      }, 100);
    }
  };

  const onSubmit = async () => {
    const formIsValid = await trigger();

    if (formIsValid) {
      if (formValues?.proposals && formValues?.proposals?.length > 0) {
        let proposalFormIsValid = true;
        formValues?.proposals?.forEach((item) => {
          if (
            !item?.description ||
            item?.description?.trim() === '' ||
            isNaN(Number(item?.price)) ||
            Number(item?.price) <= 0
          ) {
            proposalFormIsValid = false;
          }
        });

        if (!proposalFormIsValid) {
          expandAndOpenTabForm('proposal');
          toast.error('Please check the required fields in the proposal tab!');
          return;
        }
      }

      if (isNaN(Number(formValues?.equipment_price_markup)) || Number(formValues?.equipment_price_markup) <= 0) {
        expandAndOpenTabForm('equipment');
        toast.error('Please check the required fields in the equipment tab!');
        return;
      }

      if (formValues?.equipments && formValues?.equipments?.length > 0) {
        let equipmentsFormIsValid = true;
        formValues?.equipments?.forEach((item) => {
          if (
            !item?.equipment_id ||
            item?.equipment_id?.trim() === '' ||
            isNaN(Number(item?.quantity)) ||
            Number(item?.quantity) <= 0 ||
            isNaN(Number(item?.unit_cost)) ||
            Number(item?.unit_cost) <= 0
          ) {
            alert('Error');
            equipmentsFormIsValid = false;
          }
        });

        if (!equipmentsFormIsValid) {
          expandAndOpenTabForm('equipment');
          toast.error('Please check the required fields in the equipment tab!');
          return;
        }
      }

      if (isNaN(Number(formValues?.labour_price_markup)) || Number(formValues?.labour_price_markup) <= 0) {
        toast.error('Please check the required fields in labor tab!');
        expandAndOpenTabForm('labor');
        return;
      }

      if (formValues?.tasks && formValues?.tasks?.length > 0) {
        let taskFormIsValid = true;
        formValues?.tasks?.forEach((item) => {
          if (!item?.task_name || item?.task_name?.trim() === '') {
            taskFormIsValid = false;
          }
        });

        if (!taskFormIsValid) {
          expandAndOpenTabForm('labor');
          toast.error('Please check the task required fields in the labor tab!');
          return;
        }
      }

      const data: SingleJobFormData = {
        assigned_to: formValues?.assigned_to === '' ? undefined : formValues?.assigned_to,
        source_id: formValues?.source_id === '' ? undefined : formValues?.source_id,
        survey_datetime_from: convertEmptyStringToNull(formValues?.survey_datetime_from) ?? undefined,
        survey_datetime_to: convertEmptyStringToNull(formValues?.survey_datetime_to) ?? undefined,
        installation_datetime_from: convertEmptyStringToNull(formValues?.installation_datetime_from) ?? undefined,
        installation_datetime_to: convertEmptyStringToNull(formValues?.installation_datetime_to) ?? undefined,
        tower_work_datetime_from: convertEmptyStringToNull(formValues?.tower_work_datetime_from) ?? undefined,
        tower_work_datetime_to: convertEmptyStringToNull(formValues?.tower_work_datetime_to) ?? undefined,
        next_follow_up_datetime: convertEmptyStringToNull(formValues?.next_follow_up_datetime) ?? undefined,

        tasks: formValues?.tasks,
        equipments: prepareArrayPropertyForFormData(formValues?.equipments),
        proposals: prepareArrayPropertyForFormData(formValues?.proposals),
        deleted_proposal_ids: prepareArrayPropertyForFormData(formValues?.deleted_proposal_ids),
        deleted_task_ids: prepareArrayPropertyForFormData(formValues?.deleted_task_ids),

        additional_terms: formValues?.additional_terms === '' ? undefined : formValues?.additional_terms,
        equipment_price_markup: formValues?.equipment_price_markup,
        labour_price_markup: formValues?.labour_price_markup,

        total_hours: formValues?.total_hours,
        timezone: timezone,
      };

      setTargetId(props.jobData?.id);
      if (props.jobData?.id) {
        updateJobDetails(props.jobData?.id, data);
      }
    } else {
      toast.error('Please fill all the required fields!');
    }
  };

  const getJobList = () =>
    getJobs({
      limit: 10,
      page: 1,
      type: currentSelected,
    });

  useEffect(() => {
    if (updateVMCountResponse?.success && targetId === props.jobData?.id) {
      toast.success(alertMessages.jobs.vmCountUpdateSuccess);
      setTargetId(null);
      clearJobUpdatedVMCount();
      if (updateVMCountResponse?.data?.shouldReload) {
        getJobList();
      }

      if (props?.reloadActivityLogList) {
        props?.reloadActivityLogList();
      }
    }

    if (updateVMCountResponse?.error && targetId === props.jobData?.id) {
      setTargetId(null);
      clearJobUpdatedVMCount();
      toast.error(alertMessages.leads.vmCountUpdateError);
    }
  }, [updateVMCountResponse, targetId]);

  useEffect(() => {
    if (updateJobStatusResponse?.success && targetId === props.jobData?.id) {
      toast.success(alertMessages.jobs.jobUpdateSuccess);
      setTargetId(null);
      setOpen(false);
      clearUpdatedCustomerLead();
      if (updateJobStatusResponse?.data?.shouldReload) {
        getJobList();
      }
    }

    if (updateJobStatusResponse?.error && targetId === props.jobData?.id) {
      toast.error(alertMessages.jobs.jobUpdateError);
      clearUpdatedCustomerLead();
    }
  }, [targetId, updateJobStatusResponse]);

  useEffect(() => {
    if (updateCustomerDetailsResponse?.success && targetId === props.jobData?.id) {
      toast.success(alertMessages.leads.customerDetailsUpdateSuccess);
      setTargetId(null);
      clearLeadUpdateCustomerDetails();
    }

    if (updateCustomerDetailsResponse?.error && targetId === props.jobData?.id) {
      toast.error(updateCustomerDetailsResponse?.error?.data?.message);
      clearLeadUpdateCustomerDetails();
    }
  }, [targetId, updateCustomerDetailsResponse]);

  useEffect(() => {
    if (isPDFData) {
      if (sendsContractResponse?.success && targetId === props.jobData?.id) {
        // toast.success("Document sent to DocuSign Successfully");
        setTargetId(null);
        clearLeadSendContractResponse();
        setOpenDialog(false);
      }
      if (sendsContractResponse?.error && targetId === props.jobData?.id) {
        toast.error(alertMessages.leads.contractSendError);
        setTargetId(null);
        clearLeadSendContractResponse();
      }
    }
  }, [sendsContractResponse, targetId]);

  const menuRemove = [
    {
      title: 'Lost',
      icon: <BlockIcon />,
      action: 'lost',
    },
    {
      title: 'Duplicate',
      icon: <ControlPointDuplicateIcon />,
      action: 'duplicate',
    },
    {
      title: 'Other',
      icon: <MoreHorizIcon />,
      action: 'other',
    },
  ];
  const menuVM = [
    {
      title: 'Misclick',
      icon: <MouseIcon />,
    },
    {
      title: 'Yes, I just left a VM',
      icon: <VoicemailIcon />,
      action: 'VM',
    },
  ];

  const openInNewTabUCRM = (url: any) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <>
      <div className={classes.rightDetails}>
        <Button
          color={'success'}
          title={'SAVE'}
          onClick={onSubmit}
          disabled={!props?.isSaveEnabled || actionsDisabled}
          loading={updateCustomerDetailsResponse?.loading}
        />
        <span className={classes.vertLine}>|</span>
        <Button onClick={onToggleOptions} title={expandActivity ? 'Collapse' : 'Expand'} />
        <Button onClick={onToggleFilesMenu} title={filesMenuOpen ? 'Info' : 'Files'} />
        <Button disabled={actionsDisabled} onClick={() => openInNewTabUCRM(props.jobData?.ucrm_url)} title={'UCRM'} />
        <MenuDialog
          disabled={actionsDisabled}
          onClick={handleOpenModal}
          title={'VM'}
          menuList={menuVM}
          leftAlign={'left_align'}
        />
        <Button disabled={actionsDisabled} onClick={() => handleEmailDialog()} title={'Email'} />
        {/*<Button disabled={actionsDisabled} onClick={() => handleDialog(props.jobData)} title={"Contract"} />*/}
        <div className={'last__button'}>
          {actionsDisabled ? (
            <Button onClick={handleRestoreJob} title={'Restore'} disabled={updateJobStatusResponse?.loading} />
          ) : (
            <MenuDialog
              onClick={handleOpenModal}
              title={'Remove'}
              leftAlign={'left_align'}
              menuList={menuRemove}
              menuHeader={'Reason for Removing?'}
            />
          )}
        </div>

        {/* <ContractModal
          open={openDialog}
          onClose={handleDialogClose}
          leadData={isPDFData}
        />*/}

        <EmailTemplatesForm
          open={openEmailDialog}
          onClose={handleEmailDialogClose}
          isLeadEmail={true}
          leadData={props?.jobData}
          featureType={'job'}
        />
        <RemoveAction
          openModal={openModal}
          modalValue={modalValue}
          onClose={handleCloseModal}
          jobData={props.jobData}
        />
      </div>
    </>
  );
};

export { RightHeader };
