import React from 'react';
import { Provider } from 'react-redux';
import { store } from '@/redux/store';
import { ReduxService } from '@/components/layouts';
import { Routes } from '@/routes';
import { GlobalStyles } from '@/static/stylesheets/GlobalStyles';
import { ThemeProvider } from '@/theme';
import { Toaster } from 'react-hot-toast';
import { ConfirmationDialog } from '@/components/organisms/ConfirmationDialog';
import '@/static/css/fonts.css';

interface AppProps {}

const App: React.FC<AppProps> = () => (
  <>
    <Provider store={store}>
      <ThemeProvider>
        <ReduxService>
          <GlobalStyles />
          <Toaster
            position='top-right'
            reverseOrder={true}
            toastOptions={{
              error: {
                duration: 10000,
              },
            }}
          />
          <Routes />
          <ConfirmationDialog />
        </ReduxService>
      </ThemeProvider>
    </Provider>
  </>
);

export default App;
