import { css } from '@emotion/css';
import { colors, themeParser, useAppTheme } from '@/theme';

export const useTextInputStyles = () => {
  const theme = useAppTheme();

  return {
    root: css`
      label {
        color: ${themeParser(colors.textInput.labelColor.light, colors.textInput.labelColor.dark, theme)};
        font-size: 14px;
      }

      .MuiInput-underline:before {
        border-color: ${themeParser(
          colors.textInput.borderColor.light,
          colors.textInput.borderColor.dark,
          theme,
        )} !important;
      }
      .MuiInput-underline:hover:before {
        border-bottom: 1px solid !important;
      }
      .MuiInput-underline:after {
        border-bottom: 2px solid ${colors.primary} !important;
      }
      input::placeholder {
        color: ${themeParser(
          colors.textInput.placeholderColor.light,
          colors.textInput.placeholderColor.dark,
          theme,
        )} !important;
      }
      textarea::placeholder {
        color: ${themeParser(
          colors.textInput.placeholderColor.light,
          colors.textInput.placeholderColor.dark,
          theme,
        )} !important;
      }
    `,

    textInput: css`
      color: ${colors.primary} !important;
    `,

    centerMode: css`
      text-align: center !important;
    `,

    errorMode: css`
      color: ${colors.textInput.error} !important;
    `,

    selectRoot: css`
      left: 0;

      color: ${themeParser('auto', colors.primary, theme)} !important;
    `,
  };
};
