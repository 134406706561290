import { Button } from '@/components/atoms/Button';
import { MenuDialog } from '@/components/atoms/Popover';
import { TextInput } from '@/components/atoms/TextInput';
import { TaskViewDialog } from '@/components/molecules/Tasks';
import { alertMessages, priorityItems } from '@/config';
import { useAppSelector, useComponentState, useInput } from '@/hooks';
import {
  changeTaskAssignee,
  changeTaskPriority,
  deleteTask,
  markTaskAsCompleted,
  openConfirmationDialog,
  startStopTaskTimer,
} from '@/redux/actions';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { useTaskStyles } from '@/static/stylesheets/molecules/Tasks';
import { TaskItem, TaskPriorityIndex, UserParams } from '@/types';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import CheckIcon from '@mui/icons-material/Check';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import FlagIcon from '@mui/icons-material/Flag';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { default as classNames, default as classnames } from 'classnames';
import React, { useEffect } from 'react';

interface TasksGridItemProps {
  parentTaskId?: string;
  isSubTask?: boolean;
  data?: TaskItem;

  onClickUnfold?(): void;
  reloadList?(): void;
}

const TasksGridItem: React.FC<TasksGridItemProps> = (props) => {
  const classes = useTaskStyles();
  const { data: taskData, parentTaskId, isSubTask } = props;

  const staffMemberList = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_TASK_STAFF_MEMBERS];
  const staffMembers: UserParams[] = staffMemberList?.data?.data ?? [];

  const {
    state: { taskViewDialogOpen, selectedTaskId },
    setState,
  } = useComponentState<{
    taskViewDialogOpen: boolean;
    selectedTaskId: TaskItem['id'] | null;
  }>({
    taskViewDialogOpen: false,
    selectedTaskId: null,
  });

  const { setValue: setAssignedTo, bind: bindAssignedTo } = useInput('--empty--', {
    onChange(value: any) {
      if (taskData?.id && value !== '--empty--') {
        changeTaskAssignee(taskData?.id, value);
      }
    },
  });

  const toggleTaskTimer = () => {
    if (taskData?.id) {
      startStopTaskTimer(taskData?.id);
    }
  };

  const changePriority = () => {
    if (taskData && taskData?.priority) {
      const currentPriorityIndex = priorityItems.indexOf(taskData?.priority);
      let nextPriorityIndex: TaskPriorityIndex = '0';

      switch (currentPriorityIndex) {
        case 0:
          nextPriorityIndex = '1';
          break;
        case 1:
          nextPriorityIndex = '2';
          break;
        case 2:
          nextPriorityIndex = '3';
          break;
        case 3:
          nextPriorityIndex = '0';
          break;
      }

      changeTaskPriority(taskData?.id, nextPriorityIndex);
    }
  };

  const markTaskItemAsCompleted = () => {
    if (taskData?.id) {
      markTaskAsCompleted(taskData?.id);
    }
  };

  const deleteTaskItem = () => {
    if (taskData?.id) {
      openConfirmationDialog({
        title: 'Delete',
        message: alertMessages.tasks.taskDeleteConfirmation,
        type: 'error',
        textCenter: 'center',
        onClickConfirm() {
          deleteTask(taskData?.id);
        },
      });
    }
  };

  useEffect(() => {
    if (taskData?.assigned_to) {
      setAssignedTo(taskData?.assigned_to.id);
    }
  }, [taskData?.assigned_to]);

  return (
    <>
      <tr>
        <td className={'center'}>
          <span className={classes.indicatorCircleFilled}>
            <span
              className={classnames({
                wrapper: true,
                small: isSubTask,
                green: taskData?.task_status === 'On Track',
                yellow: taskData?.task_status === 'Need Attention',
                red: taskData?.task_status === 'Help Needed',
              })}
            />
          </span>
        </td>
        <td>
          <span
            onClick={() =>
              setState({
                taskViewDialogOpen: true,
                selectedTaskId: isSubTask ? parentTaskId : taskData?.id,
              })
            }
            className='task-title'
          >
            {taskData?.title}
          </span>
        </td>
        <td className={'center'}>
          <span className={'priority-button'} onClick={changePriority}>
            <FlagIcon
              className={classNames({
                'no-priority': taskData?.priority === 'No Priority',
                'low-priority': taskData?.priority === 'Low Priority',
                'medium-priority': taskData?.priority === 'Medium Priority',
                'high-priority': taskData?.priority === 'High Priority',
              })}
            />
          </span>
        </td>
        <td className={'center'}>
          {taskData?.task_types ? taskData?.task_types[0]?.task_type_name : ''}
          {taskData?.task_types && taskData?.task_types?.length > 1 ? '...' : ''}
        </td>
        <td className={'center'}>{taskData?.customer?.customer_name}</td>
        <td className={'center'}>{taskData?.days_active}</td>
        <td className={'center'}>
          <span
            className={classNames({
              'no-priority-text-only': taskData?.due_datetime?.priority === 'No Priority',
              'low-priority-text-only': taskData?.due_datetime?.priority === 'Low Priority',
              'medium-priority-text-only': taskData?.due_datetime?.priority === 'Medium Priority',
              'high-priority': taskData?.due_datetime?.priority === 'High Priority',
            })}
          >
            {taskData?.due_datetime?.date}
          </span>
        </td>
        <td className={'left'}>
          <TextInput
            select={true}
            variant={'standard'}
            options={[
              {
                title: 'Assigned To',
                value: '--empty--',
                disabled: true,
              },
              ...(staffMembers && staffMembers?.length > 0
                ? staffMembers?.map((item) => ({
                    title: `${item?.first_name ?? ''} ${item?.last_name ?? ''}`,
                    value: item?.id,
                  }))
                : []),
            ]}
            fullWidth={true}
            {...bindAssignedTo}
          />
        </td>
        <td className={'right'}>
          <div className={'action-buttons'}>
            <span className={taskData?.is_running ? 'error_watch ' : ''}>
              <Button
                title={<AccessAlarmIcon />}
                size={'small'}
                tooltipText={taskData?.is_running ? 'Stop timer' : 'Start timer'}
                onClick={toggleTaskTimer}
                color={taskData?.is_running ? 'error' : 'primary'}
                disabled={taskData?.is_complete}
                sx={{
                  background: taskData?.is_complete ? '#d9d9d9 !important' : 'auto',
                }}
              />
            </span>
            <Button
              title={<VisibilityIcon />}
              size={'small'}
              tooltipText={'Preview'}
              onClick={() =>
                setState({
                  taskViewDialogOpen: true,
                  selectedTaskId: isSubTask ? parentTaskId : taskData?.id,
                })
              }
            />
            {!isSubTask && (
              <Button
                title={<UnfoldMoreIcon />}
                size={'small'}
                tooltipText={'Show Subtasks'}
                onClick={props?.onClickUnfold}
              />
            )}
            <MenuDialog
              onClick={(e: any) => {
                switch (e?.target?.id) {
                  case 'Delete':
                    deleteTaskItem();
                    break;
                }
              }}
              tooltipText={'Delete Task'}
              title={<RemoveCircleOutlineIcon />}
              menuList={[
                {
                  title: 'Never Mind',
                  icon: null,
                  action: 'Cancel',
                },
                {
                  title: 'Yes, Delete Task',
                  icon: null,
                  action: 'Delete',
                },
              ]}
              menuHeader={'Remove Task'}
              buttonProps={{
                size: 'small',
                color: 'error',
              }}
            />
            <Button
              title={taskData?.is_complete ? <DoneAllIcon /> : <CheckIcon />}
              size={'small'}
              tooltipText={'Mark as completed'}
              color={taskData?.is_complete ? 'success' : 'primary'}
              onClick={markTaskItemAsCompleted}
            />
          </div>
        </td>
      </tr>

      <TaskViewDialog
        open={taskViewDialogOpen}
        onClose={(options) => {
          setState({
            taskViewDialogOpen: false,
            selectedTaskId: null,
          });

          if (options?.reloadList && props?.reloadList) {
            props?.reloadList();
          }
        }}
        taskId={selectedTaskId}
      />
    </>
  );
};

export { TasksGridItem };
