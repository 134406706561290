import { apiClient, endpoints } from '@/api';
import { Button } from '@/components/atoms/Button';
import { MultiSelect } from '@/components/atoms/MultiSelect';
import { SearchableTextInput, TextInput } from '@/components/atoms/TextInput';
import { EditorInstance, RenderRichTextEditorPreview, RichTextEditor } from '@/components/organisms/RichTextEditor';
import { alertMessages } from '@/config';
import { useAppSelector, useInput } from '@/hooks';
import {
  clearCreatedEmailTemplate,
  clearEmailTemplateSentTestMail,
  clearUpdatedEmailTemplate,
  createEmailTemplate,
  emailTemplateSendTestMail,
  getAllCustomVariables,
  getAllEmailTemplates,
  getProgressBarTypes,
  updateEmailTemplate,
} from '@/redux/actions';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { useEmailTemplateStyles, useStaffMemberStyle } from '@/static/stylesheets/molecules';
import { colors } from '@/theme';
import {
  CustomVariableParams,
  EmailTemplateParams,
  EmailTemplateSendTestMailParams,
  JobProgressBarTypeParams,
  LeadItem,
} from '@/types';
import { copyText, validateEmail } from '@/utils';
import { convertCCArrayToText } from '@/utils/convertCCArrayToText';
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

interface EmailTemplatesFormProps {
  open: boolean;

  onClose?(): void;

  onReload?(): void;

  formData?: EmailTemplateParams | null;
  isLeadEmail?: boolean;
  leadData?: LeadItem;
  featureType?: 'lead' | 'client' | 'job' | 'settings';

  paperStyles?: React.CSSProperties;
}

const EmailTemplatesForm: React.FC<EmailTemplatesFormProps> = (props) => {
  const classes = useStaffMemberStyle();
  const emailTemplateClasses = useEmailTemplateStyles();
  const { open, onClose, onReload, formData: editFormData, isLeadEmail, leadData, featureType } = props;

  const editMode = editFormData && editFormData?.id !== '';
  const emailTemplateResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_ALL_EMAIL_TEMPLATE_LIST];
  const allCustomVariableList = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_ALL_CUSTOM_VARIABLES];
  const jobProgressBarList = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_PROGRESS_BAR_TYPES];
  const allEmailTemplates = emailTemplateResponse?.data?.data ?? [];
  const allCustomVariables = allCustomVariableList?.data?.data ?? [];

  const createdEmailTemplate = useAppSelector(selectHttpState)[CORRELATION_IDS.CREATE_EMAIL_TEMPLATE];
  const updatedEmailTemplate = useAppSelector(selectHttpState)[CORRELATION_IDS.UPDATE_EMAIL_TEMPLATE];
  const sentTestMailResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.EMAIL_TEMPLATE_SEND_TEST_MAIL];

  const [leadEmailSending, setLeadEmailSending] = useState<boolean>(false);
  const [leadTestMailSending, setLeadTestMailSending] = useState<boolean>(false);
  const [targetId, setTargetId] = useState<null | string | undefined>(null);

  const customers: any = leadData?.customer?.customer_contacts;
  const primaryContact = customers?.find((customer: any) => customer?.contact_is_primary);

  const filteredJobProgressBarTypes = () => {
    const progressBarTypes: any[] = [];
    (jobProgressBarList?.data?.data ?? [])?.forEach((progressBarType: JobProgressBarTypeParams) => {
      if (progressBarType?.status !== null) {
        progressBarTypes.push(progressBarType);
      }
    });

    return progressBarTypes;
  };

  const jobProgressBarTypes = filteredJobProgressBarTypes();

  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState<EmailTemplateParams['id'] | null>(null);
  const [selectedProgressBarTypes, setSelectedProgressBarTypes] = useState<string[]>([]);

  const [isPreview, setIsPreview] = useState<boolean>(false);
  const [editor, setEditor] = useState<EditorInstance | null>(null);

  const {
    value: templateName,
    setValue: setTemplateName,
    bind: bindTemplateName,
    reset: resetTemplateName,
    error: templateNameError,
  } = useInput('', {
    required: 'Please enter template name!',
  });

  const {
    value: subject,
    setValue: setSubject,
    bind: bindSubject,
    reset: resetSubject,
    error: subjectError,
  } = useInput('', {
    required: 'Please enter subject!',
  });

  const {
    value: copyTo,
    setValue: setCopyTo,
    bind: bindCopyTo,
    reset: resetCopyTo,
    error: copyToError,
  } = useInput('', {
    validate(value) {
      if (!value) return true;
      const emails = value.split(',').map((email: any) => email.trim());
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      const satisfiedValidation = emails.every((email: any) => emailRegex.test(email));
      if (satisfiedValidation) {
        return true;
      }
      return 'All Emails must be valid!';
    },
  });
  const {
    value: cc,
    setValue: setCC,
    bind: bindCC,
    reset: resetCC,
    error: ccError,
    setError: setCCError,
  } = useInput('', {
    required: isLeadEmail ? 'Please select sending to!' : false,
  });

  const {
    value: bodyMessage,
    setValue: setBodyMessage,
    reset: resetBodyMessage,
    error: bodyMessageError,
  } = useInput('', {
    required: 'Please enter body message!',
  });

  const [enabledOnHasError, setEnabledOnError] = useState<boolean>(false);
  const [previewMessageData, setPreviewMessageData] = useState<
    { to: string; subject?: string; body?: any; name?: string }[]
  >([]);

  const resetFields = () => {
    resetCC();
    resetSubject();
    resetCopyTo();
    resetTemplateName();
    resetBodyMessage();
    setSelectedProgressBarTypes(() => []);
    setSelectedEmailTemplate(null);
    setEnabledOnError(false);
  };

  const getCCValuesArray = () => {
    const emailsArray: any[] = [];
    const valueString = copyTo ? copyTo?.toString()?.trim() : '';

    if (valueString === '') {
      return [];
    }

    const emails = valueString?.split(',');

    if (emails?.length > 0) {
      emails?.forEach((email: string) => {
        if (validateEmail(email?.trim())) {
          emailsArray.push(email?.trim());
        }
      });
    }

    return emailsArray;
  };

  const fetchAllEmailTemplateList = () =>
    getAllEmailTemplates(
      featureType === 'lead'
        ? 'Lead'
        : featureType === 'client'
        ? 'Clients'
        : featureType === 'job'
        ? 'Job'
        : undefined,
    );
  const fetchAllCustomVariableList = () => getAllCustomVariables();
  const fetchProgressBarTypes = () =>
    getProgressBarTypes({
      progressType: featureType === 'settings' ? 'only_email' : undefined,
    });

  const progressBarIsSelected = (id: JobProgressBarTypeParams['id']) => {
    const index = selectedProgressBarTypes?.findIndex((progressBarId) => progressBarId === id);

    return index > -1;
  };

  const selectUnselectProgressBarType = (id: JobProgressBarTypeParams['id']) => {
    const progressBarTypes = [...selectedProgressBarTypes];
    const index = progressBarTypes?.findIndex((progressBarId) => progressBarId === id);
    if (index === -1) {
      if (id) {
        progressBarTypes?.push(id);
      }
    } else {
      progressBarTypes?.splice(index, 1);
    }

    setEnabledOnError(progressBarTypes?.length === 0);
    setSelectedProgressBarTypes(() => progressBarTypes);
  };

  const handleClickEmailTemplateLoad = async () => {
    let templateString = null;
    let template: EmailTemplateParams | null = null;

    if (allEmailTemplates?.length > 0 && selectedEmailTemplate) {
      const index = allEmailTemplates?.findIndex((eT: EmailTemplateParams) => eT?.id === selectedEmailTemplate);
      template = allEmailTemplates[index];
    }

    if (leadData?.id && selectedEmailTemplate) {
      if (template) {
        if (template?.cc_address && typeof template?.cc_address !== 'string') {
          if (copyTo?.toString()?.trim() === '') {
            setCopyTo(convertCCArrayToText(template?.cc_address));
          }
        }

        if (template?.subject) {
          setSubject(template?.subject);
        }
        if (template?.name) {
          setTemplateName(template?.name);
        }

        templateString = template?.body;
      }
    } else {
      if (template && template?.body) {
        templateString = template?.body;
      }
    }

    if (templateString) {
      editor?.setEditorValue(templateString);
      // setSelectedEmailTemplate(null);
    }
  };

  const validateForm = () => {
    if (!isLeadEmail) {
      if (selectedProgressBarTypes?.length === 0) {
        setEnabledOnError(selectedProgressBarTypes.length === 0);
      }
      templateNameError?.check();
    }

    subjectError?.check();
    copyToError?.check();
    ccError?.check();
    bodyMessageError?.check();

    if (
      (!isLeadEmail && selectedProgressBarTypes?.length === 0) ||
      (!isLeadEmail && templateNameError?.check()) ||
      subjectError?.check() ||
      copyToError?.check() ||
      ccError?.check() ||
      bodyMessageError?.check()
    ) {
      return false;
    }

    return true;
  };

  const submitTemplate = () => {
    if (!validateForm()) {
      toast.error(alertMessages.generalSettings.emailTemplates.submitValidationError);
      return;
    }

    const data: EmailTemplateParams = {
      cc_address: getCCValuesArray(),
      name: templateName,
      subject,
      enabled_on: selectedProgressBarTypes,
      body: bodyMessage,
      type: featureType,
    };

    if (editMode && editFormData?.id) {
      data.id = editFormData?.id;
    }

    if (editMode) {
      updateEmailTemplate(data);
    } else {
      createEmailTemplate(data);
    }
  };

  const sendLeadEmail = (type: 'send_test' | 'send_to_client') => {
    subjectError?.check();
    copyToError?.check();
    ccError?.check();
    bodyMessageError?.check();

    if (subjectError?.check() || ccError?.check() || copyToError?.check() || bodyMessageError?.check()) {
      return false;
    }

    if (type === 'send_test') {
      setLeadTestMailSending(true);
    } else {
      setLeadEmailSending(true);
    }

    let cc_address_data: any = undefined;

    /*if (type === "send_test") {
      if (getCCValuesArray().length > 0) {
        cc_address_data = getCCValuesArray();
      }
    } else {
      if (leadData?.customer?.email) {
        cc_address_data = [leadData?.customer?.email];
      }
    }*/

    if (getCCValuesArray().length > 0) {
      cc_address_data = getCCValuesArray();
    }

    apiClient
      .post({
        url:
          featureType === 'job' && leadData?.id
            ? endpoints.private.jobEmailTemplateSendMail(leadData?.id)
            : endpoints.private.leadClientEmailTemplateSendMail(leadData?.id || ''),
        data: {
          cc: cc_address_data,
          to: cc,
          subject,
          body: bodyMessage,
          test: type === 'send_test',
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      })
      .then(() => {
        toast.success(alertMessages.generalSettings.emailTemplates.leadEmailSendSuccess);
        if (type === 'send_test') {
          setLeadTestMailSending(false);
        } else {
          setLeadEmailSending(false);
        }

        if (type === 'send_to_client' && props?.onClose) {
          props?.onClose();
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (type === 'send_test') {
          setLeadTestMailSending(false);
        } else {
          setLeadEmailSending(false);
        }
      });
  };

  const sendTestMail = () => {
    ccError.check();
    copyToError?.check();
    subjectError.check();
    bodyMessageError.check();

    const cc_address = getCCValuesArray();

    if (ccError.check()) {
      toast.error('PLease enter cc address!');
      return;
    }

    if (copyToError.check()) {
      toast.error('PLease enter copy to address!');
      return;
    }

    if (subjectError.error) {
      toast.error('Please enter subject!');
      return;
    }

    if (bodyMessageError.error) {
      toast.error('Please enter body message!');
      return;
    }

    setTargetId(leadData?.id);

    const data: EmailTemplateSendTestMailParams = {
      body: bodyMessage,
      subject,
      is_test: 1,
      type: featureType,
    };

    if (cc_address?.length > 0) {
      data.cc_address = cc_address;
    }

    emailTemplateSendTestMail(data);
  };

  const handleTogglePreview = async () => {
    const isOpen = !isPreview;

    if (isOpen) {
      if (isLeadEmail) {
        if (!validateForm()) {
          toast.error(alertMessages.generalSettings.emailTemplates.previewSubmitValidationError);
          return;
        }

        try {
          const parseEmailTemplateResponse = await apiClient
            .post({
              url:
                featureType === 'lead' || featureType === 'client'
                  ? endpoints.private.parseLeadOrClientEmailTemplate(leadData?.id)
                  : endpoints.private.parseJobEmailTemplate(leadData?.id),
              method: 'post',
              data: {
                contacts: cc,
                body: bodyMessage,
                subject,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              },
            })
            .catch((error) => {
              console.log('error', error);
              toast.error(`${error?.response?.data?.errors ?? ''} ${error?.response?.data?.message ?? ''}`);
            });

          if (parseEmailTemplateResponse && parseEmailTemplateResponse?.data) {
            setPreviewMessageData(parseEmailTemplateResponse?.data);
          }
        } catch (e) {}
      } else {
        setPreviewMessageData([]);
      }
    }
    setIsPreview(isOpen);
  };

  useEffect(() => {
    if (open) {
      setIsPreview(false);
      fetchAllEmailTemplateList();
      fetchAllCustomVariableList();
      fetchProgressBarTypes();
    }
  }, [open]);

  useEffect(() => {
    if (sentTestMailResponse?.success && targetId === leadData?.id) {
      toast.success(alertMessages.generalSettings.emailTemplates.testEmailSendSuccess);
      clearEmailTemplateSentTestMail();
      setTargetId(null);
    }

    if (sentTestMailResponse?.error && targetId === leadData?.id) {
      toast.error(alertMessages.generalSettings.emailTemplates.testEmailSendError);
      clearEmailTemplateSentTestMail();
      setTargetId(null);
    }
  }, [sentTestMailResponse]);

  useEffect(() => {
    if (updatedEmailTemplate?.success) {
      toast.success(alertMessages.generalSettings.emailTemplates.updateSuccess);
      clearUpdatedEmailTemplate();

      resetFields();

      if (onReload) {
        onReload();
      }

      if (onClose) {
        onClose();
      }
    }

    if (updatedEmailTemplate?.error) {
      const error = createdEmailTemplate?.error;
      if (error?.status === 422) {
        toast.error(error?.data?.message);
      } else {
        toast.error(alertMessages.generalSettings.emailTemplates.updateError);
      }
      clearUpdatedEmailTemplate();
    }
  }, [updatedEmailTemplate]);

  useEffect(() => {
    if (createdEmailTemplate?.success) {
      toast.success(alertMessages.generalSettings.emailTemplates.createSuccess);
      clearCreatedEmailTemplate();

      resetFields();

      if (onReload) {
        onReload();
      }

      if (onClose) {
        onClose();
      }
    }

    if (createdEmailTemplate?.error) {
      const error = createdEmailTemplate?.error;
      if (error?.status === 422) {
        toast.error(error?.data?.message);
      } else {
        toast.error(alertMessages.generalSettings.emailTemplates.createError);
      }
      clearCreatedEmailTemplate();
    }
  }, [createdEmailTemplate]);

  useEffect(() => {
    if (editMode) {
      setSelectedProgressBarTypes(editFormData?.enabled_on ?? []);
      setTemplateName(editFormData?.name);
      setSubject(editFormData?.subject);
      setCopyTo(
        convertCCArrayToText(
          editFormData?.cc_address &&
            typeof editFormData?.cc_address !== 'string' &&
            editFormData?.cc_address?.length > 0
            ? editFormData?.cc_address
            : [],
        ),
      );

      if (editor) {
        editor.setEditorValue(editFormData?.body);
      }
    }
  }, [editFormData, editMode, editor]);

  useEffect(() => {
    if (!open) {
      resetFields();
    }
  }, [open]);

  // default email setup
  useEffect(() => {
    setCC([primaryContact?.id]);
  }, [primaryContact?.id]);

  return (
    <>
      <Dialog
        className={classes.largeModal}
        open={open}
        maxWidth={'xl'}
        onClose={onClose}
        aria-labelledby='responsive-dialog-title'
        sx={{
          '& .MuiDialog-paper': {
            width: props?.paperStyles ?? '96%',
            height: '100%',
            maxWidth: props?.paperStyles ?? '100%',
          },
        }}
      >
        <DialogTitle
          className={classes.DialogTitle}
          id='responsive-dialog-title'
          sx={{ borderBottom: '1px solid #8c8c8c70' }}
        >
          {isLeadEmail ? (
            <>{`${
              isPreview
                ? 'Preview'
                : editMode
                ? `Email - ${leadData?.customer?.first_name} ${leadData?.customer?.last_name}`
                : `Email - ${leadData?.customer?.first_name} ${leadData?.customer?.last_name}`
            }`}</>
          ) : (
            <>{`${isPreview ? 'Preview' : editMode ? 'Edit' : 'Create New'} Template`}</>
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.DialogContent}>
            <div
              style={{
                display: isPreview ? 'none' : 'block',
              }}
            >
              <Grid container spacing={2} className={classes.contentWrapper}>
                <Grid item sm={12} md={4} lg={4}>
                  <Box className={emailTemplateClasses.templateInformationSection}>
                    <Box className={emailTemplateClasses.templateBasicInformationSection}>
                      {!isLeadEmail && (
                        <>
                          <Box style={{ marginTop: '10px' }}>
                            <Typography className={classes.labelForm} variant='subtitle1' component='div'>
                              Choose which pages to show this email on:
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              padding: '0 50px',
                            }}
                          >
                            <Grid container spacing={2}>
                              {jobProgressBarTypes?.map((progressBarType: JobProgressBarTypeParams, index: number) => {
                                return (
                                  <Grid item sm={4} md={4} lg={4} key={index} className={classes.checkbocGroup}>
                                    <FormGroup>
                                      <FormControlLabel
                                        className={classes.labelForm}
                                        control={
                                          <Checkbox
                                            checked={progressBarIsSelected(progressBarType?.id)}
                                            onClick={() => selectUnselectProgressBarType(progressBarType?.id)}
                                          />
                                        }
                                        label={progressBarType?.progress_type_name}
                                      />
                                    </FormGroup>
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </Box>

                          {enabledOnHasError && (
                            <div
                              style={{
                                color: '#e63c3c',
                                fontSize: 11.5,
                                marginTop: '24px',
                              }}
                            >
                              Please select a job progress type!
                            </div>
                          )}
                        </>
                      )}

                      {!isLeadEmail && (
                        <Grid item sm={12} md={12} lg={12} style={{ marginTop: '30px' }}>
                          <TextInput
                            variant={'standard'}
                            label={'Template Name'}
                            fullWidth={true}
                            {...bindTemplateName}
                            error={templateNameError?.error}
                            errorText={templateNameError?.message}
                          />
                        </Grid>
                      )}

                      {isLeadEmail && (
                        <>
                          <Box className={classes.multiSelectBody}>
                            <Grid style={{ marginBottom: '0', overflow: 'hidden' }} container>
                              <Grid item sm={2} md={3} xl={2}>
                                <span
                                  style={{
                                    marginBottom: '1rem',
                                    whiteSpace: 'nowrap',
                                    display: 'inline-flex',
                                    color: '#2196f3',
                                  }}
                                >
                                  Sending to:
                                </span>
                              </Grid>
                              <Grid item sm={10} md={9} xl={10}>
                                <MultiSelect
                                  options={[
                                    {
                                      label: 'Choose who to send email to',
                                      value: '--empty--',
                                      disabled: true,
                                    },
                                    ...(leadData?.customer?.customer_contacts ?? [])?.map((_) => {
                                      return {
                                        label: _.contact_email || '',
                                        value: _.id,
                                      };
                                    }),
                                  ]}
                                  multiple={true}
                                  value={cc || ['--empty--']}
                                  onChange={(e) => setCC(e.filter((_) => _ !== '--empty--'))}
                                  error={ccError?.error}
                                  errorText={ccError?.message}
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </>
                      )}
                      <TextInput
                        variant={'standard'}
                        label={'Copy To (separate with commas):'}
                        fullWidth={true}
                        {...bindCopyTo}
                        error={copyToError?.error}
                        errorText={copyToError?.message}
                      />
                      <TextInput
                        variant={'standard'}
                        label={'Subject Line'}
                        fullWidth={true}
                        {...bindSubject}
                        error={subjectError?.error}
                        errorText={subjectError?.message}
                      />
                      <Box className={classes.multiSelectBody}>
                        <Grid container spacing={2}>
                          <Grid item sm={10} md={9} lg={9}>
                            <SearchableTextInput
                              label={'Email templates'}
                              options={[
                                ...allEmailTemplates?.map((emailTemplate: EmailTemplateParams) => ({
                                  label: emailTemplate?.name,
                                  value: emailTemplate?.id,
                                })),
                              ]}
                              value={selectedEmailTemplate}
                              onChange={(value) => setSelectedEmailTemplate(value)}
                              loading={emailTemplateResponse?.loading}
                            />
                          </Grid>
                          <Grid item sm={2} md={3} lg={3}>
                            <Button size={'small'} title={'Load'} onClick={handleClickEmailTemplateLoad} />
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                    <Box className={classNames(emailTemplateClasses.customVariableListSection, 'custom-variable-list')}>
                      <Typography className={classes.labelForm} variant='subtitle1' component='div'>
                        Variable placeholders to use in email
                      </Typography>

                      <List>
                        {allCustomVariables?.map((item: CustomVariableParams, index: number) => (
                          <ListItem
                            key={index}
                            onClick={() => {
                              if (item?.name) {
                                copyText(item?.name, () => {
                                  toast.success('Copied to the clipboard!');
                                });
                              }
                            }}
                            sx={{
                              '& *': {
                                color: colors.primary,
                                cursor: 'pointer',
                              },
                            }}
                          >
                            <ListItemText primary={item.name} />
                            <ListItemText style={{ textAlign: 'right' }} primary={'Copy'} />
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  </Box>
                </Grid>

                <Grid item sm={12} md={8} lg={8}>
                  {bodyMessageError?.error && (
                    <div
                      style={{
                        color: '#e63c3c',
                        fontSize: 11.5,
                        marginBottom: 5,
                      }}
                    >
                      {bodyMessageError?.message}
                    </div>
                  )}
                  <RichTextEditor
                    placeholder={'Enter Text Here'}
                    onChange={(value) => setBodyMessage(value)}
                    onMount={(editor) => setEditor(editor)}
                    height={window.innerHeight - 230}
                  />
                </Grid>
              </Grid>
            </div>

            <div
              style={{
                display: isPreview ? 'block' : 'none',
              }}
            >
              {!isLeadEmail && (
                <>
                  <Typography className={classes.labelForm} variant='subtitle1' component='div'>
                    <strong>Choose which pages to show this email on</strong>
                  </Typography>
                  <div className={classes.labelForm} style={{ marginBottom: 10 }}>
                    <i>
                      {jobProgressBarTypes?.map((progressBarType: JobProgressBarTypeParams, index: number) => {
                        return (
                          <span key={index}>{`${index > 0 ? ',' : ''} ${progressBarType?.progress_type_name}`}</span>
                        );
                      })}
                    </i>
                  </div>
                </>
              )}

              <Typography className={classes.labelForm} variant='subtitle1' component='div'>
                <strong>Template</strong> : <i>{templateName}</i>
              </Typography>
              <Typography className={classes.labelForm} variant='subtitle1' component='div'>
                <strong>CC</strong> : <i>{copyTo}</i>
              </Typography>

              {(previewMessageData || [])?.map((email, index) => (
                <Box key={index} mt={10}>
                  <Typography className={classes.labelForm} variant='subtitle1' component='div'>
                    <strong>To</strong> : <i>{email?.to}</i>
                  </Typography>
                  <Typography className={classes.labelForm} variant='subtitle1' component='div'>
                    <strong>Name</strong> : <i>{email?.name}</i>
                  </Typography>
                  <Typography className={classes.labelForm} variant='subtitle1' component='div'>
                    <strong>Subject</strong> :{' '}
                    <u>
                      <i>{email?.subject}</i>
                    </u>
                  </Typography>

                  <br />

                  <Typography className={classes.labelForm} variant='subtitle1' component='div'>
                    <strong>Message Body</strong> :
                  </Typography>

                  <RenderRichTextEditorPreview content={email?.body} />
                </Box>
              ))}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.bottomSection}>
          <div>
            <Button onClick={onClose} size={'small'} color={'error'} variant={'contained'} title={'Close'} />
          </div>
          <div>
            <Button
              onClick={handleTogglePreview}
              color={'primary'}
              size={'small'}
              variant={'contained'}
              title={`${isPreview ? 'Close Preview' : 'Preview Email'}`}
            />
            <Button
              size={'small'}
              onClick={isLeadEmail ? () => sendLeadEmail('send_test') : sendTestMail}
              color={'warning'}
              variant={'contained'}
              title={'Send Test'}
              loading={sentTestMailResponse?.loading || leadTestMailSending}
            />
            {!isLeadEmail ? (
              <Button
                size={'small'}
                onClick={submitTemplate}
                color={'success'}
                variant={'contained'}
                title={'Save Template'}
                loading={createdEmailTemplate?.loading || updatedEmailTemplate?.loading}
              />
            ) : (
              <Button
                size={'small'}
                onClick={() => sendLeadEmail('send_to_client')}
                color={'success'}
                variant={'contained'}
                title={'Send it'}
                loading={leadEmailSending}
              />
            )}
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export { EmailTemplatesForm };
