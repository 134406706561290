import {
  CustomerListFilterParams,
  FileUploaderParams,
  TaskCreateParams,
  TaskFileUploaderParams,
  TaskFilterBodyParams,
  TaskItem,
  TaskListFilterParams,
  TaskTypeListFilterParams,
  UserListFilterParams,
} from '@/types';
import { store } from '@/redux/store';
import {
  changeTaskAssigneeHttpCall,
  changeTaskPriorityHttpCall,
  CORRELATION_IDS,
  createTaskHttpCall,
  deleteTaskHttpCall,
  fileUploadHttpCall,
  getFilteredTasksHttpCall,
  getSingleTaskHttpCall,
  getTaskCustomersDropdownHttpCall,
  getTasksHttpCall,
  getTaskStaffMembersDropdownHttpCall,
  getTaskTypesDropdownHttpCall,
  markTaskAsCompletedHttpCall,
  startStopTaskTimerHttpCall,
  taskFileUploadHttpCall,
  updateTaskHttpCall,
} from '@/redux/reducers/http';
import { clearHttpStateObject } from '@/redux/actions';
import { UiState, updateTaskEditFormData } from '@/redux/reducers/uiSlice';

export function setTaskEditFormData(data: Partial<UiState['taskEditForm']>) {
  store.dispatch(updateTaskEditFormData(data));
}

export function getTasks(params: TaskListFilterParams) {
  store.dispatch(getTasksHttpCall(params));
}

export function getFilteredTasks(params: TaskFilterBodyParams) {
  store.dispatch(getFilteredTasksHttpCall(params));
}

export function changeTaskAssignee(taskId: TaskItem['id'], assigneeId: string) {
  store.dispatch(changeTaskAssigneeHttpCall(taskId, assigneeId));
}

export function markTaskAsCompleted(taskId: TaskItem['id']) {
  store.dispatch(markTaskAsCompletedHttpCall(taskId));
}

export function changeTaskPriority(taskId: TaskItem['id'], priority: '0' | '1' | '2' | '3') {
  store.dispatch(changeTaskPriorityHttpCall(taskId, priority));
}

export function startStopTaskTimer(taskId: TaskItem['id']) {
  store.dispatch(startStopTaskTimerHttpCall(taskId));
}

export function createTask(params: TaskCreateParams) {
  store.dispatch(createTaskHttpCall(params));
}

export function updateTask(
  params: TaskCreateParams & {
    id: string;
  },
) {
  store.dispatch(updateTaskHttpCall(params));
}

export function deleteTask(taskId: TaskItem['id'], deleteSubTaskFromPopup?: boolean) {
  store.dispatch(deleteTaskHttpCall(taskId, deleteSubTaskFromPopup));
}

export function clearFilteredTasksResponse() {
  clearHttpStateObject(CORRELATION_IDS.GET_FILTERED_TASKS);
}

export function clearCreatedTask() {
  clearHttpStateObject(CORRELATION_IDS.CREATE_TASK);
}

export function clearUpdatedTaskResponse() {
  clearHttpStateObject(CORRELATION_IDS.UPDATE_TASK);
}

export function clearDeletedTask() {
  clearHttpStateObject(CORRELATION_IDS.DELETE_TASK);
}

export function clearMarkTaskAsCompleted() {
  clearHttpStateObject(CORRELATION_IDS.MARK_TASK_AS_COMPLETED);
}

export function clearChangeTaskPriorityResponse() {
  clearHttpStateObject(CORRELATION_IDS.CHANGE_TASK_PRIORITY);
}

export function clearStartStopTaskTimerResponse() {
  clearHttpStateObject(CORRELATION_IDS.START_STOP_TASK_TIMER);
}

export function getSingleTask(taskId: TaskItem['id'], relationSuffix?: string) {
  store.dispatch(getSingleTaskHttpCall(taskId, relationSuffix));
}

export function clearSingleTask(taskId: TaskItem['id']) {
  clearHttpStateObject([`${CORRELATION_IDS.GET_SINGLE_TASK}_${taskId}`] as any);
}

export function getTaskStaffMembers(params: UserListFilterParams) {
  store.dispatch(getTaskStaffMembersDropdownHttpCall(params));
}

export function getTaskCustomers(params: CustomerListFilterParams) {
  store.dispatch(getTaskCustomersDropdownHttpCall(params));
}

export function getTaskTypes(params: TaskTypeListFilterParams) {
  store.dispatch(getTaskTypesDropdownHttpCall(params));
}

export function uploadTaskFile(params: TaskFileUploaderParams) {
  store.dispatch(taskFileUploadHttpCall(params));
}

export function clearUploadTaskFileResponse() {
  clearHttpStateObject(CORRELATION_IDS.UPLOAD_TASK_FILE);
}
