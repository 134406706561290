import * as React from 'react';
import { useAPIInformationStyle } from '@/static/stylesheets/molecules';
import { TextInput } from '@/components/atoms/TextInput';
import Typography from '@mui/material/Typography';
import { Grid, TextareaAutosize } from '@mui/material';
import { Button } from '@/components/atoms/Button';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import CloseIcon from '@mui/icons-material/Close';
import { useAppSelector, useInput } from '@/hooks';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { Loader } from '@/components/atoms/Loader';
import {
  clearConnectedDocusignApi,
  clearConnectedDripApi,
  clearConnectedGoogleCalendarApi,
  clearConnectedUcrmApi,
  clearDisconnectedDocusignApi,
  clearDisconnectedDripApi,
  clearDisconnectedGoogleCalendarApi,
  clearDisconnectedUcrmApi,
  connectDocusignApi,
  connectDripApi,
  connectGoogleCalendarApi,
  connectUcrmApi,
  disconnectDocusignApi,
  disconnectDripApi,
  disconnectGoogleCalendarApi,
  disconnectUcrmApi,
  getAllApiInformation,
} from '@/redux/actions';
import { useEffect, useState } from 'react';
import { ApiInformationParams } from '@/types';
import toast from 'react-hot-toast';
import { alertMessages } from '@/config';

interface APIInformationProps {}

const APIInformation: React.FC<APIInformationProps> = () => {
  const classes = useAPIInformationStyle();
  const allApiInformationObject = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_ALL_API_INFORMATION];
  const connectedGoogleCalendarApi = useAppSelector(selectHttpState)[CORRELATION_IDS.CONNECT_GOOGLE_CALENDAR_API];
  const disconnectedGoogleCalendarApi = useAppSelector(selectHttpState)[CORRELATION_IDS.DISCONNECT_GOOGLE_CALENDAR_API];
  const connectedDocusignApi = useAppSelector(selectHttpState)[CORRELATION_IDS.CONNECT_DOCUSIGN_API];
  const disconnectedDocusignApi = useAppSelector(selectHttpState)[CORRELATION_IDS.DISCONNECT_DOCUSIGN_API];
  const connectedDripApi = useAppSelector(selectHttpState)[CORRELATION_IDS.CONNECT_DRIP_API];
  const disconnectedDripApi = useAppSelector(selectHttpState)[CORRELATION_IDS.DISCONNECT_DRIP_API];
  const connectedUcrmApi = useAppSelector(selectHttpState)[CORRELATION_IDS.CONNECT_UCRM_API];
  const disconnectedUcrmApi = useAppSelector(selectHttpState)[CORRELATION_IDS.DISCONNECT_UCRM_API];

  const allApiData: ApiInformationParams = allApiInformationObject?.data;
  const googleCalendarData = allApiData?.google_calendar;
  const docusignApiData = allApiData?.docusign;
  const dripApiData = allApiData?.drip;
  const ucrmApiData = allApiData?.ucrm;

  const [googleCalendarApiConnectionChecking, setGoogleCalendarConnectionApiChecking] = useState<boolean>(false);

  const {
    value: integrationKey,
    setValue: setIntegrationKey,
    reset: resetIntegrationKey,
    error: integrationKeyError,
    bind: bindIntegrationKey,
  } = useInput('', {
    required: 'Please enter integration key!',
  });

  const {
    value: userId,
    setValue: setUserId,
    reset: resetUserId,
    error: userIdError,
    bind: bindUserId,
  } = useInput('', {
    required: 'Please enter user id!',
  });

  const {
    value: rsaPrivateKey,
    reset: resetRsaPrivateKey,
    error: rsaPrivateKeyError,
    bind: bindRsaPrivateKey,
  } = useInput('', {
    required: 'Please enter user rsa private key!',
  });

  const {
    value: apiToken,
    setValue: setApiToken,
    reset: resetApiToken,
    error: apiTokenError,
    bind: bindApiToken,
  } = useInput('', {
    required: 'Please enter api token!',
  });

  const {
    value: accountId,
    setValue: setAccountId,
    reset: resetAccountId,
    error: accountIdError,
    bind: bindAccountId,
  } = useInput('', {
    required: 'Please enter account id!',
  });

  const {
    value: apiKey,
    setValue: setApiKey,
    reset: resetApiKey,
    error: apiKeyError,
    bind: bindApiKey,
  } = useInput('', {
    required: 'Please enter api key!',
  });

  const resetDocusignFields = () => {
    resetIntegrationKey();
    resetUserId();
    resetRsaPrivateKey();
  };

  const resetDripFields = () => {
    resetApiToken();
    resetAccountId();
  };

  const resetUcrmFields = () => {
    resetApiKey();
  };

  const fetchAllApiInfo = () => getAllApiInformation();

  const connectGoogleCalendar = () => connectGoogleCalendarApi();

  const redirectGoogleCalendarApi = (url: string) => {
    const popup = window.open(url, '  ', 'width=500, height=500');

    const popupTick = setInterval(function () {
      if (popup?.closed) {
        clearInterval(popupTick);
        setGoogleCalendarConnectionApiChecking(true);
        fetchAllApiInfo();
      }
    }, 700);
  };

  const connectDocusign = () => {
    if (integrationKeyError?.check() || userIdError?.check() || rsaPrivateKeyError?.check()) {
      toast.error(alertMessages.generalSettings.apiInformation.docusingSubmitValidationError);
      return;
    }
    connectDocusignApi({
      integration_key: integrationKey,
      rsa_private_key: rsaPrivateKey,
      user_id: userId,
    });
  };

  const connectDrip = () => {
    if (apiTokenError?.check() || accountIdError?.check()) {
      toast.error(alertMessages.generalSettings.apiInformation.dripSubmitValidationError);
      return;
    }
    connectDripApi({
      api_token: apiToken,
      account_id: accountId,
    });
  };

  const connectUcrm = () => {
    if (apiKeyError?.check()) {
      toast.error(alertMessages.generalSettings.apiInformation.ucrmSubmitValidationError);
      return;
    }
    connectUcrmApi({
      api_key: apiKey,
    });
  };

  const disConnectGoogleCalendar = () => disconnectGoogleCalendarApi();
  const disconnectDocusign = () => disconnectDocusignApi();
  const disconnectDrip = () => disconnectDripApi();
  const disconnectUcrm = () => disconnectUcrmApi();

  useEffect(() => {
    if (disconnectedGoogleCalendarApi?.success) {
      fetchAllApiInfo();
      toast.success(alertMessages.generalSettings.apiInformation.googleCalendarDisconnectSuccess);
      clearDisconnectedGoogleCalendarApi();
    }

    if (disconnectedGoogleCalendarApi?.error) {
      toast.error(alertMessages.generalSettings.apiInformation.googleCalendarDisconnectError);
      clearDisconnectedGoogleCalendarApi();
    }
  }, [disconnectedGoogleCalendarApi]);

  useEffect(() => {
    if (connectedGoogleCalendarApi?.success) {
      const data = connectedGoogleCalendarApi?.data;

      if (data?.url && data?.url !== '') {
        redirectGoogleCalendarApi(data?.url);
      }
      clearConnectedGoogleCalendarApi();
    }

    if (connectedGoogleCalendarApi?.error) {
      toast.error(alertMessages.generalSettings.apiInformation.googleCalendarConnectError);
      clearConnectedGoogleCalendarApi();
    }
  }, [connectedGoogleCalendarApi]);

  useEffect(() => {
    if (disconnectedDocusignApi?.success) {
      fetchAllApiInfo();
      resetDocusignFields();
      toast.success(alertMessages.generalSettings.apiInformation.docusignDisconnectSuccess);
      clearDisconnectedDocusignApi();
    }

    if (disconnectedDocusignApi?.error) {
      toast.error(alertMessages.generalSettings.apiInformation.docusignDisconnectError);
      clearDisconnectedDocusignApi();
    }
  }, [disconnectedDocusignApi]);

  useEffect(() => {
    if (connectedDocusignApi?.success) {
      fetchAllApiInfo();
      toast.success(alertMessages.generalSettings.apiInformation.docusignConnectSuccess);
      resetRsaPrivateKey();
      clearConnectedDocusignApi();
    }

    if (connectedDocusignApi?.error) {
      const error = connectedDocusignApi?.error;
      if (error?.status === 400) {
        fetchAllApiInfo();
        toast.error(error?.data?.errors);
      } else {
        toast.error(alertMessages.generalSettings.apiInformation.docusignConnectError);
      }
      clearConnectedDocusignApi();
    }
  }, [connectedDocusignApi]);

  useEffect(() => {
    if (disconnectedDripApi?.success) {
      fetchAllApiInfo();
      resetDripFields();
      toast.success(alertMessages.generalSettings.apiInformation.dripDisconnectSuccess);
      clearDisconnectedDripApi();
    }

    if (disconnectedDripApi?.error) {
      toast.error(alertMessages.generalSettings.apiInformation.dripDisconnectError);
      clearDisconnectedDripApi();
    }
  }, [disconnectedDripApi]);

  useEffect(() => {
    if (connectedDripApi?.success) {
      fetchAllApiInfo();
      resetApiToken();
      toast.success(alertMessages.generalSettings.apiInformation.dripConnectSuccess);
      clearConnectedDripApi();
    }

    if (connectedDripApi?.error) {
      toast.error(alertMessages.generalSettings.apiInformation.dripConnectError);
      clearConnectedDripApi();
    }
  }, [connectedDripApi]);

  useEffect(() => {
    if (disconnectedUcrmApi?.success) {
      fetchAllApiInfo();
      resetUcrmFields();
      toast.success(alertMessages.generalSettings.apiInformation.ucrmDisconnectSuccess);
      clearDisconnectedUcrmApi();
    }

    if (disconnectedUcrmApi?.error) {
      toast.error(alertMessages.generalSettings.apiInformation.ucrmDisconnectError);
      clearDisconnectedUcrmApi();
    }
  }, [disconnectedUcrmApi]);

  useEffect(() => {
    if (connectedUcrmApi?.success) {
      fetchAllApiInfo();
      resetUcrmFields();
      toast.success(alertMessages.generalSettings.apiInformation.ucrmConnectSuccess);
      clearConnectedUcrmApi();
    }

    if (connectedUcrmApi?.error) {
      const error = connectedUcrmApi?.error;
      if (error?.status === 401) {
        toast.error(alertMessages.generalSettings.apiInformation.ucrmConnectWrongKeyError);
      } else {
        toast.error(alertMessages.generalSettings.apiInformation.ucrmConnectError);
      }
      clearConnectedUcrmApi();
    }
  }, [connectedUcrmApi]);

  useEffect(() => {
    if (googleCalendarApiConnectionChecking) {
      setGoogleCalendarConnectionApiChecking(false);
      if (googleCalendarData?.api_access) {
        toast.success(alertMessages.generalSettings.apiInformation.googleCalendarConnectSuccess);
      } else {
        toast.error(alertMessages.generalSettings.apiInformation.googleCalendarConnectError);
      }
    }
  }, [googleCalendarData]);

  useEffect(() => {
    if (docusignApiData?.api_access) {
      setIntegrationKey(docusignApiData?.api_access?.integration_key);
      setUserId(docusignApiData?.api_access?.ds_impersonated_user_id);
    }
  }, [docusignApiData?.api_access]);

  useEffect(() => {
    if (dripApiData?.api_access) {
      setAccountId(dripApiData?.api_access?.account_id);
      setApiToken(dripApiData?.api_access?.api_token);
    }
  }, [dripApiData?.api_access]);

  useEffect(() => {
    if (ucrmApiData?.api_access) {
      setApiKey(ucrmApiData?.api_access?.api_key);
    }
  }, [ucrmApiData?.api_access]);

  useEffect(() => {
    fetchAllApiInfo();
  }, []);

  return (
    <>
      <div className={classes.root}>
        {allApiInformationObject?.loading ? (
          <Loader />
        ) : (
          <>
            <div>
              <div className={classes.cardHeader}>
                <Typography variant='subtitle1' component='div'>
                  Google Calendar Sync Settings
                </Typography>
                <div>
                  {googleCalendarData?.api_access ? (
                    <DoneAllIcon className={classes.success} />
                  ) : (
                    <CloseIcon className={classes.error} />
                  )}
                </div>
              </div>
              <Grid container>
                {/* <Grid item sm={12} md={5} lg={5}>
                  <Typography variant="subtitle1" component="h2">
                    Google Account Currently Synced
                  </Typography>
                </Grid>
                <Grid item sm={12} md={4} lg={4}>
                  <TextInput
                    className={classes.textAria}
                    variant={'standard'}
                    fullWidth={true}
                    centerMode={true}
                  />
                </Grid> */}
                <Grid item sm={12} md={12} lg={12} style={{ textAlign: 'center' }}>
                  <Button
                    color={'primary'}
                    variant={'contained'}
                    size={'small'}
                    title={googleCalendarData?.api_access ? 'Disconnect Account' : 'Connect Account'}
                    onClick={googleCalendarData?.api_access ? disConnectGoogleCalendar : connectGoogleCalendar}
                  />
                </Grid>
              </Grid>
            </div>
            <div>
              <div className={classes.cardHeader}>
                <Typography variant='subtitle1' component='div'>
                  Docusign API
                </Typography>
                <div>
                  {docusignApiData?.api_access ? (
                    <DoneAllIcon className={classes.success} />
                  ) : (
                    <CloseIcon className={classes.error} />
                  )}
                </div>
              </div>
              <Grid container spacing={2} className={classes.container}>
                <Grid item sm={12} md={4} lg={4}>
                  <Typography variant='subtitle1' component='h2'>
                    Integration Key
                  </Typography>
                </Grid>
                <Grid item sm={12} md={8} lg={8}>
                  <TextInput
                    className={classes.textAria}
                    variant={'standard'}
                    fullWidth={true}
                    centerMode={true}
                    {...bindIntegrationKey}
                    error={integrationKeyError?.error}
                    errorText={integrationKeyError?.message}
                  />
                </Grid>
                <Grid item sm={12} md={4} lg={4} className={classes.marginTop}>
                  <Typography variant='subtitle1' component='h2'>
                    User Id
                  </Typography>
                </Grid>
                <Grid item sm={12} md={8} lg={8}>
                  <TextInput
                    className={classes.textAria}
                    variant={'standard'}
                    fullWidth={true}
                    centerMode={true}
                    {...bindUserId}
                    error={userIdError?.error}
                    errorText={userIdError?.message}
                  />
                </Grid>
                <Grid item sm={12} md={4} lg={4}>
                  <Typography variant='subtitle1' component='h2'>
                    RSA Private Key
                  </Typography>
                </Grid>
                <Grid item sm={12} md={8} lg={8} className={classes.marginTop}>
                  <TextareaAutosize
                    aria-label='minimum height'
                    minRows={3}
                    className={classes.textAria}
                    {...bindRsaPrivateKey}
                  />
                  {rsaPrivateKeyError?.error && <div className={classes.errorText}>{rsaPrivateKeyError?.message}</div>}
                </Grid>
                <Grid item sm={12} md={12} lg={12} style={{ textAlign: 'end' }}>
                  <Button
                    color={'primary'}
                    variant={'contained'}
                    title={docusignApiData?.api_access ? 'Disconnect Account' : 'Connect Account'}
                    onClick={docusignApiData?.api_access ? disconnectDocusign : connectDocusign}
                    loading={disconnectedDocusignApi?.loading || connectedDocusignApi?.loading}
                  />
                </Grid>
              </Grid>
            </div>
            <div>
              <div className={classes.cardHeader}>
                <Typography variant='subtitle1' component='div'>
                  Drip API
                </Typography>
                <div>
                  {dripApiData?.api_access ? (
                    <DoneAllIcon className={classes.success} />
                  ) : (
                    <CloseIcon className={classes.error} />
                  )}
                </div>
              </div>
              <Grid container spacing={2} className={classes.container}>
                <Grid item sm={12} md={4} lg={4}>
                  <Typography variant='subtitle1' component='h2'>
                    Api Token
                  </Typography>
                </Grid>
                <Grid item sm={12} md={8} lg={8}>
                  <TextInput
                    className={classes.textAria}
                    variant={'standard'}
                    fullWidth={true}
                    centerMode={true}
                    {...bindApiToken}
                    error={apiTokenError?.error}
                    errorText={apiTokenError?.message}
                  />
                </Grid>
                <Grid item sm={12} md={4} lg={4} className={classes.marginTop}>
                  <Typography variant='subtitle1' component='h2'>
                    Account Id
                  </Typography>
                </Grid>
                <Grid item sm={12} md={8} lg={8}>
                  <TextInput
                    className={classes.textAria}
                    variant={'standard'}
                    fullWidth={true}
                    centerMode={true}
                    {...bindAccountId}
                    error={accountIdError?.error}
                    errorText={accountIdError?.message}
                  />
                </Grid>
                <Grid item sm={12} md={12} lg={12} style={{ textAlign: 'end' }}>
                  <Button
                    color={'primary'}
                    variant={'contained'}
                    title={dripApiData?.api_access ? 'Disconnect Account' : 'Connect Account'}
                    onClick={dripApiData?.api_access ? disconnectDrip : connectDrip}
                    loading={connectedDripApi?.loading || disconnectedDripApi?.loading}
                  />
                </Grid>
              </Grid>
            </div>
            <div>
              <div className={classes.cardHeader}>
                <Typography variant='subtitle1' component='div'>
                  UCRM API
                </Typography>
                <div>
                  {ucrmApiData?.api_access ? (
                    <DoneAllIcon className={classes.success} />
                  ) : (
                    <CloseIcon className={classes.error} />
                  )}
                </div>
              </div>
              <Grid container spacing={2} className={classes.container}>
                <Grid item sm={12} md={4} lg={4}>
                  <Typography variant='subtitle1' component='h2'>
                    Api Key
                  </Typography>
                </Grid>
                <Grid item sm={12} md={5} lg={5}>
                  <TextInput
                    className={classes.textAria}
                    variant={'standard'}
                    fullWidth={true}
                    centerMode={true}
                    {...bindApiKey}
                    error={apiKeyError?.error}
                    errorText={apiKeyError?.message}
                  />
                </Grid>
                <Grid item sm={12} md={3} lg={3}>
                  <Button
                    color={'primary'}
                    variant={'contained'}
                    title={ucrmApiData?.api_access ? 'Disconnect Account' : 'Connect Account'}
                    onClick={ucrmApiData?.api_access ? disconnectUcrm : connectUcrm}
                    loading={connectedUcrmApi?.loading || disconnectedUcrmApi?.loading}
                  />
                </Grid>
              </Grid>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export { APIInformation };
