import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from '@/redux/store';
import { ProposalPackageItem, ProposalTermItem } from '@/components/organisms/Leads';
import { TaskItem } from '@/types';

export interface UiState {
  confirmationDialog: {
    open: boolean;
    type: 'error' | 'info' | 'success';
    cancelType: 'error' | 'info';
    textCenter: 'center' | 'left';
    title: string;
    message: string;
    hasCancel: boolean;
    cancelLabel: string;
    confirmLabel: string;
    onClickCancel?: any;
    onClickConfirm: any;
  };
  leadsExpanded: boolean;
  jobsExpanded: boolean;
  showLostLeads: boolean;
  showDeactivatedClients: boolean;
  jobListType: 'regular' | 'completed' | 'removed';

  leadsForm: {
    source_id?: string;

    survey_date?: string;
    survey_time_from?: string;
    survey_time_to?: string;
    survey_datetime_from?: string;
    survey_datetime_to?: string;

    installation_date?: string;
    installation_time_from?: string;
    installation_time_to?: string;
    installation_datetime_from?: string;
    installation_datetime_to?: string;

    next_follow_up_date?: string;
    next_follow_up_time?: string;
    next_follow_up_datetime?: string;

    follow_up_description?: string;

    contract_download_bandwidth?: {
      value: number | null;
      error: boolean;
    };
    contract_upload_bandwidth?: {
      value: number | null;
      error: boolean;
    };
    contract_price?: {
      value: number | null;
      error: boolean;
    };
    contract_install_price?: {
      value: number | null;
      error: boolean;
    };
    contract_validity?: {
      value: number | null;
      error: boolean;
    };
    additional_terms?: {
      value: string | null;
      error: boolean;
    };
    additional_recurring_name?: {
      value: string | null;
      error: boolean;
    };
    additional_recurring_price?: {
      value: number | null;
      error: boolean;
    };
    ap_connects_to?: {
      value: string | null;
      error: boolean;
    };
    private_ip?: {
      value: string | null;
      error: boolean;
    };
    private_ip_cidr?: {
      value: string | null;
      error: boolean;
    };
    private_gateway?: {
      value: string | null;
      error: boolean;
    };
    public_ip?: {
      value: string | null;
      error: boolean;
    };
    public_ip_cidr?: {
      value: string | null;
      error: boolean;
    };

    removed_reason?: string;
    removed_reason_details?: string;
    time_zone?: string;
    packages: ProposalPackageItem[];
    terms: ProposalTermItem[];
  };

  clientsForm: {
    source_id?: string;

    survey_date?: string;
    survey_time_from?: string;
    survey_time_to?: string;
    survey_datetime_from?: string;
    survey_datetime_to?: string;

    installation_date?: string;
    installation_time_from?: string;
    installation_time_to?: string;
    installation_datetime_from?: string;
    installation_datetime_to?: string;

    next_follow_up_date?: string;
    next_follow_up_time?: string;
    next_follow_up_datetime?: string;

    contract_download_bandwidth?: {
      value: number | null;
      error: boolean;
    };
    contract_upload_bandwidth?: {
      value: number | null;
      error: boolean;
    };
    contract_price?: {
      value: number | null;
      error: boolean;
    };
    contract_install_price?: {
      value: number | null;
      error: boolean;
    };
    contract_validity?: {
      value: number | null;
      error: boolean;
    };
    additional_terms?: {
      value: string | null;
      error: boolean;
    };
    additional_recurring_name?: {
      value: string | null;
      error: boolean;
    };
    additional_recurring_price?: {
      value: number | null;
      error: boolean;
    };
    ap_connects_to?: {
      value: string | null;
      error: boolean;
    };
    private_ip?: {
      value: string | null;
      error: boolean;
    };
    private_ip_cidr?: {
      value: string | null;
      error: boolean;
    };
    private_gateway?: {
      value: string | null;
      error: boolean;
    };
    public_ip?: {
      value: string | null;
      error: boolean;
    };
    public_ip_cidr?: {
      value: string | null;
      error: boolean;
    };

    removed_reason?: string;
    removed_reason_details?: string;
    time_zone?: string;
    packages: ProposalPackageItem[];
    terms: ProposalTermItem[];
  };

  taskEditForm: {
    priority?: '0' | '1' | '2' | '3' | null;
    dueDate?: string | null;
    dueTime?: string | null;
    assignedTo?: string | null;
    taskTypes?: string[];
    childTasks?: TaskItem[];
  };

  globalSearch: string;

  isReportGlobalReload?: boolean;
}

const initialState: UiState = {
  confirmationDialog: {
    open: false,
    type: 'info',
    cancelType: 'info',
    textCenter: 'left',
    title: '',
    message: '',
    hasCancel: true,
    cancelLabel: '',
    confirmLabel: '',
    onClickCancel: undefined,
    onClickConfirm: undefined,
  },
  leadsExpanded: false,
  jobsExpanded: false,
  showLostLeads: false,
  showDeactivatedClients: false,
  jobListType: 'regular',

  leadsForm: {
    source_id: undefined,

    survey_date: undefined,
    survey_time_from: undefined,
    survey_time_to: undefined,
    survey_datetime_from: undefined,
    survey_datetime_to: undefined,

    installation_date: undefined,
    installation_time_from: undefined,
    installation_time_to: undefined,
    installation_datetime_from: undefined,
    installation_datetime_to: undefined,

    next_follow_up_date: undefined,
    next_follow_up_time: undefined,
    next_follow_up_datetime: undefined,

    contract_download_bandwidth: {
      value: null,
      error: false,
    },
    contract_upload_bandwidth: {
      value: null,
      error: false,
    },
    contract_price: {
      value: null,
      error: false,
    },
    contract_install_price: {
      value: null,
      error: false,
    },
    contract_validity: {
      value: null,
      error: false,
    },
    additional_terms: {
      value: null,
      error: false,
    },
    additional_recurring_name: {
      value: null,
      error: false,
    },
    additional_recurring_price: {
      value: null,
      error: false,
    },
    ap_connects_to: undefined,
    private_ip: undefined,
    private_ip_cidr: undefined,
    private_gateway: undefined,
    public_ip: undefined,
    public_ip_cidr: undefined,
    removed_reason: undefined,
    removed_reason_details: undefined,
    time_zone: undefined,
    packages: [],
    terms: [],
  },

  clientsForm: {
    source_id: undefined,

    survey_date: undefined,
    survey_time_from: undefined,
    survey_time_to: undefined,
    survey_datetime_from: undefined,
    survey_datetime_to: undefined,

    installation_date: undefined,
    installation_time_from: undefined,
    installation_time_to: undefined,
    installation_datetime_from: undefined,
    installation_datetime_to: undefined,

    next_follow_up_date: undefined,
    next_follow_up_time: undefined,
    next_follow_up_datetime: undefined,

    contract_download_bandwidth: {
      value: null,
      error: false,
    },
    contract_upload_bandwidth: {
      value: null,
      error: false,
    },
    contract_price: {
      value: null,
      error: false,
    },
    contract_install_price: {
      value: null,
      error: false,
    },
    contract_validity: {
      value: null,
      error: false,
    },
    additional_terms: {
      value: null,
      error: false,
    },
    additional_recurring_name: {
      value: null,
      error: false,
    },
    additional_recurring_price: {
      value: null,
      error: false,
    },
    ap_connects_to: undefined,
    private_ip: undefined,
    private_ip_cidr: undefined,
    private_gateway: undefined,
    public_ip: undefined,
    public_ip_cidr: undefined,
    removed_reason: undefined,
    removed_reason_details: undefined,
    time_zone: undefined,
    packages: [],
    terms: [],
  },

  taskEditForm: {
    assignedTo: null,
    dueDate: null,
    dueTime: null,
    taskTypes: [],
    priority: null,
    childTasks: [],
  },

  globalSearch: '',
  isReportGlobalReload: false,
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    confirmDialog: (state, action: PayloadAction<Partial<UiState['confirmationDialog']>>) => {
      return {
        ...state,
        confirmationDialog: {
          ...state.confirmationDialog,
          ...action?.payload,
        },
      };
    },
    updateLeadsExpanded: (state, action: PayloadAction<Partial<UiState['leadsExpanded']>>) => {
      return {
        ...state,
        leadsExpanded: action?.payload,
      };
    },
    showLostLeads: (state, action: PayloadAction<UiState['showLostLeads']>) => {
      return {
        ...state,
        showLostLeads: action?.payload,
      };
    },
    showDeactivatedClients: (state, action: PayloadAction<UiState['showDeactivatedClients']>) => {
      return {
        ...state,
        showDeactivatedClients: action?.payload,
      };
    },
    changeJobListType: (state, action: PayloadAction<UiState['jobListType']>) => {
      return {
        ...state,
        jobListType: action?.payload,
      };
    },
    leadsFormData: (state, action: PayloadAction<Partial<UiState['leadsForm']>>) => {
      return {
        ...state,
        leadsForm: {
          ...state.leadsForm,
          ...action?.payload,
        },
      };
    },

    clientsFormData: (state, action: PayloadAction<Partial<UiState['clientsForm']>>) => {
      return {
        ...state,
        clientsForm: {
          ...state.clientsForm,
          ...action?.payload,
        },
      };
    },

    updateTaskEditFormData: (state, action: PayloadAction<Partial<UiState['taskEditForm']>>) => {
      return {
        ...state,
        taskEditForm: {
          ...state.taskEditForm,
          ...action?.payload,
        },
      };
    },

    updateGlobalSearchValue: (state, action) => {
      return {
        ...state,
        globalSearch: action?.payload,
      };
    },

    updateReportGlobalReload: (state, action) => {
      return {
        ...state,
        isReportGlobalReload: action?.payload,
      };
    },
  },
});

export const selectUiState = (state: AppState) => state.ui;
export const {
  updateReportGlobalReload,
  confirmDialog,
  updateLeadsExpanded,
  showLostLeads,
  showDeactivatedClients,
  leadsFormData,
  clientsFormData,
  updateTaskEditFormData,
  updateGlobalSearchValue,
  changeJobListType,
} = uiSlice.actions;
export { initialState as UiInitialState };
export default uiSlice.reducer;
