import React from 'react';
import { SettingsLayout } from '@/components/layouts';
import { SettingsCard } from '@/components/organisms/SettingsCard';
import { SMTPSettings } from '@/components/molecules/Settings/Email/SMTPSettings';
import { EmailTemplates } from '@/components/molecules/Settings/Email/EmailTemplates';
import { CustomVariables } from '@/components/molecules/Settings/Email/CustomVariables';
import { PrivateLayout } from '@/components/Middleware';

const Email = () => {
  return (
    <>
      <PrivateLayout>
        <SettingsLayout hideSettingsTabPanel={true} hideHeaderRightSide={false} containerSize={'md'}>
          <SettingsCard title={'Email Settings & Templates'}>
            <SMTPSettings />
            <EmailTemplates />
            <CustomVariables />
          </SettingsCard>
        </SettingsLayout>
      </PrivateLayout>
    </>
  );
};

export { Email };
