import { css } from '@emotion/css';
import { colors, themeParser, useAppTheme } from '@/theme';

export const useGeneralSettingStyle = () => {
  const theme = useAppTheme();

  return {
    root: css`
      position: relative;
      margin-bottom: 45px;
    `,
    cardHeader: css`
      color: #2196f3;
      border-bottom: 1px solid #2196f3;
      padding-bottom: 10px;
      margin: 30px 20px 20px 20px;
      display: flex;
      justify-content: space-between;
      button {
        color: ${themeParser(colors.white.default, colors.black.default, theme)};
        padding: 4px 15px !important;
        @media (max-width: 480px) {
          padding: 4px 5px !important;
        }
      }
    `,
    itemForm: css`
      display: flex;
      align-items: end;
      padding: 5px;
    `,
    textLabel: css`
      color: ${themeParser(colors.textInput.labelColor.light, colors.textInput.labelColor.dark, theme)} !important;
      text-align: left;
      font-size: 14px !important;
    `,
    Icon: css`
      margin: 0px 10px;
      color: ${colors.primary};
      cursor: pointer;
      font-size: 22px !important;
    `,
    container: css`
      width: 95% !important;
      align-items: center;
      margin: 0px 0px 0px 0px !important;
      @media (max-width: 600px) {
        width: auto !important;
        display: block !important;
      }
    `,
    success: css`
      color: green;
    `,
    error: css`
      color: red;
    `,
  };
};
