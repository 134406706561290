import { useReportStyles } from '@/static/stylesheets/molecules';
import classNames from 'classnames';
import React from 'react';

interface ReportTableProps {
  children?: React.ReactNode;
  marginTop?: number | string;
  marginRight?: number | string;
  marginBottom?: number | string;
  marginLeft?: number | string;
  borderTopWidth?: number | string;
  borderBottomWidth?: number | string;
  tableContainerClass?: string;
}

interface TrProps {
  children?: React.ReactNode;
}

interface ThProps {
  children?: React.ReactNode;
  colSpan?: number;
  rowSpan?: number;
  align?: 'left' | 'center' | 'right';
  width?: number | string;
}

interface TdProps {
  children?: React.ReactNode;
  colSpan?: number;
  rowSpan?: number;
  align?: 'left' | 'center' | 'right';
  width?: number | string;
}

interface TbodyProps {
  children?: React.ReactNode;
}

interface TheadProps {
  children?: React.ReactNode;
}

interface TfootProps {
  children?: React.ReactNode;
}

const ReportTable: React.FC<ReportTableProps> = (props) => {
  const classes = useReportStyles();

  return (
    <div className={`${classes.reportsTable} ${props.tableContainerClass}`}>
      <table
        cellPadding={0}
        cellSpacing={0}
        border={1}
        style={{
          marginTop: props?.marginTop,
          marginLeft: props?.marginLeft,
          marginRight: props?.marginRight,
          marginBottom: props?.marginBottom,
          borderTopWidth: props?.borderTopWidth,
        }}
      >
        {props?.children}
      </table>
    </div>
  );
};

const Tr: React.FC<TrProps> = ({ children }) => <tr>{children}</tr>;

const Th: React.FC<ThProps> = ({ children, align, width, ...props }) => (
  <th
    className={classNames({
      left: align === 'left',
      center: align === 'center',
      right: align === 'right',
    })}
    style={{
      width,
    }}
    {...props}
  >
    {children}
  </th>
);

const Td: React.FC<TdProps> = ({ children, width, align, ...props }) => (
  <td
    className={classNames({
      left: align === 'left',
      center: align === 'center',
      right: align === 'right',
    })}
    style={{
      width,
    }}
    {...props}
  >
    {children}
  </td>
);

const Thead: React.FC<TheadProps> = ({ children }) => <thead>{children}</thead>;

const Tbody: React.FC<TbodyProps> = ({ children }) => <tbody>{children}</tbody>;

const Tfoot: React.FC<TfootProps> = ({ children }) => <tfoot>{children}</tfoot>;

export { ReportTable, Tbody, Td, Tfoot, Th, Thead, Tr };
