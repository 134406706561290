import * as React from 'react';
import { Input } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { useMultiSelectStyles } from '@/static/stylesheets/atoms';
import { useAppTheme } from '@/theme';
import Radio from '@mui/material/Radio';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

interface MenuProps {
  label: string;
  value: any;
  sign?: any;
  disabled?: boolean;
}

interface MultiSelectProps {
  options?: MenuProps[];
  value?: MenuProps['value'][];

  onChange?(items: MenuProps['value'][]): void;

  fullWidth?: boolean;
  label?: string;
  type?: string;
  sign?: any;
  error?: boolean;
  multiple?: boolean;
  errorText?: string | null;
  rootSx?: SxProps<Theme>;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MultiSelect: React.FC<MultiSelectProps> = (props) => {
  const classes = useMultiSelectStyles();
  const theme = useAppTheme();
  const { options, value, type, onChange, label, fullWidth, error, errorText, multiple, rootSx } = props;

  const getMappedLabels = () => {
    const labels: MenuProps['label'][] = [];

    if (options && options?.length > 0 && value && value.length > 0) {
      value?.forEach((itemValue) => {
        const index = options?.findIndex((o) => o?.value === itemValue);

        if (index > -1) {
          const item = options[index];
          labels.push(item?.label);
        }
      });
    }

    return labels;
  };

  const handleChange = (targetValue: MenuProps['value']) => {
    let values = value && value?.length > 0 ? [...value] : [];

    if (targetValue) {
      const index = values.indexOf(targetValue);
      if (index === -1) {
        if (!multiple) {
          values = [];
        }
        values.push(targetValue);
      } else {
        values.splice(index, 1);
      }

      if (onChange) {
        onChange(values);
      }
    }
  };

  const mappedLabels = getMappedLabels();

  return (
    <div>
      <FormControl
        sx={{
          m: 1,
          width: '100%',
          ...rootSx,
        }}
        className={classes.root}
        fullWidth={fullWidth}
      >
        {label && <InputLabel className={classes.label}>{label}</InputLabel>}

        <Select
          multiple={multiple}
          native={false}
          value={mappedLabels}
          input={<Input />}
          MenuProps={MenuProps}
          size={'small'}
          fullWidth={fullWidth}
          renderValue={(selected) => {
            return selected.join(', ');
          }}
        >
          {options &&
            options?.length > 0 &&
            options?.map((option, index) => (
              <MenuItem
                key={index}
                value={option?.value}
                dense={true}
                onClick={() => handleChange(option?.value)}
                disabled={option?.disabled}
              >
                {!option?.disabled && (
                  <>
                    {type !== 'radio' ? (
                      <Checkbox checked={value && value?.length > 0 && value.indexOf(option?.value) > -1} />
                    ) : (
                      <Radio checked={value && value?.length > 0 && value.indexOf(option?.value) > -1} />
                    )}
                  </>
                )}

                <ListItemText
                  primary={option?.label}
                  className={type === 'indicator' ? 'indicatorBox ' + option?.sign : ''}
                />
              </MenuItem>
            ))}
        </Select>
        {error && errorText && <span className='error'>{errorText}</span>}
      </FormControl>
    </div>
  );
};

export { MultiSelect };
