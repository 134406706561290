import React, { useEffect } from 'react';
import { FeatureItemList } from '@/components/organisms/FeatureItemList';
import { useAppSelector } from '@/hooks';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import {
  clearDeletedJobProgressItem,
  clearSavedJobProgressItem,
  deleteJobProgressItem,
  getJobProgressItemList,
  getProgressBarTypes,
  openConfirmationDialog,
  saveJobProgressItem,
} from '@/redux/actions';
import toast from 'react-hot-toast';
import { JobProgressBarTypeParams, JobProgressItemParams } from '@/types';
import { alertMessages } from '@/config';

const JobProgressBarList = () => {
  const jobProgressBarTypes = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_PROGRESS_BAR_TYPES];
  const jobProgressBarList = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_PROGRESS_LIST];
  const savedJobProgressItemItem = useAppSelector(selectHttpState)[CORRELATION_IDS.SAVE_JOB_PROGRESS_ITEM];
  const deletedJobProgressItem = useAppSelector(selectHttpState)[CORRELATION_IDS.DELETE_JOB_PROGRESS_ITEM];
  const itemList: JobProgressItemParams[] = jobProgressBarList?.data?.data ?? [];
  const jobProgressBarTypeList: JobProgressBarTypeParams[] = jobProgressBarTypes?.data?.data ?? [];

  const getJobProgressBarTypeId = () => {
    let id = null;
    const index = jobProgressBarTypeList?.findIndex((pbt) => pbt?.progress_type_name === 'Job');
    if (index > -1) {
      id = jobProgressBarTypeList[index]?.id;
    }

    return id;
  };

  const progress_type_id = getJobProgressBarTypeId();

  const fetchJobProgressBarTypes = () => getProgressBarTypes();
  const fetchItems = () =>
    getJobProgressItemList({
      progressType: 'job',
    });

  const createItem = (name: string) => {
    if (name?.trim() === '') {
      toast.error('Please enter title!');
      return;
    }

    saveJobProgressItem({
      progress_type_id,
      progress_name: name,
    });
  };

  const deleteItem = (id: JobProgressItemParams['id']) => {
    openConfirmationDialog({
      title: 'Delete',
      message: alertMessages.generalSettings.jobProgressBars.deleteConfirmation,
      type: 'error',
      textCenter: 'center',
      onClickConfirm() {
        if (id) {
          deleteJobProgressItem(id);
        }
      },
    });
  };

  useEffect(() => {
    if (savedJobProgressItemItem?.success) {
      clearSavedJobProgressItem();
      fetchItems();
      toast.success(alertMessages.generalSettings.jobProgressBars.saveSuccess);
    }

    if (savedJobProgressItemItem?.error) {
      clearSavedJobProgressItem();
      toast.error(alertMessages.generalSettings.jobProgressBars.saveError);
    }
  }, [savedJobProgressItemItem]);

  useEffect(() => {
    if (deletedJobProgressItem?.success) {
      clearDeletedJobProgressItem();
      fetchItems();
      toast.success(alertMessages.generalSettings.jobProgressBars.deleteSuccess);
    }

    if (deletedJobProgressItem?.error) {
      clearDeletedJobProgressItem();
      toast.error(alertMessages.generalSettings.jobProgressBars.deleteError);
    }
  }, [deletedJobProgressItem]);

  useEffect(() => {
    fetchJobProgressBarTypes();
    fetchItems();
  }, []);

  return (
    <>
      <FeatureItemList
        title='Job Progress Bar'
        items={itemList?.map((item) => ({
          title: item?.progress_name,
          onClickRemove() {
            deleteItem(item?.id);
          },
        }))}
        onNewInput={(value) => createItem(value)}
        loading={
          jobProgressBarTypes?.loading ||
          jobProgressBarList?.loading ||
          savedJobProgressItemItem?.loading ||
          deletedJobProgressItem?.loading
        }
        placeholder={'Enter Job Progressbar title'}
        noDelete={false}
      />
    </>
  );
};

export { JobProgressBarList };
