import React, { useEffect, useState } from 'react';
import { SingleClient } from '@/components/molecules/Clients/SingleClient';
import { useAppSelector, useThrottle } from '@/hooks';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { getClients, getClientStaffMembers, toggleDeactivatedClients } from '@/redux/actions';
import { ClientEmptyPlaceholder, ClientLoader } from '@/components/organisms/Clients';
import { Pagination } from '@/components/atoms/Pagination';
import { useClientStyles } from '@/static/stylesheets/organisms';
import { ClientItem } from '@/types';
import { selectUiState } from '@/redux/reducers/uiSlice';
import { convertToNumber } from '@/utils';
import { SingleLead } from '@/components/molecules/Leads/SingleLead';

interface ClientListProps {}

const ClientList: React.FC<ClientListProps> = () => {
  const classes = useClientStyles();

  const clientListResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_CLIENT_LIST];
  const clientListFilterResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.SEND_CLIENT_FILTER];
  const clients: ClientItem[] = clientListFilterResponse?.data
    ? clientListFilterResponse?.data?.data ?? []
    : clientListResponse?.data?.data ?? [];
  const totalPages = convertToNumber(
    clientListFilterResponse?.data ? clientListFilterResponse?.data?.last_page : clientListResponse?.data?.last_page,
  );
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  // const createdClient = useAppSelector(selectHttpState)[CORRELATION_IDS.CREATE_CLIENT];
  // const updatedClient = useAppSelector(selectHttpState)[CORRELATION_IDS.UPDATE_CLIENT];
  const createdClient = useAppSelector(selectHttpState)[CORRELATION_IDS.CREATE_LEAD];
  const updatedClient = useAppSelector(selectHttpState)[CORRELATION_IDS.UPDATE_LEAD];

  const { showDeactivatedClients, globalSearch } = useAppSelector(selectUiState);

  const [initialBoot, setInitialBoot] = useState(true);

  const clientSearchQuery = useThrottle(globalSearch, 1000);

  const getStaffMemberList = () =>
    getClientStaffMembers({
      limit: 'all',
      page: 1,
    });

  const getClientList = () =>
    getClients({
      limit,
      page: currentPage,
      type:
        !clientSearchQuery || clientSearchQuery?.trim() === ''
          ? showDeactivatedClients
            ? 'deactivatedClient'
            : 'clients'
          : undefined,
      s: clientSearchQuery ?? undefined,
    });

  const Loader = () => {
    return (
      <>
        {new Array(limit).fill('').map((item, index) => (
          <ClientLoader key={index} />
        ))}
      </>
    );
  };

  useEffect(() => {
    if (updatedClient && updatedClient?.success) {
      getClientList();
    }
  }, [updatedClient]);

  useEffect(() => {
    if (createdClient && createdClient?.success) {
      getClientList();
    }
  }, [createdClient]);

  useEffect(() => {
    getStaffMemberList();
  }, []);

  useEffect(() => {
    getClientList();
    setInitialBoot(false);
  }, [currentPage]);

  useEffect(() => {
    if (!initialBoot) {
      getClientList();
    }
  }, [clientSearchQuery]);

  useEffect(() => {
    return () => {
      toggleDeactivatedClients(false);
    };
  }, []);

  return (
    <>
      {clientListResponse?.loading ? (
        <Loader />
      ) : (
        <>
          {clients &&
            clients?.length > 0 &&
            clients?.map((item, index) => (
              <React.Fragment key={index}>
                {(item?.customer?.customer_type_name === 'Lead' ||
                  item?.customer?.customer_type_name === 'Lost Lead') && (
                  <SingleLead
                    data={item}
                    reloadList={getClientList}
                    isLostLead={item?.customer?.customer_type_name === 'Lost Lead'}
                  />
                )}

                {(item?.customer?.customer_type_name === 'Clients' ||
                  item?.customer?.customer_type_name === 'Deactivated Client') && (
                  <SingleClient
                    data={item}
                    reloadList={getClientList}
                    isDeactivatedClient={item?.customer?.customer_type_name === 'Deactivated Client'}
                  />
                )}
              </React.Fragment>
            ))}

          {clientListResponse?.success && clients.length === 0 && <ClientEmptyPlaceholder />}
          {totalPages > 1 && (
            <div className={classes.pagination}>
              <Pagination count={totalPages} currentPage={currentPage} onChange={(value) => setCurrentPage(value)} />
            </div>
          )}
        </>
      )}
    </>
  );
};

export { ClientList };
