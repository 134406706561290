import { endpoints } from '@/api/endpoints';
import { performHttpCall } from '../httpSlice';
import { CORRELATION_IDS } from '../correlationIds';
import { EquipmentFilterParams, EquipmentOrdersParams } from '@/types';

export const getEquipmentOrdersCategoryHttpCall = () =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_EQUIPMENT_ORDERS_CATEGORY,
    payload: {
      url: endpoints.private.getEquipmentOrdersCategory,
      method: 'get',
      useBearerToken: true,
    },
  });

export const createEquipmentOrdersHttpCall = (data: EquipmentOrdersParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.CREATE_EQUIPMENT_ORDERS,
    payload: {
      url: endpoints.private.createEquipmentOrders,
      method: 'post',
      useBearerToken: true,
      body: data,
    },
  });

export const getEquipmentOrdersOpenHttpCall = (params: EquipmentFilterParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_EQUIPMENT_ORDERS_OPEN,
    payload: {
      url: endpoints.private.getEquipmentOrdersOpen(params),
      method: 'get',
      useBearerToken: true,
    },
  });

export const getEquipmentOrdersCompletedHttpCall = (params: EquipmentFilterParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_EQUIPMENT_ORDERS_COMPLETED,
    payload: {
      url: endpoints.private.getEquipmentOrdersCompleted(params),
      method: 'get',
      useBearerToken: true,
    },
  });

export const getEquipmentOrdersCancelledHttpCall = (params: EquipmentFilterParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_EQUIPMENT_ORDERS_CANCELLED,
    payload: {
      url: endpoints.private.getEquipmentOrdersCancelled(params),
      method: 'get',
      useBearerToken: true,
    },
  });

export const updateEquipmentOrdersHttpCall = (id: EquipmentOrdersParams['id'], data: EquipmentOrdersParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.UPDATE_EQUIPMENT_ORDERS,
    payload: {
      url: endpoints.private.updateEquipmentOrders(id ?? ''),
      method: 'patch',
      useBearerToken: true,
      body: data,
    },
  });
