import React, { useEffect } from 'react';
import { useIpAddressStyles } from '@/static/stylesheets/molecules/IP';
import classNames from 'classnames';
import { useAppSelector } from '@/hooks';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { IpListParams } from '@/types';

interface IpAddressHorizontalContainerProps {
  selectedIpType: 'public' | 'private';
  selectedParentIpId: string | null;
  onChangeSelectedParentIpId(id: string): void;
}

interface TabItemProps {
  active?: boolean;
  title?: string | null;
  onClick?(): void;
}

const TabItem: React.FC<TabItemProps> = (props) => (
  <div
    className={classNames({
      'tab-item': true,
      active: props.active,
    })}
    onClick={props.onClick}
  >
    {props.title}
  </div>
);

const IpAddressHorizontalContainer: React.FC<IpAddressHorizontalContainerProps> = (props) => {
  const { selectedIpType, selectedParentIpId, onChangeSelectedParentIpId } = props;
  const classes = useIpAddressStyles();

  const fetchIpList = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_IP_LIST];
  const ipList: any = fetchIpList?.data?.data ?? [];

  // const searchIpList = useAppSelector(selectHttpState)[CORRELATION_IDS.SEARCH_IP_DETAILS];
  // const getSearchIpList: any = searchIpList?.data?.data ?? [];

  // const [selectedId, setSelectedId] = useState<string>('');
  // const changeSelectedId = (item: any) => {
  //   setSelectedId(item?.id);
  // };

  // useEffect(() => {
  //   if(!selectedId) {
  //     onChangeSelectedParentIpId(selectedId);
  //   }
  // }, [selectedId]);

  const checkPrentIdExists = () => {
    let exists = false;
    const data = ipList ?? [];

    const index = data?.findIndex((item: any) => item?.id === selectedParentIpId);
    if (index > -1) {
      exists = true;
    }

    return exists;
  };

  const parentIdExists = checkPrentIdExists();

  useEffect(() => {
    if (!selectedParentIpId && fetchIpList && ipList && ipList?.length > 0) {
      onChangeSelectedParentIpId(ipList[0]?.id);
    }
  }, [fetchIpList, selectedParentIpId]);

  useEffect(() => {
    if (!parentIdExists) {
      onChangeSelectedParentIpId(ipList[0]?.id);
    }
  }, [parentIdExists]);

  return (
    <>
      <div className={classes.ipHorizontalList}>
        {ipList &&
          ipList?.length > 0 &&
          ipList?.map((item: IpListParams, index: number) => (
            <TabItem
              title={item?.ip}
              active={selectedParentIpId === item?.id}
              key={index}
              onClick={() => {
                if (item?.id) {
                  onChangeSelectedParentIpId(item?.id);
                }
              }}
            />
          ))}
      </div>
    </>
  );
};

export { IpAddressHorizontalContainer };
