import React, { useEffect, useState } from 'react';
import { LeadColumn, TrackerTable } from '@/components/organisms/Leads';
import { ProgressItem } from '@/types';
import { useAppSelector } from '@/hooks';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { Box, Skeleton } from '@mui/material';

interface LeadTrackerColumnsProps {
  isLostLead?: boolean;
  leadsProgressItems?: ProgressItem[];
  leadsId?: string;
  leadData?: any;

  reloadList?(): void;
  reloadActivityLogList?(): void;
}

const LeadTrackerColumns: React.FC<LeadTrackerColumnsProps> = (props) => {
  const { isLostLead } = props;
  const [columnUpdateObject, setColumnUpdateObject] = useState<{
    updating?: boolean;
    targetId: any;
  }>({
    updating: false,
    targetId: null,
  });
  const [oldProgressBarList, setOldProgressBarList] = useState<string[]>([]);

  const jobProgressList = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_PROGRESS_LIST];
  const jobProgressItems: ProgressItem[] = jobProgressList?.data?.data ?? [];

  const isColumnActive = (id: ProgressItem['id']) => {
    const activeItems: ProgressItem[] = props.leadsProgressItems ?? [];
    const index = activeItems?.findIndex((ai) => ai?.id === id);

    return index > -1;
  };

  useEffect(() => {
    if (props?.leadsProgressItems) {
      const progressItems: string[] = [];
      props?.leadsProgressItems?.forEach((item: ProgressItem) => {
        if (item?.id) {
          progressItems?.push(item?.id);
        }
      });

      setOldProgressBarList(() => progressItems);
    }
  }, [props?.leadsProgressItems]);

  return (
    <>
      <Box sx={{ position: 'relative' }}>
        {columnUpdateObject?.updating && (
          <Box
            sx={{
              position: 'absolute',
              top: -12,
              left: 0,
              right: 0,
              zIndex: 9999,
            }}
          >
            <Skeleton
              animation={'wave'}
              sx={{
                height: 57,
                background: 'rgb(172 172 172 / 44%)',
              }}
            />
          </Box>
        )}
        <TrackerTable>
          {jobProgressItems &&
            jobProgressItems?.length > 0 &&
            jobProgressItems?.map((progressItem: any, index: number) => (
              <LeadColumn
                key={index}
                disabled={isLostLead}
                leadId={props?.leadsId}
                jobProgressBars={jobProgressItems}
                progressItem={progressItem}
                statusChangeable={index !== 0}
                active={index === 0 ? true : isColumnActive(progressItem?.id)}
                oldProgressBars={oldProgressBarList}
                onSuccess={(progressBars) => {
                  setOldProgressBarList(() => progressBars);
                  setTimeout(() => {
                    if (props?.reloadActivityLogList) {
                      props?.reloadActivityLogList();
                    }
                  }, 1000);
                }}
                leadData={props.leadData}
                onUpdateStart={(targetId) =>
                  setColumnUpdateObject(() => ({
                    updating: true,
                    targetId,
                  }))
                }
                onUpdateFinished={() =>
                  setTimeout(() => {
                    setColumnUpdateObject(() => ({
                      updating: false,
                      targetId: null,
                    }));
                  }, 200)
                }
                reloadList={props?.reloadList}
              />
            ))}
        </TrackerTable>
      </Box>
    </>
  );
};

export { LeadTrackerColumns };
