import { ContentWrapper } from '@/components/organisms/Reports';
import { ReportTable, Tbody, Td, Tfoot, Th, Tr } from '@/components/organisms/Reports/ReportContent/ReportTable';
import { useAppSelector } from '@/hooks';
import { getProfitAndLossReportAction } from '@/redux/actions';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { formatCurrency } from '@/utils';
import { useEffect } from 'react';

const TechWorkProfitLoss = () => {
  const result = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_PROFIT_AND_LOSS_REPORT];

  useEffect(() => {
    getProfitAndLossReportAction({
      profit_loss_report_type: 'install_only',
      install_type: 'job_install',
    });
  }, []);

  return (
    <>
      <ContentWrapper
        headings={[
          'Tech Work | Profit & Loss',
          "Jobs with at least a status of 'Installed' will be included in this report.",
        ]}
      >
        {result && result.data && (
          <>
            <ReportTable tableContainerClass='no-border-bottom' marginBottom={'20px'}>
              <Tbody>
                <Tr>
                  <Th align={'center'} width={'339px'}>
                    Total Tech Jobs
                  </Th>
                  <Th align={'center'}>Total Client Paid</Th>
                  <Th align={'center'}>Our Cost</Th>
                  <Th align={'center'}>Average Net Profit</Th>
                  <Th align={'center'}>Total Net Profit</Th>
                  <Th align={'center'}>Profit Margin</Th>
                </Tr>
                <Tr>
                  <Td align={'center'}>{result.data.installation_profit.total_install}</Td>
                  <Td align={'center'}>{'$' + result.data.installation_profit.total_client_paid}</Td>
                  <Td align={'center'}>{formatCurrency(result.data.installation_profit.our_cost)}</Td>
                  <Td align={'center'}>{'$' + result.data.installation_profit.average_net_profit}</Td>
                  <Td align={'center'}>{'$' + result.data.installation_profit.total_net_profit}</Td>
                  <Td align={'center'}>{result.data.installation_profit.profit_margin + '%'}</Td>
                </Tr>
              </Tbody>
            </ReportTable>
            <ReportTable>
              <Tbody>
                <Tr>
                  <Th align={'center'} width={'271px'}></Th>
                  <Th align={'center'} width={'339px'}>
                    Date Installed
                  </Th>
                  <Th align={'center'}>Total Client Paid</Th>
                  <Th align={'center'}>Our Cost</Th>
                  <Th align={'center'}>Net Profit</Th>
                  <Th align={'center'}>Profit Margin</Th>
                </Tr>
                {result.data.customer_wise_breakdown.break_down &&
                  result.data.customer_wise_breakdown.break_down.map((_: any, index: number) => (
                    <Tr key={'tpl' + index}>
                      <Td align={'left'}>{_.customer_name}</Td>
                      <Td align={'center'}>{_.date_installed}</Td>
                      <Td align={'center'}>{formatCurrency(_.total_client_paid)}</Td>
                      <Td align={'center'}>{formatCurrency(_.our_cost)}</Td>
                      <Td align={'center'}>{formatCurrency(_.net_profit)}</Td>
                      <Td align={'center'}>{_.profit_margin + '%'}</Td>
                    </Tr>
                  ))}
              </Tbody>
              <Tfoot>
                <Tr>
                  <Th align={'left'}>Total</Th>
                  <Th align={'center'}>{result.data.customer_wise_breakdown.total.total_install} Tech Jobs</Th>
                  <Th align={'center'}>{'$' + result.data.customer_wise_breakdown.total.total_client_paid}</Th>
                  <Th align={'center'}>{formatCurrency(result.data.customer_wise_breakdown.total.our_cost)}</Th>
                  <Th align={'center'}>{'$' + result.data.customer_wise_breakdown.total.net_profit}</Th>
                  <Th align={'center'}>{result.data.customer_wise_breakdown.total.profit_margin + '%'}</Th>
                </Tr>
              </Tfoot>
            </ReportTable>
          </>
        )}
      </ContentWrapper>
    </>
  );
};

export { TechWorkProfitLoss };
