import { endpoints } from '@/api/endpoints';
import { performHttpCall } from '../httpSlice';
import { CORRELATION_IDS } from '../correlationIds';
import {
  CustomerListFilterParams,
  FileUploaderParams,
  TaskCreateParams,
  TaskFileUploaderParams,
  TaskFilterBodyParams,
  TaskItem,
  TaskListFilterParams,
  TaskTypeListFilterParams,
  UserListFilterParams,
} from '@/types';

export const getTasksHttpCall = (params: TaskListFilterParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_TASKS,
    payload: {
      url: endpoints.private.getTasks(params),
      method: 'get',
      useBearerToken: true,
    },
  });

export const getFilteredTasksHttpCall = (params: TaskFilterBodyParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_FILTERED_TASKS,
    payload: {
      url: endpoints.private.getFilteredTasks(),
      method: 'post',
      useBearerToken: true,
      body: params,
    },
  });

export const getSingleTaskHttpCall = (taskId: TaskItem['id'], relationSuffix?: string) =>
  performHttpCall({
    correlationKey: `${CORRELATION_IDS.GET_SINGLE_TASK}_${taskId}${relationSuffix ?? ''}` as any,
    payload: {
      url: endpoints.private.getSingleTask(taskId),
      method: 'get',
      useBearerToken: true,
    },
  });

export const getTaskStaffMembersDropdownHttpCall = (params: UserListFilterParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_TASK_STAFF_MEMBERS,
    payload: {
      url: endpoints.private.getUsers(params),
      method: 'get',
      useBearerToken: true,
    },
  });

export const getTaskCustomersDropdownHttpCall = (params: CustomerListFilterParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_TASK_CUSTOMERS,
    payload: {
      url: endpoints.private.getCustomers(params),
      method: 'get',
      useBearerToken: true,
    },
  });

export const getTaskTypesDropdownHttpCall = (params: TaskTypeListFilterParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_TASK_TYPES,
    payload: {
      url: endpoints.private.getTaskTypes(params),
      method: 'get',
      useBearerToken: true,
    },
  });

export const changeTaskAssigneeHttpCall = (taskId: TaskItem['id'], assigneeId: string) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.CHANGE_TASK_ASSIGNEE,
    payload: {
      url: endpoints.private.changeTaskAssignee(taskId),
      method: 'patch',
      useBearerToken: true,
      body: {
        assigned_to: assigneeId,
      },
    },
  });

export const markTaskAsCompletedHttpCall = (taskId: TaskItem['id']) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.MARK_TASK_AS_COMPLETED,
    payload: {
      url: endpoints.private.markTaskAsCompleted(taskId),
      method: 'patch',
      useBearerToken: true,
    },
  });

export const changeTaskPriorityHttpCall = (taskId: TaskItem['id'], priority: '0' | '1' | '2' | '3') =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.CHANGE_TASK_PRIORITY,
    payload: {
      url: endpoints.private.changeTaskPriority(taskId),
      method: 'patch',
      useBearerToken: true,
      body: {
        priority,
      },
    },
  });

export const startStopTaskTimerHttpCall = (taskId: TaskItem['id']) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.START_STOP_TASK_TIMER,
    payload: {
      url: endpoints.private.startStopTaskTimer(taskId),
      method: 'patch',
      useBearerToken: true,
    },
  });

export const createTaskHttpCall = (params: TaskCreateParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.CREATE_TASK,
    payload: {
      url: endpoints.private.createTask(),
      method: 'post',
      useBearerToken: true,
      body: params,
    },
  });

export const updateTaskHttpCall = (
  params: TaskCreateParams & {
    id: string;
  },
) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.UPDATE_TASK,
    payload: {
      url: endpoints.private.updateTask(params?.id),
      method: 'put',
      useBearerToken: true,
      body: params,
    },
  });

export const deleteTaskHttpCall = (taskId: TaskItem['id'], deleteSubTaskFromPopup?: boolean) =>
  performHttpCall({
    correlationKey: deleteSubTaskFromPopup ? CORRELATION_IDS.DELETE_SUB_TASK_FROM_POPUP : CORRELATION_IDS.DELETE_TASK,
    payload: {
      url: endpoints.private.deleteTask(taskId),
      method: 'delete',
      useBearerToken: true,
    },
  });

export const taskFileUploadHttpCall = (params: TaskFileUploaderParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.UPLOAD_TASK_FILE,
    payload: {
      url: endpoints.private.uploadTaskFile(params),
      method: 'post',
      useBearerToken: true,
      body: params?.data,
    },
  });
