import { Button } from '@/components/atoms/Button';
import { DatePicker } from '@/components/atoms/DatePicker';
import { MultiSelect } from '@/components/atoms/MultiSelect';
import { SearchableTextInput, TextInput } from '@/components/atoms/TextInput';
import { TimePicker } from '@/components/atoms/TimePicker';
import { EditorInstance, RichTextEditor } from '@/components/organisms/RichTextEditor';
import { alertMessages } from '@/config';
import { useAppSelector, useComponentState, useInput } from '@/hooks';
import {
  clearCreatedTask,
  createTask,
  getTaskCustomers,
  getTaskStaffMembers,
  getTasks,
  openConfirmationDialog,
} from '@/redux/actions';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { useTaskStyles } from '@/static/stylesheets/molecules/Tasks';
import {
  CustomerProfileParams,
  FileUploadResponse,
  TaskCreateParams,
  TaskCreateSingleItem,
  TaskTypeParams,
  UserParams,
} from '@/types';
import { convertToNumber, dateWithTime } from '@/utils';
import { timezone } from '@/utils/timeZone';
import DeleteIcon from '@mui/icons-material/Delete';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
} from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

interface CreateTaskProps {
  open: boolean;
  mode: 'create' | 'edit';
  editData?: any;

  onClose(): void;
}

interface ChildTaskItem {
  title?: string;
  description?: string;
  reminderDate?: string;
  reminderTime?: string;
  dueDate?: string;
  dueTime?: string;
  priority?: '0' | '1' | '2' | '3' | null;
  assignedTo?: string | null;
  estimatedTime?: string | number;
  files?: FileUploadResponse;
}

const CreateTaskDialog: React.FC<CreateTaskProps> = (props) => {
  const classes = useTaskStyles();
  const { open, onClose } = props;
  const [editor, setEditor] = useState<EditorInstance | null>(null);

  const editMode: boolean = props?.mode === 'edit' && props?.editData !== undefined && props?.editData?.id !== '';

  const staffMemberList = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_TASK_STAFF_MEMBERS];
  const staffMembers: UserParams[] = staffMemberList?.data?.data ?? [];

  const taskCreateResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.CREATE_TASK];
  const customerListResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_TASK_CUSTOMERS];
  const customers: CustomerProfileParams[] = customerListResponse?.data?.data ?? [];

  const taskTypeList = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_TASK_TYPES];
  const taskTypes: TaskTypeParams[] = taskTypeList?.data?.data ?? [];

  const {
    state: {
      selectedTaskTypes,
      isPrivate,
      childTasks,
      childTaskFiles,
      selectedChildTaskIndex,
      childTasksValidationErrorIndexList,
    },
    setState,
  } = useComponentState<{
    selectedTaskTypes: string[];
    isPrivate: boolean;
    childTasks: ChildTaskItem[];
    childTaskFiles: ChildTaskItem['files'];
    selectedChildTaskIndex: number;
    childTasksValidationErrorIndexList: number[];
  }>({
    selectedTaskTypes: [],
    isPrivate: false,
    childTasks: [],
    childTaskFiles: [],
    selectedChildTaskIndex: -1,
    childTasksValidationErrorIndexList: [],
  });

  const {
    value: taskTitle,
    setValue: setTaskTitle,
    bind: bindTaskTitle,
    reset: resetTaskTitle,
    error: taskTitleError,
  } = useInput('', {
    required: 'Please enter the task title!',
  });

  const {
    value: assignedTo,
    setValue: setAssignedTo,
    bind: bindAssignedTo,
    error: assignedToError,
    reset: resetAssignedTo,
  } = useInput('', {
    required: 'Select team member!',
  });

  const {
    value: customer,
    setValue: setCustomer,
    bind: bindCustomer,
    error: customerError,
    reset: resetCustomer,
  } = useInput(undefined, {
    required: false,
  });

  const {
    value: priority,
    setValue: setPriority,
    bind: bindPriority,
    error: priorityError,
    reset: resetPriority,
  } = useInput('', {
    required: 'Please select priority!',
  });

  const {
    value: dueDate,
    setValue: setDueDate,
    bind: bindDueDate,
    reset: resetDueDate,
    error: dueDateError,
  } = useInput('', {
    // required: "Please input due date!",
  });

  const {
    value: dueTime,
    setValue: setDueTime,
    bind: bindDueTime,
    reset: resetDueTime,
    error: dueTimeError,
  } = useInput('', {
    // required: "Please input due time!",
  });

  const {
    value: childTaskDescription,
    setValue: setChildTaskDescription,
    reset: resetChildTaskDescription,
    error: childTaskDescriptionError,
  } = useInput('');

  const {
    value: childTaskReminderDate,
    setValue: setChildTaskReminderDate,
    reset: resetChildTaskReminderDate,
    error: childTaskReminderDateError,
  } = useInput('', {
    // required: 'This is required!',
  });

  const {
    value: childTaskReminderTime,
    setValue: setChildTaskReminderTime,
    reset: resetChildTaskReminderTime,
    error: childTaskReminderTimeError,
  } = useInput('', {
    // required: 'This is required!',
  });

  const {
    value: childTaskDueDate,
    setValue: setChildTaskDueDate,
    reset: resetChildTaskDueDate,
    error: childTaskDueDateError,
  } = useInput('', {
    // required: 'This is required!',
  });

  const {
    value: childTaskDueTime,
    setValue: setChildTaskDueTime,
    reset: resetChildTaskDueTime,
    error: childTaskDueTimeError,
  } = useInput('', {
    // required: 'This is required!',
  });

  const {
    value: childTaskPriority,
    setValue: setChildTaskPriority,
    bind: bindChildTaskPriority,
    reset: resetChildTaskPriority,
    error: childTaskPriorityError,
  } = useInput('', {
    // required: 'Please select priority!',
  });

  const {
    value: childTaskAssignedTo,
    setValue: setChildTaskAssignedTo,
    bind: bindChildTaskAssignedTo,
    reset: resetChildTaskAssignedTo,
    error: childTaskAssignedToError,
  } = useInput('', {
    required: 'Please select team member!',
  });

  const {
    value: childTaskEstimatedHours,
    setValue: setChildTaskEstimatedHours,
    bind: bindChildTaskEstimatedHours,
    reset: resetChildTaskEstimatedHours,
    error: childTaskEstimatedHoursError,
  } = useInput('', {
    // validate(value) {
    //   if(convertToString(value).trim() === '') {
    //     return 'Please input est. hours!';
    //   }
    //
    //   if(Number(value) < 0) {
    //     return 'Est. hrs should be greater than 0';
    //   }
    //
    //   return true;
    // }
  });

  const resetFields = () => {
    resetTaskTitle();
    resetAssignedTo();
    resetCustomer();
    resetPriority();
    resetDueDate();
    resetDueTime();

    resetChildTaskDescription();
    resetChildTaskReminderDate();
    resetChildTaskReminderTime();
    resetChildTaskDueDate();
    resetChildTaskDueTime();
    resetChildTaskPriority();
    resetChildTaskAssignedTo();
    resetChildTaskEstimatedHours();

    setState({
      selectedTaskTypes: [],
      isPrivate: false,
      childTasks: [],
      childTaskFiles: [],
      selectedChildTaskIndex: -1,
      childTasksValidationErrorIndexList: [],
    });
  };

  const getStaffMemberList = () =>
    getTaskStaffMembers({
      limit: 'all',
      page: 1,
    });

  const getCustomers = () =>
    getTaskCustomers({
      customer_type: '[Clients,Lead]',
      limit: 'all',
      page: 1,
    });

  const getTaskList = () =>
    getTasks({
      limit: 'all',
      page: 1,
    });

  const addChildTask = () => {
    const newChildTasks = [...childTasks];

    if (newChildTasks?.length === 0) {
      setState({ selectedChildTaskIndex: 0 });
    }

    newChildTasks.push({
      title: '',
      description: '',
      reminderDate: '',
      reminderTime: '',
      dueDate: '',
      dueTime: '',
      priority: null,
      assignedTo: null,
      estimatedTime: '',
      files: [],
    });

    setState({
      childTasks: newChildTasks,
    });
  };

  const deleteChildTask = (index: number) => {
    const newChildTasks = [...childTasks];
    newChildTasks.splice(index, 1);

    setState({
      childTasks: newChildTasks,
    });

    const errorIndex = childTasksValidationErrorIndexList.indexOf(index);
    if (errorIndex > -1) {
      const newErrorIndexList = [...childTasksValidationErrorIndexList];
      newErrorIndexList.splice(errorIndex, 1);
      setState({
        childTasksValidationErrorIndexList: newErrorIndexList,
      });
    }
  };

  const updateChildTaskData = (index: number, key: keyof ChildTaskItem, value: any) => {
    const newChildTasks = [...childTasks];
    if (index > -1 && newChildTasks[index]) {
      newChildTasks[index][key] = value;
      setState({
        childTasks: newChildTasks,
      });
    }
  };

  const selectChildTask = (data: ChildTaskItem, index: number) => {
    setState({
      selectedChildTaskIndex: index,
      childTaskFiles: data?.files,
    });

    editor?.setEditorValue(data?.description);
    setChildTaskDescription(data?.description);
    setChildTaskReminderDate(data?.reminderDate);
    setChildTaskReminderTime(data?.reminderTime);
    setChildTaskDueDate(data?.dueDate);
    setChildTaskDueTime(data?.dueTime);
    setChildTaskPriority(data?.priority);
    setChildTaskAssignedTo(data?.assignedTo);
    setChildTaskEstimatedHours(data?.estimatedTime);
  };

  const childTaskHasValidationError = (index: number) => {
    return childTasksValidationErrorIndexList.indexOf(index) > -1;
  };

  const validateParentTask = () => {
    taskTitleError.check();
    assignedToError.check();
    customerError.check();
    priorityError.check();
    dueDateError.check();
    dueTimeError.check();

    return !(
      taskTitleError.check() ||
      assignedToError.check() ||
      customerError.check() ||
      priorityError.check() ||
      dueDateError.check() ||
      dueTimeError.check()
    );
  };

  const validateChildTaskList = () => {
    const validationErrorIndexList: number[] = [];

    childTasks?.forEach((item, index) => {
      let hasError = false;

      if (
        item?.title === '' ||
        // !item?.reminderDate ||
        // !item?.reminderTime ||
        // !item?.dueDate ||
        // !item?.dueTime ||
        // !item?.priority ||
        item?.assignedTo === ''
        // item?.estimatedTime === ""
      ) {
        hasError = true;

        if (selectedChildTaskIndex === index) {
          childTaskReminderDateError.check();
          childTaskReminderTimeError.check();
          childTaskDueDateError.check();
          childTaskDueTimeError.check();
          childTaskPriorityError.check();
          childTaskAssignedToError.check();
          childTaskEstimatedHoursError.check();
        }
      }

      if (hasError) {
        validationErrorIndexList.push(index);
      }
    });

    setState({
      childTasksValidationErrorIndexList: validationErrorIndexList,
    });

    return validationErrorIndexList?.length === 0;
  };

  const submitJobForm = () => {
    if (validateParentTask() && validateChildTaskList()) {
      const formData: TaskCreateParams = {
        tasks: [],
        timezone: timezone,
      };

      // Parent Task Data
      const parentTaskData: TaskCreateSingleItem = {
        is_parent: true,
        title: taskTitle,
        assigned_to: assignedTo,
        priority_enum: priority,
        due_datetime: dateWithTime(dueDate, dueTime),
        is_private: isPrivate,
      };
      if (selectedTaskTypes?.length > 0) {
        parentTaskData.task_type_id = selectedTaskTypes;
      }
      if (customer) {
        parentTaskData.customer_id = customer;
      }
      formData.tasks.push(parentTaskData);

      // Child Tasks
      childTasks?.forEach((childTask) => {
        const files: string[] = [];

        childTask?.files?.forEach((file) => {
          if (file && file?.id) {
            files.push(file?.id);
          }
        });

        formData.tasks.push({
          is_parent: false,
          title: childTask?.title,
          sub_task_body: childTask?.description,
          assigned_to: childTask?.assignedTo,
          priority_enum: childTask?.priority,
          reminder_datetime: dateWithTime(childTask?.reminderDate, childTask?.reminderTime),
          due_datetime: dateWithTime(childTask?.dueDate, childTask?.dueTime),
          files,
          estimated_hours: convertToNumber(childTask?.estimatedTime),
        });
      });

      createTask(formData);
    }
  };

  const handleClosePopup = (force: boolean = false) => {
    const closePopup = () => {
      if (onClose) {
        resetFields();
        onClose();
      }
    };

    if (force) {
      closePopup();
      return;
    }

    if (
      taskTitle !== '' ||
      assignedTo !== '' ||
      customer !== '' ||
      selectedTaskTypes?.length > 0 ||
      priority !== '' ||
      dueDate !== '' ||
      dueTime !== '' ||
      childTasks?.length > 0
    ) {
      openConfirmationDialog({
        title: 'Discard changes?',
        message: 'Are you sure to close and discard all the changes?',
        type: 'error',
        textCenter: 'center',
        onClickConfirm() {
          closePopup();
        },
      });
    } else {
      closePopup();
    }
  };

  useEffect(() => {
    updateChildTaskData(selectedChildTaskIndex, 'description', childTaskDescription);
  }, [childTaskDescription, selectedChildTaskIndex]);

  useEffect(() => {
    updateChildTaskData(selectedChildTaskIndex, 'reminderDate', childTaskReminderDate);
  }, [childTaskReminderDate, selectedChildTaskIndex]);

  useEffect(() => {
    updateChildTaskData(selectedChildTaskIndex, 'reminderTime', childTaskReminderTime);
  }, [childTaskReminderTime, selectedChildTaskIndex]);

  useEffect(() => {
    updateChildTaskData(selectedChildTaskIndex, 'dueDate', childTaskDueDate);
  }, [childTaskDueDate, selectedChildTaskIndex]);

  useEffect(() => {
    updateChildTaskData(selectedChildTaskIndex, 'dueTime', childTaskDueTime);
  }, [childTaskDueTime, selectedChildTaskIndex]);

  useEffect(() => {
    updateChildTaskData(selectedChildTaskIndex, 'priority', childTaskPriority);
  }, [childTaskPriority, selectedChildTaskIndex]);

  useEffect(() => {
    updateChildTaskData(selectedChildTaskIndex, 'assignedTo', childTaskAssignedTo);
  }, [childTaskAssignedTo, selectedChildTaskIndex]);

  useEffect(() => {
    updateChildTaskData(selectedChildTaskIndex, 'estimatedTime', childTaskEstimatedHours);
  }, [childTaskEstimatedHours, selectedChildTaskIndex]);

  useEffect(() => {
    updateChildTaskData(selectedChildTaskIndex, 'files', childTaskFiles);
  }, [childTaskFiles, selectedChildTaskIndex]);

  useEffect(() => {
    if (childTasks?.length > 0 && !childTasks[selectedChildTaskIndex]) {
      setState({ selectedChildTaskIndex: 0 });
    }
  }, [selectedChildTaskIndex, childTasks]);

  useEffect(() => {
    validateChildTaskList();
  }, [
    childTaskDescription,
    childTaskAssignedTo,
    childTaskReminderDate,
    childTaskReminderTime,
    childTaskDueDate,
    childTaskDueTime,
    childTaskPriority,
    childTaskAssignedTo,
    childTaskEstimatedHours,
  ]);

  useEffect(() => {
    if (taskCreateResponse?.success) {
      toast.success(alertMessages.tasks.taskCreateSuccess);
      getTaskList();
      clearCreatedTask();
      handleClosePopup(true);
    }
  }, [taskCreateResponse?.success]);

  useEffect(() => {
    if (taskCreateResponse?.error) {
      toast.error(alertMessages.tasks.taskCreateError);
      clearCreatedTask();
    }
  }, [taskCreateResponse?.error]);

  useEffect(() => {
    if (open) {
      getStaffMemberList();
      getCustomers();
    }
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        maxWidth={'xl'}
        onClose={() => handleClosePopup()}
        sx={{
          '& .MuiPaper-root:not(.MuiAutocomplete-paper)': {
            width: 1600,
          },
        }}
        className={classes.root}
      >
        <DialogTitle className={classes.createTasksTitleBar}>{`${editMode ? 'Edit' : 'CREATE NEW'} TASK`}</DialogTitle>

        <DialogContent className={classes.DialogContentRoot}>
          <DialogContentText className={classes.DialogContent}>
            <Grid container spacing={1}>
              <Grid item lg={3}>
                <div>
                  <TextInput
                    variant={'standard'}
                    label={'Task Title'}
                    fullWidth={true}
                    error={taskTitleError.error}
                    errorText={taskTitleError.message}
                    {...bindTaskTitle}
                  />
                </div>
                <div>
                  <TextInput
                    select={true}
                    variant={'standard'}
                    options={[
                      {
                        title: 'Assigned To',
                        value: '--empty--',
                        disabled: true,
                      },
                      ...(staffMembers && staffMembers?.length > 0
                        ? staffMembers?.map((item) => ({
                            title: `${item?.first_name ?? ''} ${item?.last_name ?? ''}`,
                            value: item?.id,
                          }))
                        : []),
                    ]}
                    fullWidth={true}
                    label={'Team Member Responsible'}
                    error={assignedToError.error}
                    errorText={assignedToError.message}
                    {...bindAssignedTo}
                  />
                </div>
                <div>
                  <SearchableTextInput
                    label={'Assign to Client/Lead?'}
                    options={[
                      ...(customers && customers?.length > 0
                        ? customers?.map((item) => ({
                            label: `${item?.first_name ?? ''} ${item?.last_name ?? ''}`,
                            value: item?.id,
                          }))
                        : []),
                    ]}
                    onChange={setCustomer}
                    value={customer}
                    error={customerError.error}
                    errorText={customerError.message}
                    loading={false}
                  />
                </div>
                <div>
                  <MultiSelect
                    options={[
                      ...(taskTypes && taskTypes?.length > 0
                        ? taskTypes?.map((item) => ({
                            label: item?.task_type_name ?? '',
                            value: item?.id,
                          }))
                        : []),
                    ]}
                    value={selectedTaskTypes}
                    onChange={(items) => setState({ selectedTaskTypes: items })}
                    label={'Type of task'}
                    multiple={true}
                    rootSx={{
                      marginLeft: 0,
                      '& .MuiInputLabel-root': {
                        marginLeft: '-15px',
                      },
                    }}
                  />
                </div>
                <div>
                  <TextInput
                    select={true}
                    variant={'standard'}
                    options={[
                      {
                        title: 'No Priority',
                        value: '0',
                      },
                      {
                        title: 'Low Priority',
                        value: '1',
                      },
                      {
                        title: 'Medium Priority',
                        value: '2',
                      },
                      {
                        title: 'High Priority',
                        value: '3',
                      },
                    ]}
                    fullWidth={true}
                    label={'Priority'}
                    error={priorityError.error}
                    errorText={priorityError.message}
                    {...bindPriority}
                  />
                </div>
                <div>
                  <Grid container spacing={2}>
                    <Grid item lg={6}>
                      <div className={'custom-input-label'}>
                        <DatePicker
                          centerMode={true}
                          label={'Project Due Date'}
                          value={bindDueDate.value}
                          onChange={(value) => {
                            bindDueDate.onChange({
                              target: {
                                value,
                              },
                            } as any);
                          }}
                          error={dueDateError.error}
                          errorText={dueDateError.message}
                        />
                      </div>
                    </Grid>
                    <Grid item lg={6}>
                      <TimePicker
                        centerMode={true}
                        value={bindDueTime.value}
                        onChange={(value) => {
                          bindDueTime.onChange({
                            target: {
                              value,
                            },
                          } as any);
                        }}
                        error={dueTimeError.error}
                        errorText={dueTimeError.message}
                      />
                    </Grid>
                  </Grid>
                </div>
                <div>
                  <FormGroup
                    sx={{
                      marginTop: '20px',

                      '& .MuiTypography-root': {
                        fontSize: 13,
                      },
                    }}
                  >
                    <FormControlLabel
                      control={<Checkbox checked={isPrivate} onChange={() => setState({ isPrivate: !isPrivate })} />}
                      label={'Mark Task as Private (only you can see it)'}
                    />
                  </FormGroup>
                </div>
              </Grid>

              {childTasks?.length > 0 ? (
                <>
                  <Grid item lg={3}>
                    <div className={classes.subTasksListContainer}>
                      {childTasks &&
                        childTasks?.length > 0 &&
                        childTasks?.map((task, index) => {
                          const hasError = childTaskHasValidationError(index);
                          return (
                            <div
                              key={index}
                              className={classNames({
                                [classes.subTaskFieldContainer]: true,
                                [classes.subTaskFieldContainerActive]: selectedChildTaskIndex === index,
                              })}
                              onClick={() => selectChildTask(task, index)}
                            >
                              <div className={'wrapper'}>
                                <TextInput
                                  variant={'standard'}
                                  placeholder={'Subtask (Short title)'}
                                  fullWidth={true}
                                  value={task?.title}
                                  onChange={(e) => {
                                    updateChildTaskData(index, 'title', e?.target?.value);
                                    validateChildTaskList();
                                  }}
                                  error={hasError && task?.title?.trim() === ''}
                                />
                                <span>
                                  <IconButton
                                    size={'small'}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      deleteChildTask(index);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </span>
                              </div>
                              {hasError && (
                                <div className={'error-container'}>
                                  <WarningIcon /> Please fill all the required fields!
                                </div>
                              )}
                            </div>
                          );
                        })}
                      <div className={classes.subTaskFieldAddButtonContainer}>
                        <Button title={'Add subtask'} fullWidth={true} onClick={addChildTask} />
                      </div>
                    </div>
                  </Grid>

                  <Grid item lg={6} className={classes.selectedTaskBodyContainer}>
                    <div className={'task-detail-container'}>
                      <div>
                        <RichTextEditor
                          placeholder={'Enter Text Here'}
                          onChange={(value) => setChildTaskDescription(value)}
                          onMount={(editor) => setEditor(editor)}
                          height={280}
                        />
                      </div>
                      <div>
                        {/*<FileUploader
                          type={"task"}
                          files={childTaskFiles}
                          onUploadSuccess={(fileList) => {
                            setState({
                              childTaskFiles: fileList,
                            });
                          }}
                        />*/}
                      </div>
                      <div>
                        <Grid container spacing={3}>
                          <Grid item lg={3}>
                            <DatePicker
                              centerMode={true}
                              label={'Reminder Date'}
                              value={childTaskReminderDate}
                              onChange={(value) => {
                                setChildTaskReminderDate(value);
                              }}
                              error={childTaskReminderDateError.error}
                              errorText={childTaskReminderDateError.message}
                            />
                          </Grid>
                          <Grid item lg={3}>
                            <TimePicker
                              centerMode={true}
                              value={childTaskReminderTime}
                              onChange={(value) => {
                                setChildTaskReminderTime(value);
                              }}
                              error={childTaskReminderTimeError.error}
                              errorText={childTaskReminderTimeError.message}
                            />
                          </Grid>
                          <Grid item lg={3}>
                            <DatePicker
                              centerMode={true}
                              label={'Due Date'}
                              value={childTaskDueDate}
                              onChange={(value) => {
                                setChildTaskDueDate(value);
                              }}
                              error={childTaskDueDateError.error}
                              errorText={childTaskDueDateError.message}
                            />
                          </Grid>
                          <Grid item lg={3}>
                            <TimePicker
                              centerMode={true}
                              value={childTaskDueTime}
                              onChange={(value) => {
                                setChildTaskDueTime(value);
                              }}
                              error={childTaskDueTimeError.error}
                              errorText={childTaskDueTimeError.message}
                            />
                          </Grid>
                          <Grid item lg={4}>
                            <TextInput
                              select={true}
                              variant={'standard'}
                              options={[
                                {
                                  title: 'No Priority',
                                  value: '0',
                                },
                                {
                                  title: 'Low Priority',
                                  value: '1',
                                },
                                {
                                  title: 'Medium Priority',
                                  value: '2',
                                },
                                {
                                  title: 'High Priority',
                                  value: '3',
                                },
                              ]}
                              fullWidth={true}
                              label={'Subtask Priority'}
                              error={childTaskPriorityError.error}
                              errorText={childTaskPriorityError.message}
                              {...bindChildTaskPriority}
                            />
                          </Grid>
                          <Grid item lg={4}>
                            <TextInput
                              select={true}
                              variant={'standard'}
                              options={[
                                {
                                  title: 'Assigned To',
                                  value: '--empty--',
                                  disabled: true,
                                },
                                ...(staffMembers && staffMembers?.length > 0
                                  ? staffMembers?.map((item) => ({
                                      title: `${item?.first_name ?? ''} ${item?.last_name ?? ''}`,
                                      value: item?.id,
                                    }))
                                  : []),
                              ]}
                              fullWidth={true}
                              label={'Team Member Assigned'}
                              error={childTaskAssignedToError.error}
                              errorText={childTaskAssignedToError.message}
                              {...bindChildTaskAssignedTo}
                            />
                          </Grid>
                          <Grid item lg={4}>
                            <TextInput
                              type={'number'}
                              variant={'standard'}
                              placeholder={'Est. Time (hours)'}
                              fullWidth={true}
                              label={'Est. Time (hours)'}
                              error={childTaskEstimatedHoursError.error}
                              errorText={childTaskEstimatedHoursError.message}
                              {...bindChildTaskEstimatedHours}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Grid>
                </>
              ) : (
                <Grid item lg={9} style={{ position: 'relative' }}>
                  <div className={classes.noChildTasksContainer}>
                    <div className={'hint'}>Click the below button to add subtasks</div>
                    <div>
                      <Button title={'Add subtask'} fullWidth={true} onClick={addChildTask} />
                    </div>
                  </div>
                </Grid>
              )}
            </Grid>
          </DialogContentText>
        </DialogContent>

        <DialogActions className={classes.createTasksBottomBar}>
          <Button onClick={() => handleClosePopup()} color={'error'} variant={'contained'} title={'CANCEL'} />
          <Button
            color={'primary'}
            variant={'contained'}
            title={`${editMode ? 'SAVE' : 'CREATE'} TASK`}
            onClick={submitJobForm}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export { CreateTaskDialog };
