import { store } from '@/redux/store';
import { LoginParams } from '@/types';
import { CORRELATION_IDS, loginHttpCall, logoutHttpCall } from '@/redux/reducers/http';
import { AuthState, storeAuth } from '@/redux/reducers/authSlice';
import { clearHttpStateObject } from '@/redux/actions';

export function login(params: LoginParams) {
  store.dispatch(loginHttpCall(params));
}

export function logout() {
  store.dispatch(logoutHttpCall());
}

export function clearLoggedOut() {
  clearHttpStateObject(CORRELATION_IDS.LOGOUT);
}

export function storeLoginData(options: Partial<AuthState>) {
  store.dispatch(storeAuth(options));
}
