import { colors, themeParser, useAppTheme } from '@/theme';
import { css } from '@emotion/css';

export const useIpAddressStyles = () => {
  const theme = useAppTheme();
  return {
    root: css``,
    headerRoot: css`
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      padding: 30px 0px;
      color: ${themeParser(colors.body.dark, colors.primary, theme)};
      gap: 15px;

      @media (max-width: 768px) {
        flex-wrap: wrap;
      }

      .action-button-group {
        display: flex;
        flex-direction: row;
        gap: 20px;
      }

      .search-section {
        position: absolute;
        right: 0px;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;

        @media (max-width: 990px) {
          position: relative;
          right: auto;
        }

        input {
          height: 35px;
          border: 0;
          box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
          outline: 0;
          padding: 0 15px;
          background: ${themeParser('transparent', '#3d3d3d', theme)};
          color: ${colors.primary};
          width: 320px;
          border-top-left-radius: 7px;
          border-bottom-left-radius: 7px;
        }
        button {
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
        }
      }
    `,

    ipHorizontalList: css`
      display: flex;
      overflow: auto;
      white-space: nowrap;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      padding: 12px 12px 16px 12px;

      ::-webkit-scrollbar {
        height: 14px;
      }
      ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }

      /* Track */

      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 6px;
      }

      /* Handle */

      ::-webkit-scrollbar-thumb:horizontal {
        background: #9e9898;
        border-radius: 10px;
      }

      ::-webkit-scrollbar-thumb {
        background: #9e9898;
      }

      ::-webkit-scrollbar-thumb:hover {
        background: #2196f3;
      }

      .tab-item {
        padding: 10px 30px;
        color: ${colors.primary};
        cursor: pointer;
      }

      .tab-item.active {
        border-bottom: 3px solid ${colors.primary};
        font-weight: bold;
      }
    `,

    gridSearchContainer: css`
      display: flex;
      justify-content: center;
      gap: 10px;
      align-items: center;
      margin-top: 20px;
      span {
        color: ${themeParser('#000000de', '#cbcbcb', theme)};
      }
      input {
        width: 500px;
        height: 40px;
        outline: 0;
        border: 0;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        padding: 0 15px;
        background: ${themeParser('transparent', '#3d3d3d', theme)};
        color: ${colors.primary};
      }
    `,

    gridContainer: css`
      text-align: center;
      position: relative;
      overflow-x: hidden;
      overflow-y: auto;
      width: 100%;
      height: calc(100vh - 350px);

      ::-webkit-scrollbar {
        width: 5px;
      }
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        -webkit-border-radius: 0px;
        border-radius: 0px;
      }
      ::-webkit-scrollbar-thumb {
        background: #9e9898;
        border-radius: 10px;
      }

      @media (max-width: 920px) {
        .MuiBox-root {
          width: 500px;
        }
      }
      @media (max-width: 600px) {
        .MuiBox-root {
          width: 400px;
        }
        .MuiTableCell-root {
          text-align: left !important;
          div {
            justify-content: left;
          }
        }
      }
      .empty {
        color: ${colors.primary};
      }
      table tr button {
        display: none;
      }
      table tr .input_field {
        background: ${themeParser('#86848426', '#484848', theme)};
        color: ${colors.primary};
        min-width: 300px;
      }

      table tr .ip-desc-error {
        border: 1px solid #f44336;
      }

      table tr .close {
        color: #f44336 !important;
        opacity: 1;
        margin-left: 8px;
      }
      table tr input {
        padding: 6px;
        margin: 5px 0px;
        background: transparent;
        color: ${colors.primary};
        outline: none;
        border: 0;
        width: auto;
      }
      table tr .MuiTableCell-root {
        padding: 0px 15px !important;
      }
      table tr:hover button {
        display: flex;
        padding: 0px;
        cursor: pointer;
        color: #2196f3;
        border: none;
        background: transparent;
        svg {
          font-size: 16px;
        }
      }
    `,
  };
};
