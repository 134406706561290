import { Button } from '@/components/atoms/Button';
import { alertMessages } from '@/config';
import { options } from '@/config/constants';
import { useAppSelector, useInput } from '@/hooks';
import { useJobListType } from '@/hooks/custom';
import { clearUpdateJobStatus, getJobs, updateJobStatus } from '@/redux/actions';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { useModalStyles } from '@/static/stylesheets/organisms';
import { Grid, RadioGroup, TextareaAutosize } from '@mui/material';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Modal from '@mui/material/Modal';
import Radio from '@mui/material/Radio';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

interface RemoveActionProps {
  openModal: boolean;

  onClose?(): void;

  modalValue?: any;
  jobData?: any;
}

const RemoveAction: React.FC<RemoveActionProps> = (props) => {
  const classes = useModalStyles();
  const { openModal, onClose, modalValue, jobData } = props;
  const jobListTypeSelection = useJobListType();
  const updateJobStatusResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.UPDATE_JOB_STATUS];
  const [isReason, setIsReason] = useState(null);
  const [targetId, setTargetId] = useState(null);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const resetFields = () => {
    resetReasonDetails();
  };

  const handleChange = (val: any) => {
    setIsReason(val);
  };
  const {
    value: isReasonDetails,
    reset: resetReasonDetails,
    error: reasonDetailsError,
    bind: bindReasonDetails,
  } = useInput('', {
    required: 'Please enter Reason Details!',
    validate(value) {
      if ((isReason as any)?.toString()?.toLowerCase() === 'other' && value === '') {
        return 'Please enter the reason details!';
      }

      if ((modalValue as any)?.toString()?.toLowerCase() === 'other' && value === '') {
        return 'Please enter the reason details!';
      }

      return true;
    },
  });

  const onSubmit = () => {
    if (isReason === null && modalValue !== 'other') {
      toast.error('Please at least one field selected!');
      return;
    }
    if (isReasonDetails === '' && modalValue === 'other') {
      toast.error('Please input reason details!');
      return;
    }
    reasonDetailsError?.check();
    if (reasonDetailsError?.check() && (isReason as any)?.toString()?.toLowerCase() === 'other') {
      toast.error('Please fill all the required fields!');
      return;
    }

    const data: any = {
      status: 'removed',
      removed_reason: modalValue,
      removed_reason_details: isReasonDetails ? isReasonDetails : isReason,
      time_zone: timezone,
    };
    setTargetId(jobData?.id);
    updateJobStatus(jobData?.id, data);
  };

  const getJobList = () =>
    getJobs({
      limit: 10,
      page: 1,
      type: jobListTypeSelection?.currentSelected,
    });

  useEffect(() => {
    if (!openModal) {
      resetFields();
    }
  }, [openModal]);

  useEffect(() => {
    if (updateJobStatusResponse?.success && targetId === jobData?.id) {
      toast.success(alertMessages.jobs.jobUpdateSuccess);
      setTargetId(null);
      clearUpdateJobStatus();
      if (updateJobStatusResponse?.data?.shouldReload) {
        getJobList();
      }

      if (onClose) {
        onClose();
      }
    }

    if (updateJobStatusResponse?.error && targetId === jobData?.id) {
      toast.error(alertMessages.jobs.jobUpdateError);
      clearUpdateJobStatus();
    }
  }, [updateJobStatusResponse]);
  return (
    <>
      <Modal
        open={openModal}
        onClose={onClose}
        className={classes.root}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box>
          {modalValue === 'lost' && (
            <Grid container spacing={2} className={'radio'}>
              {options &&
                options?.length > 0 &&
                options?.map((option: any, index: number) => (
                  <Grid key={index} item sm={12} md={6} lg={6}>
                    <RadioGroup
                      aria-labelledby='demo-controlled-radio-buttons-group'
                      name='controlled-radio-buttons-group'
                      value={isReason}
                      onChange={() => handleChange(option?.value)}
                    >
                      <FormControlLabel control={<Radio />} label={option?.label} value={option?.value} />
                    </RadioGroup>
                  </Grid>
                ))}
            </Grid>
          )}
          <TextareaAutosize
            aria-label='minimum height'
            minRows={4}
            placeholder='Textarea'
            {...bindReasonDetails}
            className={reasonDetailsError?.error ? 'textaria_error' : 'textaria'}
          />
          {reasonDetailsError?.error && <span className='error'>{reasonDetailsError?.message}</span>}

          <div className={'actionButton'}>
            <Button color={'error'} variant={'contained'} title={'Cancel'} onClick={onClose} />
            <Button color={'success'} variant={'contained'} title={'Submit'} onClick={onSubmit} />
          </div>
        </Box>
      </Modal>
    </>
  );
};

export { RemoveAction };
