import { SettingsTabPanel } from '@/components/molecules/SettingsTabPanel';

const Tab = () => {
  return (
    <>
      <SettingsTabPanel />
    </>
  );
};

export default Tab;
