import { store } from '@/redux/store';
import {
  createJobHttpCall,
  getJobsHttpCall,
  getJobStaffMembersDropdownHttpCall,
  updateJobHttpCall,
  getJobQuickNotesHttpCall,
  createJobQuickNotesHttpCall,
  updateJobQuickNotesHttpCall,
  deleteJobQuickNotesHttpCall,
  getJobActivityLogsHttpCall,
  deleteAllJobEquipmentsHttpCall,
  moveJobHttpCall,
  sendJobFilterHttpCall,
  uploadJobFileHttpCall,
  getJobFileDetailsHttpCall,
  deleteJobFileHttpCall,
  getJobContractPDFHttpCall,
  sendJobDripHttpCall,
  getJobCustomersDropdownHttpCall,
  getJobTypesHttpCall,
  updateJobProgressListHttpCall,
  updateJobDetailsHttpCall,
  toggleJobQuickNotePinHttpCall,
} from '@/redux/reducers/http/httpCallSets';
import {
  CustomerProfileParams,
  JobListFilterParams,
  UserListFilterParams,
  QuickNotesParams,
  ActivityLogsParams,
  EquipmentParams,
  EquipmentBodyParams,
  MoveJobParams,
  JobFilterParams,
  JobItem,
  SendJobDripParams,
  CustomerListFilterParams,
  ProgressItem,
  JobUpdateParams,
} from '@/types';
import { clearHttpStateObject } from '@/redux/actions';
import {
  CORRELATION_IDS,
  updateJobVMCountHttpCall,
  sendJobContractHttpCall,
  restoreJobHttpCall,
  createJobEquipmentsHttpCall,
  getJobEquipmentsHttpCall,
  deleteJobEquipmentHttpCall,
  updateJobStatusHttpCall,
  updateJobCustomerDetailsHttpCall,
} from '@/redux/reducers/http';
import { SingleJobFormData } from '@/types/formData';

export function getJobs(params: JobListFilterParams) {
  if (params?.type === 'regular') {
    (params as any).type = undefined;
  }
  clearSendJobFilter();
  store.dispatch(getJobsHttpCall(params));
}

export function getJobStaffMembers(params: UserListFilterParams) {
  store.dispatch(getJobStaffMembersDropdownHttpCall(params));
}

export function createJob(data: CustomerProfileParams) {
  store.dispatch(createJobHttpCall(data));
}

export function updateJob(id: string, data: SingleJobFormData) {
  store.dispatch(updateJobHttpCall(id, data));
}

export function updateJobDetails(id: string, data: SingleJobFormData) {
  store.dispatch(updateJobDetailsHttpCall(id, data));
}

export function clearCreatedJob() {
  clearHttpStateObject(CORRELATION_IDS.CREATE_JOB);
}

export function clearUpdatedJob() {
  clearHttpStateObject(CORRELATION_IDS.UPDATE_JOB);
}

export function clearUpdatedJobDetails() {
  clearHttpStateObject(CORRELATION_IDS.UPDATE_JOB_DETAILS);
}

export function updateJobVMCount(id: string) {
  store.dispatch(updateJobVMCountHttpCall(id));
}

export function sendJobContract(id: string) {
  store.dispatch(sendJobContractHttpCall(id));
}

export function clearJobSendContractResponse() {
  clearHttpStateObject(CORRELATION_IDS.SEND_JOB_CONTRACT);
}

export function clearJobUpdatedVMCount() {
  clearHttpStateObject(CORRELATION_IDS.UPDATE_JOB_VM_COUNT);
}

export function restoreJob(id: string) {
  // store.dispatch(restoreJobHttpCall(id));
  updateJobStatus(id, {
    status: 'in_progress',
  });
}

export function clearRestoreLostJob() {
  clearHttpStateObject(CORRELATION_IDS.RESTORE_JOB);
}

export function createJobEquipments(data: EquipmentBodyParams) {
  store.dispatch(createJobEquipmentsHttpCall(data));
}

export function clearCreateJobEquipments() {
  clearHttpStateObject(CORRELATION_IDS.CREATE_JOB_EQUIPMENTS);
}

export function getJobEquipments(id: any) {
  store.dispatch(getJobEquipmentsHttpCall(id));
}

export function clearGetJobEquipments() {
  clearHttpStateObject(CORRELATION_IDS.GET_JOB_EQUIPMENTS);
}

export function deleteJobEquipment(id: EquipmentParams['id']) {
  store.dispatch(deleteJobEquipmentHttpCall(id));
}

export function deleteAllJobEquipments(id: EquipmentParams['id']) {
  store.dispatch(deleteAllJobEquipmentsHttpCall(id));
}

export function clearDeletedJobEquipment() {
  clearHttpStateObject(CORRELATION_IDS.DELETE_JOB_EQUIPMENT);
}

export function updateJobStatus(jobId: JobItem['id'], params: JobUpdateParams) {
  store.dispatch(updateJobStatusHttpCall(jobId, params));
}

export function updateJobCustomerDetails(id: CustomerProfileParams) {
  store.dispatch(updateJobCustomerDetailsHttpCall(id));
}

export function clearUpdateJobStatus() {
  clearHttpStateObject(CORRELATION_IDS.UPDATE_JOB_STATUS);
}

export function clearJobUpdateCustomerDetails() {
  clearHttpStateObject(CORRELATION_IDS.UPDATE_JOB_CUSTOMER_DETAILS);
}

export function getJobQuickNotes(id: QuickNotesParams['id']) {
  store.dispatch(getJobQuickNotesHttpCall(id));
}

export function createJobQuickNotes(data: QuickNotesParams) {
  store.dispatch(createJobQuickNotesHttpCall(data));
}

export function clearJobCreateQuickNotes() {
  clearHttpStateObject(CORRELATION_IDS.CREATE_JOB_QUICK_NOTES);
}

export function updateJobQuickNotes(data: QuickNotesParams) {
  store.dispatch(updateJobQuickNotesHttpCall(data));
}

export function toggleJobQuickNotePin(data: QuickNotesParams) {
  store.dispatch(toggleJobQuickNotePinHttpCall(data));
}

export function clearJobUpdatedQuickNotes() {
  clearHttpStateObject(CORRELATION_IDS.UPDATE_JOB_QUICK_NOTES);
}

export function deleteJobQuickNotes(id: QuickNotesParams['id']) {
  store.dispatch(deleteJobQuickNotesHttpCall(id));
}

export function clearDeleteJobQuickNoteResponse() {
  clearHttpStateObject(CORRELATION_IDS.DELETE_JOB_QUICK_NOTES);
}

export function getJobActivityLogs(params: ActivityLogsParams) {
  store.dispatch(getJobActivityLogsHttpCall(params));
}

export function moveJob(params: MoveJobParams) {
  store.dispatch(moveJobHttpCall(params));
}

export function clearMovedJobResponse() {
  clearHttpStateObject(CORRELATION_IDS.MOVE_JOB);
}

export function sendJobDrip(params: SendJobDripParams) {
  store.dispatch(sendJobDripHttpCall(params));
}

export function clearSendJobDripResponse() {
  clearHttpStateObject(CORRELATION_IDS.SEND_JOB_DRIP);
}

export function sendJobFilter(data: JobFilterParams) {
  store.dispatch(sendJobFilterHttpCall(data));
}

export function clearSendJobFilter() {
  clearHttpStateObject(CORRELATION_IDS.SEND_JOB_FILTER);
}

export function uploadJobFile(data: any, onUploadProgress: (progressEvent: any) => void) {
  store.dispatch(uploadJobFileHttpCall(data?.id, data.formData, onUploadProgress));
}

export function getJobFileDetails(id: any) {
  store.dispatch(getJobFileDetailsHttpCall(id));
}

export function deleteJobFile(id: any) {
  store.dispatch(deleteJobFileHttpCall(id));
}

export function clearJobDeleteFileResponse() {
  clearHttpStateObject(CORRELATION_IDS.DELETE_JOB_FILE);
}

export function getJobContractPDF(leadId: JobItem['id']) {
  store.dispatch(getJobContractPDFHttpCall(leadId));
}

export function clearJobContractPDF() {
  clearHttpStateObject(CORRELATION_IDS.GET_JOB_CONTRACT_PDF);
}

export function getJobCustomers(params: CustomerListFilterParams) {
  store.dispatch(getJobCustomersDropdownHttpCall(params));
}

export function getJobTypesAction() {
  store.dispatch(getJobTypesHttpCall());
}

export function updateJobProgressList(data: ProgressItem) {
  const jobId = data?.id;
  const params = { ...data };
  delete params?.id;
  store.dispatch(updateJobProgressListHttpCall(jobId, params));
}
