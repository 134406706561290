import { clearHttpStateObject } from '@/redux/actions';
import {
  CORRELATION_IDS,
  createLeadEquipmentsHttpCall,
  deleteLeadEquipmentHttpCall,
  getLeadEquipmentsHttpCall,
  restoreLostLeadHttpCall,
  sendLeadContractHttpCall,
  updateCustomerLeadHttpCall,
  updateLeadCustomerDetailsHttpCall,
  updateLeadVMCountHttpCall,
} from '@/redux/reducers/http';
import {
  createLeadHttpCall,
  createLeadQuickNotesHttpCall,
  deleteAllLeadEquipmentsHttpCall,
  deleteLeadFileHttpCall,
  deleteLeadQuickNotesHttpCall,
  deleteLostLeadAndClient,
  getLeadActivityLogsHttpCall,
  getLeadContractPDFHttpCall,
  getLeadFileDetailsHttpCall,
  getLeadQuickNotesHttpCall,
  getLeadStaffMembersDropdownHttpCall,
  getLeadsHttpCall,
  moveLeadHttpCall,
  removeLeadContactAction,
  sendLeadDripHttpCall,
  sendLeadFilterHttpCall,
  updateLeadHttpCall,
  updateLeadQuickNotesHttpCall,
  uploadLeadFileHttpCall,
} from '@/redux/reducers/http/httpCallSets';
import { store } from '@/redux/store';
import {
  ActivityLogsParams,
  CustomerProfileParams,
  EquipmentBodyParams,
  EquipmentParams,
  LeadFilterParams,
  LeadItem,
  LeadListFilterParams,
  MoveLeadParams,
  QuickNotesParams,
  SendLeadDripParams,
  UserListFilterParams,
} from '@/types';

export function getLeads(params: LeadListFilterParams) {
  clearSendLeadFilter();
  store.dispatch(getLeadsHttpCall(params));
}

export function getLeadStaffMembers(params: UserListFilterParams) {
  store.dispatch(getLeadStaffMembersDropdownHttpCall(params));
}

export function createLead(data: CustomerProfileParams) {
  store.dispatch(createLeadHttpCall(data));
}

export function updateLead(data: CustomerProfileParams) {
  store.dispatch(updateLeadHttpCall(data));
}

export function clearCreatedLead() {
  clearHttpStateObject(CORRELATION_IDS.CREATE_LEAD);
}

export function clearUpdatedLead() {
  clearHttpStateObject(CORRELATION_IDS.UPDATE_LEAD);
}

export function updateLeadVMCount(id: string) {
  store.dispatch(updateLeadVMCountHttpCall(id));
}

export function sendLeadContract(id: string) {
  store.dispatch(sendLeadContractHttpCall(id));
}

export function clearLeadSendContractResponse() {
  clearHttpStateObject(CORRELATION_IDS.SEND_LEAD_CONTRACT);
}

export function clearLeadUpdatedVMCount() {
  clearHttpStateObject(CORRELATION_IDS.UPDATE_LEAD_VM_COUNT);
}

export function restoreLostLead(id: string) {
  store.dispatch(restoreLostLeadHttpCall(id));
}

export function clearRestoreLostLead() {
  clearHttpStateObject(CORRELATION_IDS.RESTORE_LOST_LEAD);
}

export function createLeadEquipments(data: EquipmentBodyParams) {
  store.dispatch(createLeadEquipmentsHttpCall(data));
}

export function clearCreateLeadEquipments() {
  clearHttpStateObject(CORRELATION_IDS.CREATE_LEAD_EQUIPMENTS);
}

export function getLeadEquipments(id: any) {
  store.dispatch(getLeadEquipmentsHttpCall(id));
}

export function clearGetLeadEquipments() {
  clearHttpStateObject(CORRELATION_IDS.GET_LEAD_EQUIPMENTS);
}

export function deleteLeadEquipment(id: EquipmentParams['id']) {
  store.dispatch(deleteLeadEquipmentHttpCall(id));
}

export function deleteAllLeadEquipments(id: EquipmentParams['id']) {
  store.dispatch(deleteAllLeadEquipmentsHttpCall(id));
}

export function clearDeletedLeadEquipment() {
  clearHttpStateObject(CORRELATION_IDS.DELETE_LEAD_EQUIPMENT);
}

export function updateCustomerLead(id: CustomerProfileParams) {
  store.dispatch(updateCustomerLeadHttpCall(id));
}

export function updateLeadCustomerDetails(id: CustomerProfileParams) {
  store.dispatch(updateLeadCustomerDetailsHttpCall(id));
}

export function clearUpdatedCustomerLead() {
  clearHttpStateObject(CORRELATION_IDS.UPDATE_CUSTOMER_LEAD);
}

export function clearLeadUpdateCustomerDetails() {
  clearHttpStateObject(CORRELATION_IDS.UPDATE_LEAD_CUSTOMER_DETAILS);
}

export function getLeadQuickNotes(id: QuickNotesParams['id']) {
  store.dispatch(getLeadQuickNotesHttpCall(id));
}

export function createLeadQuickNotes(data: QuickNotesParams) {
  store.dispatch(createLeadQuickNotesHttpCall(data));
}

export function clearLeadCreateQuickNotes() {
  clearHttpStateObject(CORRELATION_IDS.CREATE_LEAD_QUICK_NOTES);
}

export function updateLeadQuickNotes(data: QuickNotesParams) {
  store.dispatch(updateLeadQuickNotesHttpCall(data));
}

export function clearLeadUpdatedQuickNotes() {
  clearHttpStateObject(CORRELATION_IDS.UPDATE_LEAD_QUICK_NOTES);
}

export function deleteLeadQuickNotes(id: QuickNotesParams['id']) {
  store.dispatch(deleteLeadQuickNotesHttpCall(id));
}

export function clearDeleteLeadQuickNoteResponse() {
  clearHttpStateObject(CORRELATION_IDS.DELETE_LEAD_QUICK_NOTES);
}

export function getLeadActivityLogs(params: ActivityLogsParams) {
  store.dispatch(getLeadActivityLogsHttpCall(params));
}

export function moveLead(params: MoveLeadParams) {
  store.dispatch(moveLeadHttpCall(params));
}

export function clearMovedLeadResponse() {
  clearHttpStateObject(CORRELATION_IDS.MOVE_LEAD);
}

export function sendLeadDrip(params: SendLeadDripParams) {
  store.dispatch(sendLeadDripHttpCall(params));
}

export function clearSendLeadDripResponse() {
  clearHttpStateObject(CORRELATION_IDS.SEND_LEAD_DRIP);
}

export function sendLeadFilter(data: LeadFilterParams) {
  store.dispatch(sendLeadFilterHttpCall(data));
}

export function clearSendLeadFilter() {
  clearHttpStateObject(CORRELATION_IDS.SEND_LEAD_FILTER);
}

export function uploadLeadFile(data: any, onUploadProgress: (progressEvent: any) => void) {
  store.dispatch(uploadLeadFileHttpCall(data?.id, data.formData, onUploadProgress));
}

export function getLeadFileDetails(id: any) {
  store.dispatch(getLeadFileDetailsHttpCall(id));
}

export function deleteLeadFile(id: any) {
  store.dispatch(deleteLeadFileHttpCall(id));
}

export function clearDeleteLeadFileResponse() {
  clearHttpStateObject(CORRELATION_IDS.DELETE_LEAD_FILE);
}

export function getLeadContractPDF(leadId: LeadItem['id']) {
  store.dispatch(getLeadContractPDFHttpCall(leadId));
}

export function clearLeadContractPDF() {
  clearHttpStateObject(CORRELATION_IDS.GET_LEAD_CONTRACT_PDF);
}

export function deleteLostLeadAndClientAction(id: string) {
  store.dispatch(deleteLostLeadAndClient(id));
}

export function removeLeadContact(id: string) {
  store.dispatch(removeLeadContactAction(id));
}
