import React from 'react';
import { Box } from '@mui/material';
import { useAppSelector } from '@/hooks';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { convertToNumber } from '@/utils';
import { colors, themeParser, useAppTheme } from '@/theme';

const JobCounter = () => {
  const theme = useAppTheme();
  const jobListResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_JOB_LIST];
  const jobListFilterResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.SEND_JOB_FILTER];
  const totalItems = convertToNumber(
    jobListFilterResponse?.data ? jobListFilterResponse?.data?.total : jobListResponse?.data?.total,
  );

  return (
    <Box
      sx={{
        padding: '10px 30px',
        fontSize: '12px',
        color: `${themeParser('#000000', colors.primary, theme)} !important`,
      }}
    >
      TOTAL JOBS: {totalItems ?? 0}
    </Box>
  );
};

export { JobCounter };
