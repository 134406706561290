import React from 'react';
import { SettingsTabPanel } from '@/components/molecules/SettingsTabPanel';
import { Header } from '@/components/molecules/Header';
import { FloatingActionButton } from '@/components/atoms/FloatingActionButton';
import { useDefaultLayoutStyles } from '@/static/stylesheets/layouts';
import { FeatureType } from '@/types';

interface ReportsLayoutProps {
  children?: React.ReactNode;
  hideSettingsTabPanel?: boolean;
  hideHeaderRightSide?: boolean;
  hideHeaderProfile?: boolean;
  featureType?: FeatureType;
}

const ReportsLayout: React.FC<ReportsLayoutProps> = (props) => {
  const classes = useDefaultLayoutStyles();

  return (
    <>
      <div className={classes.root}>
        <Header
          hideHeaderRightSide={props.hideHeaderRightSide}
          hideHeaderProfile={props.hideHeaderProfile}
          featureType={props?.featureType}
        />
        {props.hideSettingsTabPanel && <SettingsTabPanel />}
        {props.children}
      </div>

      <FloatingActionButton />
    </>
  );
};

export { ReportsLayout };
