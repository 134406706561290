import React from 'react';
import { PaginationProps } from '@/components/atoms/Pagination';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useDataTableStyles } from '@/static/stylesheets/organisms';
import { Pagination } from '@/components/atoms/Pagination';
import classNames from 'classnames';

interface DataTableProps {
  heads?: {
    label?: string;
    align: 'left' | 'center' | 'right';
    width?: string;
  }[];
  rows?: {
    align: 'left' | 'center' | 'right';
    label?: string | number | React.ReactNode;
  }[][];
  size?: 'small' | 'medium';
  hasPagination?: boolean;
  paginationProps?: PaginationProps;
  tableRowCellClassName?: string;
}

const DataTable: React.FC<DataTableProps> = (props) => {
  const classes = useDataTableStyles();
  const { heads, rows, size, paginationProps, hasPagination, tableRowCellClassName } = props;

  return (
    <>
      <TableContainer component={Paper} className={classes.tableBodyPart}>
        <Table sx={{ minWidth: 650 }} size={size}>
          <TableHead>
            <TableRow>
              {heads &&
                heads?.length > 0 &&
                heads?.map((head, index) => (
                  <TableCell
                    key={index}
                    align={head?.align}
                    className={classes.tableHeadCell}
                    style={{ width: head?.width }}
                  >
                    {head?.label}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows &&
              rows?.length > 0 &&
              rows.map((row, index) => (
                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  {row?.map((column, index) => (
                    <TableCell
                      key={index}
                      component='th'
                      scope='row'
                      align={column?.align}
                      className={classNames(classes.tableRowCell, tableRowCellClassName)}
                    >
                      <div className={classes.iconsContainer(column?.align)}>{column?.label}</div>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {hasPagination && (
        <div className={classes.paginationContainer}>
          <Pagination {...paginationProps} />
        </div>
      )}
    </>
  );
};

export { DataTable };
