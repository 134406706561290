import { ContentWrapper } from '@/components/organisms/Reports';
import { ReportTable, Tbody, Td, Tfoot, Th, Tr } from '@/components/organisms/Reports/ReportContent/ReportTable';
import { useAppSelector } from '@/hooks';
import { getWonVsLossReportAction } from '@/redux/actions';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { convertToNumber } from '@/utils';
import { useEffect, useState } from 'react';
interface TData {
  tabelOne: {
    total: any;
    data: any[];
  };
  tableTwo: {
    total: any;
    data: any[];
  };
  tableThree: {
    total: any;
    data: any[];
  };
}

const TechWorkLostVsWon = () => {
  const [tableData, setTableData] = useState<TData | undefined>(undefined);

  const [isShowPercentage, setIsShowPercentage] = useState(false);
  const [isShowPercentage1, setIsShowPercentage1] = useState(false);
  const [isShowPercentage2, setIsShowPercentage2] = useState(false);

  const result = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_LOST_VS_WON_REPORT];

  useEffect(() => {
    getWonVsLossReportAction({
      report_type: 'job',
    });
  }, []);

  useEffect(() => {
    if (result && result?.data) {
      restructureData(result);
    }
  }, [result]);

  const restructureData = (result: any) => {
    const newDataSet: TData = {
      tabelOne: {
        total: {},
        data: [],
      },
      tableTwo: {
        total: {},
        data: [],
      },
      tableThree: {
        total: {},
        data: [],
      },
    };

    result?.data &&
      Object.keys(result?.data).forEach((key: string) => {
        const item = result?.data[key];

        // table one
        const defaultValue = {
          percentage: 0,
          count: 0,
        };

        newDataSet.tabelOne.data.push({
          source: key,
          total_jobs: item.won_vs_lost.total_jobs || 0,
          completed_jobs: item.won_vs_lost.completed_jobs || 0,
          running_job: item.won_vs_lost.running_job || 0,
          total_lost: item.won_vs_lost.total_lost || 0,
        });

        const table1Sums: any = {};
        newDataSet.tabelOne.data.forEach((_: any) => {
          table1Sums.total_jobs = (table1Sums.total_jobs || 0) + _?.total_jobs;
          table1Sums.completed_jobs = (table1Sums.completed_jobs || 0) + _.completed_jobs?.count;
          table1Sums.running_job = (table1Sums.running_job || 0) + _.running_job?.count;
          table1Sums.total_lost = (table1Sums.total_lost || 0) + _.total_lost?.count;
        });
        newDataSet.tabelOne.total = table1Sums;

        // table two
        const table2Cols = ['lost', 'duplicate']; // + others

        const table2others = item?.lost_type_breakdown.filter((a: any) => !table2Cols.includes(a.reason));

        const table2OthersCount = table2others.reduce((acc: number, item: any) => acc + item?.count, 0) || 0;

        newDataSet.tableTwo.data.push({
          source: key,
          total_jobs: item.won_vs_lost.total_jobs || 0,
          lost: item.lost_type_breakdown.find((_: any) => _.reason === 'lost') || defaultValue,
          duplicate: item.lost_type_breakdown.find((_: any) => _.reason === 'duplicate') || defaultValue,
          other: {
            count: table2OthersCount,
            percentage: ((table2OthersCount * 100) / item.won_vs_lost.total_leads).toFixed(2),
          },
        });

        const table2Sums: any = {};
        newDataSet.tableTwo.data.forEach((_: any) => {
          table2Sums.total_jobs = (table2Sums.total_jobs || 0) + _.total_jobs;
          table2Sums.lost = (table2Sums.lost || 0) + _.lost.count;
          table2Sums.duplicate = (table2Sums.duplicate || 0) + _.duplicate.count;
          table2Sums.other = (table2Sums.other || 0) + _.other.count;
        });
        newDataSet.tableTwo.total = table2Sums;

        // table three

        const table3Cols = [
          'Job Cost To High',
          'No Longer Needs',
          'Choose Another Provider',
          "Doesn't See Value",
          'Happy with Current Provider',
        ]; // + others

        const table3others = item?.lost_type_breakdown.filter((a: any) => !table3Cols.includes(a.reason));

        const table3OthersCount = table3others.reduce((acc: number, item: any) => acc + item?.count, 0) || 0;

        newDataSet.tableThree.data.push({
          source: key,
          total_lost: item.won_vs_lost.total_lost,
          job_cost_to_high:
            item.lost_sub_type_breakdown.find((_: any) => _.reason_details === 'Job Cost To High') || defaultValue,
          no_longer_needs:
            item.lost_sub_type_breakdown.find((_: any) => _.reason_details === 'No Longer Needs') || defaultValue,
          choose_another_provider:
            item.lost_sub_type_breakdown.find((_: any) => _.reason_details === 'Choose Another Provider') ||
            defaultValue,
          does_not_see_value:
            item.lost_sub_type_breakdown.find((_: any) => _.reason_details === "Doesn't See Value") || defaultValue,
          happy_with_current_provider:
            item.lost_sub_type_breakdown.find((_: any) => _.reason_details === 'Happy with Current Provider') ||
            defaultValue,
          other: {
            count: table3OthersCount,
            percentage: (table3OthersCount * 100) / item.won_vs_lost.total_lost.count || 0,
          },
        });
      });

    const table3Sums: any = {};

    newDataSet.tableThree.data.forEach((_: any) => {
      table3Sums.total_lost = (table3Sums.total_lost || 0) + _.total_lost.count;
      table3Sums.job_cost_to_high = (table3Sums.job_cost_to_high || 0) + _.job_cost_to_high.count;
      table3Sums.no_longer_needs = (table3Sums.no_longer_needs || 0) + _.no_longer_needs.count;
      table3Sums.choose_another_provider = (table3Sums.choose_another_provider || 0) + _.choose_another_provider.count;
      table3Sums.does_not_see_value = (table3Sums.does_not_see_value || 0) + _.does_not_see_value.count;
      table3Sums.happy_with_current_provider =
        (table3Sums.happy_with_current_provider || 0) + _.happy_with_current_provider.count;
      table3Sums.other = (table3Sums.other || 0) + _.other.count;
    });

    newDataSet.tableThree.total = table3Sums;
    setTableData(newDataSet);
  };

  return (
    <>
      <ContentWrapper headings={['Tech Work | Lost vs. Won']}>
        <div className={'data-toggle-view'}>
          <span onClick={() => setIsShowPercentage(!isShowPercentage)}>Toggle %</span>
        </div>
        <ReportTable tableContainerClass='no-border-bottom' marginBottom={'18px'}>
          <Tbody>
            <Tr>
              <Th align={'left'}>Source</Th>
              <Th align={'center'}>Total Jobs</Th>
              <Th align={'center'}>Completed Jobs</Th>
              <Th align={'center'}>Total Lost</Th>
            </Tr>
            {tableData &&
              tableData.tabelOne.data.map((item: any, index: number) => (
                <Tr key={index}>
                  <Td align={'left'}>{item.source}</Td>
                  <Td align={'center'}>{item.total_jobs}</Td>
                  <Td align={'center'}>
                    {isShowPercentage
                      ? `${Math.round(convertToNumber(item.completed_jobs.percentage))}%`
                      : item.completed_jobs.count}
                  </Td>
                  <Td align={'center'}>
                    {isShowPercentage
                      ? `${Math.round(convertToNumber(item.total_lost.percentage))}%`
                      : item.total_lost.count}
                  </Td>
                </Tr>
              ))}
          </Tbody>
          <Tfoot>
            <Tr>
              <Th align={'left'}>ALL Sources</Th>
              <Th>{tableData?.tabelOne.total.total_jobs}</Th>
              <Th>
                {isShowPercentage
                  ? `${Math.round(
                      (convertToNumber(tableData?.tabelOne.total.completed_jobs) * 100) /
                        convertToNumber(tableData?.tabelOne.total.total_jobs),
                    )}%`
                  : tableData?.tabelOne.total.completed_jobs}
              </Th>
              <Th>
                {isShowPercentage
                  ? `${Math.round(
                      (convertToNumber(tableData?.tabelOne.total.total_lost) * 100) /
                        convertToNumber(tableData?.tabelOne.total.total_jobs),
                    )}%`
                  : tableData?.tabelOne.total.total_lost}
              </Th>
            </Tr>
          </Tfoot>
        </ReportTable>

        <div className={'data-toggle-view'}>
          <span onClick={() => setIsShowPercentage1(!isShowPercentage1)}>Toggle %</span>
        </div>
        <ReportTable tableContainerClass='no-border-bottom' marginBottom={'18px'}>
          <Tbody>
            <Tr>
              <Th align={'left'}>Source</Th>
              <Th align={'center'}>Total Jobs</Th>
              <Th align={'center'}>Lost</Th>
              <Th align={'center'}>Duplicate</Th>
              <Th align={'center'}>Other</Th>
            </Tr>
            {tableData &&
              tableData.tableTwo.data.map((item: any, index: number) => (
                <Tr key={index}>
                  <Td align={'left'}>{item.source}</Td>
                  <Td align={'center'}>{item.total_jobs}</Td>
                  <Td align={'center'}>
                    {isShowPercentage1 ? `${Math.round(convertToNumber(item.lost.percentage))}%` : item.lost.count}
                  </Td>
                  <Td align={'center'}>
                    {isShowPercentage1
                      ? `${Math.round(convertToNumber(item.duplicate.percentage))}%`
                      : item.duplicate.count}
                  </Td>
                  <Td align={'center'}>
                    {isShowPercentage1 ? `${Math.round(convertToNumber(item.other?.percentage))}%` : item.other.count}
                  </Td>
                </Tr>
              ))}
            <Tr>
              <Th align={'left'}>ALL Sources</Th>
              <Th>{tableData?.tableTwo.total.total_jobs}</Th>
              <Th>
                {isShowPercentage1
                  ? `${Math.round(
                      (convertToNumber(tableData?.tableTwo.total.lost) * 100) /
                        convertToNumber(tableData?.tableTwo.total.total_jobs),
                    )}%`
                  : tableData?.tableTwo.total.lost}
              </Th>
              <Th>
                {isShowPercentage1
                  ? `${Math.round(
                      (convertToNumber(tableData?.tableTwo.total.duplicate) * 100) /
                        convertToNumber(tableData?.tableTwo.total.total_jobs),
                    )}%`
                  : tableData?.tableTwo.total.duplicate}
              </Th>
              <Th>
                {isShowPercentage1
                  ? `${Math.round(
                      (convertToNumber(tableData?.tableTwo.total.other) * 100) /
                        convertToNumber(tableData?.tableTwo.total.total_jobs),
                    )}%`
                  : tableData?.tableTwo.total.other}
              </Th>
            </Tr>
          </Tbody>
        </ReportTable>

        <div className={'data-toggle-view'}>
          <span onClick={() => setIsShowPercentage2(!isShowPercentage2)}>Toggle %</span>
        </div>
        <ReportTable>
          <Tbody>
            <Tr>
              <Th align={'left'}>Source</Th>
              <Th align={'center'}>Total Lost</Th>
              <Th align={'center'}>Job Cost To High</Th>
              <Th align={'center'}>No Longer Needs</Th>
              <Th align={'center'}>Choose Another Provider</Th>
              <Th align={'center'}>Doesn't See Value</Th>
              <Th align={'center'}>Happy with Current Provider</Th>
              <Th align={'center'}>Other</Th>
            </Tr>
            {tableData &&
              tableData.tableThree.data.map((item: any, index: number) => (
                <Tr key={index}>
                  <Td align={'left'}>{item.source}</Td>
                  <Td align={'center'}>{item.total_lost.count}</Td>
                  <Td align={'center'}>
                    {isShowPercentage2
                      ? `${Math.round(convertToNumber(item.job_cost_to_high.percentage))}%`
                      : item.job_cost_to_high.count}
                  </Td>
                  <Td align={'center'}>
                    {isShowPercentage2
                      ? `${Math.round(convertToNumber(item.no_longer_needs.percentage))}%`
                      : item.no_longer_needs.count}
                  </Td>
                  <Td align={'center'}>
                    {isShowPercentage2
                      ? `${Math.round(convertToNumber(item.choose_another_provider.percentage))}%`
                      : item.choose_another_provider.count}
                  </Td>
                  <Td align={'center'}>
                    {isShowPercentage2
                      ? `${Math.round(convertToNumber(item.does_not_see_value.percentage))}%`
                      : item.does_not_see_value.count}
                  </Td>
                  <Td align={'center'}>
                    {isShowPercentage2
                      ? `${Math.round(convertToNumber(item.happy_with_current_provider.percentage))}%`
                      : item.happy_with_current_provider.count}
                  </Td>
                  <Td align={'center'}>
                    {isShowPercentage2 ? `${Math.round(convertToNumber(item.other.percentage))}%` : item.other.count}
                  </Td>
                </Tr>
              ))}
            <Tr>
              <Th align={'left'}>ALL Sources</Th>
              <Th>{tableData?.tableThree.total.total_lost}</Th>
              <Th>
                {isShowPercentage2
                  ? `${Math.round(
                      (convertToNumber(tableData?.tableThree.total.job_cost_to_high) * 100) /
                        convertToNumber(tableData?.tableThree.total.total_lost),
                    )}%`
                  : tableData?.tableThree.total.job_cost_to_high}
              </Th>
              <Th>
                {isShowPercentage2
                  ? `${Math.round(
                      (convertToNumber(tableData?.tableThree.total.no_longer_needs) * 100) /
                        convertToNumber(tableData?.tableThree.total.total_lost),
                    )}%`
                  : tableData?.tableThree.total.no_longer_needs}
              </Th>
              <Th>
                {isShowPercentage2
                  ? `${Math.round(
                      (convertToNumber(tableData?.tableThree.total.choose_another_provider) * 100) /
                        convertToNumber(tableData?.tableThree.total.total_lost),
                    )}%`
                  : tableData?.tableThree.total.choose_another_provider}
              </Th>
              <Th>
                {isShowPercentage2
                  ? `${Math.round(
                      (convertToNumber(tableData?.tableThree.total.does_not_see_value) * 100) /
                        convertToNumber(tableData?.tableThree.total.total_lost),
                    )}%`
                  : tableData?.tableThree.total.does_not_see_value}
              </Th>
              <Th>
                {isShowPercentage2
                  ? `${Math.round(
                      (convertToNumber(tableData?.tableThree.total.happy_with_current_provider) * 100) /
                        convertToNumber(tableData?.tableThree.total.total_lost),
                    )}%`
                  : tableData?.tableThree.total.happy_with_current_provider}
              </Th>
              <Th>
                {isShowPercentage2
                  ? `${Math.round(
                      (convertToNumber(tableData?.tableThree.total.other) * 100) /
                        convertToNumber(tableData?.tableThree.total.total_lost),
                    )}%`
                  : tableData?.tableThree.total.other}
              </Th>
            </Tr>
          </Tbody>
        </ReportTable>
      </ContentWrapper>
    </>
  );
};

export { TechWorkLostVsWon };
