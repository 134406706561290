import { endpoints } from '@/api/endpoints';
import {
  ApiDisconnectionParams,
  CreateLocation,
  CustomVariableFilterParams,
  CustomVariableParams,
  DocusignApiParams,
  DripApiParams,
  EmailTemplateFilterParams,
  EmailTemplateParams,
  EmailTemplateSendTestMailParams,
  EmailTemplatesFilterParams,
  EquipmentFilterParams,
  EquipmentParams,
  GetLocationsParams,
  IPParams,
  JobProgressItemParams,
  JobProgressListFilterParams,
  LeadClientJobSettingsParams,
  ProgressBarTypeFilterParams,
  ProgressItem,
  SmtpSettingsParams,
  SourceItemParams,
  UcrmApiParams,
  UpdateLocation,
  UserListFilterParams,
  UserParams,
} from '@/types';
import { CORRELATION_IDS } from '../correlationIds';
import { performHttpCall } from '../httpSlice';

export const getUsersHttpCall = (params: UserListFilterParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_USERS,
    payload: {
      url: endpoints.private.getUsers(params),
      method: 'get',
      useBearerToken: true,
    },
  });

export const createUserHttpCall = (data: UserParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.CREATE_USER,
    payload: {
      url: endpoints.private.createUser,
      method: 'post',
      useBearerToken: true,
      body: data,
    },
  });

export const updateUserHttpCall = (id: UserParams['id'], data: UserParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.UPDATE_USER,
    payload: {
      url: endpoints.private.updateUser(id ?? ''),
      method: 'put',
      useBearerToken: true,
      body: data,
    },
  });

export const deleteUserHttpCall = (id: UserParams['id']) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.DELETE_USER,
    payload: {
      url: endpoints.private.deleteUser(id ?? ''),
      method: 'delete',
      useBearerToken: true,
    },
  });

export const resetUserPasswordHttpCall = (
  id: UserParams['id'],
  data: Pick<UserParams, 'password' | 'confirm_password'>,
) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.USER_RESET_PASSWORD,
    payload: {
      url: endpoints.private.userResetPassword(id ?? ''),
      method: 'patch',
      useBearerToken: true,
      body: data,
    },
  });

export const getLeadClientJobSettingsHttpCall = () =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_LEAD_CLIENT_JOB_SETTINGS,
    payload: {
      url: endpoints.private.getLeadClientJobSettings,
      method: 'get',
      useBearerToken: true,
    },
  });

export const saveLeadClientJobSettingsHttpCall = (data: LeadClientJobSettingsParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.SAVE_LEAD_CLIENT_JOB_SETTINGS,
    payload: {
      url: endpoints.private.saveLeadClientJobSettings,
      method: 'post',
      useBearerToken: true,
      body: data,
    },
  });

export const getSourceListHttpCall = () =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_SOURCE_LIST,
    payload: {
      url: endpoints.private.getSourceList,
      method: 'get',
      useBearerToken: true,
    },
  });

export const saveSourceItemHttpCall = (data: SourceItemParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.SAVE_SOURCE_ITEM,
    payload: {
      url: endpoints.private.saveSourceItem,
      method: 'post',
      useBearerToken: true,
      body: data,
    },
  });

export const deleteSourceItemHttpCall = (id: string) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.DELETE_SOURCE_ITEM,
    payload: {
      url: endpoints.private.deleteSourceItem(id),
      method: 'delete',
      useBearerToken: true,
    },
  });

export const getApiKeyListHttpCall = () =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_API_KEY_LIST,
    payload: {
      url: endpoints.private.getApiKeyList,
      method: 'get',
      useBearerToken: true,
    },
  });

export const saveApiKeyHttpCall = () =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.SAVE_API_KEY_ITEM,
    payload: {
      url: endpoints.private.saveApiKeyItem,
      method: 'post',
      useBearerToken: true,
    },
  });

export const deleteApiKeyHttpCall = (id: string) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.DELETE_API_KEY_ITEM,
    payload: {
      url: endpoints.private.deleteApiKeyItem(id),
      method: 'delete',
      useBearerToken: true,
    },
  });

export const getJobProgressListHttpCall = (params: JobProgressListFilterParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_PROGRESS_LIST,
    payload: {
      url: endpoints.private.getJobProgressList(params),
      method: 'get',
      useBearerToken: true,
    },
  });

export const saveJobProgressItemHttpCall = (data: JobProgressItemParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.SAVE_JOB_PROGRESS_ITEM,
    payload: {
      url: endpoints.private.saveJobProgressItem,
      method: 'post',
      useBearerToken: true,
      body: data,
    },
  });

export const updateProgressListHttpCall = (id: ProgressItem['id'], data: ProgressItem) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.UPDATE_PROGRESS_LIST,
    payload: {
      url: endpoints.private.updateProgressList(id ?? ''),
      method: 'patch',
      useBearerToken: true,
      body: data,
    },
  });

export const deleteJobProgressItemHttpCall = (id: string) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.DELETE_JOB_PROGRESS_ITEM,
    payload: {
      url: endpoints.private.deleteJobProgressItem(id),
      method: 'delete',
      useBearerToken: true,
    },
  });

export const getPublicIPListHttpCall = () =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_PUBLIC_IP_LIST,
    payload: {
      url: endpoints.private.getPublicIPList,
      method: 'get',
      useBearerToken: true,
    },
  });

export const savePublicIPHttpCall = (data: IPParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.SAVE_PUBLIC_IP,
    payload: {
      url: endpoints.private.savePublicIP,
      method: 'post',
      useBearerToken: true,
      body: data,
    },
  });

export const deletePublicIPHttpCall = (id: string) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.DELETE_PUBLIC_IP,
    payload: {
      url: endpoints.private.deletePublicIP(id),
      method: 'delete',
      useBearerToken: true,
    },
  });

export const getPrivateIPListHttpCall = () =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_PRIVATE_IP_LIST,
    payload: {
      url: endpoints.private.getPrivateIPList,
      method: 'get',
      useBearerToken: true,
    },
  });

export const savePrivateIPHttpCall = (data: IPParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.SAVE_PRIVATE_IP,
    payload: {
      url: endpoints.private.savePrivateIP,
      method: 'post',
      useBearerToken: true,
      body: data,
    },
  });

export const deletePrivateIPHttpCall = (id: string) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.DELETE_PRIVATE_IP,
    payload: {
      url: endpoints.private.deletePrivateIP(id),
      method: 'delete',
      useBearerToken: true,
    },
  });

export const getEquipmentsHttpCall = (params: EquipmentFilterParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_EQUIPMENTS,
    payload: {
      url: endpoints.private.getEquipments(params),
      method: 'get',
      useBearerToken: true,
    },
  });
export const createEquipmentHttpCall = (data: EquipmentParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.CREATE_EQUIPMENT,
    payload: {
      url: endpoints.private.createEquipment,
      method: 'post',
      useBearerToken: true,
      body: data,
    },
  });

export const updateEquipmentHttpCall = (id: EquipmentParams['id'], data: EquipmentParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.UPDATE_EQUIPMENT,
    payload: {
      url: endpoints.private.updateEquipment(id ?? ''),
      method: 'put',
      useBearerToken: true,
      body: data,
    },
  });

export const deleteEquipmentHttpCall = (id: EquipmentParams['id']) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.DELETE_EQUIPMENT,
    payload: {
      url: endpoints.private.deleteEquipment(id ?? ''),
      method: 'delete',
      useBearerToken: true,
    },
  });

export const getProgressBarTypesHttpCall = (params?: ProgressBarTypeFilterParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_PROGRESS_BAR_TYPES,
    payload: {
      url: endpoints.private.getProgressBarTypes(params),
      method: 'get',
      useBearerToken: true,
    },
  });

export const getSmtpSettingsHttpCall = () =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_SMTP_SETTINGS,
    payload: {
      url: endpoints.private.getSmtpSettings,
      method: 'get',
      useBearerToken: true,
    },
  });

export const updateSmtpSettingsHttpCall = (data: SmtpSettingsParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.UPDATE_SMTP_SETTINGS,
    payload: {
      url: endpoints.private.saveSmtpSettings,
      method: 'post',
      useBearerToken: true,
      body: data,
    },
  });

export const sendTestSmtpMailHttpCall = (data: SmtpSettingsParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.SMTP_SEND_TEST_MAIL,
    payload: {
      url: endpoints.private.sentTestMail,
      method: 'post',
      useBearerToken: true,
      body: data,
    },
  });

export const getCustomVariablesHttpCall = (params: CustomVariableFilterParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_CUSTOM_VARIABLES,
    payload: {
      url: endpoints.private.getCustomVariables(params),
      method: 'get',
      useBearerToken: true,
    },
  });

export const getAllCustomVariablesHttpCall = (params: CustomVariableFilterParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_ALL_CUSTOM_VARIABLES,
    payload: {
      url: endpoints.private.getCustomVariables(params),
      method: 'get',
      useBearerToken: true,
    },
  });

export const getCustomVariableHttpCall = (id: CustomVariableParams['customVariable_id']) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_CUSTOM_VARIABLE,
    payload: {
      url: endpoints.private.getCustomVariable(id ?? ''),
      method: 'get',
      useBearerToken: true,
    },
  });

export const getCustomVariableCustomerColumnsHttpCall = () =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_CUSTOM_VARIABLE_CUSTOMER_COLUMNS,
    payload: {
      url: endpoints.private.getCustomVariableCustomerColumns,
      method: 'get',
      useBearerToken: true,
    },
  });

export const createCustomVariableHttpCall = (data: CustomVariableParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.CREATE_CUSTOM_VARIABLE,
    payload: {
      url: endpoints.private.createCustomVariable,
      method: 'post',
      useBearerToken: true,
      body: data,
    },
  });

export const updateCustomVariableHttpCall = (data: CustomVariableParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.UPDATE_CUSTOM_VARIABLE,
    payload: {
      url: endpoints.private.updateCustomVariable(data?.customVariable_id ?? ''),
      method: 'put',
      useBearerToken: true,
      body: data,
    },
  });

export const deleteCustomVariableHttpCall = (id: CustomVariableParams['customVariable_id']) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.DELETE_CUSTOM_VARIABLE,
    payload: {
      url: endpoints.private.deleteCustomVariable(id ?? ''),
      method: 'delete',
      useBearerToken: true,
    },
  });

export const getAllApiInformationHttpCall = () =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_ALL_API_INFORMATION,
    payload: {
      url: endpoints.private.getAllApiInformation,
      method: 'get',
      useBearerToken: true,
    },
  });

export const connectGoogleCalendarApiHttpCall = () =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.CONNECT_GOOGLE_CALENDAR_API,
    payload: {
      url: endpoints.private.connectGoogleCalendarApi,
      method: 'get',
      useBearerToken: true,
    },
  });

export const disconnectGoogleCalendarApiHttpCall = (data: ApiDisconnectionParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.DISCONNECT_GOOGLE_CALENDAR_API,
    payload: {
      url: endpoints.private.disconnectApi,
      method: 'post',
      useBearerToken: true,
      body: data,
    },
  });

export const connectDocusignApiHttpCall = (data: DocusignApiParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.CONNECT_DOCUSIGN_API,
    payload: {
      url: endpoints.private.connectDocusignApi,
      method: 'post',
      useBearerToken: true,
      body: data,
    },
  });

export const disconnectDocusignApiHttpCall = (data: ApiDisconnectionParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.DISCONNECT_DOCUSIGN_API,
    payload: {
      url: endpoints.private.disconnectApi,
      method: 'post',
      useBearerToken: true,
      body: data,
    },
  });

export const connectDripApiHttpCall = (data: DripApiParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.CONNECT_DRIP_API,
    payload: {
      url: endpoints.private.connectDripApi,
      method: 'post',
      useBearerToken: true,
      body: data,
    },
  });

export const disconnectDripApiHttpCall = (data: ApiDisconnectionParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.DISCONNECT_DRIP_API,
    payload: {
      url: endpoints.private.disconnectApi,
      method: 'post',
      useBearerToken: true,
      body: data,
    },
  });

export const connectUcrmApiHttpCall = (data: UcrmApiParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.CONNECT_UCRM_API,
    payload: {
      url: endpoints.private.connectUcrmApi,
      method: 'post',
      useBearerToken: true,
      body: data,
    },
  });

export const disconnectUcrmApiHttpCall = (data: ApiDisconnectionParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.DISCONNECT_UCRM_API,
    payload: {
      url: endpoints.private.disconnectApi,
      method: 'post',
      useBearerToken: true,
      body: data,
    },
  });

export const getEmailTemplateListHttpCall = (params: EmailTemplatesFilterParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_EMAIL_TEMPLATE_LIST,
    payload: {
      url: endpoints.private.getEmailTemplateList(params),
      method: 'get',
      useBearerToken: true,
    },
  });

export const getAllEmailTemplateListHttpCall = (params: EmailTemplatesFilterParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_ALL_EMAIL_TEMPLATE_LIST,
    payload: {
      url: endpoints.private.getEmailTemplateList(params),
      method: 'get',
      useBearerToken: true,
    },
  });

export const getEmailTemplateHttpCall = (params: EmailTemplateFilterParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_EMAIL_TEMPLATE,
    payload: {
      url: endpoints.private.getEmailTemplate(params),
      method: 'get',
      useBearerToken: true,
    },
  });

export const createEmailTemplateHttpCall = (data: EmailTemplateParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.CREATE_EMAIL_TEMPLATE,
    payload: {
      url: endpoints.private.createEmailTemplate,
      method: 'post',
      useBearerToken: true,
      body: data,
    },
  });

export const updateEmailTemplateHttpCall = (data: EmailTemplateParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.UPDATE_EMAIL_TEMPLATE,
    payload: {
      url: endpoints.private.updateEmailTemplate(data?.id ?? ''),
      method: 'put',
      useBearerToken: true,
      body: data,
    },
  });

export const deleteEmailTemplateHttpCall = (id: EmailTemplateParams['id']) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.DELETE_EMAIL_TEMPLATE,
    payload: {
      url: endpoints.private.deleteEmailTemplate(id ?? ''),
      method: 'delete',
      useBearerToken: true,
    },
  });

export const emailTemplateSendTestMailHttpCall = (data: EmailTemplateSendTestMailParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.EMAIL_TEMPLATE_SEND_TEST_MAIL,
    payload: {
      url: endpoints.private.emailTemplateSendTestMail,
      method: 'post',
      useBearerToken: true,
      body: data,
    },
  });

// ------------------------Pricing------------------
export const createLocationHttpCall = (data: CreateLocation) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.CREATE_LOCATION,
    payload: {
      url: endpoints.private.createLocation,
      method: 'post',
      useBearerToken: true,
      body: data,
    },
  });

export const updateLocationHttpCall = ({ id, ...others }: UpdateLocation) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.UPDATE_LOCATION,
    payload: {
      url: endpoints.private.updateLocation(id),
      method: 'put',
      useBearerToken: true,
      body: others,
    },
  });

export const deleteLocationHttpCall = (id: string) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.DELETE_LOCATION,
    payload: {
      url: endpoints.private.deleteLocation(id),
      method: 'delete',
      useBearerToken: true,
    },
  });

export const getAllLocationsHttpCall = (data: GetLocationsParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_LOCATIONS,
    payload: {
      url: endpoints.private.getAllLocations(data),
      method: 'get',
      useBearerToken: true,
    },
  });
