import { endpoints } from '@/api/endpoints';
import { performHttpCall } from '../httpSlice';
import { CORRELATION_IDS } from '../correlationIds';
import {
  CustomerProfileParams,
  ClientListFilterParams,
  UserListFilterParams,
  QuickNotesParams,
  ActivityLogsParams,
  EquipmentParams,
  EquipmentBodyParams,
  MoveClientParams,
  ClientFilterParams,
  FilesParams,
  ClientItem,
} from '@/types';

export const getClientsHttpCall = (params: ClientListFilterParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_CLIENT_LIST,
    payload: {
      url: endpoints.private.getClients(params),
      method: 'get',
      useBearerToken: true,
    },
  });

export const getClientStaffMembersDropdownHttpCall = (params: UserListFilterParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_CLIENT_STAFF_MEMBERS,
    payload: {
      url: endpoints.private.getUsers(params),
      method: 'get',
      useBearerToken: true,
    },
  });

export const createClientHttpCall = (params: CustomerProfileParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.CREATE_CLIENT,
    payload: {
      url: endpoints.private.createClient,
      method: 'post',
      useBearerToken: true,
      body: params,
    },
  });

export const updateClientHttpCall = (params: CustomerProfileParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.UPDATE_CLIENT,
    payload: {
      url: endpoints.private.updateClient(params?.id),
      method: 'put',
      useBearerToken: true,
      body: params,
    },
  });

export const updateClientVMCountHttpCall = (id: string) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.UPDATE_CLIENT_VM_COUNT,
    payload: {
      url: endpoints.private.updateClientVMCount(id ?? ''),
      method: 'patch',
      useBearerToken: true,
    },
  });

export const sendClientContractHttpCall = (id: string) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.SEND_CLIENT_CONTRACT,
    payload: {
      url: endpoints.private.sendsClientContract(id ?? ''),
      method: 'get',
      useBearerToken: true,
    },
  });

export const restoreDeactivatedClientHttpCall = (id: string) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.RESTORE_DEACTIVATED_CLIENT,
    payload: {
      url: endpoints.private.restoreDeactivatedClient(id ?? ''),
      method: 'patch',
      useBearerToken: true,
      body: {
        name: 'Clients',
      },
    },
  });

export const createClientEquipmentsHttpCall = (params: EquipmentBodyParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.CREATE_CLIENT_EQUIPMENTS,
    payload: {
      url: endpoints.private.createClientEquipments(params?.leadId),
      method: 'put',
      useBearerToken: true,
      body: {
        equipments: params.equipments,
      },
    },
  });

export const getClientEquipmentsHttpCall = (id: string) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_CLIENT_EQUIPMENTS,
    payload: {
      url: endpoints.private.getClientEquipments(id),
      method: 'get',
      useBearerToken: true,
    },
  });

export const deleteClientEquipmentHttpCall = (id: EquipmentParams['id']) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.DELETE_CLIENT_EQUIPMENT,
    payload: {
      url: endpoints.private.deleteClientEquipment(id ?? ''),
      method: 'delete',
      useBearerToken: true,
    },
  });

export const deleteAllClientEquipmentsHttpCall = (id: EquipmentParams['id']) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.DELETE_CLIENT_EQUIPMENT,
    payload: {
      url: endpoints.private.deleteAllClientEquipments(id ?? ''),
      method: 'delete',
      useBearerToken: true,
    },
  });

export const updateCustomerClientHttpCall = (params: CustomerProfileParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.UPDATE_CUSTOMER_CLIENT,
    payload: {
      url: endpoints.private.updateCustomerClient(params?.id),
      method: 'patch',
      useBearerToken: true,
      body: params,
    },
  });

export const updateClientCustomerDetailsHttpCall = (params: CustomerProfileParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.UPDATE_CLIENT_CUSTOMER_DETAILS,
    payload: {
      url: endpoints.private.updateClientCustomerDetails(params?.id),
      method: 'put',
      useBearerToken: true,
      body: params,
    },
  });

export const getClientQuickNotesHttpCall = (id: QuickNotesParams['id']) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_CLIENT_QUICK_NOTES,
    payload: {
      url: endpoints.private.getClientQuickNotes(id),
      method: 'get',
      useBearerToken: true,
    },
  });

export const createClientQuickNotesHttpCall = (data: QuickNotesParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.CREATE_CLIENT_QUICK_NOTES,
    payload: {
      url: endpoints.private.createClientQuickNotes(data?.id ?? ''),
      method: 'post',
      useBearerToken: true,
      body: data,
    },
  });

export const updateClientQuickNotesHttpCall = (data: QuickNotesParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.UPDATE_CLIENT_QUICK_NOTES,
    payload: {
      url: endpoints.private.updateClientQuickNotes(data?.id ?? ''),
      method: 'put',
      useBearerToken: true,
      body: data,
    },
  });

export const deleteClientQuickNotesHttpCall = (id: QuickNotesParams['id']) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.DELETE_CLIENT_QUICK_NOTES,
    payload: {
      url: endpoints.private.deleteClientQuickNotes(id ?? ''),
      method: 'delete',
      useBearerToken: true,
    },
  });

export const getClientActivityLogsHttpCall = (params: ActivityLogsParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_CLIENT_ACTIVITY_LOGS,
    payload: {
      url: endpoints.private.getClientActivityLogs(params),
      method: 'get',
      useBearerToken: true,
    },
  });

export const moveClientHttpCall = (params: MoveClientParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.MOVE_CLIENT,
    payload: {
      url: endpoints.private.moveClient(params?.leadId),
      method: 'patch',
      useBearerToken: true,
      body: params,
    },
  });

export const sendClientFilterHttpCall = (data: ClientFilterParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.SEND_CLIENT_FILTER,
    payload: {
      url: endpoints.private.sendClientFilter,
      method: 'post',
      useBearerToken: true,
      body: data,
    },
  });

export const uploadClientFileHttpCall = (
  id: FilesParams['id'],
  formData: any,
  onUploadProgress: (progressEvent: any) => void,
) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.UPLOAD_CLIENT_FILE,
    payload: {
      url: endpoints.private.uploadClientFile(id ?? ''),
      method: 'post',
      useBearerToken: true,
      body: formData,
      onUploadProgress,
    },
  });

export const getClientFileDetailsHttpCall = (id: any) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_CLIENT_FILE_DETAILS,
    payload: {
      url: endpoints.private.getClientFileDetails(id),
      method: 'get',
      useBearerToken: true,
    },
  });

export const deleteClientFileHttpCall = (id: any) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.DELETE_CLIENT_FILE,
    payload: {
      url: endpoints.private.deleteClientFile(id ?? ''),
      method: 'delete',
      useBearerToken: true,
    },
  });

export const getClientContractPDFHttpCall = (leadId: ClientItem['id']) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_CLIENT_CONTRACT_PDF,
    payload: {
      url: endpoints.private.getClientContractPDF(leadId),
      method: 'get',
      useBearerToken: true,
    },
  });
