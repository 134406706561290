import { AppTheme } from './ThemeContext';

export function getCurrentTheme(): AppTheme {
  const value = window.localStorage.getItem('app_theme');
  return value === null || typeof value === 'undefined' ? 'light' : value === 'dark' ? 'dark' : 'light';
}

export function setTheme(theme: AppTheme): void {
  window.localStorage.setItem('app_theme', theme);
}

export function themeParser(light: any, dark: any, currentTheme: AppTheme): string {
  return currentTheme === 'light' ? light : dark;
}
