import React, { useRef, useEffect, useState } from 'react';
import { useLeadExpandBodyStyle } from '@/static/stylesheets/organisms';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { Box, Dialog } from '@mui/material';
import { colors } from '@/theme';
import { apiClient, endpoints } from '@/api';

interface ActivityProps {
  leadData?: any;
  leadsActivity?: any;

  onReload?(): void;
}

const Activity: React.FC<ActivityProps> = (props) => {
  const classes = useLeadExpandBodyStyle();
  // const fetchActivityLogs = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_ACTIVITY_LOGS];
  const getActivityLog = props?.leadsActivity ?? [];
  const listInnerRef = useRef<HTMLDivElement>(null);
  const [currPage, setCurrPage] = useState(1);
  const [prevPage, setPrevPage] = useState(0);
  const [userList, setUserList] = useState<any[]>([]);
  const [lastList, setLastList] = useState(false);
  const [emailPreviewDialogOpen, setEmailPreviewDialogOpen] = useState(false);
  const [selectedEmailPreviewActivityLog, setSelectedEmailPreviewActivityLog] = useState<any>(null);
  const [emailPreviewData, setEmailPreviewData] = useState<any>(null);
  // console.log(fetchActivityLogs?.data?.last_page !== currPage)

  const openEmailPreviewDialog = (activityEmailId: string) => {
    setEmailPreviewDialogOpen(true);
    setSelectedEmailPreviewActivityLog(activityEmailId);
  };

  const closeEmailPreviewDialog = () => {
    setEmailPreviewDialogOpen(false);
    setSelectedEmailPreviewActivityLog(null);
    setEmailPreviewData(null);
  };

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      const total = (scrollTop + clientHeight).toFixed();
      if (Number(total) === scrollHeight) {
        setCurrPage(currPage + 1);
      }
    }
  };

  const fetchData = () => {
    if (props?.onReload) {
      props?.onReload();
    }
  };

  const getEmailPreviewData = () => {
    apiClient
      .get({
        url: endpoints.private.getSingleActivityEmail(selectedEmailPreviewActivityLog?.activity_email_id),
        method: 'get',
        useBearerToken: true,
      })
      .then(({ data }) => {
        if (data) {
          setEmailPreviewData(data);
        }
      })
      .catch(() => {
        alert('Failed to get email preview data!');
      });
  };

  useEffect(() => {
    if (emailPreviewDialogOpen && selectedEmailPreviewActivityLog) {
      getEmailPreviewData();
    }
  }, [selectedEmailPreviewActivityLog, emailPreviewDialogOpen]);

  useEffect(() => {
    if (!lastList && prevPage !== currPage) {
      fetchData();
    }
  }, [currPage, lastList, prevPage, userList]);

  useEffect(() => {
    const fetchInterval = setInterval(() => {
      fetchData();
    }, 30000);

    return () => {
      clearInterval(fetchInterval);
    };
  }, []);

  return (
    <>
      <div className={classes.activityBody}>
        <List>
          <ListItem alignItems='flex-start' className={classes.activityHeader}>
            <ListItemText primary='Activity Log'></ListItemText>
          </ListItem>
          <Divider />
          <div onScroll={onScroll} ref={listInnerRef} className={'list_body activity_list'}>
            {getActivityLog &&
              getActivityLog?.length > 0 &&
              getActivityLog?.map((item: any, index: number) => (
                <div key={index}>
                  <ListItem alignItems='flex-start'>
                    <ListItemText
                      disableTypography
                      secondary={
                        <React.Fragment>
                          <div className={'text_body'}>
                            <Typography sx={{ display: 'inline' }} component='div' variant='body2'>
                              <div>{item?.activity}</div>
                              {item?.activity_email_id && (
                                <div>
                                  <a onClick={() => openEmailPreviewDialog(item)}>Show Email</a>
                                </div>
                              )}
                            </Typography>
                            <Typography sx={{ display: 'inline' }} component='span' variant='body1'>
                              {item?.timestamp}
                            </Typography>
                          </div>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <Divider />
                </div>
              ))}
            {/*{fetchActivityLogs?.loading &&
              <CircularProgress style={{
                width: 14,
                height: 14,
                margin: "auto",
                display: "flex",
                padding: 5,
              }} />
            }  */}
          </div>
        </List>
      </div>

      <Dialog
        open={emailPreviewDialogOpen}
        onClose={closeEmailPreviewDialog}
        maxWidth={'lg'}
        sx={{
          '& .MuiPaper-root': {
            width: '1000px',
            height: '100%',
          },
        }}
      >
        <Box
          sx={{
            padding: '30px',
          }}
        >
          <Box
            sx={{
              textAlign: 'center',
              '& a': {
                color: colors.primary,
              },
            }}
          >
            {'This email was sent to '}
            <a>
              {props?.leadData?.customer?.first_name} {props?.leadData?.customer?.last_name}
            </a>
            {'  on '} {emailPreviewData?.datetime?.date} {' at '} {emailPreviewData?.datetime?.time}
          </Box>
          <Box
            sx={{ marginTop: '20px' }}
            dangerouslySetInnerHTML={{
              __html: emailPreviewData?.body,
            }}
          />
        </Box>
      </Dialog>
    </>
  );
};

export { Activity };
