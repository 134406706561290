import * as React from 'react';
import { Button } from '@/components/atoms/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useSettingDialogStyle } from '@/static/stylesheets/molecules';
import DialogTitle from '@mui/material/DialogTitle';
import { TextInput } from '@/components/atoms/TextInput';
import { CloseButton } from '@/components/atoms/Button/CloseButton';
import { EquipmentParams } from '@/types';
import { useAppSelector, useInput } from '@/hooks';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import toast from 'react-hot-toast';
import { useEffect } from 'react';
import { clearCreatedEquipment, clearUpdatedEquipment, createEquipment, updateEquipment } from '@/redux/actions';
import { convertToString, validateURL } from '@/utils';
import { alertMessages } from '@/config';

interface EquipmentFormProps {
  open?: any;
  formData?: EquipmentParams | null;

  onClose?(): void;

  onReload?(): void;
}

const EquipmentForm: React.FC<EquipmentFormProps> = (props) => {
  const classes = useSettingDialogStyle();
  const { open, onClose, formData: editFormData } = props;
  const editMode = editFormData && editFormData?.id !== '';

  const createdEquipment = useAppSelector(selectHttpState)[CORRELATION_IDS.CREATE_EQUIPMENT];
  const updatedEquipment = useAppSelector(selectHttpState)[CORRELATION_IDS.UPDATE_EQUIPMENT];
  const loading = createdEquipment?.loading || updatedEquipment?.loading;

  const {
    value: description,
    setValue: setDescription,
    reset: resetDescription,
    bind: bindDescription,
    error: descriptionError,
  } = useInput('', {
    required: 'Please enter description!',
  });

  const {
    value: sku,
    setValue: setSku,
    reset: resetSku,
    bind: bindSku,
    error: skuError,
  } = useInput('', {
    required: 'Please enter sku!',
  });

  const {
    value: link,
    setValue: setLink,
    reset: resetLink,
    bind: bindLink,
    error: linkError,
  } = useInput('', {
    validate(value) {
      if (!validateURL(value)) {
        return 'Please enter a valid URL! (Should start with http:// or https://)';
      }

      return true;
    },
  });

  const {
    value: ourCost,
    setValue: setOurCost,
    reset: resetOurCost,
    bind: bindOurCost,
    error: ourCostError,
  } = useInput('', {
    validate(value) {
      if (convertToString(value).trim() === '') {
        return 'Please enter our cost!';
      }

      if (Number(value) < 0) {
        return 'Our cost should be greater than 0';
      }

      return true;
    },
  });

  const {
    value: clientCost,
    setValue: setClientCost,
    reset: resetClientCost,
    bind: bindClientCost,
    error: clientCostError,
  } = useInput('', {
    validate(value) {
      if (convertToString(value).trim() === '') {
        return 'Please enter client cost!';
      }

      if (Number(value) < 0) {
        return 'Client cost should be greater than 0';
      }

      return true;
    },
  });

  const resetFields = () => {
    resetDescription();
    resetSku();
    resetLink();
    resetOurCost();
    resetClientCost();
  };

  const submitEquipmentData = () => {
    descriptionError.check();
    skuError.check();
    linkError.check();
    ourCostError.check();
    clientCostError.check();

    if (
      descriptionError.check() ||
      skuError.check() ||
      linkError.check() ||
      ourCostError.check() ||
      clientCostError.check()
    ) {
      toast.error(alertMessages.generalSettings.equipments.submitValidationError);
      return;
    }

    const data: EquipmentParams = {
      equipment_name: description,
      sku,
      url: link,
      client_cost: clientCost,
      our_cost: ourCost,
    };

    if (editMode && editFormData?.id) {
      data.id = editFormData?.id;
    }

    if (editMode) {
      updateEquipment(data);
    } else {
      createEquipment(data);
    }
  };

  useEffect(() => {
    if (createdEquipment && createdEquipment.success) {
      toast.success(alertMessages.generalSettings.equipments.createSuccess);
      if (props.onClose) {
        props?.onClose();
      }
      if (props.onReload) {
        props?.onReload();
      }
      clearCreatedEquipment();
    }

    if (createdEquipment && createdEquipment?.error) {
      const error = createdEquipment?.error;
      if (error?.status === 422) {
        toast.error(error?.data?.message);
      } else {
        toast.error(alertMessages.generalSettings.equipments.createError);
      }
      clearCreatedEquipment();
    }
  }, [createdEquipment]);

  useEffect(() => {
    if (updatedEquipment && updatedEquipment.success) {
      toast.success(alertMessages.generalSettings.equipments.saveSuccess);
      if (props.onClose) {
        props?.onClose();
      }
      if (props.onReload) {
        props?.onReload();
      }
      clearUpdatedEquipment();
    }

    if (updatedEquipment && updatedEquipment?.error) {
      const error = updatedEquipment?.error;
      if (error?.status === 422) {
        toast.error(error?.data?.message);
      } else {
        toast.error(alertMessages.generalSettings.equipments.saveError);
      }
      clearUpdatedEquipment();
    }
  }, [updatedEquipment]);

  useEffect(() => {
    if (editMode) {
      setDescription(editFormData?.equipment_name);
      setLink(editFormData?.url);
      setSku(editFormData?.sku);
      setOurCost(editFormData?.our_cost);
      setClientCost(editFormData?.client_cost);
    }
  }, [editMode, editFormData]);

  useEffect(() => {
    if (!open) {
      resetFields();
    }
  }, [open]);

  return (
    <>
      <Dialog className={classes.root} open={open} onClose={onClose} aria-labelledby='responsive-dialog-title'>
        <DialogTitle className={classes.DialogTitle} id='responsive-dialog-title'>
          {`${editMode ? 'Edit' : 'Add'} Item to Equipment List`}
        </DialogTitle>
        <div onClick={onClose}>
          <CloseButton />
        </div>
        <DialogContent>
          <DialogContentText className={classes.DialogContent}>
            <TextInput
              variant={'standard'}
              label={'Description'}
              fullWidth={true}
              {...bindDescription}
              error={descriptionError?.error}
              errorText={descriptionError?.message}
            />
            <TextInput
              variant={'standard'}
              label={'SKU'}
              fullWidth={true}
              {...bindSku}
              error={skuError?.error}
              errorText={skuError?.message}
            />
            <TextInput
              variant={'standard'}
              label={'Link'}
              fullWidth={true}
              {...bindLink}
              error={linkError?.error}
              errorText={linkError?.message}
            />
            <TextInput
              variant={'standard'}
              label={'Our Cost (1 unit)'}
              type={'number'}
              fullWidth={true}
              {...bindOurCost}
              error={ourCostError?.error}
              errorText={ourCostError?.message}
            />
            <TextInput
              variant={'standard'}
              type={'number'}
              label={'Client Cost (1 unit)'}
              fullWidth={true}
              {...bindClientCost}
              error={clientCostError?.error}
              errorText={clientCostError?.message}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={submitEquipmentData}
            color={'primary'}
            variant={'contained'}
            title={`${editMode ? 'Save' : 'Add'} Item`}
            loading={loading}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export { EquipmentForm };
