import React from 'react';
import { EquipmentOrdersList } from '@/components/molecules/EquipmentOrders';
import { SettingsLayout } from '@/components/layouts';
import { PrivateLayout } from '@/components/Middleware';

const EquipmentOrders = () => {
  return (
    <>
      <PrivateLayout>
        <SettingsLayout
          hideSettingsTabPanel={false}
          hideHeaderRightSide={true}
          containerSize={'xxl'}
          featureType={'equipment'}
        >
          <EquipmentOrdersList />
        </SettingsLayout>
      </PrivateLayout>
    </>
  );
};

export default EquipmentOrders;
