import { FloatingActionButton } from '@/components/atoms/FloatingActionButton';
import { BackToTopButton } from '@/components/atoms/BackToTopButton';
import { Container } from '@/components/organisms/Container';
import { Header } from '@/components/molecules/Header';
import { SettingsTabPanel } from '@/components/molecules/SettingsTabPanel';
const FloatingAction = () => {
  return (
    <>
      <Header />
      <SettingsTabPanel />
      <FloatingActionButton />
      <BackToTopButton />
      <Container size={'lg'} />
    </>
  );
};

export default FloatingAction;
