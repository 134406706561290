import React, { useEffect } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { themeParser, useAppTheme } from '@/theme';
import { useAppSelector } from '@/hooks';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { ReportsFormData, UserParams } from '@/types';
import { getReportStaffMembers } from '@/redux/actions';
import { Checkbox } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

const AssignedToPicker = () => {
  const theme = useAppTheme();

  const staffMemberListResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_REPORT_STAFF_MEMBERS];
  const staffMembers: UserParams[] = staffMemberListResponse?.data?.data ?? [];

  const { watch, setValue, control } = useFormContext<ReportsFormData>();

  const formValues = watch();

  const toggleAssignedTo = (id: string) => {
    const items = [...(formValues?.assigned_to ?? [])];
    const index = items?.indexOf(id);
    if (index === -1) {
      items?.push(id);
    } else {
      items?.splice(index, 1);
    }
    setValue('assigned_to', items);
  };

  const isAssignedToChecked = (id?: string) => {
    const items = [...(formValues?.assigned_to ?? [])];
    return !!(id && items?.indexOf(id) > -1);
  };

  const getStaffMemberList = () =>
    getReportStaffMembers({
      limit: 'all',
      page: 1,
    });

  useEffect(() => {
    getStaffMemberList();
  }, []);

  return (
    <div className={'report-filter-option-container assigned-to-picker-container'}>
      <div className={'report-filter-option-title'}>Assigned To</div>
      <div className={'report-filter-option-body'}>
        <FormGroup
          sx={{
            '& .MuiTypography-root': {
              fontSize: '13px',
              fontWeight: 400,
              marginLeft: '5px',
              color: themeParser('#313131', '#b7b7b7', theme),
            },
            '& .MuiFormControlLabel-root': {
              height: '25px',
              marginLeft: '3px',
            },
          }}
        >
          <Controller
            control={control}
            name={'assigned_to'}
            render={() => (
              <>
                {staffMembers?.map((item, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        color={'primary'}
                        checked={isAssignedToChecked(item?.id)}
                        onChange={() => {
                          if (item?.id) {
                            toggleAssignedTo(item?.id);
                          }
                        }}
                      />
                    }
                    label={`${item?.first_name ?? ''} ${item?.last_name ?? ''}`}
                  />
                ))}
              </>
            )}
          />
        </FormGroup>
      </div>
    </div>
  );
};

export { AssignedToPicker };
