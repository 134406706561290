import React from 'react';
import { NavigationTabPanelItem } from '@/components/organisms/NavigationTabPanel/NavigationTabPanel';
import { useNavigationTabPanelStyles } from '@/static/stylesheets/organisms';
import { useMatch, useNavigate } from 'react-router-dom';
import classnames from 'classnames';

const MenuItem = ({ menu }: { menu: NavigationTabPanelItem }) => {
  const classes = useNavigationTabPanelStyles();
  const navigate = useNavigate();
  const routeMatch = useMatch(menu?.route);

  const handleClickItem = (route: string) => {
    navigate(route);
  };

  return (
    <li className={classes.tabItem} onClick={() => handleClickItem(menu?.route)}>
      <span
        className={classnames({
          [classes.tabText]: true,
          [classes.active]: routeMatch,
        })}
      >
        {menu?.label}
      </span>
    </li>
  );
};

export { MenuItem };
