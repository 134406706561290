import { ContentWrapper } from '@/components/organisms/Reports';
import { ReportTable, Tbody, Td, Tfoot, Th, Thead, Tr } from '@/components/organisms/Reports/ReportContent/ReportTable';
import { useAppSelector } from '@/hooks';
import { getGeneralTotalPlYearSummaryReports } from '@/redux/actions';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { PlYearSummaryReportMonth } from '@/types';
import { formatCurrency } from '@/utils';
import { useEffect } from 'react';

const YearSummary = () => {
  const result = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_TOTAL_PL_YEAR_SUMMARY_REPORT];
  const currentYear = new Date().getFullYear();
  useEffect(() => {
    getGeneralTotalPlYearSummaryReports({
      report_type: 'yearly',
    });
  }, []);

  return (
    <>
      <ContentWrapper headings={['Total Profit & Loss Report | All New Business']}>
        {result?.data ? (
          <>
            <ReportTable borderBottomWidth={'4px'}>
              <Tbody>
                {/* Table header */}
                <Tr>
                  <Th colSpan={9}>{currentYear} ACTUALS - NEW INCOME ONLY</Th>
                </Tr>
                <Tr>
                  <Td width={'238px'}></Td>
                  <Td align={'center'}>1 Month Recurring</Td>
                  <Td align={'center'}>Rem. Months of Yr</Td>
                  <Td align={'center'}>Next Year (full yr)</Td>
                  <Td align={'center'}>Installation Rev</Td>
                  <Td align={'center'}>Tech Rev</Td>
                  <Td align={'center'}>Expenses</Td>
                  <Td align={'center'}># of Installs</Td>
                  <Td align={'center'}># of Tech Jobs</Td>
                </Tr>

                {/* Table body */}
                {result.data &&
                  Object.keys(result.data.month_wise_report).map((key: string) => {
                    const {
                      one_month_recurring,
                      remaining_months_recurring,
                      next_year_recurring,
                      install_revenue,
                      tech_revenue,
                      expenses,
                      install_count,
                      job_count,
                    }: PlYearSummaryReportMonth = result.data.month_wise_report[key];

                    return (
                      <Tr key={key}>
                        <Td>{key}</Td>
                        <Td align={'center'}>{formatCurrency(one_month_recurring)}</Td>
                        <Td align={'center'}>{formatCurrency(remaining_months_recurring)}</Td>
                        <Td align={'center'}>{formatCurrency(next_year_recurring)}</Td>
                        <Td align={'center'}>{formatCurrency(install_revenue)}</Td>
                        <Td align={'center'}>{formatCurrency(tech_revenue)}</Td>
                        <Td align={'center'}>{formatCurrency(expenses)}</Td>
                        <Td align={'center'}>{install_count}</Td>
                        <Td align={'center'}>{job_count}</Td>
                      </Tr>
                    );
                  })}
              </Tbody>
              <Tfoot>
                {result.data.total_report && (
                  <Tr>
                    <Td>Total</Td>
                    <Td align={'center'}>{formatCurrency(result.data.total_report.one_month_recurring)}</Td>
                    <Td align={'center'}>{formatCurrency(result.data.total_report.remaining_months_recurring)}</Td>
                    <Td align={'center'}>{formatCurrency(result.data.total_report.next_year_recurring)}</Td>
                    <Td align={'center'}>{formatCurrency(result.data.total_report.install_revenue)}</Td>
                    <Td align={'center'}>{formatCurrency(result.data.total_report.tech_revenue)}</Td>
                    <Td align={'center'}>{formatCurrency(result.data.total_report.expenses)}</Td>
                    <Td align={'center'}>{result.data.total_report.install_count ?? '-'}</Td>
                    <Td align={'center'}>{result.data.total_report.job_count ?? '-'}</Td>
                  </Tr>
                )}
              </Tfoot>
            </ReportTable>

            <ReportTable>
              <Thead>
                <Tr>
                  <Th width={'238px'}>Year Total Gross Revenue</Th>
                  <Th colSpan={8}>{formatCurrency(result.data.total_report.gross_revenue)}</Th>
                </Tr>
                <Tr>
                  <Th>Year Total Net Revenue</Th>
                  <Th colSpan={8}>{formatCurrency(result.data.total_report.net_revenue)}</Th>
                </Tr>
              </Thead>
            </ReportTable>
          </>
        ) : (
          <div
            style={{
              minHeight: '100px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            No Data
          </div>
        )}
      </ContentWrapper>
    </>
  );
};

export { YearSummary };
