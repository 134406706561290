import { Button } from '@/components/atoms/Button';
import { alertMessages } from '@/config';
import { useAppSelector } from '@/hooks';
import { clearLeadSendContractResponse, getLeadContractPDF, sendLeadContract } from '@/redux/actions';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { useSettingDialogStyle } from '@/static/stylesheets/molecules';
import { Box, CircularProgress } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

interface ContractModalProps {
  open?: any;
  leadData?: any;

  onClose?(): void;
}

const ContractModal: React.FC<ContractModalProps> = (props) => {
  const classes = useSettingDialogStyle();
  const { open, onClose, leadData } = props;
  const contractPdfLinkResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_LEAD_CONTRACT_PDF];
  const contractSendResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.SEND_LEAD_CONTRACT];
  const [loading, setLoading] = useState<boolean>(true);
  const [iframeLoaded, setIframeLoaded] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [contractPdfFile, setContractPdfFile] = useState<string | null>(null);
  const [targetId, setTargetId] = useState<string | null>(null);

  const onSendContract = () => {
    sendLeadContract(leadData?.id);
    setButtonLoading(true);
  };

  const Loader = () => (
    <Box
      sx={{
        textAlign: 'center',
      }}
    >
      <CircularProgress style={{ color: '#ffffff' }} />
    </Box>
  );

  const generateFileLink = () => {
    setTargetId(leadData?.id);
    getLeadContractPDF(leadData?.id);
  };

  useEffect(() => {
    if (open) {
      const file = leadData?.contract_file;

      /*if (file && getFileExtension(file) === "pdf") {
        setLoading(false);
        setContractPdfFile(file);
      } else {
        generateFileLink();
      }*/
      generateFileLink();
    }
  }, [leadData?.contract_file, open]);

  useEffect(() => {
    if (open && targetId === leadData?.id) {
      if (contractPdfLinkResponse && contractPdfLinkResponse?.success) {
        setContractPdfFile(() => contractPdfLinkResponse?.data);
        setLoading(false);
      }

      if (contractPdfLinkResponse && contractPdfLinkResponse?.error) {
      }
    }
  }, [contractPdfLinkResponse, open, targetId]);

  useEffect(() => {
    if (open && targetId === leadData?.id) {
      if (contractSendResponse && contractSendResponse?.success) {
        toast.success(alertMessages.leads.docusignSendSuccess);
        setButtonLoading(false);
        clearLeadSendContractResponse();
      }

      if (contractSendResponse && contractSendResponse?.error) {
        toast.error(alertMessages.leads.docusignSendError);
        setButtonLoading(false);
        clearLeadSendContractResponse();
      }
    }
    if (contractSendResponse?.success || contractSendResponse?.error) {
      setButtonLoading(false);
    }
  }, [open, contractSendResponse, targetId]);

  return (
    <>
      <Dialog
        className={classes.pdfView}
        open={open}
        onClose={onClose}
        maxWidth={'lg'}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogContent>
          {loading ? (
            <Loader />
          ) : (
            <div style={{ backgroundColor: '#fff' }}>
              {contractPdfFile && (
                <iframe
                  src={`https://docs.google.com/viewer?url=${contractPdfFile}&embedded=true`}
                  onLoad={() => {
                    setIframeLoaded(true);
                  }}
                  width='1200px'
                  height='100%'
                  frameBorder='0'
                  style={{ margin: 0 }}
                />
              )}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          {loading ? (
            <CircularProgress
              style={{
                color: 'white',
              }}
            />
          ) : (
            <>
              <Button
                fullWidth={false}
                onClick={onClose}
                color={'error'}
                variant={'contained'}
                title={'do not send yet'}
              />
              <Button
                fullWidth={false}
                onClick={onSendContract}
                color={'success'}
                variant={'contained'}
                title={'Looks Good, Send to DocuSign'}
                loading={buttonLoading}
              />
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export { ContractModal };
