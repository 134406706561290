import { colors, themeParser, useAppTheme } from '@/theme';
import { css } from '@emotion/css';

export const useTaskStyles = () => {
  const theme = useAppTheme();

  return {
    root: css`
      .MuiDialog-paper {
        background: ${themeParser(colors.body.light, colors.body.dark, theme)};
      }
    `,

    createTaskDialogRoot: css`
      .MuiDialog-paper {
        background: ${themeParser(colors.body.light, colors.body.dark, theme)};
        width: -webkit-fill-available;
      }

      button {
        width: 100%;
        font-weight: 500;
        color: ${themeParser(colors.body.light, colors.black.default, theme)};
        margin: 0px 14px 25px 14px;
      }

      @media (max-width: 600px) {
        .MuiGrid-grid-sm-12 {
          width: 100%;
        }

        .MuiGrid-grid-sm-11 {
          width: 90%;
        }

        .MuiGrid-grid-sm-1 {
          width: 10%;
        }
      }

      .calculator {
        label {
          color: ${themeParser('rgba(0,0,0,0.87)', colors.primary, theme)};
          font-size: 15px;
          font-family: 'Poppins';
          font-weight: 500;
        }

        input {
          color: ${themeParser('rgba(0,0,0,0.87)', colors.primary, theme)};
        }

        button {
          margin: 15px 0px;

          svg {
            color: ${themeParser('rgba(0,0,0,0.87)', colors.primary, theme)};
          }
        }

        .MuiAutocomplete-root button {
          margin: 0px 14px 25px 14px;
          width: auto;
        }

        .delete {
          font-size: 20px;
          color: #F44336;
          cursor: pointer;
        }

        .add button {
          background-color: transparent !important;
          box-shadow: none;
          color: #b5b5b5 !important;
          border: 1px solid #e0e0e0;
          font-weight: 500;
          height: 32px;
          line-height: 32px;
        }

        .MuiInput-underline:hover:before,
        .MuiInput-underline:focus:before {
          border-bottom: 1px solid !important;
          color: ${colors.primary}
        }
      }
    }
    `,

    DialogContentRoot: css`
      padding: 0 20px 20px 20px !important;

      scroll-behavior: smooth;
      ::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */

      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 6px;
      }

      /* Handle */

      ::-webkit-scrollbar-thumb {
        background: #9e9898;
      }

      ::-webkit-scrollbar-thumb:hover {
        background: #2196f3;
      }
    `,

    DialogContent: css`
      padding: 0px 0px 0px 0px;

      .MuiFormControl-root {
        margin-top: 25px;
      }

      .MuiInputLabel-root {
        color: ${themeParser('#000000de', '#a6a6a6', theme)} !important;
      }

      .MuiFormControlLabel-label,
      .MuiOutlinedInput-root,
      .MuiSvgIcon-root {
        color: ${themeParser('#000000de', '#a6a6a6', theme)} !important;
      }

      .error {
        font-size: 12px;
        color: #d32f2f;
      }

      .custom-input-label {
        .MuiInputLabel-root {
          color: ${themeParser('#000000de', '#a6a6a6', theme)} !important;
        }
      }

      .install_search .MuiInput-root {
        color: ${themeParser(colors.body.dark, colors.primary, theme)};
      }

      .install_search .MuiInput-root:before {
        border-bottom: 1px solid ${themeParser(colors.primary, colors.primary, theme)};
      }

      .install_search .MuiInput-root button .MuiSvgIcon-fontSizeSmall {
        background: ${themeParser(colors.body.dark, colors.body.light, theme)};
      }
    `,

    createTasksTitleBar: css`
      background: transparent;
      margin: 0px 20px !important;
      text-transform: uppercase !important;
      font-weight: 500 !important;
      border-bottom: 1px solid ${themeParser('#e0e0e0', colors.primary, theme)};
      letter-spacing: 3px !important;
      color: ${themeParser('#000000', 'rgb(133, 133, 133)', theme)};
      font-size: 1.2rem !important;
      text-align: center;
    `,

    createTasksBottomBar: css`
      padding: 24px !important;
      justify-content: space-between !important;

      button {
        width: auto !important;
      }
    `,

    subTasksListContainer: css`
      padding: 15px;
      padding-right: 0;
      margin-right: -1px;
    `,

    subTaskFieldContainer: css`
      padding: 15px 10px 15px 0;

      .wrapper {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;

        svg {
          color: #f44336 !important;
        }

        .MuiFormControl-root {
          margin-top: 0 !important;
        }
      }

      .error-container {
        display: flex;
        align-items: center;
        font-size: 13px;
        color: #f44336 !important;
        gap: 5px;
        margin-top: 5px;

        svg {
          font-size: 15px;
          color: #f44336 !important;
        }
      }
    `,
    subTaskFieldContainerActive: css`
      padding-left: 15px !important;
      border: 1px solid ${themeParser('#dddddd', colors.primary, theme)};
      border-radius: 5px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 1px solid ${themeParser('#ffffff', '#2a2a2a', theme)};
      position: relative;
      z-index: 1;
    `,

    subTaskFieldAddButtonContainer: css`
      margin-top: 20px;
      padding-right: 10px;
    `,

    selectedTaskBodyContainer: css`
      border: 1px solid ${themeParser('#dddddd', colors.primary, theme)};

      .task-detail-container {
        padding: 5px;
        padding-bottom: 40px;
      }
    `,

    fileUploaderContainer: css`
      margin-top: 20px;

      .container {
        margin-top: 15px;

        .dropzone {
          border: 3px dashed ${themeParser('#ddd', '#dddddd29', theme)};
          border-radius: 9px;
          margin: 0;
          height: 100px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          cursor: pointer;
          transition: all 0.3s ease-in-out;

          p {
            margin: 0px;
            text-align: center;
            color: ${themeParser(colors.body.dark, colors.primary, theme)};
            font-family: 'Poppins', sans-serif;
          }

          :hover {
            border: 3px dashed ${colors.primary};
          }

          @media (max-width: 600px) {
            min-height: 200px;
          }
        }
      }

      .uploadedFilesContainer {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-top: 20px;

        .fileItem {
          position: relative;
          height: 110px;
          width: 110px;
          border-radius: 20px;

          .deleteButton {
            position: absolute;
            top: 2px;
            right: 2px;

            button {
              background: #706b6b;
            }

            * {
              color: #ffffff !important;
            }
          }
        }
      }
    `,

    tasksContentContainer: css`
      display: flex;
      flex-direction: row;
      flex-grow: 1;
    `,
    tasksFilterSidebarHide: css`
      animation: slide-out 0.5s forwards;
      @keyframes slide-out {
        0% {
          transform: translateX(0%);
        }
        100% {
          transform: translateX(-100%);
        }
      }
    `,
    tasksFilterSidebarActive: css`
      animation: slide-in 0.5s forwards;
      @keyframes slide-in {
        100% {
          transform: translateX(0%);
        }
      }
    `,
    tasksFilterSidebarContainer: css`
      box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
      width: 305px;
      display: flex;
      flex-direction: column;

      @media (max-width: 1600px) {
        min-width: 300px;
      }
      @media (max-width: 340px) {
        min-width: 260px;
      }

      position: fixed;
      height: calc(100% - 70px);
      z-index: 9999;
      background: ${themeParser(colors.body.light, colors.body.dark, theme)};
      transform: translateX(-100%);
      .filter_body_1 {
        height: calc(100vh - 20px);
        overflow-y: auto;
        overflow-x: hidden;
      }

      .close_filter_side .toggle {
        position: absolute;
        right: -40px;
        top: calc(50% - 20px);
        width: 45px;
        height: 35px;
        background: #2196f3;
        cursor: pointer;
        color: #fff;
        border-top-left-radius: 10%;
        border-top-right-radius: 10%;
        justify-content: center;
        align-items: center;
        transform: rotate(90deg);
        box-shadow: rgb(0 0 0 / 15%) 0px -3px 4px 0px;
        display: flex;
      }

      .filter-header {
        border-bottom: 1px solid ${themeParser('#e0e0e0', '#3e3e3e', theme)} !important;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 10px;
      }

      .filter-accordion {
        display: flex;
        padding: 10px 15px;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid ${themeParser('#e0e0e0', '#3e3e3e', theme)} !important;
        cursor: pointer;
      }

      .filter-elements {
        padding: 5px 15px;

        .MuiFormControl-root svg {
          color: ${themeParser('#000000de', '#a6a6a6', theme)};
        }
      }

      .filter-accordion svg {
        color: ${themeParser('#000000de', '#a6a6a6', theme)};
      }

      span,
      .filter-header {
        color: ${themeParser('#000000de', '#a6a6a6', theme)};
      }

      .MuiInput-input,
      .MuiInputLabel-root {
        font-size: 16px !important;
        color: ${themeParser('#000000de', colors.primary, theme)} !important;
      }
    `,

    tasksGridContainer: css`
      flex-grow: 1;
      padding: 20px;
      height: calc(100vh - 105px);

      overflow: auto;
      //margin-bottom: 60px;
      ::-webkit-scrollbar {
        width: 5px;
      }
      ::-webkit-scrollbar-track {
        background-color: #000000;
        -webkit-border-radius: 0px;
        border-radius: 0px;
      }
      ::-webkit-scrollbar-thumb {
        border-radius: 0px;
        background: #ddd;
      }

      @media (max-width: 920px) {
        padding: 10px;
      }

      .table-wrapper {
        box-shadow:
          0 2px 2px 0 rgb(0 0 0 / 14%),
          0 3px 1px -2px rgb(0 0 0 / 12%),
          0 1px 5px 0 rgb(0 0 0 / 20%);
        @media (max-width: 920px) {
          width: calc(100vw - 20px);
          overflow-x: auto;
        }

        table {
          width: 100%;
          border-collapse: collapse;
          //table-layout: fixed;

          th,
          td {
            border: 1px solid ${themeParser('#e0e0e0', '#3e3e3e', theme)} !important;
            padding: 2px;
            vertical-align: middle;
          }

          th {
            padding: 8px 5px;
            border-top: 0;
            color: ${themeParser('#000000de', '#a6a6a6', theme)} !important;
          }

          td {
            padding: 7px;
            color: ${themeParser('#000000de', '#a6a6a6', theme)};
          }

          .left {
            text-align: left !important;
          }

          .center {
            text-align: center !important;
          }

          .right {
            text-align: right !important;
          }

          .priority-button {
            cursor: pointer;
            color: #9e9e9e;

            :hover {
              opacity: 0.7;
            }
          }

          .task-title {
            color: #2196f3;
            cursor: pointer;
          }

          .high-priority {
            cursor: pointer !important;
            color: #f44336 !important;
            font-family: 'Poppins', sans-serif !important;
            font-weight: 500 !important;

            * {
              color: #f44336 !important;
              font-weight: 500 !important;
            }
          }

          .medium-priority {
            cursor: pointer !important;
            color: #fbc02d !important;
            font-family: 'Poppins', sans-serif !important;
            font-weight: 500 !important;

            * {
              color: #fbc02d !important;
              font-weight: 500 !important;
            }
          }

          .medium-priority-text-only {
            color: #ab8e04 !important;
          }

          .no-priority-text-only {
            color: ${themeParser('#000000', '#a6a6a6', theme)} !important;
          }

          .low-priority-text-only {
            color: ${themeParser('#000000', '#a6a6a6', theme)} !important;
          }

          .low-priority {
            cursor: pointer !important;
            color: #2196f3 !important;
            font-family: 'Poppins', sans-serif !important;
            font-weight: 500 !important;

            * {
              color: #2196f3 !important;
              font-weight: 500 !important;
            }
          }

          .no-priority {
            cursor: pointer !important;
            color: #9e9e9e !important;
            font-family: 'Poppins', sans-serif !important;
            font-weight: 500 !important;

            * {
              color: #9e9e9e !important;
              font-weight: 500 !important;
            }
          }

          .action-buttons {
            display: flex;
            gap: 5px;
            justify-content: center;
            align-items: center;
          }

          .task_table_list {
            .left .MuiInput-root:before {
              border-color: transparent !important;
            }

            .left .MuiInput-root:after {
              border-color: transparent !important;
            }

            .left svg {
              color: ${themeParser('#000000de', '#cbcbcb', theme)};
            }

            .action-buttons button {
              min-width: 44px;

              svg {
                font-size: 1.2rem;
              }
            }

            .error_watch .MuiSvgIcon-root {
              animation: ring 1.4s linear infinite;
            }

            @keyframes ring {
              0% {
                transform: rotate(0deg);
              }
              5% {
                transform: rotate(0deg);
              }
              15% {
                transform: rotate(0deg);
              }
              25% {
                transform: rotate(20deg);
              }
              35% {
                transform: rotate(-15deg);
              }
              45% {
                transform: rotate(10deg);
              }
              55% {
                transform: rotate(-5deg);
              }
              60% {
                transform: rotate(0deg);
              }
              100% {
                transform: rotate(0deg);
              }
            }
          }
        }
      }
    `,

    tasksNoData: css`
      text-align: center;
      color: #808080 !important;
      padding: 20px 0;
      text-transform: uppercase;
    `,

    tasksPaginationContainer: css`
      text-align: center;
      padding: 30px 0;
    `,

    indicatorCircleFilled: css`
      .wrapper {
        height: 20px;
        width: 20px;
        border: none;
        display: inline-flex;
        border-radius: 150px;
        margin: 0 5px -3px 3px;
      }

      .small {
        height: 10px;
        width: 10px;
      }

      .green {
        background: #4caf50;
      }

      .yellow {
        background: #ffc107 !important;
      }

      .red {
        background: #f44336 !important;
        box-shadow: 0 0 0 0 #ff00001a;
        transform: scale(1);
        animation: pulse 1s infinite;
        @keyframes pulse {
          0% {
            transform: scale(0.85);
            box-shadow: 0 0 0 0 #ff00001a;
          }

          70% {
            transform: scale(1);
            box-shadow: 0 0 0 10px #ff00001a;
          }

          100% {
            transform: scale(0.85);
            box-shadow: 0 0 0 0 #ff00001a;
          }
        }
      }
    `,

    viewTaskDialog: css`
      .MuiDialogContent-root {
        scroll-behavior: smooth;
        ::-webkit-scrollbar {
          width: 4px;
        }

        /* Track */

        ::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px grey;
          border-radius: 6px;
        }

        /* Handle */

        ::-webkit-scrollbar-thumb {
          background: #9e9898;
        }

        ::-webkit-scrollbar-thumb:hover {
          background: #2196f3;
        }
      }

      .MuiDialog-paper {
        background: ${themeParser(colors.body.light, colors.body.dark, theme)};
        width: -webkit-fill-available;
        padding: 0 20px;
        color: ${themeParser('#000000de', '#858585', theme)};
      }

      .subTask_body_date input {
        color: ${themeParser('#000000de', '#858585', theme)};
        border: 0px solid;
        border-bottom: 1px solid !important;
        outline: none;
      }

      .subTask_body_date fieldset:hover {
        border-color: ${colors.primary};
      }

      .subTask_body_date .Mui-focused.MuiOutlinedInput-notchedOutline {
        border: 0px solid;
      }

      @media (max-width: 1023px) {
        .MuiGrid-root.MuiGrid-container {
          display: block;
        }

        .MuiDialogContent-root {
          padding-right: 15px;
        }
      }
      @media (max-width: 767px) {
        .MuiPaper-root {
          padding: 10px;
          margin: 5px;
        }

        .MuiBox-root {
          overflow-x: auto;
          width: 100vh;
        }
      }
      @media (max-width: 600px) {
        .taskParametersContainer {
          width: 100vh;
        }
      }

      .dialogTitle {
        border-bottom: 1px solid ${themeParser('#ddd', '#3e3e3e', theme)};
        @media (max-width: 910px) {
          padding-left: 0;
        }

        .wrapper {
          display: flex;
          justify-content: space-between;
          @media (max-width: 910px) {
            display: block;
          }
          @media (max-width: 600px) {
            width: 100vh;
            .actions {
              transform: scale(0.65);
              margin-left: -21%;
            }
          }

          .title {
            font-size: 18px;
            font-weight: bold;
          }

          .actions {
            display: flex;
            gap: 7px;
            align-items: center;
            color: #505050;
          }
        }
      }

      .dialogContent {
        padding: 0 0 20px;
      }

      .taskParametersContainer {
        padding: 10px;
        border-bottom: 1px solid ${themeParser('#ddd', '#3e3e3e', theme)};
        display: flex;
        overflow-y: auto;
        @media (max-width: 1023px) {
          overflow-y: auto;
          display: block;
        }

        .item {
          padding: 2px 10px;
          border-right: 1px solid rgb(63 63 63);
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 4px;
          color: ${themeParser('#3e3e3e', '#858585', theme)};

          :last-child {
            border-right: 0 !important;
          }

          @media (max-width: 1023px) {
            display: inline;
          }
        }

        .edit-action {
          flex-grow: 1;
          display: flex;
          justify-content: end;

          span {
            color: ${colors.primary};
            cursor: pointer;
          }
        }

        .high-priority {
          cursor: pointer !important;
          color: #f44336 !important;

          * {
            color: #f44336 !important;
          }
        }

        .low-priority {
          cursor: pointer !important;
          color: #2196f3 !important;

          * {
            color: #2196f3 !important;
          }
        }

        .medium-priority {
          cursor: pointer !important;
          color: #fbc02d !important;

          * {
            color: #fbc02d !important;
          }
        }

        .no-priority {
          cursor: pointer !important;
          color: #9e9e9e !important;

          * {
            color: #9e9e9e !important;
          }
        }
      }

      .taskListTableContainer {
        height: 427px;
        overflow: auto;
        padding-right: 15px;
        margin-bottom: 10px;

        &:nth-child(2) {
          height: 245px;
        }

        @media (max-width: 767px) {
          overflow-y: auto;
          overflow-x: hidden;
        }

        ::-webkit-scrollbar {
          width: 4px;
        }

        /* Track */

        ::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px grey;
          border-radius: 6px;
        }

        /* Handle */

        ::-webkit-scrollbar-thumb {
          background: #9e9898;
        }

        ::-webkit-scrollbar-thumb:hover {
          background: #2196f3;
        }

        .title-bar {
          position: relative;
          text-align: center;
          font-weight: 500;
          padding: 8px;
          margin-bottom: 10px;
          background: ${themeParser('#eeeeee', '#505050', theme)};
          color: #000;

          .add-button {
            position: absolute;
            top: 7px;
            right: 10px;
            cursor: pointer;

            * {
              color: ${colors.primary} !important;
            }
          }
        }

        table {
          width: 100%;
          border-collapse: collapse;
          border: 1px solid #4d4d4d;

          .left {
            text-align: left !important;
          }

          .center {
            text-align: center !important;
          }

          .right {
            text-align: right !important;
          }

          th {
            padding: 7px 0;
          }

          th,
          td {
            border-bottom: 1px solid ${themeParser('#eeeeee', '#555555', theme)};
            padding: 2px;
            transition: 0.3s;
          }

          tbody tr td:nth-child(2) {
            width: 320px;
          }

          tr:not(.active):hover td {
            background: ${themeParser('#f4f4f4', '#363636', theme)};
            cursor: pointer;
          }

          tr.active {
            position: relative;
            background: ${themeParser('#eeeeee', '#363636', theme)};
            border-color: #505050;
            box-shadow:
              0 2px 2px 0 rgb(0 0 0 / 14%),
              0 3px 1px -2px rgb(0 0 0 / 12%),
              0 1px 5px 0 rgb(0 0 0 / 20%);
          }

          tr.active:after {
            content: '';
            position: absolute;
            margin-top: 14px;
            margin-left: -12px;
            width: 0;
            height: 0;
            border-left: solid 10px ${themeParser('#cfcfcf', '#433e3e', theme)};
            border-right: solid 10px transparent;
            border-top: solid 10px transparent;
            z-index: 99;
            transform: rotate(225deg);
          }

          .actions {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 2px;

            button {
              padding: 0px;
              min-width: 25px;
              height: 25px !important;

              svg {
                font-size: 1rem;
              }
            }
          }

          .high-priority {
            cursor: pointer !important;
            color: #f44336 !important;

            * {
              color: #f44336 !important;
            }
          }

          .medium-priority {
            cursor: pointer !important;
            color: #fbc02d !important;

            * {
              color: #fbc02d !important;
            }
          }

          .low-priority {
            cursor: pointer !important;
            color: #2196f3 !important;

            * {
              color: #2196f3 !important;
            }
          }

          .no-priority {
            cursor: pointer !important;

            * {
              color: #9e9e9e !important;
            }
          }
        }
      }

      .activityLogContainer {
        .title-bar {
          position: relative;
          text-align: center;
          font-weight: bold;
          padding: 8px;
          background: transparent;
          color: ${colors.primary};
          border: 1px solid ${themeParser('#cfcfcf', '#433e3e', theme)};
        }

        .activityLogList {
          height: 260px;
          overflow: auto;

          ::-webkit-scrollbar {
            width: 6px;
          }

          /* Track */

          ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px grey;
            border-radius: 6px;
          }

          /* Handle */

          ::-webkit-scrollbar-thumb {
            background: #9e9898;
          }

          ::-webkit-scrollbar-thumb:hover {
            background: #2196f3;
          }

          .item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px;
            border: 1px solid ${themeParser('#cfcfcf', '#433e3e', theme)};
            border-top: 0;

            .date-time {
              color: #979797;
              font-size: 13px;
            }
          }
        }
      }

      .uploadedFilesContainer {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-top: 20px;

        .fileItem {
          position: relative;
          height: 110px;
          width: 110px;
          border-radius: 20px;

          .deleteButton {
            position: absolute;
            top: 2px;
            right: 2px;

            button {
              background: #706b6b;
            }

            * {
              color: #ffffff !important;
            }
          }
        }
      }
    `,

    activeTaskDialogPaper: css`
      width: 340px !important;
      padding: 10px 35px;
      background: ${themeParser('#ffffff', '#2a2a2a', theme)} !important;

      .content {
        padding-bottom: 22px;
      }

      .MuiFormControl-root {
        width: 100%;
      }

      .MuiInputLabel-root {
        color: ${themeParser('#000000de', '#a6a6a6', theme)} !important;
      }

      .date-picker-input {
        .MuiInputLabel-root {
          color: ${themeParser('#000000de', '#a6a6a6', theme)} !important;
          transform: translate(2px, 14px) scale(1) !important;
        }

        .MuiInputBase-input {
          font-size: 16px !important;
          color: ${themeParser('#000000de', colors.primary, theme)} !important;
        }
      }
    `,
    activeTaskDialogTitleBar: css`
      padding: 20px;
      text-align: center;
      letter-spacing: 3px;
      text-transform: uppercase;
      border-bottom: 1px solid ${themeParser('#e0e0e0', colors.primary, theme)};
      font-size: 1.2em !important;
      color: ${themeParser('#000000', colors.primary, theme)};
      font-weight: 500;
    `,

    viewFilesContainer: css`
      display: flex;
      justify-content: center;
      padding: 20px;

      .wrapper {
        width: 800px;

        @media (max-width: 1280px) {
          width: 100%;
        }

        .no-files-placeholder {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 40px;
          font-weight: bold;
        }
      }

      .fileItem {
        position: relative;
        height: 200px;
        width: 100%;
        cursor: pointer;
        transition: 0.3s;

        :hover {
          box-shadow:
            rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
            rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
        }
      }
    `,

    noChildTasksContainer: css`
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 10px;

      .hint {
        font-size: 13px;
        color: #747474;
      }
    `,
  };
};
