import { colors, themeParser, useAppTheme } from '@/theme';
import { css } from '@emotion/css';

export const useDialogStyles = () => {
  const theme = useAppTheme();
  return {
    root: css`
      .MuiDialog-paper {
        background-color: transparent;
        min-width: 300px;
        min-height: 100px;
        background: ${themeParser(colors.body.light, colors.body.dark, theme)};
      }

      .modalContent {
        padding: 24px;
      }

      .textContent {
        color: ${themeParser('#fff', '#F44336', theme)};
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-size: 18px;
        font-weight: 600;
      }

      .deleteModalActions {
        justify-content: center;
      }
    `,
  };
};
