import React, { useEffect, useState } from 'react';
import { useClientExpandBodyStyle } from '@/static/stylesheets/organisms';
import {
  ProposalAndTerms,
  Contract,
  IpInfo,
  ProposalPackageItem,
  ProposalTermItem,
} from '@/components/organisms/Clients';
import { Grid } from '@mui/material';
import { ToggleSaveButtonEnabled } from '@/components/molecules/Clients/SingleClient';
import { ClientItem, PackageItem, TermItem } from '@/types';
import { updateClientsFormData } from '@/redux/actions';
import { convertToNumber, validateIPAddress, validateNegativeNumber } from '@/utils';
import { useAppSelector, useInput } from '@/hooks';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';

interface ProposalInfoProps {
  toggleSaveEnabled: ToggleSaveButtonEnabled;
  clientData?: ClientItem;
  isDeactivatedClient?: boolean;
}

type TabElements = 'proposal' | 'contract' | 'ipInfo';

const ProposalInfo: React.FC<ProposalInfoProps> = (props) => {
  const classes = useClientExpandBodyStyle();
  const { clientData, isDeactivatedClient } = props;
  const [activateTab, setActivateTab] = useState<TabElements>('proposal');

  const handleChangeTab = (tabElement: TabElements) => {
    setActivateTab(tabElement);
  };

  /**
   * Proposal and terms section
   * ********************************************************************
   */
  const initialPackages: ProposalPackageItem = {
    download_bandwidth: '',
    upload_bandwidth: '',
    price: '',

    error: true,

    downloadError: false,
    uploadError: false,
    priceError: false,

    initialDownloadError: true,
    initialUploadError: true,
    initialPriceError: true,
  };

  const initialTerm: ProposalTermItem = {
    install_price: '',
    term_years: '',

    error: true,

    installPriceError: false,
    termError: false,

    initialInstallPriceError: true,
    initialTermError: true,
  };

  const [packages, setPackages] = useState<ProposalPackageItem[]>([initialPackages]);
  const [terms, setTerms] = useState<ProposalTermItem[]>([initialTerm]);

  const deletePackages = (index: number) => {
    let __packages: ProposalPackageItem[] = [];

    packages?.forEach((item, index) => {
      __packages[index] = { ...item };
    });

    __packages?.splice(index, 1);
    setPackages(() => __packages);
    props?.toggleSaveEnabled(true);
  };

  const addPackages = () => {
    let __packages: ProposalPackageItem[] = [];

    packages?.forEach((item, index) => {
      __packages[index] = { ...item };
    });

    __packages?.push({
      ...initialPackages,
      initialDownloadError: true,
      downloadError: true,
      initialUploadError: true,
      uploadError: true,
      initialPriceError: true,
      priceError: true,
    });
    setPackages(() => __packages);
  };
  const onChangePackages = (index: number, value: any, key: keyof PackageItem) => {
    let __packages: ProposalPackageItem[] = [];

    packages?.forEach((item, index) => {
      __packages[index] = { ...item };
    });

    __packages[index][key] = value;

    let hasError = false;

    if (
      !validateNegativeNumber(__packages[index]?.download_bandwidth) ||
      Number(__packages[index]?.download_bandwidth) === 0
    ) {
      __packages[index].initialDownloadError = true;
      __packages[index].downloadError = true;
      hasError = true;
    } else {
      __packages[index].initialDownloadError = false;
      __packages[index].downloadError = false;
    }

    if (
      !validateNegativeNumber(__packages[index]?.upload_bandwidth) ||
      Number(__packages[index]?.upload_bandwidth) === 0
    ) {
      __packages[index].initialUploadError = true;
      __packages[index].uploadError = true;
      hasError = true;
    } else {
      __packages[index].initialUploadError = false;
      __packages[index].uploadError = false;
    }

    if (!validateNegativeNumber(__packages[index]?.price) || Number(__packages[index]?.price) === 0) {
      __packages[index].initialPriceError = true;
      __packages[index].priceError = true;
      hasError = true;
    } else {
      __packages[index].initialPriceError = false;
      __packages[index].priceError = false;
    }

    __packages[index].error = hasError;

    setPackages(__packages);
    props?.toggleSaveEnabled(true);
  };

  const deleteTerms = (index: number) => {
    let __terms: ProposalTermItem[] = [];

    terms?.forEach((item, index) => {
      __terms[index] = { ...item };
    });

    __terms?.splice(index, 1);
    setTerms(() => __terms);
  };

  const addTerms = () => {
    let __terms: ProposalTermItem[] = [];

    terms?.forEach((item, index) => {
      __terms[index] = { ...item };
    });

    __terms?.push({
      ...initialTerm,
      initialInstallPriceError: true,
      installPriceError: true,
      initialTermError: true,
      termError: true,
    });
    setTerms(() => __terms);
    props?.toggleSaveEnabled(true);
  };

  const onChangeTerms = (index: number, value: any, key: keyof TermItem) => {
    let __terms: ProposalTermItem[] = [];

    terms?.forEach((item, index) => {
      __terms[index] = { ...item };
    });

    __terms[index][key] = value;

    let hasError = false;

    if (!validateNegativeNumber(__terms[index]?.install_price) || Number(__terms[index]?.install_price) === 0) {
      __terms[index].initialInstallPriceError = true;
      __terms[index].installPriceError = true;
      hasError = true;
    } else {
      __terms[index].initialInstallPriceError = false;
      __terms[index].installPriceError = false;
    }

    if (!validateNegativeNumber(__terms[index]?.term_years) || Number(__terms[index]?.term_years) === 0) {
      __terms[index].initialTermError = true;
      __terms[index].termError = true;
      hasError = true;
    } else {
      __terms[index].initialTermError = false;
      __terms[index].termError = false;
    }

    __terms[index].error = hasError;

    setTerms(__terms);
    props?.toggleSaveEnabled(true);
  };

  useEffect(() => {
    updateClientsFormData({
      packages,
    });
  }, [packages]);

  useEffect(() => {
    updateClientsFormData({
      terms,
    });
  }, [terms]);

  useEffect(() => {
    if (clientData) {
      const packagesData: ProposalPackageItem[] = [];
      const termsData: ProposalTermItem[] = [];

      if (clientData?.packages && clientData?.packages?.length > 0) {
        clientData?.packages?.forEach((item) => {
          packagesData.push({
            ...item,
            error: false,

            downloadError: false,
            uploadError: false,
            priceError: false,

            initialDownloadError: false,
            initialUploadError: false,
            initialPriceError: false,
          });
        });
      }

      if (clientData?.terms && clientData?.terms?.length > 0) {
        clientData?.terms?.forEach((item) => {
          termsData.push({
            ...item,
            error: false,

            installPriceError: false,
            termError: false,

            initialInstallPriceError: false,
            initialTermError: false,
          });
        });
      }

      setPackages(packagesData);
      setTerms(termsData);
    }
  }, [clientData]);
  /***************** Proposal and terms section ends ***********************/

  /*** --------------------------------Contract Info ----------------------------- **/
  const getClientList = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_CLIENT_LIST];

  const {
    value: download,
    reset: resetDownload,
    setValue: setDownload,
    error: downloadError,
    bind: bindDownload,
  } = useInput('', {
    validate(value) {
      if (value?.trim() !== '') {
        if (!validateNegativeNumber(value) || Number(value) < 0) {
          return 'Please Enter Download!';
        }
      }

      return true;
    },
    onChange() {
      props?.toggleSaveEnabled(true);
    },
  });

  const {
    value: upload,
    reset: resetUpload,
    setValue: setUpload,
    error: uploadError,
    bind: bindUpload,
  } = useInput('', {
    validate(value) {
      if (value?.trim() !== '') {
        if (!validateNegativeNumber(value) || Number(value) === 0) {
          return 'Please enter upload value!';
        }
      }

      return true;
    },
    onChange() {
      props?.toggleSaveEnabled(true);
    },
  });

  const {
    value: price,
    reset: resetPrice,
    setValue: setPrice,
    error: priceError,
    bind: bindPrice,
  } = useInput('', {
    validate(value) {
      if (value?.trim() !== '') {
        if (isNaN(Number(value)) || Number(value) === 0 || Number(value) < 0) {
          return 'Please enter price!';
        }
      }

      return true;
    },
    onChange() {
      props?.toggleSaveEnabled(true);
    },
  });

  const {
    value: installPrice,
    reset: resetInstallPrice,
    setValue: setInstallPrice,
    error: installPriceError,
    bind: bindInstallPrice,
  } = useInput('', {
    validate(value) {
      // if (value?.trim() !== '') {
      //   if (isNaN(Number(value)) || Number(value) < 0) {
      //     return "Please enter install price!";
      //   }
      // }

      return true;
    },
    onChange() {
      props?.toggleSaveEnabled(true);
    },
  });

  const {
    value: additionalRecurring,
    reset: resetAdditionalRecurring,
    setValue: setAdditionalRecurring,
    error: additionalRecurringError,
    bind: bindAdditionalRecurring,
  } = useInput('', {
    // required: "Please Enter Additional Recurring!",
    onChange() {
      props?.toggleSaveEnabled(true);
    },
  });

  const {
    value: validity,
    reset: resetValidity,
    setValue: setValidity,
    error: validityError,
    bind: bindValidity,
  } = useInput('', {
    validate(value) {
      if (value?.trim() !== '') {
        if (isNaN(Number(value)) || Number(value) === 0 || Number(value) < 0) {
          return 'Please enter contract validity!';
        }
      }
      return true;
    },
    onChange() {
      props?.toggleSaveEnabled(true);
    },
  });

  const {
    value: recurringPrice,
    reset: resetRecurringPrice,
    setValue: setRecurringPrice,
    error: recurringPriceError,
    bind: bindRecurringPrice,
  } = useInput('', {
    validate(value) {
      if (value?.trim() !== '') {
        if (!validateNegativeNumber(value) || Number(value) < 0) {
          return 'Please enter recurring price!';
        }
      }

      return true;
    },
    onChange() {
      props?.toggleSaveEnabled(true);
    },
  });

  const {
    value: term,
    reset: resetTerm,
    setValue: setTerm,
    error: termError,
    bind: bindTerm,
  } = useInput('', {
    onChange() {
      props?.toggleSaveEnabled(true);
    },
  });

  useEffect(() => {
    updateClientsFormData({
      contract_download_bandwidth: {
        value: download,
        error: downloadError?.error,
      },
      contract_upload_bandwidth: {
        value: upload,
        error: uploadError?.error,
      },
      contract_price: {
        value: price,
        error: priceError?.error,
      },
      contract_install_price: {
        value: installPrice,
        error: installPriceError?.error,
      },
      additional_recurring_name: {
        value: additionalRecurring,
        error: additionalRecurringError?.error,
      },
      additional_terms: {
        value: term,
        error: termError?.error,
      },
      contract_validity: {
        value: validity,
        error: validityError?.error,
      },
      additional_recurring_price: {
        value: recurringPrice,
        error: recurringPriceError?.error,
      },
    });
  }, [
    download,
    upload,
    price,
    installPrice,
    additionalRecurring,
    term,
    validity,
    recurringPrice,
    downloadError?.error,
    uploadError?.error,
    priceError?.error,
    installPriceError?.error,
    additionalRecurringError?.error,
    termError?.error,
    validityError?.error,
    recurringPriceError?.error,
  ]);

  useEffect(() => {
    if (getClientList && getClientList?.success) {
      const data = clientData;

      setDownload(data?.contract_download_bandwidth);
      setUpload(data?.contract_upload_bandwidth);
      setPrice(data?.contract_price);
      setInstallPrice(data?.contract_install_price);
      setAdditionalRecurring(data?.additional_recurring_name);
      setTerm(data?.additional_terms);
      setRecurringPrice(data?.additional_recurring_price);
      setValidity(data?.contract_validity);
    }
  }, [getClientList]);
  /** -------------------------------- Contract Info ends ------------------------- **/

  /** ----------------------------- IP Info -------------------------------------- **/
  const {
    value: connect,
    reset: resetConnect,
    setValue: setConnect,
    error: connectError,
    bind: bindConnect,
  } = useInput('', {
    onChange() {
      props?.toggleSaveEnabled(true);
    },
  });

  const {
    value: privateIP,
    reset: resetPrivateIP,
    setValue: setPrivateIP,
    error: privateIPError,
    bind: bindPrivateIP,
  } = useInput('', {
    validate(value) {
      const valueString = value ? value?.toString()?.trim() : '';

      if (valueString !== '') {
        const privateIP = valueString?.split(',');

        if (privateIP?.length > 0) {
          let error = false;
          privateIP?.forEach((privateIP: string) => {
            if (!validateIPAddress(privateIP?.trim())) {
              error = true;
            }
          });

          if (error) {
            return 'Range:- x.x.x.x';
          }
        }
      }

      return true;
    },
    onChange() {
      props?.toggleSaveEnabled(true);
    },
  });

  const {
    value: privateCIDR,
    reset: resetPrivateCIDR,
    setValue: setPrivateCIDR,
    error: privateCIDRerror,
    bind: bindPrivateCIDR,
  } = useInput('', {
    validate(value) {
      const valueString = value ? value?.toString()?.trim() : '';

      if (valueString !== '') {
        console.log('convertToNumber(valueString)', convertToNumber(valueString));
        if (!validateNegativeNumber(valueString) || Number(valueString) === 0 || convertToNumber(valueString) > 32) {
          return 'Invalid input';
        }
      }

      return true;
    },
    onChange() {
      props?.toggleSaveEnabled(true);
    },
  });

  const {
    value: privateGateway,
    reset: resetPrivateGateway,
    setValue: setPrivateGateway,
    error: privateGatewayError,
    bind: bindPrivateGateway,
  } = useInput('', {
    validate(value) {
      const valueString = value ? value?.toString()?.trim() : '';

      if (valueString !== '') {
        const gateway = valueString?.split(',');

        if (gateway?.length > 0) {
          let error = false;
          gateway?.forEach((gateway: string) => {
            if (!validateIPAddress(gateway?.trim())) {
              error = true;
            }
          });

          if (error) {
            return 'Range:- x.x.x.x';
          }
        }
      }

      return true;
    },
    onChange() {
      props?.toggleSaveEnabled(true);
    },
  });

  const {
    value: publicIP,
    reset: resetPublicIP,
    setValue: setPublicIP,
    error: publicIPError,
    bind: bindPublicIP,
  } = useInput('', {
    validate(value) {
      const valueString = value ? value?.toString()?.trim() : '';

      if (valueString !== '') {
        const publicIP = valueString?.split(',');

        if (publicIP?.length > 0) {
          let error = false;
          publicIP?.forEach((publicIP: string) => {
            if (!validateIPAddress(publicIP?.trim())) {
              error = true;
            }
          });

          if (error) {
            return 'Range:- x.x.x.x';
          }
        }
      }

      return true;
    },
    onChange() {
      props?.toggleSaveEnabled(true);
    },
  });

  const {
    value: publicCIDR,
    reset: resetPublicCIDR,
    setValue: setPublicCIDR,
    error: publicCIDRerror,
    bind: bindPublicCIDR,
  } = useInput('', {
    validate(value) {
      const valueString = value ? value?.toString()?.trim() : '';

      if (valueString !== '') {
        if (!validateNegativeNumber(valueString) || Number(valueString) === 0 || convertToNumber(valueString) > 32) {
          return 'Invalid input';
        }
      }

      return true;
    },
    onChange() {
      props?.toggleSaveEnabled(true);
    },
  });
  useEffect(() => {
    updateClientsFormData({
      ap_connects_to: {
        value: connect,
        error: connectError.error,
      },
      private_ip: {
        value: privateIP,
        error: privateIPError.error,
      },
      private_ip_cidr: {
        value: privateCIDR,
        error: privateCIDRerror.error,
      },
      private_gateway: {
        value: privateGateway,
        error: privateGatewayError.error,
      },
      public_ip: {
        value: publicIP,
        error: publicIPError.error,
      },
      public_ip_cidr: {
        value: publicCIDR,
        error: publicCIDRerror.error,
      },
    });
  }, [
    connect,
    privateIP,
    privateCIDR,
    privateGateway,
    publicIP,
    publicCIDR,

    connectError?.error,
    privateIPError?.error,
    privateCIDRerror?.error,
    privateGatewayError?.error,
    publicIPError?.error,
    publicCIDRerror?.error,
  ]);

  const handleChangeAutofill = (value: any) => {
    if (value) {
      setPackages(value.packages);
      setTerms(value.terms);
      props?.toggleSaveEnabled(true);
    }
  };

  useEffect(() => {
    const data = clientData;

    setConnect(data?.ap_connects_to);
    setPrivateIP(data?.private_ip);
    setPrivateCIDR(data?.private_ip_cidr);
    setPrivateGateway(data?.private_gateway);
    setPublicIP(data?.public_ip);
    setPublicCIDR(data?.public_ip_cidr);
  }, [clientData]);
  /** ----------------------------- IP Info ends ---------------------------------- **/
  return (
    <>
      <div className={classes.navHeader}>
        <Grid container>
          <Grid
            onClick={() => handleChangeTab('proposal')}
            item
            sm={4}
            md={4}
            lg={4}
            className={activateTab === 'proposal' ? 'active' : ''}
          >
            <span>Proposal</span>
          </Grid>
          <Grid
            onClick={() => handleChangeTab('contract')}
            item
            sm={4}
            md={4}
            lg={4}
            className={activateTab === 'contract' ? 'active' : ''}
          >
            <span>Contract</span>
          </Grid>
          <Grid
            onClick={() => handleChangeTab('ipInfo')}
            item
            sm={4}
            md={4}
            lg={4}
            className={activateTab === 'ipInfo' ? 'active' : ''}
          >
            <span>IP Info</span>
          </Grid>
        </Grid>
      </div>

      <div>
        {activateTab === 'proposal' && (
          <ProposalAndTerms
            isDeactivatedClient={isDeactivatedClient}
            toggleSaveEnabled={props.toggleSaveEnabled}
            packages={packages}
            onChangePackages={onChangePackages}
            addPackages={addPackages}
            deletePackages={deletePackages}
            terms={terms}
            onChangeTerms={onChangeTerms}
            addTerms={addTerms}
            deleteTerms={deleteTerms}
            leadsData={clientData}
            onChangeAutofill={handleChangeAutofill}
          />
        )}
        {activateTab === 'contract' && (
          <Contract
            isDeactivatedClient={isDeactivatedClient}
            formState={{
              downloadError,
              bindDownload,

              bindInstallPrice,
              installPriceError,

              bindTerm,
              termError,

              bindValidity,
              validityError,

              bindAdditionalRecurring,
              additionalRecurringError,

              bindPrice,
              priceError,

              bindRecurringPrice,
              recurringPriceError,

              bindUpload,
              uploadError,
            }}
          />
        )}
        {activateTab === 'ipInfo' && (
          <IpInfo
            isDeactivatedClient={isDeactivatedClient}
            formState={{
              bindConnect,
              connectError,

              bindPrivateIP,
              privateIPError,

              bindPrivateCIDR,
              privateCIDRerror,

              bindPrivateGateway,
              privateGatewayError,

              bindPublicIP,
              publicIPError,

              bindPublicCIDR,
              publicCIDRerror,
            }}
          />
        )}
      </div>
    </>
  );
};

export { ProposalInfo };
