import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { routePaths } from './routePaths';
import Welcome from '@/views/Welcome';
import Login from '@/views/Login';
import Profile from '@/views/Profile';
import Leads from '@/views/Leads';
import Clients from '@/views/Clients';
import Jobs from '@/views/Jobs';
import IP from '@/views/IP';
import Tasks from '@/views/Tasks';
import EquipmentOrders from '@/views/EquipmentOrders';

import InputFieldPreview from '@/views/TestComponents/InputFieldPreview';
import Tab from '@/views/TestComponents/TabSetting';
import FloatingAction from '@/views/TestComponents/FloatingAction';
import ButtonPreview from '@/views/TestComponents/ButtonPreview';
import SelectPreview from '@/views/TestComponents/SelectPreview';
import DatePickers from '@/views/TestComponents/DatePicker';
import { PaginationPreview } from '@/views/TestComponents/PaginationPreview';
import ConfirmDialogPreview from '@/views/TestComponents/ConfirmDialogPreview';
import DataTablePreview from '@/views/TestComponents/DataTablePreview';
import { GeneralSettings, Equipment, IPAddress, APIs, StaffMembers, Email, Pricing } from '@/views/Settings';
import { RichTextEditorPreview } from '@/views/TestComponents/RichTextEditorPreview';
import { MultiSelectPreview } from '@/views/TestComponents/MultiSelectPreview';
import Reports from '@/views/Reports';

const AppRoutes = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route path={routePaths.root} element={<Login />} />
          <Route path={routePaths.login} element={<Login />} />
          <Route path={routePaths.profile} element={<Profile />} />
          <Route path={routePaths.leads} element={<Leads />} />
          <Route path={routePaths.clients} element={<Clients />} />
          <Route path={routePaths.jobs} element={<Jobs />} />
          <Route path={routePaths.ip} element={<IP />} />
          <Route path={routePaths.tasks} element={<Tasks />} />
          <Route path={routePaths.equipmentOrders} element={<EquipmentOrders />} />
          <Route path={routePaths.reports} element={<Reports />} />
          <Route path={routePaths.settings.generalSettings} element={<GeneralSettings />} />
          <Route path={routePaths.settings.email} element={<Email />} />
          <Route path={routePaths.settings.pricing} element={<Pricing />} />
          <Route path={routePaths.settings.equipment} element={<Equipment />} />
          <Route path={routePaths.settings.ipAddress} element={<IPAddress />} />
          <Route path={routePaths.settings.apis} element={<APIs />} />
          <Route path={routePaths.settings.staffMembers} element={<StaffMembers />} />

          {/* Preview routes for component preview */}
          <Route path={'/input-field'} element={<InputFieldPreview />} />
          <Route path={'/tab-setting'} element={<Tab />} />
          <Route path={'/floating-action'} element={<FloatingAction />} />
          <Route path={'/button'} element={<ButtonPreview />} />
          <Route path={'/select'} element={<SelectPreview />} />
          <Route path={'/pagination'} element={<PaginationPreview />} />
          <Route path={'/confirmation'} element={<ConfirmDialogPreview />} />
          <Route path={'/datatable'} element={<DataTablePreview />} />
          <Route path={'/rich-text-editor'} element={<RichTextEditorPreview />} />
          <Route path={'/multi-select'} element={<MultiSelectPreview />} />
          <Route path={'/date'} element={<DatePickers />} />
        </Routes>
      </Router>
    </>
  );
};

export { AppRoutes as Routes };
