import * as React from 'react';
import { Button } from '@/components/atoms/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useStaffMemberStyle } from '@/static/stylesheets/molecules';
import DialogTitle from '@mui/material/DialogTitle';
import { TextInput } from '@/components/atoms/TextInput';
import { CloseButton } from '@/components/atoms/Button/CloseButton';
import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import { MultiSelect } from '@/components/atoms/MultiSelect';
import { useAppSelector, useInput } from '@/hooks';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import {
  clearCreatedCustomVariable,
  clearUpdatedCustomVariable,
  createCustomVariable,
  getCustomVariableCustomerColumns,
  updateCustomVariable,
} from '@/redux/actions';
import { Loader } from '@/components/atoms/Loader';
import toast from 'react-hot-toast';
import { CustomVariableParams } from '@/types';
import { convertToString } from '@/utils';
import { alertMessages } from '@/config';

interface CustomVariablesFormProps {
  openDialog: boolean;

  formData?: CustomVariableParams | null;
  onClose?(): void;
  onSuccess?(): void;
}

const CustomVariablesForm: React.FC<CustomVariablesFormProps> = (props) => {
  const classes = useStaffMemberStyle();
  const { openDialog, onClose, formData: editFormData, onSuccess } = props;
  const { GET_CUSTOM_VARIABLE_CUSTOMER_COLUMNS } = CORRELATION_IDS;
  const columnList = useAppSelector(selectHttpState)[GET_CUSTOM_VARIABLE_CUSTOMER_COLUMNS];
  const createdCustomVariable = useAppSelector(selectHttpState)[CORRELATION_IDS.CREATE_CUSTOM_VARIABLE];
  const updatedCustomVariable = useAppSelector(selectHttpState)[CORRELATION_IDS.UPDATE_CUSTOM_VARIABLE];
  const [columns, setColumns] = useState<string[]>([]);
  const editMode = editFormData && editFormData?.id !== '';
  const submitDisabled = columnList?.loading;
  const loading = createdCustomVariable?.loading || updatedCustomVariable?.loading;

  const {
    value: variableName,
    setValue: setVariableName,
    reset: resetVariableName,
    error: variableNameError,
    bind: bindVariableName,
  } = useInput('', {
    validate(value) {
      const specialCharsRegExp = /[ `!@#$%^&*()+\=\[\]{};':"\\|,<>\/?~]/;
      if (specialCharsRegExp.test(value)) {
        return 'Special chars are not allowed!';
      }

      return true;
    },
  });

  const resetFields = () => {
    resetVariableName();
    setColumns(() => []);
  };

  const fetchColumns = () => getCustomVariableCustomerColumns();

  const handleSubmit = () => {
    if (variableNameError?.check()) {
      toast.error(alertMessages.generalSettings.customVariables.submitValidationError);
      return;
    }

    if (columns?.length === 0) {
      toast.error('Please select some columns!');
      return;
    }

    const data: CustomVariableParams = {
      name: `{{${variableName}}}`,
      column: JSON.stringify(columns),
    };

    if (editMode && editFormData?.id) {
      data.customVariable_id = editFormData?.id;
    }

    if (editMode) {
      updateCustomVariable(data);
    } else {
      createCustomVariable(data);
    }
  };

  useEffect(() => {
    if (updatedCustomVariable?.success) {
      toast.success(alertMessages.generalSettings.customVariables.updateSuccess);
      clearUpdatedCustomVariable();
      resetFields();
      if (onClose) {
        onClose();
      }

      if (onSuccess) {
        onSuccess();
      }
    }

    if (updatedCustomVariable?.error) {
      toast.error(alertMessages.generalSettings.customVariables.updateError);
      clearUpdatedCustomVariable();
    }
  }, [updatedCustomVariable]);

  useEffect(() => {
    if (createdCustomVariable?.success) {
      toast.success(alertMessages.generalSettings.customVariables.createSuccess);
      clearCreatedCustomVariable();
      resetFields();
      if (onClose) {
        onClose();
      }

      if (onSuccess) {
        onSuccess();
      }
    }

    if (createdCustomVariable?.error) {
      const error = createdCustomVariable?.error;
      if (error?.status === 422) {
        toast.error(error?.data?.message);
      } else {
        toast.error(alertMessages.generalSettings.customVariables.createError);
      }
      clearCreatedCustomVariable();
    }
  }, [createdCustomVariable]);

  useEffect(() => {
    if (editMode) {
      const variableName = convertToString(editFormData?.name)?.replaceAll('{{', '')?.replaceAll('}}', '');
      setVariableName(variableName);

      const parsedJson = editFormData?.column ? JSON.parse(editFormData?.column) : [];
      let __columns: string[] = [];

      if (parsedJson && parsedJson?.length > 0) {
        for (let i = 0; i < parsedJson?.length; i++) {
          __columns.push(parsedJson[i]);
        }
      }

      setColumns(() => __columns);
    }
  }, [editMode, editFormData]);

  useEffect(() => {
    if (!openDialog) {
      resetFields();
    }
  }, [openDialog]);

  useEffect(() => {
    fetchColumns();
  }, []);

  return (
    <>
      <Dialog className={classes.root} open={openDialog} maxWidth={'md'} onClose={onClose}>
        <DialogTitle className={classes.DialogTitle} id='responsive-dialog-title'>
          {`${editMode ? 'Edit' : 'Add'} Custom Variable`}
        </DialogTitle>
        <div onClick={onClose}>
          <CloseButton />
        </div>
        <DialogContent>
          <DialogContentText className={classes.DialogContent}>
            <Grid container spacing={2}>
              <Grid item sm={12} md={6} lg={6}>
                <TextInput
                  variant={'standard'}
                  label={'Variable Name'}
                  fullWidth={true}
                  {...bindVariableName}
                  error={variableNameError?.error}
                  errorText={variableNameError?.message}
                />

                <div
                  style={{
                    marginTop: 10,
                    fontWeight: 'bold',
                  }}
                >
                  {!variableNameError?.error && variableName !== '' && `{{${variableName}}}`}
                </div>
              </Grid>
              <Grid item sm={12} md={6} lg={6}>
                {columnList?.loading ? (
                  <div
                    style={{
                      height: 100,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Loader />
                  </div>
                ) : (
                  <MultiSelect
                    options={
                      columnList?.data && columnList?.data?.length > 0
                        ? columnList?.data?.map((column: string) => ({
                            value: column,
                            label: column,
                          }))
                        : []
                    }
                    value={columns}
                    onChange={(items) => setColumns(() => items)}
                    label={'Select a column'}
                    multiple={true}
                  />
                )}
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmit}
            color={'primary'}
            size={'small'}
            variant={'contained'}
            title={editMode ? 'Save Variable' : 'Add Variable'}
            disabled={submitDisabled}
            loading={loading}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export { CustomVariablesForm };
