import { clearHttpStateObject } from '@/redux/actions';
import {
  CORRELATION_IDS,
  connectDocusignApiHttpCall,
  connectDripApiHttpCall,
  connectGoogleCalendarApiHttpCall,
  connectUcrmApiHttpCall,
  createCustomVariableHttpCall,
  createEmailTemplateHttpCall,
  createEquipmentHttpCall,
  createLocationHttpCall,
  createUserHttpCall,
  deleteApiKeyHttpCall,
  deleteCustomVariableHttpCall,
  deleteEmailTemplateHttpCall,
  deleteEquipmentHttpCall,
  deleteJobProgressItemHttpCall,
  deleteLocationHttpCall,
  deletePrivateIPHttpCall,
  deletePublicIPHttpCall,
  deleteSourceItemHttpCall,
  deleteUserHttpCall,
  disconnectDocusignApiHttpCall,
  disconnectDripApiHttpCall,
  disconnectGoogleCalendarApiHttpCall,
  disconnectUcrmApiHttpCall,
  emailTemplateSendTestMailHttpCall,
  getAllApiInformationHttpCall,
  getAllCustomVariablesHttpCall,
  getAllEmailTemplateListHttpCall,
  getAllLocationsHttpCall,
  getApiKeyListHttpCall,
  getCustomVariableCustomerColumnsHttpCall,
  getCustomVariableHttpCall,
  getCustomVariablesHttpCall,
  getEmailTemplateHttpCall,
  getEmailTemplateListHttpCall,
  getEquipmentsHttpCall,
  getJobProgressListHttpCall,
  getLeadClientJobSettingsHttpCall,
  getPrivateIPListHttpCall,
  getProgressBarTypesHttpCall,
  getPublicIPListHttpCall,
  getSmtpSettingsHttpCall,
  getSourceListHttpCall,
  getUsersHttpCall,
  resetUserPasswordHttpCall,
  saveApiKeyHttpCall,
  saveJobProgressItemHttpCall,
  saveLeadClientJobSettingsHttpCall,
  savePrivateIPHttpCall,
  savePublicIPHttpCall,
  saveSourceItemHttpCall,
  sendTestSmtpMailHttpCall,
  updateCustomVariableHttpCall,
  updateEmailTemplateHttpCall,
  updateEquipmentHttpCall,
  updateLocationHttpCall,
  updateProgressListHttpCall,
  updateSmtpSettingsHttpCall,
  updateUserHttpCall,
} from '@/redux/reducers/http';
import { store } from '@/redux/store';
import {
  CreateLocation,
  CustomVariableFilterParams,
  CustomVariableParams,
  DocusignApiParams,
  DripApiParams,
  EmailTemplateFilterParams,
  EmailTemplateParams,
  EmailTemplateSendTestMailParams,
  EmailTemplatesFilterParams,
  EquipmentFilterParams,
  EquipmentParams,
  GetLocationsParams,
  IPParams,
  JobProgressItemParams,
  JobProgressListFilterParams,
  LeadClientJobSettingsParams,
  PaginationParams,
  ProgressBarTypeFilterParams,
  ProgressItem,
  SmtpSettingsParams,
  SourceItemParams,
  UcrmApiParams,
  UpdateLocation,
  UserParams,
} from '@/types';

/** -------------- Users/Staff Members ---------------------- */
export function getUsers(params: PaginationParams) {
  store.dispatch(getUsersHttpCall(params));
}

export function createUser(data: UserParams) {
  store.dispatch(createUserHttpCall(data));
}

export function updateUser(data: UserParams) {
  store.dispatch(updateUserHttpCall(data?.id, data));
}

export function resetUserPassword(id: UserParams['id'], data: Pick<UserParams, 'password' | 'confirm_password'>) {
  store.dispatch(resetUserPasswordHttpCall(id, data));
}

export function deleteUser(id: UserParams['id']) {
  store.dispatch(deleteUserHttpCall(id));
}

export function clearCreatedUser() {
  clearHttpStateObject(CORRELATION_IDS.CREATE_USER);
}

export function clearUpdatedUser() {
  clearHttpStateObject(CORRELATION_IDS.UPDATE_USER);
}

export function clearUserResetPassword() {
  clearHttpStateObject(CORRELATION_IDS.USER_RESET_PASSWORD);
}

export function clearDeletedUser() {
  clearHttpStateObject(CORRELATION_IDS.DELETE_USER);
}

/** ---------------------------------------------------------- */

/** -------------- ClientList Client & Job Settings ---------------------- */
export function getLeadClientJobSettings() {
  store.dispatch(getLeadClientJobSettingsHttpCall());
}

export function saveLeadClientJobSettings(data: LeadClientJobSettingsParams) {
  store.dispatch(saveLeadClientJobSettingsHttpCall(data));
}

export function clearSavedLeadClientJobSettings() {
  clearHttpStateObject(CORRELATION_IDS.SAVE_LEAD_CLIENT_JOB_SETTINGS);
}

/** ---------------------------------------------------------- */

/** ---------------- Source Items ---------------------- */
export function getSourceItemList() {
  store.dispatch(getSourceListHttpCall());
}

export function saveSourceItem(data: SourceItemParams) {
  store.dispatch(saveSourceItemHttpCall(data));
}

export function clearSavedSourceItem() {
  clearHttpStateObject(CORRELATION_IDS.SAVE_SOURCE_ITEM);
}

export function deleteSourceItem(id: string) {
  store.dispatch(deleteSourceItemHttpCall(id));
}

export function clearDeletedSourceItem() {
  clearHttpStateObject(CORRELATION_IDS.DELETE_SOURCE_ITEM);
}

/** ---------------------------------------------------------- */

/** ---------------- Api Key List ---------------------- */
export function getApiKeyList() {
  store.dispatch(getApiKeyListHttpCall());
}

export function saveApiKeyItem() {
  store.dispatch(saveApiKeyHttpCall());
}

export function clearSavedAPiKey() {
  clearHttpStateObject(CORRELATION_IDS.SAVE_API_KEY_ITEM);
}

export function deleteAPiKeyItem(id: string) {
  store.dispatch(deleteApiKeyHttpCall(id));
}

export function clearDeletedApiKeyItem() {
  clearHttpStateObject(CORRELATION_IDS.DELETE_API_KEY_ITEM);
}

/** ---------------------------------------------------------- */

/** ---------------- Job Progress Bar Items ---------------------- */
export function getJobProgressItemList(params: JobProgressListFilterParams) {
  store.dispatch(getJobProgressListHttpCall(params));
}

export function saveJobProgressItem(data: JobProgressItemParams) {
  store.dispatch(saveJobProgressItemHttpCall(data));
}

export function deleteJobProgressItem(id: string) {
  store.dispatch(deleteJobProgressItemHttpCall(id));
}

export function clearSavedJobProgressItem() {
  clearHttpStateObject(CORRELATION_IDS.SAVE_JOB_PROGRESS_ITEM);
}

export function updateProgressList(data: ProgressItem) {
  const leadId = data?.id;
  const params = { ...data };
  delete params?.id;
  store.dispatch(updateProgressListHttpCall(leadId, params));
}

export function clearUpdateProgressList() {
  clearHttpStateObject(CORRELATION_IDS.UPDATE_PROGRESS_LIST);
}

export function clearDeletedJobProgressItem() {
  clearHttpStateObject(CORRELATION_IDS.DELETE_JOB_PROGRESS_ITEM);
}

/** ---------------------------------------------------------- */

/** ---------------- Public IP Address ---------------------- */
export function getPublicIPList() {
  store.dispatch(getPublicIPListHttpCall());
}

export function savePublicIP(ip: IPParams['ip']) {
  store.dispatch(
    savePublicIPHttpCall({
      address_type: 'public',
      ip,
    }),
  );
}

export function clearSavedPublicIP() {
  clearHttpStateObject(CORRELATION_IDS.SAVE_PUBLIC_IP);
}

export function deletePublicIP(id: string) {
  store.dispatch(deletePublicIPHttpCall(id));
}

export function clearDeletedPublicIP() {
  clearHttpStateObject(CORRELATION_IDS.DELETE_PUBLIC_IP);
}

/** ---------------------------------------------------------- */

/** ---------------- Private IP Address ---------------------- */
export function getPrivateIPList() {
  store.dispatch(getPrivateIPListHttpCall());
}

export function savePrivateIP(ip: IPParams['ip']) {
  store.dispatch(
    savePrivateIPHttpCall({
      address_type: 'private',
      ip,
    }),
  );
}

export function clearSavedPrivateIP() {
  clearHttpStateObject(CORRELATION_IDS.SAVE_PRIVATE_IP);
}

export function deletePrivateIP(id: string) {
  store.dispatch(deletePrivateIPHttpCall(id));
}

export function clearDeletedPrivateIP() {
  clearHttpStateObject(CORRELATION_IDS.DELETE_PRIVATE_IP);
}

/** ---------------------------------------------------------- */

/** -------------- Equipments ---------------------- */
export function getEquipments(params: EquipmentFilterParams) {
  store.dispatch(getEquipmentsHttpCall(params));
}

export function createEquipment(data: EquipmentParams) {
  store.dispatch(createEquipmentHttpCall(data));
}

export function updateEquipment(data: EquipmentParams) {
  store.dispatch(updateEquipmentHttpCall(data?.id, data));
}

export function deleteEquipment(id: EquipmentParams['id']) {
  store.dispatch(deleteEquipmentHttpCall(id));
}

export function clearCreatedEquipment() {
  clearHttpStateObject(CORRELATION_IDS.CREATE_EQUIPMENT);
}

export function clearUpdatedEquipment() {
  clearHttpStateObject(CORRELATION_IDS.UPDATE_EQUIPMENT);
}

export function clearDeletedEquipment() {
  clearHttpStateObject(CORRELATION_IDS.DELETE_EQUIPMENT);
}

/** ---------------------------------------------------------- */

/** -------------- Progress bar types ---------------------- */
export function getProgressBarTypes(params?: ProgressBarTypeFilterParams) {
  store.dispatch(getProgressBarTypesHttpCall(params));
}

/** ---------------------------------------------------------- */

/** -------------- Equipments ---------------------- */
export function getSmtpSettings() {
  store.dispatch(getSmtpSettingsHttpCall());
}

export function updateSmtpSettings(data: SmtpSettingsParams) {
  store.dispatch(updateSmtpSettingsHttpCall(data));
}

export function smtpSendTestMail(data: SmtpSettingsParams) {
  store.dispatch(sendTestSmtpMailHttpCall(data));
}

export function clearUpdatedSmtpSettings() {
  clearHttpStateObject(CORRELATION_IDS.UPDATE_SMTP_SETTINGS);
}

export function clearSentSmtpTestMail() {
  clearHttpStateObject(CORRELATION_IDS.SMTP_SEND_TEST_MAIL);
}

/** ---------------------------------------------------------- */

/** -------------- Custom Variables ---------------------- */
export function getCustomVariables(params: CustomVariableFilterParams) {
  store.dispatch(getCustomVariablesHttpCall(params));
}

export function getAllCustomVariables() {
  store.dispatch(
    getAllCustomVariablesHttpCall({
      page: 1,
      limit: 'all',
    }),
  );
}

export function getCustomVariable(id: CustomVariableParams['customVariable_id']) {
  store.dispatch(getCustomVariableHttpCall(id));
}

export function getCustomVariableCustomerColumns() {
  store.dispatch(getCustomVariableCustomerColumnsHttpCall());
}

export function createCustomVariable(data: CustomVariableParams) {
  store.dispatch(createCustomVariableHttpCall(data));
}

export function updateCustomVariable(data: CustomVariableParams) {
  store.dispatch(updateCustomVariableHttpCall(data));
}

export function deleteCustomVariable(id: CustomVariableParams['customVariable_id']) {
  store.dispatch(deleteCustomVariableHttpCall(id));
}

export function clearCreatedCustomVariable() {
  clearHttpStateObject(CORRELATION_IDS.CREATE_CUSTOM_VARIABLE);
}

export function clearUpdatedCustomVariable() {
  clearHttpStateObject(CORRELATION_IDS.UPDATE_CUSTOM_VARIABLE);
}

export function clearDeletedCustomVariable() {
  clearHttpStateObject(CORRELATION_IDS.DELETE_CUSTOM_VARIABLE);
}

/** ---------------------------------------------------------- */

/** -------------- APIs ---------------------- */
export function getAllApiInformation() {
  store.dispatch(getAllApiInformationHttpCall());
}

export function connectGoogleCalendarApi() {
  store.dispatch(connectGoogleCalendarApiHttpCall());
}

export function disconnectGoogleCalendarApi() {
  store.dispatch(
    disconnectGoogleCalendarApiHttpCall({
      api_name: 'google_calendar',
    }),
  );
}

export function connectDocusignApi(data: DocusignApiParams) {
  store.dispatch(connectDocusignApiHttpCall(data));
}

export function disconnectDocusignApi() {
  store.dispatch(
    disconnectDocusignApiHttpCall({
      api_name: 'docusign',
    }),
  );
}

export function connectDripApi(data: DripApiParams) {
  store.dispatch(connectDripApiHttpCall(data));
}

export function disconnectDripApi() {
  store.dispatch(
    disconnectDripApiHttpCall({
      api_name: 'drip',
    }),
  );
}

export function connectUcrmApi(data: UcrmApiParams) {
  store.dispatch(connectUcrmApiHttpCall(data));
}

export function disconnectUcrmApi() {
  store.dispatch(
    disconnectUcrmApiHttpCall({
      api_name: 'ucrm',
    }),
  );
}

export function clearConnectedGoogleCalendarApi() {
  clearHttpStateObject(CORRELATION_IDS.CONNECT_GOOGLE_CALENDAR_API);
}

export function clearConnectedDocusignApi() {
  clearHttpStateObject(CORRELATION_IDS.CONNECT_DOCUSIGN_API);
}

export function clearDisconnectedGoogleCalendarApi() {
  clearHttpStateObject(CORRELATION_IDS.DISCONNECT_GOOGLE_CALENDAR_API);
}

export function clearDisconnectedDocusignApi() {
  clearHttpStateObject(CORRELATION_IDS.DISCONNECT_DOCUSIGN_API);
}

export function clearConnectedDripApi() {
  clearHttpStateObject(CORRELATION_IDS.CONNECT_DRIP_API);
}

export function clearDisconnectedDripApi() {
  clearHttpStateObject(CORRELATION_IDS.DISCONNECT_DRIP_API);
}

export function clearConnectedUcrmApi() {
  clearHttpStateObject(CORRELATION_IDS.CONNECT_UCRM_API);
}

export function clearDisconnectedUcrmApi() {
  clearHttpStateObject(CORRELATION_IDS.DISCONNECT_UCRM_API);
}

/** ---------------------------------------------------------- */

/** -------------- Email Templates ---------------------- */
export function getEmailTemplates(params: EmailTemplatesFilterParams) {
  store.dispatch(getEmailTemplateListHttpCall(params));
}

export function getAllEmailTemplates(templatesFor?: EmailTemplatesFilterParams['templatesFor']) {
  store.dispatch(
    getAllEmailTemplateListHttpCall({
      page: 1,
      limit: 'all',
      templatesFor,
    }),
  );
}

export function getEmailTemplate(params: EmailTemplateFilterParams) {
  store.dispatch(getEmailTemplateHttpCall(params));
}

export function createEmailTemplate(data: EmailTemplateParams) {
  store.dispatch(createEmailTemplateHttpCall(data));
}

export function updateEmailTemplate(data: EmailTemplateParams) {
  store.dispatch(updateEmailTemplateHttpCall(data));
}

export function deleteEmailTemplate(id: EmailTemplateParams['id']) {
  store.dispatch(deleteEmailTemplateHttpCall(id));
}

export function emailTemplateSendTestMail(data: EmailTemplateSendTestMailParams) {
  store.dispatch(emailTemplateSendTestMailHttpCall(data));
}

export function clearCreatedEmailTemplate() {
  clearHttpStateObject(CORRELATION_IDS.CREATE_EMAIL_TEMPLATE);
}

export function clearUpdatedEmailTemplate() {
  clearHttpStateObject(CORRELATION_IDS.UPDATE_EMAIL_TEMPLATE);
}

export function clearDeletedEmailTemplate() {
  clearHttpStateObject(CORRELATION_IDS.DELETE_EMAIL_TEMPLATE);
}

export function clearEmailTemplateSentTestMail() {
  clearHttpStateObject(CORRELATION_IDS.EMAIL_TEMPLATE_SEND_TEST_MAIL);
}

/** ----------------------------Pricing------------------------------ */
export function createLocationAction(data: CreateLocation) {
  store.dispatch(createLocationHttpCall(data));
}

export function updateLocationAction(data: UpdateLocation) {
  store.dispatch(updateLocationHttpCall(data));
}

export function deleteLocationAction(id: string) {
  store.dispatch(deleteLocationHttpCall(id));
}

export function getLocationsAction(data: GetLocationsParams) {
  store.dispatch(getAllLocationsHttpCall(data));
}

export function clearCreateLocation() {
  clearHttpStateObject(CORRELATION_IDS.CREATE_LOCATION);
}

export function clearUpdateLocation() {
  clearHttpStateObject(CORRELATION_IDS.UPDATE_LOCATION);
}

export function clearDeleteLocation() {
  clearHttpStateObject(CORRELATION_IDS.DELETE_LOCATION);
}
