import React from 'react';
import { LeadItem } from '@/types';
import { useLeadStyles } from '@/static/stylesheets/organisms';

interface TrackerTableProps {
  leadData?: LeadItem;
  isLostLead?: boolean;
  children?: React.ReactNode;
}

const TrackerTable: React.FC<TrackerTableProps> = (props) => {
  const classes = useLeadStyles();
  const { isLostLead } = props;

  return (
    <>
      <div className={classes.progressBar}>
        <table className={isLostLead ? 'disable' : 'active'}>
          <tbody>
            <tr>{props?.children}</tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export { TrackerTable };
