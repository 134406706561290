import React from 'react';
import { SettingsLayout } from '@/components/layouts';
import { SettingsCard } from '@/components/organisms/SettingsCard';
import { ProfileForm } from '@/components/molecules/ProfileForm';
import { PrivateLayout } from '@/components/Middleware';

const Profile = () => {
  return (
    <>
      <PrivateLayout>
        <SettingsLayout
          hideHeaderProfile={true}
          hideSettingsTabPanel={false}
          hideHeaderRightSide={false}
          containerSize={'sm'}
        >
          <SettingsCard title={'Profile Settings'}>
            <ProfileForm />
          </SettingsCard>
        </SettingsLayout>
      </PrivateLayout>
    </>
  );
};

export default Profile;
