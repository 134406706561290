import { css } from '@emotion/css';
import { colors, themeParser, useAppTheme } from '@/theme';

export const useBackToTopButtonStyles = () => {
  const theme = useAppTheme();

  return {
    button: css`
      color: ${themeParser(colors.body.dark, colors.primary, theme)};
    `,
    arrowUpButton: css`
      background: ${themeParser(colors.body.light, colors.body.dark, theme)} !important;
      padding: 18px;
      overflow: hidden;
      border-radius: 50%;
      position: fixed !important;
      bottom: 16px;
      right: 96px;
      box-shadow:
        0 2px 2px 0 rgb(0 0 0 / 14%),
        0 3px 1px -2px rgb(0 0 0 / 12%),
        0 1px 5px 0 rgb(0 0 0 / 20%);
      z-index: 12;
      svg {
        width: 1.5em;
        height: 1.5em;
        color: ${colors.primary};
      }
    `,
  };
};
