import React, { useEffect } from 'react';
import { Button } from '@/components/atoms/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useStaffMemberStyle } from '@/static/stylesheets/molecules';
import DialogTitle from '@mui/material/DialogTitle';
import { TextInput } from '@/components/atoms/TextInput';
import { CloseButton } from '@/components/atoms/Button/CloseButton';
import Grid from '@mui/material/Grid';
import { UserParams } from '@/types';
import { useAppSelector, useInput } from '@/hooks';
import { validateEmail, validateUsaPhoneNumber } from '@/utils';
import { clearCreatedUser, clearUpdatedUser, createUser, updateUser } from '@/redux/actions';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import toast from 'react-hot-toast';
import { alertMessages } from '@/config';

interface StaffMemberFormProps {
  open: boolean;
  formData?: UserParams | null;

  onClose?(): void;
  onReload?(): void;
}

const StaffMemberForm: React.FC<StaffMemberFormProps> = (props) => {
  const classes = useStaffMemberStyle();
  const { open, onClose, formData: editFormData } = props;
  const editMode = editFormData && editFormData?.id !== '';

  const createdUser = useAppSelector(selectHttpState)[CORRELATION_IDS.CREATE_USER];
  const updatedUser = useAppSelector(selectHttpState)[CORRELATION_IDS.UPDATE_USER];
  const loading = createdUser?.loading || updatedUser?.loading;

  const {
    value: firstName,
    setValue: setFirstName,
    reset: resetFirstName,
    bind: bindFirstName,
    error: firstNameError,
  } = useInput('', {
    required: 'Please enter your first name!',
  });

  const {
    value: lastName,
    setValue: setLastName,
    reset: resetLastName,
    bind: bindLastName,
    error: lastNameError,
  } = useInput('', {
    required: 'Please enter your last name!',
  });

  const {
    value: email,
    setValue: setEmail,
    reset: resetEmail,
    bind: bindEmail,
    error: emailError,
  } = useInput('', {
    validate(value) {
      if (!validateEmail(value)) {
        return 'Please enter a valid email address!';
      }

      return true;
    },
  });

  const {
    value: phone,
    setValue: setPhone,
    reset: resetPhone,
    bind: bindPhone,
    error: phoneError,
  } = useInput('', {
    validate(value) {
      if (value?.trim() === '') {
        return 'Please enter your phone number!';
      }

      if (!validateUsaPhoneNumber(value) || value?.length > 18) {
        return 'Invalid phone number format!';
      }

      return true;
    },
  });

  const {
    value: title,
    setValue: setTitle,
    reset: resetTitle,
    bind: bindTitle,
    error: titleError,
  } = useInput('', {
    required: 'Please enter title!',
  });

  const {
    value: password,
    reset: resetPassword,
    bind: bindPassword,
    error: passwordError,
  } = useInput('', {
    validate(value) {
      if (value === '') {
        return 'Please enter password!';
      }
      if (value?.length < 8) {
        return 'Your password should be at least 8 characters!';
      }
      return true;
    },
    onChange(value) {
      if (value !== confirmPassword) {
        setConfirmPasswordError('Password does not match!');
      }
    },
  });

  const {
    value: confirmPassword,
    reset: resetConfirmPassword,
    bind: bindConfirmPassword,
    error: confirmPasswordError,
    setError: setConfirmPasswordError,
  } = useInput('', {
    validate(value) {
      if (value !== password) {
        return 'Password does not match!';
      }

      return true;
    },
  });

  const resetFields = () => {
    resetFirstName();
    resetLastName();
    resetEmail();
    resetPhone();
    resetTitle();
    resetPassword();
    resetConfirmPassword();
  };

  const submitUserData = () => {
    const data: UserParams = {
      first_name: firstName,
      last_name: lastName,
      email,
      phone,
      title,
    };

    firstNameError.check();
    lastNameError.check();
    emailError.check();
    phoneError.check();
    titleError.check();

    if (
      firstNameError.check() ||
      lastNameError.check() ||
      emailError.check() ||
      phoneError.check() ||
      titleError.check()
    ) {
      toast.error(alertMessages.generalSettings.staffMembers.submitValidationError);
      return;
    }

    if (editMode && editFormData?.id) {
      data.id = editFormData?.id;
    }

    if (!editMode) {
      data.password = password;
      data.confirm_password = confirmPassword;

      if (passwordError.check() || confirmPasswordError.check()) {
        toast.error(alertMessages.generalSettings.staffMembers.submitValidationError);
        return;
      }
    }

    if (editMode) {
      updateUser(data);
    } else {
      createUser(data);
    }
  };

  useEffect(() => {
    if (createdUser && createdUser.success) {
      toast.success(alertMessages.generalSettings.staffMembers.createSuccess);
      if (props.onClose) {
        props?.onClose();
      }
      if (props.onReload) {
        props?.onReload();
      }
      clearCreatedUser();
    }

    if (createdUser && createdUser?.error) {
      const error = createdUser?.error;
      if (error?.status === 422) {
        toast.error(error?.data?.message);
      } else {
        toast.error(alertMessages.generalSettings.staffMembers.createError);
      }
      clearCreatedUser();
    }
  }, [createdUser]);

  useEffect(() => {
    if (updatedUser && updatedUser.success) {
      toast.success(alertMessages.generalSettings.staffMembers.updateSuccess);
      if (props.onClose) {
        props?.onClose();
      }
      if (props.onReload) {
        props?.onReload();
      }
      clearUpdatedUser();
    }

    if (updatedUser && updatedUser?.error) {
      const error = updatedUser?.error;
      if (error?.status === 422) {
        toast.error(error?.data?.message);
      } else {
        toast.error(alertMessages.generalSettings.staffMembers.updateError);
      }
      clearUpdatedUser();
    }
  }, [updatedUser]);

  useEffect(() => {
    if (editMode) {
      setFirstName(editFormData?.first_name);
      setLastName(editFormData?.last_name);
      setEmail(editFormData?.email);
      setPhone(editFormData?.phone);
      setTitle(editFormData?.title);
    }
  }, [editMode, editFormData]);

  useEffect(() => {
    if (!open) {
      resetFields();
    }
  }, [open]);

  return (
    <>
      <Dialog
        className={classes.root}
        open={open}
        maxWidth={'md'}
        onClose={onClose}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogTitle className={classes.DialogTitle} id='responsive-dialog-title'>
          {`${editMode ? 'Edit' : 'Add'} Staff Member`}
        </DialogTitle>
        <div onClick={onClose}>
          <CloseButton />
        </div>
        <DialogContent>
          <DialogContentText className={classes.DialogContent}>
            <Grid container spacing={2}>
              <Grid item sm={12} md={6} lg={6}>
                <TextInput
                  variant={'standard'}
                  label={'First Name'}
                  fullWidth={true}
                  {...bindFirstName}
                  error={firstNameError?.error}
                  errorText={firstNameError?.message}
                />
              </Grid>
              <Grid item sm={12} md={6} lg={6}>
                <TextInput
                  variant={'standard'}
                  label={'Last Name'}
                  fullWidth={true}
                  {...bindLastName}
                  error={lastNameError?.error}
                  errorText={lastNameError?.message}
                />
              </Grid>
              <Grid item sm={12} md={4} lg={4}>
                <TextInput
                  variant={'standard'}
                  label={'Email'}
                  fullWidth={true}
                  {...bindEmail}
                  error={emailError?.error}
                  errorText={emailError?.message}
                />
              </Grid>
              <Grid item sm={12} md={4} lg={4}>
                <TextInput
                  variant={'standard'}
                  label={'Phone'}
                  fullWidth={true}
                  {...bindPhone}
                  error={phoneError?.error}
                  errorText={phoneError?.message}
                />
              </Grid>
              <Grid item sm={12} md={4} lg={4}>
                <TextInput
                  variant={'standard'}
                  label={'Title'}
                  fullWidth={true}
                  {...bindTitle}
                  error={titleError?.error}
                  errorText={titleError?.message}
                />
              </Grid>
              {!editMode && (
                <>
                  <Grid item sm={12} md={6} lg={6}>
                    <TextInput
                      type={'password'}
                      variant={'standard'}
                      label={'Password'}
                      fullWidth={true}
                      {...bindPassword}
                      error={passwordError?.error}
                      errorText={passwordError?.message}
                    />
                  </Grid>
                  <Grid item sm={12} md={6} lg={6}>
                    <TextInput
                      type={'password'}
                      variant={'standard'}
                      label={'Retype Password'}
                      fullWidth={true}
                      {...bindConfirmPassword}
                      error={confirmPasswordError?.error}
                      errorText={confirmPasswordError?.message}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={submitUserData}
            color={'primary'}
            variant={'contained'}
            title={`${editMode ? 'Save' : 'Add'} Staff`}
            loading={loading}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export { StaffMemberForm };
