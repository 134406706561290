import React from 'react';
import { useReportStyles } from '@/static/stylesheets/molecules';
import SearchIcon from '@mui/icons-material/Search';
import { MenuItem, SidebarMenuAccordion } from './SidebarMenuAccordion';
import { ReportMenuItem, ReportMenuKey } from '@/types';
import MenuIcon from '@mui/icons-material/Menu';
import { useComponentState } from '@/hooks';
import classNames from 'classnames';

interface SidebarProps {
  selectedMenuKey: ReportMenuKey;

  onChangeMenuItem(key: ReportMenuKey): void;
}

const generalReportMenus: ReportMenuItem[] = [
  {
    key: 'GENERAL_TOTAL_P&L_OR_YEAR_SUMMARY',
    title: 'Total P&L | Year Summary',
  },
  {
    key: 'GENERAL_TOTAL_P&L_OR_MONTH_BREAKDOWN',
    title: 'Total P&L | Month Breakdown',
  },
  {
    key: 'GENERAL_EXPENSES_BREAKDOWN',
    title: 'Expenses Breakdown',
  },
];

const leadToClientReportMenus: ReportMenuItem[] = [
  {
    key: 'LEAD_TO_CLIENT_PROFIT_AND_LOSS',
    title: 'Profit & Loss',
  },
  {
    key: 'LEAD_TO_CLIENT_PENDING_PROFIT_AND_LOSS',
    title: 'Pending Profit & Loss',
  },
  {
    key: 'LEAD_TO_CLIENT_LOST_VS_WON',
    title: 'Lost vs. Won',
  },
  {
    key: 'LEAD_TO_CLIENT_PROGRESS',
    title: 'Progress',
  },
];

const techWorkReportMenus: ReportMenuItem[] = [
  {
    key: 'TECH_WORK_PROFIT_AND_LOSS',
    title: 'Profit & Loss',
  },
  {
    key: 'TECH_WORK_PENDING_PROFIT_AND_LOSS',
    title: 'Pending Profit & Loss',
  },
  {
    key: 'TECH_WORK_LOST_VS_WON',
    title: 'Lost vs. Won',
  },
  {
    key: 'TECH_WORK_PROGRESS',
    title: 'Progress',
  },
];

const Sidebar: React.FC<SidebarProps> = (props) => {
  const classes = useReportStyles();
  const {
    state: { mobileSidebarOpen },
    setState,
  } = useComponentState({
    mobileSidebarOpen: false,
  });

  const toggleSidebar = () =>
    setState({
      mobileSidebarOpen: !mobileSidebarOpen,
    });

  const handleClickMenuItem = (key: ReportMenuKey) => {
    props.onChangeMenuItem(key);
  };

  return (
    <div className={classes.sidebarRoot}>
      <div
        className={classNames({
          'sidebar-container': true,
          'sidebar-open': mobileSidebarOpen,
        })}
      >
        <div className={'drawer-navigator'}>
          <span className={'toggle-button'} onClick={toggleSidebar}>
            <MenuIcon />
          </span>
        </div>
        <div className={'searchBar'}>
          <input type={'text'} placeholder={'Search Reports...'} className={'input'} />
          <span className={'searchIcon'}>
            <SearchIcon />
          </span>
        </div>
        <div className={'menu-container'}>
          <SidebarMenuAccordion title={'General Reports'}>
            {generalReportMenus.map((menu, index) => (
              <MenuItem
                key={index}
                menuKey={menu.key}
                title={menu.title}
                activeKey={props?.selectedMenuKey}
                onClick={handleClickMenuItem}
              />
            ))}
          </SidebarMenuAccordion>

          <SidebarMenuAccordion title={'Lead to Client | Internet'}>
            {leadToClientReportMenus.map((menu, index) => (
              <MenuItem
                key={index}
                menuKey={menu.key}
                title={menu.title}
                activeKey={props?.selectedMenuKey}
                onClick={handleClickMenuItem}
              />
            ))}
          </SidebarMenuAccordion>

          <SidebarMenuAccordion title={'Tech Work | Job'}>
            {techWorkReportMenus.map((menu, index) => (
              <MenuItem
                key={index}
                menuKey={menu.key}
                title={menu.title}
                activeKey={props?.selectedMenuKey}
                onClick={handleClickMenuItem}
              />
            ))}
          </SidebarMenuAccordion>
        </div>
      </div>
    </div>
  );
};

export { Sidebar };
