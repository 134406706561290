import React, { useEffect } from 'react';
import { FeatureItemList } from '@/components/organisms/FeatureItemList';
import { useAppSelector } from '@/hooks';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import {
  clearDeletedPublicIP,
  clearSavedPublicIP,
  deletePublicIP,
  getPublicIPList,
  openConfirmationDialog,
  savePublicIP,
} from '@/redux/actions';
import toast from 'react-hot-toast';
import { validateIPAddress } from '@/utils';
import { alertMessages } from '@/config';

const PublicIPAddressList = () => {
  const publicIPList = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_PUBLIC_IP_LIST];
  const savedPublicIP = useAppSelector(selectHttpState)[CORRELATION_IDS.SAVE_PUBLIC_IP];
  const deletedPublicIP = useAppSelector(selectHttpState)[CORRELATION_IDS.DELETE_PUBLIC_IP];
  const itemList = publicIPList?.data?.data ?? [];

  const fetchItems = () => getPublicIPList();

  const createItem = (ipAddress: string) => {
    if (ipAddress?.trim() === '') {
      toast.error(alertMessages.generalSettings.ipAddress.public.submitValidationError);
      return;
    }

    savePublicIP(ipAddress);
  };

  const deleteItem = (id: string) => {
    openConfirmationDialog({
      title: 'Delete',
      message: alertMessages.generalSettings.ipAddress.public.deleteConfirm,
      type: 'error',
      textCenter: 'center',
      onClickConfirm() {
        deletePublicIP(id);
      },
    });
  };

  useEffect(() => {
    if (savedPublicIP?.success) {
      clearSavedPublicIP();
      fetchItems();
      toast.success(alertMessages.generalSettings.ipAddress.public.saveSuccess);
    }

    if (savedPublicIP?.error) {
      const error = savedPublicIP?.error;
      if (error?.status === 422) {
        toast.error(error?.data?.message);
      } else {
        toast.error(alertMessages.generalSettings.ipAddress.public.saveError);
      }
      clearSavedPublicIP();
    }
  }, [savedPublicIP]);

  useEffect(() => {
    if (deletedPublicIP?.success) {
      clearDeletedPublicIP();
      fetchItems();
      toast.success(alertMessages.generalSettings.ipAddress.public.deleteSuccess);
    }

    if (deletedPublicIP?.error) {
      clearDeletedPublicIP();
      toast.error(alertMessages.generalSettings.ipAddress.public.deleteError);
    }
  }, [deletedPublicIP]);

  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <>
      <FeatureItemList
        title='Public IP Addresses'
        items={itemList?.map((item: any) => ({
          title: item?.ip,
          onClickRemove() {
            deleteItem(item?.id);
          },
        }))}
        onNewInput={(value) => createItem(value)}
        loading={publicIPList?.loading || savedPublicIP?.loading || deletedPublicIP?.loading}
        newInputValidator={(value) => {
          if (value?.trim() === '' || !validateIPAddress(value, 3)) {
            return 'Invalid ip address format! Please use first 3 block of your ip address!';
          }

          return true;
        }}
        placeholder={'Enter IP address'}
      />
    </>
  );
};

export { PublicIPAddressList };
