import React from 'react';
import { IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { FileUploadResponse } from '@/types';
import classNames from 'classnames';
import { css } from '@emotion/css';
import { detectImageTypeFromExtension, getFileExtension } from '@/utils';
import NoPlaceholderImage from '@/static/images/no-image-placeholder.jpg';

type FileSrc = string | null | undefined;

interface UploadedFilesProps {
  files: FileUploadResponse;
  onDelete?(id: string, index: number): void;
}

interface FileItemProps {
  fileSrc?: FileSrc;
  thumbnailSrc?: string;
  onClickDelete?(): void;
}

const FileItem: React.FC<FileItemProps> = (props) => (
  <div
    className={classNames({
      fileItem: true,
      [css`
        background: url('${props?.thumbnailSrc}') !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        background-position: center !important;
      `]: true,
    })}
  >
    <span className={'deleteButton'}>
      <IconButton size={'small'} onClick={props?.onClickDelete}>
        <CancelIcon />
      </IconButton>
    </span>
  </div>
);

const UploadedFiles: React.FC<UploadedFilesProps> = (props) => {
  const { files } = props;

  const getThumbnailSrcPath = (name?: string | null, path?: string | null) => {
    const extension = name ? getFileExtension(name) : null;
    if (extension && detectImageTypeFromExtension(extension)) {
      return path ?? NoPlaceholderImage;
    }

    return NoPlaceholderImage;
  };

  return (
    <div className={'uploadedFilesContainer'}>
      {files &&
        files?.length > 0 &&
        files?.map((file, index) => (
          <React.Fragment key={index}>
            {file && file?.file_path && (
              <FileItem
                fileSrc={file?.file_path}
                thumbnailSrc={getThumbnailSrcPath(file?.file_name, file?.file_path)}
                onClickDelete={() => {
                  if (props?.onDelete && file?.id) {
                    props?.onDelete(file?.id, index);
                  }
                }}
              />
            )}
          </React.Fragment>
        ))}
    </div>
  );
};

export { UploadedFiles };
