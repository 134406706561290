import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import classNames from 'classnames';
import { css } from '@emotion/css';
import { colors, themeParser, useAppTheme } from '@/theme';

interface DropdownOption {
  label?: string;
  value?: any;
}

interface SearchableTextInputProps {
  options?: DropdownOption[];
  label?: string;
  placeholder?: string;

  onChange?(value: any): void;

  onClear?(): void;

  value?: any;
  loading?: boolean;
  disabled?: boolean;
  error?: boolean;
  errorText?: string | null;
  helperText?: string | null;
  dropdownOptionProps?: {
    style?: React.CSSProperties;
    className?: string;
  };
  hideArrow?: boolean;
}

const useSearchableTextInputStyles = () => {
  const theme = useAppTheme();

  return {
    root: css`
      button {
        width: auto !important;
        margin: 0 !important;

        svg {
          color: ${colors.primary};
        }
      }

      .MuiInputLabel-root {
        color: #9e9e9e !important;
      }

      .MuiAutocomplete-endAdornment {
        height: 100%;
        top: 0 !important;
        display: flex;
        gap: 10px;
        align-items: start;
        justify-content: end;
      }

      .MuiInput-underline:before {
        border-color: ${themeParser('#9e9e9e', colors.textInput.borderColor.dark, theme)} !important;
      }
      .MuiInput-underline:hover:before {
        border-bottom: 1px solid ${colors.primary} !important;
      }
      .MuiInput-underline:after {
        border-bottom: 2px solid ${colors.primary} !important;
      }

      input {
        color: ${themeParser('auto', colors.primary, theme)} !important;
        font-size: 16px !important;

        ::placeholder {
          color: ${themeParser(
            colors.textInput.placeholderColor.light,
            colors.textInput.placeholderColor.dark,
            theme,
          )} !important;
        }
      }
    `,
    paper: css`
      box-shadow: ${themeParser(
        'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
        '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)',
        theme,
      )} !important;
      background: ${themeParser('#ffffff', '#2a2a2a', theme)} !important;

      ul li {
        padding: 8px 15px;
        cursor: pointer;
        color: #2196f3 !important;
        font-weight: 500;
        font-family: 'Poppins', sans-serif;
        font-size: 15px;

        :hover {
          background: ${themeParser('#eeeeee', '#505050', theme)} !important;
        }
      }
    `,

    closeButton: css`
      background: red;
      border-radius: 50%;
    `,
  };
};

const SearchableTextInput: React.FC<SearchableTextInputProps> = (props) => {
  const {
    value,
    label,
    placeholder,
    onChange,
    onClear,
    options,
    loading,
    disabled,
    error,
    errorText,
    helperText,
    hideArrow,
  } = props;
  const classes = useSearchableTextInputStyles();
  const [selectedItem, setSelectedItem] = useState<DropdownOption | null>(null);

  useEffect(() => {
    if (value && options && options?.length > 0) {
      const index = options?.findIndex((option) => option?.value === value);
      if (index > -1) {
        setSelectedItem(options[index]);
      }
    } else {
      setSelectedItem(null);
    }
  }, [value, options]);

  return (
    <>
      <Autocomplete
        className={'auto_search'}
        classes={{
          root: classes.root,
          paper: classes.paper,
        }}
        disablePortal={true}
        options={options ?? []}
        disabled={disabled}
        value={selectedItem}
        onChange={(event: any, newValue: any) => {
          const valueIsNull = !newValue?.value || newValue?.value === '';

          setSelectedItem(newValue);
          if (onChange) {
            onChange(valueIsNull ? null : newValue?.value);
          }

          if (valueIsNull && onClear) {
            onClear();
          }
        }}
        renderOption={(optionProps, option) => {
          return (
            <li
              {...optionProps}
              key={option.value}
              style={props?.dropdownOptionProps?.style}
              className={classNames({
                search_list: true,
                [props?.dropdownOptionProps?.style as any]: true,
              })}
            >
              {option.label}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            type={'text'}
            variant={'standard'}
            {...params}
            label={label}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading && <CircularProgress size={20} style={{ color: colors.primary }} />}
                  {!hideArrow && params?.InputProps?.endAdornment}
                </>
              ),
            }}
            error={error}
            helperText={error ? errorText : helperText}
          />
        )}
      />
    </>
  );
};

export { SearchableTextInput };
