import { endpoints } from '@/api/endpoints';
import {
  GeneralPLYearSummaryReportPayload,
  GetExpensesBreakdown,
  GetProfitAndLossReport,
  GetProfitWonAndLoss,
  UserListFilterParams,
} from '@/types';
import { CORRELATION_IDS } from '../correlationIds';
import { performHttpCall } from '../httpSlice';

export const getReportStaffMembersDropdownHttpCall = (params: UserListFilterParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_REPORT_STAFF_MEMBERS,
    payload: {
      url: endpoints.private.getUsers(params),
      method: 'get',
      useBearerToken: true,
    },
  });

export const postTotalPlYearSummaryReport = (data: GeneralPLYearSummaryReportPayload) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_TOTAL_PL_YEAR_SUMMARY_REPORT,
    payload: {
      url: endpoints.private.postTotalPLYearSummaryReport(),
      method: 'post',
      useBearerToken: true,
      body: data,
    },
  });

export const getProfitAndLossReport = (data: GetProfitAndLossReport) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_PROFIT_AND_LOSS_REPORT,
    payload: {
      url: endpoints.private.getProfitAndLossReport(),
      method: 'post',
      useBearerToken: true,
      body: data,
    },
  });

export const getLostVsWonReport = (data: GetProfitWonAndLoss) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_LOST_VS_WON_REPORT,
    payload: {
      url: endpoints.private.getLostVsWonReport(),
      method: 'post',
      useBearerToken: true,
      body: data,
    },
  });

export const getProgressReport = (data: GetProfitWonAndLoss) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_PROGRESS_REPORT,
    payload: {
      url: endpoints.private.getProgressReport(),
      method: 'post',
      useBearerToken: true,
      body: data,
    },
  });

export const getExpensesBreakdownReport = (data: GetExpensesBreakdown) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_EXPENSES_BREAKDOWN_REPORT,
    payload: {
      url: endpoints.private.getExpensesBreakdownReport(),
      method: 'post',
      useBearerToken: true,
      body: data,
    },
  });
