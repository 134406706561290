import React from 'react';
import { TextInput } from '@/components/atoms/TextInput';

const InputFieldPreview = () => {
  return (
    <>
      <TextInput variant={'standard'} placeholder={'Enter your text'} />
    </>
  );
};

export default InputFieldPreview;
