import { Button } from '@/components/atoms/Button';
import { MenuDialog } from '@/components/atoms/Popover/MenuDialog';
import { EmailTemplatesForm } from '@/components/molecules/Settings/Email/EmailTemplates';
import { ContractModal } from '@/components/organisms/Clients/ContractModal';
import {
  ProposalPackageItem,
  ProposalTermItem,
} from '@/components/organisms/Clients/Expand/PersonalInfo/ProposalAndTerms';
import { RemoveAction } from '@/components/organisms/Clients/Expand/modal/RemoveAction';
import { alertMessages } from '@/config';
import { useAppSelector } from '@/hooks';
import {
  clearClientSendContractResponse,
  clearClientUpdateCustomerDetails,
  clearClientUpdatedVMCount,
  clearRestoreDeactivatedClient,
  clearUpdatedCustomerClient,
  deleteLostLeadAndClientAction,
  getClients,
  restoreDeactivatedClient,
  updateClientCustomerDetails,
  updateCustomerClient,
} from '@/redux/actions';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { selectUiState } from '@/redux/reducers/uiSlice';
import { useClientStyles } from '@/static/stylesheets/organisms/Clients/clientStyles';
import { convertEmptyStringToNull } from '@/utils';
import { convertToPayloadParam } from '@/utils/convertToPayloadParam';
import { timezone } from '@/utils/timeZone';
import BlockIcon from '@mui/icons-material/Block';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MouseIcon from '@mui/icons-material/Mouse';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VoicemailIcon from '@mui/icons-material/Voicemail';
import { Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

interface HeaderActionProps {
  clientData?: any;

  onToggleOptions?(): void;

  onToggleFilesMenu?(): void;

  reloadActivityLogList?(): void;

  expandActivity?: boolean;
  filesMenuOpen?: boolean;
  isDeactivatedClient?: boolean;
  isSaveEnabled?: boolean;
}

const RightHeader: React.FC<HeaderActionProps> = (props) => {
  const classes = useClientStyles();
  const updateVMCountResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.UPDATE_CLIENT_VM_COUNT];
  const restoresDeactivatedClientResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.RESTORE_DEACTIVATED_CLIENT];
  const updateCustomerClientResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.UPDATE_CUSTOMER_CLIENT];
  const updateCustomerDetailsResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.UPDATE_CLIENT_CUSTOMER_DETAILS];
  const sendsContractResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.SEND_CLIENT_CONTRACT];
  const deleteClientAndLeadResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.DELETE_LOST_LEAD_AND_CLIENT];
  const [targetId, setTargetId] = useState(null);
  const { onToggleOptions, onToggleFilesMenu, expandActivity, filesMenuOpen } = props;
  const { showDeactivatedClients } = useAppSelector(selectUiState);
  const clientsForm: any = useAppSelector(selectUiState)?.clientsForm;

  const actionsDisabled = showDeactivatedClients || props?.isDeactivatedClient;

  // const leadsForm = leadsFormData?.payload ?? [];
  const [openDialog, setOpenDialog] = useState(false);
  const [openModal, setOpen] = useState(false);
  const [openEmailDialog, setOpenEmailDialog] = useState(false);
  const [isPDFData, setIsPDFData] = useState(null);
  const [modalValue, setModalValue] = useState('');
  const [isOpenDeleteAlert, setIsOpenDeleteAlert] = React.useState(false);

  const handleOpenModal = (e: any) => {
    const value = e.target.id;
    if (value === 'Other') {
      setOpen(true);
      setModalValue(value);
      return;
    }

    const data: any = {
      removed_reason: value,
      removed_reason_details: value,
      time_zone: timezone,
      name: 'Deactivated Client',
    };
    data.id = props.clientData?.id;
    setTargetId(props.clientData?.id);
    updateCustomerClient(data);
  };

  const handleCloseModal = () => setOpen(false);

  const handleDialog = (data: any) => {
    setOpenDialog(true);
    setIsPDFData(data);
    setTargetId(data?.id);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
    setIsPDFData(null);
  };
  const handleEmailDialog = () => {
    setOpenEmailDialog(true);
  };
  const handleEmailDialogClose = () => {
    setOpenEmailDialog(false);
  };

  const handleRestoresDeactivatedClient = () => {
    setTargetId(props.clientData?.id);
    restoreDeactivatedClient(props.clientData?.id);
  };

  const handleDeleteLostLead = () => {
    setIsOpenDeleteAlert(true);
  };

  const submitToDeleteLostLead = () => {
    setTargetId(props.clientData?.id);
    deleteLostLeadAndClientAction(props.clientData.id);
    setIsOpenDeleteAlert(false);
  };

  const onSubmit = () => {
    if (
      clientsForm?.survey_datetime_from?.trim().split(/\s+/).length > 4 ||
      clientsForm?.survey_datetime_to?.trim().split(/\s+/).length > 4 ||
      clientsForm?.installation_datetime_from?.trim().split(/\s+/).length > 4 ||
      clientsForm?.installation_datetime_to?.trim().split(/\s+/).length > 4 ||
      clientsForm?.next_follow_up_datetime?.trim().split(/\s+/).length > 4
    ) {
      toast.error('Please select date!');
      return;
    }
    /*if (
      clientsForm?.survey_datetime_from?.trim().split(/\s+/).length === 1 ||
      clientsForm?.survey_datetime_to?.trim().split(/\s+/).length === 1
    ) {
      toast.error("Please select survey date time (from and to)!");
      return;
    }

    if (
      clientsForm?.installation_datetime_from?.trim().split(/\s+/).length === 1 ||
      clientsForm?.installation_datetime_to?.trim().split(/\s+/).length === 1
    ) {
      toast.error("Please select installation date time (from and to)!");
      return;
    }

    if (clientsForm?.next_follow_up_datetime?.trim().split(/\s+/).length === 1) {
      toast.error("Please select next followup date time!");
      return;
    }*/

    const packages: ProposalPackageItem[] = clientsForm?.packages ?? [];
    const terms: ProposalTermItem[] = clientsForm?.terms ?? [];

    if (packages && packages?.length > 0) {
      let error = false;

      packages?.forEach((item) => {
        if (item?.error) {
          error = true;
        }
      });

      if (error) {
        toast.error(alertMessages.clients.submitValidationError);
        return;
      }
    }

    if (terms && terms?.length > 0) {
      let error = false;

      terms?.forEach((item) => {
        if (item?.error) {
          error = true;
        }
      });

      if (error) {
        toast.error(alertMessages.clients.submitValidationError);
        return;
      }
    }

    if (
      clientsForm?.contract_download_bandwidth?.error ||
      clientsForm?.contract_upload_bandwidth?.error ||
      clientsForm?.contract_price?.error ||
      clientsForm?.contract_install_price?.error ||
      clientsForm?.additional_recurring_name?.error ||
      clientsForm?.additional_terms?.error ||
      clientsForm?.contract_validity?.error ||
      clientsForm?.additional_recurring_price?.error
    ) {
      toast.error(alertMessages.clients.contractSubmitValidationError);
      return;
    }

    if (
      clientsForm?.ap_connects_to?.error ||
      clientsForm?.private_ip?.error ||
      clientsForm?.private_ip_cidr?.error ||
      clientsForm?.private_gateway?.error ||
      clientsForm?.public_ip?.error ||
      clientsForm?.public_ip_cidr?.error
    ) {
      toast.error(alertMessages.clients.ipSubmitValidationError);
      return;
    }

    const survey_date = clientsForm?.survey_date;
    const survey_time_from = clientsForm?.survey_time_from;
    const survey_time_to = clientsForm?.survey_time_to;
    const survey_datetime_from = convertEmptyStringToNull(clientsForm?.survey_datetime_from);
    const survey_datetime_to = convertEmptyStringToNull(clientsForm?.survey_datetime_to);

    const installation_date = clientsForm?.installation_date;
    const installation_time_from = clientsForm?.installation_time_from;
    const installation_time_to = clientsForm?.installation_time_to;
    const installation_datetime_from = convertEmptyStringToNull(clientsForm?.installation_datetime_from);
    const installation_datetime_to = convertEmptyStringToNull(clientsForm?.installation_datetime_to);

    const next_follow_up_date = clientsForm?.next_follow_up_date;
    const next_follow_up_time = clientsForm?.next_follow_up_time;
    const next_follow_up_datetime = convertEmptyStringToNull(clientsForm?.next_follow_up_datetime);

    if (
      (survey_date && (!survey_time_from || !survey_time_to)) ||
      (survey_time_from && (!survey_date || !survey_time_to)) ||
      (survey_time_to && (!survey_date || !survey_time_from))
    ) {
      toast.error(alertMessages.clients.surveyDateTimeValidationError);
      return;
    }

    if (
      (installation_date && (!installation_time_from || !installation_time_to)) ||
      (installation_time_from && (!installation_date || !installation_time_to)) ||
      (installation_time_to && (!installation_date || !installation_time_from))
    ) {
      toast.error(alertMessages.clients.installationDateTimeValidationError);
      return;
    }

    if ((next_follow_up_date && !next_follow_up_time) || (next_follow_up_time && !next_follow_up_date)) {
      toast.error(alertMessages.clients.nextFollowUpDateTimeValidationError);
      return;
    }

    const data: any = {
      time_zone: timezone,
    };

    if (survey_datetime_from) {
      data.survey_datetime_from = survey_datetime_from;
    }

    if (survey_datetime_to) {
      data.survey_datetime_to = survey_datetime_to;
    }

    if (installation_datetime_from) {
      data.installation_datetime_from = installation_datetime_from;
    }

    if (installation_datetime_to) {
      data.installation_datetime_to = installation_datetime_to;
    }

    if (next_follow_up_datetime) {
      data.next_follow_up_datetime = next_follow_up_datetime;
    }

    if (packages?.length >= 0) {
      data.packages = packages;
    }

    if (terms?.length >= 0) {
      data.terms = terms;
    }

    if (clientsForm?.source_id !== '1') {
      data.source_id = clientsForm?.source_id;
    }
    data.contract_download_bandwidth = convertToPayloadParam(clientsForm?.contract_download_bandwidth?.value, null);
    data.contract_upload_bandwidth = convertToPayloadParam(clientsForm?.contract_upload_bandwidth?.value, null);
    data.contract_price = convertToPayloadParam(clientsForm?.contract_price?.value, null);
    data.contract_install_price = convertToPayloadParam(clientsForm?.contract_install_price?.value, null);
    data.contract_validity = convertToPayloadParam(clientsForm?.contract_validity?.value, null);
    data.additional_terms = convertToPayloadParam(clientsForm?.additional_terms?.value, null);
    data.additional_recurring_name = convertToPayloadParam(clientsForm?.additional_recurring_name?.value, null);
    data.additional_recurring_price = convertToPayloadParam(clientsForm?.additional_recurring_price?.value, null);
    if (clientsForm?.ap_connects_to?.value) {
      data.ap_connects_to = clientsForm?.ap_connects_to?.value;
    }
    if (clientsForm?.private_ip?.value) {
      data.private_ip = clientsForm?.private_ip?.value;
    }
    if (clientsForm?.private_ip_cidr?.value) {
      data.private_ip_cidr = clientsForm?.private_ip_cidr?.value;
    }
    if (clientsForm?.private_ip?.value) {
      data.private_ip = clientsForm?.private_ip?.value;
    }
    if (clientsForm?.private_gateway?.value) {
      data.private_gateway = clientsForm?.private_gateway?.value;
    }
    if (clientsForm?.public_ip?.value) {
      data.public_ip = clientsForm?.public_ip?.value;
    }
    if (clientsForm?.public_ip_cidr?.value) {
      data.public_ip_cidr = clientsForm?.public_ip_cidr?.value;
    }

    data.id = props.clientData?.id;
    setTargetId(props.clientData?.id);
    updateClientCustomerDetails(data);
  };

  const getClientList = () =>
    getClients({
      limit: 10,
      page: 1,
      type: showDeactivatedClients ? 'deactivatedClient' : 'clients',
    });

  useEffect(() => {
    if (updateVMCountResponse?.success && targetId === props.clientData?.id) {
      toast.success(alertMessages.clients.vmCountUpdateSuccess);
      setTargetId(null);
      clearClientUpdatedVMCount();
      if (updateVMCountResponse?.data?.shouldReload) {
        getClientList();
      }

      if (props?.reloadActivityLogList) {
        props?.reloadActivityLogList();
      }
    }

    if (updateVMCountResponse?.error && targetId === props.clientData?.id) {
      setTargetId(null);
      clearClientUpdatedVMCount();
      toast.error(alertMessages.clients.vmCountUpdateError);
    }
  }, [updateVMCountResponse, targetId]);

  useEffect(() => {
    if (restoresDeactivatedClientResponse?.success && targetId === props.clientData?.id) {
      toast.success(alertMessages.clients.restoreDeactivatedClientSuccess);
      setTargetId(null);
      clearRestoreDeactivatedClient();
      getClientList();
    }

    if (deleteClientAndLeadResponse?.success && targetId === props.clientData?.id) {
      toast.success(alertMessages.clients.restoreDeactivatedClientSuccess);
      setTargetId(null);
      clearRestoreDeactivatedClient();
      getClientList();
    }

    if (restoresDeactivatedClientResponse?.error && targetId === props.clientData?.id) {
      toast.error(alertMessages.clients.restoreDeactivatedClientError);
      clearRestoreDeactivatedClient();
    }

    if (deleteClientAndLeadResponse?.error && targetId === props.clientData?.id) {
      toast.error(alertMessages.clients.restoreDeactivatedClientError);
      clearRestoreDeactivatedClient();
    }
  }, [targetId, restoresDeactivatedClientResponse]);

  useEffect(() => {
    if (deleteClientAndLeadResponse?.success && targetId === props.clientData?.id) {
      toast.success(alertMessages.clients.deleteClientSuccess);
      setTargetId(null);
      getClientList();
    }

    if (deleteClientAndLeadResponse?.error && targetId === props.clientData?.id) {
      toast.error(alertMessages.clients.deleteClientError);
    }
  }, [targetId, deleteClientAndLeadResponse]);

  useEffect(() => {
    if (updateCustomerClientResponse?.success && targetId === props.clientData?.id) {
      toast.success(alertMessages.clients.customerClientUpdateSuccess);
      setTargetId(null);
      setOpen(false);
      clearUpdatedCustomerClient();
      getClientList();
    }

    if (updateCustomerClientResponse?.error && targetId === props.clientData?.id) {
      toast.error(alertMessages.clients.customerClientUpdateError);
      clearUpdatedCustomerClient();
    }
  }, [targetId, updateCustomerClientResponse]);

  useEffect(() => {
    if (updateCustomerDetailsResponse?.success && targetId === props.clientData?.id) {
      toast.success(alertMessages.clients.customerDetailsUpdateSuccess);
      setTargetId(null);
      clearClientUpdateCustomerDetails();
    }

    if (updateCustomerDetailsResponse?.error && targetId === props.clientData?.id) {
      toast.error(updateCustomerDetailsResponse?.error?.data?.message);
      clearClientUpdateCustomerDetails();
    }
  }, [targetId, updateCustomerDetailsResponse]);

  useEffect(() => {
    if (isPDFData) {
      if (sendsContractResponse?.success && targetId === props.clientData?.id) {
        // toast.success("Document sent to DocuSign Successfully");
        setTargetId(null);
        clearClientSendContractResponse();
        setOpenDialog(false);
      }
      if (sendsContractResponse?.error && targetId === props.clientData?.id) {
        toast.error(alertMessages.clients.contractSendError);
        setTargetId(null);
        clearClientSendContractResponse();
      }
    }
  }, [sendsContractResponse, targetId]);

  const menuRemove = [
    {
      title: 'Client is moving',
      icon: <VisibilityOffIcon />,
      action: 'Client is moving',
    },
    {
      title: 'Switched providers',
      icon: <BlockIcon />,
      action: 'Switched providers',
    },
    {
      title: 'Other',
      icon: <MoreHorizIcon />,
      action: 'Other',
    },
  ];
  const menuVM = [
    {
      title: 'Misclick',
      icon: <MouseIcon />,
    },
    {
      title: 'Yes, I just left a VM',
      icon: <VoicemailIcon />,
      action: 'VM',
    },
  ];

  const openInNewTabUCRM = (url: any) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <>
      <div className={classes.rightDetails}>
        <Button
          color={'success'}
          title={'SAVE'}
          onClick={onSubmit}
          disabled={!props?.isSaveEnabled || actionsDisabled}
          loading={updateCustomerDetailsResponse?.loading}
        />
        <span className={classes.vertLine}>|</span>
        <Button onClick={onToggleOptions} title={expandActivity ? 'Collapse' : 'Expand'} />
        <Button onClick={onToggleFilesMenu} title={filesMenuOpen ? 'Info' : 'Files'} />
        <Button
          disabled={actionsDisabled}
          onClick={() => openInNewTabUCRM(props.clientData?.ucrm_url)}
          title={'UCRM'}
        />
        <Button disabled={actionsDisabled} onClick={() => handleEmailDialog()} title={'Email'} />
        <Button disabled={actionsDisabled} onClick={() => handleDialog(props.clientData)} title={'Contract'} />
        <div className={'last__button'}>
          {actionsDisabled ? (
            <>
              <Button onClick={handleRestoresDeactivatedClient} title={'Restore'} />
              <Button onClick={handleDeleteLostLead} color='error' style={{ marginLeft: '4px' }} title={'Delete'} />
            </>
          ) : (
            <MenuDialog
              onClick={handleOpenModal}
              title={'Deactivate'}
              leftAlign={'left_align'}
              menuList={menuRemove}
              menuHeader={'Reason for Removing?'}
            />
          )}
        </div>

        <ContractModal open={openDialog} onClose={handleDialogClose} leadData={isPDFData} />

        <EmailTemplatesForm
          open={openEmailDialog}
          onClose={handleEmailDialogClose}
          isLeadEmail={true}
          leadData={props?.clientData}
          featureType={'client'}
        />

        <RemoveAction
          openModal={openModal}
          modalValue={modalValue}
          onClose={handleCloseModal}
          leadsData={props.clientData}
        />
      </div>
      <Dialog className={classes.deleteModalRoot} open={isOpenDeleteAlert}>
        <DialogContent className='deleteModalContent'>
          <DialogContentText className='textContent'>
            Are you sure you want to permanently delete this lead forever? There is no undo button.
          </DialogContentText>
          <DialogActions className='deleteModalActions'>
            <Button color='warning' onClick={() => setIsOpenDeleteAlert(!isOpenDeleteAlert)} title={'No, Cancel'} />
            <Button color='error' onClick={submitToDeleteLostLead} title={'Yes, Permanently Delete'} />
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export { RightHeader };
