import React, { useEffect } from 'react';
import { DatePicker } from '@/components/atoms/DatePicker';
import { TimePicker } from '@/components/atoms/TimePicker';

const DatePickers = () => {
  return (
    <>
      <DatePicker />
      <TimePicker />
    </>
  );
};

export default DatePickers;
