import { css } from '@emotion/css';
import { colors, themeParser, useAppTheme } from '@/theme';

export const useFloatingActionButtonStyles = () => {
  const theme = useAppTheme();

  return {
    root: css`
      position: fixed;
      bottom: 0;
      right: 15px;
      .MuiSpeedDial-actions button {
        margin: 4px;
      }
    `,
    Action: css`
      color: ${themeParser(colors.body.dark, colors.body.light, theme)};
    `,
    fleatingAction: css`
      .MuiFab-circular {
        background: ${colors.blue.default};
        color: ${themeParser(colors.body.light, colors.body.dark, theme)};
      }
    `,
    buildIcon: css`
      background: ${colors.green.default};
      color: ${themeParser(colors.body.light, colors.body.dark, theme)};
      padding: 10px;
      overflow: hidden;
      border-radius: 50%;
      box-shadow:
        0 2px 2px 0 rgb(0 0 0 / 14%),
        0 3px 1px -2px rgb(0 0 0 / 12%),
        0 1px 5px 0 rgb(0 0 0 / 20%);
      font-size: 20px !important;
    `,
    personIcon: css`
      background: ${colors.yellow.default};
      color: ${themeParser(colors.body.light, colors.body.dark, theme)};
      padding: 10px;
      overflow: hidden;
      border-radius: 50%;
      box-shadow:
        0 2px 2px 0 rgb(0 0 0 / 14%),
        0 3px 1px -2px rgb(0 0 0 / 12%),
        0 1px 5px 0 rgb(0 0 0 / 20%);
      font-size: 20px !important;
    `,
    settingsIcon: css`
      background: ${colors.orenge.default};
      color: ${themeParser(colors.body.light, colors.body.dark, theme)};
      padding: 10px;
      overflow: hidden;
      border-radius: 50%;
      box-shadow:
        0 2px 2px 0 rgb(0 0 0 / 14%),
        0 3px 1px -2px rgb(0 0 0 / 12%),
        0 1px 5px 0 rgb(0 0 0 / 20%);
      font-size: 20px !important;
    `,
    accountBalanceIcon: css`
      background: ${colors.red.default};
      color: ${themeParser(colors.body.light, colors.body.dark, theme)};
      padding: 10px;
      overflow: hidden;
      border-radius: 50%;
      box-shadow:
        0 2px 2px 0 rgb(0 0 0 / 14%),
        0 3px 1px -2px rgb(0 0 0 / 12%),
        0 1px 5px 0 rgb(0 0 0 / 20%);
      font-size: 20px !important;
    `,
    themeIcon: css`
      background: ${colors.yellow.default};
      color: ${themeParser(colors.body.light, colors.body.dark, theme)};
      padding: 10px;
      overflow: hidden;
      border-radius: 50%;
      box-shadow:
        0 2px 2px 0 rgb(0 0 0 / 14%),
        0 3px 1px -2px rgb(0 0 0 / 12%),
        0 1px 5px 0 rgb(0 0 0 / 20%);
      font-size: 20px !important;
    `,
    exitToAppIcon: css`
      background: ${colors.purple.default};
      color: ${themeParser(colors.body.light, colors.body.dark, theme)};
      padding: 10px;
      overflow: hidden;
      border-radius: 50%;
      box-shadow:
        0 2px 2px 0 rgb(0 0 0 / 14%),
        0 3px 1px -2px rgb(0 0 0 / 12%),
        0 1px 5px 0 rgb(0 0 0 / 20%);
      font-size: 20px !important;
    `,
    blue: css`
      background: ${colors.blue.default};
      color: ${themeParser(colors.body.dark, colors.body.light, theme)};
    `,
  };
};
