import React from 'react';
import { Button } from '@/components/atoms/Button';
import { MenuDialog } from '@/components/atoms/Popover/MenuDialog';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const ButtonPreview = () => {
  const menu = [
    {
      title: 'No LOS',
      icon: <VisibilityOffIcon />,
    },
    {
      title: 'Lost',
      icon: <VisibilityOffIcon />,
    },
  ];
  return (
    <>
      <Button title={'Click'} />
      <MenuDialog title={'remove'} menuList={menu} menuHeader={'menuHeader'} />
    </>
  );
};

export default ButtonPreview;
