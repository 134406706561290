import React from 'react';
import { TaskItem } from '@/types';
import { Loader } from '@/components/atoms/Loader';

interface ActivityLogProps {
  loading?: boolean;
  activityLogs?: TaskItem['task_activity_logs'];
}

const ActivityLog: React.FC<ActivityLogProps> = (props) => {
  const { loading, activityLogs } = props;

  return (
    <>
      <div className={'activityLogContainer'}>
        <div className={'title-bar'}>
          <span>Activity Log</span>
        </div>
        <div className={'activityLogList'}>
          {loading ? (
            <div style={{ padding: 20 }}>
              <Loader />
            </div>
          ) : (
            <>
              {activityLogs &&
                activityLogs?.length > 0 &&
                activityLogs?.map((item, index) => (
                  <div key={index} className={'item'}>
                    <span>{item?.activity_body}</span>
                    <span className={'date-time'}>{item?.timestamp}</span>
                  </div>
                ))}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export { ActivityLog };
