import { Button } from '@/components/atoms/Button';
import { TextInput } from '@/components/atoms/TextInput';
import { useAppSelector } from '@/hooks';
import { getLocationsAction } from '@/redux/actions';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { useLeadExpandBodyStyle } from '@/static/stylesheets/organisms';
import { LeadItem, PackageItem, TermItem } from '@/types';
import { convertToNumber } from '@/utils';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DeleteIcon from '@mui/icons-material/Delete';
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';

export interface ProposalPackageItem extends PackageItem {
  error: boolean;

  downloadError: boolean;
  uploadError: boolean;
  priceError: boolean;

  initialDownloadError?: boolean;
  initialUploadError?: boolean;
  initialPriceError?: boolean;
}

export interface ProposalTermItem extends TermItem {
  error: boolean;

  installPriceError: boolean;
  termError: boolean;

  initialInstallPriceError?: boolean;
  initialTermError?: boolean;
}

interface ProposalAndTermsProps {
  toggleSaveEnabled: any;
  leadsData?: LeadItem;
  packages?: ProposalPackageItem[];
  terms?: ProposalTermItem[];
  lostLead?: boolean;

  onChangePackages(index: number, value: any, key: keyof PackageItem): void;

  deletePackages(index: number): void;

  addPackages(): void;

  onChangeTerms(index: number, value: any, key: keyof TermItem): void;

  deleteTerms(index: number): void;
  onChangeAutofill(value: any): void;

  addTerms(): void;
}

const ProposalAndTerms: React.FC<ProposalAndTermsProps> = (props) => {
  const classes = useLeadExpandBodyStyle();
  const getAlllocations = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_LOCATIONS];
  const {
    packages,
    terms,
    lostLead,
    onChangePackages,
    addPackages,
    deletePackages,
    onChangeTerms,
    deleteTerms,
    addTerms,
    leadsData,
    onChangeAutofill,
  } = props;

  const [location, setLocation] = useState<string>('1');

  const handleLocationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const findLocationInfo = (getAlllocations?.data?.data ?? []).find((_: any) => _.id === value);
    setLocation(value);

    onChangeAutofill(findLocationInfo);
  };

  useEffect(() => {
    getLocationsAction({
      page: 1,
      limit: 'all',
      location_type:
        leadsData?.customer?.customer_type?.toLocaleLowerCase() === 'residential' ? 'residential' : 'company',
    });
  }, [leadsData]);

  return (
    <>
      <div className={classes.root}>
        <div className={'list_body'}>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <div className={classes.selector} style={{ marginBottom: '0px' }}>
                <TextInput
                  select={true}
                  variant={'standard'}
                  fullWidth={true}
                  centerMode={true}
                  onChange={handleLocationChange}
                  value={location}
                  options={[
                    {
                      title: 'Autofill Proposal',
                      value: '1',
                      disabled: true,
                    },
                    ...(getAlllocations?.data?.data ?? []).map((_: any) => ({
                      title: _.location_name,
                      value: _.id,
                    })),
                  ]}
                />
              </div>
            </Grid>
            {packages &&
              packages?.length > 0 &&
              packages?.map((item, index) => (
                <React.Fragment key={index}>
                  <Grid item sm={4} md={4} lg={4}>
                    <TextInput
                      type={'number'}
                      variant={'standard'}
                      placeholder={'Download'}
                      centerMode={true}
                      fullWidth={true}
                      value={item?.download_bandwidth}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onChangePackages(index, e?.target?.value, 'download_bandwidth')
                      }
                      error={item?.downloadError}
                      disabled={lostLead}
                    />
                  </Grid>
                  <Grid item sm={3.5} md={3.5} lg={3.5}>
                    <TextInput
                      type={'number'}
                      variant={'standard'}
                      placeholder={'Upload'}
                      centerMode={true}
                      fullWidth={true}
                      value={item?.upload_bandwidth}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onChangePackages(index, e?.target?.value, 'upload_bandwidth')
                      }
                      error={item?.uploadError}
                      disabled={lostLead}
                    />
                  </Grid>
                  <Grid item sm={3.5} md={3.5} lg={3.5}>
                    <TextInput
                      type={'number'}
                      variant={'standard'}
                      placeholder={'Price'}
                      centerMode={true}
                      fullWidth={true}
                      value={item?.price}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onChangePackages(index, e?.target?.value, 'price')
                      }
                      startAdornment={<AttachMoneyIcon sx={{ fontSize: '19px', marginBottom: '-20%' }} />}
                      error={item?.priceError}
                      disabled={lostLead}
                    />
                  </Grid>
                  <Grid item sm={1} md={1} lg={1} className='delete'>
                    {!lostLead && <DeleteIcon onClick={() => deletePackages(index)} />}
                  </Grid>
                </React.Fragment>
              ))}

            {!lostLead && (
              <Grid item className={'button'} sm={12} md={12} lg={12} style={{ marginBottom: '10px' }}>
                <Button onClick={addPackages} variant={'contained'} title={'Add Package'} fullWidth={true} />
              </Grid>
            )}
          </Grid>
          <Grid container spacing={2}>
            {terms &&
              terms?.length > 0 &&
              terms?.map((item, index) => (
                <React.Fragment key={index}>
                  <Grid item sm={5.5} md={5.5} lg={5.5}>
                    <TextInput
                      type={'number'}
                      variant={'standard'}
                      placeholder={'Install Price'}
                      centerMode={true}
                      fullWidth={true}
                      value={item?.install_price}
                      startAdornment={<AttachMoneyIcon sx={{ fontSize: '19px', marginBottom: '-20%' }} />}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onChangeTerms(index, e?.target?.value, 'install_price')
                      }
                      error={item?.installPriceError}
                      disabled={lostLead}
                    />
                  </Grid>
                  <Grid item sm={5.5} md={5.5} lg={5.5}>
                    <TextInput
                      type={'number'}
                      variant={'standard'}
                      placeholder={'Term (Years)'}
                      centerMode={true}
                      fullWidth={true}
                      value={item?.term_years}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onChangeTerms(index, e?.target?.value, 'term_years')
                      }
                      endAdornment={convertToNumber(item?.term_years) > 1 ? 'Years' : 'Year'}
                      error={item?.termError}
                      disabled={lostLead}
                    />
                  </Grid>
                  <Grid item sm={1} md={1} lg={1} className='delete'>
                    {!lostLead && <DeleteIcon onClick={() => deleteTerms(index)} />}
                  </Grid>
                </React.Fragment>
              ))}

            {!lostLead && (
              <Grid item className={'button'} sm={12} md={12} lg={12}>
                <Button onClick={addTerms} variant={'contained'} title={'Add Term'} fullWidth={true} />
              </Grid>
            )}
          </Grid>
        </div>
      </div>
    </>
  );
};

export { ProposalAndTerms };
