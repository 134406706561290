import { themeParser, useAppTheme } from '@/theme';
import { css } from '@emotion/css';

export const useLeadCreateStyle = () => {
  const theme = useAppTheme();

  return {
    root: css`
      & .MuiPaper-root {
        margin: 20px;
        padding: 20px;
        background-color: ${themeParser('#ffffff', '#2a2a2a', theme)};
      }
    `,
    paper: css`
      position: absolute !important;
      top: 0;
    `,
    title: css`
      padding: 0 0 20px 0 !important;
      text-align: center;
      font-size: 1.2em !important;
      text-transform: uppercase;
      letter-spacing: 3px !important;
      color: ${themeParser('#000000de', '#2196f3', theme)};
      border-bottom: 1px solid ${themeParser('#dddddd', '#2196f3', theme)};
    `,
    label: css`
      font-size: 1.2em !important;
      color: ${themeParser('#000000de', '#2196f3', theme)};
    `,
    body: css`
      padding: 30px 0 0 0 !important;
      display: flex;

      .tabContainer {
        padding-bottom: 30px;
      }

      & .MuiOutlinedInput-root,
      .contactNotes,
      .MuiInput-root {
        width: 100%;
        text-indent: 10px;
        border-radius: 3px;
        background: ${themeParser('#ffffff', '#353535', theme)} !important;
        box-shadow: inset 0px 0px 2px ${themeParser('#585858', '#000', theme)};
        margin-bottom: 8px !important;
        font-family: 'Poppins', sans-serif;
        color: ${themeParser('#000000', '#2196f3', theme)};
        border-bottom: 1px solid ${themeParser('#dddddd', '#3e3e3e', theme)} !important;
        font-weight: 500 !important;
      }

      .MuiInputBase-input.MuiOutlinedInput-input {
        padding: 10px !important;
      }

      .MuiInputBase-root.MuiOutlinedInput-root {
        padding: 0 !important;
      }

      .MuiOutlinedInput-root,
      .MuiInputBase-root.MuiInput-root {
        height: 3rem;
      }

      .MuiFormHelperText-root.Mui-error {
        margin-left: 0;
        margin-bottom: 10px;
        margin-top: 0;
      }

      .MuiFormHelperText-root.Mui-error {
        position: static;
      }

      .MuiFormControl-root.MuiFormControl-fullWidth {
        margin: 0;
      }

      fieldset {
        display: none;
      }

      .Mui-disabled {
        opacity: 0.4;
        -webkit-text-fill-color: ${themeParser('#e0e0e0', 'rgb(255 250 250 / 38%)', theme)} !important;
      }

      .MuiInputBase-root.MuiInput-underline:before {
        border-color: ${themeParser('#e0e0e0', '#2a2a2a', theme)} !important;
      }
    `,
    contactPersons: css`
      @media (max-width: 767px) {
        flex-direction: column;
      }

      .contactPersonTabs {
        border: none;

        @media (max-width: 1024px) {
          width: auto;
          flex: 1 1 auto;
        }

        .MuiTabs-indicator {
          display: none;
        }
      }
      .MuiTab-root {
        background: ${themeParser('#ebebeb', '#353535', theme)};
        color: #a5a5a5;
        border: none;
        border-radius: 3px;
        padding: 10px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        margin-bottom: 5px;
        font-size: 16px;
        text-transform: capitalize;
        font-weight: 500;
        font-family: 'Poppins', sans-serif;
        align-items: self-start;

        @media (max-width: 767px) {
          max-width: 100%;
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
        }

        &.Mui-selected {
          background: ${themeParser('#d9d7d7', '#474747', theme)};
          color: #2196f3;
        }
      }

      .tabPanel {
        background-color: ${themeParser('#d9d7d7', '#474747', theme)};
        width: calc(100% - 290px);
        border-radius: 0 3px 3px 3px;

        @media (max-width: 1024px) {
          width: calc(100% - 30%);
        }

        @media (max-width: 767px) {
          width: 100%;
        }

        .tabPanelContent {
          padding: 20px;
        }

        .contactNotes {
          outline: none;
          border: none;
          resize: none;
          padding: 10px 0;
          font-size: 16px;
          font-family: 'Poppins', sans-serif;
          resize: vertical !important;
        }

        .tabPanelAction {
          display: flex;
          justify-content: center;
          position: relative;
          gap: 50px;
          margin-top: 4px;
          color: ${themeParser('#000000', '#9e9e9e', theme)};

          .MuiFormControlLabel-root {
            margin-right: 0;
          }

          .MuiCheckbox-root {
            padding: 0 10px;
          }
        }
      }

      .addNewContactBtn {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 4px;
        font-size: 16px;
        text-transform: capitalize;
        font-weight: 500;
        font-family: 'Poppins';
        color: #4caf50;
        border-radius: 3px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        background-color: ${themeParser('#ebebeb', '#353535', theme)};
        height: 48px;
      }

      .deleteContactBtn {
        opacity: 0.65;
        background: transparent;
        position: absolute;
        right: 0;
        bottom: 0;
        padding: 0;
        padding-right: 8px;

        &:hover {
          opacity: 1;
          background: transparent;
        }
      }

      .contactTypeInput {
        margin: 0;
        width: 100%;
        text-indent: 10px;
        border-radius: 3px;
        background: #353535 !important;
        box-shadow: inset 0px 0px 2px #000;
        margin-bottom: 8px !important;
        font-family: 'Poppins', sans-serif;
        color: #757575;
        border-bottom: 1px solid #3e3e3e !important;
        font-weight: 500 !important;
        padding: 12px 0;
      }
    `,
    actions: css`
      border-top: 1px solid ${themeParser('#dddddd', '#2196f3', theme)};
      justify-content: space-between !important;
      padding: 20px 0 0 0 !important;

      .MuiButton-root {
        border-radius: 2px !important;
        color: #000 !important;
        letter-spacing: 0.5px !important;
        font-weight: 500 !important;
        opacity: 0.7;
        outline: none;
        border: none;
        font-family: 'Poppins', sans-serif;

        &:hover {
          opacity: 1;
        }
      }
    `,
  };
};
