import React, { useEffect } from 'react';
import { FeatureItemList } from '@/components/organisms/FeatureItemList';
import { useAppSelector } from '@/hooks';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import {
  clearDeletedPrivateIP,
  clearSavedPrivateIP,
  deletePrivateIP,
  getPrivateIPList,
  openConfirmationDialog,
  savePrivateIP,
} from '@/redux/actions';
import toast from 'react-hot-toast';
import { validateIPAddress } from '@/utils';
import { alertMessages } from '@/config';
import TextWithShowMore from '@/components/atoms/Utils/TextWithShowMore';

const PrivateIPAddressList = () => {
  const privateIPList = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_PRIVATE_IP_LIST];
  const savedPrivateIP = useAppSelector(selectHttpState)[CORRELATION_IDS.SAVE_PRIVATE_IP];
  const deletedPrivateIP = useAppSelector(selectHttpState)[CORRELATION_IDS.DELETE_PRIVATE_IP];
  const itemList = privateIPList?.data?.data ?? [];

  const fetchItems = () => getPrivateIPList();

  const createItem = (ipAddress: string) => {
    if (ipAddress?.trim() === '') {
      toast.error(alertMessages.generalSettings.ipAddress.private.submitValidationError);
      return;
    }

    savePrivateIP(ipAddress);
  };

  const deleteItem = (id: string) => {
    openConfirmationDialog({
      title: 'Delete',
      message: alertMessages.generalSettings.ipAddress.private.deleteConfirm,
      type: 'error',
      textCenter: 'center',
      onClickConfirm() {
        deletePrivateIP(id);
      },
    });
  };

  useEffect(() => {
    if (savedPrivateIP?.success) {
      clearSavedPrivateIP();
      fetchItems();
      toast.success(alertMessages.generalSettings.ipAddress.private.saveSuccess);
    }

    if (savedPrivateIP?.error) {
      const error = savedPrivateIP?.error;
      if (error?.status === 422) {
        toast.error(<TextWithShowMore text={error?.data?.message} />);
      } else {
        toast.error(alertMessages.generalSettings.ipAddress.private.saveError);
      }
      clearSavedPrivateIP();
    }
  }, [savedPrivateIP]);

  useEffect(() => {
    if (deletedPrivateIP?.success) {
      clearDeletedPrivateIP();
      fetchItems();
      toast.success(alertMessages.generalSettings.ipAddress.private.deleteSuccess);
    }

    if (deletedPrivateIP?.error) {
      clearDeletedPrivateIP();
      toast.error(alertMessages.generalSettings.ipAddress.private.deleteError);
    }
  }, [deletedPrivateIP]);

  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <>
      <FeatureItemList
        title='Private IP Addresses'
        items={itemList?.map((item: any) => ({
          title: item?.ip,
          onClickRemove() {
            deleteItem(item?.id);
          },
        }))}
        onNewInput={(value) => createItem(value)}
        loading={privateIPList?.loading || savedPrivateIP?.loading || deletedPrivateIP?.loading}
        newInputValidator={(value) => {
          if (value?.trim() === '' || !validateIPAddress(value, 3)) {
            return 'Invalid ip address format! Please use first 3 block of your ip address!';
          }

          return true;
        }}
        placeholder={'Enter IP Address'}
      />
    </>
  );
};

export { PrivateIPAddressList };
