import React, { useEffect } from 'react';
import { FeatureItemList } from '@/components/organisms/FeatureItemList';
import {
  clearDeletedSourceItem,
  clearSavedSourceItem,
  deleteSourceItem,
  getSourceItemList,
  openConfirmationDialog,
  saveSourceItem,
} from '@/redux/actions';
import { useAppSelector } from '@/hooks';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import toast from 'react-hot-toast';
import { alertMessages } from '@/config';

const SourceList = () => {
  const sourceItemList = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_SOURCE_LIST];
  const savedSourceItem = useAppSelector(selectHttpState)[CORRELATION_IDS.SAVE_SOURCE_ITEM];
  const deletedSourceItem = useAppSelector(selectHttpState)[CORRELATION_IDS.DELETE_SOURCE_ITEM];
  const itemList = sourceItemList?.data?.data ?? [];

  const fetchItems = () => getSourceItemList();

  const createItem = (name: string) => {
    if (name?.trim() === '') {
      toast.error('Please enter title!');
      return;
    }

    saveSourceItem({
      source_name: name,
    });
  };

  const deleteItem = (id: string) => {
    openConfirmationDialog({
      title: 'Delete',
      message: alertMessages.generalSettings.sourceList.deleteConfirmation,
      textCenter: 'center',
      type: 'error',
      onClickConfirm() {
        deleteSourceItem(id);
      },
    });
  };

  useEffect(() => {
    if (savedSourceItem?.success) {
      clearSavedSourceItem();
      fetchItems();
      toast.success(alertMessages.generalSettings.sourceList.saveSuccess);
    }

    if (savedSourceItem?.error) {
      clearSavedSourceItem();
      toast.error(alertMessages.generalSettings.sourceList.saveError);
    }
  }, [savedSourceItem]);

  useEffect(() => {
    if (deletedSourceItem?.success) {
      clearDeletedSourceItem();
      fetchItems();
      toast.success(alertMessages.generalSettings.sourceList.deleteSuccess);
    }

    if (deletedSourceItem?.error) {
      clearDeletedSourceItem();
      toast.error(alertMessages.generalSettings.sourceList.deleteError);
    }
  }, [deletedSourceItem]);

  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <>
      <FeatureItemList
        title='Source List'
        items={itemList?.map((item: any) => ({
          title: item?.source_name,
          onClickRemove() {
            deleteItem(item?.id);
          },
        }))}
        onNewInput={(value) => createItem(value)}
        loading={sourceItemList?.loading || savedSourceItem?.loading || deletedSourceItem?.loading}
        placeholder={'Enter source name'}
        noDelete={false}
      />
    </>
  );
};

export { SourceList };
