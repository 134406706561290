import { useButtonStyles } from '@/static/stylesheets/atoms';
import MaterialButton from '@mui/material/Button/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import classNames from 'classnames';
import React from 'react';

export interface ButtonProps {
  type?: 'button' | 'submit';
  size?: 'small' | 'medium' | 'large';
  title?: string | React.ReactNode;
  color?: 'success' | 'error' | 'warning' | 'primary' | 'secondary' | 'inherit';
  fullWidth?: boolean;
  variant?: 'contained' | 'outlined' | 'text';
  loading?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
  sx?: SxProps<Theme>;
  tooltipText?: string;
  onClick?: any;
  className?: string;
}

const Button: React.FC<ButtonProps> = (props) => {
  const classes = useButtonStyles();

  return (
    <>
      <Tooltip title={props.tooltipText ?? ''} placement='top'>
        <MaterialButton
          variant={props.variant ?? 'contained'}
          type={props.type}
          size={props.size}
          color={props.color}
          className={classNames(props?.className, {
            [classes.root]: true,
            [classes.disabled]: props?.disabled,
          })}
          fullWidth={props.fullWidth}
          onClick={props.onClick}
          disabled={props.disabled || props?.loading}
          style={props.style}
          sx={props?.sx}
          disableFocusRipple
        >
          {props.title}
          {props.loading && (
            <CircularProgress
              style={{
                color: '#ffffff',
                width: 14,
                height: 14,
                marginLeft: 5,
              }}
            />
          )}
        </MaterialButton>
      </Tooltip>
    </>
  );
};

export { Button };
