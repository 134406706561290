import React from 'react';
import { openConfirmationDialog } from '@/redux/actions';

const ConfirmDialogPreview = () => {
  return (
    <>
      <button
        onClick={() =>
          openConfirmationDialog({
            title: 'Are you sure?',
            message: 'Alret!',
            type: 'error',
            onClickCancel() {
              alert('Cancelled!');
            },
            onClickConfirm() {
              alert('Confirmed!');
            },
          })
        }
      >
        Delete Me
      </button>
    </>
  );
};

export default ConfirmDialogPreview;
