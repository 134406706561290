import { store } from '@/redux/store';
import {
  confirmDialog,
  UiInitialState,
  UiState,
  updateLeadsExpanded,
  showLostLeads,
  leadsFormData,
  showDeactivatedClients,
  clientsFormData,
  updateGlobalSearchValue,
  changeJobListType,
  updateReportGlobalReload,
} from '@/redux/reducers/uiSlice';

export function openConfirmationDialog(options: Partial<Omit<UiState['confirmationDialog'], 'open'>>) {
  store.dispatch(
    confirmDialog({
      open: true,
      ...options,
    }),
  );
}

export function closeConfirmationDialog() {
  store.dispatch(confirmDialog(UiInitialState.confirmationDialog));
}

export function expandLeads(expand: boolean) {
  store.dispatch(updateLeadsExpanded(expand));
}

export function toggleLostLeads(show: boolean) {
  store.dispatch(showLostLeads(show));
}

export function toggleDeactivatedClients(show: boolean) {
  store.dispatch(showDeactivatedClients(show));
}

export function setJobListType(type: UiState['jobListType']) {
  store.dispatch(changeJobListType(type));
}

export function updateLeadsFormData(state: Partial<UiState['leadsForm']> | any) {
  store.dispatch(leadsFormData(state));
}

export function updateClientsFormData(state: Partial<UiState['clientsForm']> | any) {
  store.dispatch(clientsFormData(state));
}

export function setGlobalSearch(search: string) {
  store.dispatch(updateGlobalSearchValue(search));
}

export function setReportGlobalReload(value: boolean) {
  store.dispatch(updateReportGlobalReload(value));
}
