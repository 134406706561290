import { css } from '@emotion/css';
import { colors, themeParser, useAppTheme } from '@/theme';

export const useMultiSelectStyles = () => {
  const theme = useAppTheme();

  return {
    root: css`
      .MuiSelect-select {
        color: ${colors.primary} !important;
      }

      .MuiInput-underline:before {
        border-color: ${themeParser(
          colors.textInput.borderColor.light,
          colors.textInput.borderColor.dark,
          theme,
        )} !important;
      }
    `,

    label: css`
      color: ${colors.primary} !important;
    `,
  };
};
