import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { RightHeader, TrackerTable, Files } from '@/components/organisms/Leads';
import { useLeadStyles } from '@/static/stylesheets/organisms';

interface ArchivedLeadProps {
  isArchivedLead?: boolean;
}

const ArchivedLead: React.FC<ArchivedLeadProps> = (props) => {
  const classes = useLeadStyles();
  const { isArchivedLead } = props;

  const leadsData = {
    status: 'green',
    name: 'Bob Jones',
    phone: '707.334.7693',
    email: 'bobjones@gmail.com',
    address: '810 Lakeville Circle, Petaluma',
  };
  const [expandActivity, setExpandActivity] = useState(false);
  const [files, setFiles] = useState(false);
  const onExpandActivity = () => {
    setExpandActivity(!expandActivity);
  };
  const onFiles = () => {
    setFiles(!files);
  };

  return (
    <>
      <Grid container spacing={2} className={classes.root}>
        <Grid item sm={12} md={6} lg={7}>
          {/*<LeftHeader customerData={leadsData} />*/}
        </Grid>
        <Grid item sm={12} md={6} lg={5}>
          <RightHeader
            leadsData={leadsData}
            expandActivity={expandActivity}
            filesMenuOpen={files}
            onToggleOptions={onExpandActivity}
            onToggleFilesMenu={onFiles}
            isLostLead={isArchivedLead}
          />
        </Grid>
        <Grid className={classes.trackingContainer} item sm={12} md={12} lg={12}>
          <Grid container spacing={2}>
            <Grid item sm={12} md={12} lg={12}>
              <TrackerTable isLostLead={isArchivedLead} />
            </Grid>
          </Grid>
          {expandActivity && !files && (
            <Grid container spacing={2} className={classes.expandContainer}>
              <Grid item sm={6} md={3} lg={3}>
                {/*<Source/>*/}
              </Grid>
              <Grid item sm={6} md={3} lg={3}>
                {/*<PersonalInfo/>*/}
              </Grid>
              <Grid item sm={6} md={3} lg={3}>
                {/* <QuickNotes/> */}
              </Grid>
              <Grid item sm={6} md={3} lg={3}>
                {/*<Activity/>*/}
              </Grid>
            </Grid>
          )}
          {expandActivity && files && <Files />}
        </Grid>
      </Grid>
    </>
  );
};

export { ArchivedLead };
