import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useAppSelector } from '@/hooks';
import { selectAuthState } from '@/redux/reducers/authSlice';
import { routePaths } from '@/routes';
import { AuthService } from '@/services/auth';

interface PrivateLayoutProps {
  children?: React.ReactNode;
}

const PrivateLayout: React.FC<PrivateLayoutProps> = (props) => {
  const navigate = useNavigate();
  const authData = useAppSelector(selectAuthState);
  const isLoggedIn = authData?.logged_in && new AuthService().isLoggedIn();

  useEffect(() => {
    if (!isLoggedIn) {
      // Redirect to the login page
      navigate(routePaths.login);
    }
  }, [isLoggedIn]);

  return <>{props.children}</>;
};

export { PrivateLayout };
