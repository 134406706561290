import React from 'react';
import { useReportStyles } from '@/static/stylesheets/molecules';

interface ContentHeaderProps {
  headings?: string[];
  children?: React.ReactNode;
}

const ContentWrapper: React.FC<ContentHeaderProps> = (props) => {
  const classes = useReportStyles();

  return (
    <div className={classes.contentWrapperRoot}>
      <div className={'title-bar'}>
        {(props?.headings ?? [])?.map((title, index) => <div key={index}>{title}</div>)}
      </div>

      <div className={'children-wrapper'}>{props?.children}</div>
    </div>
  );
};

export { ContentWrapper };
