import {
  ActivityLogsParams,
  ClientItem,
  ClientListFilterParams,
  CustomVariableFilterParams,
  CustomerListFilterParams,
  CustomerProfileParams,
  EmailTemplateFilterParams,
  EmailTemplateParams,
  EmailTemplatesFilterParams,
  EquipmentBodyParams,
  EquipmentFilterParams,
  EquipmentParams,
  FileUploaderParams,
  GetLocationsParams,
  IpListParams,
  JobItem,
  JobListFilterParams,
  JobProgressListFilterParams,
  LeadItem,
  LeadListFilterParams,
  PaginationParams,
  ProgressBarTypeFilterParams,
  QuickNotesParams,
  TaskFileUploaderParams,
  TaskItem,
  TaskTypeListFilterParams,
} from '@/types';
import { createRequestUrl } from '@/utils';
import { timezone } from '@/utils/timeZone';

export const endpoints = {
  public: {
    login: `/login`,
  },
  private: {
    logout: `/logout`,
    getUserProfile: `/profile`,
    updateUserProfile: `/profile`,
    saveProfileEmailSignature: `/profile/email-signature`,

    getUsers: (params: PaginationParams) => `/users?page=${params?.page}&limit=${params?.limit}`,
    getUser: (id: string) => `/users/${id}`,
    createUser: `/users`,
    updateUser: (id: string) => `/users/${id}`,
    userResetPassword: (id: string) => `/users/${id}`,
    deleteUser: (id: string) => `/users/${id}`,

    getCustomers: (params: CustomerListFilterParams) =>
      `/customers?${params?.customer_type ? 'customer_type=' + params?.customer_type : ''}&page=${params?.page}&limit=${
        params?.limit
      }`,

    uploadFile: (params: FileUploaderParams) => `/files/${params?.type}`,
    deleteFile: (id: string) => `/files/${id}`,

    getLeadClientJobSettings: `/settings/common`,
    saveLeadClientJobSettings: `/settings/common`,

    getSourceList: `/settings/sources?limit=all`,
    saveSourceItem: `/settings/sources`,
    deleteSourceItem: (id: string) => `/settings/sources/${id}`,

    getApiKeyList: `/settings/third_party/api_keys`,
    saveApiKeyItem: `/settings/third_party/api_keys`,
    deleteApiKeyItem: (id: string) => `/settings/third_party/api_keys/${id}`,

    getJobProgressList: (params: JobProgressListFilterParams) =>
      `/settings/progress-bars?progressType=${params?.progressType}&sort=priority&limit=all`, // TODO:: need refactor sort and limit
    saveJobProgressItem: `/settings/progress-bars`,
    deleteJobProgressItem: (id: string) => `/settings/progress-bars/${id}`,
    updateProgressList: (id: string) => `/progress-bars/leads/${id}`,

    getPublicIPList: `/settings/ips?ipType=public&limit=all`,
    savePublicIP: `/settings/ips`,
    deletePublicIP: (id: string) => `/settings/ips/${id}`,

    getPrivateIPList: `/settings/ips?ipType=private&limit=all`,
    savePrivateIP: `/settings/ips`,
    deletePrivateIP: (id: string) => `/settings/ips/${id}`,

    getEquipments: (params: EquipmentFilterParams) =>
      `/settings/equipments?page=${params?.page}&limit=${params?.limit}${params?.search ? '&s=' + params?.search : ''}`,
    getEquipment: (id: string) => `/settings/equipments/${id}`,
    createEquipment: `/settings/equipments`,
    updateEquipment: (id: string) => `/settings/equipments/${id}`,
    deleteEquipment: (id: string) => `/settings/equipments/${id}`,

    getProgressBarTypes: (params?: ProgressBarTypeFilterParams) =>
      `/settings/progress-bar-types?${params?.progressType ? 'get_progress_type=' + params?.progressType : ''}`,

    getSmtpSettings: `/settings/emails/smtp-settings`,
    saveSmtpSettings: `/settings/emails/smtp-settings`,
    sentTestMail: `/settings/emails/smtp-settings/test`,

    getCustomVariables: (params: CustomVariableFilterParams) =>
      `/settings/emails/custom-variables?page=${params?.page}&limit=${params?.limit}`,
    getCustomVariable: (id: string) => `/settings/emails/custom-variables/${id}`,
    getCustomVariableCustomerColumns: `/settings/emails/custom-variables/customer-columns`,
    createCustomVariable: `/settings/emails/custom-variables`,
    updateCustomVariable: (id: string) => `/settings/emails/custom-variables/${id}`,
    deleteCustomVariable: (id: string) => `/settings/emails/custom-variables/${id}`,

    getAllApiInformation: `/settings/apis`,
    connectGoogleCalendarApi: `/settings/apis/google-calendar`,
    connectDocusignApi: `/settings/apis/docusign`,
    connectDripApi: `/settings/apis/drip`,
    connectUcrmApi: `/settings/apis/ucrm`,
    disconnectApi: `/settings/apis/disconnect`,

    createLocation: `/settings/locations`,
    updateLocation: (id: string) => `/settings/locations/${id}`,
    deleteLocation: (id: string) => `/settings/locations/${id}`,
    getAllLocations: ({ location_type, page, limit }: GetLocationsParams) =>
      `/settings/locations?location_type=${location_type}&page=${page}&limit=${limit}`,

    getEmailTemplateList: (params: EmailTemplatesFilterParams) =>
      `/settings/emails/templates?page=${params?.page}&limit=${params?.limit}${
        params?.templatesFor ? '&get_email_templates_for=' + params?.templatesFor : ''
      }`,
    getEmailTemplate: (params: EmailTemplateFilterParams) =>
      `/settings/emails/templates/${params?.id}&template=${params?.template}`,
    createEmailTemplate: `/settings/emails/templates`,
    updateEmailTemplate: (id: string) => `/settings/emails/templates/${id}`,
    deleteEmailTemplate: (id: string) => `/settings/emails/templates/${id}`,
    emailTemplateSendTestMail: `/settings/emails/templates/test`,
    leadClientEmailTemplateSendMail: (customerId: string) => `/customer/${customerId}/email/send`,
    jobEmailTemplateSendMail: (jobId: string) => `/jobs/${jobId}/email/send`,
    getLeads: (params: LeadListFilterParams) =>
      createRequestUrl({
        url: `/leads`,
        params: {
          page: params?.page,
          limit: params?.limit,
          customer_type: params?.type ?? undefined,
          timezone,
          s: params?.s === '' ? undefined : params?.s,
        },
      }),
    createLead: `/leads`,
    updateLead: (customerId: CustomerProfileParams['id']) => `/customers/${customerId}`,
    uploadLeadFile: (id: string) => `/leads/${id}/files`,
    getLeadFileDetails: (id: string) => `/leads/${id}/files?sort=-created_at`,
    deleteLeadFile: (id: string) => `/files/${id}`,
    getLeadContractPDF: (leadId: LeadItem['id']) => `/leads/${leadId}/contract/download`,
    updateLeadVMCount: (id: string) => `/customer-details/${id}/vm-count`,
    sendsLeadContract: (id: string) => `/leads/${id}/contract/send`,
    restoresLostLead: (id: string) => `/customer-details/${id}/restore`,
    deleteLostLeadAndClient: (id: string) => `/leads/${id}`,

    createLeadEquipments: (leadId: EquipmentBodyParams['leadId']) => `/leads/${leadId}/equipments`,
    getLeadEquipments: (id: EquipmentParams['id']) => `/leads/${id}/equipments`,
    deleteLeadEquipment: (id: EquipmentParams['id']) => `leads/equipments/${id}`,
    deleteAllLeadEquipments: (leadId: EquipmentBodyParams['leadId']) => `/leads/${leadId}/equipments`,
    updateCustomerLead: (customerId: CustomerProfileParams['id']) => `/customer-details/${customerId}/move`,
    updateLeadCustomerDetails: (customerId: CustomerProfileParams['id']) => `/customer-details/${customerId}`,
    getLeadQuickNotes: (lead_id: QuickNotesParams['id']) => `/leads/${lead_id}/quick-notes?sort=created_at`,
    createLeadQuickNotes: (lead_id: QuickNotesParams['id']) => `/leads/${lead_id}/quick-notes`,
    updateLeadQuickNotes: (note_id: QuickNotesParams['id']) => `/leads/quick-notes/${note_id}`,
    deleteLeadQuickNotes: (note_id: QuickNotesParams['id']) => `/leads/quick-notes/${note_id}`,
    getLeadActivityLogs: (params: ActivityLogsParams) =>
      `/leads/${params.id}/activity-logs?timezone=${params?.timezone}&page=${params?.page}&limit=${params?.limit}`,
    moveLead: (leadId: LeadItem['id']) => `/leads/${leadId}/move`,
    sendLeadDrip: (leadId: LeadItem['id']) => `/leads/${leadId}/drip/subscribe`,
    sendLeadFilter: `/leads/filter`,
    parseLeadEmailTemplate: (leadId: LeadItem['id'], templateId: EmailTemplateParams['id']) =>
      `/leads/${leadId}/resolve/templates/${templateId}`,
    parseLeadOrClientEmailTemplate: (leadId: LeadItem['id']) => `/customer/${leadId}/email/preview`,

    getSingleActivityEmail: (activityEmailId: string) => `/activity-emails/${activityEmailId}`,
    getClients: (params: ClientListFilterParams) =>
      createRequestUrl({
        url: `/leads`,
        params: {
          page: params?.page,
          limit: params?.limit,
          customer_type: params?.type ?? undefined,
          timezone,
          s: params?.s === '' ? undefined : params?.s,
        },
      }),
    createClient: `/leads`,
    updateClient: (customerId: CustomerProfileParams['id']) => `/customers/${customerId}`,
    uploadClientFile: (id: string) => `/leads/${id}/files`,
    getClientFileDetails: (id: string) => `/leads/${id}/files?sort=-created_at`,
    deleteClientFile: (id: string) => `/files/${id}`,
    getClientContractPDF: (leadId: ClientItem['id']) => `/leads/${leadId}/contract/download`,
    updateClientVMCount: (id: string) => `/customer-details/${id}/vm-count`,
    sendsClientContract: (id: string) => `/leads/${id}/contract/send`,
    restoreDeactivatedClient: (id: string) => `/leads/${id}/move`,
    createClientEquipments: (leadId: EquipmentBodyParams['leadId']) => `/leads/${leadId}/equipments`,
    getClientEquipments: (id: EquipmentParams['id']) => `/leads/${id}/equipments`,
    deleteClientEquipment: (id: EquipmentParams['id']) => `leads/equipments/${id}`,
    deleteAllClientEquipments: (leadId: EquipmentBodyParams['leadId']) => `/leads/${leadId}/equipments`,
    updateCustomerClient: (customerId: CustomerProfileParams['id']) => `/leads/${customerId}/move`,
    updateClientCustomerDetails: (customerId: CustomerProfileParams['id']) => `/customer-details/${customerId}`,
    getClientQuickNotes: (lead_id: QuickNotesParams['id']) => `/leads/${lead_id}/quick-notes?sort=created_at`,
    createClientQuickNotes: (lead_id: QuickNotesParams['id']) => `/leads/${lead_id}/quick-notes`,
    updateClientQuickNotes: (note_id: QuickNotesParams['id']) => `/leads/quick-notes/${note_id}`,
    deleteClientQuickNotes: (note_id: QuickNotesParams['id']) => `/leads/quick-notes/${note_id}`,
    getClientActivityLogs: (params: ActivityLogsParams) =>
      `/leads/${params.id}/activity-logs?timezone=${params?.timezone}&page=${params?.page}&limit=${params?.limit}`,
    moveClient: (leadId: ClientItem['id']) => `/leads/${leadId}/move`,
    sendClientFilter: `/leads/filter`,
    parseClientEmailTemplate: (leadId: ClientItem['id'], templateId: EmailTemplateParams['id']) =>
      `/leads/${leadId}/resolve/templates/${templateId}`,

    getTasks: (params: TaskTypeListFilterParams) =>
      `/tasks?page=${params?.page}&limit=${params?.limit}${params?.s ? '&s=' + params?.s : ''}&timezone=${timezone}`,
    getFilteredTasks: () => `/tasks/filter`,
    getSingleTask: (taskId: TaskItem['id']) => `/tasks/${taskId}?timezone=${timezone}`,
    getTaskTypes: (params: PaginationParams) => `/task-types?page=${params?.page}&limit=${params?.limit}`,
    updateTask: (taskId: TaskItem['id']) => `/tasks/${taskId}`,
    deleteTask: (taskId: TaskItem['id']) => `/tasks/${taskId}`,
    changeTaskAssignee: (taskId: TaskItem['id']) => `/tasks/${taskId}/update-assignee`,
    markTaskAsCompleted: (taskId: TaskItem['id']) => `/tasks/${taskId}/mark-as-completed`,
    changeTaskPriority: (taskId: TaskItem['id']) => `/tasks/${taskId}/update-task-priority`,
    startStopTaskTimer: (taskId: TaskItem['id']) => `/tasks/${taskId}/update-time-log`,
    pingTaskAssignee: (taskId: TaskItem['id']) => `/tasks/${taskId}/ping`,
    createTask: () => `/tasks`,
    uploadTaskFile: (params: TaskFileUploaderParams) => `files/${params?.type}/${params?.filable_id}`,

    // equipment orders
    createEquipmentOrders: `/equipment-orders`,
    getEquipmentOrdersCategory: `/equipment-orders/category`,
    getEquipmentOrdersOpen: (params: PaginationParams) =>
      `equipment-orders?orderType=open&page=${params?.page}&limit=${params?.limit}`,
    getEquipmentOrdersCompleted: (params: PaginationParams) =>
      `equipment-orders?orderType=completed&page=${params?.page}&limit=${params?.limit}`,
    getEquipmentOrdersCancelled: (params: PaginationParams) =>
      `equipment-orders?orderType=cancelled&page=${params?.page}&limit=${params?.limit}`,
    updateEquipmentOrders: (id: EquipmentParams['id']) => `/equipment-orders/${id}`,

    //ip
    getIpList: (params: IpListParams) => `/settings/ips?ipType=${params?.ip_type}&limit=all`,
    getIpChildren: (id: IpListParams['id']) => `/ips/${id}/children`,
    searchIpDetails: (params: IpListParams) =>
      `/ips?s=${params?.s}${params?.parent_ip_id ? '&parent_ip=' + params?.parent_ip_id : ''}&page=${
        params?.page
      }&limit=${params?.limit}`,
    updateIpDetails: (id: IpListParams['id']) => `/ip-details/${id}`,

    // Jobs
    getJobs: (params: JobListFilterParams) =>
      createRequestUrl({
        url: `/jobs`,
        params: {
          page: params?.page,
          limit: params?.limit,
          type: params?.type ?? undefined,
          timezone,
          s: params?.s === '' ? undefined : params?.s,
        },
      }),
    createJob: `/jobs`,
    updateJob: (id: string) => `/jobs/${id}/update`,
    updateJobDetails: (id: string) => `/jobs/${id}`,
    uploadJobFile: (id: string) => `/jobs/${id}/files`,
    getJobFileDetails: (id: string) => `/jobs/${id}/files?sort=-created_at`,
    deleteJobFile: (id: string) => `/files/${id}`,
    getJobContractPDF: (jobId: JobItem['id']) => `/jobs/${jobId}/contract/download`,
    updateJobVMCount: (id: string) => `/jobs/${id}/vm-count`,
    sendsJobContract: (id: string) => `/jobs/${id}/contract/send`,
    restoresJob: (id: string) => `/customer-details/${id}/restore`,
    createJobEquipments: (jobId: EquipmentBodyParams['leadId']) => `/jobs/${jobId}/equipments`,
    getJobEquipments: (id: EquipmentParams['id']) => `/jobs/${id}/equipments`,
    deleteJobEquipment: (id: EquipmentParams['id']) => `jobs/equipments/${id}`,
    deleteAllJobEquipments: (jobId: EquipmentBodyParams['leadId']) => `/jobs/${jobId}/equipments`,
    updateJobStatus: (jobId: JobItem['id']) => `/jobs/${jobId}/status`,
    updateJobCustomerDetails: (customerId: CustomerProfileParams['id']) => `/customer-details/${customerId}`,
    getJobQuickNotes: (job_id: QuickNotesParams['id']) => `/jobs/${job_id}/notes?sort=created_at&timezone=${timezone}`,
    createJobQuickNotes: (job_id: QuickNotesParams['id']) => `/jobs/${job_id}/notes?timezone=${timezone}`,
    updateJobQuickNotes: (note_id: QuickNotesParams['id']) => `/jobs/${note_id}/notes?timezone=${timezone}`,
    toggleJobQuickNotePin: (note_id: QuickNotesParams['id']) => `/jobs/${note_id}/notes/pinned?timezone=${timezone}`,
    deleteJobQuickNotes: (note_id: QuickNotesParams['id']) => `/jobs/${note_id}/notes?timezone=${timezone}`,
    getJobActivityLogs: (params: ActivityLogsParams) =>
      `/jobs/${params.id}/activity-logs?timezone=${params?.timezone}&page=${params?.page}&limit=${params?.limit}`,
    moveJob: (jobId: JobItem['id']) => `/jobs/${jobId}/move`,
    sendJobDrip: (jobId: JobItem['id']) => `/jobs/${jobId}/drip/subscribe`,
    sendJobFilter: `/jobs/filter`,
    parseJobOrClientEmailTemplate: (jobId: JobItem['id']) => `/jobs/${jobId}/emails/resolve`,
    getJobTypes: () => `/jobs/types`,

    updateJobProgressList: (id: string) => `/jobs/${id}/progress-bar?timezone=${timezone}`,
    parseJobEmailTemplate: (jobId: JobItem['id']) => `/jobs/${jobId}/email/preview`,

    postTotalPLYearSummaryReport: () => `/reports/general/pl`,
    getProfitAndLossReport: () => `/reports/pl`,
    getLostVsWonReport: () => `/reports/lost-vs-won`,
    getProgressReport: () => `/reports/progress`,
    getExpensesBreakdownReport: () => `/reports/general/expenses`,

    getMediafiles: `/files?file_type=images&limit=all`,
    postMediaFiles: `/files`,

    removeLeadContact: (id: string) => `/contacts/${id}`,
  },
};
