import {
  getExpensesBreakdownReport,
  getLostVsWonReport,
  getProfitAndLossReport,
  getProgressReport,
  getReportStaffMembersDropdownHttpCall,
  postTotalPlYearSummaryReport,
} from '@/redux/reducers/http/httpCallSets/reportHttpCallSets';
import { store } from '@/redux/store';
import {
  GeneralPLYearSummaryReportPayload,
  GetExpensesBreakdown,
  GetProfitAndLossReport,
  GetProfitWonAndLoss,
  UserListFilterParams,
} from '@/types';

export function getReportStaffMembers(params: UserListFilterParams) {
  store.dispatch(getReportStaffMembersDropdownHttpCall(params));
}

export function getGeneralTotalPlYearSummaryReports(params: GeneralPLYearSummaryReportPayload) {
  store.dispatch(postTotalPlYearSummaryReport(params));
}

export function getProfitAndLossReportAction(params: GetProfitAndLossReport) {
  store.dispatch(getProfitAndLossReport(params));
}
export function getWonVsLossReportAction(params: GetProfitWonAndLoss) {
  store.dispatch(getLostVsWonReport(params));
}
export function getProgressReportAction(params: GetProfitWonAndLoss) {
  store.dispatch(getProgressReport(params));
}
export function getExpensesBreakdownReportAction(params: GetExpensesBreakdown) {
  store.dispatch(getExpensesBreakdownReport(params));
}
