import React from 'react';
import { NavigationTabPanel, NavigationTabPanelProps } from '@/components/organisms/NavigationTabPanel';
import Grid from '@mui/material/Grid';
import { useSettingTabPanelStyles } from '@/static/stylesheets/molecules';
import { routePaths } from '@/routes';

interface SettingsTabPanelProps {}

const SettingsTabPanel: React.FC<SettingsTabPanelProps> = (props) => {
  const classes = useSettingTabPanelStyles();

  const tabItems: NavigationTabPanelProps['items'] = [
    {
      label: 'General Settings',
      route: routePaths.settings.generalSettings,
    },
    {
      label: 'Email',
      route: routePaths.settings.email,
    },
    {
      label: 'Pricing',
      route: routePaths.settings.pricing,
    },
    {
      label: 'Equipment',
      route: routePaths.settings.equipment,
    },
    {
      label: 'IP Addresses',
      route: routePaths.settings.ipAddress,
    },
    {
      label: 'APIs',
      route: routePaths.settings.apis,
    },
    {
      label: 'Staff Members',
      route: routePaths.settings.staffMembers,
    },
  ];

  return (
    <>
      <Grid container spacing={2} className={classes.root}>
        <Grid item xs={12}>
          <NavigationTabPanel items={tabItems} />
        </Grid>
      </Grid>
    </>
  );
};

export { SettingsTabPanel };
