import React, { useEffect } from 'react';
import { useLeadExpandBodyStyle } from '@/static/stylesheets/organisms';
import { TextInput } from '@/components/atoms/TextInput';
import { DatePicker } from '@/components/atoms/DatePicker';
import { TimePicker } from '@/components/atoms/TimePicker';
import { Grid } from '@mui/material';
import { ToggleSaveButtonEnabled } from '@/components/molecules/Leads/SingleLead';
import { useInput, useAppSelector } from '@/hooks';
import { getSourceItemList, updateClientsFormData } from '@/redux/actions';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { ClientItem } from '@/types';
import { dateWithTime } from '@/utils';

interface SourceProps {
  toggleSaveEnabled: ToggleSaveButtonEnabled;
  clientData?: ClientItem;
  isDeactivatedClient?: boolean;
}

const Source: React.FC<SourceProps> = (props) => {
  const classes = useLeadExpandBodyStyle();
  const { clientData, isDeactivatedClient } = props;
  const sourceItemList = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_SOURCE_LIST];
  const sourceList = sourceItemList?.data?.data ?? [];

  const resetFields = () => {
    resetSource();
  };

  const {
    value: sources,
    reset: resetSource,
    setValue: setSources,
    error: sourceError,
    bind: bindSource,
  } = useInput('1', {
    required: 'Please select Source!',
    onChange(value: any) {
      props?.toggleSaveEnabled(true);
    },
  });

  const {
    value: surveyDate,
    reset: resetSurveyDate,
    setValue: setSurveyDate,
    error: surveyDateError,
    setError: setSurveyDateError,
    bind: bindSurveyDate,
  } = useInput('', {
    required: 'Please select Source!',
    onChange() {
      props?.toggleSaveEnabled(true);
    },
  });
  const {
    value: surveyTimeFrom,
    reset: resetSurveyTimeFrom,
    setValue: setSurveyTimeFrom,
    error: surveyTimeFromError,
    setError: setSurveyTimeFromError,
    bind: bindSurveyTimeForm,
  } = useInput('', {
    required: 'Please select Source!',
    onChange() {
      props?.toggleSaveEnabled(true);
    },
  });
  const {
    value: surveyTimeTo,
    reset: resetSurveyTimeTo,
    setValue: setSurveyTimeTo,
    error: surveyTimeToError,
    setError: setSurveyTimeToError,
    bind: bindSurveyTimeTo,
  } = useInput('', {
    required: 'Please select Source!',
    onChange() {
      props?.toggleSaveEnabled(true);
    },
  });

  const {
    value: installationDate,
    reset: resetInstallationDate,
    setValue: setInstallationDate,
    error: installationDateError,
    setError: setInstallationDateError,
    bind: bindInstallationDate,
  } = useInput('', {
    required: 'Please select Source!',
    onChange() {
      props?.toggleSaveEnabled(true);
    },
  });

  const {
    value: installationTimeFrom,
    reset: resetInstallationTimeFrom,
    setValue: setInstallationTimeFrom,
    error: installationTimeFromError,
    setError: setInstallationTimeFromError,
    bind: bindInstallationTimeFrom,
  } = useInput('', {
    required: 'Please select Source!',
    onChange() {
      props?.toggleSaveEnabled(true);
    },
  });

  const {
    value: installationTimeTo,
    reset: resetInstallationTimeTo,
    setValue: setInstallationTimeTo,
    error: installationTimeToError,
    setError: setInstallationTimeToError,
    bind: bindInstallationTimeTo,
  } = useInput('', {
    required: 'Please select Source!',
    onChange() {
      props?.toggleSaveEnabled(true);
    },
  });

  const {
    value: followUpDate,
    reset: resetFollowUpDate,
    setValue: setFollowUpDate,
    error: followUpDateError,
    setError: setFollowUpDateError,
    bind: bindFollowUpDate,
  } = useInput('', {
    required: 'Please select Source!',
    onChange() {
      props?.toggleSaveEnabled(true);
    },
  });

  const {
    value: followUpTime,
    reset: resetFollowUpTime,
    setValue: setFollowUpTime,
    error: followUpTimeError,
    setError: setFollowUpTimeError,
    bind: bindFollowUpTime,
  } = useInput('', {
    required: 'Please select Source!',
    onChange() {
      props?.toggleSaveEnabled(true);
    },
  });

  useEffect(() => {
    if (surveyDate && (!surveyTimeFrom || !surveyTimeTo)) {
      if (!surveyTimeFrom) {
        setSurveyTimeFromError('Please input survey from time!');
      }

      if (!surveyTimeTo) {
        setSurveyTimeToError('Please input survey to time!');
      }
    }

    if (surveyTimeFrom && (!surveyDate || !surveyTimeTo)) {
      if (!surveyDate) {
        setSurveyDateError('Please input survey date!');
      }

      if (!surveyTimeTo) {
        setSurveyTimeToError('Please input survey to time!');
      }
    }

    if (surveyTimeTo && (!surveyDate || !surveyTimeFrom)) {
      if (!surveyDate) {
        setSurveyDateError('Please input survey date!');
      }

      if (!surveyTimeFrom) {
        setSurveyTimeFromError('Please input survey from time!');
      }
    }

    if ((!surveyDate && !surveyTimeFrom && !surveyTimeTo) || (surveyDate && surveyTimeFrom && surveyTimeTo)) {
      setSurveyDateError(null);
      setSurveyTimeFromError(null);
      setSurveyTimeToError(null);
    }
  }, [surveyDate, surveyTimeFrom, surveyTimeTo]);

  useEffect(() => {
    if (installationDate && (!installationTimeFrom || !installationTimeTo)) {
      if (!installationTimeFrom) {
        setInstallationTimeFromError('Please input installation from time!');
      }

      if (!installationTimeTo) {
        setInstallationTimeToError('Please input installation to time!');
      }
    }

    if (installationTimeFrom && (!installationDate || !installationTimeTo)) {
      if (!installationDate) {
        setInstallationDateError('Please input installation date!');
      }

      if (!installationTimeTo) {
        setInstallationTimeToError('Please input installation to time!');
      }
    }

    if (installationTimeTo && (!installationDate || !installationTimeFrom)) {
      if (!installationDate) {
        setInstallationDateError('Please input installation date!');
      }

      if (!installationTimeFrom) {
        setInstallationTimeFromError('Please input installation from time!');
      }
    }

    if (
      (!installationDate && !installationTimeFrom && !installationTimeTo) ||
      (installationDate && installationTimeFrom && installationTimeTo)
    ) {
      setInstallationDateError(null);
      setInstallationTimeFromError(null);
      setInstallationTimeToError(null);
    }
  }, [installationDate, installationTimeFrom, installationTimeTo]);

  useEffect(() => {
    if (followUpDate && !followUpTime) {
      setFollowUpTimeError('Please input follow up time!');
    }

    if (followUpTime && !followUpDate) {
      setFollowUpDateError('Please input follow up date!');
    }

    if ((!followUpDate && !followUpTime) || (followUpDate && followUpTime)) {
      setFollowUpDateError(null);
      setFollowUpTimeError(null);
    }
  }, [followUpDate, followUpTime]);

  useEffect(() => {
    updateClientsFormData({
      source_id: sources,

      survey_date: surveyDate,
      survey_time_from: surveyTimeFrom,
      survey_time_to: surveyTimeTo,
      survey_datetime_from: dateWithTime(surveyDate, surveyTimeFrom),
      survey_datetime_to: dateWithTime(surveyDate, surveyTimeTo),

      installation_date: installationDate,
      installation_time_from: installationTimeFrom,
      installation_time_to: installationTimeTo,
      installation_datetime_from: dateWithTime(installationDate, installationTimeFrom),
      installation_datetime_to: dateWithTime(installationDate, installationTimeTo),

      next_follow_up_date: followUpDate,
      next_follow_up_time: followUpTime,
      next_follow_up_datetime: dateWithTime(followUpDate, followUpTime),
    });
  }, [
    sources,
    surveyDate,
    surveyTimeFrom,
    surveyTimeTo,
    installationDate,
    installationTimeFrom,
    installationTimeTo,
    followUpDate,
    followUpTime,
  ]);

  useEffect(() => {
    if (clientData) {
      const data = clientData;
      setSources(data?.source_id ? data?.source_id : '1');
      setSurveyDate(data?.survey_datetime_from || data?.survey_datetime_to);
      setSurveyTimeFrom(data?.survey_datetime_from);
      setSurveyTimeTo(data?.survey_datetime_to);
      setInstallationDate(data?.installation_datetime_from || data?.installation_datetime_to);
      setInstallationTimeFrom(data?.installation_datetime_from);
      setInstallationTimeTo(data?.installation_datetime_to);
      setFollowUpDate(data?.next_follow_up_datetime);
      setFollowUpTime(data?.next_follow_up_datetime);
    }
  }, [clientData]);

  useEffect(() => {
    getSourceItemList();
  }, []);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.selector}>
          <TextInput
            select={true}
            variant={'standard'}
            fullWidth={true}
            options={[
              {
                title: 'Source',
                value: '1',
                disabled: true,
              },
              ...(sourceList && sourceList?.length > 0
                ? sourceList?.map((item: any) => ({
                    title: item?.source_name,
                    value: item?.id,
                  }))
                : []),
            ]}
            {...bindSource}
            error={sourceError?.error}
            errorText={sourceError?.message}
            disabled={isDeactivatedClient}
          />
          <span>{sourceError?.message}</span>
        </div>
        <TextInput
          variant={'standard'}
          placeholder={'UCRM Link'}
          fullWidth={true}
          value={clientData?.ucrm_url}
          disabled={true}
        />
        <div className={classes.dateBody}>
          <label>Survey Date & Arrival Time</label>
          <Grid container spacing={2}>
            <Grid item sm={4} md={4} lg={4}>
              <DatePicker
                value={bindSurveyDate.value}
                onChange={(value) => {
                  bindSurveyDate.onChange({
                    target: {
                      value,
                    },
                  } as any);
                }}
                error={surveyDateError.error}
                disabled={isDeactivatedClient}
              />
            </Grid>
            <Grid item sm={4} md={4} lg={4}>
              <TimePicker
                placeholder={'Arrival From'}
                value={bindSurveyTimeForm.value}
                onChange={(value) => {
                  bindSurveyTimeForm.onChange({
                    target: {
                      value,
                    },
                  } as any);
                }}
                error={surveyTimeFromError.error}
                disabled={isDeactivatedClient}
              />
            </Grid>
            <Grid item sm={4} md={4} lg={4}>
              <TimePicker
                placeholder={'Arrival To'}
                value={bindSurveyTimeTo.value}
                onChange={(value) => {
                  bindSurveyTimeTo.onChange({
                    target: {
                      value,
                    },
                  } as any);
                }}
                error={surveyTimeToError.error}
                disabled={isDeactivatedClient}
              />
            </Grid>
          </Grid>
        </div>
        <div className={classes.dateBody}>
          <label>Install Date & Arrival Time</label>
          <Grid container spacing={2}>
            <Grid item sm={4} md={4} lg={4}>
              <DatePicker
                value={bindInstallationDate.value}
                onChange={(value) => {
                  bindInstallationDate.onChange({
                    target: {
                      value,
                    },
                  } as any);
                }}
                error={installationDateError.error}
                disabled={isDeactivatedClient}
              />
            </Grid>
            <Grid item sm={4} md={4} lg={4}>
              <TimePicker
                placeholder={'Arrival From'}
                value={bindInstallationTimeFrom.value}
                onChange={(value) => {
                  bindInstallationTimeFrom.onChange({
                    target: {
                      value,
                    },
                  } as any);
                }}
                error={installationTimeFromError.error}
                disabled={isDeactivatedClient}
              />
            </Grid>
            <Grid item sm={4} md={4} lg={4}>
              <TimePicker
                placeholder={'Arrival To'}
                value={bindInstallationTimeTo.value}
                onChange={(value) => {
                  bindInstallationTimeTo.onChange({
                    target: {
                      value,
                    },
                  } as any);
                }}
                error={installationTimeToError.error}
                disabled={isDeactivatedClient}
              />
            </Grid>
          </Grid>
        </div>
        <div className={classes.dateBody}>
          <label>Next Follow Up</label>
          <Grid container spacing={2}>
            <Grid item sm={6} md={6} lg={6}>
              <DatePicker
                value={bindFollowUpDate.value}
                onChange={(value) => {
                  bindFollowUpDate.onChange({
                    target: {
                      value,
                    },
                  } as any);
                }}
                error={followUpDateError.error}
                disabled={isDeactivatedClient}
              />
            </Grid>
            <Grid item sm={6} md={6} lg={6}>
              <TimePicker
                placeholder={'Time'}
                value={bindFollowUpTime.value}
                onChange={(value) => {
                  bindFollowUpTime.onChange({
                    target: {
                      value,
                    },
                  } as any);
                }}
                error={followUpTimeError.error}
                disabled={isDeactivatedClient}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export { Source };
