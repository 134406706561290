import { css } from '@emotion/css';
import { colors, themeParser, useAppTheme } from '@/theme';

export const useLoginFormStyles = () => {
  const theme = useAppTheme();

  return {
    root: css`
      background: #2a2a2a;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
    `,
    loginContainer: css`
      box-shadow:
        0 2px 2px 0 rgb(0 0 0 / 14%),
        0 3px 1px -2px rgb(0 0 0 / 12%),
        0 1px 5px 0 rgb(0 0 0 / 20%);
      padding: 20px;
      border-radius: 6px;
      margin: 0 auto;
      border-bottom: 5px solid #2196f3;
      width: 400px;
      top: 15%;
      position: relative;
      max-width: 100%;
      img {
        margin: 0 auto 30px;
        display: block;
        width: 270px;
      }
      @media (max-width: 480px) {
        width: 85% !important;
        margin: auto;
      }
      label {
        color: ${themeParser(colors.textInput.labelColor.dark, colors.textInput.labelColor.dark, theme)} !important;
        font-size: 14px;
      }

      .MuiInput-underline:before {
        border-color: ${themeParser(
          colors.textInput.borderColor.dark,
          colors.textInput.borderColor.dark,
          theme,
        )} !important;
      }
      .MuiInput-underline:hover:before {
        border-bottom: 1px solid !important;
      }
      input::placeholder {
        color: ${themeParser(
          colors.textInput.placeholderColor.dark,
          colors.textInput.placeholderColor.light,
          theme,
        )} !important;
      }
    `,
    inputForm: css`
      margin: 0 0 20px !important;
    `,
    button: css`
      button {
        color: ${themeParser(colors.body.light, colors.body.dark, theme)} !important;
        font-weight: 500;
        letter-spacing: 3px;
        margin: 5px 0 10px;
        height: 36px;
        line-height: 36px;
        padding: 0 16px;
        text-transform: uppercase;
      }
    `,
  };
};
