import React from 'react';
import { PrivateLayout } from '@/components/Middleware';
import { TasksContent } from '@/components/molecules/Tasks';
import { TasksLayout } from '@/components/layouts/TasksLayout';

const Tasks = () => {
  return (
    <>
      <PrivateLayout>
        <TasksLayout
          hideSettingsTabPanel={false}
          hideHeaderRightSide={true}
          containerSize={'xxl'}
          featureType={'tasks'}
        >
          <TasksContent />
        </TasksLayout>
      </PrivateLayout>
    </>
  );
};

export default Tasks;
