import React, { useState } from 'react';
import { useIpAddressStyles } from '@/static/stylesheets/molecules/IP';
import { Button } from '@/components/atoms/Button';

interface IpAddressHeaderProps {
  search: string;
  selectedIpType: 'public' | 'private';

  onChangeSearch(value: string | null): void;

  onSelectIpType(type: 'public' | 'private'): void;
}

const IpAddressHeader: React.FC<IpAddressHeaderProps> = (props) => {
  const { selectedIpType, search, onChangeSearch, onSelectIpType } = props;
  const classes = useIpAddressStyles();

  return (
    <div className={classes.headerRoot}>
      <div className={'action-button-group'}>
        <Button
          title={"PRIVATE IP'S"}
          color={selectedIpType === 'private' ? 'primary' : 'inherit'}
          onClick={() => onSelectIpType('private')}
        />

        <Button
          title={"PUBLIC IP'S"}
          color={selectedIpType === 'public' ? 'primary' : 'inherit'}
          onClick={() => onSelectIpType('public')}
        />
      </div>
      <div className={'search-section'}>
        <input
          type={'search'}
          placeholder={'Global Search'}
          value={search}
          onChange={(e) => {
            const value = e?.target?.value;
            onChangeSearch(value === '' ? null : value);
          }}
        />
        <Button title={'Reset'} onClick={() => onChangeSearch(null)} />
      </div>
    </div>
  );
};

export { IpAddressHeader };
