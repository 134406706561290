import React, { useEffect, useState } from 'react';
import { EditorInstance, RichTextEditor } from '@/components/organisms/RichTextEditor';
import { useComponentState, useInput } from '@/hooks';
import { Grid } from '@mui/material';
import { DatePicker } from '@/components/atoms/DatePicker';
import { TimePicker } from '@/components/atoms/TimePicker';
import { TaskItem } from '@/types';
import { ChildTaskUpdateActionProps } from '@/components/molecules/Tasks';
import { FileUploader } from '@/components/molecules/Tasks/CreateTaskDialog/FileUploader';
import { format } from 'date-fns';
import { formatDateTime } from '@/utils';

interface SubTasksFormProps {
  selectedTaskIndexForEdit: number | null;

  taskData?: TaskItem | null;

  updateData(options: ChildTaskUpdateActionProps): void;
}

const SubTasksForm: React.FC<SubTasksFormProps> = (props) => {
  const { selectedTaskIndexForEdit, taskData, updateData } = props;
  const [editor, setEditor] = useState<EditorInstance | null>(null);

  const {
    state: {},
    setState,
  } = useComponentState<{}>({});

  const {
    value: description,
    setValue: setDescription,
    reset: resetDescription,
    error: descriptionError,
  } = useInput('', {
    required: 'Please enter description!',
  });

  const {
    value: dueDate,
    setValue: setDueDate,
    bind: bindDueDate,
    reset: resetDueDate,
    error: dueDateError,
  } = useInput(format(new Date(), 'MM/d/yyyy'), {
    required: 'Please input due date!',
  });

  const {
    value: dueTime,
    setValue: setDueTime,
    bind: bindDueTime,
    reset: resetDueTime,
    error: dueTimeError,
  } = useInput('', {
    required: 'Please input due time!',
  });

  const {
    value: reminderDate,
    setValue: setReminderDate,
    reset: resetReminderDate,
    error: reminderDateError,
  } = useInput(format(new Date(), 'MM/d/yyyy'), {
    required: 'This is required!',
  });

  const {
    value: reminderTime,
    setValue: setReminderTime,
    reset: resetReminderTime,
    error: reminderTimeError,
  } = useInput('', {
    required: 'This is required!',
  });

  useEffect(() => {
    if (selectedTaskIndexForEdit !== null) {
      updateData({
        index: selectedTaskIndexForEdit,
        payload: {
          description,
        },
      });
    }
  }, [description, selectedTaskIndexForEdit]);

  useEffect(() => {
    if (selectedTaskIndexForEdit !== null) {
      updateData({
        index: selectedTaskIndexForEdit,
        payload: {
          reminder_datetime: {
            ...taskData?.reminder_datetime,
            date: formatDateTime(new Date(reminderDate), 'MM/d/yyyy'),
          },
        },
      });
    }
  }, [reminderDate, selectedTaskIndexForEdit]);

  useEffect(() => {
    if (selectedTaskIndexForEdit !== null) {
      updateData({
        index: selectedTaskIndexForEdit,
        payload: {
          reminder_datetime: {
            ...taskData?.reminder_datetime,
            timestamp: reminderTime,
          },
        },
      });
    }
  }, [reminderTime, selectedTaskIndexForEdit]);

  useEffect(() => {
    if (selectedTaskIndexForEdit !== null) {
      updateData({
        index: selectedTaskIndexForEdit,
        payload: {
          due_datetime: {
            ...taskData?.due_datetime,
            date: formatDateTime(new Date(dueDate), 'MM/d/yyyy'),
          },
        },
      });
    }
  }, [dueDate, selectedTaskIndexForEdit]);

  useEffect(() => {
    if (selectedTaskIndexForEdit !== null) {
      updateData({
        index: selectedTaskIndexForEdit,
        payload: {
          due_datetime: {
            ...taskData?.due_datetime,
            timestamp: dueTime,
          },
        },
      });
    }
  }, [dueTime, selectedTaskIndexForEdit]);

  useEffect(() => {
    if (taskData) {
      editor?.setEditorValue(taskData?.description);
      setDueDate(taskData?.due_datetime?.date);
      setDueTime(taskData?.due_datetime?.timestamp);

      setReminderDate(taskData?.reminder_datetime?.date);
      setReminderTime(taskData?.reminder_datetime?.timestamp);
    }
  }, [taskData, editor]);

  return (
    <div>
      <div>
        <RichTextEditor
          placeholder={'Enter Text Here'}
          onChange={(value) => setDescription(value)}
          onMount={(editor) => setEditor(editor)}
          height={460}
        />
        {descriptionError && descriptionError?.message && (
          <div style={{ color: 'red', fontSize: 14, marginTop: '5px' }}>{descriptionError?.message}</div>
        )}
      </div>
      <div>
        <FileUploader
          type={'task'}
          filableId={taskData?.id}
          files={taskData?.files ?? []}
          onUploadSuccess={(fileList) => {
            if (selectedTaskIndexForEdit !== null) {
              updateData({
                index: selectedTaskIndexForEdit,
                payload: {
                  files: fileList,
                },
              });
            }
          }}
        />
      </div>
      <div style={{ marginTop: '20px' }} className={'subTask_body_date'}>
        <Grid container spacing={3}>
          <Grid item lg={6}>
            <div>Reminder Date</div>
            <div>
              <Grid container spacing={3}>
                <Grid item lg={6}>
                  <DatePicker
                    centerMode={true}
                    value={reminderDate}
                    onChange={(value) => {
                      setReminderDate(value);
                    }}
                    error={reminderDateError.error}
                    errorText={reminderDateError.message}
                  />
                </Grid>
                <Grid item lg={6}>
                  <TimePicker
                    centerMode={true}
                    value={reminderTime}
                    onChange={(value) => {
                      setReminderTime(value);
                    }}
                    error={reminderTimeError.error}
                    errorText={reminderTimeError.message}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item lg={6}>
            <div>Due Date</div>
            <div>
              <Grid container spacing={3}>
                <Grid item lg={6}>
                  <DatePicker
                    centerMode={true}
                    value={bindDueDate.value}
                    onChange={(value) => {
                      bindDueDate.onChange({
                        target: {
                          value,
                        },
                      } as any);
                    }}
                    error={dueDateError.error}
                    errorText={dueDateError.message}
                  />
                </Grid>
                <Grid item lg={6}>
                  <TimePicker
                    centerMode={true}
                    value={bindDueTime.value}
                    onChange={(value) => {
                      bindDueTime.onChange({
                        target: {
                          value,
                        },
                      } as any);
                    }}
                    error={dueTimeError.error}
                    errorText={dueTimeError.message}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export { SubTasksForm };
