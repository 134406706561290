import { DatePicker } from '@/components/atoms/DatePicker';
import { TextInput } from '@/components/atoms/TextInput';
import { useInput } from '@/hooks';
import { themeParser, useAppTheme } from '@/theme';
import { ReportsFormData } from '@/types';
import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

const SelectPeriod = () => {
  const theme = useAppTheme();
  const [isDisableDateInput, setIsDisableDateInput] = useState(true);

  const { watch, setValue } = useFormContext<ReportsFormData>();

  const formValues = watch();

  useEffect(() => {
    if (formValues.period === 'custom') {
      setIsDisableDateInput(false);
    } else {
      setIsDisableDateInput(true);
    }

    if (formValues.period === 'all_time') {
      setValue('period_start', undefined);
      setValue('period_end', undefined);
    }

    if (formValues.period === 'year_to_date') {
      const td = new Date();
      const startDate = `${td.getFullYear()}-01-01`;
      const endDate = td.toDateString();

      setValue('period_start', startDate);
      setValue('period_end', endDate);
    }

    if (formValues.period === 'today') {
      const td = new Date();
      const today = td.toDateString();

      setValue('period_start', today);
      setValue('period_end', today);
    }

    if (formValues.period === 'this_month') {
      const td = new Date();
      const startDate = `${td.getFullYear()}-${td.getMonth() + 1}-01`;
      const endDate = td.toDateString();

      setValue('period_start', startDate);
      setValue('period_end', endDate);
    }

    if (formValues.period === 'this_month') {
      const td = new Date();
      const startDate = `${td.getFullYear()}-${td.getMonth() + 1}-01`;
      const endDate = `${td.getFullYear()}-${td.getMonth() + 1}-${new Date(
        td.getFullYear(),
        td.getMonth() + 1,
        0,
      ).getDate()}`;

      setValue('period_start', startDate);
      setValue('period_end', endDate);
    }

    if (formValues.period === 'this_year') {
      const td = new Date();
      const startDate = `${td.getFullYear()}-01-01`;
      const endDate = `${td.getFullYear()}-12-31`;

      setValue('period_start', startDate);
      setValue('period_end', endDate);
    }

    if (formValues.period === 'last_30_days') {
      const td = new Date();
      const startDate = new Date(new Date().setDate(new Date().getDate() - 30)).toDateString();
      const endDate = td.toDateString();

      setValue('period_start', startDate);
      setValue('period_end', endDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.period]);

  const { error: dueDateError } = useInput('', {
    required: 'Please input due date!',
  });

  return (
    <div className={'report-filter-option-container select-period-container'}>
      <div className={'report-filter-option-title'}>Select Period</div>
      <div className={'report-filter-option-body'}>
        <Grid container spacing={2}>
          <Grid item lg={5}>
            <TextInput
              options={[
                {
                  title: 'Custom',
                  value: 'custom',
                },
                {
                  title: 'All Time',
                  value: 'all_time',
                },
                {
                  title: 'Year to Date',
                  value: 'year_to_date',
                },
                {
                  title: 'Today',
                  value: 'today',
                },
                {
                  title: 'This Month',
                  value: 'this_month',
                },
                {
                  title: 'This Year',
                  value: 'this_year',
                },
                {
                  title: 'Last 30 Days',
                  value: 'last_30_days',
                },
              ]}
              style={{
                borderColor: '#9e9e9e',
              }}
              select={true}
              value={formValues.period}
              variant={'standard'}
              label={' '}
              fullWidth={true}
              onChange={(e) => setValue('period', e.target.value)}
            />
          </Grid>

          <Grid item lg={3}>
            <DatePicker
              centerMode={true}
              value={formValues.period_start}
              disabled={isDisableDateInput}
              onChange={(value: string) => setValue('period_start', value)}
              error={dueDateError.error}
              errorText={dueDateError.message}
              rootSx={{
                marginTop: '4px',
                '& .MuiOutlinedInput-root': {
                  borderBottom: `1px solid ${themeParser('#9e9e9e', '#434343', theme)}`,
                  borderRadius: 0,
                  color: themeParser('#313131', '#2196f3', theme),
                },
              }}
            />
          </Grid>
          <Grid item lg={1}>
            <p className='report-filter-option-period-to'>to</p>
          </Grid>
          <Grid item lg={3}>
            <DatePicker
              centerMode={true}
              value={formValues.period_end}
              disabled={isDisableDateInput}
              onChange={(value: string) => setValue('period_end', value)}
              error={dueDateError.error}
              errorText={dueDateError.message}
              rootSx={{
                marginTop: '4px',
                '& .MuiOutlinedInput-root': {
                  borderBottom: `1px solid ${themeParser('#9e9e9e', '#434343', theme)}`,
                  borderRadius: 0,
                  color: themeParser('#313131', '#2196f3', theme),
                },
              }}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export { SelectPeriod };
