import React from 'react';
import { SettingsLayout } from '@/components/layouts';
import { JobCounter, JobList } from '@/components/molecules/Jobs';
// import { ArchivedJob } from '@/components/molecules/Jobs/ArchivedJob';
import { PrivateLayout } from '@/components/Middleware';
import { useLeadStyles } from '@/static/stylesheets/organisms';

const Jobs = () => {
  const classes = useLeadStyles();

  return (
    <>
      <PrivateLayout>
        <div className={classes.leadListBody}>
          <SettingsLayout
            hideSettingsTabPanel={false}
            hideHeaderRightSide={true}
            containerSize={'xxl'}
            featureType={'jobs'}
          >
            {/*{removedJob ?
              <ArchivedJob isArchivedJob={removedJob} /> :
              <JobList />
            }*/}

            <JobList />
          </SettingsLayout>
          <JobCounter />
        </div>
      </PrivateLayout>
    </>
  );
};

export default Jobs;
