type PossibleFileExtensions =
  | 'png'
  | 'svg'
  | 'jpg'
  | 'jpeg'
  | 'pdf'
  | 'gif'
  | 'doc'
  | 'docx'
  | 'xls'
  | 'xlsm'
  | 'ppt'
  | 'pptx'
  | 'mp3'
  | 'mp4'
  | 'bmp'
  | 'wmv';

export function getFileExtension(fileName: string): string | undefined {
  return fileName?.split('.').pop();
}

export function detectImageTypeFromExtension(extension: PossibleFileExtensions | string): boolean {
  let isImage = false;

  switch (extension) {
    case 'jpg':
      isImage = true;
      break;
    case 'png':
      isImage = true;
      break;
    case 'jpeg':
      isImage = true;
      break;
    case 'gif':
      isImage = true;
      break;
    case 'bmp':
      isImage = true;
      break;
    case 'svg':
      isImage = true;
      break;
  }

  return isImage;
}
