import { EquipmentFilterParams, EquipmentOrdersParams } from '@/types';
import { store } from '@/redux/store';
import {
  CORRELATION_IDS,
  createEquipmentOrdersHttpCall,
  getEquipmentOrdersCategoryHttpCall,
  getEquipmentOrdersOpenHttpCall,
  getEquipmentOrdersCompletedHttpCall,
  getEquipmentOrdersCancelledHttpCall,
  updateEquipmentOrdersHttpCall,
} from '@/redux/reducers/http';
import { clearHttpStateObject } from '@/redux/actions';

/** -------------- Equipment Orders ---------------------- */
export function getEquipmentOrdersCategory() {
  store.dispatch(getEquipmentOrdersCategoryHttpCall());
}

export function saveEquipmentOrders(data: EquipmentOrdersParams) {
  store.dispatch(createEquipmentOrdersHttpCall(data));
}

export function getEquipmentOrdersOpen(params: EquipmentFilterParams) {
  store.dispatch(getEquipmentOrdersOpenHttpCall(params));
}
export function getEquipmentOrdersCompleted(params: EquipmentFilterParams) {
  store.dispatch(getEquipmentOrdersCompletedHttpCall(params));
}

export function getEquipmentOrdersCancelled(params: EquipmentFilterParams) {
  store.dispatch(getEquipmentOrdersCancelledHttpCall(params));
}

export function updateEquipmentOrder(data: EquipmentOrdersParams) {
  store.dispatch(updateEquipmentOrdersHttpCall(data?.id, data));
}

export function clearSaveEquipmentOrders() {
  clearHttpStateObject(CORRELATION_IDS.CREATE_EQUIPMENT_ORDERS);
}
export function clearUpdateEquipmentOrders() {
  clearHttpStateObject(CORRELATION_IDS.UPDATE_EQUIPMENT_ORDERS);
}
