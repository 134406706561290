export enum CORRELATION_IDS {
  LOGIN = 'login',
  LOGOUT = 'logout',

  GET_USER_PROFILE = 'getUserProfile',
  UPDATE_USER_PROFILE = 'updateUserProfile',
  SAVE_PROFILE_EMAIL_SIGNATURE = 'saveProfileEmailSignature',

  GET_USERS = 'getUsers',
  CREATE_USER = 'createUser',
  UPDATE_USER = 'updateUser',
  USER_RESET_PASSWORD = 'resetPassword',
  DELETE_USER = 'deleteUser',

  UPLOAD_FILE = 'uploadFile',
  GET_SINGLE_ACTIVITY_EMAIL = 'getSingleActivityEmail',

  GET_LEAD_CLIENT_JOB_SETTINGS = 'getLeadClientJobSettings',
  SAVE_LEAD_CLIENT_JOB_SETTINGS = 'saveLeadClientJobSettings',

  GET_SOURCE_LIST = 'getSourceList',
  SAVE_SOURCE_ITEM = 'saveSourceItem',
  DELETE_SOURCE_ITEM = 'deleteSourceItem',

  GET_API_KEY_LIST = 'getApiKeyList',
  SAVE_API_KEY_ITEM = 'saveApiKeyItem',
  DELETE_API_KEY_ITEM = 'deleteApiKeyItem',

  GET_PROGRESS_LIST = 'getJobProgressList',
  SAVE_JOB_PROGRESS_ITEM = 'saveJobProgressItem',
  DELETE_JOB_PROGRESS_ITEM = 'deleteJobProgressItem',
  UPDATE_PROGRESS_LIST = 'updateProgressList',

  GET_PUBLIC_IP_LIST = 'getPublicIPList',
  SAVE_PUBLIC_IP = 'savePublicIp',
  DELETE_PUBLIC_IP = 'deletePublicIp',

  GET_PRIVATE_IP_LIST = 'getPrivateIPList',
  SAVE_PRIVATE_IP = 'savePrivateIp',
  DELETE_PRIVATE_IP = 'deletePrivateIp',

  GET_EQUIPMENTS = 'getEquipments',
  CREATE_EQUIPMENT = 'createEquipment',
  UPDATE_EQUIPMENT = 'updateEquipment',
  DELETE_EQUIPMENT = 'deleteEquipment',

  GET_PROGRESS_BAR_TYPES = 'getProgressBarTypes',

  GET_SMTP_SETTINGS = 'getSmtpSettings',
  UPDATE_SMTP_SETTINGS = 'updateSmtpSettings',
  SMTP_SEND_TEST_MAIL = 'smtpSendTestMail',

  GET_CUSTOM_VARIABLES = 'getCustomVariables',
  GET_ALL_CUSTOM_VARIABLES = 'getAllCustomVariables',
  GET_CUSTOM_VARIABLE = 'getCustomVariable',
  GET_CUSTOM_VARIABLE_CUSTOMER_COLUMNS = 'getCustomVariableCustomerColumns',
  CREATE_CUSTOM_VARIABLE = 'createCustomVariable',
  UPDATE_CUSTOM_VARIABLE = 'updateCustomVariable',
  DELETE_CUSTOM_VARIABLE = 'deleteCustomVariable',

  GET_ALL_API_INFORMATION = 'getAllApiInformation',
  CONNECT_GOOGLE_CALENDAR_API = 'connectGoogleCalendarApi',
  CONNECT_DOCUSIGN_API = 'connectDocusignApi',
  CONNECT_DRIP_API = 'connectDripApi',
  CONNECT_UCRM_API = 'connectUcrmApi',
  DISCONNECT_GOOGLE_CALENDAR_API = 'disconnectGoogleCalendarApi',
  DISCONNECT_DOCUSIGN_API = 'disconnectDocusignApi',
  DISCONNECT_DRIP_API = 'disconnectDripApi',
  DISCONNECT_UCRM_API = 'disconnectUcrmApi',

  GET_EMAIL_TEMPLATE_LIST = 'getEmailTemplateList',
  GET_ALL_EMAIL_TEMPLATE_LIST = 'getAllEmailTemplateList',
  GET_EMAIL_TEMPLATE = 'getEmailTemplate',
  CREATE_EMAIL_TEMPLATE = 'createEmailTemplate',
  UPDATE_EMAIL_TEMPLATE = 'updateEmailTemplate',
  DELETE_EMAIL_TEMPLATE = 'deleteEmailTemplate',
  EMAIL_TEMPLATE_SEND_TEST_MAIL = 'emailTemplateSendTestMail',

  GET_FILTER_STAFF_MEMBER_LIST = 'getFilterStaffMemberList',

  GET_LEAD_LIST = 'getLeads',
  CREATE_LEAD = 'createLead',
  UPDATE_LEAD = 'updateLead',
  GET_LEAD_STAFF_MEMBERS = 'getLeadStaffMembersDropdownList',
  UPLOAD_LEAD_FILE = 'uploadLeadFile',
  GET_LEAD_FILE_DETAILS = 'getLeadFileDetails',
  DELETE_LEAD_FILE = 'deleteLeadFile',
  GET_LEAD_CONTRACT_PDF = 'getLeadContractPDF',
  UPDATE_LEAD_VM_COUNT = 'updateLeadVMCount',
  SEND_LEAD_CONTRACT = 'sendLeadContract',
  RESTORE_LOST_LEAD = 'restoreLostLead',
  CREATE_LEAD_EQUIPMENTS = 'createLeadEquipments',
  GET_LEAD_EQUIPMENTS = 'getLeadEquipments',
  DELETE_LEAD_EQUIPMENT = 'deleteLeadEquipment',
  UPDATE_CUSTOMER_LEAD = 'updateCustomerLead',
  UPDATE_LEAD_CUSTOMER_DETAILS = 'updateLeadCustomerDetails',
  GET_LEAD_QUICK_NOTES = 'getLeadQuickNotes',
  CREATE_LEAD_QUICK_NOTES = 'createLeadQuickNotes',
  UPDATE_LEAD_QUICK_NOTES = 'updateLeadQuickNotes',
  DELETE_LEAD_QUICK_NOTES = 'deleteLeadQuickNotes',
  GET_LEAD_ACTIVITY_LOGS = 'getLeadActivityLogs',
  MOVE_LEAD = 'moveLead',
  SEND_LEAD_DRIP = 'sendLeadDrip',
  SEND_LEAD_FILTER = 'sendLeadFilter',

  GET_CLIENT_LIST = 'getClients',
  CREATE_CLIENT = 'createClient',
  UPDATE_CLIENT = 'updateClient',
  GET_CLIENT_STAFF_MEMBERS = 'getClientStaffMembersDropdownList',
  UPLOAD_CLIENT_FILE = 'uploadClientFile',
  GET_CLIENT_FILE_DETAILS = 'getClientFileDetails',
  DELETE_CLIENT_FILE = 'deleteClientFile',
  GET_CLIENT_CONTRACT_PDF = 'getClientContractPDF',
  UPDATE_CLIENT_VM_COUNT = 'updateClientVMCount',
  SEND_CLIENT_CONTRACT = 'sendClientContract',
  RESTORE_DEACTIVATED_CLIENT = 'restoreDeactivatedClient',
  CREATE_CLIENT_EQUIPMENTS = 'createClientEquipments',
  GET_CLIENT_EQUIPMENTS = 'getClientEquipments',
  DELETE_CLIENT_EQUIPMENT = 'deleteClientEquipment',
  UPDATE_CUSTOMER_CLIENT = 'updateCustomerClient',
  UPDATE_CLIENT_CUSTOMER_DETAILS = 'updateClientCustomerDetails',
  GET_CLIENT_QUICK_NOTES = 'getClientQuickNotes',
  CREATE_CLIENT_QUICK_NOTES = 'createClientQuickNotes',
  UPDATE_CLIENT_QUICK_NOTES = 'updateClientQuickNotes',
  DELETE_CLIENT_QUICK_NOTES = 'deleteClientQuickNotes',
  GET_CLIENT_ACTIVITY_LOGS = 'getClientActivityLogs',
  MOVE_CLIENT = 'moveClient',
  SEND_CLIENT_FILTER = 'sendClientFilter',

  GET_TASKS = 'getTasks',
  GET_SINGLE_TASK = 'getSingleTask',
  GET_FILTERED_TASKS = 'getFilteredTasks',
  GET_TASK_STAFF_MEMBERS = 'getTaskStaffMembersDropdownList',
  GET_TASK_CUSTOMERS = 'getTaskCustomersDropdownList',
  GET_TASK_TYPES = 'getTaskTypeList',
  CREATE_TASK = 'createTask',
  UPDATE_TASK = 'updateTask',
  DELETE_TASK = 'deleteTask',
  DELETE_SUB_TASK_FROM_POPUP = 'deleteSubTaskFromPopup',
  CHANGE_TASK_ASSIGNEE = 'changeTaskAssignee',
  MARK_TASK_AS_COMPLETED = 'markTaskAsCompleted',
  CHANGE_TASK_PRIORITY = 'changeTaskPriority',
  START_STOP_TASK_TIMER = 'startStopTaskTimer',
  UPLOAD_TASK_FILE = 'uploadTaskFile',

  CREATE_EQUIPMENT_ORDERS = 'createEquipmentOrders',
  GET_EQUIPMENT_ORDERS_CATEGORY = 'getEquipmentOrdersCategory',
  GET_EQUIPMENT_ORDERS_OPEN = 'getEquipmentOrdersOpen',
  GET_EQUIPMENT_ORDERS_COMPLETED = 'getEquipmentOrdersCompleted',
  GET_EQUIPMENT_ORDERS_CANCELLED = 'getEquipmentOrdersCancelled',
  UPDATE_EQUIPMENT_ORDERS = 'updateEquipmentOrders',

  GET_IP_LIST = 'getIpList',
  GET_IP_CHILDREN = 'getIpChildren',
  UPDATE_IP_CHILDREN = 'updateIpChildren',
  SEARCH_IP_CHILDREN = 'searchIpChildren',

  GET_JOB_LEAD_CLIENTS = 'getJobLeadClients',
  GET_JOB_LIST = 'getJobs',
  CREATE_JOB = 'createJob',
  UPDATE_JOB_DETAILS = 'updateJobDetails',
  UPDATE_JOB = 'updateJob',
  GET_JOB_STAFF_MEMBERS = 'getJobStaffMembersDropdownList',
  UPLOAD_JOB_FILE = 'uploadJobFile',
  GET_JOB_FILE_DETAILS = 'getJobFileDetails',
  DELETE_JOB_FILE = 'deleteJobFile',
  GET_JOB_CONTRACT_PDF = 'getJobContractPDF',
  UPDATE_JOB_VM_COUNT = 'updateJobVMCount',
  SEND_JOB_CONTRACT = 'sendJobContract',
  RESTORE_JOB = 'restoreJob',
  CREATE_JOB_EQUIPMENTS = 'createJobEquipments',
  GET_JOB_EQUIPMENTS = 'getJobEquipments',
  DELETE_JOB_EQUIPMENT = 'deleteJobEquipment',
  UPDATE_JOB_STATUS = 'updateJobStatus',
  UPDATE_JOB_CUSTOMER_DETAILS = 'updateJobCustomerDetails',
  GET_JOB_QUICK_NOTES = 'getJobQuickNotes',
  CREATE_JOB_QUICK_NOTES = 'createJobQuickNotes',
  UPDATE_JOB_QUICK_NOTES = 'updateJobQuickNotes',
  DELETE_JOB_QUICK_NOTES = 'deleteJobQuickNotes',
  GET_JOB_ACTIVITY_LOGS = 'getJobActivityLogs',
  MOVE_JOB = 'moveJob',
  SEND_JOB_DRIP = 'sendJobDrip',
  SEND_JOB_FILTER = 'sendJobFilter',
  GET_JOB_TYPES = 'getJobTypes',
  UPDATE_JOB_PROGRESS_LIST = 'updateJobProgressList',

  GET_REPORT_STAFF_MEMBERS = 'getReportStaffMembersDropdownList',
  GET_TOTAL_PL_YEAR_SUMMARY_REPORT = 'postTotalPlYearSummaryReport',
  GET_PROFIT_AND_LOSS_REPORT = 'getProfitAndLossReport',
  GET_LOST_VS_WON_REPORT = 'getLostVsWonReport',
  GET_PROGRESS_REPORT = 'getProgressReport',
  GET_EXPENSES_BREAKDOWN_REPORT = 'getExpensesBreakdownReport',

  GET_MEDIA_FILES = 'getMediafiles',

  DELETE_LOST_LEAD_AND_CLIENT = 'deleteLostLeadAndClientAction',

  REMOVE_LEAD_CONTRACT = 'removeLeadContactAction',

  CREATE_LOCATION = 'createLocationAction',
  UPDATE_LOCATION = 'updateLocationAction',
  GET_LOCATIONS = 'getLocationsAction',
  SAVE_LOCATION = 'saveLocationAction',
  DELETE_LOCATION = 'deleteLocationAction',
}
