import React from 'react';
import { SettingsLayout } from '@/components/layouts';
import { LeadClientJobSettings } from '@/components/molecules/Settings/LeadClientJobSettings';
import { SettingsCard } from '@/components/organisms/SettingsCard';
import { PrivateLayout } from '@/components/Middleware';
import { SourceList } from '@/components/molecules/SourceList';
import { JobProgressBarList } from '@/components/molecules/JobProgressBarList';

const GeneralSettings = () => {
  return (
    <>
      <PrivateLayout>
        <SettingsLayout hideSettingsTabPanel={true} hideHeaderRightSide={false} containerSize={'md'}>
          <SettingsCard title={'General Settings'}>
            <LeadClientJobSettings />
            <SourceList />
            <JobProgressBarList />
          </SettingsCard>
        </SettingsLayout>
      </PrivateLayout>
    </>
  );
};

export { GeneralSettings };
