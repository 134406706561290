import React, { useEffect, useState } from 'react';
import { DataTable } from '@/components/organisms/DataTable';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import Container from '@mui/material/Container';
import { SettingsCard } from '@/components/organisms/SettingsCard';
import { useAppSelector } from '@/hooks';
import { getEquipmentOrdersCancelled } from '@/redux/actions';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { convertToNumber } from '@/utils';
import { Loader } from '@/components/atoms/Loader';
import { useEquipmentsStyles } from '@/static/stylesheets/molecules/EquipmentOrders';

const CancelOrdersList = () => {
  const classes = useEquipmentsStyles();
  const equipmentOrdersCencelled = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_EQUIPMENT_ORDERS_CANCELLED];
  const equipmentOrderList: any = equipmentOrdersCencelled?.data?.data ?? [];
  const totalPages = convertToNumber(equipmentOrdersCencelled?.data?.last_page);

  const [limit, setLimit] = useState(6);
  const [currentPage, setCurrentPage] = useState(1);

  const getEquipmentOrdersList = () =>
    getEquipmentOrdersCancelled({
      limit,
      page: currentPage,
    });

  useEffect(() => {
    getEquipmentOrdersList();
  }, [currentPage]);
  return (
    <>
      <Container style={{ marginTop: '-40px' }} className={'mx-width'}>
        <SettingsCard title={'Cancelled Orders'}>
          <div style={{ textAlign: 'center', padding: 10 }}>
            {equipmentOrdersCencelled?.loading ? (
              <div
                style={{
                  height: 100,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Loader />
              </div>
            ) : (
              <div style={{ width: '100%', display: 'inline-block' }}>
                <DataTable
                  heads={[
                    { label: 'Description', align: 'left' },
                    { label: 'Quantity', align: 'center' },
                    { label: 'Total Est. Cost', align: 'center' },
                    { label: 'Client', align: 'center' },
                    { label: 'Category', align: 'center' },
                    { label: 'Link', align: 'center' },
                    { label: 'Ordered On', align: 'center' },
                    { label: 'Requested By', align: 'center' },
                    { label: 'Cancelled On', align: 'center' },
                  ]}
                  rows={[
                    ...equipmentOrderList?.map((equipment: any) => [
                      { label: equipment?.equipment?.equipment_name ?? '', align: 'left' },
                      { label: equipment?.quantity ?? '', align: 'center' },
                      { label: equipment?.estimated_cost ?? '', align: 'center' },
                      {
                        label:
                          equipment?.customer?.customer_type === 'Residential'
                            ? `${equipment?.customer?.first_name ?? ''} ${equipment?.customer?.last_name ?? ''}`
                            : equipment?.customer?.first_name ?? '',
                        align: 'center',
                      },
                      { label: equipment?.category?.category_name ?? '', align: 'center' },

                      {
                        label: (
                          <a target='_blank' href={equipment?.equipment_url} rel='noreferrer'>
                            <InsertLinkIcon style={{ color: '#2196f3' }} />
                          </a>
                        ),
                        align: 'center',
                      },
                      { label: equipment?.ordered_on, align: 'center' },
                      { label: equipment?.requested_by?.name, align: 'center' },
                      { label: equipment?.cancelled_on, align: 'center' },
                    ]),
                  ]}
                  tableRowCellClassName={classes.tableRowCell}
                  hasPagination={true}
                  paginationProps={{
                    count: totalPages,
                    currentPage,
                    onChange(value) {
                      setCurrentPage(value);
                    },
                  }}
                />
              </div>
            )}
          </div>
        </SettingsCard>
      </Container>
    </>
  );
};

export { CancelOrdersList };
