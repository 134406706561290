export const colors = {
  primary: '#2196f3',
  secondary: '#2196f3',

  body: {
    light: '#ffffff',
    dark: '#2a2a2a',
  },
  regularText: {
    light: '#2a2a2a',
    dark: '#ffffff',
  },
  white: {
    default: '#ffffff',
    light: '#eeeeee',
    deep: '#d9d9d9',
  },
  black: {
    default: '#000000',
  },
  blue: {
    default: '#2196F3',
  },
  green: {
    default: '#4CAF50',
  },
  yellow: {
    default: '#fbc02d',
  },
  orenge: {
    default: '#ff9800',
  },
  red: {
    default: '#F44336',
  },
  purple: {
    default: '#9c27b0',
  },
  hoverBG: {
    dark: '#505050',
    light: '#2196f3',
  },
  tabItem: {
    light: '#3e3e3e',
    dark: '#3e3e3e',
  },
  tabItems: {
    light: '#c0c0c045',
    dark: '#3e3e3e',
  },
  borderLeftColor: {
    light: '#2a2a2a',
    dark: '#2a2a2a',
  },
  tabText: {
    light: '#9e9e9e',
    dark: '#9e9e9e',
  },
  tabTextActive: {
    light: '#fff',
    dark: '#2196f3',
  },
  tableheader: {
    dark: '#959595',
  },
  tableBorder: {
    dark: '#3E3E3E',
    light: '#0000001f',
  },
  textInput: {
    labelColor: {
      dark: '#a0a0a0',
      light: '#000000',
    },
    borderColor: {
      dark: '#3e3e3e',
      light: '#000000',
    },
    placeholderColor: {
      dark: '#c8c8c8',
      light: '#000000',
    },
    error: '#c74a4a',
  },
  layout: {
    borderColor: {
      dark: '#3e3e3e',
      light: '#e0e0e0',
    },
  },
  button: {
    default: {
      bg: '#3c3c3c',
    },
  },

  pagination: {
    bg: {
      light: '#ffffff',
      dark: '#1a1a1a',
    },
    text: {
      light: '#000000',
      dark: '#ffffff',
    },
  },
  progress: {
    light: '#ebebeb',
    dark: '#3e3e3e',
  },
};
