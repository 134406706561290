import React, { useState } from 'react';
import { IpAddressList } from '@/components/molecules/IP/IpAddressList';
import { SettingsLayout } from '@/components/layouts';
import { PrivateLayout } from '@/components/Middleware';

const IP = () => {
  const [reloadActive, setReloadActive] = useState<boolean>(false);
  return (
    <>
      <PrivateLayout>
        <SettingsLayout
          hideSettingsTabPanel={false}
          hideHeaderRightSide={true}
          containerSize={'xxl'}
          featureType={'ip'}
          turnOnReloadActive={() => setReloadActive(true)}
          containerChildStyle={{
            padding: '5px',
          }}
        >
          <IpAddressList reloadActive={reloadActive} turnOfReloadActive={() => setReloadActive(false)} />
        </SettingsLayout>
      </PrivateLayout>
    </>
  );
};

export default IP;
