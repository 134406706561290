import { ContentWrapper } from '@/components/organisms/Reports';
import { ReportTable, Tbody, Td, Th, Tr } from '@/components/organisms/Reports/ReportContent/ReportTable';
import { useAppSelector } from '@/hooks';
import { getGeneralTotalPlYearSummaryReports } from '@/redux/actions';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { formatCurrency } from '@/utils';
import { useEffect } from 'react';

const MonthBreakDown = () => {
  const result = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_TOTAL_PL_YEAR_SUMMARY_REPORT];

  useEffect(() => {
    getGeneralTotalPlYearSummaryReports({
      report_type: 'monthly',
    });
  }, []);

  const dataTable = [
    ['Jan', 'Feb', 'Mar', 'Apr'],
    ['May', 'Jun', 'Jul', 'Aug'],
    ['Sep', 'Oct', 'Nov', 'Dec'],
  ];

  return (
    <>
      <ContentWrapper headings={['Total Profit & Loss Report | All New Business | Month Breakdown']}>
        {result?.data &&
          dataTable.map((monthArray: string[], index: number) => {
            return (
              <ReportTable key={'key' + index}>
                <Tbody>
                  {/* Table header */}
                  <Tr>
                    <Th width={'300px'}></Th>
                    {monthArray.map((month: string) => (
                      <Th key={month} colSpan={2}>
                        {month}
                      </Th>
                    ))}
                  </Tr>
                  <Tr>
                    <Td></Td>
                    <Td></Td>
                    <Td align={'center'} width={'179px'}>
                      Over 12 Months
                    </Td>

                    <Td></Td>
                    <Td align={'center'} width={'179px'}>
                      Over 12 Months
                    </Td>

                    <Td width={'46px'}></Td>
                    <Td align={'center'} width={'179px'}>
                      Over 12 Months
                    </Td>

                    <Td width={'46px'}></Td>
                    <Td align={'center'} width={'179px'}>
                      Over 12 Months
                    </Td>
                  </Tr>

                  {/* Table body */}
                  <Tr>
                    <Td>Installs</Td>
                    {monthArray.map((month: string) => (
                      <>
                        <Td width={'100px'} key={month + 'install1'} align={'center'}>
                          {result.data.month_wise_report[month].install_count}
                        </Td>
                        <Td key={month + 'install2'} align={'center'}>
                          -
                        </Td>
                      </>
                    ))}
                  </Tr>

                  <Tr>
                    <Td>Tech Jobs</Td>
                    {monthArray.map((month: string) => (
                      <>
                        <Td width={'100px'} key={month + 'tech1'} align={'center'}>
                          {result.data.month_wise_report[month].job_count}
                        </Td>
                        <Td key={month + 'tech2'} align={'center'}>
                          -
                        </Td>
                      </>
                    ))}
                  </Tr>

                  <Tr>
                    <Td>Installation Revenue</Td>
                    {monthArray.map((month: string) => (
                      <>
                        <Td width={'100px'} key={month + 'Revenue1'} align={'center'}>
                          {formatCurrency(result.data.month_wise_report[month].install_revenue)}
                        </Td>
                        <Td key={month + 'Revenue2'} align={'center'}>
                          -
                        </Td>
                      </>
                    ))}
                  </Tr>

                  <Tr>
                    <Td>Monthly Recurring</Td>
                    {monthArray.map((month: string) => (
                      <>
                        <Td width={'100px'} key={month + 'Recurring1'} align={'center'}>
                          {formatCurrency(result.data.month_wise_report[month].one_month_recurring)}
                        </Td>
                        <Td key={month + 'Recurring2'} align={'center'}>
                          {formatCurrency(result.data.month_wise_report[month].next_year_recurring)}
                        </Td>
                      </>
                    ))}
                  </Tr>

                  <Tr>
                    <Td>Tech Work Revenue</Td>
                    {monthArray.map((month: string) => (
                      <>
                        <Td width={'100px'} key={month + 'Revenue1'} align={'center'}>
                          {formatCurrency(result.data.month_wise_report[month].tech_revenue)}
                        </Td>
                        <Td key={month + 'Revenue2'} align={'center'}>
                          -
                        </Td>
                      </>
                    ))}
                  </Tr>

                  <Tr>
                    <Td>Total Revenue</Td>
                    {monthArray.map((month: string) => (
                      <>
                        <Td width={'100px'} key={month + 'Revenue1'} align={'center'}>
                          {formatCurrency(result.data.month_wise_report[month].monthly_total?.total_revenue.monthly)}
                        </Td>
                        <Td key={month + 'Revenue2'} align={'center'}>
                          {formatCurrency(result.data.month_wise_report[month].monthly_total?.total_revenue.yearly)}
                        </Td>
                      </>
                    ))}
                  </Tr>

                  <Tr>
                    <Td>Total Expenses for All Work</Td>
                    {monthArray.map((month: string) => (
                      <>
                        <Td width={'100px'} key={month + 'Work1'} align={'center'}>
                          {formatCurrency(
                            result.data.month_wise_report[month].monthly_total?.total_expense_for_all_work,
                          )}
                        </Td>
                        <Td key={month + 'Work2'} align={'center'}>
                          -
                        </Td>
                      </>
                    ))}
                  </Tr>

                  <Tr>
                    <Td>Total Net Profit</Td>
                    {monthArray.map((month: string) => (
                      <>
                        <Td width={'100px'} key={month + 'Profit1'} align={'center'}>
                          {formatCurrency(result.data.month_wise_report[month].monthly_total?.total_net_profit.monthly)}
                        </Td>
                        <Td key={month + 'Profit2'} align={'center'}>
                          {formatCurrency(result.data.month_wise_report[month].monthly_total?.total_net_profit.yearly)}
                        </Td>
                      </>
                    ))}
                  </Tr>
                </Tbody>
              </ReportTable>
            );
          })}
      </ContentWrapper>
    </>
  );
};

export { MonthBreakDown };
