import React, { useState, useEffect } from 'react';
import { useLeadStyles } from '@/static/stylesheets/organisms/Leads/leadStyles';
import classnames from 'classnames';
import { TextInput } from '@/components/atoms/TextInput';
import EditIcon from '@mui/icons-material/Edit';
import { CreateClient } from '@/components/molecules/Clients';
import { CustomerProfileParams, UserParams } from '@/types';
import { useAppSelector, useInput } from '@/hooks';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { Loader } from '@/components/atoms/Loader';
import { ToggleSaveButtonEnabled } from '@/components/molecules/Leads/SingleLead';
import { updateLead } from '@/redux/actions';
import { selectUiState } from '@/redux/reducers/uiSlice';
import { formatPhoneNumber } from '@/utils';
import { CreateLead } from '@/components/molecules/Leads';

interface LeftHeaderProps {
  customerData?: CustomerProfileParams;
  toggleSaveEnabled: ToggleSaveButtonEnabled;
  LeadsData?: any;
  isDeactivatedClient?: boolean;
  reloadList?(): void;
}

const LeftHeader: React.FC<LeftHeaderProps> = (props) => {
  const classes = useLeadStyles();
  const { reloadList, LeadsData, customerData, isDeactivatedClient } = props;
  const staffMemberList = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_CLIENT_STAFF_MEMBERS];
  const staffMembers: UserParams[] = staffMemberList?.data?.data ?? [];
  const [openDialog, setOpenDialog] = useState(false);
  const { showDeactivatedClients } = useAppSelector(selectUiState);

  const handleDialog = () => {
    if (!showDeactivatedClients) {
      setOpenDialog(true);
    }
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const { setValue: setAssignedTo, bind: bindAssignedTo } = useInput('--empty--', {
    onChange(value: any) {
      props?.toggleSaveEnabled(true);
      const data: CustomerProfileParams = {
        assigned_to: value,
      };
      data.id = customerData?.id;
      updateLead(data);
    },
  });

  const prepareAddress = () => {
    let address = '';

    if (customerData?.street && customerData?.street !== '') {
      address += customerData?.street;
    }

    if (customerData?.apartment && customerData?.apartment !== '') {
      if (address !== '') {
        address += ', ';
      }
      address += `${customerData?.apartment}`;
    }

    if (customerData?.city && customerData?.city !== '') {
      if (address !== '') {
        address += ', ';
      }
      address += `${customerData?.city}`;
    }

    return address;
  };

  useEffect(() => {
    setAssignedTo(customerData?.assigned_to);
  }, [customerData?.assigned_to]);

  return (
    <>
      <div>
        <span
          className={classnames({
            [classes.indicatorCircleFilled]: true,
            [LeadsData?.status === 0 ? classes.green : LeadsData?.status === 1 ? classes.yellow : classes.red]: true,
          })}
        />
        <span onClick={handleDialog} className={classes.Names}>
          {`${customerData?.first_name ?? ''} ${customerData?.last_name ?? ''}`}
        </span>
        {customerData?.customer_type && (
          <span className={classes.Phones} style={{ color: '#2196f3' }}>
            {customerData?.customer_type?.toLocaleLowerCase() === 'business' ? 'C' : 'R'}
          </span>
        )}

        <span className={classes.Phones}>
          <a href={`tel:${customerData?.phone}`} target='_blank' rel='noreferrer'>
            {customerData?.phone ? formatPhoneNumber(customerData?.phone?.toString()) : ''}
          </a>
        </span>
        <span className={classes.gmail}>
          <a href={`mailto: ${customerData?.email}`} target='_blank' rel='noreferrer'>
            {customerData?.email}
          </a>
        </span>
        <span className={classes.address}>
          <a href={` https://maps.google.com/?q=${customerData?.address}`} target='_blank'>
            {prepareAddress()}
          </a>
        </span>

        <span className={classes.selector}>
          {staffMemberList?.loading ? (
            <Loader
              containerStyle={{
                display: 'inline-block',
              }}
              progressStyle={{
                height: 20,
                width: 20,
              }}
            />
          ) : (
            <TextInput
              select={true}
              variant={'standard'}
              disabled={showDeactivatedClients || isDeactivatedClient}
              options={[
                {
                  title: 'Assigned To',
                  value: '--empty--',
                  disabled: true,
                },
                ...(staffMembers && staffMembers?.length > 0
                  ? staffMembers?.map((item) => ({
                      title: `${item?.first_name ?? ''} ${item?.last_name ?? ''}`,
                      value: item?.id,
                    }))
                  : []),
              ]}
              {...bindAssignedTo}
              fullWidth={false}
            />
          )}
        </span>
      </div>

      {/* <CreateClient
        open={openDialog}
        onClose={handleDialogClose}
        mode={"edit"}
        editData={customerData}
      /> */}

      {openDialog && (
        <CreateLead
          open={openDialog}
          onClose={handleDialogClose}
          mode={'edit'}
          editData={customerData}
          reloadList={reloadList}
          title={'Client'}
        />
      )}
    </>
  );
};

export { LeftHeader };
