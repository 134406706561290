import { IpListParams, EquipmentOrdersParams } from '@/types';
import { store } from '@/redux/store';
import {
  CORRELATION_IDS,
  getIpListHttpCall,
  getIpChildrenHttpCall,
  updateIpDetailsHttpCall,
  searchIpDetailsHttpCall,
} from '@/redux/reducers/http';
import { clearHttpStateObject } from '@/redux/actions';

/** -------------- IP ---------------------- */

export function getIpList(params: IpListParams) {
  store.dispatch(getIpListHttpCall(params));
}

export function getIpChildren(id: EquipmentOrdersParams['id']) {
  store.dispatch(getIpChildrenHttpCall(id));
}

export function updateIpDetails(data: IpListParams) {
  store.dispatch(updateIpDetailsHttpCall(data?.ip_id, data));
}

export function searchIpDetails(params: IpListParams) {
  store.dispatch(searchIpDetailsHttpCall(params));
}

export function clearUpdateIpDetails() {
  clearHttpStateObject(CORRELATION_IDS.UPDATE_EQUIPMENT_ORDERS);
}
