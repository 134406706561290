import moment from 'moment-timezone';

export function getTimeZone() {
  try {
    const timeZone = moment.tz.guess();
    return timeZone;
  } catch (error) {
    console.error('Error getting time zone:', error);
    return null;
  }
}

export const timezone: any = getTimeZone();
