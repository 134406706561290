import React from 'react';
import { getCurrentTheme } from '@/theme/utils';

export type AppTheme = 'light' | 'dark';

export interface ThemeState {
  appTheme: AppTheme;
  setAppTheme?(theme: AppTheme): void;
}

export const themeState: ThemeState = {
  appTheme: getCurrentTheme(),
};

export const ThemeContext = React.createContext(themeState);
