import { TextInput } from '@/components/atoms/TextInput';
import { ToggleSaveButtonEnabled } from '@/components/molecules/Leads/SingleLead';
import { EditNotes } from '@/components/organisms/Leads/Expand/modal/EditNotes';
import { alertMessages } from '@/config';
import { useAppSelector, useInput } from '@/hooks';
import {
  clearDeleteLeadQuickNoteResponse,
  clearLeadCreateQuickNotes,
  clearLeadUpdatedQuickNotes,
  createLeadQuickNotes,
  deleteLeadQuickNotes,
  openConfirmationDialog,
  updateLeadQuickNotes,
} from '@/redux/actions';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { useLeadExpandBodyStyle } from '@/static/stylesheets/organisms';
import { QuickNotesParams } from '@/types';
import { timezone } from '@/utils/timeZone';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import GpsNotFixedIcon from '@mui/icons-material/GpsNotFixed';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';

interface QuickNotesProps {
  lostLead?: boolean;
  toggleSaveEnabled: ToggleSaveButtonEnabled;
  leadsId?: any;
  leadsNotes?: any;

  onReload?(): void;
}

const QuickNotes: React.FC<QuickNotesProps> = (props) => {
  const { lostLead } = props;
  const classes = useLeadExpandBodyStyle();
  // const fetchQuickNotes = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_QUICK_NOTES];
  const CreateQuickNotesResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.CREATE_LEAD_QUICK_NOTES];
  const deleteQuickNotesResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.DELETE_LEAD_QUICK_NOTES];
  const updateQuickNotesResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.UPDATE_LEAD_QUICK_NOTES];
  const getQuickNote: QuickNotesParams[] = props?.leadsNotes ?? [];
  const [formDialogOpen, setFormDialogOpen] = useState(false);
  const listInnerRef = useRef<HTMLDivElement>(null);
  const [targetId, setTargetId] = useState(null);
  const [isNotes, setNotes] = useState(null);
  //const [quickNotes, setQuickNotes] = useState<quickNotesParams[]>([getQuickNotes]);

  const openFormDialog = (item: any) => {
    setFormDialogOpen(true);
    setNotes(item);
    setTargetId(item?.id);
  };

  const handleDialogClose = () => {
    setFormDialogOpen(false);
  };

  const handleGPS = (item: any) => {
    let isPinned = false;
    if (item.order < 1) {
      isPinned = true;
    } else {
      isPinned = false;
    }
    const data: QuickNotesParams = {
      pinned: isPinned,
      time_zone: timezone,
      id: item?.id,
    };
    updateLeadQuickNotes(data);
  };

  const resetFields = () => {
    resetCreateNote();
  };
  const {
    value: createNote,
    reset: resetCreateNote,
    error: createNoteError,
    bind: bindCreateNote,
  } = useInput('', {
    required: 'Please enter Note!',
  });
  const onFormSubmit = () => {
    createNoteError?.check();
    if (createNoteError?.check()) {
      toast.error(alertMessages.leads.quickNoteSubmitValidationError);
      return;
    }

    const data: QuickNotesParams = {
      note_body: createNote,
      time_zone: timezone,
      id: props?.leadsId,
    };
    createLeadQuickNotes(data);
    resetFields();
    setTargetId(props?.leadsId);
  };

  const handleKeyDown = (e: any) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      onFormSubmit();
    }
  };

  useEffect(() => {
    resetFields();
    if (props?.onReload) {
      props?.onReload();
    }
  }, []);

  useEffect(() => {
    if (CreateQuickNotesResponse?.success && targetId) {
      toast.success(alertMessages.leads.quickNoteCreateSuccess);
      clearLeadCreateQuickNotes();
      if (props?.onReload) {
        props?.onReload();
      }
      resetFields();
      setTargetId(null);
      if (listInnerRef.current) {
        let element = listInnerRef.current;
        const { scrollHeight } = listInnerRef.current;
        element.scrollTop = scrollHeight;
      }
    }
    if (CreateQuickNotesResponse?.error && targetId) {
      toast.error(alertMessages.leads.quickNoteCreateError);
      clearLeadCreateQuickNotes();
    }
  }, [CreateQuickNotesResponse]);

  useEffect(() => {
    if (deleteQuickNotesResponse?.success && targetId) {
      clearDeleteLeadQuickNoteResponse();
      toast.success(alertMessages.leads.quickNoteDeleteSuccess);
      if (props?.onReload) {
        props?.onReload();
      }
      setTargetId(null);
    }
    if (deleteQuickNotesResponse?.error && targetId) {
      clearDeleteLeadQuickNoteResponse();
      toast.error(alertMessages.leads.quickNoteDeleteError);
    }
  }, [deleteQuickNotesResponse, targetId]);

  useEffect(() => {
    if (updateQuickNotesResponse?.success) {
      toast.success(alertMessages.leads.quickNoteUpdateSuccess);
      if (props?.onReload) {
        props?.onReload();
      }
      setFormDialogOpen(false);
      clearLeadUpdatedQuickNotes();
      setTargetId(null);
    }
    if (updateQuickNotesResponse?.error) {
      toast.error(alertMessages.leads.quickNoteUpdateError);
      clearLeadUpdatedQuickNotes();
    }
  }, [updateQuickNotesResponse]);

  return (
    <>
      <div className={classes.activityBody}>
        <List>
          <ListItem alignItems='flex-start' className={classes.activityHeader}>
            <ListItemText primary='Quick Notes'></ListItemText>
          </ListItem>
          <Divider />
          <div className={'list_body'} ref={listInnerRef}>
            {getQuickNote &&
              getQuickNote?.length > 0 &&
              getQuickNote?.map((item: any, index: number) => (
                <div key={index}>
                  <ListItem alignItems='flex-start'>
                    <ListItemText
                      disableTypography
                      secondary={
                        <React.Fragment>
                          <div className={'text_body text_span'}>
                            <Typography sx={{ display: 'inline' }} component='span' variant='body1'>
                              {item?.metadata?.created_by?.name} <em>{item?.metadata?.last_update_date_time}</em>
                            </Typography>

                            {!lostLead && (
                              <Typography sx={{ display: 'inline' }} component='div' variant='body2'>
                                {item?.order < 1 ? (
                                  <GpsNotFixedIcon
                                    onClick={() => handleGPS(item)}
                                    className={'gps_icon'}
                                    style={{ cursor: 'pointer' }}
                                  />
                                ) : (
                                  <GpsFixedIcon
                                    onClick={() => handleGPS(item)}
                                    className={'gps_icon'}
                                    style={{ cursor: 'pointer' }}
                                  />
                                )}
                                <EditIcon
                                  onClick={() => openFormDialog(item)}
                                  className={'edit_icon'}
                                  style={{ cursor: 'pointer' }}
                                />
                                <DeleteIcon
                                  className={'delete_icon'}
                                  onClick={() =>
                                    openConfirmationDialog({
                                      title: 'Are you sure?',
                                      message: alertMessages.leads.quickNoteDeleteConfirmation,
                                      type: 'error',
                                      textCenter: 'center',
                                      onClickConfirm() {
                                        deleteLeadQuickNotes(item?.id);
                                        setTargetId(item?.id);
                                      },
                                    })
                                  }
                                  style={{ cursor: 'pointer' }}
                                />
                              </Typography>
                            )}
                          </div>
                          <div>
                            <p style={{ whiteSpace: 'break-spaces' }}>{item?.note_body}</p>
                          </div>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <Divider />
                </div>
              ))}
          </div>
          {!lostLead && (
            <div className={'input_body'}>
              <TextInput
                onKeyDown={handleKeyDown}
                variant={'standard'}
                fullWidth={true}
                multiline={true}
                label={'Create New Note'}
                {...bindCreateNote}
                error={createNoteError?.error}
                errorText={createNoteError?.message}
              />
            </div>
          )}
        </List>
      </div>
      <EditNotes openDialog={formDialogOpen} onClose={handleDialogClose} notes={isNotes} />
    </>
  );
};

export { QuickNotes };
