import React, { useEffect } from 'react';
import { useAppSelector } from '@/hooks';
import { selectAuthState } from '@/redux/reducers/authSlice';
import { useNavigate } from 'react-router';
import { routePaths } from '@/routes';
import { AuthService } from '@/services/auth';

interface PublicLayoutProps {
  children?: React.ReactNode;
}

const PublicLayout: React.FC<PublicLayoutProps> = (props) => {
  const navigate = useNavigate();
  const authData = useAppSelector(selectAuthState);
  const isLoggedIn = authData?.logged_in && new AuthService().isLoggedIn();

  useEffect(() => {
    if (isLoggedIn) {
      // Redirect to the dashboard
      navigate(routePaths.settings.generalSettings); //TODO:: We need to redirect to the lead page later
    }
  }, [isLoggedIn]);

  return <>{props.children}</>;
};

export { PublicLayout };
