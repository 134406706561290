import { css } from '@emotion/css';

export const useDefaultLayoutStyles = () => {
  return {
    root: css`
      display: flex;
      flex-direction: column;
      height: 100vh;
      width: 100vw;
      overflow: hidden;
    `,
  };
};
