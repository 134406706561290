import React from 'react';
import { SettingsLayout } from '@/components/layouts';
import { SettingsCard } from '@/components/organisms/SettingsCard';
import { PublicIPAddressList } from '@/components/molecules/PublicIPAddressList';
import { PrivateIPAddressList } from '@/components/molecules/PrivateIPAddressList';
import { PrivateLayout } from '@/components/Middleware';

const IPAddress = () => {
  return (
    <>
      <PrivateLayout>
        <SettingsLayout hideSettingsTabPanel={true} hideHeaderRightSide={false} containerSize={'md'}>
          <SettingsCard title={'IP Addresses'}>
            <PublicIPAddressList />
            <PrivateIPAddressList />
          </SettingsCard>
        </SettingsLayout>
      </PrivateLayout>
    </>
  );
};

export { IPAddress };
