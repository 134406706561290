import { endpoints } from '@/api/endpoints';
import { performHttpCall } from '../httpSlice';
import { CORRELATION_IDS } from '../correlationIds';
import { IpListParams } from '@/types';

export const getIpListHttpCall = (params: IpListParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_IP_LIST,
    payload: {
      url: endpoints.private.getIpList(params),
      method: 'get',
      useBearerToken: true,
    },
  });

export const getIpChildrenHttpCall = (id: IpListParams['id']) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_IP_CHILDREN,
    payload: {
      url: endpoints.private.getIpChildren(id ?? ''),
      method: 'get',
      useBearerToken: true,
    },
  });

export const updateIpDetailsHttpCall = (id: IpListParams['id'], data: IpListParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.UPDATE_IP_CHILDREN,
    payload: {
      url: endpoints.private.updateIpDetails(id ?? ''),
      method: 'put',
      useBearerToken: true,
      body: data,
    },
  });

export const searchIpDetailsHttpCall = (params: IpListParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.SEARCH_IP_CHILDREN,
    payload: {
      url: endpoints.private.searchIpDetails(params),
      method: 'get',
      useBearerToken: true,
    },
  });
