import React, { useEffect, useState } from 'react';
import { SingleJob } from '@/components/molecules/Jobs/SingleJob';
import { useAppSelector, useThrottle } from '@/hooks';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import {
  getJobs,
  getJobStaffMembers,
  getJobProgressItemList,
  clearSendJobFilter,
  clearUpdatedJob,
  clearCreatedJob,
  clearUpdatedJobDetails,
  setJobListType,
  getEquipments,
} from '@/redux/actions';
import { LeadEmptyPlaceholder, LeadLoader } from '@/components/organisms/Leads';
import { Pagination } from '@/components/atoms/Pagination';
import { useLeadStyles } from '@/static/stylesheets/organisms';
import { JobItem } from '@/types';
import { selectUiState } from '@/redux/reducers/uiSlice';
import { convertToNumber } from '@/utils';
import { CreateJob } from '@/components/molecules/Jobs/CreateJob';
import { useJobListType } from '@/hooks/custom';
import toast from 'react-hot-toast';

interface JobListProps {}

const JobList: React.FC<JobListProps> = () => {
  const classes = useLeadStyles();
  const jobListTypeSelection = useJobListType();

  const jobListResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_JOB_LIST];
  const jobListFilterResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.SEND_JOB_FILTER];
  const jobs: JobItem[] = jobListFilterResponse?.data?.data
    ? jobListFilterResponse?.data?.data ?? []
    : jobListResponse?.data?.data ?? [];
  const totalPages = convertToNumber(
    jobListFilterResponse?.data ? jobListFilterResponse?.data?.last_page : jobListResponse?.data?.last_page,
  );
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const createdJob = useAppSelector(selectHttpState)[CORRELATION_IDS.CREATE_JOB];
  const updatedJobDetails = useAppSelector(selectHttpState)[CORRELATION_IDS.UPDATE_JOB_DETAILS];
  const { globalSearch } = useAppSelector(selectUiState);

  const [initialBoot, setInitialBoot] = useState(true);
  const [editJobPopupOpen, setEditJobPopupOpen] = useState(false);
  const [editJobData, setEditJobData] = useState<any>(null);
  const jobSearchQuery = useThrottle(globalSearch, 1000);

  const getStaffMemberList = () =>
    getJobStaffMembers({
      limit: 'all',
      page: 1,
    });

  const getJobList = () =>
    getJobs({
      limit,
      page: currentPage,
      type: !jobSearchQuery || jobSearchQuery?.trim() === '' ? jobListTypeSelection?.currentSelected : undefined,
      s: jobSearchQuery ?? undefined,
    });

  const Loader = () => {
    return (
      <>
        {new Array(limit).fill('').map((item, index) => (
          <LeadLoader key={index} />
        ))}
      </>
    );
  };

  const fetchEquipments = () => {
    getEquipments({
      limit: 'all',
      page: 1,
    });
  };

  useEffect(() => {
    if (updatedJobDetails && updatedJobDetails?.success) {
      clearUpdatedJobDetails();
      // getJobList();
      toast.success('Job details saved successfully!');
    }
    if (updatedJobDetails && updatedJobDetails?.error) {
      clearUpdatedJob();
      toast.error('Failed to save job details!');
    }
  }, [updatedJobDetails]);

  useEffect(() => {
    if (createdJob && createdJob?.success) {
      clearCreatedJob();
      getJobList();
    }
    if (createdJob && createdJob?.error) {
      clearCreatedJob();
    }
  }, [createdJob]);

  useEffect(() => {
    getJobProgressItemList({
      progressType: 'job',
    });
    getStaffMemberList();
    fetchEquipments();
  }, []);

  useEffect(() => {
    getJobList();
    setInitialBoot(false);
  }, [currentPage]);

  useEffect(() => {
    if (!initialBoot) {
      getJobList();
    }
  }, [jobSearchQuery]);

  useEffect(() => {
    return () => {
      clearSendJobFilter();
      setJobListType('regular');
    };
  }, []);

  return (
    <>
      {jobListResponse?.loading ? (
        <Loader />
      ) : (
        <>
          {jobs &&
            jobs?.length > 0 &&
            jobs?.map((item, index) => (
              <SingleJob
                key={index}
                data={item}
                reloadList={getJobList}
                onClickEdit={(data) => {
                  setEditJobData(data);
                  setEditJobPopupOpen(true);
                }}
                isRemovedJob={item?.type === 'Removed Job'}
              />
            ))}
          {jobListResponse?.success && jobs.length === 0 && <LeadEmptyPlaceholder />}
          {totalPages > 1 && (
            <div className={classes.pagination}>
              <Pagination count={totalPages} currentPage={currentPage} onChange={(value) => setCurrentPage(value)} />
            </div>
          )}
        </>
      )}

      {/* Edit job popup */}
      <CreateJob
        open={editJobPopupOpen}
        onClose={() => {
          setEditJobPopupOpen(false);
          setEditJobData(null);
        }}
        onSuccess={getJobList}
        mode={'edit'}
        editData={editJobData}
      />
    </>
  );
};

export { JobList };
