import { css } from '@emotion/css';
import { colors, themeParser, useAppTheme } from '@/theme';

export const useSettingsCardStyle = () => {
  const theme = useAppTheme();

  return {
    root: css`
      box-shadow:
        0 2px 2px 0 rgb(0 0 0 / 14%),
        0 3px 1px -2px rgb(0 0 0 / 12%),
        0 1px 5px 0 rgb(0 0 0 / 20%);
      border-radius: 6px;
      border-bottom: 5px solid #2196f3;
      margin: 30px 0px 70px 0px;
    `,
    cardHeader: css`
      background: #2196f3;
      padding: 10px;
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 1px;
      color: #2a2a2a;
      text-align: center;
      font-size: 1.15rem;
      line-height: 110%;
    `,
  };
};
