import React from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Box } from '@mui/material';
import classNames from 'classnames';
import Accordion from '@mui/material/Accordion';
import { ReportMenuKey } from '@/types';

interface SidebarMenuAccordionProps {
  title: string;
  children?: React.ReactNode;
}

interface MenuItemProps {
  title: string;
  menuKey: ReportMenuKey;
  activeKey?: ReportMenuKey;

  onClick?(key: ReportMenuKey): void;
}

const MenuItem: React.FC<MenuItemProps> = (props) => (
  <Box
    className={classNames({
      'menu-item': true,
      active: props?.menuKey === props?.activeKey,
    })}
    onClick={() => {
      if (props?.onClick) {
        props?.onClick(props?.menuKey);
      }
    }}
  >
    {props?.title}
  </Box>
);

const SidebarMenuAccordion: React.FC<SidebarMenuAccordionProps> = (props) => {
  return (
    <>
      <Accordion elevation={0} className={'menu-accordion'} defaultExpanded={true}>
        <AccordionSummary expandIcon={<ArrowDropDownIcon />} className={'menu-accordion-header'}>
          <Typography fontWeight={500} fontSize={14}>
            {props?.title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={'menu-accordion-details'}>{props?.children}</AccordionDetails>
      </Accordion>
    </>
  );
};

export { SidebarMenuAccordion, MenuItem };
