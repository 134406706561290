import { endpoints } from '@/api/endpoints';
import {
  ActivityLogsParams,
  CustomerProfileParams,
  EquipmentBodyParams,
  EquipmentParams,
  FilesParams,
  LeadFilterParams,
  LeadItem,
  LeadListFilterParams,
  MoveLeadParams,
  QuickNotesParams,
  SendLeadDripParams,
  UserListFilterParams,
} from '@/types';
import { CORRELATION_IDS } from '../correlationIds';
import { performHttpCall } from '../httpSlice';

export const getLeadsHttpCall = (params: LeadListFilterParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_LEAD_LIST,
    payload: {
      url: endpoints.private.getLeads(params),
      method: 'get',
      useBearerToken: true,
    },
  });

export const getLeadStaffMembersDropdownHttpCall = (params: UserListFilterParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_LEAD_STAFF_MEMBERS,
    payload: {
      url: endpoints.private.getUsers(params),
      method: 'get',
      useBearerToken: true,
    },
  });

export const createLeadHttpCall = (params: CustomerProfileParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.CREATE_LEAD,
    payload: {
      url: endpoints.private.createLead,
      method: 'post',
      useBearerToken: true,
      body: params,
    },
  });

export const updateLeadHttpCall = (params: CustomerProfileParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.UPDATE_LEAD,
    payload: {
      url: endpoints.private.updateLead(params?.id),
      method: 'put',
      useBearerToken: true,
      body: params,
    },
  });

export const updateLeadVMCountHttpCall = (id: string) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.UPDATE_LEAD_VM_COUNT,
    payload: {
      url: endpoints.private.updateLeadVMCount(id ?? ''),
      method: 'patch',
      useBearerToken: true,
    },
  });

export const sendLeadContractHttpCall = (id: string) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.SEND_LEAD_CONTRACT,
    payload: {
      url: endpoints.private.sendsLeadContract(id ?? ''),
      method: 'get',
      useBearerToken: true,
    },
  });

export const restoreLostLeadHttpCall = (id: string) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.RESTORE_LOST_LEAD,
    payload: {
      url: endpoints.private.restoresLostLead(id ?? ''),
      method: 'patch',
      useBearerToken: true,
    },
  });

export const createLeadEquipmentsHttpCall = (params: EquipmentBodyParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.CREATE_LEAD_EQUIPMENTS,
    payload: {
      url: endpoints.private.createLeadEquipments(params?.leadId),
      method: 'put',
      useBearerToken: true,
      body: {
        equipments: params.equipments,
      },
    },
  });

export const getLeadEquipmentsHttpCall = (id: string) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_LEAD_EQUIPMENTS,
    payload: {
      url: endpoints.private.getLeadEquipments(id),
      method: 'get',
      useBearerToken: true,
    },
  });

export const deleteLeadEquipmentHttpCall = (id: EquipmentParams['id']) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.DELETE_LEAD_EQUIPMENT,
    payload: {
      url: endpoints.private.deleteLeadEquipment(id ?? ''),
      method: 'delete',
      useBearerToken: true,
    },
  });

export const deleteAllLeadEquipmentsHttpCall = (id: EquipmentParams['id']) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.DELETE_LEAD_EQUIPMENT,
    payload: {
      url: endpoints.private.deleteAllLeadEquipments(id ?? ''),
      method: 'delete',
      useBearerToken: true,
    },
  });

export const updateCustomerLeadHttpCall = (params: CustomerProfileParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.UPDATE_CUSTOMER_LEAD,
    payload: {
      url: endpoints.private.updateCustomerLead(params?.id),
      method: 'put',
      useBearerToken: true,
      body: params,
    },
  });

export const updateLeadCustomerDetailsHttpCall = (params: CustomerProfileParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.UPDATE_LEAD_CUSTOMER_DETAILS,
    payload: {
      url: endpoints.private.updateLeadCustomerDetails(params?.id),
      method: 'put',
      useBearerToken: true,
      body: params,
    },
  });

export const getLeadQuickNotesHttpCall = (id: QuickNotesParams['id']) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_LEAD_QUICK_NOTES,
    payload: {
      url: endpoints.private.getLeadQuickNotes(id),
      method: 'get',
      useBearerToken: true,
    },
  });

export const createLeadQuickNotesHttpCall = (data: QuickNotesParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.CREATE_LEAD_QUICK_NOTES,
    payload: {
      url: endpoints.private.createLeadQuickNotes(data?.id ?? ''),
      method: 'post',
      useBearerToken: true,
      body: data,
    },
  });

export const updateLeadQuickNotesHttpCall = (data: QuickNotesParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.UPDATE_LEAD_QUICK_NOTES,
    payload: {
      url: endpoints.private.updateLeadQuickNotes(data?.id ?? ''),
      method: 'put',
      useBearerToken: true,
      body: data,
    },
  });

export const deleteLeadQuickNotesHttpCall = (id: QuickNotesParams['id']) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.DELETE_LEAD_QUICK_NOTES,
    payload: {
      url: endpoints.private.deleteLeadQuickNotes(id ?? ''),
      method: 'delete',
      useBearerToken: true,
    },
  });

export const getLeadActivityLogsHttpCall = (params: ActivityLogsParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_LEAD_ACTIVITY_LOGS,
    payload: {
      url: endpoints.private.getLeadActivityLogs(params),
      method: 'get',
      useBearerToken: true,
    },
  });

export const moveLeadHttpCall = (params: MoveLeadParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.MOVE_LEAD,
    payload: {
      url: endpoints.private.moveLead(params?.leadId),
      method: 'patch',
      useBearerToken: true,
      body: params,
    },
  });

export const sendLeadDripHttpCall = (params: SendLeadDripParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.SEND_LEAD_DRIP,
    payload: {
      url: endpoints.private.sendLeadDrip(params?.leadId),
      method: 'get',
      useBearerToken: true,
      body: params,
    },
  });

export const sendLeadFilterHttpCall = (data: LeadFilterParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.SEND_LEAD_FILTER,
    payload: {
      url: endpoints.private.sendLeadFilter,
      method: 'post',
      useBearerToken: true,
      body: data,
    },
  });

export const uploadLeadFileHttpCall = (
  id: FilesParams['id'],
  formData: any,
  onUploadProgress: (progressEvent: any) => void,
) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.UPLOAD_LEAD_FILE,
    payload: {
      url: endpoints.private.uploadLeadFile(id ?? ''),
      method: 'post',
      useBearerToken: true,
      body: formData,
      onUploadProgress,
    },
  });

export const getLeadFileDetailsHttpCall = (id: any) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_LEAD_FILE_DETAILS,
    payload: {
      url: endpoints.private.getLeadFileDetails(id),
      method: 'get',
      useBearerToken: true,
    },
  });

export const deleteLeadFileHttpCall = (id: any) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.DELETE_LEAD_FILE,
    payload: {
      url: endpoints.private.deleteLeadFile(id ?? ''),
      method: 'delete',
      useBearerToken: true,
    },
  });

export const getLeadContractPDFHttpCall = (leadId: LeadItem['id']) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_LEAD_CONTRACT_PDF,
    payload: {
      url: endpoints.private.getLeadContractPDF(leadId),
      method: 'get',
      useBearerToken: true,
    },
  });

export const deleteLostLeadAndClient = (id: string) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.DELETE_LOST_LEAD_AND_CLIENT,
    payload: {
      url: endpoints.private.deleteLostLeadAndClient(id ?? ''),
      method: 'delete',
      useBearerToken: true,
    },
  });

export const removeLeadContactAction = (id: string) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.REMOVE_LEAD_CONTRACT,
    payload: {
      url: endpoints.private.removeLeadContact(id ?? ''),
      method: 'delete',
      useBearerToken: true,
    },
  });
