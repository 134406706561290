import { colors, themeParser, useAppTheme } from '@/theme';
import { css } from '@emotion/css';

export const useStaffMemberStyle = () => {
  const theme = useAppTheme();

  return {
    root: css`
      .MuiDialog-paper {
        background: ${themeParser(colors.body.light, colors.body.dark, theme)};
        width: 900px;
      }

      button {
        width: 100%;
        font-weight: 500;
        color: ${themeParser(colors.body.light, colors.body.dark, theme)};
        margin: 0px 14px 25px 14px;
      }
    `,
    largeModal: css`
      .MuiDialog-paper {
        background: ${themeParser(colors.body.light, colors.body.dark, theme)};
        width: 1200px;
      }

      h2 {
        background: transparent;
        color: ${colors.primary};
        font-size: 1.15rem;
        text-transform: capitalize !important;
      }

      .MuiDialogContent-root {
        padding-bottom: 5px !important;
      }
    `,
    DialogTitle: css`
      background: #2196f3;
      padding: 8px !important;
      text-transform: uppercase !important;
      font-weight: 500 !important;
      letter-spacing: 1px;
      color: ${themeParser(colors.body.light, colors.body.dark, theme)};
      font-size: 1.15rem !important;
      text-align: center;
      font-family: 'Poppins', sans-serif !important;
    `,
    DialogContent: css`
      padding: 0px 0px 0px 0px;

      color: ${themeParser('auto', '#b3b3b3', theme)} !important;

      .MuiFormControl-root {
        margin-top: 25px;
        margin-bottom: 1rem;
      }

      .textaria_error {
        border-color: red !important;
        outline: none !important;
        width: 100%;
        padding: 10px 0px 10px 10px;
      }

      .textaria {
        padding: 10px 0px 10px 10px;
        width: 100%;
        font-family: 'Poppins', sans-serif;
      }

      .textaria:focus {
        border-color: #1976d2 !important;
        outline: none !important;
      }

      .error {
        font-size: 12px;
        color: #d32f2f;
      }
    `,
    border: css`
      border: 1px solid #8c8c8c70;
      padding: 10px 10px !important;

      .MuiFormControl-fullWidth.MuiTextField-root {
        margin-top: 5px;
      }
    `,
    contentWrapper: css`
      margin-top: 0px !important;
      padding: 0px;
      margin-left: -8px !important;

      .MuiTextField-root {
        margin-top: 5px !important;
      }

      .custom-variable-list {
        overflow-y: auto;
        max-height: calc(100vh - 500px);
        height: calc(100vh - 500px);
      }

      .custom-variable-list::-webkit-scrollbar {
        width: 4px;
        background-color: darkgrey;
      }

      .custom-variable-list::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid slategrey;
      }

      li {
        margin: 0;
        padding: 5px;
        color: ${colors.primary};
        border-bottom: 1px solid ${themeParser(colors.tabItems.light, colors.tabItems.dark, theme)} !important;
        cursor: pointer;
        padding-right: 10px !important;
        padding-left: 3px;

        span {
          font-size: 0.78571rem !important;
        }
      }

      li:hover {
        background-color: ${themeParser(colors.tabItems.light, colors.tabItems.dark, theme)} !important;
      }
    `,
    labelForm: css`
      color: ${themeParser(colors.textInput.labelColor.light, colors.textInput.labelColor.dark, theme)};
    `,
    checkbocGroup: css`
      .MuiFormGroup-root {
        height: 15px;

        svg {
          color: ${themeParser(colors.textInput.labelColor.light, colors.textInput.labelColor.dark, theme)};
        }

        .Mui-checked svg {
          color: ${colors.primary};
        }
      }
    `,
    bottomSection: css`
      border-top: 1px solid #8c8c8c70;
      display: flex !important;
      justify-content: space-between !important;
      width: 99%;

      button {
        padding: 2px 12px;
        margin: 7px;
        font-weight: 400 !important;
        font-size: 14px;
        color: ${themeParser(colors.pagination.text.dark, colors.pagination.text.light, theme)};
      }

      @media (max-width: 580px) {
        display: block !important;
        text-align: center;
      }
    `,
    yellow: css`
      background: ${colors.yellow.default};
    `,
    multiSelectBody: css`
      .MuiGrid-container {
        align-items: end;
        display: flex;
        margin-top: -10px;
        margin-bottom: 20px;

        & > .MuiGrid-item {
          @media (max-width: 768px) {
            padding-left: 16px;
            width: 100%;
          }
        }

        .MuiTextField-root {
          margin: 0px;
        }

        .MuiInput-underline:before {
          border-color: ${themeParser(
            colors.textInput.borderColor.light,
            colors.textInput.borderColor.dark,
            theme,
          )} !important;
        }

        .MuiInput-input {
          color: ${colors.primary};
          width: 100%;
        }

        svg {
          color: #f44336;
          cursor: pointer;
        }

        .MuiSvgIcon-fontSizeMedium {
          color: ${colors.primary};
        }

        .MuiInput-underline:hover:before {
          border-bottom: 1px solid
            ${themeParser(colors.textInput.borderColor.light, colors.textInput.borderColor.dark, theme)} !important;
        }

        label,
        input {
          color: ${themeParser(colors.textInput.labelColor.light, colors.textInput.labelColor.dark, theme)};
        }
      }
    `,
  };
};
