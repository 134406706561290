import React, { useState } from 'react';
import { MultiSelect } from '@/components/atoms/MultiSelect';

const MultiSelectPreview = () => {
  const [items, setItems] = useState([1, 2]);

  return (
    <div>
      <MultiSelect
        options={[
          {
            label: 'First one',
            value: 1,
          },
          {
            label: 'Second one',
            value: 2,
          },
        ]}
        value={items}
        onChange={(items) => setItems(() => items)}
        label={'OK'}
        fullWidth={true}
      />
    </div>
  );
};

export { MultiSelectPreview };
