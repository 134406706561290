import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { useDatePickerStyles } from '@/static/stylesheets/atoms';

interface TimePickerProps {
  value?: null | number | Date;
  error?: boolean;
  errorText?: string | null;
  centerMode?: boolean;
  fullWidth?: boolean;

  onChange?(value: any): void;

  label?: string;

  disabled?: boolean;
  placeholder?: string;
}

const TimePicker: React.FC<TimePickerProps> = (props) => {
  const classes = useDatePickerStyles();
  const { value, onChange, disabled, placeholder, error, errorText, centerMode, fullWidth, label } = props;
  const [values, setValue] = useState<number | Date | null>(null);

  useEffect(() => {
    setValue(value ?? null);
  }, [value]);

  return (
    <>
      <div className={classes.root}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MobileTimePicker
            value={values}
            orientation='landscape'
            ampm={true}
            disabled={disabled}
            DialogProps={{ className: 'timePicker' }}
            onChange={(time) => {
              setValue(time);
              if (onChange) {
                onChange(time);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={placeholder ?? 'Time'}
                error={error}
                helperText={errorText}
                fullWidth={fullWidth}
                sx={{
                  '& input': {
                    textAlign: `${centerMode ? 'center' : 'left'} !important`,
                  },
                }}
                label={label}
              />
            )}
          />
        </LocalizationProvider>
      </div>
    </>
  );
};

export { TimePicker };
