import React from 'react';
import { Backdrop } from '@mui/material';
import { Loader } from './Loader';

interface OverlaySpinnerProps {
  show?: boolean;
}

const OverlaySpinner: React.FC<OverlaySpinnerProps> = ({ show }) => {
  if (!show) return null;

  return (
    <Backdrop open={show} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'transparent' }}>
      <Loader />
    </Backdrop>
  );
};

export default OverlaySpinner;
