import { useAppSelector } from '@/hooks';
import { getJobProgressItemList } from '@/redux/actions';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { themeParser, useAppTheme } from '@/theme';
import { JobProgressItemParams, ReportsFormData } from '@/types';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { convertToNumber } from '@/utils';

interface IProps {
  selectedMenuKey: string;
}

const filterDataByPriority = (data: JobProgressItemParams[], name1: string, name2: string) => {
  const getPriorityByName = (name: string) => {
    const item = data.find((item) => item.progress_name === name);
    return item ? convertToNumber(item.priority) : Infinity;
  };

  const priority1 = getPriorityByName(name1) || 0;
  const priority2 = getPriorityByName(name2) || 0;

  if (priority1 === Infinity || priority2 === Infinity || priority1 === priority2) {
    return [];
  }

  const [start, end] = priority1 < priority2 ? [priority1, priority2] : [priority2, priority1];

  return (data || [])?.filter(
    (item) => convertToNumber(item?.priority) >= start && convertToNumber(item?.priority) < end,
  );
};

const CurrentStatusPicker = ({ selectedMenuKey }: IProps) => {
  const theme = useAppTheme();
  const jobProgressBarList = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_PROGRESS_LIST];
  const progressBarList: JobProgressItemParams[] = jobProgressBarList?.data?.data ?? [];

  const { watch, setValue, control } = useFormContext<ReportsFormData>();

  useEffect(() => {
    getJobProgressItemList({
      progressType: selectedMenuKey === 'LEAD_TO_CLIENT_PENDING_PROFIT_AND_LOSS' ? 'lead' : 'job',
    });
  }, [selectedMenuKey]);

  const formValues = watch();

  const filterProgressBarList = filterDataByPriority(
    progressBarList,
    selectedMenuKey === 'LEAD_TO_CLIENT_PENDING_PROFIT_AND_LOSS' ? 'Proposal Accepted' : 'Proposal Sent',
    'Installed',
  );

  return (
    <div className={'report-filter-option-container current-status-picker-container'}>
      <div className={'report-filter-option-title'}>Current Status</div>
      <div className={'report-filter-option-body'}>
        <FormControl
          sx={{
            '& .MuiTypography-root': {
              fontSize: '13px',
              fontWeight: 400,
              color: themeParser('#313131', '#b7b7b7', theme),
            },
            '& .MuiFormControlLabel-root': {
              height: '25px',
            },
          }}
        >
          <Controller
            control={control}
            name={'source'}
            render={({ field }) => (
              <RadioGroup>
                {filterProgressBarList &&
                  filterProgressBarList?.length > 0 &&
                  filterProgressBarList?.map((item, index) => (
                    <FormControlLabel
                      key={index}
                      value={item?.id}
                      checked={formValues?.current_status === item?.id}
                      onChange={() => setValue('current_status', item?.id)}
                      control={<Radio size={'small'} />}
                      label={item?.progress_name}
                    />
                  ))}
              </RadioGroup>
            )}
          />
        </FormControl>
      </div>
    </div>
  );
};

export { CurrentStatusPicker };
