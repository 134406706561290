import { colors, themeParser, useAppTheme } from '@/theme';
import { css } from '@emotion/css';

export const usePricingStyle = () => {
  const theme = useAppTheme();

  return {
    root: css`
      border: 1px solid ${themeParser(colors.layout.borderColor.light, colors.layout.borderColor.dark, theme)};

      .MuiInput-underline:before {
        border-color: ${themeParser(colors.layout.borderColor.light, colors.layout.borderColor.dark, theme)} !important;
      }

      .Mui-disabled {
        -webkit-text-fill-color: ${themeParser('#000', '#cbcbcb', theme)} !important;
      }

      label {
        font-weight: 500;
        font-family: 'Poppins', sans-serif;
      }

      input {
        color: ${themeParser('#000000', colors.green.default, theme)} !important;
        font-weight: 400;
        font-size: 16px;
        font-family: 'Poppins', sans-serif;

        :focus {
          color: ${themeParser('auto', colors.primary, theme)} !important;
        }

        ::placeholder {
          color: ${themeParser('auto', '#9E9E9E', theme)} !important;
          font-weight: 400;
        }
      }

      .delete {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        margin-bottom: 4px;

        svg {
          font-size: 19px;
          color: #f44336;
          cursor: pointer;
        }
      }

      .button button {
        background-color: transparent !important;
        box-shadow: none;
        color: ${themeParser('#b5b5b5', '#666', theme)} !important;
        border: 1px solid ${themeParser('#e0e0e0', '#3e3e3e', theme)};
        font-weight: 500;
        height: 32px;
        line-height: 32px;
      }

      .button button:hover {
        color: ${themeParser('#b5b5b5', '#a1a0a0', theme)} !important;
        border: 1px solid ${themeParser('#e0e0e0', '#747474', theme)};
        box-shadow:
          0 1px 1px 0 rgb(0 0 0 / 14%),
          0 1px 5px 0 rgb(0 0 0 / 12%),
          0 1px 1px -1px rgb(0 0 0 / 20%);
      }

      .pricingTableHolder {
        color: ${themeParser('#000000c7', '#838383', theme)} !important;
        background-color: ${themeParser('#eeeeee85', '#353535', theme)} !important;
        padding: 7px 0;
        margin: 0px 0px 0px 16px;
        text-transform: uppercase;
        font-size: 16px;
        letter-spacing: 2.5px;
        width: 100%;
        text-align: center;
      }

      ul {
        list-style: none;
        padding: 0;
      }
    `,
    letterIcon: css`
      color: ${themeParser('#fffdfd', '#707070b0', theme)} !important;
      background-color: ${themeParser('#bbbbbb', '#2a2a2a', theme)} !important;
      height: 22px;
      width: 22px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 15px;
      flex-wrap: nowrap;
      flex-shrink: 0;
    `,
    hide: css`
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.4s ease-in-out;
    `,
    show: css`
      max-height: 500px; /* Set the maximum height */
      overflow: hidden;
      transition: max-height 0.5s ease-in-out;

      @media (max-width: 768px) {
        overflow-y: auto;
      }
    `,
    according: css`
      border-top: 1px solid ${themeParser('#e0e0e0', '#3e3e3e', theme)};
      border-right: 1px solid ${themeParser('#e0e0e0', '#3e3e3e', theme)};
      border-left: 1px solid ${themeParser('#e0e0e0', '#3e3e3e', theme)};
      margin: 0;
    `,
    textHeader: css`
      background-color: ${themeParser('#eeeeee', '', theme)} !important;
      color: ${themeParser('#000000', '#2196f3', theme)} !important;
    `,
    accordionHeader: css`
      display: flex;
      align-items: center;
      gap: 10px;
      line-height: 46px;
      padding: 0px;
      background-color: ${themeParser('#eeeeee', '#3e3e3e', theme)} !important;
      color: ${themeParser('#000000', '#2196f3', theme)} !important;
      font-size: 16px;
      border-bottom: 1px solid ${themeParser('#e0e0e0', '#2a2a2a', theme)};
      cursor: pointer;
      overflow: hidden;
    `,
    accordionBody: css`
      border-bottom: 1px solid ${themeParser('#e0e0e0', '#3e3e3e', theme)};
      padding: 0;
      color: ${themeParser('#2a2a2a', '#9e9e9e', theme)} !important;
      box-shadow:
        inset 0 2px 2px 0 ${themeParser('rgba(255, 255, 255, 0.1)', 'rgba(0, 0, 0, 0.14)', theme)},
        inset 0 3px 1px -2px ${themeParser('rgba(255, 255, 255, 0.1)', 'rgba(0, 0, 0, 0.14)', theme)},
        inset 0 1px 5px 0 ${themeParser('rgba(255, 255, 255, 0.1)', 'rgba(0, 0, 0, 0.14)', theme)};
    `,
    activityBody: css`
      border: 1px solid ${themeParser(colors.layout.borderColor.light, colors.layout.borderColor.dark, theme)};
      li p {
        color: ${themeParser('#000000de', '#cbcbcb', theme)};
      }
      hr {
        border-bottom: 1px solid ${themeParser(colors.layout.borderColor.light, colors.layout.borderColor.dark, theme)};
      }
      ul {
        padding-top: 0px;
        padding-bottom: 0;
      }
      li {
        padding-right: 10px;
      }
      .text_span {
        .MuiTypography-body2 {
          width: 80px !important;
          text-align: right !important;
        }
      }
      .text_body {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        span,
        a {
          color: #2196f3;
          cursor: pointer;
          text-decoration: none;
        }
        a:hover {
          text-decoration: underline;
        }
        .MuiTypography-body1 {
          font-size: 11px;
          color: #a5a5a5;
        }
        .MuiTypography-body2 {
          width: 70%;
          text-align: left;
          color: ${themeParser('#000000de', '#cbcbcb', theme)};
        }
        p {
          max-width: 220px;
        }
        svg {
          font-size: 16px;
          color: #b7b7b7;
          transition: all 0.3s ease-in-out;
          opacity: 0.6;
          margin: 0px 2px;
        }
        .gps_icon:hover {
          color: ${colors.primary};
        }
        .edit_icon:hover {
          color: #ff9800;
        }
        .delete_icon:hover {
          color: #f44336;
        }
      }
      .activity_list {
        max-height: 415px !important;
        min-height: 415px !important;
      }
      .list_body {
        max-height: 350px;
        min-height: 350px;
        overflow: hidden;
        overflow-y: auto;
        scroll-behavior: smooth;
        ::-webkit-scrollbar {
          width: 4px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: #888;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      }
      .input_body {
        background: ${themeParser('#0000000d', '#00000017', theme)};
        padding: 10px;
        label {
          font-size: 1rem;
          color: #a5a5a5;
        }
        label.Mui-focused {
          color: ${colors.primary} !important;
        }
        .MuiInput-underline:before {
          border-color: ${themeParser(
            colors.layout.borderColor.light,
            colors.layout.borderColor.dark,
            theme,
          )} !important;
        }
      }
    `,
    activityHeader: css`
      text-align: center !important;
      color: ${themeParser(colors.body.dark, colors.primary, theme)};
      span {
        font-weight: 600;
      }
    `,
    navHeader: css`
      .MuiGrid-item {
        background: transparent;
        padding: 12px;
        text-align: center;
        cursor: pointer;
        span {
          color: ${themeParser(colors.body.dark, colors.primary, theme)};
        }
      }
      .MuiGrid-item.active {
        border: 1px solid ${themeParser(colors.layout.borderColor.light, colors.layout.borderColor.dark, theme)};
        font-weight: 600;
        border-bottom: 0px solid;
      }
    `,
    selector: css`
      margin-bottom: 20px;
      .MuiSelect-select {
        color: ${themeParser('#000000de', '#cbcbcb', theme)};
      }
      .MuiSelect-select {
        background-color: transparent !important;
      }
    `,
    dateBody: css`
      margin-top: 20px;
      label {
        padding-bottom: 6px;
        display: block;
        color: ${themeParser(colors.body.dark, colors.primary, theme)};
      }
      .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border-color: ${colors.primary};
      }
    `,
  };
};
