import { css } from '@emotion/css';
import { colors, themeParser, useAppTheme } from '@/theme';

export const useDatePickerStyles = () => {
  const theme = useAppTheme();

  return {
    root: css`
      .MuiOutlinedInput-root {
        .MuiOutlinedInput-notchedOutline {
          border: 0;
          padding: 0;
          margin: 0;
          border-bottom: 1px solid
            ${themeParser(colors.layout.borderColor.light, colors.layout.borderColor.dark, theme)};
          border-radius: 0;
        }
        .MuiOutlinedInput-notchedOutline.Mui-focused {
          border: 0 !important;
          border-bottom: 1px solid;
        }
        input {
          padding: 10px 5px;
          outline: none;
        }
      }
    `,
  };
};
