import { ContentWrapper } from '@/components/organisms/Reports';
import { ReportTable, Tbody, Td, Tfoot, Th, Tr } from '@/components/organisms/Reports/ReportContent/ReportTable';
import { useAppSelector } from '@/hooks';
import { getProfitAndLossReportAction } from '@/redux/actions';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { formatCurrency } from '@/utils';
import { useEffect } from 'react';

const LeadToClientPendingProfitLoss = () => {
  const result = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_PROFIT_AND_LOSS_REPORT];

  useEffect(() => {
    getProfitAndLossReportAction({
      profit_loss_report_type: 'pending',
      install_type: 'internet_install',
    });
  }, []);

  return (
    <>
      <ContentWrapper
        headings={[
          'Lead To Client | Pending Profit & Loss',
          "Only active leads are included in this report if they are marked as of at least the status 'Proposal Accepted' and before status 'Installed'.",
        ]}
      >
        {result && result.data && (
          <>
            <ReportTable tableContainerClass='no-border-bottom' marginBottom={'20px'}>
              <Tbody>
                {/* Table Header */}
                <Tr>
                  <Th align={'center'} colSpan={7}>
                    Installation Profit
                  </Th>
                </Tr>
                <Tr>
                  <Th align={'center'} width={'339px'}>
                    Total Installs
                  </Th>
                  <Th align={'center'}>Install Cost Paid</Th>
                  <Th align={'center'}>Our Cost</Th>
                  <Th align={'center'}>Average Net Profit</Th>
                  <Th align={'center'}>Total Net Profit</Th>
                  <Th align={'center'}>Profit Margin</Th>
                </Tr>

                {/* Table body */}
                {result.data.installation_profit && (
                  <Tr>
                    <Td align={'center'}>{result.data.installation_profit.total_install}</Td>
                    <Td align={'center'}>{formatCurrency(result.data.installation_profit.install_cost_paid)}</Td>
                    <Td align={'center'}>{formatCurrency(result.data.installation_profit.our_cost)}</Td>
                    <Td align={'center'}>{formatCurrency(result.data.installation_profit.average_net_profit)}</Td>
                    <Td align={'center'}>{'$' + result.data.installation_profit.total_net_profit}</Td>
                    <Td align={'center'}>{result.data.installation_profit.profit_margin + '%'}</Td>
                  </Tr>
                )}
              </Tbody>
            </ReportTable>
            <ReportTable tableContainerClass='no-border-bottom' marginBottom={'20px'}>
              <Tbody>
                {/* Table Header */}
                <Tr>
                  <Th align={'center'} colSpan={7}>
                    Monthly Recurring + Installation Profit
                  </Th>
                </Tr>
                <Tr>
                  <Th align={'center'} width={'339px'}>
                    Total Installs
                  </Th>
                  <Th align={'center'}>1 Mo. Recurring</Th>
                  <Th align={'center'}>12 Mo. Recurring</Th>
                  <Th align={'center'}>Ave. Mo. Recurring</Th>
                  <Th align={'center'}>1 Mo. Net Profit</Th>
                  <Th align={'center'}>12 Mo. Net Profit</Th>
                  <Th align={'center'}>This Year Net Profit</Th>
                </Tr>

                {/* Table body */}
                {result.data.installation_profit_with_monthly_recurring && (
                  <Tr>
                    <Td align={'center'}>{result.data.installation_profit_with_monthly_recurring.total_install}</Td>
                    <Td align={'center'}>
                      {formatCurrency(result.data.installation_profit_with_monthly_recurring['1_month_recurring'])}
                    </Td>
                    <Td align={'center'}>
                      {formatCurrency(result.data.installation_profit_with_monthly_recurring['12_month_recurring'])}
                    </Td>
                    <Td align={'center'}>
                      {formatCurrency(
                        result.data.installation_profit_with_monthly_recurring['average_month_recurring'],
                      )}
                    </Td>
                    <Td align={'center'}>
                      {formatCurrency(result.data.installation_profit_with_monthly_recurring['1_month_net_profit'])}
                    </Td>
                    <Td align={'center'}>
                      {formatCurrency(result.data.installation_profit_with_monthly_recurring['12_month_net_profit'])}
                    </Td>
                    <Td align={'center'}>
                      {formatCurrency(result.data.installation_profit_with_monthly_recurring.this_year_net_profit)}
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </ReportTable>
            <ReportTable>
              <Tbody>
                <Tr>
                  <Th align={'center'} width={'271px'}></Th>
                  <Th align={'center'} width={'339px'}>
                    Date Installed
                  </Th>
                  <Th align={'center'}>Total Client Paid</Th>
                  <Th align={'center'}>Install Paid</Th>
                  <Th align={'center'}>Monthly Recurring</Th>
                  <Th align={'center'}>Our Cost</Th>
                  <Th align={'center'}>Net Profit</Th>
                </Tr>
                {result.data.customer_wise_breakdown.break_down &&
                  result.data.customer_wise_breakdown.break_down.map((_: any, index: number) => (
                    <Tr key={'pl' + index}>
                      <Td align={'left'}>{_.customer_name}</Td>
                      <Td align={'center'}>{_.date_installed}</Td>
                      <Td align={'center'}>{formatCurrency(_.total_client_paid)}</Td>
                      <Td align={'center'}>{formatCurrency(_.install_paid)}</Td>
                      <Td align={'center'}>{formatCurrency(_.monthly_recurring)}</Td>
                      <Td align={'center'}>{formatCurrency(_.our_cost)}</Td>
                      <Td align={'center'}>{formatCurrency(_.net_profit)}</Td>
                    </Tr>
                  ))}
              </Tbody>
              <Tfoot>
                <Tr>
                  <Th align={'left'}>Total</Th>
                  <Th align={'center'}>{result.data.customer_wise_breakdown.total.total_install} Installs</Th>
                  <Th align={'center'}>{'$' + result.data.customer_wise_breakdown.total.total_client_paid}</Th>
                  <Th align={'center'}>{formatCurrency(result.data.customer_wise_breakdown.total.install_paid)}</Th>
                  <Th align={'center'}>
                    {formatCurrency(result.data.customer_wise_breakdown.total.monthly_recurring)}
                  </Th>
                  <Th align={'center'}>{formatCurrency(result.data.customer_wise_breakdown.total.our_cost)}</Th>
                  <Th align={'center'}>{'$' + result.data.customer_wise_breakdown.total.net_profit}</Th>
                </Tr>
              </Tfoot>
            </ReportTable>
          </>
        )}
      </ContentWrapper>
    </>
  );
};

export { LeadToClientPendingProfitLoss };
