import { store } from '@/redux/store';
import { clearHttpStateObject } from '@/redux/actions';
import {
  CORRELATION_IDS,
  getUserProfileHttpCall,
  updateUserProfileHttpCall,
  saveUserProfileEmailSignatureHttpCall,
} from '@/redux/reducers/http';
import { UserProfileEmailSignature, UserProfileParams } from '@/types';

/** -------------- User profile ---------------------- */
export function getUserProfile() {
  store.dispatch(getUserProfileHttpCall());
}

export function updateUserProfile(params: UserProfileParams | any) {
  store.dispatch(updateUserProfileHttpCall(params));
}

export function saveUserProfileEmailSignature(params: UserProfileEmailSignature) {
  store.dispatch(saveUserProfileEmailSignatureHttpCall(params));
}

export function clearUpdatedUserProfile() {
  clearHttpStateObject(CORRELATION_IDS.UPDATE_USER_PROFILE);
}

export function clearSavedUserProfileEmailSignature() {
  clearHttpStateObject(CORRELATION_IDS.SAVE_PROFILE_EMAIL_SIGNATURE);
}
/** ---------------------------------------------------------- */
