import React from 'react';
import { Box } from '@mui/material';
import { useAppSelector } from '@/hooks';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { convertToNumber } from '@/utils';
import { colors, themeParser, useAppTheme } from '@/theme';

const LeadCounter = () => {
  const theme = useAppTheme();
  const leadListResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_LEAD_LIST];
  const leadListFilterResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.SEND_LEAD_FILTER];
  const totalItems = convertToNumber(
    leadListFilterResponse?.data ? leadListFilterResponse?.data?.total : leadListResponse?.data?.total,
  );

  return (
    <Box
      sx={{
        padding: '10px 30px',
        fontSize: '12px',
        color: `${themeParser('#000000', colors.primary, theme)} !important`,
      }}
    >
      TOTAL LEADS: {totalItems ?? 0}
    </Box>
  );
};

export { LeadCounter };
