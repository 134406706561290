import React, { useState } from 'react';
import { SettingsLayout } from '@/components/layouts';
import { ClientCounter, ClientList } from '@/components/molecules/Clients';
import { DeactivatedClients } from '@/components/molecules/Clients/DeactivatedClients';
import { PrivateLayout } from '@/components/Middleware';
import { useClientStyles } from '@/static/stylesheets/organisms';

const Clients = () => {
  const classes = useClientStyles();
  const [deactivatedClient, setDeactivatedClients] = useState<boolean>(false);

  const onChangeClientType = () => {
    setDeactivatedClients(!deactivatedClient);
  };

  return (
    <>
      <PrivateLayout>
        <div className={classes.clientListBody}>
          <SettingsLayout
            hideSettingsTabPanel={false}
            hideHeaderRightSide={true}
            containerSize={'xxl'}
            onChangeType={onChangeClientType}
            featureType={'clients'}
          >
            {deactivatedClient ? <DeactivatedClients isDeactivatedClient={deactivatedClient} /> : <ClientList />}
          </SettingsLayout>

          <ClientCounter />
        </div>
      </PrivateLayout>
    </>
  );
};

export default Clients;
