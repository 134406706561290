import React, { useEffect, useState } from 'react';
import { TaskGridNoData, TasksGridItem } from '@/components/organisms/Tasks';
import { TaskGridLoader } from '@/components/organisms/Tasks';
import { TaskItem } from '@/types';
import { getSingleTask } from '@/redux/actions';
import { useAppSelector } from '@/hooks';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';

interface TaskItemProps {
  taskData?: TaskItem;
  childrenExpanded?: boolean;
  reloadList?(): void;
}

const TaskGridRow: React.FC<TaskItemProps> = (props) => {
  const parentTaskData = props?.taskData;
  const singleTaskResponse = (useAppSelector(selectHttpState) as any)[
    `${CORRELATION_IDS.GET_SINGLE_TASK}_${parentTaskData?.id}`
  ];

  const singleTaskData: TaskItem = singleTaskResponse?.data;
  const childTasks = singleTaskData?.child_tasks ?? [];

  const [childrenExpanded, setChildrenExpanded] = useState<boolean>(false);
  const toggleChildrenExpanded = () => setChildrenExpanded(!childrenExpanded);

  const getSingleTaskData = () => {
    if (parentTaskData?.id) {
      getSingleTask(parentTaskData?.id);
    }
  };

  useEffect(() => {
    if (childrenExpanded) {
      getSingleTaskData();
    }
  }, [childrenExpanded]);

  useEffect(() => {
    setChildrenExpanded(props?.childrenExpanded ?? false);
  }, [props?.childrenExpanded]);

  return (
    <>
      <TasksGridItem data={props?.taskData} onClickUnfold={toggleChildrenExpanded} reloadList={props?.reloadList} />

      {childrenExpanded && (
        <>
          {singleTaskResponse?.loading ? (
            <TaskGridLoader />
          ) : (
            <>
              {childTasks && childTasks?.length > 0 ? (
                <>
                  {childTasks?.map((item, index) => (
                    <TasksGridItem key={index} data={item} isSubTask={true} parentTaskId={singleTaskData?.id} />
                  ))}
                </>
              ) : (
                <TaskGridNoData title={'No subtasks found'} />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export { TaskGridRow };
