import React, { useEffect } from 'react';
import { Button } from '@/components/atoms/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useStaffMemberStyle } from '@/static/stylesheets/molecules';
import DialogTitle from '@mui/material/DialogTitle';
import { CloseButton } from '@/components/atoms/Button/CloseButton';
import Grid from '@mui/material/Grid';
import { useInput } from '@/hooks';
import toast from 'react-hot-toast';
import { TextareaAutosize } from '@mui/material';
import { updateJobQuickNotes } from '@/redux/actions';
import { QuickNotesParams } from '@/types';
import { timezone } from '@/utils/timeZone';

interface EditNotesFormProps {
  openDialog: boolean;
  onClose?(): void;
  notes?: any;
}

const EditNotes: React.FC<EditNotesFormProps> = (props) => {
  const classes = useStaffMemberStyle();
  const { openDialog, onClose, notes } = props;
  //const [createNote, setCreateNote] = useState(notes?.note_body);
  const resetFields = () => {
    resetCreateNote();
  };

  const {
    value: createNote,
    reset: resetCreateNote,
    error: createNoteError,
    setValue: setDefaultNote,
    bind: bindCreateNote,
  } = useInput('', {
    required: 'Please enter Note!',
  });

  const onNoteSubmit = () => {
    createNoteError?.check();
    if (createNoteError?.check()) {
      toast.error('Please fill all the required fields!');
      return;
    }
    const data: QuickNotesParams = {
      note_body: createNote,
      time_zone: timezone,
      id: notes?.id,
    };
    updateJobQuickNotes(data);
  };

  useEffect(() => {
    if (notes && openDialog) {
      setDefaultNote(notes?.note_body);
    }
  }, [notes, openDialog]);

  return (
    <>
      <Dialog
        className={classes.root}
        open={openDialog}
        maxWidth={'md'}
        onClose={onClose}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogTitle className={classes.DialogTitle} id='responsive-dialog-title'>
          {`Edit Quick Notes`}
        </DialogTitle>
        <div onClick={onClose}>
          <CloseButton />
        </div>
        <DialogContent>
          <DialogContentText className={classes.DialogContent}>
            <Grid container spacing={2}>
              <Grid item sm={12} md={12} lg={12}>
                {/* <TextInput
                  variant={'standard'}
                  label={"Notes title"}
                  fullWidth={true}
                  {...bindCreateNote}
                  error={createNoteError?.error}
                  errorText={createNoteError?.message}
                /> */}
                <TextareaAutosize
                  aria-label='minimum height'
                  minRows={4}
                  placeholder='Textarea'
                  {...bindCreateNote}
                  className={createNoteError?.error ? 'textaria_error' : 'textaria'}
                />
                {createNoteError?.error && <span className='error'>{createNoteError?.message}</span>}
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color={'primary'}
            size={'small'}
            variant={'contained'}
            title={'Update Notes'}
            onClick={onNoteSubmit}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export { EditNotes };
