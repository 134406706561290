import React, { useEffect, useState } from 'react';
import { useLoginFormStyles } from '@/static/stylesheets/molecules/loginFormStyles';
import { Button } from '@/components/atoms/Button';
import { TextInput } from '@/components/atoms/TextInput';
import Grid from '@mui/material/Grid';
import Logo from '@/static/images/logo.png';
import { login, storeLoginData } from '@/redux/actions';
import { useAppSelector, useInput } from '@/hooks';
import { validateEmail } from '@/utils';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { AuthService } from '@/services/auth';
import { useNavigate } from 'react-router-dom';
import { routePaths } from '@/routes';
import { clearHttpStateObject } from '@/redux/actions';
import toast from 'react-hot-toast';
import { ThemeState, withAppTheme } from '@/theme';
import TextWithShowMore from '@/components/atoms/Utils/TextWithShowMore';

interface LoginFormProps {
  setAppTheme: ThemeState['setAppTheme'];
  appTheme: ThemeState['appTheme'];
}

const LoginForm: React.FC<LoginFormProps> = (props) => {
  const classes = useLoginFormStyles();
  const navigate = useNavigate();
  const { value: email, bind: bindEmail } = useInput('');
  const { value: password, bind: bindPassword } = useInput('');

  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const loginInfo = useAppSelector(selectHttpState)[CORRELATION_IDS.LOGIN];
  const loading = loginInfo?.loading ?? false;

  const handleClickSubmit = () => {
    if (!validateEmail(email)) {
      setEmailError(true);
      return;
    } else {
      setEmailError(false);
    }

    if (password?.length < 8) {
      setPasswordError(true);
      return;
    } else {
      setPasswordError(false);
    }

    login({
      email,
      password,
    });
  };

  useEffect(() => {
    if (loginInfo && loginInfo.success && loginInfo.data) {
      const token = loginInfo.data?.token;
      const userData = loginInfo.data?.user;
      new AuthService().setToken(token);

      storeLoginData({
        token,
        logged_in: true,
        shouldReloadUserData: true,
        userData,
      });

      // update the system theme if there is any user config
      if (userData?.application_theme) {
        const theme = JSON.parse(userData?.application_theme)?.theme;
        if (props?.setAppTheme && (theme === 'light' || theme === 'dark')) {
          props?.setAppTheme(theme);
        }
      }

      navigate(routePaths.settings.generalSettings); //TODO:: We need to redirect to the lead page later
      return () => clearHttpStateObject(CORRELATION_IDS.LOGIN);
    }

    if (loginInfo && loginInfo?.error) {
      const error = loginInfo?.error;
      if (error?.status === 401) {
        toast.error(<TextWithShowMore text={error?.data?.message} />);
      } else {
        toast.error('Failed to login. Please try again!');
      }
    }
  }, [loginInfo]);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.loginContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <img src={Logo} alt='logo' />
            </Grid>
            <Grid item xs={12} className={classes.inputForm}>
              <TextInput
                variant={'standard'}
                label={'Email'}
                fullWidth={true}
                {...bindEmail}
                error={emailError}
                helperText={emailError ? 'Enter a valid email!' : null}
              />
            </Grid>
            <Grid item xs={12} className={classes.inputForm}>
              <TextInput
                variant={'standard'}
                label={'Password'}
                type={'password'}
                fullWidth={true}
                {...bindPassword}
                error={passwordError}
                helperText={passwordError ? 'Your password should be at least 8 characters!' : null}
              />
            </Grid>
            <Grid item xs={12} className={classes.button}>
              <Button title={'Login'} fullWidth={true} onClick={handleClickSubmit} loading={loading} />
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

const LoginFormExtended = withAppTheme(LoginForm);
export { LoginFormExtended as LoginForm };
