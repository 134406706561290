import React from 'react';
import MaterialPagination from '@mui/material/Pagination';
import { usePaginationStyles } from '@/static/stylesheets/atoms';

export interface PaginationProps {
  count?: number;
  currentPage?: number;

  onChange?(value: number): void;
}

const Pagination: React.FC<PaginationProps> = (props) => {
  const classes = usePaginationStyles();

  return (
    <>
      <div className={classes.root}>
        <MaterialPagination
          count={props.count}
          variant='outlined'
          shape='rounded'
          classes={{
            outlined: classes.root,
          }}
          page={props.currentPage}
          onChange={(event, value) => {
            if (props.onChange) {
              props.onChange(value);
            }
          }}
        />
      </div>
    </>
  );
};

export { Pagination };
