import { ContentWrapper } from '@/components/organisms/Reports';
import { ReportTable, Tbody, Td, Tfoot, Th, Tr } from '@/components/organisms/Reports/ReportContent/ReportTable';
import { useAppSelector } from '@/hooks';
import { getExpensesBreakdownReportAction } from '@/redux/actions';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { formatCurrency } from '@/utils';
import { useEffect } from 'react';

const ExpensesBreakDown = () => {
  const result = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_EXPENSES_BREAKDOWN_REPORT];

  useEffect(() => {
    getExpensesBreakdownReportAction({
      install_type: 'internet_install',
    });
  }, []);

  return (
    <>
      <ContentWrapper headings={['General | Expenses Breakdown']}>
        <ReportTable tableContainerClass='no-border-bottom' marginBottom={'20px'}>
          <Tbody>
            <Tr>
              {(result?.data?.expense_by_job_type ?? []).map((item: any, index: number) => (
                <Th align={'center'} key={index}>
                  {item?.category_name}
                </Th>
              ))}
            </Tr>
            <Tr>
              {(result?.data?.expense_by_job_type ?? []).map((item: any, index: number) => (
                <Td align={'center'} key={index}>
                  {formatCurrency(item?.total_actual_cost)}
                </Td>
              ))}
            </Tr>

            <Tr>
              {(result?.data?.expense_by_job_type ?? []).map((item: any, index: number) => (
                <Td align={'center'} key={index}>
                  {`${item?.expense_percentage}%`}
                </Td>
              ))}
            </Tr>
          </Tbody>
        </ReportTable>

        <ReportTable>
          <Tbody>
            <Tr>
              <Th align={'center'} width={'271px'}></Th>
              {(result?.data?.expense_by_job_type ?? []).map((item: any, index: number) => (
                <Th align={'center'} key={index}>
                  {item?.category_name}
                </Th>
              ))}
            </Tr>
            {(result?.data?.expense_by_client ?? []).map((item: any, index: number) => (
              <Tr key={index}>
                <Td align={'left'}>{item?.customer_name}</Td>
                {(item?.categories ?? []).map((_: any, i: number) => (
                  <Td align={'center'} key={i}>
                    {_?.total_cost}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
          <Tfoot>
            <Tr>
              <Th align={'left'}>Total</Th>
              {(result?.data?.expense_by_job_type ?? []).map((item: any, index: number) => (
                <Th align={'center'} key={index}>
                  0
                </Th>
              ))}
            </Tr>
          </Tfoot>
        </ReportTable>
      </ContentWrapper>
    </>
  );
};

export { ExpensesBreakDown };
