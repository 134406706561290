import React, { useEffect } from 'react';
import { Button } from '@/components/atoms/Button';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import DialogTitle from '@mui/material/DialogTitle';
import { TaskCreateParams, TaskItem } from '@/types';
import { deleteTask, openConfirmationDialog, startStopTaskTimer, updateTask } from '@/redux/actions';
import { timezone } from '@/utils/timeZone';
import { useAppSelector, useComponentState } from '@/hooks';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { selectUiState } from '@/redux/reducers/uiSlice';
import { convertToNumber, dateWithTime, getTaskPriorityIndex } from '@/utils';
import { apiClient, endpoints } from '@/api';
import toast from 'react-hot-toast';
import { alertMessages } from '@/config';
import { Loader } from '@/components/atoms/Loader';

interface DialogHeaderProps {
  loading?: boolean;
  filesView?: boolean;

  onClickToggleFilesView?(): void;

  taskData?: TaskItem | null;

  updateParentTaskData(data: {
    [key in keyof TaskItem]: any;
  }): void;

  onActionsDone?(reload: boolean): void;
  handleClose?(): void;
}

const DialogHeader: React.FC<DialogHeaderProps> = (props) => {
  const { loading, taskData, updateParentTaskData, onClickToggleFilesView, onActionsDone, handleClose } = props;
  const taskEditFormData = useAppSelector(selectUiState).taskEditForm;
  const updateTaskResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.UPDATE_TASK];

  // useEffect(() => {
  //   if (updateTaskResponse?.success) {
  //     if(handleClose){
  //       handleClose()
  //     }
  //   }
  // }, [updateTaskResponse]);

  const {
    state: { pingLoading, completeTaskLoading },
    setState,
  } = useComponentState({
    pingLoading: false,
    completeTaskLoading: false,
  });

  const pingAssignee = () => {
    if (taskData?.id) {
      setState({ pingLoading: true });
      apiClient
        .get({
          method: 'get',
          url: endpoints.private.pingTaskAssignee(taskData?.id),
        })
        .then(({ data }) => {
          setState({ pingLoading: false });
          toast.success(data);
        })
        .catch(() => {
          setState({ pingLoading: false });
          toast.error(alertMessages.tasks.pingAssigneeError);
        });
    }
  };

  const deleteTaskItem = () => {
    if (taskData?.id) {
      openConfirmationDialog({
        title: 'Delete',
        message: alertMessages.tasks.taskDeleteConfirmation,
        type: 'error',
        textCenter: 'center',
        onClickConfirm() {
          deleteTask(taskData?.id);
        },
      });
    }
  };

  const markTaskItemAsCompleted = () => {
    if (taskData?.id && !taskData?.is_complete) {
      setState({ completeTaskLoading: true });
      apiClient
        .patch({
          method: 'patch',
          url: endpoints.private.markTaskAsCompleted(taskData?.id),
        })
        .then(() => {
          setState({ completeTaskLoading: false });
          updateParentTaskData({
            is_complete: true,
          });
          toast.success(alertMessages.tasks.taskMarkedAsCompletedSuccess);

          if (onActionsDone) {
            onActionsDone(true);
          }
        })
        .catch((error: any) => {
          setState({ completeTaskLoading: false });
          if (error?.response?.status === 422) {
            toast.error(error?.response?.data?.errors);
            return;
          }

          toast.error(alertMessages.tasks.taskMarkedAsCompletedError);
        });
    }
  };

  const toggleTaskTimer = () => {
    if (taskData?.id) {
      apiClient
        .patch({
          method: 'patch',
          url: endpoints.private.startStopTaskTimer(taskData?.id),
        })
        .then(() => {
          const isRunning = !taskData?.is_running;
          updateParentTaskData({
            is_running: isRunning,
          });
          toast.success(
            taskData?.is_running ? alertMessages.tasks.taskTimerStopSuccess : alertMessages.tasks.taskTimerStartSuccess,
          );

          if (onActionsDone) {
            onActionsDone(true);
          }
        })
        .catch(() => {
          toast.error(
            taskData?.is_running ? alertMessages.tasks.taskTimerStopError : alertMessages.tasks.taskTimerStartError,
          );
        });
    }
  };

  const handleClickSave = () => {
    if (taskData) {
      const formData: TaskCreateParams & {
        id: string;
      } = {
        id: taskData?.id ?? '',
        tasks: [],
        timezone: timezone,
      };

      // Parent Task Data
      formData.tasks.push({
        is_parent: true,
        is_private: taskData?.is_private,
        title: taskData?.title,
        customer_id: taskData?.customer?.customer_id || undefined,
        priority_enum: taskEditFormData?.priority,
        assigned_to: taskEditFormData?.assignedTo,
        task_type_id: taskEditFormData?.taskTypes,
        due_datetime: dateWithTime(taskEditFormData?.dueDate, taskEditFormData?.dueTime),
      });

      // Child Task Data
      (taskEditFormData?.childTasks ?? [])?.forEach((task) => {
        const files: string[] = [];

        task?.files?.forEach((file) => {
          if (file && file?.id) {
            files.push(file?.id);
          }
        });

        const idInObject: { id?: string } = {};
        if (task?.id) {
          idInObject.id = task?.id;
        }

        formData.tasks.push({
          ...idInObject,
          is_parent: false,
          title: task?.title,
          sub_task_body: task?.description,
          assigned_to: task?.assigned_to?.id,
          priority_enum: getTaskPriorityIndex(task?.priority),
          reminder_datetime: dateWithTime(task?.reminder_datetime?.date, task?.reminder_datetime?.timestamp),
          due_datetime: dateWithTime(task?.due_datetime?.date, task?.due_datetime?.timestamp),
          files,
          estimated_hours: convertToNumber(task?.estimated_hours),
          is_complete: task?.is_complete,
        });
      });

      updateTask(formData);
    }
  };

  return (
    <>
      <DialogTitle className={'dialogTitle'}>
        <div className={'wrapper'}>
          <div className={'title'}>Task: {taskData?.title}</div>
          <div className={'actions'}>
            {loading ? (
              <Loader
                containerStyle={{
                  width: 100,
                  padding: 0,
                }}
                progressStyle={{
                  height: 20,
                  width: 20,
                }}
              />
            ) : (
              <>
                <Button
                  title={'Save'}
                  color={'success'}
                  size={'small'}
                  onClick={handleClickSave}
                  loading={updateTaskResponse?.loading}
                />
                {'|'}
                <Button title={'Ping'} color={'primary'} size={'small'} onClick={pingAssignee} loading={pingLoading} />
                <Button
                  title={props?.filesView ? 'View Tasks' : 'View Files'}
                  color={'primary'}
                  size={'small'}
                  onClick={onClickToggleFilesView}
                />
                <Button title={'Delete'} color={'error'} size={'small'} onClick={deleteTaskItem} />
                <Button
                  title={taskData?.is_complete ? 'Completed' : 'Complete'}
                  size={'small'}
                  color={taskData?.is_complete ? 'primary' : 'success'}
                  disabled={taskData?.is_complete}
                  onClick={markTaskItemAsCompleted}
                  loading={completeTaskLoading}
                />
                {'|'}
                <Button
                  title={<AccessAlarmIcon />}
                  size={'small'}
                  color={taskData?.is_running ? 'error' : 'primary'}
                  disabled={taskData?.is_complete}
                  sx={{
                    background: taskData?.is_complete ? '#d9d9d9 !important' : 'auto',
                  }}
                  onClick={toggleTaskTimer}
                />
                <Button onClick={handleClose} title={'close'} size={'small'} color={'error'} />
              </>
            )}
          </div>
        </div>
      </DialogTitle>
    </>
  );
};

export { DialogHeader };
