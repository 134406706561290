import { endpoints } from '@/api/endpoints';
import { performHttpCall } from '../httpSlice';
import { CORRELATION_IDS } from '../correlationIds';
import { LoginParams, UserProfileEmailSignature, UserProfileParams } from '@/types';

export const loginHttpCall = (data: LoginParams) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.LOGIN,
    payload: {
      url: endpoints.public.login,
      method: 'post',
      body: data,
      useBearerToken: true,
    },
  });

export const logoutHttpCall = () =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.LOGOUT,
    payload: {
      url: endpoints.private.logout,
      method: 'post',
      useBearerToken: true,
    },
  });

export const getUserProfileHttpCall = () =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.GET_USER_PROFILE,
    payload: {
      url: endpoints.private.getUserProfile,
      method: 'get',
      useBearerToken: true,
    },
  });

export const updateUserProfileHttpCall = (params: UserProfileParams | any) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.UPDATE_USER_PROFILE,
    payload: {
      url: endpoints.private.updateUserProfile,
      method: 'post',
      useBearerToken: true,
      body: params,
    },
  });

export const saveUserProfileEmailSignatureHttpCall = (params: UserProfileEmailSignature) =>
  performHttpCall({
    correlationKey: CORRELATION_IDS.SAVE_PROFILE_EMAIL_SIGNATURE,
    payload: {
      url: endpoints.private.saveProfileEmailSignature,
      method: 'post',
      useBearerToken: true,
      body: params,
    },
  });
