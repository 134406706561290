import React, { useState } from 'react';
import { RichTextEditor } from '@/components/organisms/RichTextEditor';

const RichTextEditorPreview = () => {
  const [paragraphValue, setParagraphValue] = useState('');
  return (
    <div>
      {/*<RichTextEditor
        onMount={paragraphValue}
        onChange={(value) => setParagraphValue(value)}
      />*/}
    </div>
  );
};

export { RichTextEditorPreview };
