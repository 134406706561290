import { FileUploaderParams, UserListFilterParams } from '@/types';
import { store } from '@/redux/store';
import {
  CORRELATION_IDS,
  fileUploadHttpCall,
  getFilterStaffMembersDropdownHttpCall,
  getMediafiles,
  getSingleActivityEmailHttpCall,
} from '@/redux/reducers/http';
import { clearHttpStateObject } from '@/redux/actions';

export function getFilterStaffMembers(params: UserListFilterParams) {
  store.dispatch(getFilterStaffMembersDropdownHttpCall(params));
}

export function uploadFile(params: FileUploaderParams) {
  store.dispatch(fileUploadHttpCall(params));
}

export function clearUploadFileResponse() {
  clearHttpStateObject(CORRELATION_IDS.UPLOAD_FILE);
}

export function getSingleActivityEmail(activityEmailId: string) {
  store.dispatch(getSingleActivityEmailHttpCall(activityEmailId));
}
export function getUploadedMediafiles() {
  store.dispatch(getMediafiles());
}
