import { css } from '@emotion/css';

export const useSettingTabPanelStyles = () => {
  return {
    root: css`
      // position: fixed;
      // top: 64.3px;
      display: flex;
    `,
  };
};
