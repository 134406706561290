import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { JobItem, JobProgressItem, ProgressItem } from '@/types';
import {
  clearUpdateProgressList,
  openConfirmationDialog,
  moveJob,
  getJobs,
  sendJobDrip,
  clearSendJobDripResponse,
  updateJobProgressList,
} from '@/redux/actions';
import { useAppSelector } from '@/hooks';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import toast from 'react-hot-toast';
import { InstallPopUP } from '@/components/organisms/Jobs/InstallPopUP';
import { useJobListType } from '@/hooks/custom';

interface JobColumnProps {
  disabled?: boolean;
  jobId?: JobItem['id'];
  progressItem?: JobProgressItem;
  active?: boolean;
  statusChangeable?: boolean;
  oldProgressBars?: string[];

  onUpdateStart?(targetId: any): void;

  onUpdateFinished?(): void;

  onSuccess?(progressBars: string[]): void;

  jobData?: JobItem;
  jobProgressBars?: JobProgressItem[];
  isLastItem?: boolean;

  reloadList?(): void;
}

const JobColumn: React.FC<JobColumnProps> = (props) => {
  const {
    progressItem,
    jobId,
    active,
    disabled,
    oldProgressBars,
    onSuccess,
    onUpdateStart,
    onUpdateFinished,
    jobProgressBars,
  } = props;
  const [updateStatusLoading, setUpdateStatusLoading] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(active ?? false);
  const updateProgressListItems = useAppSelector(selectHttpState)[CORRELATION_IDS.UPDATE_JOB_PROGRESS_LIST];
  const jobListTypeSelection = useJobListType();
  const [targetId, setTargetId] = useState<JobItem['id'] | null>(null);
  const [targetProgressName, setTargetProgressName] = useState<string | null | undefined>(null);

  const getJobList = () =>
    getJobs({
      limit: 10,
      page: 1,
      type: jobListTypeSelection?.currentSelected,
    });
  const updateStatus = (active: boolean | null = null) => {
    if (!props?.statusChangeable) {
      return;
    }

    const __active = active !== null ? active : !isActive;
    let data: ProgressItem = {
      id: jobId,
    };

    if (__active) {
      // data.new_progress_bar = progressItem?.id;
      data.old_progress_bars = oldProgressBars;
    } else {
      data.old_progress_bars = [];

      [...(oldProgressBars ?? [])]?.forEach((item) => {
        if (progressItem?.id !== item) {
          data.old_progress_bars.push(item);
        }
      });

      const oldProgressBarLastIndex = data?.old_progress_bars?.length - 1;
      if (oldProgressBarLastIndex > -1) {
        // data.new_progress_bar = data.old_progress_bars[data?.old_progress_bars?.length - 1] ?? null;
        data.old_progress_bars.splice(oldProgressBarLastIndex, 1);
      }
    }

    data.new_progress_bar = progressItem?.id;

    if (data.old_progress_bars?.length === 0) {
      if (jobProgressBars && jobProgressBars?.length > 0) {
        const index = jobProgressBars?.findIndex((jpb) => jpb?.name === 'Fresh');
        if (index > -1) {
          data.old_progress_bars.push(jobProgressBars[index]?.id);
        }
      }
    }

    setTargetId(jobId);
    setUpdateStatusLoading(true);
    updateJobProgressList(data);

    if (onUpdateStart) {
      onUpdateStart(jobId);
    }

    setIsActive(__active);
  };

  useEffect(() => {
    if (updateProgressListItems?.success && updateStatusLoading && targetId === jobId) {
      setUpdateStatusLoading(false);

      if (updateProgressListItems?.data?.shouldReload) {
        getJobList();
      } else {
        if (onSuccess) {
          const newActiveProgressList = updateProgressListItems?.data ?? [];
          const progressItems: string[] = [];

          newActiveProgressList?.forEach((item: ProgressItem) => {
            if (item?.id) {
              progressItems?.push(item?.id);
            }
          });

          setTargetProgressName(null);
          onSuccess(progressItems);
        }

        if (onUpdateFinished) {
          onUpdateFinished();
        }
      }

      clearUpdateProgressList();
    }

    if (updateProgressListItems?.error && updateStatusLoading && targetId === jobId) {
      toast.error('Failed to update the status!');
      setIsActive(!isActive);
      if (onUpdateFinished) {
        onUpdateFinished();
      }

      setTargetProgressName(null);
      clearUpdateProgressList();
    }
  }, [updateProgressListItems, updateStatusLoading, targetId, jobId]);

  useEffect(() => {
    setIsActive(active ?? false);
  }, [active]);

  return (
    <>
      <td
        className={classNames({
          active: isActive,
          lostLeads: disabled,
        })}
        onClick={() => {
          if (progressItem) {
            setTargetProgressName(progressItem?.name);

            if (props?.isLastItem && !isActive) {
              openConfirmationDialog({
                message: 'Would you like to move to completed job section?',
                type: 'success',
                cancelType: 'error',
                confirmLabel: 'Yes',
                cancelLabel: 'No',
                textCenter: 'center',
                onClickCancel() {},
                onClickConfirm() {
                  updateStatus();
                },
              });

              return;
            }

            updateStatus();
          }
        }}
      >
        <button>{progressItem?.name}</button>
      </td>
    </>
  );
};

export { JobColumn };
