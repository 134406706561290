import { css } from '@emotion/css';
import { colors, themeParser, useAppTheme } from '@/theme';

export const useNavigationTabPanelStyles = () => {
  const theme = useAppTheme();

  return {
    tabItems: css`
      background: ${themeParser(colors.tabItem.light, colors.tabItem.dark, theme)};
      margin: 0;
      padding: 0;
      box-shadow:
        0 2px 2px 0 rgb(0 0 0 / 14%),
        0 3px 1px -2px rgb(0 0 0 / 12%),
        0 1px 5px 0 rgb(0 0 0 / 20%);
    `,
    tabItem: css`
      display: inline-flex;
      text-align: center;
      line-height: 45px;
      padding: 0;
      margin: 0;
      text-transform: capitalize;
      width: 14.285714285714%;
      margin-left: auto;
      left: auto;
      right: auto;
      height: 45px;
      @media (max-width: 1140px) {
        min-width: 16.2%;
        width: auto;
      }
      @media (max-width: 1019px) {
        min-width: 24.999999999%;
      }
      @media (max-width: 767px) {
        min-width: 24.999999999%;
        line-height: 38px;
        height: 38px;
      }
      @media (max-width: 600px) {
        min-width: 50%;
      }
    `,
    tabText: css`
      color: ${themeParser(colors.tabText.light, colors.tabText.dark, theme)};
      border-bottom: none;
      border-left: 1px solid ${themeParser(colors.borderLeftColor.light, colors.borderLeftColor.dark, theme)};
      border-bottom: 1px solid ${themeParser('#9c9696', colors.borderLeftColor.dark, theme)};
      display: block;
      width: 100%;
      height: 100%;
      padding: 0 24px;
      font-size: 14px;
      cursor: pointer;
      transition: color .28s ease, background-color .28s ease;
      @media (max-width: 767px){
        padding: 0 14px;
        font-size: 12px;
      }
      :hover {
        color: ${themeParser(colors.tabTextActive.light, colors.tabTextActive.dark, theme)};
        font-weight: 600;
      },
    `,
    active: css`
      color: ${themeParser(colors.tabTextActive.light, colors.tabTextActive.dark, theme)} !important;
      font-weight: 600;
      font-size: 15px;
    `,
  };
};
