import { Loader } from '@/components/atoms/Loader';
import { DataTable } from '@/components/organisms/DataTable';
import { useAppSelector } from '@/hooks';
import { clearUpdateIpDetails, updateIpDetails } from '@/redux/actions';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { useIpAddressStyles } from '@/static/stylesheets/molecules/IP';
import { IpListParams } from '@/types';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import classNames from 'classnames';
import { convertToNumber } from '@/utils';
import OverlaySpinner from '@/components/atoms/Loader/OverlaySpinner';

interface IpAddressGridProps {
  hideSearch: boolean;
  search: string;

  onChangeSearch(value: string | null): void;

  currentPage: number;
  setCurrentPage(value: number): void;
}

interface DescriptionItemProps {
  data?: IpListParams;

  onChange?(value: string): void;

  onSave?(data: IpListParams): void;
}

const DescriptionItem: React.FC<DescriptionItemProps> = (props) => {
  const { data, onChange, onSave } = props;
  console.log('data', data);
  const [disableButton, setDisableButton] = useState(true);
  const [error, setError] = useState(false);
  const fetchUpdateIpResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.UPDATE_IP_CHILDREN];

  const [value, setValue] = useState(data?.description ?? '');

  const handleClickSave = () => {
    if (error) {
      setDisableButton(true);
      setValue(data?.description ?? '');
      setError(false);
      return;
    }

    if (onSave && !error) {
      if (onChange) {
        onChange(value);
      }
      onSave({
        id: data?.id,
        description: value,
      });
      setDisableButton(true);
    }
  };

  useEffect(() => {
    setValue(data?.description ?? '');
  }, [data?.description]);

  useEffect(() => {
    if (fetchUpdateIpResponse?.success) {
      setDisableButton(true);
    }
    if (fetchUpdateIpResponse?.error) {
      setDisableButton(true);
    }
  }, [fetchUpdateIpResponse]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        width: '100%',
        alignItems: 'center',
        paddingRight: '10px',
      }}
    >
      <input
        className={classNames({
          input_field: !disableButton,
        })}
        value={value}
        placeholder='Enter description'
        disabled={disableButton}
        style={{ border: disableButton ? 'none' : '', minWidth: '300px' }}
        onChange={(e) => {
          setError(false);
          // if (!e.target.value || e.target.value?.trim() === "") {
          //   setError(true);
          // }
          setValue(e?.target?.value);
        }}
        onKeyDown={(e) => {
          if (e?.keyCode === 13) {
            handleClickSave();
          }
        }}
      />
      {data?.editable && (
        <>
          {disableButton && (
            <button style={{ marginLeft: 8 }} onClick={() => setDisableButton(false)}>
              <EditIcon />
            </button>
          )}

          {!disableButton && (
            <button style={{ marginLeft: 8 }} onClick={handleClickSave}>
              <SaveIcon />
            </button>
          )}

          {!disableButton && (
            <button
              onClick={() => {
                setDisableButton(true);
                setValue(data?.description ?? '');
                setError(false);
              }}
              className={'close'}
            >
              <CloseIcon />
            </button>
          )}
        </>
      )}
    </div>
  );
};

const IpAddressGrid: React.FC<IpAddressGridProps> = (props) => {
  const { hideSearch, search, onChangeSearch, currentPage, setCurrentPage } = props;
  const classes = useIpAddressStyles();
  const fetchIpListResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_IP_CHILDREN];
  const fetchIpListSearchResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.SEARCH_IP_CHILDREN];
  const fetchUpdateIpResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.UPDATE_IP_CHILDREN];
  const [tergetId, setTergetId] = useState<IpListParams['id'] | null>(null);
  const totalPages = convertToNumber(fetchIpListSearchResponse?.data?.last_page);

  useEffect(() => {
    if (fetchUpdateIpResponse?.success && tergetId === fetchUpdateIpResponse?.payload?.ip_id) {
      clearUpdateIpDetails();
      setTergetId(null);

      toast.success('Saved successfully!');
    }
    if (fetchUpdateIpResponse?.error) {
      toast.error(fetchUpdateIpResponse?.error?.data?.message);
    }
  }, [fetchUpdateIpResponse, tergetId]);
  const [ipListData, setIpListData] = useState<any>([]);

  const updateDescription = (index: number, value: string) => {
    const newData = [...ipListData];
    newData[index] = {
      ...newData[index],
      description: value,
    };
    setIpListData(() => newData);
  };
  const saveDescription = (item: IpListParams) => {
    // if (!item?.description) return;

    const data: IpListParams = {};
    data.ip_id = item?.id;
    data.description = item?.description;
    updateIpDetails(data);
    setTergetId(item?.id);
  };

  useEffect(() => {
    if (fetchIpListSearchResponse?.success) {
      setIpListData(() => fetchIpListSearchResponse?.data?.data ?? []);
    }
  }, [fetchIpListSearchResponse]);

  useEffect(() => {
    if (fetchIpListResponse?.success) {
      setIpListData(() => fetchIpListResponse?.data ?? []);
    }
  }, [fetchIpListResponse]);

  return (
    <>
      {!hideSearch && (
        <div className={classes.gridSearchContainer}>
          <span>Search</span>
          <input
            type={'search'}
            placeholder={'Enter search'}
            value={search}
            onChange={(e) => {
              const value = e?.target?.value;
              onChangeSearch(value === '' ? null : value);
            }}
          />
        </div>
      )}

      <div className={classes.gridContainer} style={hideSearch ? { minHeight: '76vh' } : {}}>
        {fetchIpListResponse?.loading ? (
          <Loader />
        ) : (
          <>
            <OverlaySpinner show={fetchIpListSearchResponse?.loading} />
            {ipListData && ipListData?.length > 0 ? (
              <Box
                sx={{
                  paddingTop: '20px',
                  '& .MuiTableCell-root': {
                    padding: '15px !important',
                  },
                  width: '100%',
                  display: 'inline-block',
                }}
              >
                <DataTable
                  heads={[
                    { label: 'IP Address', align: 'right', width: '50%' },
                    { label: 'Description', align: 'left', width: '50%' },
                  ]}
                  rows={[
                    ...ipListData?.map((item: IpListParams, index: number) => [
                      { label: item?.ip, align: 'right' },
                      {
                        label: (
                          <DescriptionItem
                            data={item}
                            onChange={(value) => {
                              updateDescription(index, value);
                            }}
                            onSave={(data) => {
                              saveDescription(data);
                            }}
                          />
                        ),
                        align: 'left',
                      },
                    ]),
                  ]}
                  hasPagination={hideSearch ? true : false}
                  paginationProps={{
                    count: totalPages,
                    currentPage,
                    onChange(value) {
                      setCurrentPage(value);
                    },
                  }}
                />
              </Box>
            ) : (
              <Box
                className={'empty'}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100px',
                }}
              >
                No Data found
              </Box>
            )}
          </>
        )}
      </div>
    </>
  );
};

export { IpAddressGrid };
