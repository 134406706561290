import { css } from '@emotion/css';
import { colors, themeParser, useAppTheme } from '@/theme';

export const useProfileStyles = () => {
  const theme = useAppTheme();

  return {
    root: css`
      margin: 40px 20px;
      button {
        color: ${themeParser(colors.body.light, colors.black.default, theme)};
      }
    `,
    form: css`
      align-items: center;
      margin-bottom: 20px;
      .MuiGrid-item {
        margin-bottom: 25px;
      }
    `,
    fileUpload: css`
      display: none;
    `,
    inputField: css`
      border-bottom: 1px solid #3e3e3e !important;
      background: transparent;
      padding: 14px;
      border: none;
      outline: none;
      width: 100%;
    `,
    fileInputBody: css`
      position: relative;
      display: flex;
      align-items: center;
      button {
        padding: 14px 20px;
        width: 190px;
        margin: 0;
        font-weight: 600;
        cursor: pointer;
        color: ${themeParser(colors.body.light, colors.black.default, theme)};
        box-shadow:
          0 3px 3px 0 rgb(0 0 0 / 14%),
          0 1px 7px 0 rgb(0 0 0 / 12%),
          -1px 1px 1px -1px rgb(0 0 0 / 19%);
        background-color: #616161;
        border: none;
        border-radius: 2px;
      }
      button:hover {
        background: #2196f3 !important;
      }
    `,
    signature: css`
      h4 {
        margin: 0;
        padding: 15px 0px;
        font-weight: 500;
        color: ${colors.primary};
        cursor: pointer;
      }
    `,
    group: css`
      display: flex;
      align-items: center;
      flex-direction: row !important;
      margin-top: 10px;
      justify-content: center;
      color: ${themeParser(colors.textInput.labelColor.light, colors.textInput.labelColor.dark, theme)};
      label {
        margin: 0px 1px;
      }
    `,
    textEditBody: css`
      .MuiDialog-paper,
      .ql-toolbar {
        padding: 14px 10px;
        border-radius: 0;
        background: ${themeParser(colors.body.light, colors.body.dark, theme)};
      }
      .mantine-RichTextEditor-root {
        border: none;
      }
      .mantine-ActionIcon-root {
        background: transparent;
        border-color: ${colors.primary};
        color: ${colors.primary};
      }
      h6 {
        color: ${colors.primary};
        text-align: left;
        font-size: 16px;
        margin: 8px 0px;
        width: 100vh;
      }
      .mantine-RichTextEditor-toolbarGroup {
        margin: 8px 2px;
      }
      .quill {
        height: 300px;
        overflow-y: scroll;
      }
      .MuiDialogActions-root button {
        margin-top: 20px;
        color: ${themeParser(colors.body.light, colors.black.default, theme)};
      }
      .MuiGrid-item {
        overflow: hidden;
      }
    `,
  };
};
