import React from 'react';
import { TasksGrid, TasksFilterSidebar } from '@/components/molecules/Tasks';
import { useTaskStyles } from '@/static/stylesheets/molecules/Tasks';

interface TasksContentProps {}

const TasksContent: React.FC<TasksContentProps> = () => {
  const classes = useTaskStyles();

  return (
    <div className={classes.tasksContentContainer}>
      <TasksFilterSidebar />
      <TasksGrid />
    </div>
  );
};

export { TasksContent };
