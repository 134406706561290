import React, { useEffect } from 'react';
import { useTaskStyles } from '@/static/stylesheets/molecules/Tasks';
import FlagIcon from '@mui/icons-material/Flag';
import { Dialog, Grid } from '@mui/material';
import { TextInput } from '@/components/atoms/TextInput';
import { MultiSelect } from '@/components/atoms/MultiSelect';
import { DatePicker } from '@/components/atoms/DatePicker';
import { Button } from '@/components/atoms/Button';
import { useAppSelector, useComponentState, useInput } from '@/hooks';
import { TaskItem, TaskPriorityIndex, TaskTypeParams, UserParams } from '@/types';
import classNames from 'classnames';
import { apiClient, endpoints } from '@/api';
import toast from 'react-hot-toast';
import { alertMessages, priorityItems } from '@/config';
import { TimePicker } from '@/components/atoms/TimePicker';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { setTaskEditFormData } from '@/redux/actions';
import { getTaskPriorityIndex } from '@/utils';

interface TaskParametersProps {
  taskData?: TaskItem | null;

  onChangePriority?(): void;
  reloadData?(): void;
}

const TaskParameters: React.FC<TaskParametersProps> = (props) => {
  const classes = useTaskStyles();
  const taskData = props?.taskData;

  const staffMemberList = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_TASK_STAFF_MEMBERS];
  const staffMembers: UserParams[] = staffMemberList?.data?.data ?? [];

  const taskTypeList = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_TASK_TYPES];
  const taskTypes: TaskTypeParams[] = taskTypeList?.data?.data ?? [];

  const {
    state: { editDialogOpen, priority, selectedTaskTypes },
    setState,
  } = useComponentState<{
    editDialogOpen: boolean;
    priority: TaskItem['priority'] | null;
    selectedTaskTypes: string[];
  }>({
    editDialogOpen: false,
    priority: null,
    selectedTaskTypes: [],
  });

  const {
    value: selectedAssignedTo,
    setValue: setSelectedAssignedTo,
    bind: bindSelectedAssignedTo,
    error: selectedAssignedToError,
  } = useInput('', {
    required: 'Select team member!',
  });

  const {
    value: selectedPriority,
    setValue: setSelectedPriority,
    bind: bindSelectedPriority,
    error: selectedPriorityError,
  } = useInput('', {
    required: 'Please select priority!',
  });

  const {
    value: selectedDueDate,
    setValue: setSelectedDueDate,
    bind: bindSelectedDueDate,
    error: selectedDueDateError,
  } = useInput('', {
    required: 'Please input due date!',
  });

  const {
    value: selectedDueTime,
    setValue: setSelectedDueTime,
    bind: bindSelectedDueTime,
    error: selectedDueTimeError,
  } = useInput('', {
    required: 'Please input due time!',
  });

  const { setError: setSelectedTaskTypeError, error: selectedTaskTypeError } = useInput('', {
    validate() {
      if (selectedTaskTypes?.length === 0) {
        return 'Please select task types!';
      }
      return true;
    },
  });

  const handleOpenEditDialog = () => setState({ editDialogOpen: true });
  const handleCloseEditDialog = () => setState({ editDialogOpen: false });

  const changePriority = () => {
    if (taskData && priority) {
      const currentPriorityIndex = priorityItems.indexOf(priority);
      let nextPriorityIndex: TaskPriorityIndex = '0';

      switch (currentPriorityIndex) {
        case 0:
          nextPriorityIndex = '1';
          break;
        case 1:
          nextPriorityIndex = '2';
          break;
        case 2:
          nextPriorityIndex = '3';
          break;
        case 3:
          nextPriorityIndex = '0';
          break;
      }

      apiClient
        .patch({
          method: 'patch',
          url: endpoints.private.changeTaskPriority(taskData?.id),
          useBearerToken: true,
          data: {
            priority: nextPriorityIndex,
          },
        })
        .then(() => {
          setState({
            priority: priorityItems[Number(nextPriorityIndex)],
          });

          setSelectedPriority(nextPriorityIndex);

          if (props?.onChangePriority) {
            props?.onChangePriority();
          }
        })
        .catch(() => {
          toast.error(alertMessages.tasks.taskChangePriorityError);
        });
    }
  };

  const saveTask = () => {
    selectedAssignedToError.check();
    selectedPriorityError.check();
    selectedDueDateError.check();
    selectedDueTimeError.check();
    selectedTaskTypeError.check();

    if (
      selectedAssignedToError.check() ||
      selectedPriorityError.check() ||
      selectedTaskTypeError.check() ||
      selectedDueDateError.check() ||
      selectedDueTimeError.check()
    ) {
      toast.error(alertMessages.tasks.taskParameterSubmitValidationError);
      return;
    }

    setTaskEditFormData({
      priority: selectedPriority,
      dueDate: selectedDueDate,
      dueTime: selectedDueTime,
      assignedTo: selectedAssignedTo,
      taskTypes: selectedTaskTypes,
    });

    handleCloseEditDialog();
  };

  useEffect(() => {
    const taskTypes: string[] = [];
    taskData?.task_types?.forEach((taskType) => {
      if (taskType?.id) {
        taskTypes.push(taskType?.id);
      }
    });

    setSelectedAssignedTo(taskData?.assigned_to?.id);
    setSelectedDueDate(taskData?.due_datetime?.date);
    setSelectedDueTime(taskData?.due_datetime?.timestamp);
    setSelectedPriority(getTaskPriorityIndex(taskData?.priority));
    setState({
      selectedTaskTypes: taskTypes,
      priority: taskData?.priority,
    });
  }, [taskData]);

  return (
    <>
      <div className={'taskParametersContainer'}>
        {priority && (
          <div
            className={classNames({
              item: true,
              'no-priority': priority === 'No Priority',
              'low-priority': priority === 'Low Priority',
              'medium-priority': priority === 'Medium Priority',
              'high-priority': priority === 'High Priority',
            })}
            onClick={changePriority}
          >
            <FlagIcon />
            {priority}
          </div>
        )}
        <div className={'item'}>{taskData?.assigned_to?.name}</div>
        <div className={'item'}>
          {taskData?.task_types ? taskData?.task_types[0]?.task_type_name : ''}
          {taskData?.task_types && taskData?.task_types?.length > 1 ? '...' : ''}
        </div>
        <div className={'item'}>{`Date Created: ${taskData?.date_created}`}</div>
        <div className={'item'}>{`Days Active: ${taskData?.days_active} Days`}</div>
        <div className={'item'}>{`Due Date: ${taskData?.due_datetime?.date}`}</div>
        <div className={'item'}>{`Total Est. Time: ${taskData?.estimated_hours} Hours`}</div>
        <div className={'item'}>{`Total Hours Worked: ${taskData?.actual_hours_taken}`}</div>

        <div className={'edit-action'}>
          <span onClick={handleOpenEditDialog}>Edit</span>
        </div>
      </div>

      <Dialog
        open={editDialogOpen}
        onClose={handleCloseEditDialog}
        classes={{
          paper: classes.activeTaskDialogPaper,
        }}
        hideBackdrop={true}
      >
        <div className={classes.activeTaskDialogTitleBar}>EDIT TASK PARAMETERS</div>
        <div className={'content'}>
          <div className={'form-field'} style={{ marginTop: selectedPriority ? '20px' : 'auto' }}>
            <TextInput
              select={true}
              variant={'standard'}
              options={[
                {
                  title: 'No Priority',
                  value: '0',
                },
                {
                  title: 'Low Priority',
                  value: '1',
                },
                {
                  title: 'Medium Priority',
                  value: '2',
                },
                {
                  title: 'High Priority',
                  value: '3',
                },
              ]}
              fullWidth={true}
              label={selectedPriority ? undefined : 'Priority'}
              error={selectedPriorityError.error}
              errorText={selectedPriorityError.message}
              {...bindSelectedPriority}
            />
          </div>
          <div className={'form-field'} style={{ marginTop: selectedAssignedTo ? '25px' : 'auto' }}>
            <TextInput
              select={true}
              variant={'standard'}
              options={[
                {
                  title: 'Assigned To',
                  value: '--empty--',
                  disabled: true,
                },
                ...(staffMembers && staffMembers?.length > 0
                  ? staffMembers?.map((item) => ({
                      title: `${item?.first_name ?? ''} ${item?.last_name ?? ''}`,
                      value: item?.id,
                    }))
                  : []),
              ]}
              fullWidth={true}
              label={selectedAssignedTo ? undefined : 'Assigned To'}
              error={selectedAssignedToError.error}
              errorText={selectedAssignedToError.message}
              {...bindSelectedAssignedTo}
            />
          </div>
          <div className={'form-field'} style={{ marginTop: selectedTaskTypes?.length > 0 ? '20px' : 'auto' }}>
            <MultiSelect
              options={[
                ...(taskTypes && taskTypes?.length > 0
                  ? taskTypes?.map((item) => ({
                      label: item?.task_type_name ?? '',
                      value: item?.id,
                    }))
                  : []),
              ]}
              value={selectedTaskTypes}
              onChange={(items) => {
                setState({ selectedTaskTypes: items });
                selectedTaskTypeError.check();
              }}
              label={selectedTaskTypes?.length === 0 ? 'Type of task' : undefined}
              multiple={true}
              rootSx={{
                marginLeft: 0,
                '& .MuiInputLabel-root': {
                  marginLeft: '-15px',
                },
              }}
              error={selectedTaskTypeError.error}
              errorText={selectedTaskTypeError.message}
            />
          </div>

          <div
            className={'form-field form-field date-picker-input'}
            style={{ marginTop: selectedDueDate ? '10px' : 'auto' }}
          >
            <DatePicker
              label={selectedDueDate ? undefined : 'Due Date'}
              fullWidth={true}
              value={bindSelectedDueDate.value}
              onChange={(value) => {
                bindSelectedDueDate.onChange({
                  target: {
                    value,
                  },
                } as any);
              }}
              error={selectedDueDateError.error}
              errorText={selectedDueDateError.message}
            />
          </div>
          <div
            className={'form-field form-field date-picker-input'}
            style={{ marginTop: selectedDueDate ? '15px' : 'auto' }}
          >
            <TimePicker
              label={selectedDueTime ? undefined : 'Due Time'}
              value={bindSelectedDueTime.value}
              onChange={(value) => {
                bindSelectedDueTime.onChange({
                  target: {
                    value,
                  },
                } as any);
              }}
              error={selectedDueTimeError.error}
              errorText={selectedDueTimeError.message}
            />
          </div>

          <div className={'form-field'} style={{ marginTop: '20px' }}>
            <Button title={'Ok'} color={'success'} fullWidth={true} onClick={saveTask} />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export { TaskParameters };
