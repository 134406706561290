import React from 'react';
import { colors } from '@/theme/colors';
import { Box } from '@mui/material';
import { IconButton } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';

interface ActionButtonProps {
  icon: 'delete' | 'edit' | 'lock' | 'done' | 'ignore';
  onClick?(): void;
}

export const ActionButton: React.FC<ActionButtonProps> = (props) => {
  const { icon, onClick } = props;

  let iconComponent = null;

  switch (icon) {
    case 'delete':
      iconComponent = <DeleteForeverIcon style={{ color: colors.red.default, fontSize: 19 }} />;
      break;
    case 'edit':
      iconComponent = <EditIcon style={{ color: colors.primary, fontSize: 19 }} />;
      break;
    case 'lock':
      iconComponent = <LockIcon style={{ color: colors.green.default, fontSize: 19 }} />;
      break;
    case 'done':
      iconComponent = <DoneAllIcon style={{ color: colors.green.default, fontSize: 19 }} />;
      break;
    case 'ignore':
      iconComponent = <DoNotDisturbIcon style={{ color: colors.red.default, fontSize: 19 }} />;
      break;
  }

  return (
    <Box
      sx={{
        display: 'inline-block',
        marginRight: '5px',

        '& :last-child': {
          marginRight: 0,
        },
      }}
    >
      <IconButton onClick={onClick} size={'small'}>
        {iconComponent}
      </IconButton>
    </Box>
  );
};
