import React from 'react';
import { Loader } from '@/components/atoms/Loader';

interface TaskGridLoaderProps {
  containerStyle?: React.CSSProperties;
  colSpan?: number;
}

const TaskGridLoader: React.FC<TaskGridLoaderProps> = ({ containerStyle, colSpan = 9 }) => {
  return (
    <>
      <tr>
        <td style={containerStyle} colSpan={colSpan}>
          <Loader />
        </td>
      </tr>
    </>
  );
};

export { TaskGridLoader };
