import { Button, Collapse, Typography } from '@mui/material';
import React, { useState } from 'react';

interface TextWithShowMoreProps {
  text: string;
  maxLength?: number;
}

const TextWithShowMore: React.FC<TextWithShowMoreProps> = ({ text, maxLength = 40 }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // Check if the text length exceeds the specified max length
  const hasMoreText = text.length > maxLength;

  // Toggle the expansion of the text
  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className='text-with-show-more'>
      {/* <p className='transition-height duration-300 ease-in-out'>
        {showFullText ? text : `${text.slice(0, maxLength)}...`}
      </p>
      {hasMoreText && (
        <p className='text-sky-500 hover:text-sky-600 ml-3' onClick={handleShowMoreClick}>
          {showFullText ? 'Show Less ' : 'Show More '}&rarr;
        </p>
      )} */}
      <Collapse in={isExpanded} timeout='auto' unmountOnExit>
        <Typography variant='body1'>{text}</Typography>
      </Collapse>
      {!isExpanded && (
        <>
          <Typography variant='body1'>{text.slice(0, maxLength)}...</Typography>
          {hasMoreText && (
            <Button size='small' onClick={handleToggle}>
              Show More
            </Button>
          )}
        </>
      )}
      {isExpanded && (
        <Button size='small' onClick={handleToggle}>
          Show Less
        </Button>
      )}
    </div>
  );
};

export default TextWithShowMore;
