export const alertMessages = {
  common: {
    file: {
      deleteSuccess: 'File deleted!',
      deleteError: 'Failed to delete file!',
    },
  },
  generalSettings: {
    pricing: {
      saveSuccess: 'Saved successfully!',
      saveError: 'Failed to save!',
      createSuccess: 'Location created successfully!',
      createError: 'Failed to create the location!',
      deleteSuccess: 'Location deleted successfully!',
      deleteError: 'Failed to delete the location!',
    },
    leadClientJobSettings: {
      saveSuccess: 'Saved successfully!',
      saveError: 'Failed to save!',
      submitValidationError: 'Please fill up the required fields!',
    },
    sourceList: {
      deleteConfirmation: 'Are you sure to remove this item?',
      saveSuccess: 'Item saved successfully!',
      saveError: 'Failed to save the item!',
      deleteSuccess: 'Item deleted successfully!',
      deleteError: 'Failed to delete the item!',
    },
    apiKeyList: {
      deleteConfirmation: 'Are you sure to remove this item?',
      saveSuccess: 'Item saved successfully!',
      saveError: 'Failed to save the item!',
      deleteSuccess: 'Item deleted successfully!',
      deleteError: 'Failed to delete the item!',
    },
    jobProgressBars: {
      deleteConfirmation: 'Are you sure to remove this item?',
      saveSuccess: 'Item saved successfully!',
      saveError: 'Failed to save the item!',
      deleteSuccess: 'Item deleted successfully!',
      deleteError: 'Failed to delete the item!',
    },
    smtpSettings: {
      submitValidationError: 'Please fill all the required fields!',
      sendTestMailValidationError: 'Please fill all the required fields!',
      saveSuccess: 'Smtp settings saved successfully!',
      saveError: 'Failed to save the smtp settings!',
      testMailSendSuccess: 'Test mail sent successfully!',
      testMailSendError: 'Failed to send the test mail!',
    },
    emailTemplates: {
      submitValidationError: 'Please fill all the required fields!',
      leadEmailSendSuccess: 'Email sent successfully!',
      leadEmailSendError: 'Failed to send email!',
      previewSubmitValidationError: 'Please fill all the required fields!',
      testEmailSendSuccess: 'Test mail sent successfully!',
      testEmailSendError: 'Failed to send the test email!',
      updateSuccess: 'Email template updated successfully!',
      updateError: 'Failed to update the email template!',
      createSuccess: 'Email template created successfully!',
      createError: 'Failed to create the email template!',
      deleteSuccess: 'Email template deleted successfully!',
      deleteError: 'Failed to delete the email template!',
      deleteConfirmation: 'Are you sure you want to remove this email template? There is no way to undo this action!',
    },
    customVariables: {
      submitValidationError: 'Please fill the required fields!',
      createSuccess: 'Successfully created the custom variable!',
      createError: 'Failed to create the custom variable!',
      updateSuccess: 'Successfully updated the custom variable!',
      updateError: 'Failed to update the custom variable!',
      deleteSuccess: 'Custom variable deleted successfully!',
      deleteError: 'Failed to delete the custom variable!',
      deleteConfirm: 'Are you sure you want to remove this custom variable? There is no way to undo this action.!',
    },
    equipments: {
      createSuccess: 'Equipment created successfully!',
      createError: 'Failed to create the equipment!',
      saveSuccess: 'Equipment saved successfully!',
      saveError: 'Failed to save the equipment!',
      deleteSuccess: 'Equipment deleted successfully!',
      deleteError: 'Failed to delete the equipment!',
      submitValidationError: 'Please fill the required fields!',
    },
    ipAddress: {
      public: {
        submitValidationError: 'Please enter ip address!',
        deleteConfirm: 'Are you sure to remove this ip address?',
        saveSuccess: 'Ip address saved successfully!',
        saveError: 'Failed to save the ip address!',
        deleteSuccess: 'Item deleted successfully!',
        deleteError: 'Failed to delete the item!',
      },
      private: {
        submitValidationError: 'Please enter ip address!',
        deleteConfirm: 'Are you sure to remove this ip address?',
        saveSuccess: 'Ip address saved successfully!',
        saveError: 'Failed to save the ip address!',
        deleteSuccess: 'Item deleted successfully!',
        deleteError: 'Failed to delete the item!',
      },
    },
    apiInformation: {
      docusingSubmitValidationError: 'Please fill all the required fields!',
      dripSubmitValidationError: 'Please fill all the required fields!',
      ucrmSubmitValidationError: 'Please fill all the required fields!',
      googleCalendarConnectSuccess: 'Connected successfully!',
      googleCalendarConnectError: 'Failed to connect!',
      googleCalendarDisconnectSuccess: 'Disconnected successfully!',
      googleCalendarDisconnectError: 'Failed to disconnect!',
      docusignConnectSuccess: 'Connected successfully!',
      docusignConnectError: 'Failed to connect!',
      docusignDisconnectSuccess: 'Disconnected successfully!',
      docusignDisconnectError: 'Failed to disconnect!',
      dripConnectSuccess: 'Connected successfully!',
      dripConnectError: 'Failed to connect!',
      dripDisconnectSuccess: 'Disconnected successfully!',
      dripDisconnectError: 'Failed to disconnect!',
      ucrmConnectSuccess: 'Connected successfully!',
      ucrmConnectError: 'Failed to connect!',
      ucrmConnectWrongKeyError: 'You have submitted wrong api key!',
      ucrmDisconnectSuccess: 'Disconnected successfully!',
      ucrmDisconnectError: 'Failed to disconnect!',
    },
    staffMembers: {
      submitValidationError: 'Please fill up all the required fields!',
      createSuccess: 'User created successfully!',
      createError: 'Failed to create the user!',
      updateSuccess: 'User saved successfully!',
      updateError: 'Failed to save the user!',
      deleteSuccess: 'Staff member deleted successfully!',
      deleteError: 'Failed to delete the staff member!',
    },
  },
  leads: {
    submitValidationError: 'Please fill all the required fields!',
    leadCreateSuccess: 'Lead created successfully!',
    leadCreateDuplicateEmailOrPhone: 'The email/phone has already been taken',
    leadCreateError: 'Failed to create the lead!',
    leadUpdateSuccess: 'Lead saved successfully!',
    leadUpdateDuplicateEmail: 'The email has already been taken!',
    leadUpdateDuplicatePhone: 'The phone has already been taken!',
    leadUpdateError: 'Failed to save the lead!',
    proposalSubmitValidationError: 'Please fill all the required fields in the proposal tab!',
    contractSubmitValidationError: 'Please check the required fields in the contract tab!',
    ipSubmitValidationError: 'Please check the fields in the ip info tab!',
    vmCountUpdateSuccess: 'Request successfully updated!',
    vmCountUpdateError: 'Failed to update the status!',
    restoreLostLeadSuccess: 'Request successfully updated!',
    restoreLostLeadError: 'Failed to update the status!!',
    deleteLeadSuccess: 'Request successfully Deleted!',
    deleteLeadError: 'Request failed to delete!!',
    customerLeadUpdateSuccess: 'Request successfully updated!',
    customerLeadUpdateError: 'Failed to update the status!',
    customerDetailsUpdateSuccess: 'Request successfully updated!',
    contractSendError: 'Failed to send the contract!',
    docusignSendSuccess: 'Document sent to DocuSign Successfully',
    docusignSendError: 'Failed to send the contract!',
    quickNoteSubmitValidationError: 'Please fill all the required fields!',
    quickNoteCreateSuccess: 'Request successfully updated!',
    quickNoteCreateError: 'Failed to update the note!',
    quickNoteDeleteSuccess: 'Note deleted successfully',
    quickNoteDeleteError: 'Failed to Deleted the note!',
    quickNoteUpdateSuccess: 'Request successfully Updated!',
    quickNoteUpdateError: 'Failed to Updated the status!',
    quickNoteDeleteConfirmation: 'Are you sure you want to remove this Notes? There is no way to undo this action.!',
    surveyDateTimeValidationError: 'Please check the survey date times!',
    installationDateTimeValidationError: 'Please check the installation date times!',
    nextFollowUpDateTimeValidationError: 'Please check the next follow up date times!',
  },
  clients: {
    submitValidationError: 'Please fill all the required fields!',
    clientCreateSuccess: 'Client created successfully!',
    clientCreateDuplicateEmailOrPhone: 'The email/phone has already been taken',
    clientCreateError: 'Failed to create the client!',
    clientUpdateSuccess: 'Client saved successfully!',
    clientUpdateDuplicateEmail: 'The email has already been taken!',
    clientUpdateDuplicatePhone: 'The phone has already been taken!',
    clientUpdateError: 'Failed to save the client!',
    proposalSubmitValidationError: 'Please fill all the required fields in the proposal tab!',
    contractSubmitValidationError: 'Please check the required fields in the contract tab!',
    ipSubmitValidationError: 'Please check the fields in the ip info tab!',
    vmCountUpdateSuccess: 'Request successfully updated!',
    vmCountUpdateError: 'Failed to update the status!',
    restoreDeactivatedClientSuccess: 'Request successfully updated!',
    restoreDeactivatedClientError: 'Failed to update the status!!',
    deleteClientSuccess: 'Request successfully Deleted!',
    deleteClientError: 'Request failed to delete!!',
    customerClientUpdateSuccess: 'Request successfully updated!',
    customerClientUpdateError: 'Failed to update the status!',
    customerDetailsUpdateSuccess: 'Request successfully updated!',
    contractSendError: 'Failed to send the contract!',
    docusignSendSuccess: 'Document sent to DocuSign Successfully',
    docusignSendError: 'Failed to send the contract!',
    quickNoteSubmitValidationError: 'Please fill all the required fields!',
    quickNoteCreateSuccess: 'Request successfully updated!',
    quickNoteCreateError: 'Failed to update the status!',
    quickNoteDeleteSuccess: 'Request successfully Deleted!',
    quickNoteDeleteError: 'Failed to Deleted the status!',
    quickNoteUpdateSuccess: 'Request successfully Updated!',
    quickNoteUpdateError: 'Failed to Updated the status!',
    quickNoteDeleteConfirmation: 'Are you sure you want to remove this Notes? There is no way to undo this action.!',
    surveyDateTimeValidationError: 'Please check the survey date times!',
    installationDateTimeValidationError: 'Please check the installation date times!',
    nextFollowUpDateTimeValidationError: 'Please check the next follow up date times!',
  },
  jobs: {
    jobCreateSuccess: 'Job created successfully!',
    jobCreateError: 'Failed to create the job!',
    jobUpdateSuccess: 'Job updated successfully!',
    jobUpdateError: 'Failed to update the job!',
    quickNoteCreateSuccess: 'Request successfully updated!',
    quickNoteCreateError: 'Failed to update the note!',
    quickNoteDeleteSuccess: 'Note deleted successfully',
    quickNoteDeleteError: 'Failed to Deleted the note!',
    quickNoteUpdateSuccess: 'Request successfully Updated!',
    quickNoteUpdateError: 'Failed to Updated the status!',
    quickNoteDeleteConfirmation: 'Are you sure you want to remove this Notes? There is no way to undo this action.!',
    quickNoteSubmitValidationError: 'Please fill all the required fields!',
    vmCountUpdateSuccess: 'Request successfully updated!',
  },
  tasks: {
    taskListFilterValidationError: 'Please select at least one option to filter tasks!',
    taskDeleteConfirmation: 'Are you sure to delete this task?',
    taskCreateSuccess: 'Task created successfully!',
    taskCreateError: 'Failed to create the task!',
    taskUpdateSuccess: 'Task saved successfully!',
    taskUpdateError: 'Failed to saved the task!',
    taskDeleteSuccess: 'Task deleted successfully!',
    taskDeleteError: 'Failed to delete the task!',
    taskMarkedAsCompletedSuccess: 'Task completed successfully!',
    taskMarkedAsCompletedError: 'Failed to complete task!',
    taskMarkedAsCompletedUnfinishedChildrenError: 'This Task is Running! Task Has Unfinished Children Task(s)',
    taskTimerStartSuccess: 'Timer has started!',
    taskTimerStopSuccess: 'Timer has stopped!',
    taskTimerStartError: 'Failed to start the timer!',
    taskTimerStopError: 'Failed to stop the timer!',
    taskChangePriorityError: 'Failed to change priority!',
    taskParameterSubmitValidationError: 'Please fill all the required fields!',
    childTaskSubmitValidationError: 'Please fill all the required fields!',
    pingAssigneeError: 'Failed to ping!',
  },
};
