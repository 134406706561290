import React from 'react';
import { useClientStyles } from '@/static/stylesheets/organisms';
import { Grid, Skeleton } from '@mui/material';

interface ClientLoaderProps {}

const ClientLoader: React.FC<ClientLoaderProps> = () => {
  const classes = useClientStyles();

  return (
    <>
      <div className={classes.clientLoader}>
        <Grid container spacing={2} className={classes.root}>
          <Grid item sm={12} md={6} lg={7}>
            <div className={'left-header'}>
              <Skeleton animation={'wave'} className={'container-skeleton'} />
            </div>
          </Grid>
          <Grid item sm={12} md={6} lg={5}>
            <div className={'right-header'}>
              <Skeleton animation={'wave'} className={'button-skeleton'} />
              <Skeleton animation={'wave'} className={'button-skeleton'} />
              <Skeleton animation={'wave'} className={'button-skeleton'} />
              <Skeleton animation={'wave'} className={'button-skeleton'} />
              <Skeleton animation={'wave'} className={'button-skeleton'} />
              <Skeleton animation={'wave'} className={'button-skeleton'} />
            </div>
          </Grid>

          <Grid className={classes.trackingContainer} item sm={12} md={12} lg={12}>
            <Grid container spacing={2}>
              <Grid item sm={12} md={12} lg={12}>
                <div className={'tracker-container'}>
                  <Skeleton animation={'wave'} className={'tracker-list-skeleton'} />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export { ClientLoader };
