import { TaskPriority, TaskPriorityIndex } from '@/types';
import { priorityItems } from '@/config';

export function getTaskPriorityIndex(priority?: TaskPriority | null): TaskPriorityIndex {
  let index: TaskPriorityIndex = '0';

  if (priority) {
    switch (priority) {
      case 'No Priority':
        index = '0';
        break;
      case 'Low Priority':
        index = '1';
        break;
      case 'Medium Priority':
        index = '2';
        break;
      case 'High Priority':
        index = '3';
        break;
    }
  }

  return index;
}

export function getTaskPriorityByIndex(index: TaskPriorityIndex): TaskPriority {
  return priorityItems[index];
}
