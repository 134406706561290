import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useCardItemListStyle } from '@/static/stylesheets/organisms';
import Typography from '@mui/material/Typography';
import { TextInput } from '@/components/atoms/TextInput';
import { OptionProps, useInput } from '@/hooks';
import { Loader } from '@/components/atoms/Loader';
import toast from 'react-hot-toast';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { colors } from '@/theme';
import { copyText } from '@/utils';

interface CardItemListProps {
  title?: String;
  items?: {
    title?: string;
    onClickRemove?(): void;
  }[];
  loading?: boolean;

  onNewInput?(value: any): void;

  newInputValidator?: OptionProps['validate'];
  placeholder?: string;
  hasCopyButton?: boolean;
  noDelete?: boolean;
  noInput?: boolean;
}

const FeatureItemList: React.FC<CardItemListProps> = (props) => {
  const classes = useCardItemListStyle();
  const { items, title, newInputValidator, placeholder } = props;

  const {
    value: newItemValue,
    setValue: setNewItemValue,
    error: newInputError,
    bind: bindNewInput,
    reset: resetNewInput,
  } = useInput('', {
    ...(newInputValidator
      ? {
          validate: newInputValidator,
        }
      : {
          required: 'Please enter required field!',
        }),
  });

  const handleKeyDown = (e: any) => {
    if (e.keyCode && e.keyCode === 13 && props.onNewInput) {
      if (newInputError?.check()) {
        toast.error(newInputError?.initialValidationMessage ?? newInputError?.message);
        return;
      }

      props.onNewInput(newItemValue);
      setNewItemValue('');
      setTimeout(() => {
        resetNewInput();
      }, 20);
    }
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.cardHeader}>
          <Typography variant='subtitle1' component='div'>
            {title}
          </Typography>
        </div>

        <List>
          {items &&
            items?.length > 0 &&
            items.map((item, index) => (
              <ListItem
                key={index}
                secondaryAction={
                  <>
                    {props?.hasCopyButton ? (
                      <IconButton
                        edge='end'
                        style={{ color: colors.primary }}
                        onClick={() => {
                          if (item?.title) {
                            copyText(item?.title, () => {
                              toast.success('Copied to the clipboard!');
                            });
                          }
                        }}
                        disabled={props.loading}
                      >
                        <ContentCopyIcon />
                      </IconButton>
                    ) : null}

                    {!props?.noDelete ? (
                      <IconButton
                        edge='end'
                        style={{ color: '#f44336' }}
                        onClick={item?.onClickRemove}
                        disabled={props.loading}
                      >
                        <CloseIcon />
                      </IconButton>
                    ) : null}
                  </>
                }
              >
                <ListItemText primary={item?.title} />
              </ListItem>
            ))}
        </List>

        {props?.loading && <Loader />}

        {!props?.noInput && (
          <div
            style={{
              marginBottom: 10,
            }}
          >
            <TextInput
              variant={'filled'}
              placeholder={placeholder}
              fullWidth={true}
              centerMode={true}
              error={newInputError.error}
              onKeyDown={handleKeyDown}
              {...bindNewInput}
            />
          </div>
        )}
      </div>
    </>
  );
};

export { FeatureItemList };
