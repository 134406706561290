import React, { useEffect, useState } from 'react';
import { Button } from '@/components/atoms/Button';
import Dialog from '@mui/material/Dialog';
import { SearchableTextInput, TextInput } from '@/components/atoms/TextInput';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useSettingDialogStyle } from '@/static/stylesheets/molecules';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { getEquipments } from '@/redux/actions';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { EquipmentParams } from '@/types';
import { useAppSelector } from '@/hooks';

interface CalculatorProps {
  openDialog?: any;

  onClick?(): void;
}

const Calculator: React.FC<CalculatorProps> = (props) => {
  const classes = useSettingDialogStyle();
  const { openDialog, onClick } = props;
  const equipmentList = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_EQUIPMENTS];
  const equipmentsData: EquipmentParams[] = equipmentList?.data?.data ?? [];
  const initialEquipment: EquipmentParams = {
    id: '',
    quantity: '',
    our_cost: '',
    client_cost: '',
  };

  const [equipments, setEquipments] = useState<EquipmentParams[]>([initialEquipment]);

  const onChangeEquipmentItem = (index: number, key: keyof EquipmentParams, value: any) => {
    const __equipments = [...equipments];
    __equipments[index][key] = value;

    if (key === 'id') {
      const __equipmentIndex = equipmentsData?.findIndex((eq) => eq?.id === value);
      if (__equipmentIndex > -1) {
        const __equipment = equipmentsData[__equipmentIndex];
        __equipments[index].quantity = 1;
        __equipments[index].our_cost = __equipment?.our_cost;
        __equipments[index].client_cost = __equipment?.client_cost;
      }
    }
    if (key === 'quantity') {
      const __equipmentIndex = equipments?.findIndex((eq) => eq?.quantity === value);
      if (__equipmentIndex > -1) {
        const __equipment = equipmentsData[__equipmentIndex];
        __equipments[index].our_cost = __equipment?.our_cost * value;
        __equipments[index].client_cost = __equipment?.client_cost * value;
      }
    }
    setEquipments(() => __equipments);
  };

  const deleteEquipment = (index: number) => {
    const __equipments = [...equipments];
    __equipments?.splice(index, 1);
    setEquipments(() => __equipments);
  };

  const addEquipment = () => {
    const __equipments = [...equipments];
    __equipments?.push(initialEquipment);
    setEquipments(() => __equipments);
  };

  const calculateTotalOurCost = (): number => {
    let total = 0;
    equipments?.forEach((item) => {
      total += Number(item?.our_cost);
    });

    return total;
  };
  const calculateTotalClientCost = (): number => {
    let total = 0;
    equipments?.forEach((item) => {
      total += Number(item?.client_cost);
    });

    return total;
  };
  const calculateDifference = (): number => {
    return calculateTotalClientCost() - calculateTotalOurCost();
  };
  const calculateMargin = (): number => {
    return (calculateTotalClientCost() / calculateTotalOurCost()) * 100 - 100 || 0;
  };
  const fetchEquipments = () => {
    getEquipments({
      limit: 'all',
      page: 1,
    });
  };
  useEffect(() => {
    if (openDialog) {
      fetchEquipments();
    }
  }, [openDialog]);
  return (
    <>
      <Dialog
        className={classes.root}
        open={openDialog}
        maxWidth={'md'}
        onClose={onClick}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogTitle className={classes.createLeadsTitle} id='responsive-dialog-title'>
          {'Lookup Equipment Cost'}
        </DialogTitle>
        <DialogContent className='calculator'>
          <DialogContentText className={classes.DialogContent}>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12} sm={6} md={3.5} lg={3.5}>
                <label>Equipment</label>
              </Grid>
              <Grid item xs={12} sm={6} md={2.5} lg={2.5} sx={{ textAlign: 'center' }}>
                <label>Quantity</label>
              </Grid>
              <Grid item xs={12} sm={6} md={2.5} lg={2.5} sx={{ textAlign: 'center' }}>
                <label>Our Cost</label>
              </Grid>
              <Grid item xs={12} sm={6} md={2.5} lg={2.5} sx={{ textAlign: 'center' }}>
                <label>Client Cost</label>
              </Grid>
            </Grid>
            {equipments &&
              equipments?.length > 0 &&
              equipments?.map((item, index) => (
                <Grid key={index} container spacing={2} sx={{ alignItems: 'end' }}>
                  <Grid item xs={12} sm={6} md={3.5} lg={3.5}>
                    <SearchableTextInput
                      placeholder={'Type Product...'}
                      options={[
                        ...equipmentsData.map((equipment: EquipmentParams) => ({
                          label: equipment?.equipment_name,
                          value: equipment?.id,
                        })),
                      ]}
                      value={item?.id}
                      onChange={(value) => onChangeEquipmentItem(index, 'id', value)}
                      loading={equipmentList?.data?.loading}
                      dropdownOptionProps={{
                        style: {
                          padding: '10px',
                          cursor: 'pointer',
                          color: '#2a2a2a',
                        },
                      }}
                      hideArrow={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2.5} lg={2.5}>
                    <TextInput
                      type={'number'}
                      variant={'standard'}
                      placeholder={'Quantity'}
                      fullWidth={true}
                      centerMode={true}
                      value={item?.quantity}
                      error={Number(item?.quantity) < 1}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onChangeEquipmentItem(index, 'quantity', e?.target?.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2.5} lg={2.5}>
                    <TextInput
                      type={'number'}
                      variant={'standard'}
                      placeholder={'Our Cost'}
                      fullWidth={true}
                      centerMode={true}
                      value={item?.our_cost}
                      error={Number(item?.our_cost) < 1}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onChangeEquipmentItem(index, 'our_cost', e?.target?.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2.5} lg={2.5}>
                    <TextInput
                      type={'number'}
                      variant={'standard'}
                      placeholder={'Client Cost'}
                      fullWidth={true}
                      centerMode={true}
                      value={item?.client_cost}
                      error={Number(item?.our_cost) < 1}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onChangeEquipmentItem(index, 'client_cost', e?.target?.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={1} md={1} lg={1} sx={{ textAlign: 'center' }}>
                    <DeleteIcon onClick={() => deleteEquipment(index)} className='delete' />
                  </Grid>
                </Grid>
              ))}
            <Grid container sx={{ mt: 1 }}>
              <Grid item xs={12} sm={12} md={12} lg={12} className={'add'}>
                <Button
                  className={classes.calculatorEquipmentAddButton}
                  onClick={addEquipment}
                  title={'Add Equipment'}
                  fullWidth={true}
                ></Button>
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ pt: 3 }}>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <label>
                  Our Total Cost: <strong>${calculateTotalOurCost().toFixed(0)}</strong>
                </label>
              </Grid>
              <Grid item xs={12} sm={6} md={3.5} lg={3.5} sx={{ textAlign: 'left' }}>
                <label>
                  Client Total Cost: <strong>${calculateTotalClientCost().toFixed(0)} + tax</strong>
                </label>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} sx={{ textAlign: 'right' }}>
                <label>
                  Difference: <strong>${calculateDifference().toFixed(0)} + tax</strong>
                </label>
              </Grid>
              <Grid item xs={12} sm={6} md={2.5} lg={2.5} sx={{ textAlign: 'right' }}>
                <label>
                  Margin: <strong>{calculateMargin().toFixed(2)}%</strong>
                </label>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button fullWidth={true} onClick={onClick} color={'error'} variant={'contained'} title={'CLOSE'} />
        </DialogActions>
      </Dialog>
    </>
  );
};

export { Calculator };
