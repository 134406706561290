import React from 'react';
import { useNavigationTabPanelStyles } from '@/static/stylesheets/organisms';
import { MenuItem } from './MenuItem';

export interface NavigationTabPanelItem {
  label: 'General Settings' | 'Email' | 'Pricing' | 'Equipment' | 'IP Addresses' | 'APIs' | 'Staff Members';
  route: string;
}

export interface NavigationTabPanelProps {
  items?: NavigationTabPanelItem[];
}

const NavigationTabPanel: React.FC<NavigationTabPanelProps> = (props) => {
  const classes = useNavigationTabPanelStyles();
  const { items } = props;

  return (
    <>
      <ul className={classes.tabItems}>{items?.map((menu, index) => <MenuItem key={index} menu={menu} />)}</ul>
    </>
  );
};

export { NavigationTabPanel };
