import React, { useEffect } from 'react';
import { Button } from '@/components/atoms/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useStaffMemberStyle } from '@/static/stylesheets/molecules';
import DialogTitle from '@mui/material/DialogTitle';
import { TextInput } from '@/components/atoms/TextInput';
import { CloseButton } from '@/components/atoms/Button/CloseButton';
import Grid from '@mui/material/Grid';
import { useAppSelector, useInput } from '@/hooks';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { clearUserResetPassword, resetUserPassword } from '@/redux/actions';
import toast from 'react-hot-toast';
import { UserParams } from '@/types';

interface ResetPasswordFormProps {
  open?: any;
  userId?: UserParams['id'];
  onClose?(): void;
}

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = (props) => {
  const classes = useStaffMemberStyle();
  const { open, onClose, userId } = props;

  const changedPassword = useAppSelector(selectHttpState)[CORRELATION_IDS.USER_RESET_PASSWORD];
  const loading = changedPassword?.loading;

  const {
    value: password,
    reset: resetPassword,
    bind: bindPassword,
    error: passwordError,
  } = useInput('', {
    validate(value) {
      if (value === '') {
        return 'Please enter new password!';
      }
      if (value?.length < 8) {
        return 'Your password should be at least 8 characters!';
      }
      return true;
    },
    onChange(value) {
      if (value !== confirmPassword) {
        setConfirmPasswordError('Password does not match!');
      }
    },
  });

  const {
    value: confirmPassword,
    reset: resetConfirmPassword,
    bind: bindConfirmPassword,
    error: confirmPasswordError,
    setError: setConfirmPasswordError,
  } = useInput('', {
    validate(value) {
      if (value !== password) {
        return 'Password does not match!';
      }

      return true;
    },
  });

  const resetFields = () => {
    resetPassword();
    resetConfirmPassword();
  };

  const handleSubmit = () => {
    if (!userId || userId === '') {
      toast.error('Please select a user!');
      return;
    }

    if (passwordError.check() || confirmPasswordError.check()) {
      toast.error('Please fill up the required fields!');
      return;
    }

    resetUserPassword(userId, {
      password: password,
      confirm_password: confirmPassword,
    });
  };

  const handleClickClose = () => {
    if (onClose) {
      onClose();
      resetFields();
    }
  };

  useEffect(() => {
    if (changedPassword?.success) {
      toast.success('Password changed successfully!');
      handleClickClose();
      clearUserResetPassword();
    }

    if (changedPassword?.error) {
      toast.error('Failed to change the password!');
      clearUserResetPassword();
    }
  }, [changedPassword]);

  return (
    <>
      <Dialog
        className={classes.root}
        open={open}
        maxWidth={'md'}
        onClose={handleClickClose}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogTitle className={classes.DialogTitle} id='responsive-dialog-title'>
          {'Change Password'}
        </DialogTitle>
        <div onClick={handleClickClose}>
          <CloseButton />
        </div>
        <DialogContent>
          <DialogContentText className={classes.DialogContent}>
            <Grid container spacing={2}>
              <Grid item sm={12} md={6} lg={6}>
                <TextInput
                  type={'password'}
                  variant={'standard'}
                  label={'New Password'}
                  fullWidth={true}
                  {...bindPassword}
                  error={passwordError?.error}
                  errorText={passwordError?.message}
                />
              </Grid>
              <Grid item sm={12} md={6} lg={6}>
                <TextInput
                  type={'password'}
                  variant={'standard'}
                  label={'Retype Password'}
                  fullWidth={true}
                  {...bindConfirmPassword}
                  error={confirmPasswordError?.error}
                  errorText={confirmPasswordError?.message}
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmit}
            color={'primary'}
            variant={'contained'}
            title={'Change Password'}
            loading={loading}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export { ResetPasswordForm };
