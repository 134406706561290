export const routePaths = {
  root: '/',
  login: '/login',
  dashboard: '/dashboard',
  profile: '/profile',
  leads: '/leads',
  settings: {
    generalSettings: '/settings/General-settings',
    email: '/settings/email',
    pricing: '/settings/pricing',
    equipment: '/settings/equipment',
    ipAddress: '/settings/ip-address',
    apis: '/settings/apis',
    staffMembers: '/settings/staff-members',
  },
  clients: '/clients',
  jobs: '/jobs',
  ip: '/ip',
  tasks: '/tasks',
  dTasks: 'https://tasks.webperception.com',
  equipmentOrders: '/equipment-orders',
  reports: '/reports',
};
