import React, { useEffect } from 'react';
import { useLeadExpandBodyStyle } from '@/static/stylesheets/organisms';
import { Box, Grid } from '@mui/material';
import { SearchableTextInput, TextInput } from '@/components/atoms/TextInput';
import { Button } from '@/components/atoms/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { SingleJobFormData } from '@/types/formData';
import { getEquipments } from '@/redux/actions';
import { useAppSelector } from '@/hooks';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { EquipmentParams } from '@/types';
import { convertToNumber } from '@/utils';
import { ToggleSaveButtonEnabled } from '@/components/molecules/Jobs/SingleJob';
import { useJobListType } from '@/hooks/custom';

interface EquipmentFormProps {
  toggleSaveEnabled: ToggleSaveButtonEnabled;
}

const EquipmentForm: React.FC<EquipmentFormProps> = (props) => {
  const classes = useLeadExpandBodyStyle();
  const { fieldsDisabled } = useJobListType();

  const equipmentListResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_EQUIPMENTS];
  const equipmentItems: EquipmentParams[] = equipmentListResponse?.data?.data ?? [];

  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<SingleJobFormData>();

  const formValues = watch();

  const {
    fields: equipmentFields,
    remove,
    append,
  } = useFieldArray({
    name: 'equipments',
    control,
  });

  const addRow = () => {
    append({
      equipment_id: undefined,
      quantity: '',
      unit_cost: '',
    });
    props?.toggleSaveEnabled(true);
  };

  const removeRow = (index: number) => {
    const equipments = [...(formValues?.equipments ?? [])];
    setValue('equipments', []);
    setTimeout(() => {
      equipments?.splice(index, 1);
      setValue('equipments', equipments);
      props?.toggleSaveEnabled(true);
    }, 50);
  };

  const getEquipmentItems = () =>
    getEquipments({
      limit: 'all',
      page: 1,
    });

  const getOurCost = () => {
    let total = 0;
    formValues?.equipments?.forEach((item) => {
      const equipment = equipmentItems?.find((eq) => eq?.id === item?.equipment_id);
      if (equipment) {
        total += convertToNumber(equipment?.our_cost) * convertToNumber(item?.quantity);
      }
    });
    return total;
  };

  const getTotalClientCost = () => {
    let total = 0;
    formValues?.equipments?.forEach((item) => {
      const equipment = equipmentItems?.find((eq) => eq?.id === item?.equipment_id);
      if (equipment) {
        total += convertToNumber(item?.unit_cost) * convertToNumber(item?.quantity);
      }
    });
    return total;
  };

  const getEquipmentCost = () => {
    let total = 0;
    formValues?.equipments?.forEach((item) => {
      total +=
        (1 + convertToNumber(formValues?.equipment_price_markup)) *
        convertToNumber(item?.quantity) *
        convertToNumber(item?.unit_cost);
    });
    return total;
  };

  const ourCost = getOurCost();
  const totalClientCost = getTotalClientCost();
  const totalEquipmentCost = getEquipmentCost();
  const totalEquipmentNetProfit = totalEquipmentCost - ourCost;

  useEffect(() => {
    setValue('our_cost', ourCost);
  }, [ourCost]);

  useEffect(() => {
    setValue('our_cost', ourCost);
    setValue('total_equipment_cost', totalEquipmentCost);
    setValue('total_equipment_net', totalEquipmentCost);
  }, [ourCost, totalEquipmentCost]);

  useEffect(() => {
    getEquipmentItems();
  }, []);

  return (
    <>
      <div className={classes.root}>
        <div className={'list_body'}>
          <Grid container spacing={2}>
            <Grid item sm={12} md={12} lg={12}>
              <Controller
                control={control}
                name={'equipment_price_markup'}
                rules={{
                  required: 'Please input equipment price markup',
                  validate(value) {
                    if (isNaN(Number(value)) || Number(value) <= 0) {
                      return 'Integer only!';
                    }

                    return true;
                  },
                }}
                render={({ field }) => (
                  <TextInput
                    type={'number'}
                    variant={'standard'}
                    placeholder={'Equipment Markup'}
                    centerMode={true}
                    fullWidth={true}
                    {...field}
                    onKeyUp={() => props?.toggleSaveEnabled(true)}
                    error={!!errors?.equipment_price_markup}
                    disabled={fieldsDisabled}
                  />
                )}
              />
            </Grid>

            {formValues?.equipments?.map((item, index) => (
              <React.Fragment key={index}>
                <Grid item sm={5} md={5} lg={5}>
                  <Controller
                    control={control}
                    name={`equipments.${index}.equipment_id`}
                    rules={{
                      required: 'Please enter description',
                    }}
                    render={({ field }) => (
                      <SearchableTextInput
                        placeholder={'Choose from existing Job/Client'}
                        options={[
                          ...(equipmentItems ?? []).map((item) => ({
                            label: item?.equipment_name,
                            value: item?.id,
                          })),
                        ]}
                        onChange={(value) => {
                          if (value) {
                            const equipment = equipmentItems?.find((eq) => eq?.id === value);

                            setValue(`equipments.${index}.equipment_id`, value);
                            setValue(`equipments.${index}.quantity`, 1);
                            setValue(`equipments.${index}.unit_cost`, equipment?.client_cost);
                          } else {
                            setValue(`equipments.${index}.equipment_id`, value);
                          }
                          props?.toggleSaveEnabled(true);
                        }}
                        value={field?.value}
                        error={errors?.equipments && !!errors?.equipments[index]?.equipment_id}
                        disabled={fieldsDisabled}
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={3} md={3} lg={3}>
                  <Controller
                    control={control}
                    name={`equipments.${index}.quantity`}
                    rules={{
                      required: 'Please input quantity',
                      validate(value) {
                        if (isNaN(Number(value)) || Number(value) <= 0) {
                          return 'Integer only!';
                        }

                        return true;
                      },
                    }}
                    render={({ field }) => (
                      <TextInput
                        type={'number'}
                        variant={'standard'}
                        placeholder={'Quantity'}
                        centerMode={true}
                        fullWidth={true}
                        {...field}
                        onKeyUp={() => props?.toggleSaveEnabled(true)}
                        error={errors?.equipments && !!errors?.equipments[index]?.quantity}
                        disabled={fieldsDisabled}
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={3} md={3} lg={3}>
                  <Controller
                    control={control}
                    name={`equipments.${index}.unit_cost`}
                    rules={{
                      required: 'Please input unit_cost!',
                      validate(value) {
                        if (isNaN(Number(value)) || Number(value) <= 0) {
                          return 'Integer only!';
                        }

                        return true;
                      },
                    }}
                    render={({ field }) => (
                      <TextInput
                        type={'number'}
                        variant={'standard'}
                        placeholder={'Unit Cost'}
                        centerMode={true}
                        fullWidth={true}
                        {...field}
                        onKeyUp={() => props?.toggleSaveEnabled(true)}
                        error={errors?.equipments && !!errors?.equipments[index]?.unit_cost}
                        disabled={fieldsDisabled}
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={1} md={1} lg={1} className='delete'>
                  {!fieldsDisabled && index !== 0 && <DeleteIcon onClick={() => removeRow(index)} />}
                </Grid>
              </React.Fragment>
            ))}

            {!fieldsDisabled && (
              <Grid item className={'button'} sm={12} md={12} lg={12} style={{ marginBottom: '10px' }}>
                <Button onClick={addRow} variant={'contained'} title={'Add Equipment'} fullWidth={true} />
              </Grid>
            )}
          </Grid>

          <Box
            sx={{
              fontSize: '12px',
              marginTop: '10px',
              color: '#9d9d9d',
            }}
          >
            <Grid container spacing={2}>
              <Grid item sm={6} md={6} lg={6}>
                OUR COST: ${convertToNumber(ourCost?.toFixed(2))}
              </Grid>
              <Grid item sm={6} md={6} lg={6} sx={{ textAlign: 'right' }}>
                TOTAL EQUIP COST: ${convertToNumber(totalEquipmentCost?.toFixed(2))}
              </Grid>
              <Grid item sm={12} md={12} lg={12} sx={{ textAlign: 'center' }}>
                TOTAL EQUIP NET PROFIT: ${convertToNumber(totalEquipmentNetProfit?.toFixed(2))}
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
    </>
  );
};

export { EquipmentForm };
