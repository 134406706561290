import { apiClient, endpoints } from '@/api';
import { alertMessages } from '@/config';
import { useAppSelector, useComponentState } from '@/hooks';
import { clearUploadFileResponse, uploadFile, uploadTaskFile } from '@/redux/actions';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { useTaskStyles } from '@/static/stylesheets/molecules/Tasks';
import { FileUploadResponse, FileUploaderParams, TaskFileUploaderParams } from '@/types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import toast from 'react-hot-toast';
import { UploadedFiles } from './UploadedFiles';

interface FileUploaderProps {
  onClick?(): void;

  files?: FileUploadResponse;

  onUploadSuccess?(fileList: FileUploadResponse): void;

  type: TaskFileUploaderParams['type'];
  filableId?: TaskFileUploaderParams['filable_id'];
}

const FileUploader: React.FC<FileUploaderProps> = (props) => {
  const classes = useTaskStyles();
  const fileUploadResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.UPLOAD_TASK_FILE];

  const {
    state: { fileList },
    setState,
  } = useComponentState<{
    fileList: FileUploadResponse;
  }>({
    fileList: [],
  });

  const onDrop = useCallback((acceptedFiles: any) => {
    let formData = new FormData();

    if (acceptedFiles && acceptedFiles?.length > 0) {
      acceptedFiles?.forEach((file: any, index: number) => {
        formData.append(`files[${index}]`, acceptedFiles[index]);
      });
    }
    const data: TaskFileUploaderParams = {
      type: props?.type,
      filable_id: props?.filableId ?? '',
      data: formData,
    };

    uploadTaskFile(data);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const onDeleteFile = (id: string, index: number) => {
    apiClient
      .delete({
        url: endpoints.private.deleteFile(id),
        method: 'delete',
        useBearerToken: true,
      })
      .then(() => {
        toast.success(alertMessages?.common?.file?.deleteSuccess);

        const newFiles = [...fileList];
        newFiles.splice(index, 1);
        setState({ fileList: newFiles });

        if (props?.onUploadSuccess) {
          props?.onUploadSuccess(newFiles);
        }
      })
      .catch(() => {
        toast.error(alertMessages?.common?.file?.deleteError);
      });
  };

  useEffect(() => {
    setState({ fileList: props?.files ?? [] });
  }, [props?.files]);

  useEffect(() => {
    if (fileUploadResponse?.success) {
      const data: FileUploadResponse = fileUploadResponse?.data ?? [];
      const newFiles = [...fileList, ...data];
      setState({
        fileList: newFiles,
      });

      clearUploadFileResponse();

      if (props?.onUploadSuccess) {
        props?.onUploadSuccess(newFiles);
      }
    }
    if (fileUploadResponse?.error) {
      toast.error('Failed to Upload File');
      clearUploadFileResponse();
    }
  }, [fileUploadResponse]);

  console.log('fileList', fileList);

  return (
    <div className={classes.fileUploaderContainer}>
      <UploadedFiles files={fileList} onDelete={onDeleteFile} />

      <section className='container'>
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          {fileUploadResponse?.loading ? <p>Uploading...</p> : <p>Upload one or more files</p>}
        </div>
      </section>
    </div>
  );
};

export { FileUploader };
