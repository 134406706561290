export function formatPhoneNumber(phoneNumberString: string) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '').slice(-10);
  var match = null;
  if (cleaned.length === 7) {
    match = cleaned.match(/^(\d{3})(\d{3})(\d{1})$/);
  } else if (cleaned.length === 8) {
    match = cleaned.match(/^(\d{3})(\d{3})(\d{2})$/);
  } else if (cleaned.length === 9) {
    match = cleaned.match(/^(\d{3})(\d{3})(\d{3})$/);
  } else if (cleaned.length === 10) {
    match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  }
  if (match) {
    return match[1] + '.' + match[2] + '.' + match[3];
  } else if (cleaned.length < 7) {
    return cleaned;
  }
  return null;
}
