import { useAppSelector } from '@/hooks';

export const useJobListType = () => {
  const jobListType = useAppSelector((state) => state?.ui?.jobListType);
  return {
    currentSelected: jobListType,
    showRegular: jobListType === 'regular',
    showCompleted: jobListType === 'completed',
    showRemoved: jobListType === 'removed',
    fieldsDisabled: jobListType === 'completed' || jobListType === 'removed',
  };
};
