import React from 'react';
import { Box } from '@mui/material';
import { themeParser, useAppTheme } from '@/theme';

const NoSubTaskSelected = () => {
  const theme = useAppTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '70%',
        borderLeft: `1px solid ${themeParser('#ddd', '#3e3e3e', theme)}`,

        '& h3': {
          marginBottom: '2px',
        },
      }}
    >
      <h3>No subtask selected</h3>
      <div>(Select subtask to edit)</div>
    </Box>
  );
};

export { NoSubTaskSelected };
