import React, { useEffect, useState } from 'react';
import { useLeadStyles } from '@/static/stylesheets/organisms/Leads/leadStyles';
import { Box, CircularProgress, Grid, Tooltip } from '@mui/material';
import { Button } from '@/components/atoms/Button';
import { LightBox } from '@/components/atoms/Lightbox';
import { FileUploader } from '@/components/organisms/Clients/Files/FileUploader';
import { LeadItem } from '@/types';
import { clearDeleteClientFileResponse, deleteClientFile, openConfirmationDialog } from '@/redux/actions';
import { useAppSelector } from '@/hooks';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import toast from 'react-hot-toast';
import ArticleIcon from '@mui/icons-material/Article';
import { detectImageTypeFromExtension, downloadFileFromUrl, getFileExtension } from '@/utils';

interface FilesProps {
  leadData?: LeadItem;
  leadsFiles?: any;
  leadsId?: string;

  isDeactivatedClient?: boolean;

  onReload?(): void;
}

const Files: React.FC<FilesProps> = (props) => {
  const { isDeactivatedClient } = props;
  const classes = useLeadStyles();
  const [index, setIndex] = useState(-1);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const fileUploaderResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.UPLOAD_CLIENT_FILE];
  const deleteFileData = useAppSelector(selectHttpState)[CORRELATION_IDS.DELETE_CLIENT_FILE];
  const leadsFiles: LeadItem[] = props?.leadsFiles ?? [];

  const files = leadsFiles.map((item: any, i: number) => {
    return {
      src: item?.file_path,
      blurhash: item?.blurhash,
      file_name: item?.file_name,
      index: i,
      id: item?.id,
    };
  });
  const download = async (url: any, fileName: any) => {
    downloadFileFromUrl(url, fileName);
  };
  const onClickDelete = (id: any) => {
    deleteClientFile(id);
  };

  useEffect(() => {
    if (deleteFileData?.success) {
      if (props?.onReload) {
        props?.onReload();
        toast.success('File deleted successfully!');

        clearDeleteClientFileResponse();
      }
    }
    if (deleteFileData?.error) {
      toast.error('Failed to Upload File');
      clearDeleteClientFileResponse();
    }
  }, [deleteFileData]);

  return (
    <>
      <div className={classes.fileBody}>
        <Grid container spacing={3} className={'itemFlex'}>
          <Grid item xs={12} sm={3} md={2.2} lg={2}>
            <FileUploader
              isDeactivatedClient={isDeactivatedClient}
              leadsId={props.leadsId}
              onSuccess={props.onReload}
              onProgressChange={(progress) => setUploadProgress(progress)}
            />
          </Grid>
          <Grid item xs={12} sm={9} md={9.8} lg={10}>
            <Grid container spacing={2} className={'img_grid'}>
              {files.map((item: any, index: number) => {
                const fileExtension = getFileExtension(item?.file_name);

                const ImageOrFile =
                  fileExtension && detectImageTypeFromExtension(fileExtension) ? (
                    <img onClick={() => setIndex(item?.index)} src={item?.src} alt={item?.file_name} />
                  ) : (
                    <div className='other'>
                      <ArticleIcon />
                    </div>
                  );

                return (
                  <Grid key={index} item xs={12} sm={4} md={3} lg={2.4} xl={2}>
                    <div>
                      <Tooltip placement={'right-start'} title={item?.file_name ?? ''} arrow>
                        {ImageOrFile}
                      </Tooltip>
                      {!isDeactivatedClient && (
                        <div className={'actionButton'}>
                          <Button
                            color={'error'}
                            title={'Delete'}
                            onClick={() => {
                              openConfirmationDialog({
                                title: 'Delete',
                                message: 'Are you sure to delete this item?',
                                type: 'error',
                                onClickCancel() {},
                                onClickConfirm() {
                                  onClickDelete(item?.id);
                                },
                                textCenter: 'center',
                              });
                            }}
                          />
                          <Button onClick={() => download(item?.src, item?.file_name)} title={'Download'} />
                        </div>
                      )}
                    </div>
                  </Grid>
                );
              })}

              {fileUploaderResponse?.loading && (
                <Grid key={index} item xs={12} sm={4} md={3} lg={2.4} xl={2}>
                  <Box className={classes.fileLoader}>
                    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                      <CircularProgress
                        variant='determinate'
                        value={uploadProgress}
                        color={'primary'}
                        style={{ width: 50, height: 50 }}
                      />
                      <Box
                        sx={{
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          position: 'absolute',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontSize: '13px !important',
                        }}
                      >
                        {`${uploadProgress}%`}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Grid>

          <LightBox open={index >= 0} index={index} onClose={() => setIndex(-1)} slides={files} />
        </Grid>
      </div>
    </>
  );
};

export { Files };
