import { Button } from '@/components/atoms/Button';
import { TextInput } from '@/components/atoms/TextInput';
import { useLeadExpandBodyStyle } from '@/static/stylesheets/organisms';
import DeleteIcon from '@mui/icons-material/Delete';
import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { ILocation } from './PricingSetting';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

interface IProps {
  data: ILocation;
}

const ProposalAndTerms: React.FC<IProps> = ({ data }) => {
  const classes = useLeadExpandBodyStyle();
  const [isDeactivatedClient] = useState(false);

  const { control } = useFormContext();

  const {
    fields: packagesFields,
    append: packagesAppend,
    remove: packagesRemove,
  } = useFieldArray({
    control,
    name: 'packages',
  });

  const {
    fields: termsFields,
    append: termsAppend,
    remove: termsRemove,
  } = useFieldArray({
    control,
    name: 'terms',
  });

  const addPackages = () => {
    packagesAppend({
      id: '',
      location_id: data.id,
      download_bandwidth: '',
      price: '',
      upload_bandwidth: '',
    });
  };

  const addTerms = () => {
    termsAppend({
      id: '',
      location_id: data.id,
      install_price: '',
      term_years: '',
    });
  };

  return (
    <>
      <div className={classes.root}>
        <div className={'list_body'}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <div className='pricingTableHolder'>Monthly Rates</div>
                {packagesFields &&
                  packagesFields?.length > 0 &&
                  (packagesFields ?? []).map((item, index) => (
                    <React.Fragment key={index}>
                      <Grid item sm={4} md={4} lg={4}>
                        <Controller
                          name={`packages.${index}.download_bandwidth`}
                          control={control}
                          render={({ field: { value, onChange, ...field } }) => (
                            <TextInput
                              type={'number'}
                              variant={'standard'}
                              placeholder={'Download'}
                              centerMode={true}
                              fullWidth={true}
                              value={value}
                              onChange={({ target: { value } }) => {
                                onChange(value);
                              }}
                              // error={item?.downloadError}
                              disabled={isDeactivatedClient}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item sm={3.5} md={3.5} lg={3.5}>
                        <Controller
                          name={`packages.${index}.upload_bandwidth`}
                          control={control}
                          render={({ field: { value, onChange, ...field } }) => (
                            <TextInput
                              type={'number'}
                              variant={'standard'}
                              placeholder={'Upload'}
                              centerMode={true}
                              fullWidth={true}
                              value={value}
                              onChange={({ target: { value } }) => {
                                onChange(value);
                              }}
                              // error={item?.uploadError}
                              disabled={isDeactivatedClient}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item sm={3.5} md={3.5} lg={3.5}>
                        <Controller
                          name={`packages.${index}.price`}
                          control={control}
                          render={({ field: { value, onChange, ...field } }) => {
                            return (
                              <TextInput
                                type={'number'}
                                variant={'standard'}
                                placeholder={'Price'}
                                centerMode={true}
                                fullWidth={true}
                                value={value}
                                onChange={({ target: { value } }) => {
                                  onChange(value);
                                }}
                                startAdornment={<AttachMoneyIcon sx={{ fontSize: '19px', marginBottom: '-20%' }} />}
                                disabled={isDeactivatedClient}
                              />
                            );
                          }}
                        />
                      </Grid>
                      <Grid item sm={1} md={1} lg={1} className='delete'>
                        {!isDeactivatedClient && <DeleteIcon onClick={() => packagesRemove(index)} />}
                      </Grid>
                    </React.Fragment>
                  ))}

                {!isDeactivatedClient && (
                  <Grid item className={'button'} sm={12} md={12} lg={12} style={{ marginBottom: '10px' }}>
                    <Button onClick={addPackages} variant={'contained'} title={'Add Package'} fullWidth={true} />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <p className='pricingTableHolder'>Installation</p>
                {termsFields &&
                  termsFields?.length > 0 &&
                  termsFields?.map((item, index) => (
                    <React.Fragment key={index}>
                      <Grid item sm={5.5} md={5.5} lg={5.5}>
                        <Controller
                          name={`terms.${index}.install_price`}
                          control={control}
                          render={({ field: { value, onChange, ...field } }) => (
                            <TextInput
                              type={'number'}
                              variant={'standard'}
                              placeholder={'Install Price'}
                              centerMode={true}
                              fullWidth={true}
                              value={value}
                              onChange={({ target: { value } }) => {
                                onChange(value);
                              }}
                              startAdornment={<AttachMoneyIcon sx={{ fontSize: '19px', marginBottom: '-20%' }} />}
                              // error={item?.installPriceError}
                              disabled={isDeactivatedClient}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item sm={5.5} md={5.5} lg={5.5}>
                        <Controller
                          name={`terms.${index}.term_years`}
                          control={control}
                          render={({ field: { value, onChange, ...field } }) => (
                            <TextInput
                              type={'number'}
                              variant={'standard'}
                              placeholder={'Term (Years)'}
                              centerMode={true}
                              fullWidth={true}
                              value={value}
                              onChange={({ target: { value } }) => {
                                onChange(value);
                              }}
                              endAdornment={value > 1 ? 'Years' : 'Year'}
                              disabled={isDeactivatedClient}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item sm={1} md={1} lg={1} className='delete'>
                        {!isDeactivatedClient && <DeleteIcon onClick={() => termsRemove(index)} />}
                      </Grid>
                    </React.Fragment>
                  ))}

                {!isDeactivatedClient && (
                  <Grid item className={'button'} sm={12} md={12} lg={12}>
                    <Button onClick={addTerms} variant={'contained'} title={'Add Term'} fullWidth={true} />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export { ProposalAndTerms };
