import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { themeParser, useAppTheme } from '@/theme';
import { Controller, useFormContext } from 'react-hook-form';
import { JobTypeParams, ReportMenuKey, ReportsFormData } from '@/types';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { useAppSelector } from '@/hooks';

const TypeOfWorkPicker = ({ selectedMenuKey }: { selectedMenuKey: ReportMenuKey }) => {
  const theme = useAppTheme();
  const jobTypeList = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_JOB_TYPES];
  const jobTypeItems: JobTypeParams[] = jobTypeList?.data?.data ?? [];

  const { setValue, watch, control } = useFormContext<ReportsFormData>();

  const formValues = watch();

  return (
    <div className={'report-filter-option-container type-of-work-picker-container'}>
      <div className={'report-filter-option-title'}>Type of Work</div>
      <div className={'report-filter-option-body'}>
        <FormControl
          sx={{
            '& .MuiTypography-root': {
              fontSize: '13px',
              fontWeight: 400,
              color: themeParser('#313131', '#b7b7b7', theme),
            },
            '& .MuiFormControlLabel-root': {
              height: '25px',
            },
          }}
        >
          <Controller
            control={control}
            name={'install_type'}
            render={({ field }) =>
              selectedMenuKey === 'TECH_WORK_PROFIT_AND_LOSS' ||
              selectedMenuKey === 'TECH_WORK_PENDING_PROFIT_AND_LOSS' ||
              selectedMenuKey === 'TECH_WORK_LOST_VS_WON' ||
              selectedMenuKey === 'TECH_WORK_PROGRESS' ? (
                <RadioGroup>
                  {(jobTypeItems || [])?.map((job, index) => (
                    <FormControlLabel
                      key={index}
                      value={job?.id}
                      control={<Radio size={'small'} />}
                      label={job?.name}
                      checked={formValues?.job_type === job?.id}
                      onChange={() => setValue('job_type', job?.id)}
                    />
                  ))}
                </RadioGroup>
              ) : (
                <RadioGroup>
                  <FormControlLabel
                    value='internet_install'
                    control={<Radio size={'small'} />}
                    label='Internet Install'
                    checked={formValues?.install_type === 'internet_install'}
                    onChange={() => setValue('install_type', 'internet_install')}
                  />
                  <FormControlLabel
                    value='job_install'
                    control={<Radio size={'small'} />}
                    label='Tech Work | Job'
                    checked={formValues?.install_type === 'job_install'}
                    onChange={() => setValue('install_type', 'job_install')}
                  />
                </RadioGroup>
              )
            }
          />
        </FormControl>
      </div>
    </div>
  );
};

export { TypeOfWorkPicker };
