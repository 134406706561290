import React, { useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { uploadClientFile } from '@/redux/actions';
import { FilesParams } from '@/types';
import { useAppSelector } from '@/hooks';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import toast from 'react-hot-toast';
import classNames from 'classnames';
import { css } from '@emotion/css';

interface FileUploaderProps {
  onClick?(): void;

  onSuccess?(): void;

  leadsId?: any;
  isDeactivatedClient?: boolean;

  onProgressChange?(progress: number): void;
}

const FileUploader: React.FC<FileUploaderProps> = (props) => {
  const { isDeactivatedClient, onProgressChange } = props;
  const fileUploaded = useAppSelector(selectHttpState)[CORRELATION_IDS.UPLOAD_CLIENT_FILE];

  const onDrop = useCallback((acceptedFiles: any) => {
    let formData = new FormData();

    if (acceptedFiles && acceptedFiles?.length > 0) {
      acceptedFiles?.forEach((file: any, index: number) => {
        formData.append(`files[${index}]`, acceptedFiles[index]);
      });
    }
    const data: FilesParams = {
      id: props.leadsId,
      formData,
    };
    uploadClientFile(data, (progressEvent) => {
      const percentageCompleted = Math.round((progressEvent?.loaded * 100) / progressEvent?.total);
      if (onProgressChange) {
        onProgressChange(percentageCompleted);
      }
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  useEffect(() => {
    if (fileUploaded?.success) {
      if (props?.onSuccess) {
        props?.onSuccess();
      }

      if (onProgressChange) {
        onProgressChange(0);
      }
    }
    if (fileUploaded?.error) {
      toast.error('Failed to Upload File');

      if (onProgressChange) {
        onProgressChange(0);
      }
    }
  }, [fileUploaded]);

  return (
    <>
      <section className='container'>
        <div
          {...(!isDeactivatedClient ? getRootProps() : {})}
          className={classNames({
            dropzone: true,
            [css`
              pointer-events: none !important;
            `]: isDeactivatedClient,
          })}
        >
          <input {...getInputProps()} />
          <p>Drag & drop</p>
          <p>files or Photos here</p>
          <p>or click to Upload</p>
        </div>
      </section>
    </>
  );
};

export { FileUploader };
