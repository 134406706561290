import React, { useEffect, useState } from 'react';
import { SignatureForm } from '@/components/molecules/ProfileForm';
import { useProfileStyles } from '@/static/stylesheets/molecules';
import { Button } from '@/components/atoms/Button';
import { FormControlLabel, FormGroup, Grid, Switch } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { TextInput } from '@/components/atoms/TextInput';
import { useAppSelector, useInput } from '@/hooks';
import { validateEmail } from '@/utils';
import toast from 'react-hot-toast';
import { clearUpdatedUserProfile, getUserProfile, updateUserProfile } from '@/redux/actions';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { Loader } from '@/components/atoms/Loader';
import { UserProfileParams } from '@/types';
import { ThemeState, withAppTheme } from '@/theme';

interface ProfileFormProps {
  setAppTheme: ThemeState['setAppTheme'];
  appTheme: ThemeState['appTheme'];
}

const ProfileForm: React.FC<ProfileFormProps> = (props) => {
  const classes = useProfileStyles();
  const [emailSignatureDialogOpen, setEmailSignatureDialogOpen] = useState<boolean>(false);
  const [isDarkTheme, setIsDarkTheme] = useState<boolean>(false);
  const [profileImageFile, setProfileImageFile] = useState<any>(null);
  const [profileImagePreview, setProfileImagePreview] = useState<any>('');
  const [emailSignature, setEmailSignature] = useState('');
  const [profileImageFileError, setProfileImageFileError] = useState(false);

  const userProfileData = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_USER_PROFILE];
  const updatedUserProfile = useAppSelector(selectHttpState)[CORRELATION_IDS.UPDATE_USER_PROFILE];

  const {
    value: firstName,
    setValue: setFirstName,
    bind: bindFirstName,
    error: firstNameError,
  } = useInput('', {
    required: 'Please enter first name!',
  });

  const {
    value: lastName,
    setValue: setLastName,
    bind: bindLastName,
    error: lastNameError,
  } = useInput('', {
    required: 'Please enter last name!',
  });

  const {
    value: email,
    setValue: setEmail,
    bind: bindEmail,
    error: emailError,
  } = useInput('', {
    validate(value) {
      if (!validateEmail(value)) {
        return 'Please enter a valid email address!';
      }

      return true;
    },
  });

  const {
    value: phone,
    setValue: setPhone,
    bind: bindPhone,
    error: phoneError,
  } = useInput('', {
    required: 'Please enter phone number!',
  });

  const {
    value: password,
    bind: bindPassword,
    reset: resetPassword,
    error: passwordError,
  } = useInput('', {
    validate(value) {
      if (value !== '') {
        if (value?.length < 8) {
          return 'Please enter password (At least 8 characters)';
        }
      }

      return true;
    },
  });

  const {
    value: confirmPassword,
    bind: bindConfirmPassword,
    reset: resetConfirmPassword,
    error: confirmPasswordError,
  } = useInput('', {
    validate(value) {
      if (password !== value) {
        return 'Password does not match!';
      }

      return true;
    },
  });

  const {
    value: sessionTimeoutHours,
    setValue: setSessionTimeOutHours,
    bind: bindSessionTimeOutHours,
    error: sessionTimeoutHoursError,
  } = useInput('', {
    required: 'Please enter session timeout (Hours)!',
  });

  const resetFields = () => {
    resetPassword();
    resetConfirmPassword();
  };

  const handleToggleTheme = () => {
    setIsDarkTheme(!isDarkTheme);
  };

  // change the system theme
  const changeTheme = () => {
    if (props?.setAppTheme) {
      props?.setAppTheme(isDarkTheme ? 'dark' : 'light');
    }
  };

  const themeJson = {
    theme: isDarkTheme ? 'dark' : 'light',
  };

  const handleClickEmailSignature = () => {
    setEmailSignatureDialogOpen(true);
  };

  const handleCloseEmailSignatureDialog = () => {
    setEmailSignatureDialogOpen(false);
  };

  const onChangeFile = (event: any) => {
    try {
      const file = event?.target?.files[0];
      const fileExtension = file.type.split('/').pop();

      if (!(fileExtension === 'png' || fileExtension === 'jpeg' || fileExtension === 'jpg')) {
        toast.error('Please select images(jpeg, jpg, png only)!');
        setProfileImageFileError(true);
        return;
      } else {
        setProfileImageFileError(false);
      }
      setProfileImageFile(file);

      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setProfileImagePreview(reader.result);
      });
      reader.readAsDataURL(file);
    } catch (e) {}
  };

  const handleClickFileUploadPicker = () => {
    const handle = document.querySelector('input');
    if (handle != null) {
      handle.click();
    }
  };

  const getUserProfileData = () => getUserProfile();

  const saveProfile = () => {
    firstNameError?.check();
    lastNameError?.check();
    emailError?.check();
    phoneError?.check();
    passwordError?.check();
    confirmPasswordError?.check();
    sessionTimeoutHoursError?.check();

    if (
      firstNameError?.check() ||
      lastNameError?.check() ||
      emailError?.check() ||
      phoneError?.check() ||
      passwordError?.check() ||
      confirmPasswordError?.check() ||
      sessionTimeoutHoursError?.check()
    ) {
      toast.error('Please fill all the required fields!');
      return;
    }

    if (profileImageFileError) {
      toast.error('Please select a valid profile picture!');
      return;
    }

    const formData = new FormData();

    formData.append('first_name', firstName);
    formData.append('last_name', lastName);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('session_timeout', sessionTimeoutHours);
    formData.append('application_theme', JSON.stringify(themeJson));
    console.log(profileImageFile, 'profileImageFile');
    if (profileImageFile) {
      formData.append('profile_image', profileImageFile);
    }

    if (password !== '') {
      formData.append('password', password);
    }

    if (confirmPassword !== '') {
      formData.append('confirm_password', confirmPassword);
    }

    updateUserProfile(formData);
  };

  useEffect(() => {
    if (updatedUserProfile?.success) {
      changeTheme(); // change the theme
      resetFields();
      toast.success('Saved successfully!');
      clearUpdatedUserProfile();
    }

    if (updatedUserProfile?.error) {
      const error = updatedUserProfile?.error;
      if (error?.status === 422) {
        toast.error(error?.data?.message);
      } else {
        toast.error('Failed to save profile!');
      }
      clearUpdatedUserProfile();
    }
  }, [updatedUserProfile]);

  useEffect(() => {
    if (userProfileData?.success) {
      const data: UserProfileParams = userProfileData?.data;
      setFirstName(data?.first_name ?? '');
      setLastName(data?.last_name ?? '');
      setEmail(data?.email ?? '');
      setPhone(data?.phone ?? '');
      setSessionTimeOutHours(data?.session_timeout ?? '');
      if (data?.application_theme) {
        const theme = JSON.parse(data?.application_theme)?.theme;
        setIsDarkTheme(theme === 'dark');
      }
      setProfileImagePreview(data?.profile_image ?? '');
      setEmailSignature(data?.email_signature ?? '');
    }
  }, [userProfileData]);

  useEffect(() => {
    getUserProfileData();
  }, []);

  return (
    <>
      <div className={classes.root}>
        {userProfileData?.loading ? (
          <Loader />
        ) : (
          <>
            <Grid container spacing={2} className={classes.form}>
              <Grid item sm={12} md={3.5} lg={3.5}>
                <Avatar alt={firstName} src={profileImagePreview} sx={{ width: 150, height: 150 }} />
              </Grid>
              <Grid item sm={12} md={8} lg={8}>
                <div className={classes.fileInputBody}>
                  <input
                    className={classes.fileUpload}
                    placeholder='250px x 250px'
                    type='file'
                    onChange={onChangeFile}
                  />
                  <button onClick={handleClickFileUploadPicker}>Profile Image</button>
                  <input
                    className={classes.inputField}
                    placeholder='250px x 250px'
                    type='text'
                    onClick={handleClickFileUploadPicker}
                  />
                </div>
                <div className={classes.signature}>
                  <h4 onClick={handleClickEmailSignature}>Set Email Signature</h4>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} className={classes.form}>
              <Grid item sm={12} md={6} lg={6}>
                <TextInput
                  variant={'standard'}
                  label={'First Name'}
                  fullWidth={true}
                  {...bindFirstName}
                  error={firstNameError.error}
                  errorText={firstNameError.message}
                />
              </Grid>
              <Grid item sm={12} md={6} lg={6}>
                <TextInput
                  variant={'standard'}
                  label={'Last Name'}
                  fullWidth={true}
                  {...bindLastName}
                  error={lastNameError.error}
                  errorText={lastNameError.message}
                />
              </Grid>
              <Grid item sm={12} md={6} lg={6}>
                <TextInput
                  variant={'standard'}
                  label={'Email'}
                  fullWidth={true}
                  {...bindEmail}
                  error={emailError.error}
                  errorText={emailError.message}
                />
              </Grid>
              <Grid item sm={12} md={6} lg={6}>
                <TextInput
                  variant={'standard'}
                  label={'Telephone'}
                  fullWidth={true}
                  {...bindPhone}
                  error={phoneError.error}
                  errorText={phoneError.message}
                />
              </Grid>
              <Grid item sm={12} md={6} lg={6}>
                <TextInput
                  type={'password'}
                  variant={'standard'}
                  label={'New Password'}
                  fullWidth={true}
                  {...bindPassword}
                  error={passwordError.error}
                  errorText={passwordError.message}
                  autoComplete={'off'}
                />
              </Grid>
              <Grid item sm={12} md={6} lg={6}>
                <TextInput
                  type={'password'}
                  variant={'standard'}
                  label={'Repeat Password'}
                  fullWidth={true}
                  {...bindConfirmPassword}
                  error={confirmPasswordError.error}
                  errorText={confirmPasswordError.message}
                />
              </Grid>
              <Grid item sm={12} md={6} lg={6}>
                <TextInput
                  variant={'standard'}
                  label={'Session Timeout (hours)'}
                  fullWidth={true}
                  {...bindSessionTimeOutHours}
                  error={sessionTimeoutHoursError.error}
                  errorText={sessionTimeoutHoursError.message}
                />
              </Grid>
              <Grid item sm={12} md={6} lg={6}>
                <FormGroup className={classes.group}>
                  <label>Light Theme</label>
                  <FormControlLabel
                    control={<Switch checked={isDarkTheme} onChange={handleToggleTheme} />}
                    label='Dark Theme'
                  />
                </FormGroup>
              </Grid>
            </Grid>
            <div>
              <Button
                color={'primary'}
                variant={'contained'}
                title={'Save'}
                fullWidth={true}
                onClick={saveProfile}
                loading={updatedUserProfile?.loading}
              />
            </div>
          </>
        )}

        <SignatureForm
          open={emailSignatureDialogOpen}
          onClose={handleCloseEmailSignatureDialog}
          emailSignature={emailSignature}
        />
      </div>
    </>
  );
};

const ProfileFormExtended = withAppTheme(ProfileForm);

export { ProfileFormExtended as ProfileForm };
