import React, { useEffect } from 'react';
import { useLeadExpandBodyStyle } from '@/static/stylesheets/organisms';
import { Box, Grid } from '@mui/material';
import { TextInput } from '@/components/atoms/TextInput';
import { Button } from '@/components/atoms/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { SingleJobFormData } from '@/types/formData';
import { convertToNumber } from '@/utils';
import { ToggleSaveButtonEnabled } from '@/components/molecules/Jobs/SingleJob';
import { useJobListType } from '@/hooks/custom';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

interface ProposalAndTermsProps {
  toggleSaveEnabled: ToggleSaveButtonEnabled;
}

const ProposalAndTermsForm: React.FC<ProposalAndTermsProps> = (props) => {
  const classes = useLeadExpandBodyStyle();
  const { fieldsDisabled } = useJobListType();

  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<SingleJobFormData>();

  const formValues = watch();

  const {
    fields: proposalFields,
    remove,
    append,
  } = useFieldArray({
    name: 'proposals',
    control,
  });

  const addRow = () => {
    append({
      description: '',
      price: '',
    });
    props?.toggleSaveEnabled(true);
  };

  const removeRow = (index: number) => {
    const item = (formValues?.proposals ?? [])[index];
    const deletedProposals = [...(formValues?.deleted_proposal_ids ?? [])];
    if (item?.id) {
      deletedProposals.push(item?.id);
    }

    setValue('deleted_proposal_ids', deletedProposals);

    remove(index);
    props?.toggleSaveEnabled(true);
  };

  const totalEquipmentCost = convertToNumber(formValues?.total_equipment_cost);
  const totalLaborCost = convertToNumber(formValues?.total_labor_cost);

  const getTotalCost = () => {
    let total = 0;
    /*formValues?.proposals?.forEach((item) => {
      total += convertToNumber(item?.price);
    });*/
    return total + totalEquipmentCost + totalLaborCost;
  };

  const totalCost = getTotalCost();

  useEffect(() => {
    setValue('total_cost', totalCost);
  }, [totalCost]);

  return (
    <>
      <div className={classes.root}>
        <div className={'list_body'}>
          <Grid container spacing={2}>
            {proposalFields?.map((item, index) => (
              <React.Fragment key={index}>
                <Grid item sm={6} md={6} lg={6}>
                  <Controller
                    control={control}
                    name={`proposals.${index}.description`}
                    rules={{
                      required: 'Please enter description',
                    }}
                    render={({ field }) => (
                      <TextInput
                        type={'text'}
                        variant={'standard'}
                        placeholder={'Description'}
                        centerMode={true}
                        fullWidth={true}
                        {...field}
                        onKeyUp={() => props?.toggleSaveEnabled(true)}
                        error={errors?.proposals && !!errors?.proposals[index]?.description}
                        disabled={fieldsDisabled}
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={5} md={5} lg={5}>
                  <Controller
                    control={control}
                    name={`proposals.${index}.price`}
                    rules={{
                      required: 'Please input price!',
                      validate(value) {
                        if (isNaN(Number(value)) || Number(value) <= 0) {
                          return 'Integer only!';
                        }

                        return true;
                      },
                    }}
                    render={({ field }) => (
                      <TextInput
                        type={'number'}
                        variant={'standard'}
                        placeholder={'Price'}
                        centerMode={true}
                        fullWidth={true}
                        {...field}
                        onKeyUp={() => props?.toggleSaveEnabled(true)}
                        startAdornment={<AttachMoneyIcon sx={{ fontSize: '19px', marginBottom: '-20%' }} />}
                        error={errors?.proposals && !!errors?.proposals[index]?.price}
                        disabled={fieldsDisabled}
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={1} md={1} lg={1} className='delete'>
                  {!fieldsDisabled && <DeleteIcon onClick={() => removeRow(index)} />}
                </Grid>
              </React.Fragment>
            ))}

            {!fieldsDisabled && (
              <Grid item className={'button'} sm={12} md={12} lg={12} style={{ marginBottom: '10px' }}>
                <Button onClick={addRow} variant={'contained'} title={'Add line item'} fullWidth={true} />
              </Grid>
            )}

            <Grid item sm={12} md={12} lg={12}>
              <Controller
                control={control}
                name={'additional_terms'}
                render={({ field }) => (
                  <TextInput
                    type={'text'}
                    variant={'standard'}
                    placeholder={'Additional Terms'}
                    centerMode={true}
                    fullWidth={true}
                    {...field}
                    onKeyUp={() => props?.toggleSaveEnabled(true)}
                    error={!!errors?.additional_terms}
                    disabled={fieldsDisabled}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Box
            sx={{
              fontSize: '12px',
              marginTop: '10px',
              color: '#9d9d9d',
            }}
          >
            <Grid container spacing={2}>
              <Grid item sm={6} md={6} lg={6}>
                TOTAL EQUIPMENT COST: ${convertToNumber(totalEquipmentCost?.toFixed(2))}
              </Grid>
              <Grid item sm={6} md={6} lg={6} sx={{ textAlign: 'right' }}>
                TOTAL LABOR COST: ${convertToNumber(totalLaborCost?.toFixed(2))}
              </Grid>
              <Grid item sm={12} md={12} lg={12} sx={{ textAlign: 'center' }}>
                TOTAL COST: ${convertToNumber(totalCost?.toFixed(2))}
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
    </>
  );
};

export { ProposalAndTermsForm };
