import React, { useState } from 'react';
import { SettingsLayout } from '@/components/layouts';
import { LeadList, LeadCounter } from '@/components/molecules/Leads';
import { ArchivedLead } from '@/components/molecules/Leads/ArchivedLead';
import { PrivateLayout } from '@/components/Middleware';
import { useLeadStyles } from '@/static/stylesheets/organisms';

const Leads = () => {
  const classes = useLeadStyles();
  const [lostLead, setLostLead] = useState<boolean>(false);

  const onChangeLeadType = () => {
    setLostLead(!lostLead);
  };

  return (
    <>
      <PrivateLayout>
        <div className={classes.leadListBody}>
          <SettingsLayout
            hideSettingsTabPanel={false}
            hideHeaderRightSide={true}
            containerSize={'xxl'}
            onChangeType={onChangeLeadType}
            featureType={'leads'}
            wrapperStyle={{
              height: '10vh',
              flexGrow: 1,
            }}
          >
            {lostLead ? <ArchivedLead isArchivedLead={lostLead} /> : <LeadList />}
          </SettingsLayout>
          <LeadCounter />
        </div>
      </PrivateLayout>
    </>
  );
};

export default Leads;
