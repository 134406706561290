import React from 'react';
import { LoginForm } from '@/components/molecules/LoginForm';
import { PublicLayout } from '@/components/Middleware';

const Login = () => {
  return (
    <>
      <PublicLayout>
        <LoginForm />
      </PublicLayout>
    </>
  );
};

export default Login;
