import React, { useEffect, useState } from 'react';
import { DataTable } from '@/components/organisms/DataTable';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import Container from '@mui/material/Container';
import { SettingsCard } from '@/components/organisms/SettingsCard';
import { useAppSelector } from '@/hooks';
import {
  getEquipmentOrdersOpen,
  updateEquipmentOrder,
  getEquipmentOrdersCompleted,
  getEquipmentOrdersCancelled,
  clearUpdateEquipmentOrders,
} from '@/redux/actions';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { EquipmentOrdersParams } from '@/types';
import toast from 'react-hot-toast';
import { convertToNumber } from '@/utils';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import { MenuDialog } from '@/components/atoms/Popover';
import { Loader } from '@/components/atoms/Loader';
import { useEquipmentsStyles } from '@/static/stylesheets/molecules/EquipmentOrders';

const OpenOrdersList = () => {
  const classes = useEquipmentsStyles();
  const equipmentOrdersOpen = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_EQUIPMENT_ORDERS_OPEN];
  const equipmentOrderList: any = equipmentOrdersOpen?.data?.data ?? [];
  const updatedEquipmentOrders = useAppSelector(selectHttpState)[CORRELATION_IDS.UPDATE_EQUIPMENT_ORDERS];
  console.log(updatedEquipmentOrders, 'updatedEquipmentOrders');
  const totalPages = convertToNumber(equipmentOrdersOpen?.data?.last_page);
  console.log(totalPages, 'totalPages');
  const [limit, setLimit] = useState(6);
  const [currentPage, setCurrentPage] = useState(1);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [actualCost, setActualCost] = useState(null);

  const CompletedOrder = (id: EquipmentOrdersParams['id']) => {
    const data: EquipmentOrdersParams = {
      id: id,
      order_type: 'completed',
      actual_cost: convertToNumber(actualCost),
      timezone: timezone,
    };
    updateEquipmentOrder(data);
    if (id) {
      equipmentOrdersCompleted();
    }
  };

  const CancelledOrder = (id: EquipmentOrdersParams['id']) => {
    const data: EquipmentOrdersParams = {
      id: id,
      order_type: 'cancelled',
      timezone: timezone,
    };
    updateEquipmentOrder(data);
    if (id) {
      equipmentOrdersCancelled();
    }
  };
  const getEquipmentOrdersList = () =>
    getEquipmentOrdersOpen({
      limit,
      page: currentPage,
    });

  const equipmentOrdersCompleted = () =>
    getEquipmentOrdersCompleted({
      limit: 6,
      page: 1,
    });

  const equipmentOrdersCancelled = () =>
    getEquipmentOrdersCancelled({
      limit: 6,
      page: 1,
    });

  useEffect(() => {
    getEquipmentOrdersList();
  }, [currentPage]);

  useEffect(() => {
    if (updatedEquipmentOrders?.success) {
      getEquipmentOrdersList();
      equipmentOrdersCompleted();
      equipmentOrdersCancelled();
      toast.success('Updated successfully!');
      clearUpdateEquipmentOrders();
    }
    if (updatedEquipmentOrders?.error) {
      toast.error(updatedEquipmentOrders?.error?.data?.message);
    }
  }, [updatedEquipmentOrders]);

  return (
    <>
      <Container className={'mx-width'}>
        <SettingsCard title={'Open Orders'}>
          <div style={{ textAlign: 'center', padding: 10 }}>
            {equipmentOrdersOpen?.loading ? (
              <div
                style={{
                  height: 100,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Loader />
              </div>
            ) : (
              <div style={{ width: '100%', display: 'inline-block' }}>
                <DataTable
                  heads={[
                    { label: 'Description', align: 'left' },
                    { label: 'Quantity', align: 'center' },
                    { label: 'Total Est. Cost', align: 'center' },
                    { label: 'Client', align: 'center' },
                    { label: 'Category', align: 'center' },
                    { label: 'Link', align: 'center' },
                    { label: 'Ordered On', align: 'center' },
                    { label: 'Requested By', align: 'center' },
                    { label: 'Actions', align: 'center' },
                  ]}
                  rows={[
                    ...equipmentOrderList?.map((equipment: any) => [
                      { label: equipment?.equipment?.equipment_name ?? '', align: 'left' },
                      { label: equipment?.quantity ?? '', align: 'center' },
                      { label: equipment?.estimated_cost ?? '', align: 'center' },
                      {
                        label:
                          equipment?.customer?.customer_type === 'Residential'
                            ? `${equipment?.customer?.first_name ?? ''} ${equipment?.customer?.last_name ?? ''}`
                            : equipment?.customer?.first_name ?? '',
                        align: 'center',
                      },
                      { label: equipment?.category?.category_name ?? '', align: 'center' },

                      {
                        label: (
                          <a target='_blank' href={equipment?.equipment_url} rel='noreferrer'>
                            <InsertLinkIcon style={{ color: '#2196f3' }} />
                          </a>
                        ),
                        align: 'center',
                      },
                      { label: equipment?.ordered_on, align: 'center' },
                      { label: equipment?.requested_by?.name, align: 'center' },
                      {
                        label: (
                          <div className={'action-icon dailog_body'}>
                            <MenuDialog
                              onClick={(e: any) => {
                                switch (e?.target?.id) {
                                  case 'Complete':
                                    if (equipment?.id) {
                                      CompletedOrder(equipment?.id);
                                    }
                                    break;
                                }
                              }}
                              title={<DoneAllIcon />}
                              isInput={true}
                              subHeader={
                                'Are you sure you want to complete this order? There is no way to undo this action.'
                              }
                              menuList={[
                                {
                                  title: 'Complete Order',
                                  icon: null,
                                  action: 'Complete',
                                  prepareOptions({ isData }) {
                                    setActualCost(isData);
                                    return {
                                      disabled: isData ? false : true,
                                    };
                                  },
                                },
                                {
                                  title: 'Never Mind',
                                  icon: null,
                                  action: 'Delete',
                                },
                              ]}
                              buttonProps={{
                                size: 'small',
                                color: 'success',
                              }}
                            />
                            <MenuDialog
                              onClick={(e: any) => {
                                switch (e?.target?.id) {
                                  case 'Delete':
                                    if (equipment?.id) {
                                      CancelledOrder(equipment?.id);
                                    }
                                    break;
                                }
                              }}
                              title={<DoNotDisturbIcon />}
                              subHeader={
                                'Are you sure you want to cancel this order? There is no way to undo this action.'
                              }
                              menuList={[
                                {
                                  title: 'Yes, cancel order',
                                  icon: null,
                                  action: 'Delete',
                                },
                                {
                                  title: 'Never Mind',
                                  icon: null,
                                  action: 'Cancel',
                                },
                              ]}
                              buttonProps={{
                                size: 'small',
                                color: 'error',
                              }}
                            />
                          </div>
                        ),
                        align: 'center',
                      },
                    ]),
                  ]}
                  tableRowCellClassName={classes.tableRowCell}
                  hasPagination={true}
                  paginationProps={{
                    count: totalPages,
                    currentPage,
                    onChange(value) {
                      setCurrentPage(value);
                    },
                  }}
                />
              </div>
            )}
          </div>
        </SettingsCard>
      </Container>
    </>
  );
};

export { OpenOrdersList };
