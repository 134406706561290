import { css } from '@emotion/css';
import { colors, themeParser, useAppTheme } from '@/theme';

export const useDataTableStyles = () => {
  const theme = useAppTheme();

  return {
    tableBodyPart: css`
      background: transparent !important;
      box-shadow: none !important;
      tbody .MuiTableRow-root:hover {
        background-color: ${themeParser(colors.tabItems.light, colors.tabItems.dark, theme)} !important;
      }
    `,
    tableHeadCell: css`
      font-weight: bold !important;
      color: ${themeParser(colors.body.dark, colors.tableheader.dark, theme)} !important;
      font-size: 15px !important;
      border-bottom: 1px solid ${themeParser(colors.tabItems.light, colors.tabItems.dark, theme)} !important;
      padding-left: 5px !important;
      font-family: 'Poppins', sans-serif !important;
    `,

    tableRowCell: css`
      color: ${themeParser(colors.body.dark, colors.tableheader.dark, theme)} !important;
      border-bottom: 1px solid ${themeParser(colors.tabItems.light, colors.tabItems.dark, theme)} !important;
      font-size: 84% !important;
      padding-left: 5px !important;
      padding-top: 10px !important;
      padding-bottom: 10px !important;
      font-family: 'Poppins', sans-serif !important;
      tr:hover {
        background-color: rgba(242, 242, 242, 0.5);
      }
    `,

    iconsContainer: (align?: 'center' | 'left' | 'right') => css`
      display: flex;
      flex-direction: row;
      justify-content: ${align === 'center' ? 'center' : align === 'left' ? 'start' : 'end'};
      .action-icon {
        width: 100%;
        text-align: center;
      }
      .dailog_body {
        text-align: end;
        .MuiButton-root {
          background-color: transparent !important;
          box-shadow: none;
          min-width: auto;
        }
        .MuiButton-containedError {
          color: #f44336 !important;
        }
        .MuiButton-containedSuccess {
          color: #4caf50 !important;
        }
      }
    `,

    paginationContainer: css`
      padding: 15px;
    `,
  };
};
