import { css } from '@emotion/css';
import { colors, themeParser, useAppTheme } from '@/theme';

export const useHeaderStyles = () => {
  const theme = useAppTheme();

  return {
    root: css`
      // position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 99;
      display: flex;
    `,
    appBar: css`
      background: #2a2a2a;
      box-shadow:
        0 2px 2px 0 rgb(0 0 0 / 14%),
        0 3px 1px -2px rgb(0 0 0 / 12%),
        0 1px 5px 0 rgb(0 0 0 / 20%);
      color: #fff;
      width: 100%;
    `,
    navWrapper: css`
      position: relative;
      // height: 100%;
      display: flex;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: space-between;
      align-items: flex-start;
      box-shadow:
        0 2px 2px 0 rgb(0 0 0 / 14%),
        0 3px 1px -2px rgb(0 0 0 / 12%),
        0 1px 5px 0 rgb(0 0 0 / 20%);

      .showFilter {
        display: flex;
        z-index: 1111;
      }
    `,
    wrapperLeftSide: css`
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      height: 64px;

      .menuShow {
        display: grid;
        animation: left_to 0.35s;
        z-index: 11111;
        @keyframes left_to {
          from {
            margin-left: -340px;
          }
          to {
            margin-left: 0px;
          }
        }
      }
    `,
    itemList: css`
      @media (max-width: 990px) {
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: flex-start;
        position: absolute;
        height: 100vh;
        left: 0;
        top: 0;
        background: #2a2a2a;
        box-shadow:
          0 2px 2px 0 rgb(0 0 0 / 14%),
          0 3px 1px -2px rgb(0 0 0 / 12%),
          0 1px 5px 0 rgb(0 0 0 / 20%);
        display: none;
        width: 300px;
      }
    `,
    wrapperRightSide: css`
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      height: 64px;

      .bg_transparent {
        background: transparent !important;
      }

      .loop {
        svg {
          animation: rotation 0.3s linear;
        }

        @keyframes rotation {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(359deg);
          }
        }
      }

      .reverse {
        svg {
          animation: reverses 0.3s linear;
        }

        @keyframes reverses {
          from {
            transform: rotate(359deg);
          }
          to {
            transform: rotate(0deg);
          }
        }
      }

      button {
        position: relative;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        font-weight: 500;
        background-color: #2196f3 !important;
        color: #fff;
        font-size: 14px;
        outline: 0;
        width: 40px;
        height: 40px;
        line-height: 40px;
        padding: 0;
        margin-right: 10px;
        border-radius: 50%;
        outline: none;
        border: none;

        svg {
          height: 40px;
          line-height: 40px;
          display: block;
          font-size: 22px;
          color: ${themeParser(colors.white.default, colors.black.default, theme)};
        }
      }

      @media (max-width: 1340px) {
        position: absolute;
        height: 100vh;
        background: #2a2a2a;
        box-shadow:
          0 2px 2px 0 rgb(0 0 0 / 14%),
          0 3px 1px -2px rgb(0 0 0 / 12%),
          0 1px 5px 0 rgb(0 0 0 / 20%);
        width: 340px;
        justify-content: center;
        animation: left_to_right 0.35s;
        right: 0;
        display: none;
        @keyframes left_to_right {
          from {
            margin-right: -340px;
          }
          to {
            margin-right: 0px;
          }
        }
      }
    `,
    closeIcon: css`
      display: none;
      @media (max-width: 1200px) {
        position: absolute;
        top: 10px;
        left: 10px;
        color: #2196f3;
        display: block;
      }
    `,
    brandLogo: css`
      margin: 0 30px;
      position: relative;
      height: 64px;
      border-right: 1px solid #646464;
      padding: 0 30px 0 0;
      cursor: pointer;

      img {
        width: 125px;
        margin-top: 8px;
        left: 0;
      }
    `,
    item: css`
      margin: 0 0 0 5px;
      color: ${themeParser('#b5b5b5', '#000000', theme)};
      border: 1px solid #2a2a2a;
      line-height: 20px;
      padding: 4px 10px;
      border-radius: 3px;
      text-transform: uppercase;
      background: #3e3e3e;
      box-shadow:
        inset 0 2px 2px 0 rgb(0 0 0 / 29%),
        inset 0 3px 1px -2px rgb(0 0 0 / 28%),
        inset 0 1px 5px 0 rgb(0 0 0 / 33%);
      letter-spacing: 1px;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      font-size: 15px;
      font-weight: 500;

      .active {
        color: ${themeParser('#fff', '#2196f3', theme)};
      }

      @media (max-width: 767px) {
        padding: 10px 10px;
        margin: 8px 8px 8px 20px;
        width: 160px;
      }
    `,
    itemActive: css`
      color: ${colors.primary} !important;
    `,
    closeIcon2: css`
      display: none;
      @media (max-width: 1200px) {
        position: absolute;
        top: 10px;
        right: 10px;
        color: #2196f3;
        display: block;
      }
      @media (min-width: 767px) {
        display: none;
      }
    `,
    searchBody: css`
      width: 300px;
      margin-right: 30px;
      margin-left: 10px;
      display: flex;
      align-items: center;
      @media (max-width: 1260px) {
        margin-right: auto;
        margin-left: auto;
      }
    `,
    inputField: css`
      margin: 10px 0;
      background: #505050;
      height: 45px;
      border-radius: 3px;
      width: 300px !important;
      box-shadow:
        inset 0 2px 2px 0 rgb(0 0 0 / 14%),
        inset 0 3px 1px -2px rgb(0 0 0 / 12%),
        inset 0 1px 5px 0 rgb(0 0 0 / 20%);

      :focus-within {
        box-shadow: none;
        border-radius: 0;
      }

      input {
        height: 36px;
        padding-left: 60px;
        padding-right: 40px;
        color: #29abe2 !important;
        font-size: 15px;
      }

      button {
        background: transparent;
        background-color: transparent !important;
        color: #2196f3 !important;
        margin-top: -43px;
        position: absolute;
        margin-left: 8px;
      }

      .close-hidden {
        display: none !important;
      }

      .close {
        color: #29abe2 !important;
        * {
          color: #29abe2 !important;
        }

        right: 45px !important;
        display: block !important;
        position: absolute;
        padding-top: 0px;
        opacity: 1 !important;
      }

      .search_icon {
        color: #29abe2 !important;
        font-size: 23px !important;
        margin-top: 2px;
      }
    `,
    dollarIcon: css`
      margin-right: -6px;
      margin-left: 15px;
      cursor: pointer;
      height: 64px;
      line-height: 64px;
      display: block;
      font-size: 24px;
      color: #2196f3 !important;
    `,
    rightChild: css`
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 1200px) {
        margin-bottom: 25px;
      }
    `,
    menu: css`
      text-transform: uppercase;
      background-color: #2196f3 !important;
      color: #fff;
      cursor: pointer;
      display: none;

      svg {
        font-size: 28px;
        margin: auto 6px;
      }

      @media (max-width: 990px) {
        display: flex;
        align-items: center;
        margin-right: 20px;
      }
    `,
    wrapperRight: css`
      display: flex;
      align-items: center;
      @media (max-width: 1200px) {
        display: block;
      }
    `,
    filterMenu: css`
      text-transform: uppercase;
      background-color: #2196f3 !important;
      color: #fff;
      cursor: pointer;
      display: none;

      svg {
        font-size: 28px;
        margin: auto 6px;
      }

      @media (max-width: 1340px) {
        display: flex;
        align-items: center;
        margin-right: 20px;
      }
    `,
    hide: css`
      max-height: 0 !important;
    `,
    show: css`
      overflow-y: hidden;
      max-height: 500px; /* approximate max height */
      transition: max-height 1.5s ease;
      position: fixed;
      top: 65.3px;
      background: #2a2a2a;
      left: 0;
      right: 0;
      width: 100%;
    `,
    filterBody: css`
      padding-bottom: 20px;
      box-shadow:
        0 2px 2px 0 rgb(0 0 0 / 14%),
        0 3px 1px -2px rgb(0 0 0 / 12%),
        0 1px 5px 0 rgb(0 0 0 / 20%);
      padding-top: 10px;

      .MuiRadio-root {
        color: #9e9e9e;
      }

      .multiSelect .MuiFormControl-root {
        margin: 0px;
        margin-top: 20px !important;

        .MuiInputLabel-root {
          margin-left: -15px;
          font-size: 16px;
        }
      }

      @media (max-width: 1200px) {
        display: block;
        .MuiSelect-select {
          white-space: normal !important;
        }
      }
      @media (max-width: 1200px) {
        position: relative;
        top: 0px;
        box-shadow: none;
        padding-bottom: 5px;
        max-height: 65vh;
        overflow-y: auto;
      }
      @media (max-width: 990px) and (orientation: landscape) {
        max-height: 40vh;
      }

      .headerText p {
        font-size: 16px;
        font-weight: 500;
        padding: 0 0 5px;
        color: #2196f3;
        border-bottom: 1px solid #2196f3;
      }

      ,
      . button {
        display: flex;
        margin-top: 20px;

        .MuiButton-root {
          width: 100%;
        }
      }

      .button {
        display: flex;
        margin-top: 15px;

        button:first-child {
          width: 100%;
        }

        button {
          margin: 2px;
          letter-spacing: 5px;
          transition: all 0.3s ease-in-out;
          font-weight: 500;
          font-size: 13px;
          background-color: #2196f3 !important;
          cursor: pointer;
          padding: 0 16px;
          border-radius: 2px !important;
          line-height: 30.4px;
          height: 32.4px;
          color: #2a2a2a !important;
          display: flex;
          align-items: center;
          border-color: #2196f3;
          box-shadow:
            0px 3px 1px -2px rgb(0 0 0 / 20%),
            0px 2px 2px 0px rgb(0 0 0 / 14%),
            0px 1px 5px 0px rgb(0 0 0 / 12%);
        }
      }

      .MuiFormGroup-root .MuiFormControlLabel-root {
        .MuiRadio-root,
        .MuiCheckbox-root {
          height: 35px;
          line-height: 35px;
          color: #5a5a5a !important;
        }

        .Mui-checked {
          color: #1976d2 !important;
        }

        .MuiTypography-root {
          color: #2196f3;
          font-weight: 500;
          line-height: 25px;
        }
      }

      .indicatorBox .MuiTypography-root {
        display: flex;
        align-items: center;
      }

      .indicatorBox .MuiTypography-root::after {
        height: 20px;
        content: '';
        width: 20px;
        border: none;
        display: inline-flex;
        border-radius: 150px;
        margin: 0 5px -3px 10px;
        padding-right: 0;
      }

      .green .MuiTypography-root::after {
        background: #4caf50 !important;
      }

      .yellow .MuiTypography-root::after {
        background: #ffc107 !important;
      }

      .red .MuiTypography-root::after {
        background: #f44336 !important;
        animation: pulse 1s infinite;
        @keyframes pulse {
          0% {
            transform: scale(0.85);
            box-shadow: 0 0 0 0 #ff00001a;
          }

          70% {
            transform: scale(1);
            box-shadow: 0 0 0 10px #ff00001a;
          }

          100% {
            transform: scale(0.85);
            box-shadow: 0 0 0 0 #ff00001a;
          }
        }
      }

      .error {
        font-size: 12px;
        color: #d32f2f;
        margin-top: 4px;
      }
    `,
  };
};
