import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { RightHeader, Files } from '@/components/organisms/Clients';
import { useClientExpandBodyStyle } from '@/static/stylesheets/organisms';

interface DeactivatedClientsProps {
  isDeactivatedClient?: boolean;
}

const DeactivatedClients: React.FC<DeactivatedClientsProps> = (props) => {
  const classes = useClientExpandBodyStyle();
  const { isDeactivatedClient } = props;

  const [expandActivity, setExpandActivity] = useState(false);
  const [files, setFiles] = useState(false);
  const onExpandActivity = () => {
    setExpandActivity(!expandActivity);
  };
  const onFiles = () => {
    setFiles(!files);
  };

  return (
    <>
      <Grid container spacing={2} className={classes.root}>
        <Grid item sm={12} md={6} lg={7}>
          {/*<LeftHeader customerData={leadsData} />*/}
        </Grid>
        <Grid item sm={12} md={6} lg={5}>
          <RightHeader
            expandActivity={expandActivity}
            filesMenuOpen={files}
            onToggleOptions={onExpandActivity}
            onToggleFilesMenu={onFiles}
            isDeactivatedClient={isDeactivatedClient}
          />
        </Grid>
        <Grid item sm={12} md={12} lg={12}>
          {expandActivity && !files && (
            <Grid container spacing={2}>
              <Grid item sm={6} md={3} lg={3}>
                {/*<Source/>*/}
              </Grid>
              <Grid item sm={6} md={3} lg={3}>
                {/*<PersonalInfo/>*/}
              </Grid>
              <Grid item sm={6} md={3} lg={3}>
                {/* <QuickNotes/> */}
              </Grid>
              <Grid item sm={6} md={3} lg={3}>
                {/*<Activity/>*/}
              </Grid>
            </Grid>
          )}
          {expandActivity && files && <Files />}
        </Grid>
      </Grid>
    </>
  );
};

export { DeactivatedClients };
