import { useLeadExpandBodyStyle } from '@/static/stylesheets/organisms';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import React, { useEffect, useRef, useState } from 'react';

// import { getActivityLogs } from '@/redux/actions';

interface ActivityProps {
  leadsId?: any;
  leadsActivity?: any;

  onReload?(): void;
}

const Activity: React.FC<ActivityProps> = (props) => {
  const classes = useLeadExpandBodyStyle();
  const getActivityLog = props?.leadsActivity ?? [];
  const listInnerRef = useRef<HTMLDivElement>(null);
  const [currPage, setCurrPage] = useState(1);
  const [prevPage, setPrevPage] = useState(0);
  const [userList, setUserList] = useState<any[]>([]);
  const [lastList, setLastList] = useState(false);

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      const total = (scrollTop + clientHeight).toFixed();
      if (Number(total) === scrollHeight) {
        setCurrPage(currPage + 1);
      }
    }
  };

  const fetchData = () => {
    if (props?.onReload) {
      props?.onReload();
    }
  };

  useEffect(() => {
    if (!lastList && prevPage !== currPage) {
      fetchData();
    }
  }, [currPage, lastList, prevPage, userList]);

  useEffect(() => {
    const fetchInterval = setInterval(() => {
      fetchData();
    }, 30000);

    return () => {
      clearInterval(fetchInterval);
    };
  }, []);

  return (
    <>
      <div className={classes.activityBody}>
        <List>
          <ListItem alignItems='flex-start' className={classes.activityHeader}>
            <ListItemText primary='Activity Log'></ListItemText>
          </ListItem>
          <Divider />
          <div onScroll={onScroll} ref={listInnerRef} className={'list_body activity_list'}>
            {getActivityLog &&
              getActivityLog?.length > 0 &&
              getActivityLog?.map((item: any, index: number) => (
                <div key={index}>
                  <ListItem alignItems='flex-start'>
                    <ListItemText
                      disableTypography
                      secondary={
                        <React.Fragment>
                          <div className={'text_body'}>
                            <Typography sx={{ display: 'inline' }} component='div' variant='body2'>
                              {item?.activity}
                              {/* <a href='#' target={'_blank'}>{"ClientList email #2"}</a> */}
                            </Typography>
                            <Typography sx={{ display: 'inline' }} component='span' variant='body1'>
                              {item?.timestamp}
                            </Typography>
                          </div>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <Divider />
                </div>
              ))}
          </div>
        </List>
      </div>
    </>
  );
};

export { Activity };
