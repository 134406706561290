import React, { useEffect, useState } from 'react';
import { Button } from '@/components/atoms/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useSettingDialogStyle } from '@/static/stylesheets/molecules';
import { clearClientSendContractResponse, getClientContractPDF, sendClientContract } from '@/redux/actions';
import { Box, CircularProgress } from '@mui/material';
import { useAppSelector } from '@/hooks';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import toast from 'react-hot-toast';
import { alertMessages } from '@/config';

interface ContractModalProps {
  open?: any;
  leadData?: any;

  onClose?(): void;
}

const ContractModal: React.FC<ContractModalProps> = (props) => {
  const classes = useSettingDialogStyle();
  const { open, onClose, leadData } = props;
  const contractPdfLinkResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_CLIENT_CONTRACT_PDF];
  const contractSendResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.SEND_LEAD_CONTRACT];
  const [loading, setLoading] = useState(true);
  const [contractPdfFile, setContractPdfFile] = useState<string | null>(null);
  const [targetId, setTargetId] = useState<string | null>(null);
  const [iframeLoaded, setIframeLoaded] = useState<boolean>(false);

  const onSendContract = () => {
    // setButtonLoading(true);
    sendClientContract(leadData?.id);
  };

  const Loader = () => (
    <Box
      sx={{
        textAlign: 'center',
      }}
    >
      <CircularProgress style={{ color: '#ffffff' }} />
    </Box>
  );

  const generateFileLink = () => {
    setTargetId(leadData?.id);
    getClientContractPDF(leadData?.id);
  };

  useEffect(() => {
    if (open) {
      const file = leadData?.contract_file;

      // if (file && getFileExtension(file) === "pdf") {
      //   setLoading(false);
      //   setContractPdfFile(file);
      // } else {
      //   generateFileLink();
      // }

      generateFileLink();
    }
  }, [leadData?.contract_file, open]);

  useEffect(() => {
    if (open && targetId === leadData?.id) {
      if (contractPdfLinkResponse && contractPdfLinkResponse?.success) {
        setContractPdfFile(() => contractPdfLinkResponse?.data);
        setLoading(false);
      }

      if (contractPdfLinkResponse && contractPdfLinkResponse?.error) {
        setLoading(false);
      }
    }
  }, [contractPdfLinkResponse, open, targetId]);

  useEffect(() => {
    if (open && targetId === leadData?.id) {
      if (contractSendResponse && contractSendResponse?.success) {
        toast.success(alertMessages.clients.docusignSendSuccess);
        clearClientSendContractResponse();
      }

      if (contractSendResponse && contractSendResponse?.error) {
        toast.error(alertMessages.clients.contractSendError);
        clearClientSendContractResponse();
      }
    }
  }, [open, contractSendResponse, targetId]);

  return (
    <>
      <Dialog
        className={classes.pdfView}
        open={open}
        onClose={onClose}
        maxWidth={'lg'}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogContent>
          {loading ? (
            <Loader />
          ) : (
            <>
              {contractPdfFile && (
                <iframe
                  src={`https://docs.google.com/viewer?url=${contractPdfFile}&embedded=true`}
                  onLoad={() => {
                    setIframeLoaded(true);
                  }}
                  width='1200px'
                  height='100%'
                  frameBorder='0'
                />
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button fullWidth={false} onClick={onClose} color={'error'} variant={'contained'} title={'do not send yet'} />
          <Button
            fullWidth={false}
            onClick={onSendContract}
            color={'success'}
            variant={'contained'}
            title={'Looks Good, Send to DocuSign'}
            loading={contractSendResponse?.loading}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export { ContractModal };
