import React, { useEffect, useRef, useState } from 'react';
import { IpAddressGrid, IpAddressHeader, IpAddressHorizontalContainer } from '@/components/organisms/IP';
import Container from '@mui/material/Container';
import { useAppSelector, useComponentState, useThrottle } from '@/hooks';
import { getIpChildren, getIpList, searchIpDetails } from '@/redux/actions';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { convertToNumber } from '@/utils';

interface IpDataGridProps {
  reloadActive: boolean;

  turnOfReloadActive(): void;
}

const IpAddressList: React.FC<IpDataGridProps> = (props) => {
  const { reloadActive, turnOfReloadActive } = props;
  const fetchIpListSearchResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.SEARCH_IP_CHILDREN];

  const isFirstRender = useRef(true);
  const [limit, setLimit] = useState<number>(15);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const {
    state: { selectedParentIpId, globalSearch, childrenSearch, selectedIpType },
    setState,
  } = useComponentState<{
    globalSearch: null | string;
    childrenSearch: null | string;
    selectedParentIpId: null | string;
    selectedIpType: 'private' | 'public';
  }>({
    globalSearch: null,
    childrenSearch: null,
    selectedParentIpId: null,
    selectedIpType: 'private',
  });

  const throttleGlobalSearch = useThrottle(globalSearch, 1000);
  const throttleChildrenSearch = useThrottle(childrenSearch, 1000);

  useEffect(() => {
    if (selectedParentIpId) {
      getIpChildren(selectedParentIpId);
    }
  }, [selectedParentIpId]);

  useEffect(() => {
    if (isFirstRender?.current) {
      isFirstRender.current = false;
      return;
    }

    turnOfReloadActive();

    if (selectedParentIpId && (throttleGlobalSearch !== null || throttleChildrenSearch !== null)) {
      searchIpDetails({
        s: throttleGlobalSearch !== null ? globalSearch : throttleChildrenSearch !== null ? throttleChildrenSearch : '',
        parent_ip_id: throttleChildrenSearch !== null ? selectedParentIpId : null,
        limit,
        page: 1,
      });
      setCurrentPage(1);
    } else if (selectedParentIpId) {
      getIpList({
        ip_type: selectedIpType,
      });
      getIpChildren(selectedParentIpId);
    } else {
      getIpList({
        ip_type: selectedIpType,
      });
    }
  }, [throttleGlobalSearch, throttleChildrenSearch, selectedParentIpId, selectedIpType, reloadActive]);

  useEffect(() => {
    if (currentPage !== convertToNumber(fetchIpListSearchResponse?.data?.page))
      searchIpDetails({
        s: throttleGlobalSearch !== null ? globalSearch : throttleChildrenSearch !== null ? throttleChildrenSearch : '',
        parent_ip_id: throttleChildrenSearch !== null ? selectedParentIpId : null,
        limit,
        page: currentPage,
      });
  }, [currentPage]);
  return (
    <>
      <div>
        <IpAddressHeader
          selectedIpType={selectedIpType}
          search={globalSearch ?? ''}
          onChangeSearch={(search) => setState({ globalSearch: search })}
          onSelectIpType={(type) =>
            setState({
              selectedParentIpId: null,
              selectedIpType: type,
            })
          }
        />
        {(globalSearch === null || globalSearch === '') && (
          <IpAddressHorizontalContainer
            selectedIpType={selectedIpType}
            selectedParentIpId={selectedParentIpId}
            onChangeSelectedParentIpId={(id) => {
              setState({
                childrenSearch: null,
                selectedParentIpId: id,
              });
            }}
          />
        )}
        <IpAddressGrid
          hideSearch={globalSearch !== null && globalSearch !== ''}
          search={childrenSearch ?? ''}
          onChangeSearch={(search) => setState({ childrenSearch: search })}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </>
  );
};

export { IpAddressList };
