import { apiClient } from '@/api';
import { endpoints } from '@/api/endpoints';
import { useAppSelector } from '@/hooks';
import { getUploadedMediafiles } from '@/redux/actions';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { colors, themeParser, useAppTheme } from '@/theme';
import { uuidv4 } from '@/utils';
import { css } from '@emotion/css';
import { CheckOutlined, CloudUploadOutlined } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import classNames from 'classnames';
import React, { ChangeEvent, useEffect, useState } from 'react';

export interface EditorInstance {
  setEditorValue(value: any): void;
}

interface RichTextEditorProps {
  height?: number;
  placeholder?: string;

  onMount?(editor: EditorInstance): void;

  onChange(value: any): void;
  disabledTools?: ['picture'];
  disableDragAndDrop?: boolean;
  disableLocalImageSelection?: boolean;
}

const useStyles = () => {
  const theme = useAppTheme();

  return {
    root: css`
      margin: -15px 0px -30px 0px !important;
      padding-top: 5px;

      border-left: 1px solid ${themeParser('#e2e2e2', '#505050', theme)};
      .note-frame {
        // border: 1px solid ${themeParser('#e2e2e2', '#505050', theme)};
        border: none;
      }

      .note-toolbar {
        background: transparent;
        border-bottom: 1px solid ${themeParser('#e2e2e2', '#505050', theme)};

        button {
          background: transparent;
          color: ${colors.primary};
          border-color: ${colors.primary};
        }

        button.active {
          background: ${colors.primary} !important;
          color: ${themeParser(colors.white.default, colors.black.default, theme)} !important;
        }
      }

      .note-editable {
        background-color: ${themeParser(colors.body.light, colors.body.dark, theme)} !important;
        color: ${themeParser(colors.regularText.light, colors.regularText.dark, theme)} !important;
        font-size: 15px !important;
        font-family: 'Poppins', sans-serif !important;
      }

      .note-editable > :first-child {
        margin-top: 0 !important;
      }
    `,

    disableLocalFileUpload: css`
      .note-group-select-from-files {
        display: none;
      }
    `,
  };
};

const RichTextEditor: React.FC<RichTextEditorProps> = (props) => {
  const classes = useStyles();
  const { onMount, onChange, height, placeholder, disabledTools, disableDragAndDrop } = props;

  const [editor, setEditor] = useState<any>(null);
  const [editorId] = useState<string>(uuidv4());
  const [mediaDialogOpen, setMediaDialogOpen] = useState<boolean>(false);

  const getToolbarData = () => {
    const insertToolbar: string[] = ['link', 'hr'];

    if (disabledTools && disabledTools?.length > 0) {
      disabledTools?.forEach((item) => {
        if (item === 'picture') {
          const toolbarIndex = insertToolbar?.findIndex((t) => t === item);
          if (toolbarIndex > -1) {
            insertToolbar?.splice(toolbarIndex, 1);
          }
        }
      });
    }

    return [
      ['style', ['style']],
      // ['font', ['bold', 'italic', 'underline', 'clear']],
      ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
      ['fontname', ['fontname']],
      ['fontsize', ['fontsize']],
      ['color', ['color']],
      ['para', ['ul', 'ol', 'paragraph']],
      ['height', ['height']],
      ['table', ['table']],
      ['insert', [...insertToolbar]],
      ['view', ['fullscreen', 'codeview']],
      ['custom', ['customButton']],
      ['help', ['help']],
    ];
  };

  const toolbar = getToolbarData();

  const handleClickImport = (images: any[]) => {
    if (images && editor?.insertImage) {
      const findImagePathUrls = (images ?? []).map((_: any) => _.file_path);
      editor.insertImage(findImagePathUrls);
    }
  };

  useEffect(() => {
    if (editor && typeof editor?.setValue === 'function') {
      if (onMount) {
        onMount({
          setEditorValue: editor?.setValue,
        });
      }

      if (editor?.getValue()?.trim() === '<p><br></p>') {
        if (editor) {
          editor?.setValue('');
        }
      }
    }
  }, [editor]);

  useEffect(() => {
    const editor = (window as any).initializeSummerNote({
      id: editorId,
      placeholder,
      height,
      onChange(value: any) {
        if (onChange) {
          onChange(value);

          if (value?.trim() === '<p><br></p>') {
            if (editor) {
              editor?.setValue('');
            }
          }
        }
      },
      onClickMediaUploader() {
        setMediaDialogOpen(true);
      },
      toolbar,
      disableDragAndDrop,
    });

    setEditor(editor);
  }, [editorId]);

  return (
    <>
      <div
        className={classNames({
          [classes.root]: true,
          [classes.disableLocalFileUpload]: props?.disableLocalImageSelection,
        })}
      >
        <textarea id={editorId}></textarea>
      </div>
      <RichTextEditorMediaDialog
        onClickImport={handleClickImport}
        open={mediaDialogOpen}
        onClose={() => setMediaDialogOpen(false)}
      />
    </>
  );
};

const RenderRichTextEditorPreview = ({ content }: { content: any }) => (
  <div
    className='note-editor note-frame'
    style={{
      background: '#ffffff',
    }}
  >
    <div className='note-editing-area'>
      <div
        className='note-editable'
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
    </div>
  </div>
);

const useUploadDialogStyle = () => {
  const theme = useAppTheme();

  return {
    root: css`
      .MuiDialog-paper {
        background: ${themeParser(colors.body.light, colors.body.dark, theme)};
        width: 95%;
      }

      button {
        font-weight: 500;
        color: #000000de;
        margin: 10px;
      }

      .modalTitle {
        color: ${themeParser('#000000de', '#a0a0a0', theme)};
      }

      .MuiDialogContent-dividers {
        border-color: #a0a0a0;
      }

      .media-dialog-content {
        .empty-message {
          color: ${themeParser('#000000de', '#a0a0a0', theme)};
        }
      }
      .custom-url-input {
        label {
          color: ${themeParser('#000000de', '#a0a0a0', theme)} !important;
        }
        .MuiInputBase-input {
          color: ${themeParser('#000000de', '#a0a0a0', theme)} !important;
        }

        .MuiOutlinedInput-notchedOutline {
          border-color: rgb(158, 158, 158) !important;
          color: ${themeParser('#000000de', '#a0a0a0', theme)} !important;
        }
      }

      .media-dialog-header {
        padding: 20px 30px;

        .media-uploader {
          margin-bottom: 20px;
        }
      }
    `,
    uploadBtnLabel: css`
      padding: 30px;
      border: 1px dashed rgb(158, 158, 158);
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      border-radius: 2px;
      cursor: pointer;
      color: ${themeParser('#000000de', '#a0a0a0', theme)};
    `,
    uploadDialogItemContainer: css`
      display: flex;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;
      width: 100%;
      text-align: center;
      justify-content: center;
      height: 100%;
    `,
    uploadDialogItem: css`
      width: 200px;
      overflow: hidden;
      border-radius: 2px;
      aspect-ratio: 3 / 2;
      cursor: pointer;
      border: 1px solid transparent;
      position: relative;
      background: #ddd;
      justify-content: space-between;

      :hover {
        border-color: #a0a0a0;
        opacity: 0.9;
      }

      @media (max-width: 992px) {
        width: calc(90% / 3);
      }

      @media (max-width: 480px) {
        width: calc(90% / 2);
      }

      img {
        object-fit: cover;
        width: 100%;
        user-select: none;
      }

      .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        color: #ffff;
        transform: translate(-50%, -50%);
        background-color: #4caf50;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        display: none;

        svg {
          font-size: 24px;
          color: #fff;
        }
      }

      &.active {
        border-color: #a0a0a0;

        .icon {
          display: flex;
        }

        img {
          opacity: 0.8;
        }
      }
    `,
  };
};

interface RichTextEditorMediaDialogType {
  onClickImport: (params: any[]) => void;
  open: boolean;
  onClose(): void;
}

const RichTextEditorMediaDialog: React.FC<RichTextEditorMediaDialogType> = ({ onClickImport, open, onClose }) => {
  const [selectedMedia, setSelectedMedia] = useState<any>([]);
  const [medias, setMedias] = useState<any>([]);
  const [urlInput, setUrlInput] = useState<string>();
  const classes = useUploadDialogStyle();

  const onChangeUploadBtn = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    const formData: FormData = new FormData();

    if (files) {
      for (let i = 0; i < files.length; i++) {
        formData.append('files[]', files[i]);
      }

      const result = await apiClient.post({
        method: 'post',
        url: endpoints.private.postMediaFiles,
        useBearerToken: true,
        data: formData,
      });

      if (result && result.data) {
        setMedias([...result.data, ...medias]);
      }
    }
  };

  const handleClose = () => {
    onClose();
    setUrlInput('');
    setSelectedMedia([]);
  };

  const handleImport = () => {
    if (urlInput) {
      onClickImport([...selectedMedia, { file_path: urlInput }]);
    } else {
      onClickImport(selectedMedia);
    }

    handleClose();
  };

  const result = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_MEDIA_FILES];

  useEffect(() => {
    getUploadedMediafiles();
  }, []);

  useEffect(() => {
    if (result) {
      setMedias(result?.data?.data ?? []);
    }
  }, [result]);

  const handleChangeMedia = (media: any) => {
    const find = (selectedMedia ?? []).find((_: any) => _.file_path === media.file_path);

    if (find) {
      const restMedia = selectedMedia.filter((_: any) => _.file_path !== media.file_path);

      setSelectedMedia(restMedia);
    } else {
      setSelectedMedia([...selectedMedia, media]);
    }
  };

  return (
    <>
      <Dialog
        className={classes.root}
        PaperProps={{
          sx: {
            position: 'fixed',
            top: 50,
            m: 0,
            height: '600px',
            width: '60%',
          },
        }}
        scroll='paper'
        maxWidth='md'
        open={open}
        onClose={handleClose}
      >
        <div>
          <DialogTitle className='modalTitle'>{'Select or upload media'}</DialogTitle>
          <div className='media-dialog-header'>
            <div className='media-uploader'>
              <input
                type='file'
                accept='image/*'
                style={{ display: 'none' }}
                id='mediaUpload'
                multiple
                onChange={onChangeUploadBtn}
              />
              <label className={classes.uploadBtnLabel} htmlFor='mediaUpload'>
                <CloudUploadOutlined />
                Upload
              </label>
            </div>

            <TextField
              onChange={(e: any) => setUrlInput(e.target.value)}
              label='Image URL'
              fullWidth
              className='custom-url-input'
            />
          </div>
        </div>
        <DialogContent className='media-dialog-content' dividers={true}>
          <div className={classes.uploadDialogItemContainer}>
            {medias ? (
              (medias ?? []).map((item: any, index: number) => (
                <div
                  key={index}
                  onClick={() => handleChangeMedia(item)}
                  className={`${classes.uploadDialogItem} ${
                    item === (selectedMedia ?? []).find((_: any) => _?.file_path === item?.file_path) && 'active'
                  }`}
                >
                  <img src={item?.file_path} alt={item?.file_name} />
                  <span className='icon'>
                    <CheckOutlined />
                  </span>
                </div>
              ))
            ) : (
              <p className='empty-message'>There is no media</p>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='error' autoFocus onClick={handleClose}>
            Close
          </Button>
          <Button
            variant='contained'
            disabled={selectedMedia.length < 1 && !urlInput ? true : false}
            onClick={handleImport}
            autoFocus
          >
            Import
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export { RenderRichTextEditorPreview, RichTextEditor };
