import React, { useEffect, useState } from 'react';
import { SearchableTextInput, TextInput } from '@/components/atoms/TextInput';
import { Button } from '@/components/atoms/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useSettingDialogStyle } from '@/static/stylesheets/molecules';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { useAppSelector, useInput } from '@/hooks';
import {
  getTaskCustomers,
  getEquipmentOrdersCategory,
  saveEquipmentOrders,
  getEquipments,
  getEquipmentOrdersOpen,
  clearSaveEquipmentOrders,
} from '@/redux/actions';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { EquipmentOrdersParams, CustomerProfileParams, EquipmentParams } from '@/types';
import toast from 'react-hot-toast';
import { alertMessages } from '@/config';
import { convertToNumber, validateURL } from '@/utils';
import { Box } from '@mui/material';

interface CreateEquipmentOrdersProps {
  open: boolean;
  mode: 'create' | 'edit';
  onClose(): void;
}

const CreateEquipmentOrders: React.FC<CreateEquipmentOrdersProps> = (props) => {
  const classes = useSettingDialogStyle();
  const { open, onClose } = props;
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const categoryList = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_EQUIPMENT_ORDERS_CATEGORY];
  const categoryLists: EquipmentOrdersParams[] = categoryList?.data?.data ?? [];

  const equipmentList = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_EQUIPMENTS];
  const equipmentsData: EquipmentParams[] = equipmentList?.data?.data ?? [];

  const customerListResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_TASK_CUSTOMERS];
  const customers: CustomerProfileParams[] = customerListResponse?.data?.data ?? [];
  const createdEquipmentOrders = useAppSelector(selectHttpState)[CORRELATION_IDS.CREATE_EQUIPMENT_ORDERS];
  const [selectedEquipment, setSelectedEquipment] = useState<EquipmentParams['id'] | null>(null);
  const [selectedCustomer, setSelectedCustomer] = useState<CustomerProfileParams['id'] | null>(null);

  const onChangeEquipmentItems = (value: any) => {
    setSelectedEquipment(value);
    const __equipmentIndex = equipmentsData?.findIndex((eq) => eq?.id === value);
    if (__equipmentIndex > -1) {
      const __equipment = equipmentsData[__equipmentIndex];
      const client_cost = __equipment?.client_cost;
      const our_cost = __equipment?.our_cost;
      const url = __equipment.url;
      setEquipmentLink(url);
      setSinglePrice(client_cost);
      setQuantity(1);
      const total = convertToNumber(our_cost) * 1;
      setCost(total);
    }
  };

  const {
    value: singlePrice,
    setValue: setSinglePrice,
    error: singlePriceError,
    bind: bindSinglePrice,
    reset: resetSinglePrice,
  } = useInput('', {
    required: 'Please Enter Single Price!',
    onChange(value) {
      const total = convertToNumber(value) * convertToNumber(quantity);
      setCost(total);
    },
  });

  const {
    value: quantity,
    setValue: setQuantity,
    error: quantityError,
    bind: bindQuantity,
    reset: resetQuantity,
  } = useInput('', {
    required: 'Please Enter Quantity!',
    onChange(value) {
      const total = convertToNumber(value) * convertToNumber(singlePrice);
      setCost(total);
    },
  });

  const {
    value: cost,
    setValue: setCost,
    error: costError,
    bind: bindCost,
    reset: resetCost,
    setError: setCostError,
  } = useInput('', {
    required: 'Please Enter Cost!',
  });
  const {
    value: equipmentLink,
    setValue: setEquipmentLink,
    error: equipmentLinkError,
    bind: bindEquipmentLink,
    reset: resetEquipmentLink,
  } = useInput('', {
    validate(value) {
      if (!validateURL(value)) {
        return 'Please enter a valid URL! (Should start with http:// or https://)';
      }
      return true;
    },
  });

  const { error: selectedEquipmentError, reset: resetEquipment } = useInput('', {
    validate() {
      if (!selectedEquipment || selectedEquipment === '') {
        return 'Please select Equipment!';
      }
      return true;
    },
  });

  const {
    value: category,
    setValue: setCategory,
    error: categoryError,
    bind: bindCategory,
    reset: resetCategory,
  } = useInput('--empty--', {
    validate(value) {
      if (value === '' || value === '--empty--') {
        return 'Please select Category!';
      }

      return true;
    },
  });

  const resetFields = () => {
    resetEquipmentLink();
    resetQuantity();
    resetCost();
    resetSinglePrice();
    resetEquipmentLink();
    resetCategory();
    setSelectedEquipment(null);
  };

  const submitLead = () => {
    quantityError?.check();
    categoryError?.check();
    singlePriceError?.check();
    costError?.check();
    equipmentLinkError?.check();
    selectedEquipmentError?.check();

    if (
      quantityError?.check() ||
      categoryError?.check() ||
      singlePriceError?.check() ||
      costError?.check() ||
      equipmentLinkError?.check() ||
      selectedEquipmentError?.check()
    ) {
      toast.error(alertMessages.leads.submitValidationError);
      return;
    }
    const data: EquipmentOrdersParams = {
      equipment_id: selectedEquipment,
      category_id: category,
      price: singlePrice,
      quantity: quantity,
      actual_cost: cost,
      equipment_url: equipmentLink,
      timezone: timezone,
    };
    if (selectedCustomer) {
      data.customer_id = selectedCustomer;
    }
    saveEquipmentOrders(data);
  };

  const fetchCategory = () => getEquipmentOrdersCategory();

  const getCustomers = () =>
    getTaskCustomers({
      customer_type: '[Clients,Lead]',
      limit: 'all',
      page: 1,
    });

  const fetchEquipments = () => {
    getEquipments({
      limit: 'all',
      page: 1,
    });
  };

  useEffect(() => {}, []);

  useEffect(() => {
    if (open) {
      getCustomers();
      fetchCategory();
      fetchEquipments();
    } else {
      resetFields();
    }
  }, [open]);

  const getEquipmentOrdersList = () =>
    getEquipmentOrdersOpen({
      limit: 6,
      page: 1,
    });

  useEffect(() => {
    if (createdEquipmentOrders?.success) {
      toast.success('created successfully!');
      if (props?.onClose) {
        props?.onClose();
      }
      clearSaveEquipmentOrders();
      getEquipmentOrdersList();
    }
    if (createdEquipmentOrders?.error) {
      toast.error(createdEquipmentOrders?.error?.data?.message);
    }
  }, [createdEquipmentOrders]);

  return (
    <>
      <Dialog
        className={classes.root}
        open={open}
        maxWidth={'sm'}
        onClose={onClose}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogTitle className={classes.createLeadsTitle} id='responsive-dialog-title'>
          Create New Order
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.DialogContent}>
            <Grid container spacing={2}>
              <Grid item sm={12} md={12} lg={12} className={'searchable_input'}>
                <SearchableTextInput
                  label={'Equipment'}
                  options={[
                    ...equipmentsData.map((equipment: EquipmentParams) => ({
                      label: equipment?.equipment_name,
                      value: equipment?.id,
                    })),
                  ]}
                  value={selectedEquipment}
                  onChange={(value) => onChangeEquipmentItems(value)}
                  loading={equipmentList?.data?.loading}
                  error={selectedEquipment ? false : selectedEquipmentError?.error}
                  errorText={selectedEquipmentError?.message}
                />
              </Grid>

              <Grid item sm={12} md={12} lg={12}>
                <Box sx={{ marginTop: '20px' }}>
                  <TextInput
                    variant={'standard'}
                    label={'Single Unit Price'}
                    style={{ marginTop: -10 }}
                    type='number'
                    fullWidth={true}
                    {...bindSinglePrice}
                    error={singlePriceError?.error}
                    errorText={singlePriceError?.message}
                  />
                </Box>
              </Grid>
              <Grid item sm={12} md={12} lg={12}>
                <TextInput
                  variant={'standard'}
                  label={'Quantity'}
                  type='number'
                  style={{ marginTop: 5 }}
                  fullWidth={true}
                  {...bindQuantity}
                  error={quantityError?.error}
                  errorText={quantityError?.message}
                />
              </Grid>
              <Grid item sm={12} md={12} lg={12}>
                <TextInput
                  variant={'standard'}
                  label={'Estimated Cost'}
                  type='number'
                  style={{ marginTop: 5 }}
                  fullWidth={true}
                  {...bindCost}
                  error={costError?.error}
                  errorText={costError?.message}
                />
              </Grid>
              <Grid item sm={12} md={12} lg={12}>
                <TextInput
                  variant={'standard'}
                  label={'Equipment Link'}
                  style={{ marginTop: 5 }}
                  fullWidth={true}
                  {...bindEquipmentLink}
                  error={equipmentLinkError?.error}
                  errorText={equipmentLinkError?.message}
                />
              </Grid>
              <Grid item sm={12} md={12} lg={12}>
                <TextInput
                  select={true}
                  variant={'standard'}
                  style={{ marginTop: 20 }}
                  options={[
                    {
                      title: 'Purchase Category',
                      value: '--empty--',
                      disabled: true,
                    },
                    ...(categoryLists && categoryLists?.length > 0
                      ? categoryLists?.map((item) => ({
                          title: item?.category_name,
                          value: item?.id,
                        }))
                      : []),
                  ]}
                  {...bindCategory}
                  error={categoryError?.error}
                  errorText={categoryError?.message}
                  fullWidth={true}
                />
              </Grid>
              <Grid item sm={12} md={12} lg={12} className={'searchable_input'} style={{ marginTop: -15 }}>
                <SearchableTextInput
                  label={'Choose from Existing Lead/Client'}
                  options={[
                    ...customers.map((customers: CustomerProfileParams) => ({
                      label: `${customers?.first_name ?? ''} ${customers?.last_name ?? ''}`,
                      value: customers?.id,
                    })),
                  ]}
                  value={selectedCustomer}
                  onChange={(value) => setSelectedCustomer(value)}
                  loading={equipmentList?.data?.loading}
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.createLeadsBottom}>
          <Button onClick={onClose} color={'error'} variant={'contained'} title={'CANCEL'} />
          <Button
            onClick={submitLead}
            color={'primary'}
            variant={'contained'}
            title={'SUBMIT ORDER'}
            loading={createdEquipmentOrders?.loading}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export { CreateEquipmentOrders };
