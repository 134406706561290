import { css } from '@emotion/css';
import { colors, themeParser, useAppTheme } from '@/theme';

export const useModalStyles = () => {
  const theme = useAppTheme();

  return {
    root: css`
      .MuiBox-root {
        background: ${themeParser(colors.body.light, colors.body.dark, theme)};
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 600px;
        margin: 20px auto;
        padding: 20px 40px;
        h2 {
          color: ${themeParser(colors.black.default, colors.primary, theme)};
          font-size: 1.15rem;
          line-height: 110%;
          text-align: center;
        }
        textarea {
          margin-top: 20px;
          width: calc(100% - 10px);
          padding-left: 10px;
          padding-top: 10px;
          background: transparent;
          color: ${themeParser(colors.black.default, colors.primary, theme)};
          font-size: 16px;
          font-family: 'Poppins', sans-serif;
          border: 1px solid ${themeParser('#00000017', '#ffffff17', theme)};
        }
        .actionButton {
          text-align: center;
          button {
            margin: 10px;
            font-weight: 500;
            letter-spacing: 0.5px;
            font-size: 14px;
          }
        }
        .radio {
          padding: 20px 0px 0px 0px;
          color: #9e9e9e;
          .MuiRadio-root {
            color: #9e9e9e;
          }
          .Mui-checked {
            color: ${colors.primary} !important;
          }
        }
        hr {
          margin-top: 20px;
        }
      }
      .error {
        font-size: 12px;
        color: #d32f2f;
      }
      .textaria_error {
        border-color: red !important;
        outline: none !important;
        width: 100%;
      }
      .textaria:focus {
        border-color: #1976d2 !important;
        outline: none !important;
      }
    `,
  };
};
